import { SummaryCard, CardRow, CardSection } from 'components/SummaryCard';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AdditionalInfoProps } from 'screens/Mutations/utils/types';

type AdditionalInformationProps = {
  onClick: () => void;
  rootFormName: string;
  additionalInformationForm: AdditionalInfoProps;
};

const AdditionalInformationSummary: FC<AdditionalInformationProps> = ({
  onClick,
  rootFormName,
  additionalInformationForm,
}) => {
  const { t } = useTranslation();

  return (
    <SummaryCard
      title={t(`${rootFormName}title`)}
      onEditClick={onClick}
      testdId="conversionMutation-additionalInformation"
    >
      <CardSection>
        <CardRow
          testId="conversionMutation-additionalInformation-name"
          name={t(`${rootFormName}name`)}
          value={additionalInformationForm.name}
        />
        <CardRow
          testId="conversionMutation-additionalInformation-phoneNumber"
          name={t(`${rootFormName}phoneNumber`)}
          value={additionalInformationForm.phoneNumber}
        />
        <CardRow
          testId="conversionMutation-additionalInformation-info"
          name={t(`${rootFormName}info`)}
          value={additionalInformationForm.info}
        />
      </CardSection>
    </SummaryCard>
  );
};

export default AdditionalInformationSummary;
