import currency from 'currency.js';

export const formatLoanNumber = (loanNumber: number | undefined): string => {
  if (!loanNumber) return '-';
  return currency(loanNumber, {
    separator: '.',
    symbol: '',
    precision: 0,
  }).format();
};
