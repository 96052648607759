import { HEADER_SELECTED_GROUP_CLAIM, http } from '../http';
import { RequestConsent } from '../__generated__/api-di';

export const postConsentRequest = async (group: string | null, requestConsent: RequestConsent) => {
  try {
    const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
    const response = await http({
      url: '/api/v1/client-consent-request',
      method: 'POST',
      headers,
      data: JSON.stringify(requestConsent),
    });
    return { data: response.data, status: 200 };
  } catch (err: any) {
    return { status: err.status };
  }
};
