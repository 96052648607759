import {
  defaultApplicant,
  OHAFormDefaultValues,
} from 'screens/Mutations/components/DismissalJointLiabilityMutation/constants';
import hasNhgGuarantee from '../hasNhgGuarantee';
import isEmpty from 'utils/predicates/isEmpty';
import {
  ApplicantReadDto,
  ApplicationReadDto,
  CurrentLoanPartReadDto,
  CurrentPolicyReadDto,
  DismissalJointLiabilityMutationApplication,
  DismissalJointLiabilityMutationStateInfo,
  EmploymentIncomeReadDto,
  EntrepreneurIncomeReadDto,
  LoanPartInfo,
  LoanPartReadDto,
  LoanReadDto,
  ObligationReadDto,
  OtherIncomeReadDto,
  RealEstateReadDto,
  SocialSecurityIncomeReadDto,
  TypeOfEmployment,
  TypeOfOtherIncome,
} from '__generated__/api';
import isTypeOfBenefitForPension from '../../../predicates/isTypeOfBenefitForPension';
import {
  Applicant,
  EmploymentIncome,
  EntrepreneurIncome,
  SocialSecurityIncome,
  Income,
  OHAForm,
  TypeOfIncome,
  OtherIncome,
  Obligation,
  CollateralFormType,
  ToggleType,
  CostAndResourcesFormType,
  LoanPartType,
  SplitLoan,
  Policy,
  LoanFormType,
} from '../../types';
import mapBooleanToToggleType from '../mapBooleanToToggleType';
import { mapConvertedLoan, mapHasConnectedPolicy } from '../mapLoans';

const mapEmploymentIncome = (income: EmploymentIncomeReadDto): EmploymentIncome =>
  income as EmploymentIncome;

const mapSocialInsuranceWage = (income: OtherIncomeReadDto): EmploymentIncome => ({
  typeOfEmployment: TypeOfEmployment.SocialInsuranceWage,
  incomeAmount: income.amount || undefined,
  numberOfEmployments: income.numberOfEmployments,
  pensionContribution: income.pensionContribution,
});

const mapSocialSecurityIncome = (income: SocialSecurityIncomeReadDto): SocialSecurityIncome => ({
  incomeAmount: income.benefit ?? undefined,
  startDate: income.startDate ?? null,
  endDate: income.endDate ?? null,
  typeOfBenefit: income.typeOfBenefit ?? undefined,
});

const mapOtherIncome = ({
  typeOfOtherIncome,
  endDate,
  amount,
}: OtherIncomeReadDto): OtherIncome => ({
  typeOfOtherIncome: typeOfOtherIncome ?? undefined,
  endDate,
  incomeAmount: amount ?? undefined,
});

const mapEntrepreneurIncome = ({
  legalForm,
  startDate,
  typeOfCompany,
  averageIncome,
  annualIncome1,
  annualIncome2,
  annualIncome3,
}: EntrepreneurIncomeReadDto): EntrepreneurIncome => ({
  typeOfCompany: typeOfCompany ?? undefined,
  legalForm: legalForm,
  startDate: startDate ?? null,
  incomeAmount: averageIncome ?? undefined,
  incomePreviousFirstYear: annualIncome1 ?? undefined,
  incomePreviousSecondYear: annualIncome2 ?? undefined,
  incomePreviousThirdYear: annualIncome3 ?? undefined,
  hasIncomeStatement: averageIncome && averageIncome > 0 ? 'yes' : 'no',
});

const mapIncomes = (applicant: ApplicantReadDto, newApplicant = false): Income[] => {
  // social insurance wage is saved as other income, but displayed as employment income
  const socialInsuranceWages =
    applicant.otherIncomes?.filter(
      (x) => x.typeOfOtherIncome === TypeOfOtherIncome.SocialInsuranceWage
    ) || [];

  const employmentIncomes: Income[] = (applicant.employmentIncomes || [])
    .map((income) => ({
      employment: mapEmploymentIncome(income),
      typeOfIncome: TypeOfIncome.Employment,
    }))
    .concat(
      socialInsuranceWages.map((income) => ({
        employment: mapSocialInsuranceWage(income),
        typeOfIncome: TypeOfIncome.Employment,
      }))
    );

  const entrepreneurIncomes: Income[] = (applicant.entrepreneurIncomes || []).map((income) => ({
    entrepreneur: mapEntrepreneurIncome(income),
    typeOfIncome: TypeOfIncome.Entrepreneur,
  }));

  const socialSecurityIncomes: Income[] = (
    applicant.socialSecurityIncomes?.filter(
      (x) => x.typeOfBenefit && !isTypeOfBenefitForPension(x.typeOfBenefit)
    ) || []
  ).map((income) => ({
    socialSecurity: mapSocialSecurityIncome(income),
    typeOfIncome: TypeOfIncome.SocialSecurity,
  }));

  const pensionIncomes: Income[] = (
    applicant.socialSecurityIncomes?.filter(
      (x) => x.typeOfBenefit && isTypeOfBenefitForPension(x.typeOfBenefit)
    ) || []
  ).map((income) => ({
    pension: mapSocialSecurityIncome(income),
    typeOfIncome: TypeOfIncome.Pension,
  }));

  const otherIncomes: Income[] = (
    applicant.otherIncomes?.filter(
      (x) => x.typeOfOtherIncome !== TypeOfOtherIncome.SocialInsuranceWage
    ) || []
  ).map((income) => ({
    otherIncome: mapOtherIncome(income),
    typeOfIncome: TypeOfIncome.Other,
  }));

  const incomes = [
    ...entrepreneurIncomes,
    ...employmentIncomes,
    ...socialSecurityIncomes,
    ...pensionIncomes,
    ...otherIncomes,
  ];

  return incomes.length ? incomes : [];
};

const mapObligations = (obligations?: ObligationReadDto[] | null): Obligation[] => {
  if (isEmpty(obligations)) return [];

  return obligations.map(
    ({
      isNewObligation,
      redemptionByNewMortgage,
      createdDate,
      partialRedemptionByPersonalEquity,
      ...rest
    }) =>
      ({
        partialRedemptionByPersonalEquity: mapBooleanToToggleType(
          partialRedemptionByPersonalEquity
        ),
        redemptionByNewMortgage: mapBooleanToToggleType(redemptionByNewMortgage),
        isObligationByBKR: mapBooleanToToggleType(isNewObligation),
        ...rest,
      } as Obligation)
  );
};

const mapApplicant = (applicant?: ApplicantReadDto, newApplicant = false): Applicant => {
  if (!applicant)
    return {
      ...defaultApplicant,
      personalInfo: {
        ...defaultApplicant.personalInfo,
        hasNewApplicant: mapBooleanToToggleType(newApplicant),
      },
    };

  return {
    personalInfo: {
      initials: applicant.initials ?? undefined,
      firstNames: applicant.firstName ?? undefined,
      insertion: applicant.middleName ?? undefined,
      surname: applicant.lastName ?? undefined,
      dateOfBirth: applicant.dateOfBirth ?? undefined,
      maritalStatus: applicant.civilStatus ?? undefined,
      maritalStatusVSOld: applicant.civilStatusBeforeEndOfRelationship ?? undefined,
      numberOfDivorces: applicant.numberOfDivorces ?? undefined,
      children: applicant.numberOfChildrenLivingAtHome ?? undefined,
      homeOwner: mapBooleanToToggleType(applicant.ownerRealEstate),
      hasNewApplicant: mapBooleanToToggleType(newApplicant),
    },
    contactDetails: {
      street: applicant.street ?? '',
      postalCode: applicant.postalCode ?? '',
      houseNumber: applicant.houseNumber ?? undefined,
      houseNumberSuffix: applicant.houseNumberExtension || undefined,
      city: applicant.city ?? '',
      country: applicant.country,
      email: applicant.emailAddress ?? '',
      phoneNumber: applicant.privatePhoneNumber ?? undefined,
    },
    income: {
      incomes: mapIncomes(applicant, newApplicant),
    },
    obligation: {
      obligations: mapObligations(applicant.obligations),
    },
  };
};

const mapApplicants = (applicants?: ApplicantReadDto[] | null): Applicant[] => {
  if (isEmpty(applicants)) return [{ ...defaultApplicant }, { ...defaultApplicant }];

  const [currentApplicant, newApplicant] = applicants;
  return [mapApplicant(currentApplicant), mapApplicant(newApplicant, !!newApplicant)];
};

const mapCollateral = (
  realEstates?: RealEstateReadDto[] | null,
  NHGGuarantee?: ToggleType
): CollateralFormType => {
  if (isEmpty(realEstates)) return {} as CollateralFormType;

  const realEstate = realEstates[0];

  return { ...realEstate, NHGGuarantee } as CollateralFormType;
};
const mapCostAndResources = (
  loan?: LoanReadDto,
  hasBuyoutAmount?: ToggleType
): CostAndResourcesFormType => {
  if (!loan) return {} as CostAndResourcesFormType;

  const { buyoutAmountByPersonalEquity, loanAmountBuyout, typeOfBuyoutFinancing } = loan;

  return {
    ...loan,
    typeOfBuyoutFinancing,
    buyoutAmount: loanAmountBuyout,
    buyoutAmountByPersonalEquity,
    hasBuyoutAmount: hasBuyoutAmount,
  } as CostAndResourcesFormType;
};

const mapNewLoanParts = (loanParts?: LoanPartReadDto[] | null): LoanPartType[] => {
  if (isEmpty(loanParts)) return [];
  return loanParts
    .filter((loanPart) => !loanPart.originalLoanPart)
    .map((loanPart) => {
      return {
        NHGGuarantee: mapBooleanToToggleType(loanPart.hasNhg),
        repaymentType: loanPart.redemptionTypeDisplayName ?? undefined,
        loanAmount: loanPart.amount ?? undefined,
        box1: loanPart.amountTaxBox1 ?? undefined,
        box3: loanPart.amountTaxBox3 ?? undefined,
        interestPercentage: loanPart.interestPercentage ?? undefined,
        newDuration: loanPart.duration ?? undefined,
        endDateInterestDeduction: loanPart.endDateInterestDeduction ?? undefined,
        fixedRatePeriod: loanPart.fixedInterestPeriod ?? undefined,
      };
    });
};

export const mapSplitLoan = (newLoanParts: LoanPartReadDto[]): SplitLoan[] =>
  newLoanParts.map((splitPart) => ({
    amount: splitPart.amount ?? 0,
    fixedRatePeriod: splitPart.fixedInterestPeriod ?? undefined,
    repaymentType: splitPart.redemptionTypeDisplayName ?? undefined,
    NHGGuarantee: mapBooleanToToggleType(splitPart.hasNhg),
    box1: splitPart.amountTaxBox1 ?? undefined,
    box3: splitPart.amountTaxBox3 ?? undefined,
    interestPercentage: splitPart.interestPercentage ?? undefined,
    endDateInterestDeduction: splitPart.endDateInterestDeduction ?? undefined,
    hasDurationPeriodChange: mapBooleanToToggleType(!!splitPart.duration),
    newDuration: splitPart.duration ?? undefined,
  }));

const lifeInsurancePolicyInEmptyLoanPart = (currentLoanPart: CurrentLoanPartReadDto) =>
  !currentLoanPart.loanPartNumber &&
  currentLoanPart.currentPolicies?.[0] &&
  !currentLoanPart.currentPolicies?.some((policy) => policy.originalLoanPart);

export const mapLoanParts = (
  currentLoanParts: CurrentLoanPartReadDto[],
  newLoanParts: LoanPartReadDto[],
  loanPartsInfo: LoanPartInfo[]
): LoanPartType[] =>
  currentLoanParts
    .filter((currentLoanPart) => !lifeInsurancePolicyInEmptyLoanPart(currentLoanPart))
    .map((currentLoanPart) => {
      const connectedParts = newLoanParts.filter(
        (newPart) => newPart.originalLoanPart === currentLoanPart.loanPartNumber
      );
      const isSplitLoan = connectedParts.length > 1;
      const isConvertLoan = connectedParts.length > 0 && connectedParts.length < 2;
      return {
        loanPartNumber: currentLoanPart.loanPartNumber ?? undefined,
        repaymentType: currentLoanPart.redemptionTypeDisplayName ?? undefined,
        shouldSplitLoan: mapBooleanToToggleType(currentLoanPart.shouldLoanPartSplit),
        NHGGuarantee: mapBooleanToToggleType(currentLoanPart.hasNhg),
        loanAmount: currentLoanPart.principalAmount ?? undefined,
        hasConnectedPolicy: mapHasConnectedPolicy(loanPartsInfo, currentLoanPart.loanPartNumber),
        splitLoan: isSplitLoan ? mapSplitLoan(connectedParts) : undefined,
        convertLoan: isConvertLoan ? mapConvertedLoan(connectedParts) : undefined,
        numberOfSplitLoanParts: connectedParts.length > 1 ? connectedParts.length : 0,
        box1: currentLoanPart.amountTaxBox1 ?? undefined,
        box3: currentLoanPart.amountTaxBox3 ?? undefined,
        interestPercentage: currentLoanPart.interestPercentage ?? undefined,
        endDateInterestDeduction: currentLoanPart.endDateInterestDeduction ?? undefined,
        hasDurationPeriodChange: mapBooleanToToggleType(!!currentLoanPart.duration),
        newDuration: currentLoanPart.duration ?? undefined,
        hasInterestRedemption: mapBooleanToToggleType(currentLoanPart.hasInterestRedemption),
        fixedRatePeriod: currentLoanPart.fixedInterestPeriod ?? undefined,
        typeOfFinancing: currentLoanPart.typeOfInterestRedemptionFinancing,
        policies: mapLoanPartPolicies(currentLoanPart, loanPartsInfo),
      };
    });

const mapLoanPartPolicies = (
  currentLoanPart: CurrentLoanPartReadDto,
  loanPartsInfo: LoanPartInfo[]
): Policy[] => {
  const connectedPoliciesInfo = loanPartsInfo.find(
    (part) => part.loanPartNumber === currentLoanPart.loanPartNumber
  );
  return mapPolicies(currentLoanPart.currentPolicies ?? [], connectedPoliciesInfo ?? {});
};

const mapLoanPolicies = (
  currentLoanParts: CurrentLoanPartReadDto[],
  loanPartsInfo: LoanPartInfo[]
): Policy[] => {
  return currentLoanParts.flatMap((currentLoanPart) => {
    const connectedPoliciesInfo = loanPartsInfo.find((part) => !part.loanPartNumber);
    const filteredPolicies = currentLoanPart.currentPolicies?.filter(
      (policy) => !policy.originalLoanPart
    );
    return mapPolicies(filteredPolicies ?? [], connectedPoliciesInfo ?? {});
  });
};

const mapPolicies = (policies: CurrentPolicyReadDto[], loanPartInfo: LoanPartInfo) => {
  return policies.map((policy) => {
    const extrPolicyInfo = loanPartInfo?.policies?.find(
      (policyInfo) => policyInfo.policyNumber === policy.policyNumber
    );

    return {
      policyNumber: policy.policyNumber ?? undefined,
      numberOfPolicyHolders: extrPolicyInfo?.numberOfPolicyHolders ?? undefined,
      policyHolders: {
        personOne: policy.policyHolders?.[0]?.fullName ?? '',
        personTwo: policy.policyHolders?.[1]?.fullName ?? '',
      },
      numberOfInsuredPersons: extrPolicyInfo?.numberOfInsuredPeople ?? undefined,
      isPolicyForConnectedPersons: mapBooleanToToggleType(
        extrPolicyInfo?.hasPolicyInNameOfInsuredPerson
      ),
      insuredPersons: !extrPolicyInfo?.hasPolicyInNameOfInsuredPerson
        ? {
            personOne: policy.insuredPeople?.[0]?.fullName ?? '',
            personTwo: policy.insuredPeople?.[1]?.fullName ?? '',
          }
        : {},
      typeOfPolicyMutation: policy.typeOfMutation ?? undefined,
      mutationDescription: policy.mutationDescription ?? undefined,
      policyAmount: policy.amount ?? undefined,
      originalLoanPart: policy.originalLoanPart ?? undefined,
    };
  });
};

const mapLoan = (
  application: ApplicationReadDto,
  stateInfo?: DismissalJointLiabilityMutationStateInfo
): LoanFormType => {
  const { loanNumber, currentRealEstates, loan } = application;

  const loanPartsInfo = stateInfo?.loanParts ?? [];

  const currentLoan = currentRealEstates?.[0]?.currentLoans?.[0];

  const currentLoanParts = currentLoan?.currentLoanParts ?? [];
  const newLoanParts = loan?.loanParts ?? [];

  return {
    loanNumber: loanNumber || 0,
    currentLoanParts: mapLoanParts(currentLoanParts, newLoanParts, loanPartsInfo),
    newLoanParts: mapNewLoanParts(newLoanParts),
    policies: mapLoanPolicies(currentLoanParts, loanPartsInfo),
    amount: currentLoan?.principalAmount ?? undefined,
    extraPayOffAmount: currentLoan?.amountExtraRedemption ?? undefined,
    changeLifeInsurancePolicy: mapBooleanToToggleType(stateInfo?.changeLifeInsurance),
    changeCurrentMortgage: mapBooleanToToggleType(stateInfo?.changeCurrentMortgage),
    amountRaise: loan?.amountRaise,
  };
};

const mapOhaApiToForm = (apiData: DismissalJointLiabilityMutationApplication): OHAForm => {
  const { application, dismissalJointLiabilityMutationStateInfo: stateInfo } = apiData;
  if (!application) return OHAFormDefaultValues;

  const {
    id,
    reference,
    loanNumber,
    moneylender,
    intermediary,
    applicants,
    realEstates,
    currentRealEstates,
    loan,
  } = application;

  const [currentApplicant, newApplicant] = mapApplicants(applicants);

  return {
    id,
    reference,
    loanNumber: loanNumber || 0,
    commercialLabel: moneylender?.commercialLabel,
    moneylenderName: moneylender?.name,
    intermediary,
    productOptions: [],
    currentApplicant,
    newApplicant,
    loan: mapLoan(application, stateInfo),
    collateral: mapCollateral(realEstates, hasNhgGuarantee(currentRealEstates)),
    costAndResources: mapCostAndResources(loan, mapBooleanToToggleType(stateInfo?.hasBuyoutAmount)),
    additionalInformation: {
      name: intermediary?.name,
      phoneNumber: intermediary?.phoneNumber,
      info: loan?.additionalInformation || undefined,
    },
  };
};
export default mapOhaApiToForm;
