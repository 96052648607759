import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, SaveOutlined } from '@mui/icons-material';
import { ConfirmationDialog } from 'components/CustomDialog';
import { useDeleteApplication } from 'use/applications';
import { LoadingIconButton } from 'components/LoadingIconButton';
import { ButtonText, ButtonWrapper, IconButton } from './BarActionButtons.styled';
import { useWatch } from 'react-hook-form';
import { useExpoints } from 'screens/Clients/hooks/useExpoints';

type BarActionButtonsProps = {
  handleNotification: (status: string, translationKey: string) => void;
  handleNavigateOnCloseForm: () => void;
  handleNavigate: () => void;
  handleSaveOnButtonClick: (isSaveEnabled: boolean) => Promise<void>;
  handleSave: (isNotification: boolean) => Promise<boolean>;
  isLoadingSaveApplication: boolean;
  saveAndExitDialog: boolean;
};

const BarActionButtons: FC<BarActionButtonsProps> = ({
  handleNavigateOnCloseForm,
  handleNavigate,
  handleSaveOnButtonClick,
  handleNotification,
  isLoadingSaveApplication,
  saveAndExitDialog,
  handleSave,
}) => {
  const { t } = useTranslation();
  const formState: any = useWatch();
  const { id: applicationId } = formState;
  const [deleteApplicationDialog, setDeleteApplicationDialog] = useState(false);
  const { callMutationTerminatedSurvey } = useExpoints();

  const terminate = () => {
    callMutationTerminatedSurvey('conversion');
  };

  const onSuccessDelete = () => {
    handleNotification('success', 'deleteApplication');
    setDeleteApplicationDialog(false);
    handleNavigateOnCloseForm();
  };

  const onErrorDelete = () => {
    handleNotification('error', 'deleteApplication');
  };

  const { mutate: deleteApplication } = useDeleteApplication(
    applicationId ?? 0,
    onSuccessDelete,
    onErrorDelete
  );

  return (
    <>
      <ButtonWrapper>
        <LoadingIconButton
          isLoading={isLoadingSaveApplication}
          onClick={() => handleSave(true)}
          size="medium"
        >
          <SaveOutlined />
          <ButtonText>{t('directMutations.header.save')}</ButtonText>
        </LoadingIconButton>
        <IconButton
          onClick={() => {
            setDeleteApplicationDialog(true);
          }}
          size="large"
        >
          <DeleteOutlined />
          <ButtonText>{t('directMutations.header.delete')}</ButtonText>
        </IconButton>
      </ButtonWrapper>

      <ConfirmationDialog
        isOpen={saveAndExitDialog}
        onConfirmation={() => {
          handleSaveOnButtonClick(true);
          handleNavigate();
        }}
        onDecline={() => {
          handleSaveOnButtonClick(false);
          handleNavigate();
          terminate();
        }}
        onClose={() => handleSaveOnButtonClick(false)}
        confirmationText={t('directMutations.confirmSaveOnExit.confirm')}
        declineText={t('directMutations.confirmSaveOnExit.decline')}
        bodyText={t('directMutations.confirmSaveOnExit.body')}
      />
      <ConfirmationDialog
        isOpen={deleteApplicationDialog}
        onConfirmation={deleteApplication}
        onDecline={() => setDeleteApplicationDialog(false)}
        onClose={() => setDeleteApplicationDialog(false)}
        confirmationText={t('directMutations.buttons.deleteButton')}
        declineText={t('directMutations.buttons.cancelButton')}
        bodyText={t('directMutations.confirmDelete.bodyMutation')}
      />
    </>
  );
};

export default BarActionButtons;
