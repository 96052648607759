import { FC, Fragment } from 'react';
import { SummaryCard } from 'components/SummaryCard';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import { renderConnectedPolicies } from './utils/renderConnectedPolicies';
import { hasPoliciesForSummary } from './utils/hasPoliciesForSummary';

type ConnectedPolicySummaryProps = {
  onClick: (step: string) => void;
};

export const ConnectedPolicySummary: FC<ConnectedPolicySummaryProps> = ({ onClick }) => {
  const { t } = useTranslation();

  const loan = useSelector((state: RootState) => state.ohaForm.loan);

  const handleOnClick = (step: string) => () => onClick(step);
  if (!hasPoliciesForSummary(loan)) return null;

  return (
    <SummaryCard
      title={t('directMutations.summary.policyHeader')}
      onEditClick={handleOnClick('financingConnectedPolicy')}
      key="summary"
    >
      {loan.changeLifeInsurancePolicy === 'yes' &&
        renderConnectedPolicies(
          'directMutations.policies.deathInsurance',
          loan.loanNumber,
          loan.amount,
          loan.policies
        )}

      {loan.changeCurrentMortgage === 'yes' &&
        loan.currentLoanParts.map((part, index) => {
          if (part.hasConnectedPolicy === 'yes') {
            return (
              <Fragment key={index}>
                {renderConnectedPolicies(
                  `${t('directMutations.loanParts.loanPartNumber')} ${part.loanPartNumber}`,
                  loan.loanNumber,
                  loan.amount,
                  part.policies
                )}
              </Fragment>
            );
          }
          return <Fragment key={index} />;
        })}
    </SummaryCard>
  );
};

export default ConnectedPolicySummary;
