import { useTranslation } from 'react-i18next';
import { Box, Container } from '@mui/material';
import { useQueryNotifications } from 'use/notifications';
import SearchBar from '../../components/SearchBar';
import NotificationList from '../../components/NotificationList';
import LoadingIndicator from '../../components/LoadingIndicator';
import NotificationsHeader from './components/NotificationsHeader';
import PlaceholderEmptyList from '../../components/PlaceholderEmptyList';
import { IsMobile } from 'utils/global';
import useStyles from './Notifications.styled';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setResetClientFilters } from 'store/genericClientListFiltersReducer';
import { useNotificationFilter } from './hooks/useNotification';

const Notifications = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status, data, isFetching, refetch } = useQueryNotifications();
  const isMobile = IsMobile();
  const {
    filter,
    searchTerm,
    filteredNotifications,
    handleSearch,
    emptySearch,
    handleFilterChange,
  } = useNotificationFilter(data, status, isFetching);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setResetClientFilters());
  }, [dispatch]);

  const handleFilter = (value: string) => {
    handleFilterChange(value);
  };

  return (
    <div className={classes.root}>
      <NotificationsHeader
        filter={filter}
        onFilter={handleFilter}
        status={status}
        onRefetch={refetch}
        isFetching={isFetching}
      />
      <Container className={classes.container}>
        <Box
          my={isMobile ? 1 : 3}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <SearchBar
            placeholder={t('notifications.filter.search')}
            searchTerm={searchTerm}
            handleInputChange={handleSearch}
            emptySearch={emptySearch}
            width={360}
            data-testid="notification-testBar"
          />
        </Box>
        {status === 'loading' || isFetching ? (
          <LoadingIndicator data-testid="loading-spinner" />
        ) : status === 'success' && data && data.length > 0 ? (
          <div data-testid="notification-list">
            <NotificationList notifications={filteredNotifications} />
          </div>
        ) : (
          <PlaceholderEmptyList subtitle={t('notifications.empty')} />
        )}
      </Container>
    </div>
  );
};

export default Notifications;
