import { Divider, Box, Grid, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import NewsCard from 'screens/News/components/NewsCard';
import { googleTagManager } from 'use/gtm';
import formatDate from 'utils/formatDate';
import { NewsItem } from '__generated__/api';
import { NewTitleWrapper, TypographyDate, TitleTypography, TitleCategory } from '../Home.styled';

type NewsTileProps = {
  item: NewsItem;
};

const NewsTile = ({ item }: NewsTileProps) => {
  const handleNewsClick = (): void => {
    googleTagManager({
      event: 'ButtonClick',
      btnName: 'NewsItemHomepage',
      newsTitle: item.title ?? undefined,
    });
  };
  return (
    <NewTitleWrapper>
      <Divider />
      <Link
        underline="none"
        to={`/news/${item.slug}`}
        component={NavLink}
        onClick={handleNewsClick}
      >
        <Box display="flex" mt={2} mb={1} alignItems="center">
          <Grid container mr={2} flexDirection="column" alignItems="flex-start">
            {item.dateCreated && (
              <TypographyDate mb={1}>{formatDate(item.dateCreated)}</TypographyDate>
            )}
            <TitleTypography>{item.title}</TitleTypography>
            <TitleCategory>{item.category}</TitleCategory>
          </Grid>
          <NewsCard newsItem={item} useThumbnail width={80} height={80} />
        </Box>
      </Link>
    </NewTitleWrapper>
  );
};

export default NewsTile;
