import { FC, useCallback } from 'react';
import { Hidden } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../store';
import { setMobileDrawerVisible } from '../../store/appReducer';
import { Drawer } from './NavDrawer.styled';
import NavDrawerContent from './NavDrawerContent';
import { useLocation } from 'react-router-dom';
import { RouteConfig } from 'routes';

type NavDrawerProps = {
  routes?: RouteConfig[];
};

const NavDrawer: FC<NavDrawerProps> = ({ routes = [] }) => {
  const dispatch = useAppDispatch();
  const mobileDrawerVisible = useAppSelector((state) => state.app.mobileDrawerVisible);
  const location = useLocation();

  const commercialRelationFromUrl = () => {
    const path = location.pathname;
    if (path === '/') return null;
    const root = path.split('/')[1];
    if (root.indexOf('_CR_') > -1 || root.indexOf('_CL_') > -1) return root;
    return null;
  };

  const commercialRelation = commercialRelationFromUrl();

  const closeDrawer = useCallback(() => {
    dispatch(setMobileDrawerVisible(false));
  }, [dispatch]);

  return (
    <>
      <Hidden smDown>
        <Drawer anchor="left" variant="permanent">
          <NavDrawerContent
            closeDrawer={closeDrawer}
            routes={routes}
            commercialRelation={commercialRelation}
          />
        </Drawer>
      </Hidden>
      <Hidden smUp>
        <Drawer anchor="left" open={mobileDrawerVisible} onClose={closeDrawer}>
          <NavDrawerContent
            closeDrawer={closeDrawer}
            routes={routes}
            commercialRelation={commercialRelation}
          />
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavDrawer;
