import { styled } from '@mui/material/styles';
import { AppBar, Theme } from '@mui/material';

type Props = {
  theme?: Theme;
  height?: number;
  ispopup?: boolean;
};

export const HeaderAppBar = styled(AppBar)<Props>(({ theme, height, ispopup }) => ({
  color: theme.palette.black.main,
  backgroundColor: theme.palette.white.main,
  padding: '4px 16px',
  left: 0,
  Gap: theme.spacing(3),
  minHeight: height ?? 'auto',
  width: ispopup ? 'calc(100% - 17px)' : '100%',
}));
