import React, { FC, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  Fab,
  Hidden,
  Icon,
  Slide,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import StepIcon from '../StepIcon/StepIcon';
import backgroundImageStater from '../../assets/images/half.png';
import TaskList from './TaskList';
import Summary from './Summary/Summary';
import Header from './Header';
import { useQueryTaskDetails, useSubmitIssues } from 'use/tasks';
import LoadingIndicator from 'components/LoadingIndicator';
import { CommentSolution, useSolutionReducer } from '../../store/taskReducer';
import { useQueryProofItems } from 'use/documents';
import {
  Case,
  TaskSolutionIssue,
  TaskSolutionResolutionFileActionType,
  TaskInfo,
  TaskDetails,
} from '../../__generated__/api-di';
import { isTaskEnded, readFileToBase64 } from '../../utils/global';
import { taskSolutionGenericCommentKey } from '../../constants';
import ExitWithoutSavingWrapper from 'components/ExitWithoutSavingPopup/ExitWithoutSavingWrapper';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

const iconWidth = 40;
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      display: 'flex',
    },
  },
  wrapper: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100% - 72px)',
  },
  scrollWrapper: {
    overflow: 'auto',
    flex: 1,
  },
  sidebar: {
    maxWidth: 400,
    height: '100%',
    backgroundColor: theme.palette.purple.dark,
    backgroundImage: `url("${backgroundImageStater}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 70px',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 900,
    margin: '60px 100px',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4),
    },
  },
  stepper: {
    padding: theme.spacing(7.5, 4),
    backgroundColor: 'transparent',
  },
  stepConnector: {
    borderColor: 'transparent',
  },
  step: {
    cursor: 'pointer',
  },
  stepLabel: {
    'width': 250,
    'height': 40,
    'whiteSpace': 'nowrap',
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
    'lineHeight': '40px',
    'padding': theme.spacing(0, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
      width: 200,
    },
    'borderRadius': theme.shape.borderRadius,
    'color': theme.palette.common.white,
    'fontSize': 14,
    'fontWeight': 300,
    '&.Mui-active': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      fontWeight: 400,
    },
    '&.Mui-completed': {
      color: theme.palette.common.white,
      fontWeight: 300,
    },
  },
  spacer: {
    flexGrow: 1,
  },
  bottomNav: {
    position: 'fixed',
    bottom: theme.spacing(3),
  },
  fabLeft: {
    marginLeft: theme.spacing(-8),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
    },
  },
  fabRight: {
    right: theme.spacing(3),
  },
  fabIcon: {
    marginLeft: theme.spacing(1),
    transform: 'scale(0.8)',
  },
  paper: {
    'width': '100%',
    'padding': theme.spacing(2, 3.5),
    'borderRadius': '8px',
    '& hr': {
      marginLeft: `calc(${iconWidth}px + ${theme.spacing(2)}px)`,
      backgroundColor: 'rgba(0,0,0,0.08)',
    },
  },
  fileIcon: {
    width: iconWidth,
    marginRight: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
  multiline: {
    minHeight: 120,
  },
  description: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
  dangerBtn: {
    marginLeft: theme.spacing(1),
    color: theme.palette.danger.main,
  },
  mobileDiv: {
    display: 'flex',
    flexDirection: 'column',
  },
  viewIcon: {
    color: theme.palette.grey[700],
    marginBottom: '6px',
  },
}));

const Transition = React.forwardRef<unknown, TransitionProps>((props: any, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const steps = ['resolveIssues', 'summary'];

type Props = {
  open: boolean;
  application?: Case;
  task?: TaskInfo;
  onClose: () => void;
};

const ResolveTasks: FC<Props> = ({ open, onClose, application, task }) => {
  if (application === undefined || !open) {
    // do not render when not shown
    return null;
  }

  return (
    <ResolveTasksWrapped open={open} application={application!} task={task} onClose={onClose} />
  );
};

type PropsWrapped = {
  open: boolean;
  application: Case;
  task?: TaskInfo;
  onClose: () => void;
};

const ResolveTasksWrapped: FC<PropsWrapped> = ({ open, onClose, application, task }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [askClose, setAskClose] = useState(false);
  const [completeError, setCompleteError] = useState<boolean | undefined>(undefined);
  const [activeStep, setActiveStep] = React.useState(0);

  const id =
    application && task
      ? {
          loanNumber: application.application!.loanNumberExternal!,
          index: application.application!.index!,
          taskId: task.taskId!,
          commercialLabel: application.lender!.label!,
        }
      : undefined;
  const taskDetailsResponse = useQueryTaskDetails(id);
  const taskDetail = taskDetailsResponse.data;

  function reset() {
    setActiveStep(0);
    setAskClose(false);
    onClose();
  }

  const closeApplicationDialog = () => {
    setAskClose(true);
  };

  if (id === undefined) {
    return null;
  }

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      className={classes.root}
      onClose={closeApplicationDialog}
    >
      <Header application={application} reset={closeApplicationDialog} />
      {taskDetailsResponse.isLoading && <LoadingIndicator />}
      {taskDetailsResponse.isSuccess && taskDetail && (
        <ResolveTasksInner
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          askClose={askClose}
          setAskClose={setAskClose}
          application={application}
          taskDetail={taskDetail}
          setCompleteError={setCompleteError}
          reset={reset}
        />
      )}
      <CustomSnackbar
        isOpen={completeError === true}
        handleClose={() => setCompleteError(false)}
        severity="error"
        message={t('tasks.solutions.submitError')}
      />
    </Dialog>
  );
};

type PropsInner = {
  application: Case;
  taskDetail: TaskDetails;
  activeStep: number;
  askClose: boolean;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setCompleteError: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setAskClose: React.Dispatch<React.SetStateAction<boolean>>;
  reset: () => void;
};

const ResolveTasksInner: FC<PropsInner> = ({
  application,
  taskDetail,
  activeStep,
  setActiveStep,
  setCompleteError,
  reset,
  askClose,
  setAskClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [solutionState, dispatch] = useSolutionReducer(taskDetail);
  const [loading, setLoading] = React.useState(false);
  const submitIssues = useSubmitIssues(
    () => {
      setLoading(false);
      reset();
    },
    () => {
      setLoading(false);
      setCompleteError(true);
    }
  );
  const id = {
    loanNumber: application.application!.loanNumberExternal!,
    index: application.application!.index!,
    taskId: taskDetail.taskId!,
    commercialLabel: application.lender!.label!,
  };
  const includeDocumentId = true;
  const proofItemsResponse = useQueryProofItems(application, includeDocumentId);

  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const onCloseSaveDialog = () => {
    setAskClose(false);
  };

  async function sendTaskResolution() {
    setLoading(true);
    const issues: Array<TaskSolutionIssue> = [];

    for (const [key, solutions] of Array.from(solutionState.entries())) {
      if (key === taskSolutionGenericCommentKey || solutions.length === 0) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const description =
        taskDetail?.context!.issues!.filter((i) => i.id === key)[0].description ?? '';

      const solvedIssue: TaskSolutionIssue = {
        description: description,
        category: description,
        id: key,
        resolution: {
          files: [],
        },
        // proofItem:
      };

      for (const solution of solutions) {
        switch (solution.type) {
          case 'comment':
            solvedIssue.resolution.comment = solution.data.comment;
            break;
          case 'solvedByOther':
            solvedIssue.resolution.solvedByOtherIssue = true;
            break;
          case 're-upload': {
            // eslint-disable-next-line no-await-in-loop
            const data = await readFileToBase64(solution.data.file!);

            solvedIssue!.resolution!.files!.push({
              comment: undefined,
              proofItem: solution.data.proofItem!,
              action: TaskSolutionResolutionFileActionType.ReUpload,
              base64Content: data,
              encoding: 'utf8',
              mimeType: solution.data.file?.type ?? '',
              name: solution.data.file?.name ?? '',
            });
            break;
          }
          case 'addNew': {
            // eslint-disable-next-line no-await-in-loop
            const data = await readFileToBase64(solution.data.file!);

            solvedIssue!.resolution!.files!.push({
              comment: undefined,
              documentType: solution.data.label!,
              action: TaskSolutionResolutionFileActionType.AddUpload,
              base64Content: data,
              encoding: 'utf8',
              mimeType: solution.data.file?.type ?? '',
              name: solution.data.file?.name ?? '',
            });
            break;
          }
          default:
            break;
        }
      }

      issues.push(solvedIssue);
    }

    const genericComment =
      (solutionState.get(taskSolutionGenericCommentKey)?.[0] as CommentSolution | undefined)?.data
        .comment ?? undefined;
    submitIssues.mutate({
      id,
      issues,
      comment: genericComment,
    });
  }

  const hasSolutions = () => {
    for (const [key, solutions] of Array.from(solutionState.entries())) {
      if (key !== taskSolutionGenericCommentKey || solutions.length > 0) {
        return true;
      }
    }

    return false;
  };
  const pristine = !hasSolutions() || isTaskEnded(taskDetail);

  return (
    <ExitWithoutSavingWrapper
      askClose={askClose}
      pristine={pristine}
      onContinue={reset}
      onCancel={onCloseSaveDialog}
    >
      <Box className={classes.wrapper}>
        <Hidden smDown>
          <Box className={classes.sidebar}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              className={classes.stepper}
              connector={
                <StepConnector
                  classes={{
                    line: classes.stepConnector,
                  }}
                />
              }
            >
              {steps.map((label, index) => {
                const stepIsDisabled = index > activeStep;
                return (
                  <Step
                    key={label}
                    disabled={stepIsDisabled}
                    className={`${!stepIsDisabled && classes.step}`}
                  >
                    <StepLabel
                      classes={{
                        label: classes.stepLabel,
                      }}
                      StepIconComponent={StepIcon}
                      onClick={() => !stepIsDisabled && setActiveStep(index)}
                      // disabled={stepIsDisabled}
                    >
                      {t(`tasks.steps.${label}.title`)}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </Hidden>
        <div className={classes.scrollWrapper}>
          <Box className={classes.content}>
            <Typography variant="h4">{t(`tasks.steps.${steps[activeStep]}.title`)}</Typography>
            <Typography className={classes.description} variant="body1">
              {t(`tasks.steps.${steps[activeStep]}.description`)}
            </Typography>

            {activeStep === 0 && (
              <>
                <TaskList
                  application={application}
                  task={taskDetail}
                  proofItems={proofItemsResponse.data ?? []}
                  solutionState={solutionState}
                  dispatch={dispatch}
                />
              </>
            )}
            {activeStep === 1 && (
              <Summary
                application={application}
                task={taskDetail}
                solutionState={solutionState}
                dispatch={dispatch}
              />
            )}

            {activeStep !== 0 && (
              <Fab
                size="medium"
                color="secondary"
                aria-label="previous"
                disabled={loading || activeStep === 0}
                onClick={handleBack}
                className={`${classes.bottomNav} ${classes.fabLeft}`}
              >
                <Icon>arrow_left</Icon>
              </Fab>
            )}
            {activeStep === 0 ? (
              <Fab
                color="primary"
                variant="extended"
                size="medium"
                disabled={false}
                onClick={handleNext}
                className={`${classes.bottomNav} ${classes.fabRight}`}
              >
                {t('tasks.solutions.toSummary')}
                <Icon className={classes.fabIcon}>arrow_right</Icon>
              </Fab>
            ) : (
              <Fab
                variant="extended"
                color="primary"
                size="medium"
                disabled={loading || isTaskEnded(taskDetail)}
                onClick={sendTaskResolution}
                className={`${classes.bottomNav} ${classes.fabRight}`}
              >
                {t('tasks.solutions.toComplete') as string}
                <Icon className={classes.fabIcon}>arrow_right</Icon>
              </Fab>
            )}
          </Box>
        </div>
      </Box>
    </ExitWithoutSavingWrapper>
  );
};

export default ResolveTasks;
