import type { FC } from 'react';
import { FormHelperText, Box } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { FormInputProps } from '../FormInputProps';
import { Checkbox, Column, FormLabel } from 'components/Form/Forms.styled';

export const FormInputSingleCheckbox: FC<FormInputProps> = ({
  name,
  label,
  shouldUnregister = false,
  testId,
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    shouldUnregister,
  });
  const id = `checkbox-${name}`;

  return (
    <Box sx={{ display: 'flex' }} data-testid={`${testId || name}-input-wrapper`}>
      <Column>
        <Checkbox
          data-testid={id}
          id={id}
          onChange={onChange}
          value={value}
          color={error ? 'error' : 'primary'}
        />
      </Column>
      <Column>
        <FormLabel htmlFor={id} data-testid={`${testId || name}-input-label`}>
          {label}
        </FormLabel>
        <FormHelperText data-testid="error-message-input" error={!!error}>
          {error?.message}
        </FormHelperText>
      </Column>
    </Box>
  );
};
export default FormInputSingleCheckbox;
