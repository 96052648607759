import { useState, useCallback, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useQueryClientLetters } from 'use/letters';
import type { Letter } from '__generated__/api';
import ViewClientFile from 'components/LetterList/ViewClientFile';
import { useGoogleTagManager } from 'use/gtm';
import LettersTable from './LettersTable';

type DocumentReference = {
  displayName: string;
  commercialLabel: string;
  letterId: string;
  loanNumber: number;
};

type ClientDetailLetterListProps = {
  loanNumber: number;
  commercialLabel: string;
};

const ClientDetailLetterList: FC<ClientDetailLetterListProps> = ({
  loanNumber,
  commercialLabel,
}) => {
  const googleTagManager = useGoogleTagManager();
  const { t } = useTranslation();
  const [documentReference, setDocumentReference] = useState<DocumentReference | null>(null);
  const [showDocument, setShowDocument] = useState(false);
  const { data: letters, isError } = useQueryClientLetters(commercialLabel, loanNumber);

  const openDocumentView = (
    displayName: string,
    commercialLabel: string,
    letterId: string,
    loanNumber: number
  ) => {
    setDocumentReference({ displayName, commercialLabel, loanNumber, letterId });
    setShowDocument(true);
  };

  const closeDocumentView = () => {
    setDocumentReference(null);
    setShowDocument(false);
  };

  const handleDocument = useCallback(
    (letter: Letter) => {
      openDocumentView(letter.displayName, commercialLabel, letter.id, loanNumber);
      googleTagManager({
        event: 'ButtonClick',
        btnName: 'OpenLetter',
        typeOfLetter: letter.displayName,
      });
    },
    [googleTagManager, commercialLabel, loanNumber]
  );

  if (isError || !letters || !letters.length) {
    return null;
  }
  return (
    <Box mt={5}>
      <Typography variant="h6" component="h3">
        {t('clients.detail.letters')}
      </Typography>

      <LettersTable clientLetters={letters} handleDocument={handleDocument} />
      {documentReference && documentReference.loanNumber && (
        <ViewClientFile
          displayName={documentReference.displayName}
          commercialLabel={documentReference.commercialLabel}
          loanNumber={documentReference.loanNumber}
          letterId={documentReference.letterId}
          open={showDocument}
          onClose={closeDocumentView}
        />
      )}
    </Box>
  );
};

export default ClientDetailLetterList;
