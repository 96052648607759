import { object } from 'yup';
import { errorMessages } from 'screens/Mutations/constants';
import * as Yup from 'yup';

export const dismissalJointLiabilityMutationFormSchema = object({
  acceptedAgreement: Yup.bool()
    .required(errorMessages.required)
    .oneOf([true], errorMessages.required),
  acceptedBkrAgreement: Yup.bool()
    .required(errorMessages.required)
    .oneOf([true], errorMessages.required),
});
