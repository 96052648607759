import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import SearchBar from 'components/SearchBar';
import { useClients } from './hooks/useClients';
import ClientList from './components/ClientList/ClientList';
import ClientsHeader from './components/ClientHeader/ClientHeader';
import DesktopFilterArea from './components/Filters/DesktopFilterArea';
import MobileFilterArea from './components/Filters/MobileFilterArea';
import ClientsTemplate from '../templates/Lists/ListWrapper/WrapperTemplate';
import ClientDetail from './components/ClientDetail';

function LabeledClients() {
  const { t } = useTranslation();
  const {
    clientsQueryResult,
    onCloseClient,
    onSelectClient,
    page,
    selectedClient,
    filterState,
    searchTerm,
    filteredClients,
    handleSearch,
    handlePage,
    handleFilter,
  } = useClients();
  const { data: clientsData, isLoading: isClientLoading } = clientsQueryResult;
  const handleSelectSingleValues = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    handleFilter({ name, value });
  };

  const handleInputChange = (e: ChangeEvent<any>) => {
    const value = e.currentTarget.value;
    handleSearch(value);
  };

  const emptySearch = () => {
    handleSearch('');
  };

  return (
    <>
      <ClientsTemplate
        header={
          <ClientsHeader
            handleFilter={handleFilter}
            filterState={filterState}
            filter
            isCSV={false}
            isLabeled
          />
        }
        searchBar={
          <SearchBar
            placeholder={t('clients.filter.search')}
            searchTerm={searchTerm}
            handleInputChange={handleInputChange}
            emptySearch={emptySearch}
            width={360}
          />
        }
        filterArea={
          <DesktopFilterArea
            filterState={filterState}
            handleSelectSingleValues={handleSelectSingleValues}
          />
        }
        list={
          <ClientList
            clients={filteredClients}
            onClickItem={onSelectClient}
            onPage={handlePage}
            page={page}
            totalClients={clientsData?.total}
            isLoading={isClientLoading}
          />
        }
        mobileFilterArea={
          <MobileFilterArea
            filterState={filterState}
            handleSelectSingleValues={handleSelectSingleValues}
          />
        }
      />
      {selectedClient && <ClientDetail client={selectedClient} onClose={onCloseClient} />}
    </>
  );
}

export default LabeledClients;
