import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Hidden, Typography } from '@mui/material';

import TaskStatusIcon from '../../TaskListItem/TaskStatusIcon';
import clsx from 'clsx';
import { TaskDetailsIssue, TaskInfoDelegationState } from '__generated__/api-di';

const useStyles = makeStyles((theme) => ({
  root: {
    'display': 'flex',
    'minHeight': 80,
    '&:hover': {
      backgroundColor: theme.palette.white.light,
    },
    'borderRadius': 6,
    'alignItems': 'flex-start',
    'padding': theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 0),
    },
  },
  text: {
    flex: 1,
  },
  text2: {
    marginBottom: theme.spacing(0),
  },
  small: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    display: 'block',
    paddingTop: theme.spacing(0.5),
  },
  proofItemName: {
    textTransform: 'capitalize',
  },
}));

interface IssueCardProps {
  issue: TaskDetailsIssue;
  taskState: TaskInfoDelegationState;
}

const IssueCard: React.FC<IssueCardProps> = ({ issue, taskState }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Hidden mdDown>
        <TaskStatusIcon status={taskState} />
      </Hidden>
      <div className={classes.text}>
        <div className={classes.text2}>
          <Typography className={clsx([classes.proofItemName])} variant="body1">
            {issue.description}
          </Typography>
          <Typography className={clsx([classes.small])} variant="body2">
            {taskState} {/* TODO: Translate */}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default IssueCard;
