import { Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderRadius: 6,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));

type Props = {
  message: string;
};
const UploadDisabledAlert = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid="upload-disabled-alert">
      <InfoIcon className={classes.icon} />
      <Typography variant="body2" color="textSecondary">
        {props.message}
      </Typography>
    </div>
  );
};

export default UploadDisabledAlert;
