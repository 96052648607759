import { useEffect, useState, FC, ChangeEvent } from 'react';
import { Hidden, Table, TableFooter } from '@mui/material';
import Pagination from 'components/Pagination/Pagination';
import { useGoogleTagManager } from 'use/gtm';
import { Case } from '__generated__/api-di';
import { PaginationWrapper } from 'screens/templates/Lists/ListWrapper/Wrapper.styled';
import ApplicationMobile from './components/ApplicationMobile';
import ApplicationDesktop from './components/ApplicationDesktop';
import LoadingIndicator from 'components/LoadingIndicator';
import PlaceholderEmptyList from 'components/PlaceholderEmptyList';
import { useTranslation } from 'react-i18next';

export type ApplicationListProps = {
  items: Case[];
  onClickItem: (item: Case) => void;
  isLoading: boolean;
};

const ApplicationList: FC<ApplicationListProps> = ({ items, onClickItem, isLoading }) => {
  const tagEvent = useGoogleTagManager();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const { t } = useTranslation();
  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
    tagEvent({
      event: 'ButtonClick',
      btnName: 'PaginationApplicationList',
      pageNumber: page,
      totalApplications: items.length,
    });
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [items]);

  if (isLoading) {
    return <LoadingIndicator data-testid="items-loading" />;
  }
  if (items.length === 0) {
    return <PlaceholderEmptyList subtitle={t('applications.table.none')} />;
  }
  return (
    <>
      <ApplicationMobile items={items} onClickItem={onClickItem} />
      <ApplicationDesktop
        items={items}
        onClickItem={onClickItem}
        rowsPerPage={rowsPerPage}
        page={page}
      />
      <Hidden smDown>
        <Table>
          <TableFooter>
            <PaginationWrapper>
              <Pagination
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                count={items.length}
              />
            </PaginationWrapper>
          </TableFooter>
        </Table>
      </Hidden>
    </>
  );
};

export default ApplicationList;
