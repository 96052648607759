import { Grid, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButToLetDecimalAmountEnabled } from 'config/constants';
import CurrencyField from 'lib/CurrencyField/CurrencyField';
import IntegerNumberField from 'lib/IntegerNumberField/IntegerNumberField';
import { FormField } from 'screens/BuyToLet/BuyToLetForm/form/FormField';
import { BtlApplicant } from 'types/BtlFormApplication';

export type BuyToLetCollateralsProps = {
  category: 'naturalPerson' | 'legalPerson';
  handleTextChange: (event: any) => void;
  handleCurrencyChange: (event: any, name: string) => void;
  applicant: BtlApplicant;
  errorMessage: (id: string, category: string) => React.ReactNode;
};

const BuyToLetCollaterals: FC<BuyToLetCollateralsProps> = ({
  category,
  applicant,
  handleCurrencyChange,
  handleTextChange,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const [focused, setFocused] = useState<boolean>(false);
  const form = 'buyToLetForm.buyToLetCollaterals';
  const categoryCapitalized = category.charAt(0).toUpperCase() + category.slice(1);
  const validationMessageName = category === 'naturalPerson' ? 'incomesAndProperties' : category;
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">{t(`${form}.collaterals`)}</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormField
          open
          label={t(`${form}.numberOfRentalCollateralsOwned${categoryCapitalized}`)}
          className={focused ? 'Mui-focused' : ''}
          required={
            applicant.totalValueOfRentalCollateralsOwned !== null &&
            applicant.totalValueOfRentalCollateralsOwned !== undefined
          }
        >
          <Grid item container xs={12}>
            <IntegerNumberField
              label={null}
              placeholder={t(
                `${form}.numberOfRentalCollateralsOwned${categoryCapitalized}Placeholder`
              )}
              style={{ marginTop: '0px' }}
              containerWidth={6}
              name="numberOfRentalCollateralsOwned"
              variant="outlined"
              required={
                applicant.totalValueOfRentalCollateralsOwned !== null &&
                applicant.totalValueOfRentalCollateralsOwned !== undefined
              }
              InputLabelProps={{
                shrink: true,
              }}
              min={0}
              max={99999}
              onFocus={() => {
                setFocused(true);
              }}
              onBlur={() => {
                setFocused(false);
              }}
              onChange={(e) => {
                const value = e.target.value;
                handleTextChange({
                  target: {
                    name: e.target.name,
                    value:
                      value === '' || value === undefined || value === null
                        ? undefined
                        : parseInt(value, 10),
                  },
                });
              }}
              value={applicant.numberOfRentalCollateralsOwned ?? ''}
              error={Boolean(
                errorMessage('.numberOfRentalCollateralsOwned', validationMessageName)
              )}
            />
          </Grid>
        </FormField>
      </Grid>
      <Grid item xs={12} sm={12}>
        <CurrencyField
          containerWidth={6}
          label={t(`${form}.totalValueOfRentalCollateralsOwned${categoryCapitalized}`)}
          placeholder={t(
            `${form}.totalValueOfRentalCollateralsOwned${categoryCapitalized}Placeholder`
          )}
          required={
            applicant.numberOfRentalCollateralsOwned !== null &&
            applicant.numberOfRentalCollateralsOwned !== undefined
          }
          name="totalValueOfRentalCollateralsOwned"
          onChange={(number: number | null) =>
            handleCurrencyChange(number, 'totalValueOfRentalCollateralsOwned')
          }
          value={applicant.totalValueOfRentalCollateralsOwned ?? ''}
          error={Boolean(
            errorMessage('.totalValueOfRentalCollateralsOwned', validationMessageName)
          )}
          allowDecimals={ButToLetDecimalAmountEnabled}
        />
      </Grid>
    </>
  );
};

export default BuyToLetCollaterals;
