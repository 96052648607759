import { Hidden, IconButton, Icon, Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { buttonStyles } from '../styling';

const UploadButton = ({ onClick }: ButtonProps) => {
  const { t } = useTranslation();
  const classes = buttonStyles();

  return (
    <>
      <Hidden mdUp>
        <IconButton
          data-testid="upload-button"
          color="primary"
          onClick={onClick}
          title={t('applications.detail.upload')}
        >
          <Icon>upload</Icon>
        </IconButton>
      </Hidden>
      <Hidden mdDown>
        <Button
          data-testid="upload-button"
          color="primary"
          variant="contained"
          onClick={onClick}
          className={classes.button}
          endIcon={<Icon className={classes.icon}>upload</Icon>}
        >
          {t('applications.detail.upload')}
        </Button>
      </Hidden>
    </>
  );
};

export default UploadButton;
