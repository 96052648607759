import { parseISO, format } from 'date-fns';
import { nl } from 'date-fns/locale';

const getDateText = (syncedOnUtc: string | undefined) => {
  if (!syncedOnUtc) return '';
  const date = parseISO(`${syncedOnUtc}`);
  const now = new Date();
  const dayDiff = now.getDate() - date.getDate();

  switch (dayDiff) {
    case 0:
      return `vandaag ${format(date, 'HH:mm')} uur`;
    case 1:
      return `gisteren ${format(date, 'HH:mm')} uur`;
    case 2:
      return `eergisteren ${format(date, 'HH:mm')} uur`;
    default:
      return `${format(date, 'dd-MM-yyyy HH:mm', { locale: nl })} uur`;
  }
};

export default getDateText;
