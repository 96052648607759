import { FC, useState } from 'react';
import { Tab } from '@mui/material';
import { googleTagManager } from 'use/gtm';
import { useTranslation } from 'react-i18next';
import RealEstateDetails from './components/RealEstateDetails';
import LoanDetails from '../LoanParts/LoanPartsDetails';
import ConstructionDepotDetails from './components/ConstructionDepotDetails';
import ContactDetails from './components/ContactDetails';
import PersonalDetails from './components/PersonalDetails';
import { Tabs } from 'screens/Clients/Clients.styled';
import { ClientDetailsModel } from 'types/ClientDetailsModel';

export type ClientDataContentProps = {
  clientData: ClientDetailsModel;
};

const ClientDataContent: FC<ClientDataContentProps> = ({ clientData }) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  const { realEstates, loan, loanParts, depots, persons } = clientData;
  const translationRoot = 'clients.clientData.tabs';

  const getAvailableTabs = (): string[] => {
    const availableTabs = [];
    if (loan) availableTabs.push('loan');
    if (realEstates && !!realEstates[0]) availableTabs.push('realEstate');
    if (depots && !!depots[0]) availableTabs.push('constructionDepot');
    if (persons) {
      availableTabs.push('contact');
      availableTabs.push('personal');
    }
    return availableTabs;
  };

  const tabs = getAvailableTabs();

  const onChangeTab = (e: React.ChangeEvent<any>, newValue: number) => {
    const tab = tabs[newValue];
    googleTagManager({
      event: 'TabClick',
      btnName: `Navigation-Tab-${tab}`,
    });
    googleTagManager({
      event: 'Navigate',
      btnName: `Tab-${tab}`,
    });
    setCurrentTab(newValue);
  };

  const isCurrentTab = (tab: string) => {
    switch (tab) {
      case 'loan':
        return <LoanDetails loan={loan} loanParts={loanParts} />;
      case 'realEstate':
        return realEstates && <RealEstateDetails realEstate={realEstates[0]} />;
      case 'constructionDepot':
        return depots && <ConstructionDepotDetails depots={depots} />;
      case 'contact':
        return persons && <ContactDetails persons={persons} />;
      case 'personal':
        return persons && <PersonalDetails persons={persons} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Tabs value={currentTab} onChange={onChangeTab} indicatorColor="primary" textColor="primary">
        {tabs.map((tabName) => (
          <Tab
            label={t(`${translationRoot}.${tabName}`)}
            data-testid={`clientdata-tab-${tabName}`}
            key={tabName}
          />
        ))}
      </Tabs>

      {isCurrentTab(tabs[currentTab])}
    </>
  );
};

export default ClientDataContent;
