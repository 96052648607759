import { LoanFormType } from 'screens/Mutations/utils/types';

export const hasPoliciesForSummary = (loan: LoanFormType) => {
  let result = false;
  if (loan.policies.length > 0) {
    result = true;
  }
  result = loan.currentLoanParts.some((part) => {
    if (part.hasConnectedPolicy === 'no') return false;
    if (!part.policies) return false;
    if (part.policies.length === 0) return false;
    return true;
  });
  if (loan.changeCurrentMortgage === 'no' && loan.changeLifeInsurancePolicy === 'no') {
    result = false;
  }
  return result;
};
