import { FC } from 'react';

import { useNotificationCount } from 'use/notifications';
import {
  NotificationNumberLarge,
  NotificationNumberMedium,
  NotificationNumberSmall,
} from './NotificationCount.styled';

type NotificationCountProps = {
  size?: 'S' | 'M' | 'L';
};

const NotificationCount: FC<NotificationCountProps> = ({ size = 'M' }) => {
  const { notificationCount } = useNotificationCount();
  const NotificationNumber =
    size === 'L'
      ? NotificationNumberLarge
      : size === 'S'
      ? NotificationNumberSmall
      : NotificationNumberMedium;

  return notificationCount && notificationCount > 0 ? (
    <NotificationNumber>{notificationCount}</NotificationNumber>
  ) : null;
};

export default NotificationCount;
