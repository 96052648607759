import { yupResolver } from '@hookform/resolvers/yup';
import additionalInformationSchema from 'screens/Mutations/schemas/additionalInformationSchema';
import {
  acceptedAgreementSchema,
  loanDetailFormSchema,
  connectedPoliciesFormSchema,
} from '../../schemas/conversionMutation/conversionMutationFormSchema';
import { StepNames } from './types';

const conversionSchema = (activeStep: StepNames) => {
  switch (activeStep) {
    case 'loanDetails':
      return loanDetailFormSchema;
    case 'policies':
      return connectedPoliciesFormSchema;
    case 'additionalInformation':
      return additionalInformationSchema;
    case 'summary':
      return acceptedAgreementSchema;
    default:
      return undefined;
  }
};

const conversionResolver = (activeStep: StepNames) => {
  const stepResolver = conversionSchema(activeStep);
  if (!stepResolver) {
    return undefined;
  }
  return yupResolver(stepResolver);
};

export default conversionResolver;
