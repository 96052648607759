import {
  Grid,
  Typography,
  Divider as MuiDivider,
  Icon as MuiIcon,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
  TypographyProps,
  Button,
} from '@mui/material';

import { styled } from '@mui/material/styles';

const ValuesContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 2, 0, 0),
}));

const Container = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 8.75, 0, 4),
  [theme.breakpoints.down('md')]: { padding: theme.spacing(0, 6.75, 0, 2) },
}));

const LoanPartText = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.text.primary,
  fontWeight: 'bold',
}));

const HeaderText = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '11px',
  color: theme.palette.text.disabled,
}));

const HeaderDivider = styled(MuiDivider)(({ theme }) => ({
  margin: theme.spacing(0, 2, 0, 2),
  [theme.breakpoints.only('xs')]: {
    margin: theme.spacing(0, 2, 0, 2),
  },
}));

const Divider = styled(MuiDivider)({
  margin: 0,
});

const BottomDivider = styled(MuiDivider)(({ theme }) => ({
  margin: theme.spacing(2, 2, 0, 2),
}));

const SubTitle = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
}));

const Column = styled(Grid)(({ theme }) => ({
  'whiteSpace': 'nowrap',
  'textOverflow': 'ellipsis',
  'overflow': 'hidden',
  'color': theme.palette.text.primary,
  'fontSize': '14px',
  'lineHeight': '20px',
  'padding': '0 8px 0 0',
  [theme.breakpoints.only('xs')]: {
    width: '100%',
  },
  '& p': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

const TextColumn = styled(Column)(({ theme }) => ({
  width: '20%',
  [theme.breakpoints.down('xl')]: { width: '30%' },
  [theme.breakpoints.down('md')]: { width: '25%' },
  [theme.breakpoints.down('sm')]: { width: '100%' },
}));

const AmountColumn = styled(Column)(({ theme }) => ({
  width: '12%',
  [theme.breakpoints.down('xl')]: { width: '20%' },
  [theme.breakpoints.down('md')]: { width: '25%' },
  [theme.breakpoints.down('sm')]: { width: '100%' },
}));

const RentColumn = styled(Column)(({ theme }) => ({
  width: '5%',
  [theme.breakpoints.down('xl')]: { width: '10%' },
  [theme.breakpoints.down('md')]: { width: '10%' },
  [theme.breakpoints.down('sm')]: { width: '100%' },
}));

const DateColumn = styled(Column)(({ theme }) => ({
  width: '10%',
  [theme.breakpoints.down('xl')]: { width: '10%' },
  [theme.breakpoints.down('md')]: { width: '10%' },
  [theme.breakpoints.down('sm')]: { width: '100%' },
}));

const BorderTextColumn = styled(TextColumn)(({ theme }) => ({
  borderLeftStyle: 'solid',
  borderLeftColor: theme.palette.grey[300],
  borderLeftWidth: '2px',
  paddingLeft: '16px',
  [theme.breakpoints.only('xs')]: {
    display: 'none',
  },
}));

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': { display: 'none' },
  '&.MuiAccordion-root': {
    margin: 0,
    padding: theme.spacing(0, 4, 0, 4),
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: { padding: theme.spacing(0, 2, 0, 2) },
  },
  '&.MuiAccordion-root.Mui-expanded': {
    backgroundColor: theme.palette.white.main,
    boxShadow: '0 6px 12px 0 rgb(22 20 47 / 12%)',
  },
  '&.MuiAccordionSummary-root': {
    margin: 0,
    padding: 0,
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)({
  paddingLeft: '0',
  paddingRight: '0',
});

const AccordionPaddingSummary = styled(MuiAccordionSummary)({
  paddingLeft: '0',
  paddingRight: '20px',
});

const AccordionDetails = styled(MuiAccordionDetails)({
  'display': 'flex',
  'flexDirection': 'column',
  '&.MuiAccordionDetails-root': {
    padding: 0,
  },
});

const FieldWrapper = styled(Grid)(({ theme }) => ({
  width: '100%',
  maxWidth: theme.spacing(82),
}));

const TopWrapper = styled(Grid)(({ theme }) => ({
  width: '100%',
  maxWidth: theme.spacing(82),
  paddingTop: theme.spacing(4),
}));

const LoanPartListWrapper = styled(Grid)({
  marginBottom: '56px',
});

const Icon = styled(MuiIcon)({
  marginLeft: '6px',
});

const LoanPart = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  width: '100%',
  maxWidth: theme.spacing(140),
}));

const PaddingRightGrid = styled(Grid)({
  paddingRight: '8px',
});

const AddButton = styled(Button)(({ theme }) => ({
  width: 'fit-content',
  marginBottom: theme.spacing(2),
  fontWeight: 700,
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  color: theme.palette.danger.dark,
  width: 'fit-content',
  marginBottom: theme.spacing(2),
  fontWeight: 700,
}));

export {
  AddButton,
  Container,
  ValuesContainer,
  DeleteButton,
  LoanPartText,
  HeaderText,
  HeaderDivider,
  Divider,
  BottomDivider,
  SubTitle,
  BorderTextColumn,
  TextColumn,
  AmountColumn,
  RentColumn,
  DateColumn,
  Accordion,
  AccordionSummary,
  AccordionPaddingSummary,
  AccordionDetails,
  FieldWrapper,
  TopWrapper,
  LoanPartListWrapper,
  Icon,
  LoanPart,
  PaddingRightGrid,
};
