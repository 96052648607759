import {
  Accordion as MUIAccordion,
  AccordionDetails as MUIAccordionDetails,
  AccordionSummary as MUIAccordionSummary,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Accordion = styled(MUIAccordion)(({ theme }) => ({
  'padding': theme.spacing(1, 4),
  'boxShadow': '0 6px 12px 0 rgb(22 20 47 / 12%)',
  'borderRadius': '4px',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&.MuiAccordion-root.Mui-expanded': {
    padding: theme.spacing(2, 4, 4, 4),
    margin: 0,
    marginBottom: '24px',
  },
  '&:not(.Mui-expanded)': {
    '&.MuiPaper-root': {
      borderTop: '1px solid #ddd',
      borderBottom: '1px solid #ddd',
      borderRadius: 0,
      backgroundColor: 'transparent',
      marginBottom: '-1px',
      boxShadow: 'none',
    },
  },
}));

export const AccordionDetails = styled(MUIAccordionDetails)({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
});

export const AccordionSummary = styled(MUIAccordionSummary)({
  'padding': 0,
  '.MuiGrid-root': {
    width: '100%',
  },
  '&.MuiAccordionSummary-content': {
    margin: 0,
  },
  '&.Mui-expanded': {
    paddingBottom: '12px',
  },
});
