import { minExistingLoanNumber, maxExistingLoanNumber } from '../constants';

const schemaPart = {
  hasExistingMortgageByMoneylender: {
    type: 'boolean',
    description: 'Has existing mortgage by money lender of the applicant',
  },
  existingLoanNumber: {
    type: ['number', 'null'],
    minimum: minExistingLoanNumber,
    maximum: maxExistingLoanNumber,
    description: 'Existing loan nr of the applicant',
  },
  isMortgageRejected: {
    type: 'boolean',
    description: 'Has existing mortgage by money lender of the applicant',
  },
  reasonMortgageRejection: {
    type: ['string', 'null'],
  },
};

export default schemaPart;
