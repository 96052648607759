import pdf from 'assets/images/icons/pdf.svg';
import xlsx from 'assets/images/icons/xlsx.svg';

export const renderCorrectIcon = (type: string) => {
  switch (type) {
    case 'pdf':
      return pdf;
    case 'xlsx':
      return xlsx;
    default:
      return pdf;
  }
};
