import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { BtlLoan } from 'types/BtlFormApplication';
import CurrencyFormat from 'screens/Applications/components/ApplicationNew/CurrencyFormat';
import { LoanPartReadDto } from '__generated__/api';

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    '& td': {
      borderBottom: 'none',
    },
    '&.error': {
      'backgroundColor': theme.palette.danger.light,
      '& :last-child': {
        color: theme.palette.danger.main,
      },
    },
  },
  errorIcon: {
    'float': 'right',
    'display': 'none',
    '.error &': {
      display: 'block',
    },
  },
  loanPartTitle: {
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  loan: BtlLoan;
  errorMessage: (id: string, category: string) => void;
  onClickField: (field: string) => void;
};

const BuyToLetLoanPartsSummary = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const form = 'buyToLetForm.loan';
  const { loan, errorMessage, onClickField } = props;

  return (
    <>
      <Table className={classes.table}>
        <TableBody>
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.amount', 'loan')),
            })}
          >
            <TableCell>{t(`${form}.amount`)}</TableCell>
            <TableCell onClick={() => onClickField('amount')}>
              {!!loan.amount && <CurrencyFormat value={loan.amount} />}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {loan.loanParts?.map((loanPart, index) => (
        <LoanPartTable
          loanPart={loanPart}
          errorMessage={errorMessage}
          loanPartIndex={index}
          onClickField={onClickField}
          key={`${loanPart.amount}${index}`}
        />
      ))}
    </>
  );
};

type LoanPartProps = {
  loanPart: LoanPartReadDto;
  loanPartIndex: number;
  onClickField: (field: string) => void;
  errorMessage: (id: string, category: string) => void;
};

const LoanPartTable: FC<LoanPartProps> = ({
  loanPart,
  loanPartIndex,
  onClickField,
  errorMessage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const form = 'applications.new.form';
  return (
    <Box my={2}>
      <Typography variant="body1" className={classes.loanPartTitle}>
        {t(`${form}.loanPartNumbered`, { number: loanPartIndex + 1 })}
      </Typography>
      <Table className={classes.table}>
        <TableBody>
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.duration', `loan.loanParts[${loanPartIndex}]`)),
            })}
          >
            <TableCell>
              {t(`${form}.duration`)}
              <span className="required">*</span>
            </TableCell>
            <TableCell onClick={() => onClickField('duration')}>
              {loanPart.duration ? loanPart.duration / 12 : ''}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.amount', `loan.loanParts[${loanPartIndex}]`)),
            })}
          >
            <TableCell>
              {t(`${form}.amount`)}
              <span className="required">*</span>
            </TableCell>

            <TableCell onClick={() => onClickField('amount')}>
              {!!loanPart.amount && <CurrencyFormat value={loanPart.amount} />}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.typeOfRedemption', `loan.loanParts[${loanPartIndex}]`)),
            })}
          >
            <TableCell>
              {t(`${form}.typeOfRedemption`)}
              <span className="required">*</span>
            </TableCell>
            <TableCell onClick={() => onClickField('typeOfRedemption')}>
              {loanPart.typeOfRedemption && t(`${form}.${loanPart.typeOfRedemption}`)}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(
                errorMessage('.fixedInterestPeriod', `loan.loanParts[${loanPartIndex}]`)
              ),
            })}
          >
            <TableCell>
              {t(`${form}.fixedInterestPeriod`)}
              <span className="required">*</span>
            </TableCell>
            <TableCell onClick={() => onClickField('fixedInterestPeriod')}>
              {loanPart.fixedInterestPeriod}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default BuyToLetLoanPartsSummary;
