import ProofItemButtonOptions from 'types/ProofItemsButtons';
import { ProofItemStatus, Status } from 'utils/proof-item';

export type ProofItemButtonProps = {
  status: Status;
  // deprecated
  isFinancialApprovalDocument?: boolean | null;
  // deprecated
  isGenerated?: boolean;
  isUploadAllowed?: boolean;
  reuploadAvailable?: boolean;
  numberOfUploads?: number;
  // deprecated
  homePriority?: boolean;
  // deprecated
  allowedPdmTools?: string[];
  isOckto?: boolean;
};

export const getProofItemButtonOptions = (
  status?: Status,
  isUploadAllowed?: boolean | null,
  isOckto?: boolean | null,
  reuploadAvailable?: boolean | null,
  numberOfUploads?: number | null
): ProofItemButtonOptions | null => {
  if (status === ProofItemStatus.cancelled) return null;

  if (isOckto) {
    if (status === ProofItemStatus.rejected) {
      return { hasDownloadButton: true, hasUploadButton: true };
    }
    if (status === ProofItemStatus.approved) {
      return { hasDownloadButton: true };
    }
    return null;
  }

  if (isUploadAllowed) {
    if (status === ProofItemStatus.rejected || status === ProofItemStatus.waiting) {
      return { hasUploadButton: true };
    }
    if (status === ProofItemStatus.pendingApproval) {
      if (
        reuploadAvailable &&
        numberOfUploads !== undefined &&
        numberOfUploads !== null &&
        numberOfUploads < 10
      ) {
        return { hasDownloadButton: true, hasReuploadButton: true };
      }
      return { hasDownloadButton: true };
    }
    if (status === ProofItemStatus.approved || status === ProofItemStatus.turnedDown) {
      return { hasDownloadButton: true };
    }
    return { hasDisabledUploadButton: true, hasDownloadButton: false };
  }

  if (!isUploadAllowed) {
    if (status === ProofItemStatus.rejected || status === ProofItemStatus.waiting) {
      return { hasDisabledUploadButton: true, hasDownloadButton: false };
    }
  }

  return null;
};

/** @deprecated use ```getProofItemButtonOptions``` */
export const getProofItemButtonOptionsEDMS = ({
  status,
  isFinancialApprovalDocument,
  isUploadAllowed,
  homePriority,
  numberOfUploads,
  reuploadAvailable,
  isGenerated,
  allowedPdmTools,
}: ProofItemButtonProps): ProofItemButtonOptions | null => {
  const isOckto =
    allowedPdmTools !== undefined &&
    (allowedPdmTools as string[]).find((pdm) => pdm.toLowerCase() === 'ockto') !== undefined;

  if (status === ProofItemStatus.cancelled) return null;

  if (isGenerated && status === ProofItemStatus.rejected) return { hasUploadButton: true };
  if (isGenerated && status === ProofItemStatus.approved && isOckto) {
    return { hasDownloadButton: true };
  }
  if (isGenerated && status !== ProofItemStatus.rejected) return null;

  const isFinalContractDownload = Boolean(isFinancialApprovalDocument);

  if (status === ProofItemStatus.rejected || status === ProofItemStatus.waiting) {
    if (homePriority && !isUploadAllowed)
      return { hasDisabledUploadButton: true, hasDownloadButton: isFinalContractDownload };
    if (!homePriority && !isUploadAllowed)
      return !isFinalContractDownload ? null : { hasDownloadButton: true };

    return { hasUploadButton: true, hasDownloadButton: isFinalContractDownload };
  }

  if (status === ProofItemStatus.pendingApproval) {
    if (
      isUploadAllowed &&
      reuploadAvailable &&
      numberOfUploads !== undefined &&
      numberOfUploads < 10
    )
      return { hasDownloadButton: true, hasReuploadButton: true };
    if (isUploadAllowed || isFinalContractDownload) return { hasDownloadButton: true };
  }

  if (status === ProofItemStatus.approved || status === ProofItemStatus.turnedDown) {
    if (isUploadAllowed || isFinalContractDownload) return { hasDownloadButton: true };
  }

  return null;
};
