import { getCustomerNames, getCustomerNamesApplicationData } from 'utils/global';
import { FilterState } from 'types/ApplicationDataDetails';
import { ApplicationData } from '__generated__/api';
import { Case } from '__generated__/api-di';
import { createSortCaseObject, createSortApplicationObject } from '.';

const sortedCases = createSortCaseObject();
const sortedApplications = createSortApplicationObject();

export const handleFilterCase = (items: Case[], searchTerm: string, filterState: FilterState) => {
  return items
    .filter((item) => {
      if (!searchTerm) {
        return true;
      }
      return (
        getCustomerNames(item)?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(item.application!.loanNumberExternal ?? '').includes(searchTerm.replace(/\./g, ''))
      );
    })
    .filter((item) => {
      if (filterState.moneylender.includes('all')) {
        return true;
      }
      return filterState.moneylender.indexOf(item.lender!.label!) !== -1;
    })
    .filter((item) => {
      if (filterState.type.includes('all')) {
        return true;
      }
      return filterState.type.indexOf(item.application!.type!) !== -1;
    })
    .filter((item) => {
      if (filterState.status === 'all') {
        return true;
      }
      return filterState.status.indexOf(item.application!.status!.value!) !== -1;
    })
    .sort(sortedCases[filterState.sortBy]);
};

export const handleFilterApplication = (
  items: ApplicationData[],
  searchTerm: string,
  filterState: FilterState
) => {
  return items
    .filter((item) => {
      if (!searchTerm) {
        return true;
      }
      return getCustomerNamesApplicationData(item)
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());
    })
    .filter((item) => {
      if (filterState.moneylender.includes('all')) {
        return true;
      }
      return filterState.moneylender.indexOf(item.commercialLabel!) !== -1;
    })
    .filter((item) => {
      if (filterState.type.includes('all')) {
        return true;
      }
      return item.type && filterState.type.indexOf(item.type) !== -1;
    })
    .sort(sortedApplications[filterState.sortBy]);
};
