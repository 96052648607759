import { formatISO } from 'date-fns';
import { source } from 'screens/Mutations/components/ConversionMutation/constants';
import isEmpty from 'utils/predicates/isEmpty';
import {
  ApplicantReadDto,
  CurrentLoanPartReadDto,
  CurrentPolicyReadDto,
  DismissalJointLiabilityMutationApplication,
  InsuredPersonReadDto,
  LoanPartInfo,
  LoanPartReadDto,
  PolicyHolderReadDto,
  PolicyInfo,
  RealEstateReadDto,
  Status,
  TypeOfLoan,
} from '__generated__/api';
import {
  Applicant,
  CollateralFormType,
  ConnectedPersons,
  ConvertLoan,
  LoanPartType,
  OHAForm,
  Policy,
  SplitLoan,
} from '../../types';
import {
  mapEmploymentIncomes,
  mapEntrepreneurIncomes,
  mapOtherIncomes,
  mapSocialSecurityIncomes,
} from '../mapIncomes';
import mapObligations from '../mapObligations';
import mapToggleButton from '../mapToggleButton';

const mapApplicant = (applicant: Applicant): ApplicantReadDto => {
  // vraag staat uit bij jeske over wat er met die naam moet gebeuren.
  // Deze wordt bij omzetten uit de stor gehaald nadat die bij client / details in de store is gezet
  // const applicants = store.getState().conversionForm.applicants ?? [];
  const {
    personalInfo: {
      initials,
      surname,
      insertion,
      firstNames,
      dateOfBirth,
      maritalStatusVSOld,
      maritalStatus,
      numberOfDivorces,
      children,
      homeOwner,
    },
    income,
    obligation,
  } = applicant;
  return {
    initials,
    lastName: surname,
    middleName: insertion,
    firstName: firstNames,
    dateOfBirth: dateOfBirth
      ? formatISO(new Date(dateOfBirth), { representation: 'date' })
      : undefined,
    postalCode: applicant.contactDetails.postalCode,
    city: applicant.contactDetails.city,
    country: applicant.contactDetails.country,
    street: applicant.contactDetails.street,
    emailAddress: applicant.contactDetails.email,
    houseNumber: parseHouseNumber(applicant.contactDetails.houseNumber),
    houseNumberExtension: applicant.contactDetails.houseNumberSuffix,
    obligations: mapObligations(obligation),
    privatePhoneNumber: applicant.contactDetails.phoneNumber,
    accumulatedIncomes: [],
    civilStatus: maritalStatus,
    civilStatusBeforeEndOfRelationship: maritalStatusVSOld,
    numberOfDivorces,
    numberOfChildrenLivingAtHome: children,
    ownerRealEstate: homeOwner === 'yes',
    entrepreneurIncomes: mapEntrepreneurIncomes(income),
    otherIncomes: mapOtherIncomes(income),
    socialSecurityIncomes: mapSocialSecurityIncomes(income),
    employmentIncomes: mapEmploymentIncomes(income),
  };
};

const parseHouseNumber = (houseNumber: number | string | undefined) => {
  if (!houseNumber) return undefined;
  if (typeof houseNumber == 'number') return houseNumber;
  if (typeof houseNumber == 'string') return parseInt(houseNumber, 10);
  return undefined;
};

const mapApplicants = (
  currentApplicant: Applicant,
  newApplicant: Applicant
): ApplicantReadDto[] => {
  const result = [];

  result.push(mapApplicant(currentApplicant));
  if (newApplicant.personalInfo.hasNewApplicant === 'yes') result.push(mapApplicant(newApplicant));

  return result;
};

const mapRealEstates = (collateral: CollateralFormType): RealEstateReadDto[] => {
  return [
    {
      distributionValue: collateral.distributionValue ?? null,
      marketValue: collateral.marketValue ?? null,
      valuationCosts: collateral.valuationCosts ?? null,
    },
  ];
};
const mapPolicyHolders = (persons: ConnectedPersons = {}): PolicyHolderReadDto[] => {
  const holders: PolicyHolderReadDto[] = [];
  persons.personOne && holders.push({ fullName: persons.personOne });
  persons.personTwo && holders.push({ fullName: persons.personTwo });
  return holders;
};

const mapInsuredPeople = (
  insuredPersons: ConnectedPersons = {},
  policyHolders: ConnectedPersons = {}
): InsuredPersonReadDto[] => {
  const holders: InsuredPersonReadDto[] = [];

  if (insuredPersons.personOne) {
    holders.push({ fullName: insuredPersons.personOne });
    insuredPersons.personTwo && holders.push({ fullName: insuredPersons.personTwo });
  } else {
    holders.push({ fullName: policyHolders.personOne });
    policyHolders.personTwo && holders.push({ fullName: policyHolders.personTwo });
  }
  return holders;
};

export const mapPolicies = (
  policies?: Policy[],
  originalLoanPart?: number
): CurrentPolicyReadDto[] => {
  if (!policies) return [];
  return policies.map((policy) => {
    return {
      policyNumber: policy.policyNumber,
      policyHolders: mapPolicyHolders(policy.policyHolders),
      insuredPeople: mapInsuredPeople(policy.insuredPersons, policy.policyHolders),
      typeOfMutation: policy.typeOfPolicyMutation,
      mutationDescription: policy.mutationDescription,
      amount: policy.policyAmount,
      originalLoanPart,
    };
  });
};

const mapCurrentPolicies = (loanPart: LoanPartType) => {
  return [
    ...mapPolicies(
      loanPart.hasConnectedPolicy === 'yes' ? loanPart.policies : undefined,
      loanPart.loanPartNumber
    ),
  ];
};

const mapLifeInsurancePolicy = (policies: Policy[]) => {
  return [
    {
      currentPolicies: mapPolicies(policies),
    },
  ];
};

const mapCurrentLoanParts = (currentLoanParts: LoanPartType[] = []): CurrentLoanPartReadDto[] => {
  return currentLoanParts.map((loanPart) => {
    return {
      loanPartNumber: loanPart.loanPartNumber,
      PrincipalAmount: loanPart.loanAmount,
      hasNhg: mapToggleButton(loanPart.NHGGuarantee),
      redemptionTypeDisplayName: loanPart.repaymentType,
      shouldloanPartSplit: mapToggleButton(loanPart.shouldSplitLoan),
      currentPolicies: mapCurrentPolicies(loanPart),
      HasPolicyLinked: mapToggleButton(loanPart.hasConnectedPolicy),
      duration: loanPart.newDuration ?? null,
      endDateInterestDeduction: loanPart.endDateInterestDeduction,
      hasInterestRedemption: mapToggleButton(loanPart.hasInterestRedemption) ?? null,
      typeOfInterestRedemptionFinancing: loanPart.typeOfFinancing,
      interestPercentage: loanPart.interestPercentage ?? null,
      fixedInterestPeriod: loanPart.fixedRatePeriod ?? null,
      principalAmount: loanPart.loanAmount ?? null,
      amountTaxBox1: loanPart.box1 ?? null,
      amountTaxBox3: loanPart.box3 ?? null,
    };
  });
};

const mapLoanParts = (loanParts: LoanPartType[] = []): LoanPartReadDto[] => {
  if (isEmpty(loanParts)) return [];

  const mappedParts = loanParts.flatMap((loanPart: LoanPartType) => {
    if (loanPart.shouldSplitLoan === 'yes' && loanPart.splitLoan && loanPart.splitLoan.length > 0) {
      return loanPart.splitLoan.map((splitPart) => {
        return mappedSplitPart(splitPart, loanPart.loanPartNumber);
      });
    }
    if (loanPart.convertLoan) {
      return mappedConvertedPart(loanPart.convertLoan, loanPart.loanPartNumber);
    }
    if (!loanPart.loanPartNumber) {
      return mappedNewPart(loanPart);
    }
    return undefined;
  });

  return mappedParts.filter((x) => x !== undefined) as LoanPartReadDto[];
};

const mappedSplitPart = (splitPart: SplitLoan, loanPartNumber?: number): LoanPartReadDto => {
  return {
    originalLoanPart: loanPartNumber,
    amount: splitPart.amount,
    fixedInterestPeriod: splitPart.fixedRatePeriod,
    hasNhg: mapToggleButton(splitPart.NHGGuarantee),
    redemptionTypeDisplayName: splitPart.repaymentType,
    amountTaxBox1: splitPart.box1,
    amountTaxBox3: splitPart.box3,
    interestPercentage: splitPart.interestPercentage,
    endDateInterestDeduction: splitPart.endDateInterestDeduction,
    duration: splitPart.hasDurationPeriodChange === 'yes' ? splitPart.newDuration : undefined,
  };
};

const mappedNewPart = (loanPart: LoanPartType): LoanPartReadDto => {
  return {
    fixedInterestPeriod: loanPart.fixedRatePeriod,
    hasNhg: mapToggleButton(loanPart.NHGGuarantee),
    redemptionTypeDisplayName: loanPart.repaymentType,
    amountTaxBox1: loanPart.box1,
    amountTaxBox3: loanPart.box3,
    interestPercentage: loanPart.interestPercentage,
    endDateInterestDeduction: loanPart.endDateInterestDeduction,
    duration: loanPart.newDuration,
    amount: loanPart.loanAmount,
  };
};
const mappedConvertedPart = (
  convertLoan: ConvertLoan,
  loanPartNumber?: number
): LoanPartReadDto => {
  return {
    originalLoanPart: loanPartNumber,
    fixedInterestPeriod: convertLoan.fixedRatePeriod,
    hasNhg: mapToggleButton(convertLoan.NHGGuarantee),
    redemptionTypeDisplayName: convertLoan.repaymentType,
  };
};

const mapPolicyInfo = (policies: Policy[]): PolicyInfo[] =>
  policies.map((policy) => ({
    policyNumber: policy.policyNumber,
    hasPolicyInNameOfInsuredPerson: mapToggleButton(policy.isPolicyForConnectedPersons),
    numberOfPolicyHolders: policy.numberOfPolicyHolders,
    numberOfInsuredPeople: policy.numberOfInsuredPersons,
  }));

const mapseparatePolicies = (separatePolicies: Policy[]) =>
  separatePolicies && separatePolicies.length > 0
    ? [
        {
          policies: mapPolicyInfo(separatePolicies ?? []),
        },
      ]
    : [];

const mapLoanPartInfo = (loanparts: LoanPartType[]): LoanPartInfo[] =>
  loanparts.map((loanPart) => ({
    loanPartNumber: loanPart.loanPartNumber,
    hasConnectedPolicy: mapToggleButton(loanPart.hasConnectedPolicy),
    policies: mapPolicyInfo(loanPart.policies ?? []),
  }));

export function mapOHAForm(form: OHAForm): DismissalJointLiabilityMutationApplication {
  const intermediaryData =
    form.intermediary ||
    form?.additionalInformation?.name ||
    form?.additionalInformation?.phoneNumber
      ? {
          ...form.intermediary,
          name: form?.additionalInformation?.name,
          phoneNumber: form?.additionalInformation?.phoneNumber,
        }
      : undefined;
  return {
    dismissalJointLiabilityMutationStateInfo: {
      loanParts: [
        ...mapLoanPartInfo(form.loan.currentLoanParts),
        ...mapseparatePolicies(form.loan.policies),
      ],
      changeLifeInsurance: mapToggleButton(form.loan.changeLifeInsurancePolicy),
      changeCurrentMortgage: mapToggleButton(form.loan.changeCurrentMortgage),
      hasBuyoutAmount: mapToggleButton(form.costAndResources.hasBuyoutAmount),
    },
    application: {
      currentRealEstates: [
        {
          currentLoans: [
            {
              currentLoanParts: [
                ...mapCurrentLoanParts(
                  form.loan.changeCurrentMortgage === 'yes' ? form.loan.currentLoanParts : undefined
                ),
                ...mapLifeInsurancePolicy(
                  form.loan.changeLifeInsurancePolicy === 'yes' ? form.loan.policies : []
                ),
              ],
              loanNumber: form.loan.loanNumber?.toString(),
              principalAmount: form.loan.amount,
              amountExtraRedemption: form.loan.extraPayOffAmount,
            },
          ],
        },
      ],
      realEstates: mapRealEstates(form.collateral),
      loan: {
        loanParts: mapLoanParts(
          form.loan.changeCurrentMortgage === 'yes'
            ? [...form.loan.currentLoanParts, ...form.loan.newLoanParts]
            : undefined
        ),
        nhgCosts: form?.costAndResources.nhgCosts,
        typeOfLoan: TypeOfLoan.DismissalJointLiability,
        loanAmountBuyout: form.costAndResources.buyoutAmount,
        typeOfBuyoutFinancing: form.costAndResources.typeOfBuyoutFinancing,
        buyoutAmountByPersonalEquity: form.costAndResources.buyoutAmountByPersonalEquity,
        consultancyCosts: form.costAndResources.consultancyCosts,
        notaryCosts: form.costAndResources.notaryCosts,
        addedPersonalEquity: form.costAndResources.addedPersonalEquity,
        additionalInformation: form?.additionalInformation?.info,
        amountRaise: form?.loan.amountRaise,
      },
      loanNumber: form.loan.loanNumber,
      moneylender: {
        commercialLabel: form.commercialLabel,
        name: form.moneylenderName,
      },
      intermediary: intermediaryData,
      applicants: mapApplicants(form.currentApplicant, form.newApplicant),
      id: form.id,
      reference: form.reference,
      notaries: null,
      product: {},
      remark: {},
      source: source,
      status: Status.New,
    },
  };
}
