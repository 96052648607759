import { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import history from '../history';

const useCustomRouterPrompt = (shouldShowPrompt: boolean) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const path = useRef<any>();
  const unblockHandle = useRef<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (shouldShowPrompt) {
      unblockHandle.current = history.block((prompt: any) => {
        path.current = prompt.location.pathname;
        setShowPrompt(true);
      });
    }
    return () => {
      unblockHandle.current && unblockHandle.current();
    };
  }, [shouldShowPrompt]);

  const handleRoute = useCallback(
    (allowRoute: boolean) => {
      if (allowRoute) {
        unblockHandle.current();
        navigate(path.current);
      }
      setShowPrompt(false);
    },
    [navigate]
  );

  return {
    handleRoute,
    showPrompt,
  };
};
export default useCustomRouterPrompt;
