import { getPoliciesFormSchema } from 'screens/Mutations/schemas/policies/policiesSchema';
import { StepName } from '../types';
import { applicantsSchema } from './applicant/applicantSchema';
import { collateralSchema as collateralBaseSchema } from './collateral/collateralSchema';
import { loanSchema as loanBaseSchema } from './financing/loanSchema';
import { costAndResourcesSchema } from './financing/costAndResources';

const getSchema = (activeStep: StepName) => {
  switch (activeStep) {
    case 'personalInfo':
    case 'contactDetails':
    case 'income':
    case 'applicants':
    case 'obligation':
      return applicantsSchema;
    case 'collateral':
      return collateralBaseSchema;
    case 'financingLoan':
      return loanBaseSchema;
    case 'financingConnectedPolicy':
      return getPoliciesFormSchema();
    case 'financingCostAndResources':
      return costAndResourcesSchema;
    default:
      return undefined;
  }
};

export default getSchema;
