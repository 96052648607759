import { Hidden, Typography } from '@mui/material';
import Header from 'components/Header';
import { FC } from 'react';
import { HeaderBox } from './HeaderTemplate.styled';

type HeaderTemplateProps = {
  sectionName: string;
  children?: React.ReactNode;
};

const HeaderTemplate: FC<HeaderTemplateProps> = ({ children, sectionName }) => {
  return (
    <Header>
      <Hidden smDown>
        <HeaderBox width="100%">
          <Typography data-testid="header-title" variant="h4">
            {sectionName}
          </Typography>
          {children}
        </HeaderBox>
      </Hidden>
      <Hidden smUp>
        <Typography data-testid="header-title-mobile" variant="h5">
          {sectionName}
        </Typography>
      </Hidden>
    </Header>
  );
};

export default HeaderTemplate;
