import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatLoanAmount, formatLoanNumber, getCustomerNames } from 'utils/global';
import StatusTag from 'components/StatusTag/StatusTag';
import { translateApplicationEndDate } from '../utils/utils';
import { Box, Fade, TableCell, Tooltip } from '@mui/material';
import { Case } from '__generated__/api-di';
import Logo from 'components/Logo';
import { Emphasize } from 'screens/templates/Lists/ListWrapper/Wrapper.styled';
import { LenderName, useClasses, InterPunt, TableRow } from '../../../Applications.styled';
import { useGoogleTagManager } from 'use/gtm';

type ApplicationListItemProps = {
  application: Case;
  onClickItem: (application: Case) => void;
};

const ApplicationListItem: FC<ApplicationListItemProps> = ({ application, onClickItem }) => {
  const { t } = useTranslation();
  const classes = useClasses();
  const tagEvent = useGoogleTagManager();

  const handleRowClick = () => {
    tagEvent({
      event: 'ButtonClick',
      btnName: 'ApplicationListItem',
    });
    onClickItem(application);
  };

  return (
    <TableRow hover onClick={handleRowClick} data-testid="concept-application-item">
      <TableCell>
        <Box
          key={application.application?.loanNumberExternal}
          display="flex"
          gap={2}
          alignItems="center"
        >
          <Logo label={application.lender?.label} size="M" />
          <Box>
            {getCustomerNames(application).length > 24 ? (
              <Tooltip
                classes={{ tooltip: `${classes.tooltip} name` }}
                title={getCustomerNames(application)}
                placement="top-start"
                TransitionComponent={Fade}
                data-testid="tooltip-customerName"
                PopperProps={{
                  popperOptions: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: '-8px, -40px',
                        },
                      },
                    ],
                  },
                }}
              >
                <Emphasize data-testid="customerName-tooltip" truncate={1}>
                  {getCustomerNames(application)}
                </Emphasize>
              </Tooltip>
            ) : (
              <Emphasize truncate={1} data-testid="customerName">
                {getCustomerNames(application)}
              </Emphasize>
            )}

            <LenderName data-testid="lenderName-amount">
              {application.lender?.name ?? ''}
              <InterPunt>‧</InterPunt>
              {formatLoanAmount(application.application!.loanAmount!)}
            </LenderName>
          </Box>
        </Box>
      </TableCell>
      <TableCell data-testid="type">{t(`type.${application.application!.type}`)}</TableCell>
      <TableCell data-testid="loanNumber">
        {application.application?.loanNumberExternal &&
          formatLoanNumber(application.application.loanNumberExternal)}
      </TableCell>
      <TableCell>{translateApplicationEndDate(application)}</TableCell>
      <TableCell>
        {application.proofItem?.amount && (
          <>
            <span data-testid="receive-ProofItem">{`${application.proofItem.received!}/`}</span>
            <span data-testid="total-ProofItem">{application.proofItem.amount!}</span>
          </>
        )}
      </TableCell>
      <TableCell sx={{ width: 218 }}>
        <StatusTag
          urgency={application.application?.priority?.urgency!}
          progress={application.application?.progress!}
          label={t(`applications.filter.${application.application?.status?.value}`)}
        />
      </TableCell>
    </TableRow>
  );
};

export default ApplicationListItem;
