import { Grid } from '@mui/material';
import { FC } from 'react';
import { Container, GridContainer } from 'components/FormStepper/FormStepper.styled';
import { FormInputDropdown, FormInputNumber, FormInputText } from 'components/Form';
import { useTranslation } from 'react-i18next';
import FormTitleText from 'components/Form/FormTitle';
import { SubTitle } from 'screens/Mutations/molecules/styled';
import { Option } from '../../../../../Mutations/utils/types';
import PlaceholderEmptyList from 'components/PlaceholderEmptyList';
import LoadingIndicator from 'components/LoadingIndicator';

type CreateClientProps = {
  relatedMoneylendersOptions: Option[];
  isLoading: boolean;
  hasMoneyLenderOptions: boolean;
};

const CreateClient: FC<CreateClientProps> = ({
  relatedMoneylendersOptions,
  isLoading,
  hasMoneyLenderOptions,
}) => {
  const { t } = useTranslation();
  const subtitle = t('clients.createClient.subtitle').split('\n');

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!hasMoneyLenderOptions) {
    return (
      <PlaceholderEmptyList
        title={t('clients.createClient.noCommercialLabelsAllowedTitle')}
        subtitle={t('clients.createClient.noCommercialLabelsAllowedSubtitle')}
      />
    );
  }

  return (
    <Container>
      <Grid item xs={12}>
        <FormTitleText title={t('clients.createClient.formTitle')} description={subtitle} />
      </Grid>
      <SubTitle variant="h5">{t('clients.createClient.customer')}</SubTitle>
      <GridContainer container direction="column">
        <Grid item xs={12} sm={6}>
          <FormInputNumber
            name="loanNumber"
            label={t('clients.createClient.loanNumberLabel')}
            placeholder={t('clients.createClient.loanNumberPlaceholder')}
            thousandSeparator
            decimalScale={0}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputDropdown
            name="commercialRelation"
            label={t('clients.createClient.moneylenderLabel')}
            placeholder={t('clients.createClient.moneylenderPlaceholder')}
            tooltip={t('clients.createClient.moneylenderTooltip')}
            options={relatedMoneylendersOptions}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputText
            name="clientName"
            maxLength={100}
            label={t('clients.createClient.customerNameLabel')}
            placeholder={t('clients.createClient.customerNamePlaceholder')}
            required
          />
        </Grid>
      </GridContainer>
    </Container>
  );
};

export default CreateClient;
