// TODO: when using Muiv5 datepicker, refactor this nonsense
/*
 * Beware workarounds involving bugs in material-ui-pickers' design.
 *
 * See https://github.com/mui-org/material-ui-pickers/issues/1358#issuecomment-628015527
 *
 * @material-ui/pickers operate on a Date, but we really want a String.
 * These funky DateUtils let @material-ui/pickers pick dates in the local
 * timezone ... but they ensure outside callers only see ISO8601 Strings.
 */

/**
 * Convert a _local-time_ value to an ISO-8601 Date string.
 *
 * For instance: given 2020-05-13T03:59:50.000Z, if we're in UTC-4,
 * return "2020-05-12".
 *
 * Why? Because material-ui selects dates in local time, not in UTC. If we
 * were to run date.toISOString(), that would convert to UTC and then
 * convert to String; but if we convert to UTC, that changes the date.
 */
export const jsDateToLocalISO8601DateString = (date: Date) => {
  const dateString = [
    String(date.getFullYear()),
    String(101 + date.getMonth()).substring(1),
    String(100 + date.getDate()).substring(1),
  ].join('-');
  if (dateString === 'NaN-aN-aN') return '';
  return dateString;
};

const captureSingleDatefromField = (date: Date, fixDate: string) => {
  const fixDateString = fixDate.split('-');
  const indexOfReplaceableDate = fixDateString.indexOf('*');
  const inputDateString = jsDateToLocalISO8601DateString(date).split('-');
  fixDateString[indexOfReplaceableDate] = inputDateString[indexOfReplaceableDate];
  return fixDateString.join('-');
};

const getDateValueFromInput = (date: Date, fixDate?: string) => {
  let dateValue;
  dateValue = jsDateToLocalISO8601DateString(date);

  if (fixDate && dateValue) {
    dateValue = captureSingleDatefromField(date, fixDate);
  }

  return dateValue;
};

export default getDateValueFromInput;
