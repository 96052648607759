import React from 'react';
import { CircularProgress } from '@mui/material/';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const LoadingIndicator = (props: any) => {
  const classes = useStyles();

  return (
    <div className={classes.root} {...props}>
      <CircularProgress />
    </div>
  );
};

export default LoadingIndicator;
