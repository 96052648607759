import { FC, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormHelperText, FormLabel } from '@mui/material';
import { FormInputProps } from '../FormInputProps';
import { InputWrapper, ToggleButton, ToggleButtonGroup } from '../Forms.styled';

type ToggleButtonOption = {
  label: string;
  value: any;
  required?: boolean;
};

export interface FormToggleButtonGroupProps extends FormInputProps {
  options: ToggleButtonOption[];
  testId?: string;
  togglePopup?: (value: boolean) => void;
}

export const FormToggleButtonGroup: FC<FormToggleButtonGroupProps> = ({
  name,
  label,
  options,
  required,
  shouldUnregister = false,
  testId,
  togglePopup,
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    shouldUnregister,
  });

  const generateToggleButtons = () =>
    options.map(({ label, value }) => (
      <ToggleButton key={label} value={value} data-testid={`btn-${value}`}>
        {label}
      </ToggleButton>
    ));

  const handleChange = useCallback(
    (e: any, value: string) => {
      onChange({ target: { value } });
      if (togglePopup) togglePopup(value === 'yes');
    },
    [onChange, togglePopup]
  );

  return (
    <InputWrapper data-testid={`formToggleButton-${testId || name}`}>
      <FormLabel required={required} data-testid={`${testId || name}-input-label`}>
        {label}
      </FormLabel>
      <ToggleButtonGroup
        exclusive
        error={error ? 1 : 0}
        data-testid={testId ?? name}
        onChange={(e, value) => handleChange(e, value)}
        value={value}
      >
        {generateToggleButtons()}
      </ToggleButtonGroup>
      <FormHelperText data-testid="error-message-input" error={!!error}>
        {error?.message}
      </FormHelperText>
    </InputWrapper>
  );
};

export default FormToggleButtonGroup;
