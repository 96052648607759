import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(6, 0),
    height: '100%',
  },
}));

const NotFound: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Typography variant="h4">{t('notFound.title')}</Typography>
        <Typography variant="subtitle1">{t('notFound.subtitle')}</Typography>
      </Container>
    </div>
  );
};

export default NotFound;
