import { styled } from '@mui/material/styles';
import { IconButton as MuiIconButton } from '@mui/material';

const IconButton = styled(MuiIconButton)({
  'display': 'flex',
  'flexWrap': 'wrap',
  '&:hover': {
    borderRadius: '30px',
  },
});

export default IconButton;
