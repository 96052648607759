import { FormLabel } from '@mui/material';
import { CalendarPickerView, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useController, useFormContext } from 'react-hook-form';
import { nl } from 'date-fns/locale';
import { FormInputProps } from '../FormInputProps';
import getDateValueFromInput from 'utils/form/validations/getDateValueFromInput';
import { InputWrapper, DatePickerText } from '../Forms.styled';

const DATE_FORMAT = 'dd-MM-yyyy';
const MASK_FORMAT = '__-__-____';

type ParsableDate = object | string | number | Date | undefined;

export interface FormInputDateProps extends FormInputProps {
  required?: boolean;
  maxDate?: ParsableDate;
  views?: CalendarPickerView[];
  minDate?: ParsableDate;
  disableFuture?: boolean;
  disablePast?: boolean;
  placeholder?: string;
  fixDate?: string;
  testId?: string;
}

const FormInputDate = ({
  name,
  shouldUnregister = false,
  label,
  required,
  maxDate,
  minDate,
  disableFuture,
  disablePast,
  views,
  placeholder = 'DD - MM - JJJJ',
  fixDate,
  testId,
}: FormInputDateProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    shouldUnregister,
  });

  return (
    <InputWrapper>
      <FormLabel required={required}>{label}</FormLabel>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
        <DatePicker
          onAccept={(date) => {
            if (!date) return;
            const selectedValue: string | number | Date = date as string | number | Date;
            const parsedDate: Date = new Date(selectedValue);
            onChange({
              target: { value: getDateValueFromInput(parsedDate, fixDate) },
            });
          }}
          views={views}
          mask={MASK_FORMAT}
          inputFormat={DATE_FORMAT}
          onChange={onChange}
          value={value || null}
          disableFuture={disableFuture}
          disablePast={disablePast}
          renderInput={(props) => (
            <DatePickerText
              {...props}
              inputProps={{
                ...props.inputProps,
                placeholder: placeholder,
              }}
              error={!!error}
              data-testid={testId ?? name}
              helperText={error?.message}
              FormHelperTextProps={{ style: { marginLeft: 0 } }}
            />
          )}
          maxDate={maxDate}
          minDate={minDate}
        />
      </LocalizationProvider>
    </InputWrapper>
  );
};

export default FormInputDate;
