import React, { FC } from 'react';
import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { Box, Button, Dialog, DialogActions, Icon, IconButton, Typography } from '@mui/material/';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  dialog: {
    alignSelf: 'flex-start',
    marginTop: 120,
    padding: theme.spacing(4),
  },
  title: {
    width: 400,
  },
  warning: {
    width: 48,
    height: 47,
    borderRadius: 8,
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeBtn: {
    marginTop: -12,
    marginRight: -12,
  },
  dialogBtns: {
    'justifyContent': 'flex-start',
    'padding': 0,
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
}));

type Props = {
  open: boolean;
  handleClose: () => void;
  onConfirm: () => void;
};

const ConfirmationDialog: FC<Props> = ({ open, handleClose, onConfirm }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      aria-labelledby="btl-confirmation-dialog"
    >
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <div className={classes.warning}>
          <Icon color="secondary">alert_triangle</Icon>
        </div>
        <IconButton className={classes.closeBtn} size="large" onClick={handleClose}>
          <Icon>close</Icon>
        </IconButton>
      </Box>
      <Box mt={2} mb={4} id="btl-confirmation-dialog">
        <Typography variant="h6" color="textPrimary">
          {t('buyToLet.confirmationDialog')}
        </Typography>
      </Box>
      <DialogActions classes={{ root: classes.dialogBtns }}>
        <Button onClick={onConfirm} variant="contained" color="primary">
          {t('buyToLet.confirmationSubmit')}
        </Button>
        <Button onClick={handleClose} variant="text" color="primary">
          {t('buyToLet.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
