import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StateProps = {
  prevLocation?: string;
};
const initialState: StateProps = {
  prevLocation: undefined,
};
const rootSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocation: (state, action: PayloadAction<string>) => {
      state.prevLocation = action.payload;
    },
  },
});

export default rootSlice.reducer;

export const { setLocation } = rootSlice.actions;
