import { useGroup } from 'use/group';
import { useQuery } from 'react-query';
import { getClientDataFiles } from 'api/downloads';

const useQueryClientDataDownloadFiles = () => {
  const group = useGroup();
  const enabled = Boolean(group);

  return useQuery(['CVS-download-list'], () => getClientDataFiles(group), { enabled });
};

export { useQueryClientDataDownloadFiles };
