import { FC, useCallback } from 'react';
import { FormInputProps } from '../FormInputProps';
import { NumberFormatValues, PatternFormat } from 'react-number-format';
import { FormLabel, FormHelperText } from '@mui/material';
import { useFormContext, useController } from 'react-hook-form';
import { InputWrapper, TextInput } from '../Forms.styled';

interface FormInputPhoneNumberProps extends FormInputProps {
  placeholder?: string;
  defaultValue?: string | number;
  required?: boolean;
  testId?: string;
  format?: string;
}

export const FormInputPhoneNumber: FC<FormInputPhoneNumberProps> = ({
  name,
  label,
  placeholder,
  required,
  defaultValue,
  disabled,
  shouldUnregister = false,
  testId,
  format,
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    shouldUnregister,
  });

  const returnValidValue = (value: number | undefined) => {
    if (value !== undefined) return value;
    if (defaultValue !== undefined) return defaultValue;
    return '';
  };

  const handleChange = useCallback(
    (values: any): any => {
      onChange(values.value);
    },
    [onChange]
  );

  const validateInput = (values: NumberFormatValues) => {
    const { value } = values;
    if (value.length < 16) return true;
    return false;
  };

  return (
    <InputWrapper data-testid={`${testId || name}-input-wrapper`}>
      <FormLabel data-testid={`${testId || name}-input-label`} required={required}>
        {label}
      </FormLabel>
      <PatternFormat
        type="tel"
        valueIsNumericString
        customInput={TextInput}
        inputProps={{
          'data-testid': testId ?? name,
        }}
        format={format || '###############'}
        variant="outlined"
        size="small"
        allowEmptyFormatting
        isAllowed={validateInput}
        onValueChange={handleChange}
        value={returnValidValue(value)}
        error={!!error}
        placeholder={placeholder}
        disabled={disabled}
      />
      <FormHelperText data-testid="error-message-input" error={!!error}>
        {error?.message}
      </FormHelperText>
    </InputWrapper>
  );
};

export default FormInputPhoneNumber;
