import type { FC } from 'react';
import { AccordionSummary, Grid, AccordionDetails, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { LoanPartType } from '../../../../utils/types';
import { ExpandMore } from '@mui/icons-material';
import Policies from './components/Policies';
import { CustomNumberFormat } from 'components/CustomNumberFormat';
import FormTitleText from 'components/Form/FormTitle';
import { ConnectedPoliciesWrapper, LoanPartsGrid, TitleWrapper } from './ConnectedPolicies.styled';
import { useFormContext } from 'react-hook-form';
import { Accordion } from 'screens/Mutations/molecules/Accordion/Accordion';

export const ConnectedPolicies: FC = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const loanNumber = watch('conversionQuestionnaire.loanNumber');
  const loanParts: LoanPartType[] = watch('loanParts');

  // TODO: because the index is increased regardless of policy,
  // this means that when the there are some loanparts
  // without policies, the index is out of sync. This needs to be refactored,
  // (This is now fixed in OHA because the policies are inside the loanparts)
  let testindex = -1;

  return (
    <ConnectedPoliciesWrapper data-testid="connectedPolicies-wrapper">
      <FormTitleText
        title={t('directMutations.policies.title')}
        description={t('directMutations.policies.subtitle')}
      />

      <LoanPartsGrid>
        {loanParts.map((loanPart: LoanPartType, i) => {
          if (!loanPart.hasConnectedPolicy || loanPart.hasConnectedPolicy === 'no') return null;
          testindex++;

          return (
            <Accordion
              key={loanPart.loanPartNumber}
              defaultExpanded
              data-testid="connectedPolicies-accordion"
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel${i}-content`}
                id={`panel${i}-content`}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TitleWrapper>
                      <Typography variant="h5">
                        {t('directMutations.form.loanPart')} {loanPart.loanPartNumber}
                      </Typography>
                    </TitleWrapper>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      data-testid={`conversionMutation-summary-${i}-loanPartNumber`}
                    >
                      {t('directMutations.form.loanPartNumber')}:{' '}
                      {`${loanNumber}.${loanPart.loanPartNumber}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      data-testid={`conversionMutation-summary-${i}-repaymentType`}
                    >
                      {t('directMutations.form.repaymentType')}: {loanPart.repaymentType}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      data-testid={`conversionMutation-summary-${i}-loanAmount`}
                    >
                      {t('directMutations.form.loanAmount')}:{' '}
                      <CustomNumberFormat value={loanPart.loanAmount} />
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {loanPart.loanPartNumber && (
                  <Policies loanPartNumber={loanPart.loanPartNumber} loanPartIndex={testindex} />
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </LoanPartsGrid>
    </ConnectedPoliciesWrapper>
  );
};

export default ConnectedPolicies;
