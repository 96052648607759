import { Hidden, IconButton, Icon, Button } from '@mui/material';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { ProofItem } from 'types/ProofItem';
import { Case } from '__generated__/api-di';
import { buttonStyles } from '../styling';

type Props = {
  onClick: (proofItem: ProofItem) => void;
  proofItem: ProofItem;
  // eslint-disable-next-line react/no-unused-prop-types
  $case?: Case;
};

const DownloadButton = ({ onClick, proofItem }: Props) => {
  const { t } = useTranslation();
  const classes = buttonStyles();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e: any) => {
    e.stopPropagation();
    onClick(proofItem);
  };

  if (!proofItem || !proofItem.documentId) return null;

  return (
    <>
      <Hidden mdUp>
        <IconButton
          color="default"
          onClick={handleClick}
          title={t('applications.detail.view')}
          data-testid="download-button"
        >
          <Icon>download</Icon>
        </IconButton>
      </Hidden>
      <Hidden mdDown>
        <Button
          color="primary"
          onClick={handleClick}
          className={classes.button}
          data-testid="download-button"
        >
          {t('applications.detail.view')}
        </Button>
      </Hidden>
    </>
  );
};

export default DownloadButton;
