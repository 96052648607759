import { CardSection, CardRow } from 'components/SummaryCard';
import { format } from 'date-fns';
import { t } from 'i18next';
import { LoanPartType } from 'screens/Mutations/utils/types';
import { formatAmount } from 'utils/formatAmount';
import { formatYesNo } from 'utils/formatYesNo/formatYesNo';

export const renderSplitLoan = (part: LoanPartType) =>
  part.splitLoan?.map((split, i) => (
    <CardSection
      key={`${split.box1}-${i}`}
      title={`${t('directMutations.form.part')} ${i + 1} ${t(
        'directMutations.summary.loanParts.split'
      )}`}
    >
      <CardRow
        name={t('directMutations.summary.loanParts.changeDuration')}
        value={formatYesNo(split.hasDurationPeriodChange)}
      />
      <CardRow
        name={t('directMutations.summary.loanParts.newDuration')}
        value={split.newDuration}
      />
      <CardRow name={t('directMutations.form.newRepaymentType')} value={split.repaymentType} />
      <CardRow name={t('directMutations.form.loanAmount')} value={formatAmount(split.amount)} />
      <CardRow
        name={t('directMutations.obligation.interestPercentage')}
        value={`${split.interestPercentage}%`}
      />
      <CardRow
        name={t('directMutations.obligation.fixedInterestPeriod')}
        value={split.fixedRatePeriod}
      />
      <CardRow
        name={t('directMutations.obligation.amountTaxBox1')}
        value={formatAmount(split.box1)}
      />
      <CardRow
        name={t('directMutations.loanParts.endDateInterestDeduction')}
        value={
          split.endDateInterestDeduction
            ? format(new Date(split.endDateInterestDeduction), 'dd-MM-yyyy')
            : undefined
        }
      />
      <CardRow
        name={t('directMutations.obligation.amountTaxBox3')}
        value={formatAmount(split.box3)}
      />
      <CardRow
        name={t('directMutations.summary.loanParts.nhg')}
        value={formatYesNo(split.NHGGuarantee)}
      />
    </CardSection>
  ));
