import { FC } from 'react';

import { DownloadNotificationNumber } from '../NavDrawer.styled';
import { useQueryClientDataDownloadFiles } from 'use/downloads';

const DownloadsCounter: FC = () => {
  const { data: clientFiles } = useQueryClientDataDownloadFiles();

  const clientFilesFilted = Array.isArray(clientFiles)
    ? clientFiles.filter((fileProps) => !fileProps?.dateAccessed)
    : [];

  const downloadsNumber = clientFilesFilted.length;

  return downloadsNumber === 0 ? null : (
    <DownloadNotificationNumber smallnumber={downloadsNumber > 9 ? 1 : 0}>
      {downloadsNumber}
    </DownloadNotificationNumber>
  );
};

export default DownloadsCounter;
