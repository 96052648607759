import { Step, StepName } from '../types';

type Navigation = {
  next?: StepName;
  prev?: StepName;
  nextInvalid?: StepName;
};

type Config = {
  initial: StepName;
  navigations: Record<StepName, Navigation>;
};

const config: Config = {
  initial: 'applicants',
  navigations: {
    applicants: {
      next: 'collateral',
    },
    personalInfo: {
      next: 'contactDetails',
      prev: 'applicants',
    },
    contactDetails: {
      next: 'income',
      prev: 'personalInfo',
    },
    income: {
      next: 'obligation',
      prev: 'contactDetails',
    },
    obligation: {
      next: 'applicants',
      prev: 'income',
    },
    collateral: {
      next: 'financingCostAndResources',
      prev: 'applicants',
    },
    financingCostAndResources: {
      next: 'financingLoan',
      prev: 'collateral',
    },
    financingLoan: {
      next: 'financingConnectedPolicy',
      prev: 'financingCostAndResources',
    },
    financingConnectedPolicy: {
      next: 'additionalInfo',
      prev: 'financingLoan',
     },
    additionalInfo: {
      prev: 'financingConnectedPolicy',
      next: 'summary',
    },
    summary: {
      prev: 'additionalInfo',
    },
  },
};

const navigator = (steps: Record<string, Step>, activeStep: Step) => {
  const indexOf = (name: string) => {
    const all = Object.values(steps);
    const filtered = all.filter((s) => s.name === name);
    const step =
      filtered.length > 1 ? filtered.find((s) => s.index === activeStep.index) : filtered[0];
    return step ? all.indexOf(step) : 0;
  };

  const get = (step: Step) => config.navigations[step.name];

  const goTo = (input: 'next' | 'prev'): number => {
    const current = get(activeStep);

    const index = indexOf(!current || !current[input] ? activeStep.name : current[input]!);

    return index || 0;
  };

  return { goTo, get, indexOf };
};

export default navigator;
