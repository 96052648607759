import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import formatDate from 'utils/formatDate';
import { BtlApplicant } from 'types/BtlFormApplication';

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    '& td': {
      borderBottom: 'none',
    },
    '&.error': {
      'backgroundColor': theme.palette.danger.light,
      '& :last-child': {
        color: theme.palette.danger.main,
      },
    },
  },
  errorIcon: {
    'float': 'right',
    'display': 'none',
    '.error &': {
      display: 'block',
    },
  },
  loanPartTitle: {
    marginBottom: theme.spacing(2),
  },
}));

type BuyToLetApplicantPersonalSummaryProps = {
  applicant: BtlApplicant;
  applicantIndex: number;
  onClickField: (field: string) => void;
  errorMessage: (id: string, category: string) => void;
};

const BuyToLetApplicantPersonalSummary: FC<BuyToLetApplicantPersonalSummaryProps> = ({
  applicant,
  applicantIndex,
  onClickField,
  errorMessage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const form = 'buyToLetForm.newApplicantNaturalPerson';
  const existingMortgageQuestions = 'buyToLetForm.buyToLetExistingMortgageQuestions';

  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(
              errorMessage(`[${applicantIndex}].hasExistingMortgageByMoneylender`, 'applicants')
            ),
          })}
        >
          <TableCell>
            {t(`${existingMortgageQuestions}.hasExistingMortgageByMoneylender`)}
          </TableCell>
          <TableCell onClick={() => onClickField('hasExistingMortgageByMoneylender')}>
            {applicant.hasExistingMortgageByMoneylender === false
              ? t(`${form}.no`)
              : applicant.hasExistingMortgageByMoneylender === true
              ? t(`${form}.yes`)
              : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        {applicant.hasExistingMortgageByMoneylender === true && (
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage(`[${applicantIndex}].existingLoanNumber`, 'applicants')),
            })}
          >
            <TableCell>
              {t(`${existingMortgageQuestions}.existingLoanNumber`)}
              <span className="required">*</span>
            </TableCell>
            <TableCell onClick={() => onClickField('existingLoanNumber')}>
              {applicant.existingLoanNumber}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
        )}
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].isMortgageRejected`, 'applicants')),
          })}
        >
          <TableCell>{t(`${existingMortgageQuestions}.isMortgageRejected`)}</TableCell>
          <TableCell onClick={() => onClickField('isMortgageRejected')}>
            {applicant.isMortgageRejected === false
              ? t(`${form}.no`)
              : applicant.isMortgageRejected === true
              ? t(`${form}.yes`)
              : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        {applicant.isMortgageRejected === true && (
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(
                errorMessage(`[${applicantIndex}].reasonMortgageRejection`, 'applicants')
              ),
            })}
          >
            <TableCell>
              {t(`${existingMortgageQuestions}.reasonMortgageRejection`)}
              <span className="required">*</span>
            </TableCell>
            <TableCell onClick={() => onClickField('reasonMortgageRejection')}>
              {applicant.reasonMortgageRejection}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
        )}

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].gender`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.gender`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('gender')}>
            {applicant.gender ? t(`${form}.${applicant.gender}`) : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].initials`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.initials`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('initials')}>
            {applicant.initials}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].firstName`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.firstName`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('firstName')}>
            {applicant.firstName}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].middleName`, 'applicants')),
          })}
        >
          <TableCell>{t(`${form}.middleName`)}</TableCell>
          <TableCell onClick={() => onClickField('middleName')}>
            {applicant.middleName}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].lastName`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.lastName`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('lastName')}>
            {applicant.lastName}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].civilStatus`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.civilStatus`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('civilStatus')}>
            {applicant.civilStatus ? t(`${form}.${applicant.civilStatus}`) : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].dateOfBirth`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.dateOfBirth`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('dateOfBirth')}>
            {applicant.dateOfBirth && formatDate(applicant.dateOfBirth)}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].nationality`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.nationality`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('nationality')}>
            {applicant.nationality ? t(`${form}.${applicant.nationality}`) : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].typeOfIdentification`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.typeOfIdentification`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('typeOfIdentification')}>
            {applicant.typeOfIdentification ? t(`${form}.${applicant.typeOfIdentification}`) : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].identificationNumber`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.identificationNumber`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('identificationNumber')}>
            {applicant.identificationNumber}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].typeOfPermit`, 'applicants')),
          })}
        >
          <TableCell>{t(`${form}.typeOfPermit`)}</TableCell>
          <TableCell onClick={() => onClickField('typeOfPermit')}>
            {applicant.typeOfPermit ? t(`${form}.${applicant.typeOfPermit}`) : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].typeOfPermit`, 'applicants')),
          })}
        >
          <TableCell>{t(`${form}.permitNumber`)}</TableCell>
          <TableCell onClick={() => onClickField('permitNumber')}>
            {applicant.permitNumber}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default BuyToLetApplicantPersonalSummary;
