import { number, object, string } from 'yup';
import { errorMessages, getTypeOfOtherIncomeOptions } from 'screens/Mutations/constants';
import isNil from 'utils/predicates/isNil';
import { defaultDate } from 'utils/form/validations';

const otherIncomeTypeValues = getTypeOfOtherIncomeOptions().map((f) => f.value);

export const otherIncomeSchema = object({
  typeOfOtherIncome: string()
    .nullable(true)
    .oneOf(otherIncomeTypeValues)
    .required(errorMessages.required),
  endDate: defaultDate.isFutureDate(errorMessages.FutureDate),
  incomeAmount: number().when('typeOfOtherIncome', {
    is: (v: any) => !isNil(v),
    then: number()
      .nullable(true)
      .min(1)
      .required(errorMessages.required)
      .typeError(errorMessages.number),
  }),
});
