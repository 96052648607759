import { http, HEADER_SELECTED_GROUP_CLAIM } from '../http';
import { BtlApplication } from '../types/BtlFormApplication';
import { toNewBtlApplication } from '../utils/buyToLet';
import {
  ApplicationReadDto,
  BuyToLetApplicationRequest,
  CreateBuyToLetEventResponse,
  Orientation,
  QuestionData,
} from '__generated__/api';
import { ValidationResponse, WizardData } from 'types/Wizard';
import { validateNewBtlApplication } from './applicationRegistration';

export type BuyToLetApplicationInfo = {
  application: BtlApplication;
  orientation?: Orientation;
  extraInfo: {
    activeStep?: number;
    activeSubStep?: number;
    latestStepSeen?: number;
    latestSubStepSeen?: {
      [key: number]: number;
    };
  };
};

export async function getBuyToLetApplicationRequestDetailsInfo(
  id: number,
  group: string | null
): Promise<BuyToLetApplicationInfo | undefined> {
  const url = `/api/buytolet/${id}`;
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };

  try {
    const response = await http.get<BuyToLetApplicationRequest>(url, { headers });

    const data = response.data
      ? {
          application: toNewBtlApplication(response.data.application!!),
          extraInfo: {
            activeStep: response.data.currentStep ?? undefined,
            activeSubStep: response.data.currentSubStep ?? undefined,
            latestStepSeen: response.data.latestStepSeen ?? undefined,
            latestSubStepSeen: response.data.latestSubStepSeen ?? undefined,
          },
        }
      : undefined;

    return data;
  } catch (error: any) {
    if (error.response?.status === 404) {
      return undefined;
    }
    throw error; // re-throw the error unchanged
  }
}

export async function saveBtlApplication(
  applicationInfo: BuyToLetApplicationInfo,
  group: string | null
): Promise<{ data: BuyToLetApplicationInfo; status: number }> {
  const url = '/api/buytolet';

  const saveModel = {
    application: applicationInfo.application,
    orientation: applicationInfo.orientation,
    currentStep: applicationInfo.extraInfo.activeStep,
    currentSubStep: applicationInfo.extraInfo.activeSubStep,
    latestStepSeen: applicationInfo.extraInfo.latestStepSeen,
    latestSubStepSeen: applicationInfo.extraInfo.latestSubStepSeen,
  };

  const response = applicationInfo.application.id
    ? await http.put<BuyToLetApplicationRequest>(url, JSON.stringify(saveModel), {
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
          [HEADER_SELECTED_GROUP_CLAIM]: group,
        },
      })
    : await http.post<BuyToLetApplicationRequest>(url, JSON.stringify(saveModel), {
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
          [HEADER_SELECTED_GROUP_CLAIM]: group,
        },
      });

  return {
    data: {
      application: toNewBtlApplication(response.data.application!!),
      extraInfo: {
        activeStep: response.data.currentStep ?? undefined,
        activeSubStep: response.data.currentSubStep ?? undefined,
        latestStepSeen: response.data.latestStepSeen ?? undefined,
        latestSubStepSeen: response.data.latestSubStepSeen ?? undefined,
      },
    },
    status: response.status,
  };
}

export function submitBtlApplication(application: ApplicationReadDto, group: string | null) {
  const url = '/api/buytolet/submit';

  return http.post<CreateBuyToLetEventResponse>(url, JSON.stringify(application), {
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json',
      [HEADER_SELECTED_GROUP_CLAIM]: group,
    },
  });
}

export async function getBuyToLetConfigurationForOrientationPage(): Promise<QuestionData> {
  const url = '/api/buytolet/orientation';
  const response = await http.get<QuestionData>(url);
  return response.data;
}

export async function validateBuyToLetOrientationPage(
  wizardData: WizardData,
  group: string | null
) {
  const url = '/api/buytolet/orientation/validate';
  try {
    const response = await http.post<ValidationResponse>(url, JSON.stringify(wizardData), {
      headers: {
        Accept: '*/*',
        [HEADER_SELECTED_GROUP_CLAIM]: group,
      },
    });
    return { data: response.data, status: 200 };
  } catch (err: any) {
    const {
      response: { status },
    } = err;
    return { status: status };
  }
}

export function validateNewBuyToLetApplication(
  group: string | null,
  newBtlApplication: BtlApplication
) {
  return validateNewBtlApplication(newBtlApplication, group);
}
