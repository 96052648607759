import { FC, useState, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import TitleBar from '../TitleBar';
import BottomNavigation from '../BottomNavigation';
import Loan from './components/Loan';
import { FormStepProps, LoanFormType, OHAForm } from 'screens/Mutations/utils/types';
import { RootState } from 'store';
import { saveLoan } from 'store/ohaFormReducer';
import { loanSchema } from '../../schema/loan';
import { mapPoliciesToLoan } from '../../../../templates/Loan/utils';
import { useOhaFormStep } from 'screens/Mutations/hooks/useOhaFormStep';
import { mapOHAForm } from 'screens/Mutations/utils/mappers/oha/mapFormToApi';
import FormTemplate from 'components/Templates/FormTemplate';
import FormStepper from '../FormStepper';
import { Form } from 'components/Molecules/components/Form/Form.styled';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

const LoanForm: FC<FormStepProps> = ({ title, subtitle }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [snackbar, setSnackbar] = useState<{ status: string; name: string } | undefined>();
  const ohaFormState = useSelector((state: RootState) => state.ohaForm);

  const formMethods = useForm<LoanFormType>({
    defaultValues: {
      ...ohaFormState.loan,
      currentLoanParts: ohaFormState.loan.currentLoanParts?.map((loanPart) => ({
        ...loanPart,
        convertLoan: { ...loanPart.convertLoan },
        splitLoan: loanPart.splitLoan?.map((splitLoan) => ({ ...splitLoan })),
      })),
      newLoanParts: ohaFormState.loan.newLoanParts?.map((loanPart) => ({
        ...loanPart,
      })),
    },
    resolver: yupResolver(loanSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = formMethods;

  const [
    amount,
    loanNumber,
    extraPayOffAmount,
    changeCurrentMortgage,
    changeLifeInsurancePolicy,
    currentLoanParts,
    newLoanParts,
    policies,
    amountRaise,
  ] = watch([
    'amount',
    'loanNumber',
    'extraPayOffAmount',
    'changeCurrentMortgage',
    'changeLifeInsurancePolicy',
    'currentLoanParts',
    'newLoanParts',
    'policies',
    'amountRaise',
  ]);

  const loanValues: LoanFormType = useMemo(
    () => ({
      amount,
      loanNumber,
      extraPayOffAmount,
      changeCurrentMortgage,
      changeLifeInsurancePolicy,
      currentLoanParts,
      newLoanParts,
      policies,
      amountRaise,
    }),
    [
      amount,
      loanNumber,
      extraPayOffAmount,
      changeCurrentMortgage,
      changeLifeInsurancePolicy,
      currentLoanParts,
      newLoanParts,
      policies,
      amountRaise,
    ]
  );
  const completeForm: OHAForm = {
    ...ohaFormState,
    loan: loanValues,
  };

  const onSaveError = () => handleNotification('error', 'submit');

  const { save, isSaving, goToNextStep } = useOhaFormStep({ onSaveError });

  const onSubmit = async () => {
    if (isDirty) {
      const newLoan = mapPoliciesToLoan(loanValues);
      dispatch(saveLoan(newLoan));
      await save(mapOHAForm(completeForm));
    }

    goToNextStep();
  };

  const dispatchForm = useCallback(() => {
    const newLoan = mapPoliciesToLoan(loanValues);
    dispatch(saveLoan(newLoan));
  }, [loanValues, dispatch]);

  const handleNotification = (status: string, name: string) => setSnackbar({ status, name });

  const handleOnClose = useCallback(() => setSnackbar(undefined), []);

  return (
    <>
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormTemplate
            header={
              <TitleBar
                onResult={handleNotification}
                formState={completeForm}
                resetForm={reset}
                isFormDirty={isDirty}
                dispatchCurrentForm={dispatchForm}
              />
            }
            sidebar={<FormStepper />}
            footer={
              <BottomNavigation dispatchCurrentForm={dispatchForm} isLoadingSubmit={isSaving} />
            }
          >
            <Loan title={title} subtitle={subtitle} />
          </FormTemplate>
        </Form>
      </FormProvider>
      {snackbar && (
        <CustomSnackbar
          severity={snackbar.status === 'success' ? 'success' : 'error'}
          isOpen={!!snackbar}
          handleClose={handleOnClose}
          message={t(`directMutations.snackbar.${snackbar.name}.${snackbar.status}`)}
        />
      )}
    </>
  );
};

export default LoanForm;
