import { object, string, number } from 'yup';
import { errorMessages } from 'utils/form/errorMessages';
import { TypeOfFinancing } from '__generated__/api';

export const costAndResourcesSchema = object().shape({
  hasBuyoutAmount: string().typeError(errorMessages.required).required(errorMessages.required),
  buyoutAmount: number()
    .nullable(true)
    .when('hasBuyoutAmount', {
      is: (val: any) => val === 'yes',
      then: number().typeError(errorMessages.number).required(errorMessages.required),
    }),
  typeOfBuyoutFinancing: string()
    .nullable()
    .when('hasBuyoutAmount', {
      is: (val: any) => val === 'yes',
      then: string().required(errorMessages.required),
    }),
  buyoutAmountByPersonalEquity: number()
    .nullable(true)
    .when('hasBuyoutAmount', {
      is: (val: any) => val === 'yes',
      then: number()
        .nullable(true)
        .when('typeOfBuyoutFinancing', {
          is: (val: any) => val === TypeOfFinancing.PersonalEquityAndRaiseMortgage,
          then: number().typeError(errorMessages.number).required(errorMessages.required),
        }),
    }),
  consultancyCosts: number().typeError(errorMessages.number).required(errorMessages.required),
  notaryCosts: number().typeError(errorMessages.number).required(errorMessages.required),
  addedPersonalEquity: number().typeError(errorMessages.number).required(errorMessages.required),
});
