import { useQuery } from 'react-query';
import { http, HEADER_SELECTED_GROUP_CLAIM } from '../http';
import { ProofItem } from '../types/ProofItem';
import { queryClient } from '../config/react-query';
import { ApplicationStatus } from 'constants/index';
import { Case } from '__generated__/api-di';
import { useGroup } from './group';
import { DataUpdateFunction } from 'react-query/types/core/utils';

async function getProofItems(
  group: string | null,
  commercialLabel: string,
  loanNumber: number,
  applicationIndexNumber: number
): Promise<ProofItem[]> {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const response = await http.get(
    `/api/v1/proofitems/${commercialLabel}/${loanNumber}/${applicationIndexNumber}`,
    { headers }
  );

  return response.data.proofItems;
}

async function CreateProofItemResponse(group: string | null, application: Case) {
  let proofItems: ProofItem[] | [] = [];

  proofItems = await getProofItems(
    group,
    application.lender?.label ?? '',
    application.application?.loanNumberExternal ?? 0,
    application.application?.index ?? 0
  );

  return proofItems.filter((proofItem) => {
    return (
      (application.application?.status?.value === ApplicationStatus.inProgress &&
        proofItem.utmostDeliveryMoment === 'BeforeProposal') ||
      application.application?.status?.value !== ApplicationStatus.inProgress
    );
  });
}

export function useQueryProofItems(application: Case, includeDocumentId: boolean = false) {
  const group = useGroup();

  return useQuery(
    [
      'proof-items',
      group,
      application.lender?.label,
      application.application?.loanNumberExternal,
      application.application?.index,
    ],
    () => {
      return CreateProofItemResponse(group, application)
        .then((response) => response)
        .catch(() => {
          return [];
        });
    },
    {
      enabled: !(
        application.application?.status?.value === ApplicationStatus.new ||
        application.application?.status?.value === ApplicationStatus.rejected ||
        application.application?.status?.value === ApplicationStatus.terminated
      ),
    }
  );
}
export const optimisticUpdateUploadedProofItemStatus = (
  group: string | null,
  commercialLabel: string,
  loanNumberExternal: number,
  applicationIndexNumber: number,
  selectedProofItem: ProofItem
) => {
  const queryKeyBase = [
    'proof-items',
    group,
    commercialLabel,
    loanNumberExternal,
    applicationIndexNumber,
  ];
  queryClient.cancelQueries(queryKeyBase);

  queryClient.setQueryData<DataUpdateFunction<ProofItem[], ProofItem[]>>(
    queryKeyBase,
    (proofItemsFromCache: ProofItem[]) => {
      const proofItemsUpdated = proofItemsFromCache.map((proofItemFromCache) => {
        if (JSON.stringify(proofItemFromCache) === JSON.stringify(selectedProofItem)) {
          return {
            ...proofItemFromCache,
            status: 'ReceivedForApproval',
            dateReceived: new Date().toISOString(),
          };
        }
        return proofItemFromCache;
      });
      return proofItemsUpdated;
    }
  );
  queryClient.invalidateQueries(queryKeyBase);
};

async function getDocument(
  group: string,
  commercialLabel: string,
  loanNumber: number,
  documentId: number
) {
  const response = await http({
    method: 'get',
    url: `/api/v1/proofitems/${commercialLabel}/${loanNumber}/document/${documentId}`,
    responseType: 'arraybuffer',
    headers: { [HEADER_SELECTED_GROUP_CLAIM]: group },
  });

  const octetStreamMime = 'application/octet-stream';
  const contentType = response.headers['content-type'] || octetStreamMime;
  return new Blob([response.data], { type: contentType });
}

export function useQueryDocument(commercialLabel: string, loanNumber: number, documentId: number) {
  const group = useGroup();
  const { status, data, refetch } = useQuery(
    ['documents', group, commercialLabel, loanNumber, documentId],
    () => getDocument(group ?? '', commercialLabel, loanNumber, documentId),
    { enabled: false }
  );
  return {
    status,
    data,
    refetch,
  };
}
