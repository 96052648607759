import { FC, Fragment } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DetailsRow } from 'lib/ClientDataDetails/Fields';
import { formatAddressWithPerson } from 'utils/formatAddress';
import TemplateDetails from './TemplateDetails';
import { ClientDetailsPerson } from 'types/ClientDetailsModel';
import formatFullName from 'utils/formatFullName/formatFullName';

type ContactDetailsProps = {
  persons: ClientDetailsPerson[];
};

const ContactDetails: FC<ContactDetailsProps> = ({ persons }) => {
  const { t } = useTranslation();
  const translationsRoot = 'clients.clientData.contactDetails';
  if (!persons.length) {
    return null;
  }
  return (
    <Grid container data-testid="contactDetails-container">
      {persons.map((person, index) => {
        const phoneNumbers = person.contact?.phoneNumber;
        const emailAddresses = person.contact?.emailAddress;
        const fullName = formatFullName(
          person.natural?.name?.initials,
          person.natural?.name?.lastNamePrefix,
          person.natural?.name?.lastName
        );
        return (
          <Grid xs={12} md={6} item px={3} py={2} key={`${fullName}-${index}`}>
            <TemplateDetails title={fullName}>
              <DetailsRow label={t(`${translationsRoot}.emailAddress`)}>
                <SingleValue value={emailAddresses} titleRoot={`${translationsRoot}.emailType`} />
              </DetailsRow>
              <DetailsRow label={t(`${translationsRoot}.phoneNumber`)}>
                <SingleValue value={phoneNumbers} titleRoot={`${translationsRoot}.phoneType`} />
              </DetailsRow>
              <DetailsRow
                type="string"
                label={t(`${translationsRoot}.address`)}
                value={formatAddressWithPerson(person) ?? '-'}
              />
            </TemplateDetails>
          </Grid>
        );
      })}
    </Grid>
  );
};

type SingleValueProps = {
  value?: string;
  titleRoot: string;
};

const SingleValue = ({ value, titleRoot }: SingleValueProps) => {
  const { t } = useTranslation();

  if (!value) {
    return <>-</>;
  }

  return (
    <>
      <Fragment key={`${titleRoot}${value}`}>
        <span title={t(`${titleRoot}${value?.toLowerCase()}`)}>{value}</span>
        <br />
      </Fragment>
    </>
  );
};

export default ContactDetails;
