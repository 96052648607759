import { Grid } from '@mui/material';
import { FC } from 'react';
import { FormInputDate, FormInputCurrency } from 'components/Form';
import { formFields, ValuationTypes } from '../../../constants';
import { Warning } from './SubForm.styled';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type SubFormProps = {
  valuationType: ValuationTypes;
};

const SubForm: FC<SubFormProps> = ({ valuationType }) => {
  const formData = formFields[valuationType];
  const date = useWatch({ name: 'collateralValueQuestionnaire.date' });
  const { t } = useTranslation();

  const dateWarning = () => {
    const today = new Date();
    const halfYearAgo = new Date(today.setMonth(today.getMonth() - 6));
    const result =
      valuationType === 'valuationReport' &&
      date &&
      date > new Date(1000, 1, 1) &&
      date < halfYearAgo;
    return result;
  };

  return (
    <Grid item data-testid="subForm-wrapper" key={valuationType}>
      <FormInputDate
        name={formData.inputDate.name}
        label={t(formData.inputDate.label)}
        placeholder={formData?.inputDate.placeholder}
        views={formData?.inputDate.views}
        required
        fixDate={formData?.inputDate.fixDate}
        maxDate={formData?.inputDate.maxDate}
        shouldUnregister
        disableFuture
      />
      {dateWarning() && <Warning>Let op! Het taxatierapport is ouder dan 6 maanden</Warning>}
      <FormInputCurrency
        name={formData.inputNumber.name}
        label={t(formData.inputNumber.label)}
        shouldUnregister
        required
      />
    </Grid>
  );
};
export default SubForm;
