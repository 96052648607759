import currency from 'currency.js';
import { LoanFormType, LoanPartType } from 'screens/Mutations/utils/types';

export const formatIncome = (income?: number) =>
  currency(income ?? 0, {
    separator: '.',
    decimal: ',',
    symbol: '€\u00A0',
    precision: 2,
  }).format();

const hasConnectedPolicy = ({ hasConnectedPolicy }: LoanPartType) => hasConnectedPolicy === 'yes';

export const hasPolicy = (loan: LoanFormType): boolean =>
  loan.changeLifeInsurancePolicy === 'yes' ||
  (loan.changeCurrentMortgage === 'yes' && loan.currentLoanParts.some(hasConnectedPolicy));
