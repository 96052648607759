import { FC, useState, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TitleBar from '../TitleBar';
import ContactDetails from '../../../../templates/ContactDetails';
import BottomNavigation from '../BottomNavigation';
import { yupResolver } from '@hookform/resolvers/yup';
import type { ApplicantFormStepProps, ContactDetailsFormType } from '../../../../utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { saveContactDetails } from 'store/ohaFormReducer';
import { contactDetailsSchema } from '../../../../schemas/contactDetails';
import { useOhaFormStep } from 'screens/Mutations/hooks/useOhaFormStep';
import { mapOHAForm } from 'screens/Mutations/utils/mappers/oha/mapFormToApi';
import FormTemplate from 'components/Templates/FormTemplate';
import FormStepper from '../FormStepper';
import { Form } from 'components/Molecules/components/Form/Form.styled';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

const ContactDetailsForm: FC<ApplicantFormStepProps> = ({ title, subtitle, rootFormName }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [snackbar, setSnackbar] = useState<{ status: string; name: string }>();
  const ohaFormState = useSelector((state: RootState) => state.ohaForm);
  const { firstNames, insertion, surname } = ohaFormState[rootFormName].personalInfo;
  const fullName = `${firstNames || ''} ${insertion || ''} ${surname || ''}`;

  const formMethods = useForm<ContactDetailsFormType>({
    defaultValues: { ...ohaFormState[rootFormName].contactDetails },
    resolver: yupResolver(contactDetailsSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = formMethods;

  const [street, houseNumber, houseNumberSuffix, postalCode, city, country, phoneNumber, email] =
    watch([
      'street',
      'houseNumber',
      'houseNumberSuffix',
      'postalCode',
      'city',
      'country',
      'phoneNumber',
      'email',
    ]);

  const contactDetailsValues: ContactDetailsFormType = useMemo(
    () => ({
      street,
      houseNumber,
      houseNumberSuffix,
      postalCode,
      city,
      country,
      phoneNumber,
      email,
    }),
    [street, houseNumber, houseNumberSuffix, postalCode, city, country, phoneNumber, email]
  );

  const completeForm = {
    ...ohaFormState,
    [rootFormName]: {
      personalInfo: { ...ohaFormState[rootFormName].personalInfo },
      income: { ...ohaFormState[rootFormName].income },
      obligation: { ...ohaFormState[rootFormName].obligation },
      contactDetails: { ...contactDetailsValues },
    },
  };

  const onSaveError = () => handleNotification('error', 'submit');
  const { save, isSaving, goToNextStep } = useOhaFormStep({ onSaveError });

  const onSubmit = async (formData: ContactDetailsFormType) => {
    dispatch(saveContactDetails({ formData, rootFormName }));

    if (isDirty) {
      await save(
        mapOHAForm({
          ...ohaFormState,
          [rootFormName]: { ...ohaFormState[rootFormName], contactDetails: formData },
        })
      );
    }

    goToNextStep();
  };

  const handleNotification = useCallback(
    (status: string, name: string) => setSnackbar({ status, name }),
    []
  );

  const handleOnClose = useCallback(() => setSnackbar(undefined), []);

  const dispatchForm = useCallback(() => {
    dispatch(saveContactDetails({ formData: contactDetailsValues, rootFormName }));
  }, [rootFormName, contactDetailsValues, dispatch]);

  return (
    <>
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormTemplate
            header={
              <TitleBar
                onResult={handleNotification}
                formState={completeForm}
                resetForm={reset}
                isFormDirty={isDirty}
                dispatchCurrentForm={dispatchForm}
              />
            }
            sidebar={<FormStepper />}
            footer={
              <BottomNavigation dispatchCurrentForm={dispatchForm} isLoadingSubmit={isSaving} />
            }
          >
            <ContactDetails title={title} subtitle={subtitle} name={fullName} />
          </FormTemplate>
        </Form>
      </FormProvider>

      {snackbar && (
        <CustomSnackbar
          isOpen={!!snackbar}
          handleClose={handleOnClose}
          severity={snackbar.status === 'success' ? 'success' : 'error'}
          message={t(`directMutations.snackbar.${snackbar.name}.${snackbar.status}`)}
        />
      )}
    </>
  );
};

export default ContactDetailsForm;
