import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { getCustomerNames } from 'utils/global';
import StatusTag from 'components/StatusTag';
import { Case } from '__generated__/api-di';
import Logo from 'components/Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    order: 1,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  customerNames: {
    fontWeight: 500,
    fontSize: '16px',
    flexGrow: 1,
  },
  interpunct: {
    margin: theme.spacing(0, 1),
  },
  commercialName: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  status: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    order: 2,
    [theme.breakpoints.down('md')]: {
      order: 3,
      marginTop: theme.spacing(2),
    },
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    order: 3,
    [theme.breakpoints.down('md')]: {
      order: 2,
      alignItems: 'flex-start',
      marginTop: theme.spacing(-2),
    },
  },
}));

type ApplicationDetailHeaderProps = {
  applicationData: { application: Case; from: string };
  onClose: () => void;
};

const ApplicationDetailHeader = ({
  applicationData: { application },
  onClose,
}: ApplicationDetailHeaderProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { lender, application: applicationDetails } = application;
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid
          item
          lg={7}
          xs={11}
          className={classes.heading}
          data-testid="applicationDetails-header-logo"
        >
          <Logo label={lender!.label!} size="M" className={classes.logo} />
          <Box>
            <Typography
              className={classes.customerNames}
              data-testid="applicationDetails-header-customerNames"
            >
              {getCustomerNames(application)}
            </Typography>
            <Typography
              className={classes.commercialName}
              data-testid="applicationDetails-header-commercialName"
            >
              {lender!.name}
              <span className={classes.interpunct} />
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          lg={3}
          xs={12}
          className={classes.status}
          data-testid="applicationDetails-header-status"
        >
          <StatusTag
            urgency={applicationDetails!.priority!.urgency!}
            progress={applicationDetails!.progress!}
            label={t(`applications.filter.${applicationDetails!.status!.value}`)}
          />
        </Grid>
        <Grid
          item
          lg={3}
          xs={1}
          className={classes.closeButton}
          data-testid="applicationDetails-header-closeButton"
        >
          <IconButton onClick={onClose} size="large">
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default ApplicationDetailHeader;
