import { FC, useState } from 'react';
import { Dialog, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FileViewer from 'components/DocumentViewer';

import { useTranslation } from 'react-i18next';
import { SlideUpTransition } from '../../lib/Transition/transition';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
  main: {
    flexGrow: 100,
  },
  fullHeight: {
    height: '100%',
    flexGrow: 100,
  },
}));

type Props = {
  file?: any;
  open: boolean;
  onClose: () => void;
};

const ViewFile: FC<Props> = ({ file, open, onClose }) => {
  const [state, setState] = useState('');
  const classes = useStyles();
  const [scale, setScale] = useState(1);
  const { t } = useTranslation();
  let fileInfo;

  const fileName = file?.name;
  const fileSize = file?.size;

  const uploadFileInfo = {
    name: fileName,
    size: fileSize,
  };

  if (uploadFileInfo !== null) {
    fileInfo = uploadFileInfo;
  }

  const reset = () => {
    setState('');
  };

  const handleZoom = (scalePercentage: number) => {
    setScale(scalePercentage / 100);
  };

  return (
    <Dialog
      data-testid="viewDocument-wrapper"
      fullScreen
      open={open}
      TransitionComponent={SlideUpTransition}
      onClose={onClose}
      TransitionProps={{ onExited: reset }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="stretch"
        className={classes.fullHeight}
      >
        {state !== 'error' ? (
          <>
            <Header fileInfo={fileInfo} onZoom={handleZoom} onClose={onClose} zoomVisible />(
            <div className={classes.main}>
              <FileViewer file={file} scale={scale} />
            </div>
          </>
        ) : (
          t('error.default')
        )}
      </Box>
    </Dialog>
  );
};

export default ViewFile;
