import { Grid } from '@mui/material';
import { FC } from 'react';
import { GridRowHeader } from '../../Overview.style';
import TableBodyRow from './TableBody/TableBodyRow';
import { Category, ListOfMoneyLenders } from 'types/FunctionalitiesOverview';
import { useQueryProfiles } from 'use/profiles';
import { sortTableList } from 'screens/Overview/utils';

type TableProps = {
  category: Category;
  page: number;
  entriesperPage: number;
};
const Table: FC<TableProps> = ({ category, page, entriesperPage }) => {
  const { displayName, functionalities } = category;
  const { data: profiles } = useQueryProfiles();

  return (
    <>
      <GridRowHeader item columnheader={1}>
        {displayName}
      </GridRowHeader>
      {functionalities.map((features) => {
        const commercialLabels = sortTableList(features.commercialLabels, profiles);
        return (
          <Grid key={features.name} container direction="row" wrap="nowrap">
            <GridRowHeader item>{features.displayName}</GridRowHeader>
            {commercialLabels
              .slice(page * entriesperPage, page * entriesperPage + entriesperPage)
              .map(({ isAvailable, label }: ListOfMoneyLenders, index) => (
                <TableBodyRow isActive={isAvailable} key={`${label}-${index}`} />
              ))}
          </Grid>
        );
      })}
    </>
  );
};
export default Table;
