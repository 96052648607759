import { useTranslation } from 'react-i18next';
import { FC, useEffect } from 'react';
import DownloadList from './components/DownloadList/DownloadList';
import { Container, Box } from './Downloads.styled';
import HeaderTemplate from 'components/Templates/Header/HeaderTemplate';
import { useDispatch } from 'react-redux';
import { setResetClientFilters } from 'store/genericClientListFiltersReducer';

const Downloads: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setResetClientFilters());
  }, [dispatch]);

  return (
    <>
      <HeaderTemplate sectionName={t('general.header.menu.downloads')} />

      <Container>
        <Box my={5}>
          <DownloadList />
        </Box>
      </Container>
    </>
  );
};

export default Downloads;
