import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { googleTagManager } from 'use/gtm';
import { useQueryNewsItems } from 'use/newsItems';
import { NextButton, SectioTypography } from '../Home.styled';
import NewsTile from './NewsTile';

const HomeSectionNews = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useQueryNewsItems();

  const news = data
    ?.filter((item) => !item.isImportant)
    .sort((a, b) => new Date(b.dateCreated!).getTime() - new Date(a.dateCreated!).getTime())
    .slice(0, 3);

  const handleClickNextButton = () => {
    navigate('/news');
    googleTagManager({ event: 'ButtonClick', btnName: 'SeeAllNews' });
  };

  if (isLoading || isError || news?.length === 0) {
    return null;
  }
  return (
    <Grid container my={4} direction="column" alignItems="flex-start">
      <Box display="flex" alignItems="center">
        <SectioTypography variant="overline" color="textSecondary">
          {t('home.news')}
        </SectioTypography>
      </Box>
      {news?.map((item) => (
        <NewsTile key={item.id} item={item} />
      ))}
      <NextButton color="primary" endIcon={<ChevronRight />} onClick={handleClickNextButton}>
        {t('home.allNews')}
      </NextButton>
    </Grid>
  );
};

export default HomeSectionNews;
