import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { formatLoanNumber, formatLoanAmount } from 'utils/global';
import HandlingTimeButton from './HandlingTimeButton';
import { Case } from '__generated__/api-di';
import formatDate from 'utils/formatDate';

const useStyles = makeStyles((theme) => ({
  infoSection: {
    padding: '0 32px',
  },
  infoItem: {
    margin: theme.spacing(1, 0),
  },
  infoItemLabel: {
    fontWeight: 500,
    fontSize: '12px',
  },
  infoItemText: {
    fontSize: '16px',
    margin: '4px 0',
  },
  infoItemDeeplink: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    flexDirection: 'column',
  },
  deeplink: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
}));

type ApplicationDetailInfoProps = {
  applicationData: Case; // TODO: Get all data case detail
  children?: React.ReactNode;
};

const ApplicationDetailInfo: React.FC<ApplicationDetailInfoProps> = ({
  applicationData,
  children,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const application = applicationData.application!;

  const loanAmount = formatLoanAmount(application.loanAmount!);
  const loanNumber = formatLoanNumber(application.loanNumberExternal!);
  const documentsUploaded = t('applications.detail.withProofItems', {
    received: applicationData.proofItem?.received,
    total: applicationData.proofItem?.amount,
  });
  const creationDate = formatDate(application.creationDate!);
  const applicationDeadlineDate = application.passingDate!.planned
    ? formatDate(application!.passingDate!.planned!)
    : formatDate(application!.passingDate!.utmost!);

  const applicationType = t(`applications.type.${application!.type}`);

  return (
    <Grid container data-testid="applicationDetails-header">
      <Grid
        item
        lg={2}
        md={6}
        xs={6}
        className={classes.infoItem}
        data-testid="applicationDetails-loanAmount"
      >
        <Typography className={classes.infoItemLabel} color="textSecondary">
          {t('applications.detail.loanAmount')}
        </Typography>
        <Typography className={classes.infoItemText}>{loanAmount}</Typography>
      </Grid>
      <Grid
        item
        lg={2}
        md={6}
        xs={6}
        className={classes.infoItem}
        data-testid="applicationDetails-loanNumber"
      >
        <Typography className={classes.infoItemLabel} color="textSecondary">
          {t('applications.detail.loanNumber')}
        </Typography>
        <Typography className={classes.infoItemText}>{loanNumber}</Typography>
      </Grid>
      <Grid
        item
        lg={3}
        md={6}
        xs={6}
        className={classes.infoItem}
        data-testid="applicationDetails-documentsUploaded"
      >
        <Typography className={classes.infoItemLabel} color="textSecondary">
          {t('applications.detail.documentsUploaded')}
        </Typography>
        <Typography className={classes.infoItemText}>{documentsUploaded}</Typography>
      </Grid>
      <Grid
        item
        lg={5}
        md={6}
        xs={6}
        className={classes.infoItem}
        data-testid="applicationDetails-address"
      >
        <Typography className={classes.infoItemLabel} color="textSecondary">
          {t('applications.detail.address')}
        </Typography>
        <Typography className={classes.infoItemText}>{children}</Typography>
      </Grid>

      <Grid
        item
        lg={2}
        md={6}
        xs={6}
        className={classes.infoItem}
        data-testid="applicationDetails-creationDate"
      >
        <Typography className={classes.infoItemLabel} color="textSecondary">
          {t('applications.detail.creationDate')}
        </Typography>
        <Typography className={classes.infoItemText}>{creationDate}</Typography>
      </Grid>
      <Grid
        item
        lg={2}
        md={6}
        xs={6}
        className={classes.infoItem}
        data-testid="applicationDetails-applicationDeadline"
      >
        <Typography className={classes.infoItemLabel} color="textSecondary">
          {t('applications.detail.applicationDeadline')}
        </Typography>
        <Typography className={classes.infoItemText}>{applicationDeadlineDate}</Typography>
      </Grid>
      <Grid
        item
        lg={3}
        md={6}
        xs={6}
        className={classes.infoItem}
        data-testid="applicationDetails-applicationType"
      >
        <Typography className={classes.infoItemLabel} color="textSecondary">
          {t('applications.detail.applicationType')}
        </Typography>
        <Typography className={classes.infoItemText}>{applicationType}</Typography>
      </Grid>
      <Grid
        item
        lg={3}
        md={6}
        xs={6}
        className={classes.infoItemDeeplink}
        data-testid="applicationDetails-handlingTimes"
      >
        <Typography className={classes.infoItemLabel} color="textSecondary">
          {t('applications.detail.handlingTimes')}
        </Typography>
        <div className={classes.deeplink} data-testid="applicationDetails-deepLink">
          {applicationData.lender?.deepLink && (
            <HandlingTimeButton link={applicationData.lender!.deepLink} />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ApplicationDetailInfo;
