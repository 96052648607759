import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AdditionalInformation from 'screens/Mutations/templates/AdditionalInformation';

const AdditionalInformationForm: FC = () => {
  const { t } = useTranslation();

  return (
    <AdditionalInformation
      title={t('directMutations.additionalInformation.title')}
      subtitle={t('directMutations.additionalInformation.subtitle')}
      rootFormName="additionalInformation."
    />
  );
};

export default AdditionalInformationForm;
