import clsx from 'clsx';
import { Paper, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { CheckCircleRounded, RadioButtonUncheckedRounded } from '@mui/icons-material';

type Props = {
  title: string;
  description: string;
  isSelected: boolean;
  onClick: () => void;
};

const useStyles = makeStyles((theme) => ({
  item: {
    minHeight: 112,
    borderRadius: 6,
    cursor: 'pointer',
    padding: theme.spacing(2, 4),
  },
  selectedClient: {
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  itemTypeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemType: {
    fontWeight: 'bolder',
    marginBottom: theme.spacing(1),
    flex: 1,
  },
  itemTypeSelected: {
    color: theme.palette.primary.main,
  },
}));

const MutationItem = ({ title, description, isSelected, onClick }: Props) => {
  const classes = useStyles();

  return (
    <Paper
      variant="outlined"
      className={clsx([classes.item, { [classes.selectedClient]: isSelected }])}
      onClick={onClick}
    >
      <div className={classes.itemTypeWrapper}>
        <Typography
          className={clsx([classes.itemType, { [classes.itemTypeSelected]: isSelected }])}
        >
          {title}
        </Typography>
        {isSelected ? (
          <CheckCircleRounded color="primary" />
        ) : (
          <RadioButtonUncheckedRounded color="action" />
        )}
      </div>
      <Typography color="textSecondary">{description}</Typography>
    </Paper>
  );
};

export default MutationItem;
