import { styled } from '@mui/material/styles';
import {
  Container as MuiContainer,
  Box as MuiBox,
  Grid as MuiGrid,
  Typography as MuiTypography,
  TypographyProps,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Container = styled(MuiContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 3),
  },
}));

const Box = styled(MuiBox)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const Typography = styled(MuiTypography)(({ theme }) => ({
  fontSize: '32px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '19px',
  },
}));

const GridItem = styled(MuiGrid)(({ theme }) => ({
  padding: theme.spacing(4, 0),
}));

const Dot = styled(FiberManualRecordIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: '15px',
}));

interface TableCellTextProps extends TypographyProps {
  isaccessed?: 0 | 1;
}
const TableCellText = styled(MuiTypography)<TableCellTextProps>((props) => {
  const { theme, isaccessed } = props;
  return {
    'color': theme.palette.text.primary,
    'fontWeight': isaccessed ? 400 : 500,
    'fontSize': 14,
    '&.truncate': {
      display: 'table-cell',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };
});
const MobileTableCellText = styled(MuiTypography)<TableCellTextProps>(({ theme, isaccessed }) => ({
  'color': theme.palette.text.primary,
  'fontWeight': isaccessed ? 400 : 700,
  'fontSize': 17,
  '&.truncate': {
    display: 'table-cell',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
const MobileGrid = styled(MuiGrid)(({ theme }) => ({
  padding: theme.spacing(1, 0.5),
}));

export {
  Container,
  Box,
  Typography,
  GridItem,
  Dot,
  TableCellText,
  MobileTableCellText,
  MobileGrid,
};
