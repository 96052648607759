import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import backgroundImageStater from '../../assets/images/header-background.jpg';
import { Container, Grid, Hidden, IconButton } from '@mui/material';
import { Menu } from '@mui/icons-material';

import { useAppDispatch } from '../../store';
import { setMobileDrawerVisible } from '../../store/appReducer';
import { useLocation } from 'react-router-dom';
import NotificationCount from 'components/NotificationList/NotificationCount';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.purple.dark,
    backgroundImage: `linear-gradient(to top, #00000080, #00000020), url("${backgroundImageStater}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: theme.palette.common.white,
    display: 'flex',
    marginBottom: 20,
    paddingTop: 64,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 23,
      paddingBottom: 22,
      backgroundSize: '150%',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
  },
  mobile: {
    width: '100%',
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mobileHeader: {
    margin: theme.spacing(0, 2),
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButton: {
    zIndex: 1250,
  },
}));

type HeaderProps = {
  style?: any;
  className?: string;
  paddingTop?: number;
  children?: React.ReactNode;
};

const Header: FC<HeaderProps> = ({ style, className, children, paddingTop = 8 }) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const toggleMobileDrawer = (visible: boolean) => dispatch(setMobileDrawerVisible(visible));
  const location = useLocation();

  return (
    <Grid container pt={paddingTop} className={`${classes.root} ${className}`} style={style}>
      <Hidden smDown>
        <Container className={classes.container}>
          <>{children}</>
        </Container>
      </Hidden>
      <Hidden smUp>
        <div className={classes.mobile}>
          <div className={classes.mobileHeader}>{children}</div>
          <IconButton
            className={classes.menuButton}
            size="small"
            color="inherit"
            onClick={() => toggleMobileDrawer(true)}
          >
            {location.pathname !== '/notifications' && <NotificationCount size="L" />}
            <Menu />
          </IconButton>
        </div>
      </Hidden>
    </Grid>
  );
};

export default Header;
