import React, { FC } from 'react';
import { Typography, Paper, Box, Button, Icon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BtlApplicant } from 'types/BtlFormApplication';
import { useTranslation } from 'react-i18next';
import BuyToLetLegalPersonPersonalSummary from './BuyToLetLegalPersonPersonalSummary';
import BuyToLetApplicantContactDataSummary from '../BuyToLetApplicantContactDataSummary';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: 60,
    marginTop: theme.spacing(5),
  },
  card: {
    'padding': theme.spacing(3),
    'borderRadius': 4,
    '&.margin': {
      marginBottom: theme.spacing(3),
    },
  },
  subHeader: {
    marginBottom: theme.spacing(3),
  },
}));

export type BuyToLetLegalApplicantSummaryProps = {
  applicant: BtlApplicant;
  applicantIndex: number;
  navigateToApplicant: any;
  navigateToApplicantField: (applicant: number, subStep: number, field: string) => void;
  errorMessage: (id: string, category: string) => void;
};

const BuyToLetLegalApplicantSummary: FC<BuyToLetLegalApplicantSummaryProps> = ({
  applicant,
  applicantIndex,
  navigateToApplicant,
  navigateToApplicantField,
  errorMessage,
}) => {
  const classes = useStyles();
  const form = 'buyToLetForm.summary';

  const { t } = useTranslation();

  return (
    <>
      <div className={classes.wrapper} key={applicant.companyName}>
        <Typography className={classes.subHeader} variant="h4">
          {applicant.companyName}
        </Typography>
        <Paper className={`${classes.card} margin`}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.subHeader} variant="h6">
              {`${applicant.companyName} - ${t('applications.stepTitle.personalData')}`}
            </Typography>
            <Button
              variant="text"
              color="primary"
              endIcon={<Icon>pencil</Icon>}
              onClick={() => navigateToApplicant(applicantIndex, 0)}
            >
              {t(`${form}.edit`)}
            </Button>
          </Box>

          <BuyToLetLegalPersonPersonalSummary
            applicant={applicant}
            applicantIndex={applicantIndex}
            onClickField={(field: any) => navigateToApplicantField(applicantIndex, 0, field)}
            errorMessage={errorMessage}
          />
        </Paper>

        <Paper className={`${classes.card} margin`}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.subHeader} variant="h6">
              {`${applicant.companyName} - ${t('applications.stepTitle.correspondance')}`}
            </Typography>
            <Button
              variant="text"
              color="primary"
              endIcon={<Icon>pencil</Icon>}
              onClick={() => navigateToApplicant(applicantIndex, 1)}
            >
              {t(`${form}.edit`)}
            </Button>
          </Box>

          <BuyToLetApplicantContactDataSummary
            applicant={applicant}
            applicantIndex={applicantIndex}
            onClickField={(field: any) =>
              navigateToApplicantField(
                applicantIndex,
                applicant.isLegalPerson === true ? 1 : 2,
                field
              )
            }
            errorMessage={errorMessage}
          />
        </Paper>
      </div>
    </>
  );
};

export default BuyToLetLegalApplicantSummary;
