import { useQuery } from 'react-query';
import { HEADER_SELECTED_GROUP_CLAIM, http } from '../../http';
import { useGroup } from 'use/group';

export async function fetchMutationForm(commercialLabel: string, mutationInfo: MutationInfo) {
  const response = await http({
    url: `/api/mutations/application-forms/${commercialLabel}/${mutationInfo.type}`,
    method: 'GET',
    responseType: 'blob',
    headers: { Accept: 'application/octect-stream' },
  });

  const contentDisposition = response.headers['content-disposition'] ?? '';
  const filename = contentDisposition.match(/filename="(.+)"/i)?.[1] ?? 'formulier.pdf';

  const url = window.URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  link.click();
  window.URL.revokeObjectURL(url);
}

export async function uploadMutationForm(
  loanNumber: string,
  mutation: MutationInfo,
  uploadedFile: File,
  group: string | null,
  commercialLabel: string
) {
  const formData = new FormData();
  formData.append(
    'json',
    JSON.stringify({ loanNumber: Number(loanNumber), mutationType: mutation.type, commercialLabel })
  );
  formData.append('uploadedFile', uploadedFile);

  const response = await http({
    url: '/api/mutations',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      [HEADER_SELECTED_GROUP_CLAIM]: group,
    },
    data: formData,
  });

  return response;
}

export type LoanInfo = {
  moneylender: string;
  mutations: MutationInfo[];
};

type MutationType =
  | 'Raise'
  | 'Conversion'
  | 'ConversionWithRaise'
  | 'DismissalJointLiability'
  | 'Transfer'
  | 'CustomApplication'
  | 'CollateralValue';

export type MutationInfo = {
  type: MutationType;
  applicationForm: string;
  customFormUrl?: string;
};

async function getMutationInfo(
  group: string | null,
  loanNumber: number,
  commercialLabel: string
): Promise<LoanInfo> {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const response = await http(`/api/mutations/info/${commercialLabel}/${loanNumber}`, { headers });
  return response.data;
}

export function useQueryMutationInfo(loanNumber?: number, commercialLabel?: string) {
  const group = useGroup();
  return useQuery(['mutations/info', group, loanNumber], () => {
    return loanNumber && commercialLabel
      ? getMutationInfo(group, loanNumber, commercialLabel)
      : undefined;
  });
}
