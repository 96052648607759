import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { List, Tabs, Tab } from '@mui/material';
import type { ProofItem } from '../../types/ProofItem';
import ProofItemListItem from '../ProofItemListItem';
import TaskListItem from '../TaskListItem';
import { countCompletedTasks, sortTaskPredicate } from '../../utils/global';
import { Case, TaskInfo } from '../../__generated__/api-di';
import { GroupedProofItems, TabKey } from './types';
import TabPanel from './components/TabPanel';
import TabLabel from './components/TabLabel';
import countReceived from './utils/countReceived';
import isReceived from './utils/isReceived';
import getAllTabs from './utils/getAllTabs';
import { getApplicationTab } from './utils/getDefaultTab';

const useStyles = makeStyles((theme) => ({
  listItem: {
    height: 80,
  },
  tabs: {
    flexGrow: 1,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  tabRoot: {
    'padding': 0,
    'opacity': 1,
    'marginRight': theme.spacing(3),
    '&:last-child': {
      margin: 0,
    },
  },
  tabWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));

type ActionListExtraProps = {
  tasks: Array<TaskInfo>;
  applicationData: Case;
  allProofItems: GroupedProofItems;
  filteredProofItems: GroupedProofItems;
  onClickView: (proofitem: ProofItem) => void;
  onClickUpload: (proofitem: ProofItem) => void;
  onClickResolve: (task: TaskInfo) => void;
};

const ActionListExtra: React.FC<ActionListExtraProps> = ({
  tasks,
  applicationData,
  allProofItems,
  filteredProofItems,
  onClickView,
  onClickUpload,
  onClickResolve,
}) => {
  const classes = useStyles();
  const tabClasses = { root: classes.tabRoot, wrapper: classes.tabWrapper };
  const { t } = useTranslation();

  const allTabs = getAllTabs(allProofItems, tasks);
  const applicationTab = getApplicationTab(applicationData);
  const hasTabs = allTabs.length > 0;

  const defaultTab: TabKey = hasTabs
    ? allTabs.includes(applicationTab)
      ? applicationTab
      : allTabs[0]
    : 'administrativeDocuments';

  const [selectedTab, setSelectedTab] = useState<TabKey>(defaultTab);

  const hasTasks = allTabs.includes('tasks');
  const hasOfferLetter = allTabs.includes('offerLetter');
  const hasFinalContracts = allTabs.includes('finalContracts');
  const hasAdministrativeDocuments = allTabs.includes('administrativeDocuments');

  const onChangeTab = (_: React.ChangeEvent<any>, newValue: TabKey) => setSelectedTab(newValue);

  const { administrativeDocuments, finalContracts, offerLetter } = allProofItems;

  const taskLabel = hasTasks && (
    <TabLabel chip={`${countCompletedTasks(tasks)}/${tasks.length}`} text={t('tasks.tasks')} />
  );

  const countOfferLetters = isReceived(offerLetter?.status) ? 1 : 0;
  const offerLetterLabel = hasOfferLetter && (
    <TabLabel chip={`${countOfferLetters}/1`} text={t('proofitem.offerLetter')} />
  );

  const finalContactsLabel = (
    <TabLabel
      chip={`${countReceived(finalContracts)}/${allProofItems.finalContracts.length}`}
      text={t('proofitem.finalContract')}
    />
  );

  const administrativeDocumentsLabel = (
    <TabLabel
      chip={`${countReceived(administrativeDocuments)}/${
        allProofItems.administrativeDocuments.length
      }`}
      text={t('proofitem.administrativeDocuments')}
    />
  );

  const addTabPanel = (key: TabKey, items: ProofItem[]) => (
    <TabPanel value={selectedTab} tabKey={key}>
      <List disablePadding data-testid={key}>
        {items.map((proofitem, index) => (
          <ProofItemListItem
            key={index}
            proofItem={proofitem}
            application={applicationData}
            onClickView={onClickView}
            onClickUpload={() => onClickUpload(proofitem)}
          />
        ))}
      </List>
    </TabPanel>
  );

  if (!hasTabs) return null;

  return (
    <>
      <Tabs
        className={classes.tabs}
        value={selectedTab}
        onChange={onChangeTab}
        indicatorColor="primary"
      >
        {hasTasks && <Tab value="tasks" classes={tabClasses} label={taskLabel} />}
        {hasOfferLetter && (
          <Tab value="offerLetter" classes={tabClasses} label={offerLetterLabel} />
        )}
        {hasFinalContracts && (
          <Tab value="finalContracts" classes={tabClasses} label={finalContactsLabel} />
        )}
        {hasAdministrativeDocuments && (
          <Tab
            value="administrativeDocuments"
            classes={tabClasses}
            label={administrativeDocumentsLabel}
          />
        )}
      </Tabs>

      {hasOfferLetter &&
        filteredProofItems.offerLetter &&
        addTabPanel('offerLetter', [filteredProofItems.offerLetter])}

      {hasFinalContracts && addTabPanel('finalContracts', filteredProofItems.finalContracts)}

      {hasAdministrativeDocuments &&
        addTabPanel('administrativeDocuments', filteredProofItems.administrativeDocuments)}

      {hasTasks && (
        <TabPanel value={selectedTab} tabKey="tasks">
          <List disablePadding>
            {tasks.sort(sortTaskPredicate).map((task) => (
              <TaskListItem
                key={task.taskId}
                task={task}
                onClickResolve={() => {
                  onClickResolve(task);
                }}
              />
            ))}
          </List>
        </TabPanel>
      )}
    </>
  );
};

export default ActionListExtra;
