import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ListTitleText, ButtonGroup } from '../NotificationPreference.styled';
import { NotificationPreferenceFormType } from 'types/NotificationPreference';
import NotificationListItem from './NotificationListItem';
import { Button, Divider, List } from '@mui/material';
import { useFormContext, useFieldArray } from 'react-hook-form';

const NotificationList: FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<NotificationPreferenceFormType>();
  const { fields } = useFieldArray({ name: 'notifications', control });

  return (
    <List>
      <ListTitleText variant="subtitle2">
        {t('notificationPreference.notification-list.subtitle')}
      </ListTitleText>
      <Divider />
      {fields.map((notification, index) => {
        return (
          <NotificationListItem key={notification.id} index={index} notification={notification} />
        );
      })}
      <ButtonGroup container justifyContent="flex-end">
        <Button color="primary" size="medium" variant="contained" type="submit">
          {t('notificationPreference.notification-list.save')}
        </Button>
      </ButtonGroup>
    </List>
  );
};
export default NotificationList;
