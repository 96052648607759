import React, { useEffect, FC, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Hidden, Table, TableFooter, Typography } from '@mui/material';
import Pagination from 'components/Pagination/Pagination';
import { useGoogleTagManager } from 'use/gtm';
import { ApplicationData } from '__generated__/api';
import { PaginationWrapper } from 'screens/templates/Lists/ListWrapper/Wrapper.styled';
import ConceptsDesktop from './components/ConceptsDesktop';
import LoadingIndicator from 'components/LoadingIndicator';
import PlaceholderEmptyList from 'components/PlaceholderEmptyList';
import { EmptyListRefreshDescriptionWrapper } from 'screens/Clients/Clients.styled';
import ConceptMobile from './components/ConceptsMobile';

export type ConceptListProps = {
  items: ApplicationData[];
  onClickItem: (item: ApplicationData, pathName?: string) => void;
  isLoading: boolean;
};

const ConceptList: FC<ConceptListProps> = ({ items, onClickItem, isLoading }) => {
  const { t } = useTranslation();
  const tagEvent = useGoogleTagManager();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const pathName = window.location.pathname;

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
    tagEvent({
      event: 'ButtonClick',
      btnName: 'PaginationApplicationConceptList',
      pageNumber: page,
      totalApplications: items.length,
    });
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };
  const handleClickItem = (item: ApplicationData) => {
    onClickItem(item, pathName);
  };

  useEffect(() => {
    setPage(0);
  }, [items]);

  if (isLoading) {
    return <LoadingIndicator data-testid="client-loading" />;
  }
  if (items?.length === 0) {
    return (
      <PlaceholderEmptyList subtitle={t('clients.empty.subtitle')}>
        <EmptyListRefreshDescriptionWrapper>
          <Typography variant="subtitle2" color="textSecondary">
            {t('clients.empty.refreshPage')}
          </Typography>
        </EmptyListRefreshDescriptionWrapper>
      </PlaceholderEmptyList>
    );
  }

  return (
    <>
      <ConceptsDesktop
        onClickItem={handleClickItem}
        items={items}
        page={page}
        rowsPerPage={rowsPerPage}
      />
      <ConceptMobile items={items} onClickItem={handleClickItem} />
      <Hidden smDown>
        <Table>
          <TableFooter>
            <PaginationWrapper>
              <Pagination
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                count={items.length}
              />
            </PaginationWrapper>
          </TableFooter>
        </Table>
      </Hidden>
    </>
  );
};

export default ConceptList;
