import type { FC } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormInputText } from 'components/Form';
import {
  DetailsPaper,
  DetailsDivider,
  EmailGridItem,
  WarningText,
  Explanation,
} from '../NotificationPreference.styled';

const Details: FC = () => {
  const { t } = useTranslation();

  return (
    <DetailsPaper>
      <Explanation variant="body1">{t('notificationPreference.details.explanation')}</Explanation>
      <DetailsDivider />
      <Grid container>
        <EmailGridItem container item alignItems="center">
          <FormInputText
            name="contactDetails.emailAddress"
            label={t('notificationPreference.details.email')}
            required
            maxLength={254}
          />
        </EmailGridItem>
      </Grid>
      <WarningText variant="body2">
        Let op! Er moet een e-mailadres worden opgegeven om notificaties te kunnen ontvangen.
        Vergeet niet om je wijzigingen op te slaan.
        <br />
        Het e-mailadres dat je hier invult wordt alleen gebruikt voor de notificaties. Als je het
        e-mailadres in de administratie van Stater wilt wijzigen, kun je hiervoor een e-mail sturen
        naar <a href="mailto:tussenpersonen@stater.nl">tussenpersonen@stater.nl</a>.
      </WarningText>
    </DetailsPaper>
  );
};
export default Details;
