import { ContactData } from 'types/ApplicationDataDetails';
import { ClientDetailsPerson } from 'types/ClientDetailsModel';
import { CaseDetailsContactDataInfo } from '__generated__/api-di';

export const formatStreet = (
  street?: string,
  houseNumber?: string | number,
  houseNumberSuffix?: string
) => (street ? `${street} ${houseNumber ?? ''}${houseNumberSuffix ?? ''}` : '');

export const formatAddressWithPerson = (person: ClientDetailsPerson) => {
  const address = person.contact?.address;

  if (!address) return null;

  const street = formatStreet(address.street, address.houseNumber, address.houseNumberSuffix);

  const country =
    address?.country?.code && address.country.code !== 'NL' && address.country.displayName
      ? `, ${address.country.displayName}`
      : '';

  const postalCode = address?.postalCode ? ` ${address.postalCode}` : '';
  const city = address?.city ? ` ${address.city}` : '';

  const concatenateAddress = `${street},${postalCode}${city}${country}`;
  return address.street || address.postalCode || address.city ? concatenateAddress : null;
};

export const formatAddress = (contactData?: ContactData | CaseDetailsContactDataInfo) => {
  if (!contactData) return '';

  const street = contactData.street;
  const zipCode = contactData.zipCode ?? '';
  const city = contactData.city ?? '';

  if (street && zipCode && city) {
    const streetPart = formatStreet(
      street,
      contactData.houseNumber,
      contactData.houseNumberAddition
    );

    return `${streetPart}, ${zipCode} ${city}`;
  }

  if (zipCode && city) return `${zipCode} ${city}`;

  return city;
};
