export const TYPEOFTAXBOX = ['1', '3'];

export const FIXEDINTERESTPERIOD = ['120', '144', '180', '240', '300', '360'];

export const CIVILSTATUS = [
  'CivilPartnership',
  'CivilPartnerShipLimited',
  'CivilPartnerShipWithConditions',
  'LivingTogetherWithContract',
  'LivingTogetherWithoutContract',
  'Married',
  'MarriedForeignLaw',
  'MarriedLimited',
  'MarriedWithSettlement',
  'Single',
  'Coheir',
];

export const TYPEOFEMPLOYMENT = [
  'FlexibleWithoutPerspective',
  'FlexibleWithPerspective',
  'Permanent',
  'TemporaryWithIntent',
  'TemporaryWithoutIntent',
];

export const LEGALFORM = [
  'Foundation',
  'GeneralPartnership',
  'LimitedPartnership',
  'OneManBusiness',
  'Partnership',
  'PrivateCompany',
  'PublicCompany',
];

export const TYPEOFBENEFIT = [
  'DependantPension',
  'DisablityInsuranceWithoutReinspection',
  'DisablityInsuranceWithReinspection',
  'Insurance',
  'InvalidInsuranceSelfEmployed',
  'LawForSurvivingDependants',
  'OldAgeInsurance',
  'OldAgePension',
  'ProvisionFullyIncapacitated',
  'ResumptionPartiallyIncapacitated',
  'StateUnemploymentBenefits',
  'TideoverAllowance',
  'UnemploymentBenefits',
  'Wajong',
  'Welfare',
];

export const TYPEOFINSURANCE = ['AnnuityInsurance', 'DisabilityInsurance', 'IncomeProtectionCover'];

export const TYPEOFOBLIGATION = [
  'AdvancedPaymentStudy',
  'AlimonyChildren',
  'AlimonyPartner',
  'DebtRepayment',
  'ExistingMortgage',
  'LoanEmployer',
  'MailOrderCredit',
  'PensionContribution',
  'PersonalLoan',
  'PrivateLease',
  'Remaining',
  'RentalCosts',
  'ResidualDebtNhg',
  'ResidualDebtNoNhg',
  'RevolvingCredit',
  'StudentLoan',
  'Telecommunication',
];

export const TYPEOFNEWLYBUILD = [
  'CollectivePrivateCommissioning',
  'ProjectConstruction',
  'SelfConstruction',
];

export const TYPEOFCOLLATERAL = ['Existing', 'NewlyBuild', 'ExistingRenovated'];

export const TYPEOFREALESTATE = [
  'Appartement',
  'AppartementWithGarage',
  'BuildingLand',
  'BuildingPlot',
  'CommercialProperty',
  'CommercialRealEstate',
  'FamilyHome',
  'FamilyHomeWithGarage',
  'Farm',
  'Garage',
  'Houseboat',
  'PleasureBoat',
  'PrivateHouseWithCommercialSpace',
  'RecreationHouse',
  'Store',
  'StorePrivateHouse',
];

export const TYPEOFREDEMPTION = ['Annuity', 'InterestOnly'];

export const COUNTRIES = [
  null,
  'NLD',
  'AFG',
  'ALA',
  'ALB',
  'DZA',
  'ASM',
  'AND',
  'AGO',
  'AIA',
  'ATA',
  'ATG',
  'ARG',
  'ARM',
  'ABW',
  'AUS',
  'AZE',
  'BHS',
  'BHR',
  'BGD',
  'BRB',
  'BEL',
  'BLZ',
  'BEN',
  'BMU',
  'BTN',
  'BOL',
  'BES',
  'BIH',
  'BWA',
  'BVT',
  'BRA',
  'IOT',
  'BRN',
  'BGR',
  'BFA',
  'BDI',
  'KHM',
  'CAN',
  'CAF',
  'CHL',
  'CHN',
  'CXR',
  'CCK',
  'COL',
  'COM',
  'COD',
  'COG',
  'COK',
  'CRI',
  'CUB',
  'CUW',
  'CYP',
  'DNK',
  'DJI',
  'DMA',
  'DOM',
  'DEU',
  'ECU',
  'EGY',
  'SLV',
  'GNQ',
  'ERI',
  'EST',
  'ETH',
  'FRO',
  'FLK',
  'FJI',
  'PHL',
  'FIN',
  'FRA',
  'GUF',
  'PYF',
  'ATF',
  'GAB',
  'GMB',
  'GEO',
  'GHA',
  'GIB',
  'GRD',
  'GRC',
  'GRL',
  'GLP',
  'GUM',
  'GTM',
  'GGY',
  'GIN',
  'GNB',
  'GUY',
  'HTI',
  'HMD',
  'VAT',
  'HND',
  'HKG',
  'HUN',
  'IRL',
  'ISL',
  'IND',
  'IDN',
  'IRQ',
  'IRN',
  'ISR',
  'ITA',
  'CIV',
  'JAM',
  'JPN',
  'YEM',
  'JEY',
  'JOR',
  'CYM',
  'CPV',
  'CMR',
  'KAZ',
  'KEN',
  'KGZ',
  'KIR',
  'KWT',
  'HRV',
  'LAO',
  'LSO',
  'LVA',
  'LBN',
  'LBR',
  'LBY',
  'LIE',
  'LTU',
  'LUX',
  'VIR',
  'VGB',
  'MAC',
  'MDG',
  'MWI',
  'MDV',
  'MYS',
  'MLI',
  'MLT',
  'IMN',
  'MAR',
  'MHL',
  'MTQ',
  'MRT',
  'MUS',
  'MYT',
  'MEX',
  'FSM',
  'MDA',
  'MCO',
  'MNG',
  'MNE',
  'MSR',
  'MOZ',
  'MMR',
  'NAM',
  'NRU',
  'NPL',
  'SWZ',
  'NIC',
  'NCL',
  'NZL',
  'NER',
  'NGA',
  'NIU',
  'PRK',
  'MKD',
  'MNP',
  'NOR',
  'NFK',
  'UGA',
  'UKR',
  'UZB',
  'OMN',
  'AUT',
  'PAK',
  'PLW',
  'PSE',
  'PAN',
  'PNG',
  'PRY',
  'PER',
  'PCN',
  'POL',
  'PRT',
  'PRI',
  'QAT',
  'REU',
  'ROU',
  'RUS',
  'RWA',
  'BLM',
  'KNA',
  'LCA',
  'VCT',
  'SPM',
  'SLB',
  'WSM',
  'SMR',
  'STP',
  'SAU',
  'SEN',
  'SRB',
  'SYC',
  'SLE',
  'SGP',
  'SXM',
  'SHN',
  'MAF',
  'SVN',
  'SVK',
  'SDN',
  'SOM',
  'ESP',
  'SJM',
  'LKA',
  'SUR',
  'SYR',
  'TJK',
  'TWN',
  'TZA',
  'THA',
  'TLS',
  'TGO',
  'TKL',
  'TON',
  'TTO',
  'TCD',
  'CZE',
  'TUN',
  'TUR',
  'TKM',
  'TCA',
  'TUV',
  'URY',
  'VUT',
  'VEN',
  'UMI',
  'GBR',
  'ARE',
  'USA',
  'VNM',
  'WLF',
  'ESH',
  'BLR',
  'ZMB',
  'ZWE',
  'ZAF',
  'SGS',
  'KOR',
  'SSD',
  'SWE',
  'CHE',
];

export const NATIONALITY = [
  'Mvbz0000',
  'Mvbz0001',
  'Mvbz0027',
  'Mvbz0028',
  'Mvbz0029',
  'Mvbz0030',
  'Mvbz0031',
  'Mvbz0032',
  'Mvbz0033',
  'Mvbz0034',
  'Mvbz0035',
  'Mvbz0036',
  'Mvbz0037',
  'Mvbz0038',
  'Mvbz0039',
  'Mvbz0040',
  'Mvbz0041',
  'Mvbz0042',
  'Mvbz0043',
  'Mvbz0044',
  'Mvbz0045',
  'Mvbz0046',
  'Mvbz0047',
  'Mvbz0048',
  'Mvbz0049',
  'Mvbz0050',
  'Mvbz0051',
  'Mvbz0052',
  'Mvbz0053',
  'Mvbz0054',
  'Mvbz0055',
  'Mvbz0056',
  'Mvbz0057',
  'Mvbz0058',
  'Mvbz0059',
  'Mvbz0060',
  'Mvbz0061',
  'Mvbz0062',
  'Mvbz0063',
  'Mvbz0064',
  'Mvbz0066',
  'Mvbz0067',
  'Mvbz0068',
  'Mvbz0069',
  'Mvbz0070',
  'Mvbz0071',
  'Mvbz0072',
  'Mvbz0073',
  'Mvbz0074',
  'Mvbz0076',
  'Mvbz0077',
  'Mvbz0079',
  'Mvbz0080',
  'Mvbz0081',
  'Mvbz0083',
  'Mvbz0084',
  'Mvbz0085',
  'Mvbz0086',
  'Mvbz0087',
  'Mvbz0100',
  'Mvbz0101',
  'Mvbz0104',
  'Mvbz0105',
  'Mvbz0106',
  'Mvbz0108',
  'Mvbz0109',
  'Mvbz0110',
  'Mvbz0111',
  'Mvbz0112',
  'Mvbz0113',
  'Mvbz0114',
  'Mvbz0115',
  'Mvbz0116',
  'Mvbz0117',
  'Mvbz0118',
  'Mvbz0119',
  'Mvbz0120',
  'Mvbz0121',
  'Mvbz0122',
  'Mvbz0123',
  'Mvbz0125',
  'Mvbz0126',
  'Mvbz0127',
  'Mvbz0128',
  'Mvbz0129',
  'Mvbz0130',
  'Mvbz0131',
  'Mvbz0132',
  'Mvbz0133',
  'Mvbz0134',
  'Mvbz0135',
  'Mvbz0136',
  'Mvbz0137',
  'Mvbz0138',
  'Mvbz0139',
  'Mvbz0140',
  'Mvbz0142',
  'Mvbz0143',
  'Mvbz0144',
  'Mvbz0145',
  'Mvbz0147',
  'Mvbz0148',
  'Mvbz0149',
  'Mvbz0151',
  'Mvbz0152',
  'Mvbz0154',
  'Mvbz0155',
  'Mvbz0156',
  'Mvbz0157',
  'Mvbz0200',
  'Mvbz0202',
  'Mvbz0204',
  'Mvbz0205',
  'Mvbz0206',
  'Mvbz0207',
  'Mvbz0208',
  'Mvbz0211',
  'Mvbz0212',
  'Mvbz0213',
  'Mvbz0214',
  'Mvbz0216',
  'Mvbz0218',
  'Mvbz0219',
  'Mvbz0222',
  'Mvbz0223',
  'Mvbz0250',
  'Mvbz0251',
  'Mvbz0252',
  'Mvbz0253',
  'Mvbz0254',
  'Mvbz0255',
  'Mvbz0256',
  'Mvbz0259',
  'Mvbz0261',
  'Mvbz0262',
  'Mvbz0263',
  'Mvbz0264',
  'Mvbz0265',
  'Mvbz0267',
  'Mvbz0268',
  'Mvbz0300',
  'Mvbz0301',
  'Mvbz0302',
  'Mvbz0304',
  'Mvbz0305',
  'Mvbz0306',
  'Mvbz0307',
  'Mvbz0308',
  'Mvbz0309',
  'Mvbz0310',
  'Mvbz0312',
  'Mvbz0313',
  'Mvbz0314',
  'Mvbz0315',
  'Mvbz0316',
  'Mvbz0317',
  'Mvbz0319',
  'Mvbz0320',
  'Mvbz0321',
  'Mvbz0322',
  'Mvbz0324',
  'Mvbz0325',
  'Mvbz0326',
  'Mvbz0327',
  'Mvbz0328',
  'Mvbz0329',
  'Mvbz0331',
  'Mvbz0333',
  'Mvbz0334',
  'Mvbz0335',
  'Mvbz0336',
  'Mvbz0337',
  'Mvbz0338',
  'Mvbz0339',
  'Mvbz0341',
  'Mvbz0342',
  'Mvbz0345',
  'Mvbz0400',
  'Mvbz0401',
  'Mvbz0402',
  'Mvbz0405',
  'Mvbz0421',
  'Mvbz0424',
  'Mvbz0425',
  'Mvbz0430',
  'Mvbz0431',
  'Mvbz0432',
  'Mvbz0437',
  'Mvbz0442',
  'Mvbz0443',
  'Mvbz0444',
  'Mvbz0445',
  'Mvbz0446',
  'Mvbz0447',
  'Mvbz0448',
  'Mvbz0449',
  'Mvbz0450',
  'Mvbz0451',
  'Mvbz0452',
  'Mvbz0454',
  'Mvbz0455',
  'Mvbz0499',
];

export const TYPEOFOTHERINCOME = ['Alimony', 'BoxTwo', 'Different', 'Donation'];

export const taskSolutionGenericCommentKey = 'genericComment';

export const ApplicationStatus = Object.freeze({
  new: 'new',
  inProgress: 'inProgress', // Aanvraag in behandeling
  rejected: 'rejected', // Afgewezen
  terminated: 'terminated', // Beeindigd
  offerMade: 'offerMade', // Rente aanbod aanleveren
  offerDocuments: 'offerDocuments', // Overeenkomst aanleveren
  waitingForReview: 'waitingForReview', // Wacht op beoordeling
  offerAdditionalDocuments: 'offerAdditionalDocuments', // Aanvullende Documenten aanleveren
  inFirstReview: 'inFirstReview', // In 1e beoordeling
  inSecondReview: 'inSecondReview', // In 2e beoordeling
  agreementMade: 'agreementMade', // Overeenkomst aanleveren
  offerAdministrativeDocuments: 'offerAdministrativeDocuments', // Administratieve documenten aanleveren
  inAdministrativeReview: 'inAdministrativeReview', // In administratieve beoordeling
  creditAccepted: 'creditAccepted', // Krediet geaccepteerd
  notaryDocumentsSent: 'notaryDocumentsSent', // Notarisstukken verzonden
  managingApplications: 'managingApplications', // Aanvraag in beheer
  unknown: 'unknown', // Onbekend
});

export const MidofficeApplicationType = Object.freeze({
  NewLoan: 'NewLoan',
  Transfer: 'Transfer',
  Move: 'Move',
  ChangeProductInternal: 'ChangeProductInternal',
  ChangeProduct: 'ChangeProduct',
  Mutation: 'Mutation',
});

export const MidofficeApplicationSubType = Object.freeze({
  Conversion: 'Conversion',
  ConversionRaise: 'ConversionRaise',
  ChangeLiability: 'ChangeLiability',
  Extend: 'Extend',
});

export const ApplicationType = Object.freeze({
  NewLoan: 'NewLoan',
  Mutation: 'Mutation',
  ChangeLiability: 'ChangeLiability',
  Extend: 'Extend',
  Unknown: 'Unknown',
  Move: 'Move',
});
