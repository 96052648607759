import { SelectChangeEvent } from '@mui/material';
import FabMobile from 'components/FabMobile';
import MultiSelect from 'components/MultiSelect';
import Select from 'components/Select';
import { ApplicationType } from 'constants/index';
import { useTranslation } from 'react-i18next';
import { APPLICATION_TYPES, SORT_OPTIONS_CONCEPTS } from 'screens/Applications/constants';
import { FilterState } from 'types/ApplicationDataDetails';
import { ApplicationData } from '__generated__/api';

type ConceptsFiltersProps = {
  filterState: FilterState;
  items?: ApplicationData[];
  handleMultiSelectChange: (e: SelectChangeEvent<any>) => void;
  handleSingleFilterChange: (e: SelectChangeEvent<any>) => void;
  isMobile?: boolean;
  onClick?: () => void;
};

export default function ConceptsFilters({
  filterState,
  handleMultiSelectChange,
  handleSingleFilterChange,
  items,
  isMobile,
  onClick,
}: ConceptsFiltersProps) {
  const { t } = useTranslation();

  const ApplicationTypeOptions = APPLICATION_TYPES.filter((at) => {
    return at !== ApplicationType.Unknown;
  }).map((type) => ({
    value: type,
    text: t(`applications.type.${type}`),
  }));

  if (isMobile) {
    return (
      <FabMobile onClickNew={onClick}>
        <MultiSelect
          label="type"
          selectedValue={filterState.type}
          handleChange={handleMultiSelectChange}
          options={ApplicationTypeOptions}
          selectAll
        />

        <Select
          label="sortBy"
          selectedValue={filterState.sortBy}
          handleChange={handleSingleFilterChange}
          options={SORT_OPTIONS_CONCEPTS}
        />
      </FabMobile>
    );
  }

  return (
    <>
      <MultiSelect
        label="type"
        selectedValue={filterState.type}
        handleChange={handleMultiSelectChange}
        options={ApplicationTypeOptions}
        selectAll
      />

      <Select
        label="sortBy"
        selectedValue={filterState.sortBy}
        handleChange={handleSingleFilterChange}
        options={SORT_OPTIONS_CONCEPTS}
      />
    </>
  );
}
