import { useQuery, useMutation } from 'react-query';
import {
  getCasesData,
  getCaseDetails,
  getASRCaseDetails,
  updateAsrCasesDetails,
  sendAsrApplicationEx,
  getAllCases,
} from '../api/cases';
import { useGroup } from './group';
import { queryClient } from '../config/react-query';
import { Case, CaseDetails } from '__generated__/api-di';

async function getCases(group: string | null, commercialRelation: string | null): Promise<Case[]> {
  const casesList = await getCasesData(group, commercialRelation);
  return casesList;
}
interface Id {
  commercialLabel: string;
  loanNumberExternal: number;
  applicationIndexNumber: number;
}

export const getId = (application: Case): Id => ({
  commercialLabel: application.lender!.label!,
  loanNumberExternal: application.application!.loanNumberExternal!,
  applicationIndexNumber: application.application!.index!,
});

async function getApplicationDetailsEx(id: Id, group: string | null): Promise<CaseDetails> {
  const { commercialLabel, loanNumberExternal, applicationIndexNumber } = id;
  const applicationDetails = await getCaseDetails(
    {
      commercialLabel,
      loanNumberExternal,
      applicationIndexNumber,
    },
    group
  );
  return applicationDetails;
}

async function getAsrDetailsEx(id: number, group: string | null): Promise<any> {
  const asrDetails = await getASRCaseDetails(id, group);
  return asrDetails;
}

export function useQueryAllCases() {
  const group = useGroup();
  return useQuery(['cases', group], () => getAllCases(group), {
    enabled: !!group,
  });
}

export function useQueryCases() {
  const commercialRelation = localStorage.getItem('commercialRelation');

  const group = useGroup();
  return useQuery(['cases', group, commercialRelation], () => getCases(group, commercialRelation), {
    enabled: !!group,
  });
}

export function optimisticUpdateApplicationEx(
  commercialLabel: string,
  loanNumberExternal: number,
  applicationIndexNumber: number,
  group: string | null
) {
  if (group) {
    const queryKey = ['cases', group];
    queryClient.cancelQueries('cases');

    // mutate application
    const applications = queryClient.getQueryData<Case[]>(queryKey);
    const updatedApplication = applications?.find((application) => {
      return (
        application.lender?.label === commercialLabel &&
        application.application?.loanNumberExternal === loanNumberExternal &&
        application.application?.index === applicationIndexNumber
      );
    }) as any;
    const updatedReceivedProofItems = updatedApplication.numberOfReceivedProofItems + 1;
    updatedApplication.numberOfReceivedProofItems = updatedReceivedProofItems.toString();

    queryClient.setQueryData(queryKey, applications);
    queryClient.invalidateQueries(queryKey);
  }
}

export function useQueryApplicationDetailsEx(id: Id, enabled: boolean = true) {
  const group = useGroup();
  return useQuery(['case-details', id, group], () => getApplicationDetailsEx(id, group), {
    enabled: !!group && enabled,
  });
}

export function useQueryAsrApplicationDetailsEx(id: number) {
  const group = useGroup();
  const { status, error, isError, isFetching, data } = useQuery(
    ['asr-details', id, group],
    () => getAsrDetailsEx(id, group),
    { enabled: !!group && !!id }
  );

  return {
    status,
    error,
    isError,
    isFetching,
    data,
  };
}

export function updateAsrApplicationEx(id: number, group: string | null, asrDetails: any) {
  return updateAsrCasesDetails(id, group, asrDetails);
}

export function useMutationAsrApplicationEx() {
  const group = useGroup();
  return useMutation((asrDetails: any) => sendAsrApplicationEx(asrDetails, group as string));
}
