import { Dialog, IconButton, Box, Hidden, DialogProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import React, { FC } from 'react';
import backgroundImageStater from 'assets/images/half.png';
import { SlideUpTransition } from 'lib/Transition/transition';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      display: 'flex',
    },
  },
  header: {
    height: 92,
    padding: theme.spacing(3, 5),
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.textBody.main,
    boxShadow: '0 6px 12px 0 rgba(22,20,47,0.12)',
    position: 'relative',
    zIndex: 1300,
  },
  logo: {
    width: 150,
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(4),
  },
  wrapper: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'row',
  },
  sidebar: {
    maxWidth: 400,
    minWidth: 200,
    backgroundColor: theme.palette.purple.dark,
    backgroundImage: `url("${backgroundImageStater}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 70px',
  },
  content: {
    minHeight: '100vh',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 900,
    margin: '60px 100px',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4),
    },
  },
  spacer: {
    flexGrow: 1,
  },
  paper: {
    'width': '100%',
    'padding': theme.spacing(2, 3.5),
    'borderRadius': '8px',
    '& hr': {
      marginLeft: `calc(40px + ${theme.spacing(2)})`,
      backgroundColor: 'rgba(0,0,0,0.08)',
    },
  },
}));

interface ApplicationModalProps extends DialogProps {
  onClose: () => void;
  logo: string;
  sidebar?: React.ReactNode;
}

const ApplicationModal: FC<ApplicationModalProps> = ({
  open,
  onClose,
  logo,
  sidebar,
  children,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Dialog
      {...props}
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={SlideUpTransition}
      className={classes.root}
    >
      <div className={classes.header}>
        <img alt="logo" className={classes.logo} src={logo} />
        <span className={classes.spacer} />
        <IconButton onClick={onClose} size="large">
          <Close data-testid="closeBuyToLetDialog" />
        </IconButton>
      </div>

      <Box className={classes.wrapper}>
        <Hidden smDown>
          <Box className={classes.sidebar}>{sidebar}</Box>
        </Hidden>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </Dialog>
  );
};

export default ApplicationModal;
