import { MoneyLenderTilesProps } from 'types/MoneyLenders';
import { HEADER_SELECTED_GROUP_CLAIM, http } from '../http';

export const getMoneyLenderTiles = async (
  group: string | null
): Promise<MoneyLenderTilesProps[]> => {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };

  const response = await http.get('api/moneylenders/tiles', {
    headers,
  });

  return response.data;
};
