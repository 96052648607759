import { ProofItem } from '../../types/ProofItem';

export function createProofItem(
  code: number,
  documentId: number,
  description: string | undefined = ''
): ProofItem {
  return {
    category: 'Miscellaneous',
    numberOfUploads: null,
    status: 'Approved',
    dateRejected: null,
    dateReceived: '',
    reasonForRejection: null,
    dateLastReminder: null,
    code: code,
    dateApproved: null,
    shortDescription: description,
    description: description,
    supplier: 'TODO',
    approvalDate: null,
    documentId: documentId,
    isOckto: false,
    isUploadAllowed: true,
  };
}
