import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Toolbar,
  IconButton,
  Fab,
  Icon,
  Typography,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Close } from '@mui/icons-material';
import { useGoogleTagManager } from 'use/gtm';

import { useGroup } from 'use/group';
import { LoanInfo, MutationInfo, uploadMutationForm } from './useMutation';
import MutationFlowStepSelectFile from './MutationFlowSelectFile';
import Logo from '../Logo';
import FormTemplate from 'components/Templates/FormTemplate';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

const useStyles = makeStyles((theme) => ({
  logo: {
    marginRight: theme.spacing(2),
  },
  headerTitle: {
    fontWeight: 'bolder',
  },
  fillSpace: {
    flex: 1,
  },
  stepper: {
    backgroundColor: 'transparent',
  },
  stepConnector: {
    borderColor: 'transparent',
  },
  stepLabel: {
    'width': 250,
    'height': 40,
    'lineHeight': '40px',
    'padding': theme.spacing(0, 3),
    'borderRadius': theme.shape.borderRadius,
    'color': theme.palette.common.white,
    'fontSize': 14,
    'fontWeight': 500,
    '&.Mui-active': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-completed': {
      color: theme.palette.common.white,
    },
  },
  bottomNav: {
    'position': 'fixed',
    'bottom': theme.spacing(3),
    'right': theme.spacing(3),
    'left': 424,
    'display': 'flex',
    '& > *': {
      marginLeft: theme.spacing(-2),
    },
  },
  fabIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const maxFilesizeMb = (window as any).REACT_APP_UPLOAD_MAX_FILE_SIZE_IN_MB;

type MutationFlowProps = {
  loanNumber: string;
  commercialLabel: string;
  loanInfo: LoanInfo;
  selectedMutation: MutationInfo;
  onClose: () => void;
  onSuccess: () => void;
};

const MutationFlow = (props: MutationFlowProps) => {
  const { loanNumber, commercialLabel, loanInfo, selectedMutation, onClose, onSuccess } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const group = useGroup();
  const [selectedFile, setSelectedFile] = useState<File>();
  const selectFile = (file: any) => setSelectedFile(file);
  const googleTagManager = useGoogleTagManager();

  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const isSubmitDisabled = loading || !selectedMutation || !selectedFile;

  const onSubmit = async () => {
    try {
      if (selectedFile && selectedMutation) {
        setLoading(true);
        await uploadMutationForm(
          loanNumber,
          selectedMutation,
          selectedFile,
          group,
          commercialLabel
        );
        onSuccess();
        googleTagManager({
          event: 'Upload',
          uploadSuccesful: true,
        });
      }
    } catch (e: any) {
      setError(e.message);
      googleTagManager({
        event: 'Upload',
        errorMessage: e.message,
        errorType: e.type,
        uploadSuccesful: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const mutations = loanInfo?.mutations ?? [];

  if (mutations.length === 0) {
    onClose();
  }

  return (
    <>
      <FormTemplate
        ispopup
        header={
          <Toolbar>
            <Logo label={commercialLabel} size="M" className={classes.logo} />
            <Typography className={classes.headerTitle}>{t('mutations.header.title')}</Typography>
            <span className={classes.fillSpace} />
            <IconButton edge="end" onClick={onClose} size="large">
              <Close />
            </IconButton>
          </Toolbar>
        }
        sidebar={
          <Stepper
            className={classes.stepper}
            orientation="vertical"
            connector={
              <StepConnector
                classes={{
                  line: classes.stepConnector,
                }}
              />
            }
          >
            <Step>
              <StepLabel
                classes={{
                  label: classes.stepLabel,
                }}
              >
                {t('mutations.stepFileSelectTitle')}
              </StepLabel>
            </Step>
          </Stepper>
        }
        footer={
          <div className={classes.bottomNav}>
            <span className={classes.fillSpace} />
            <Fab
              variant="extended"
              color="primary"
              size="medium"
              disabled={isSubmitDisabled}
              onClick={onSubmit}
            >
              {t('mutations.uploadAndSubmit') as string}
              <Icon className={classes.fabIcon}>arrow_right</Icon>
            </Fab>
          </div>
        }
      >
        <MutationFlowStepSelectFile
          commercialLabel={commercialLabel}
          selectedMutation={selectedMutation}
          maxFilesizeMb={maxFilesizeMb}
          selectedFile={selectedFile}
          selectFile={selectFile}
          setError={setError}
        />
      </FormTemplate>

      <CustomSnackbar
        isOpen={!!error}
        handleClose={() => setError(undefined)}
        severity="error"
        message={
          error === 'fileTooLarge'
            ? t('error.fileTooLarge', { maxMB: maxFilesizeMb })
            : t('error.default')
        }
      />
    </>
  );
};

export default MutationFlow;
