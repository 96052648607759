import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NavLink, Icon } from '../NavDrawer.styled';
import Item from './Item';
import { RouteConfig } from 'routes';
import NotificationCount from 'components/NotificationList/NotificationCount';
import DownloadsCounter from './DownloadsCounter';

type NavListItemProps = {
  route: RouteConfig;
  onClick: (routeName: string) => void;
  commercialRelation: string | null;
};

const NavListItem: FC<NavListItemProps> = ({ route, onClick, commercialRelation }) => {
  const { t } = useTranslation();

  const [path, setPath] = useState<string>('/');

  useEffect(() => {
    if (!route.path) return;
    if (commercialRelation) {
      const path = route.path.replace(':commercialRelation', commercialRelation);
      setPath(path);
    } else {
      setPath(route.path as string);
    }
  }, [route, commercialRelation, path]);

  const isNotificationNumber: boolean = route.name === 'notifications';
  const isDownloadsNumber: boolean = route.name === 'downloads';

  return (
    <NavLink to={path as string} key={route.name}>
      <Item
        icon={<Icon fontSize="inherit">{route.icon}</Icon>}
        menuName={t(`general.header.menu.${route.name}`)}
        handleClick={() => onClick(route.name)}
      >
        <>
          {isNotificationNumber && <NotificationCount size="S" />}
          {isDownloadsNumber && <DownloadsCounter />}
        </>
      </Item>
    </NavLink>
  );
};

export default NavListItem;
