import {
  RootFormName,
  PersonalInfoFormType,
  ObligationFormType,
  ContactDetailsFormType,
  IncomeFormType,
  CollateralFormType,
  CostAndResourcesFormType,
  LoanFormType,
  OHAForm,
  AdditionalInfoFormType,
} from '../../../../../utils/types';
import { personalInfoSchema } from '../../../../../schemas/personalInfo';
import { contactDetailsSchema } from '../../../../../schemas/contactDetails';
import { incomesSchema } from '../../../../../schemas/income';
import obligationsSchema from '../../../../../schemas/obligation/obligationSchema';
import { collateralSchema } from '../../../../../schemas/collateral';
import { costAndResourcesSchema } from '../../../schema/costAndResources';
import { loanSchema } from '../../../schema/loan';
import { getPoliciesFormSchema } from '../../../../../schemas/policies/policiesSchema';
import additionalInformationSchema from '../../../../../schemas/additionalInformationSchema';
import { steps } from '../../../constants';
import mapToggleButton from '../../../../../utils/mappers/mapToggleButton';
import { hasPolicy } from 'screens/Mutations/components/DismissalJointLiabilityMutation/utils';

export const isPersonalInfoStepComplete = (
  state: PersonalInfoFormType,
  rootFormName: RootFormName
): boolean => personalInfoSchema(rootFormName).isValidSync(state);

export const isContactDetailsStepComplete = (state: ContactDetailsFormType): boolean =>
  contactDetailsSchema.isValidSync(state);

export const isIncomeStepComplete = (state: IncomeFormType): boolean =>
  incomesSchema.isValidSync(state);

export const isObligationsStepComplete = (state: ObligationFormType): boolean =>
  obligationsSchema.isValidSync(state);

export const isCollateralStepComplete = (state: CollateralFormType): boolean =>
  collateralSchema.isValidSync(state);

export const isCostAndResourcesStepComplete = (state: CostAndResourcesFormType): boolean =>
  costAndResourcesSchema.isValidSync(state);

export const isLoanStepComplete = (state: LoanFormType): boolean => loanSchema.isValidSync(state);

export const isPolicyStepComplete = (state: LoanFormType): boolean =>
  getPoliciesFormSchema().isValidSync(state);

export const isAdditionalInfoStepComplete = (state: {
  additionalInformation: AdditionalInfoFormType | undefined;
}): boolean => additionalInformationSchema.isValidSync(state);

export const getIncompleteStep = (state: OHAForm): string => {
  const hasNewApplicant = mapToggleButton(state.newApplicant.personalInfo.hasNewApplicant);

  switch (false) {
    case isPersonalInfoStepComplete(state.currentApplicant.personalInfo, 'currentApplicant'):
      return steps.currentApplicant.personalInfo;
    case isContactDetailsStepComplete(state.currentApplicant.contactDetails):
      return steps.currentApplicant.contactDetails;
    case isIncomeStepComplete(state.currentApplicant.income):
      return steps.currentApplicant.income;
    case isObligationsStepComplete(state.currentApplicant.obligation):
      return steps.currentApplicant.obligations;
    case isPersonalInfoStepComplete(state.newApplicant.personalInfo, 'newApplicant'):
      return steps.newApplicant.personalInfo;
    case hasNewApplicant
      ? isContactDetailsStepComplete(state.newApplicant.contactDetails)
      : 'disabled':
      return steps.newApplicant.contactDetails;
    case hasNewApplicant ? isIncomeStepComplete(state.newApplicant.income) : 'disabled':
      return steps.newApplicant.income;
    case hasNewApplicant ? isObligationsStepComplete(state.newApplicant.obligation) : 'disabled':
      return steps.newApplicant.obligations;
    case isCollateralStepComplete(state.collateral):
      return steps.collateral;
    case isCostAndResourcesStepComplete(state.costAndResources):
      return steps.financingCostAndResources;
    case isLoanStepComplete(state.loan):
      return steps.financingLoan;
    case hasPolicy(state.loan) ? isPolicyStepComplete(state.loan) : 'disabled':
      return steps.financingConnectedPolicy;
    case isAdditionalInfoStepComplete({ additionalInformation: state.additionalInformation }):
      return steps.additionalInfo;
    default:
      return steps.summary;
  }
};
