import { Person } from 'types/Client';
import {
  CivilStatus,
  Country,
  Gender,
  IntermediaryReadDto,
  LegalForm,
  Status,
  TypeOfBenefit,
  TypeOfCompany,
  TypeOfEmployment,
  TypeOfFinancing,
  TypeOfLoan,
  TypeOfObligation,
  TypeOfOtherIncome,
  TypeOfPolicyMutation,
  TypeOfRedemption,
} from '../../../__generated__/api';

export type MutationType =
  | 'Conversion'
  | 'Raise'
  | 'ConversionWithRaise'
  | 'DismissalJointLiability'
  | 'CollateralValue';

export type ToggleType = 'yes' | 'no';
export type TypeOfPart = 'currentLoanParts' | 'newLoanParts' | 'convertLoan' | 'splitLoan';

export type FormStepProps = {
  title: string;
  subtitle: string;
  secondParagraphDescription?: string;
};

export type ApplicantFormStepProps = FormStepProps & {
  rootFormName: RootFormName;
};

export type SplitLoan = {
  amount?: number;
  fixedRatePeriod?: number;
  repaymentType?: string;
  NHGGuarantee?: ToggleType;
  box1?: number;
  box3?: number;
  interestPercentage?: number;
  endDateInterestDeduction?: string;
  hasDurationPeriodChange?: ToggleType;
  newDuration?: number;
};

export type ConvertLoan = {
  repaymentType?: string;
  fixedRatePeriod?: number;
  NHGGuarantee?: ToggleType;
};

export type LoanPartType = {
  loanPartNumber?: number;
  repaymentType?: string;
  shouldSplitLoan?: ToggleType;
  NHGGuarantee?: ToggleType;
  hasConnectedPolicy?: ToggleType;
  splitLoan?: SplitLoan[];
  loanAmount?: number;
  box1?: number;
  box3?: number;
  interestPercentage?: number;
  endDateInterestDeduction?: string;
  hasDurationPeriodChange?: ToggleType;
  newDuration?: number;
  hasInterestRedemption?: ToggleType;
  convertLoan?: ConvertLoan;
  numberOfSplitLoanParts?: number;
  fixedRatePeriod?: number;
  typeOfFinancing?: TypeOfFinancing;
  policies?: Policy[];
};

export type ConversionQuestionnaire = {
  loanNumber: number;
  residualDebt?: number;
  toggleInterest?: ToggleType;
  toggleFine?: ToggleType;
  toggleCoFinancedFine?: ToggleType;
};

export type CollateralValueQuestionnaire = {
  loanNumber: number;
  valuationType?: 'valuationReport' | 'WOZ-order';
};

export type ApplicantDetails = {
  initials?: string;
  lastName?: string;
  middleName?: string;
};
export type AdditionalInfoProps = {
  name?: string;
  phoneNumber?: string;
  info?: string;
};

export type LoanDetailsFormType = {
  conversionQuestionnaire: ConversionQuestionnaire;
  loanParts: LoanPartType[];
  status: Status;
  loanPartPolicies?: LoanPartPolicy[];
  id?: number;
  typeOfLoan: TypeOfLoan;
  commercialLabel: string;
  moneylenderName?: string;
  applicants?: ApplicantDetails[] | [];
  additionalInformation?: AdditionalInfoProps;
};

export type CollateralLoanDetailsFormType = {
  collateralValueQuestionnaire: CollateralValueQuestionnaire;
};

export type ConnectedPoliciesFormType = {
  loanPartPolicies: LoanPartPolicy[];
};

export type LoanPartPolicy = {
  loanPartNumber?: number;
  policies: Policy[];
};

export type ConversionForm = {
  loanDetails: LoanDetailsFormType;
  connectedPolicies: ConnectedPoliciesFormType;
  acceptedAgreement?: boolean;
  applicants?: Person[];
  intermediary?: IntermediaryReadDto;
  result?: string;
};

export type MutationForm = {
  id?: number;
  reference?: string | null;
};

export type OHAForm = MutationForm & {
  commercialLabel?: string | null;
  loanNumber: number;
  moneylenderName?: string | null;
  intermediary?: IntermediaryReadDto;
  currentApplicant: Applicant;
  newApplicant: Applicant;
  collateral: CollateralFormType;
  loan: LoanFormType;
  costAndResources: CostAndResourcesFormType;
  productOptions: Option[];
  additionalInformation: AdditionalInfoFormType;
  result?: string;
};

export type AdditionalInfoFormType = {
  name?: string;
  phoneNumber?: string;
  info?: string;
};

export type ConnectedPersons = {
  personOne?: string;
  personTwo?: string;
};

export type UrlParams = {
  loanNumber: string;
  commercialLabel: string;
  applicationIndexNumber?: string;
  commercialRelation?: string;
};

export type Policy = {
  policyNumber?: string;
  numberOfPolicyHolders?: number;
  policyHolders?: ConnectedPersons;
  isPolicyForConnectedPersons?: ToggleType;
  numberOfInsuredPersons?: number;
  insuredPersons?: ConnectedPersons;
  typeOfPolicyMutation?: TypeOfPolicyMutation;
  mutationDescription?: string;
  policyAmount?: number;
  originalLoanPart?: number;
};

export type Option = {
  label: string;
  value: string | number;
};

export type Notification = 'error' | 'info' | 'success' | 'warning';

export type Step = string;

export type CardRow = {
  label: string;
  value: string | number | undefined;
  testId?: string;
};

export type CardValues = {
  headerName: string;
  values: CardRow[];
};

export type SummaryCard = {
  title: string;
  subtitle: string;
  step: string;
  values: CardValues[];
};

export type BasePersonalInfo = {
  initials?: string;
  firstNames?: string;
  insertion?: string;
  surname?: string;
  dateOfBirth?: string | null | Date;
  maritalStatus?: CivilStatus;
  gender?: Gender;
};

export type PersonalInfoFormType = BasePersonalInfo & {
  maritalStatusVSOld?: CivilStatus;
  numberOfDivorces?: number;
  children?: number;
  homeOwner?: ToggleType;
  hasNewApplicant?: ToggleType;
};

export type CollateralFormType = {
  distributionValue?: number;
  marketValue?: number;
  valuationCosts?: number;
};

export type LoanFormType = {
  loanNumber: number;
  amount?: number;
  extraPayOffAmount?: number;
  changeLifeInsurancePolicy?: ToggleType;
  changeCurrentMortgage?: ToggleType;
  currentLoanParts: LoanPartType[];
  newLoanParts: LoanPartType[];
  policies: Policy[];
  amountRaise?: number | null;
};

export type CostAndResourcesFormType = {
  hasBuyoutAmount?: ToggleType;
  buyoutAmount?: number;
  typeOfBuyoutFinancing?: TypeOfFinancing;
  buyoutAmountByPersonalEquity?: number;
  consultancyCosts?: number;
  notaryCosts?: number;
  nhgCosts?: number;
  addedPersonalEquity?: number;
};

export type ContactDetailsFormType = {
  street: string;
  houseNumber?: number;
  houseNumberSuffix?: string;
  postalCode: string;
  city: string;
  country?: Country;
  phoneNumber?: string;
  email: string;
};

export type Obligation = Redemption & {
  typeOfObligation: TypeOfObligation;
  redemptionTypeDisplayName: string | null;
  isObligationByBKR: ToggleType;
  principalDebt: number;
  obligationAmount: number;
  redemptionByNewMortgage: ToggleType;
  partialRedemptionByPersonalEquity: ToggleType;
  repayableAmount: number;
};

type Redemption = {
  actualDebt?: number | null;
  typeOfRedemption?: TypeOfRedemption;
  startDate?: string | null;
  endDate?: string | null;
  fixedInterestPeriod?: number | null;
  interestPercentage?: number | null;
  amountTaxBox1?: number | null;
  endDateInterestDeduction?: string | null;
  amountTaxBox3?: number | null;
  registryRank?: number | null;
};

export type ObligationFormType = {
  id?: number;
  obligations: Obligation[];
};

export type BaseApplicant<P = BasePersonalInfo> = {
  personalInfo: P;
  contactDetails: ContactDetailsFormType;
  income: IncomeFormType;
  obligation: ObligationFormType;
};

// OHA applicant
export type Applicant = BaseApplicant<PersonalInfoFormType>;

export type RootFormName = 'currentApplicant' | 'newApplicant';

export type EntrepreneurIncome = {
  typeOfCompany?: TypeOfCompany;
  legalForm?: LegalForm;
  startDate: string | null;
  hasIncomeStatement: ToggleType;
  incomeAmount?: number;
  incomePreviousFirstYear?: number;
  incomePreviousSecondYear?: number;
  incomePreviousThirdYear?: number;
};

export type EmploymentIncome = {
  typeOfEmployment: TypeOfEmployment;

  startDate?: string | null;
  endDate?: string | null;

  averageHoursPerWeek?: number | null;
  grossSalary?: number | null;
  holidayAllowance?: number | null;
  irregularHoursAllowance?: number | null;
  thirteenthMonth?: number | null;
  lumpSumFee?: number | null;
  yearEndBonus?: number | null;
  overtimeAllowance?: number | null;

  // flexible without perspective
  flexibleAnnualIncome1?: number | null;
  flexibleAnnualIncome2?: number | null;
  flexibleAnnualIncome3?: number | null;

  // social insurance wage
  // todo: new Type
  incomeAmount?: number;
  numberOfEmployments?: number | null;
  pensionContribution?: number | null;
};

export type SocialSecurityIncome = {
  typeOfBenefit?: TypeOfBenefit;
  startDate: string | null;
  endDate?: string | null;
  incomeAmount?: number;
};

export type OtherIncome = {
  typeOfOtherIncome?: TypeOfOtherIncome;
  incomeAmount?: number;
  endDate?: string | null;
};

export type IncomeFormType = {
  id?: number;
  incomes: Income[];
  isIncome?: boolean;
  hasIncome?: string;
};

export type Income = {
  typeOfIncome?: TypeOfIncome;
  entrepreneur?: EntrepreneurIncome;
  otherIncome?: OtherIncome;
  socialSecurity?: SocialSecurityIncome;
  pension?: SocialSecurityIncome;
  employment?: EmploymentIncome;
};

export enum TypeOfIncome {
  Employment = 'Employment',
  Entrepreneur = 'Entrepreneur',
  Pension = 'Pension',
  SocialSecurity = 'SocialSecurity',
  Other = 'Other',
}
