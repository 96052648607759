import { FC } from 'react';
import { useQueryApplicationLetterDocument } from 'use/letters';
import ViewerDialog from './ViewerDialog';

type Props = {
  commercialLabel: string;
  index: string;
  letterId: string;
  open: boolean;
  onClose: () => void;
  loanNumber: string;
  displayName: string;
};

const ViewApplicationFile: FC<Props> = ({
  commercialLabel,
  index,
  letterId,
  open,
  onClose,
  loanNumber,
  displayName,
}) => {
  const document = useQueryApplicationLetterDocument(commercialLabel, index, letterId, loanNumber);

  return (
    <ViewerDialog document={document} open={open} onClose={onClose} displayName={displayName} />
  );
};

export default ViewApplicationFile;
