/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 * from: https://stackoverflow.com/a/38327540
 *
 * @param array An array of type V.
 * @param iteratee The iteratee to transform keys.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export default function groupBy<K, V>(
  array: Array<V>,
  iteratee: (input: V) => K
): Map<K, Array<V>> {
  const map = new Map<K, Array<V>>();
  array.forEach((item) => {
    const key = iteratee(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
