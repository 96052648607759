import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useGoogleTagManager } from 'use/gtm';

import MutationFlow from './MutationFlow';
import { SlideUpTransition } from '../../lib/Transition/transition';
import { LoanInfo, MutationInfo } from './useMutation';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import { MutationType } from '__generated__/api';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      display: 'flex',
      overflowY: 'scroll',
    },
  },
}));

type MutationDialogProps = {
  open: boolean;
  onClose: () => void;
  loanNumber: string;
  commercialLabel: string;
  loanInfo: LoanInfo;
  selectedMutation: MutationInfo | undefined;
};

const MutationDialog = (props: MutationDialogProps) => {
  const googleTagManager = useGoogleTagManager();
  const { open, loanNumber, loanInfo, selectedMutation, commercialLabel, onClose } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [success, setSucces] = useState(false);
  const onSuccess = () => {
    setSucces(true);
    onClose();
  };
  const onCloseModal = () => {
    onClose();
    googleTagManager({
      event: 'ButtonClick',
      btnName: 'ExitMutation',
    });
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={SlideUpTransition}
        className={classes.root}
        onClose={onCloseModal}
      >
        {selectedMutation && (
          <MutationFlow
            loanNumber={loanNumber}
            commercialLabel={commercialLabel}
            loanInfo={loanInfo}
            onClose={onCloseModal}
            onSuccess={onSuccess}
            selectedMutation={selectedMutation}
          />
        )}
      </Dialog>

      <CustomSnackbar
        isOpen={success}
        handleClose={() => setSucces(false)}
        severity="success"
        message={selectedMutation?.type === MutationType.Transfer ? t('mutations.meenemen.success') : t('mutations.success')}
      />
    </>
  );
};

export default MutationDialog;
