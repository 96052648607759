import { Close } from '@mui/icons-material';
import React, { FC } from 'react';
import {
  Toolbar,
  HeaderLogo,
  HeaderTitleBox,
  HeaderTitleText,
  HeaderSubtitleText,
  HeaderSubtitleBox,
  MoneyLenderText,
  TitleBarButtonsContainer,
} from './Titlebar.styled';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import { UrlParams } from 'screens/Mutations/utils/types';

export type TitleBarProps = {
  title?: string;
  moneyLender?: string;
  children?: React.ReactElement | React.ReactElement[];
  onCloseClick?: () => void;
};

const TitleBar: FC<TitleBarProps> = ({ moneyLender, children, title, onCloseClick }) => {
  const { t } = useTranslation();
  const { commercialLabel } = useParams<keyof UrlParams>() as UrlParams;

  return (
    <Toolbar>
      <HeaderTitleBox>
        {commercialLabel && <HeaderLogo size="M" label={commercialLabel} />}
        <Box>
          <HeaderTitleText data-testid="titleBar-title">{title}</HeaderTitleText>
          {commercialLabel && (
            <HeaderSubtitleBox>
              <HeaderSubtitleText data-testid="titleBar-subtitle">
                {t('directMutations.header.subtitle')}
              </HeaderSubtitleText>
              <MoneyLenderText data-testid="titleBar-moneylender">{moneyLender}</MoneyLenderText>
            </HeaderSubtitleBox>
          )}
        </Box>
      </HeaderTitleBox>
      <TitleBarButtonsContainer>
        {children}
        <Divider orientation="vertical" flexItem />
        <IconButton data-testid="titleBar-closeButton" onClick={onCloseClick} edge="end">
          <Close />
        </IconButton>
      </TitleBarButtonsContainer>
    </Toolbar>
  );
};

export default TitleBar;
