import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import {
  ListItemSecondaryAction,
  Button,
  Icon,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  MenuProps,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { TaskInfoDelegationState } from '../../__generated__/api-di';

const useStyles = makeStyles((theme) => ({
  root: {
    top: theme.spacing(2),
    transform: 'none',
    [theme.breakpoints.down('md')]: {
      top: 0,
      right: 0,
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  firstButton: {
    width: 'auto',
    marginRight: theme.spacing(1),
  },
  button: {
    width: 'auto',
  },
  loader: {
    marginRight: 42,
  },
  icon: {
    transform: 'scale(0.8)',
  },
  paper: {
    padding: theme.spacing(2),
    width: 350,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const StyledMenu = withStyles((theme) => ({
  paper: {
    boxShadow: '0 6px 12px 0 rgba(22,20,47,0.12)',
    color: theme.palette.text.secondary,
    fontWeight: 300,
  },
}))((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      'backgroundColor': theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    'minWidth': 'initial',
  },
}))(MenuItem);

const StyledListItemIcon = withStyles((theme) => ({
  root: {
    minWidth: '24px',
    width: '24px',
    height: '28px',
  },
}))(ListItemIcon);

type TaskButtonsProps = {
  status: TaskInfoDelegationState;
  commentAvailable: boolean;
  solvedByOtherIssueAvailable: boolean;
  onClickAddComment: () => void;
  onClickUploadProofItem: () => void;
  onClickAddProofItem: () => void;
  onClickSolvedBytOtherIssue: () => void;
};

const TaskButtons: React.FC<TaskButtonsProps> = (props: TaskButtonsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClickResolve = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseResolve = () => {
    setAnchorEl(null);
  };

  switch (props.status) {
    case TaskInfoDelegationState.Pending:
    // Fallthrough
    case TaskInfoDelegationState.InProgress:
      return (
        <ListItemSecondaryAction className={classes.root}>
          <>
            <Hidden mdUp>
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickResolve(e);
                }}
                size="large"
              >
                <Icon>edit</Icon>
              </IconButton>
            </Hidden>
            <Hidden mdDown>
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickResolve(e);
                }}
                className={classes.button}
                endIcon={<Icon className={classes.icon}>edit</Icon>}
              >
                {t('tasks.solutions.options')}
              </Button>
            </Hidden>
            <StyledMenu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseResolve}
            >
              <StyledMenuItem
                onClick={() => {
                  handleCloseResolve();
                  props.onClickAddComment();
                }}
                disabled={!props.commentAvailable}
              >
                <ListItemText primary={t('tasks.solutions.addComment')} />
                <StyledListItemIcon>
                  <Icon>message</Icon>
                </StyledListItemIcon>
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  handleCloseResolve();
                  props.onClickUploadProofItem();
                }}
              >
                <ListItemText primary={t('tasks.solutions.reUploadProofItem')} />
                <StyledListItemIcon>
                  <Icon>upload</Icon>
                </StyledListItemIcon>
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  handleCloseResolve();
                  props.onClickAddProofItem();
                }}
              >
                <ListItemText primary={t('tasks.solutions.addNewProofItem')} />
                <StyledListItemIcon>
                  <Icon>plus</Icon>
                </StyledListItemIcon>
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  handleCloseResolve();
                  props.onClickSolvedBytOtherIssue();
                }}
                disabled={!props.solvedByOtherIssueAvailable}
              >
                <ListItemText primary={t('tasks.solutions.solvedByOtherIssue')} />
                <StyledListItemIcon>
                  <Icon>check</Icon>
                </StyledListItemIcon>
              </StyledMenuItem>
            </StyledMenu>
          </>
        </ListItemSecondaryAction>
      );
    case TaskInfoDelegationState.Completed:
    // Fallthrough
    case TaskInfoDelegationState.Revoked:
    default:
      return null;
  }
};

export default TaskButtons;
