import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, Hidden, Typography, Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ChevronRight } from '@mui/icons-material';
import ClampLines from 'react-clamp-lines';

import SearchedOnTextResult from './components/SearchedOnTextResult';
import SearchBar from 'components/SearchBar';
import MultiSelect from 'components/MultiSelect';
import { useSearch } from 'use/useSearch';
import SearchedOnTitleResult from './components/SearchedOnTitleResult';
import PlaceholderEmptyList from 'components/PlaceholderEmptyList';
import SubInfoNews from './components/SubInfoNews';
import NewsItemHero from './components/NewsItemHero';

import clsx from 'clsx';
import { IsMobile } from 'utils/global';
import { useQueryNewsItems } from 'use/newsItems';
import { NewsItem } from '__generated__/api';
import LoadingIndicator from 'components/LoadingIndicator';
import NewsCard from './components/NewsCard';
import HeaderTemplate from 'components/Templates/Header/HeaderTemplate';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setResetClientFilters } from 'store/genericClientListFiltersReducer';

const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(1),
    color: theme.palette.textBody.main,
  },
  readArticleBtn: {
    color: theme.palette.primary.main,
    marginLeft: '-16px',
  },
  mostRecentArticle: {
    marginTop: theme.spacing(-6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  mobileSearch: {
    borderRadius: 6,
    marginTop: theme.spacing(-1),
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up('md')]: {
      height: 185,
      padding: 0,
    },
  },
  main: {
    marginBottom: 150,
  },
  title: {
    [theme.breakpoints.down('md')]: {
      marginBottom: -8,
    },
  },
  arrow: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
  mediumImage: {
    width: '100%',
    height: 150,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row-reverse',
  },
  searchBar: {
    margin: theme.spacing(6, 1, 4),
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
    },
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  topResult: {
    margin: theme.spacing(0, 0, 4),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 0),
      fontSize: '1.25em',
    },
  },
  newsArticles: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  newsItem: {
    padding: theme.spacing(3, 0),

    [theme.breakpoints.up('sm')]: {
      'padding': theme.spacing(3),
      '&:hover': {
        borderRadius: theme.spacing(1),
        boxShadow: '0 0 5px 2px rgba(0, 0, 0, 0.034)',
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  searchBox: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  hideSearch: {
    visibility: 'hidden',
  },
  alertContainer: {
    marginTop: theme.spacing(1),
  },
}));

const News = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: news, isLoading, isError } = useQueryNewsItems();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setResetClientFilters());
  }, [dispatch]);

  const onClickNewsItem = (newsArticle: NewsItem) => {
    navigate(`/news/${newsArticle.slug}`);
  };

  const {
    searchTerm,
    searchedTitleItems,
    searchedTextItems,
    filterState,
    handleSearch,
    handleMultiSelectChange,
    emptySearch,
  } = useSearch(news);

  const activeCategories =
    news
      ?.map((item: NewsItem) => item.category!)
      .filter((value, index, self) => self.indexOf(value) === index) ?? [];

  const sortedItems =
    searchedTextItems
      ?.slice()
      .sort((a, b) => new Date(b.dateCreated!).getTime() - new Date(a.dateCreated!).getTime()) ??
    [];

  const isMobile = IsMobile();

  return (
    <>
      {isError ? (
        <Container className={classes.alertContainer}>
          <Alert severity="error">{t('news.empty')}</Alert>
        </Container>
      ) : isLoading ? (
        <LoadingIndicator />
      ) : (
        news && (
          <>
            <HeaderTemplate sectionName={t('general.header.menu.news')} />
            <Hidden smUp>
              <Container>
                <Box
                  my={isMobile ? 1 : 3}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box
                    className={clsx(classes.searchBox, {
                      [classes.hideSearch]: news?.length === 1,
                    })}
                  >
                    <SearchBar
                      placeholder={t('news.filter.search')}
                      searchTerm={searchTerm}
                      handleInputChange={handleSearch}
                      emptySearch={emptySearch}
                      width={360}
                      data-testid="news.filter.search"
                    />
                  </Box>
                </Box>
              </Container>
            </Hidden>
            <Hidden smDown>
              <Container className={classes.mostRecentArticle}>
                <NewsItemHero closable={false} />
                <Grid item className={classes.searchBar}>
                  <SearchBar
                    placeholder={t('news.filter.search')}
                    searchTerm={searchTerm}
                    handleInputChange={handleSearch}
                    emptySearch={emptySearch}
                    width={360}
                    data-testid="news.filter.search"
                  />

                  <MultiSelect
                    label="category"
                    selectedValue={filterState.category}
                    handleChange={handleMultiSelectChange}
                    options={activeCategories}
                    selectAll
                  />
                </Grid>
              </Container>
            </Hidden>

            <Container className={classes.main}>
              {searchTerm && (
                <>
                  <Typography variant="h4" className={classes.topResult}>
                    {t('news.filter.topResults', { searchTerm: searchTerm })}
                  </Typography>
                  {searchedTitleItems.length === 0 && searchedTextItems?.length === 0 && (
                    <PlaceholderEmptyList title={t('news.empty')} border={false} />
                  )}
                  {searchedTitleItems.length > 0 && (
                    <SearchedOnTitleResult
                      items={searchedTitleItems}
                      handleClick={onClickNewsItem}
                      searchTerm={searchTerm}
                    />
                  )}
                  {searchedTextItems.length > 0 && (
                    <SearchedOnTextResult items={searchedTextItems} handleClick={onClickNewsItem} />
                  )}
                </>
              )}

              <Grid className={classes.newsArticles} container direction="row">
                {!searchTerm &&
                  sortedItems.map((item, index) => (
                    <Grid key={index} item sm={12} md={4} className={classes.newsItem}>
                      <NewsCard newsItem={item} className={classes.mediumImage} />
                      <SubInfoNews newsItem={item} />
                      <ClampLines
                        text={item.title!}
                        id={item.id!}
                        buttons={false}
                        ellipsis="..."
                        lines={2}
                        className={classes.title}
                        innerElement="h3"
                      />
                      <ClampLines
                        text={item.introParagraph!}
                        id={item.id!}
                        buttons={false}
                        ellipsis="..."
                        lines={2}
                        className={classes.text}
                      />
                      <Button
                        variant="text"
                        className={classes.readArticleBtn}
                        onClick={() => onClickNewsItem(item)}
                      >
                        {t('news.readFullArticle')}
                        <ChevronRight className={classes.arrow} />
                      </Button>
                    </Grid>
                  ))}
              </Grid>
            </Container>
          </>
        )
      )}
    </>
  );
};

export default News;
