import { Typography, Paper, Divider, Grid, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const Explanation = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const ListTitleText = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 4),
  color: theme.palette.text.secondary,
}));

export const EmailTypeText = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0.8),
  color: theme.palette.text.secondary,
}));

export const AdditionText = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0.8),
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.grey[100],
}));

export const WarningText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const DetailsPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(4, 0),
  boxShadow: 'none',
  borderRadius: '4px',
}));

export const ListItemPadding = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  backgroundColor: theme.palette.background.paper,
}));

export const DetailsDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(0, 0, 4),
}));

export const EmailGridItem = styled(Grid)(({ theme }) => ({
  maxWidth: '440px',
}));

export const ButtonGroup = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(7, 4),
}));
