import { useGroup } from 'use/group';
import { useQuery } from 'react-query';
import { getMoneyLenderTiles } from 'api/moneyLenderTiles';
import { MoneyLenderTilesProps } from 'types/MoneyLenders';

export const useQueryMoneyLendersTiles = () => {
  const group = useGroup();
  return useQuery(
    ['moneyLenders-tiles'],
    (): Promise<MoneyLenderTilesProps[]> => getMoneyLenderTiles(group),
    { enabled: !!group }
  );
};
