import { Box, Grid, GridProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootBox = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  height: '100%',
  flexDirection: 'row',
});

export const FormBox = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1168px',
  marginTop: '64px',
  marginLeft: '350px',
  paddingLeft: '96px',
  paddingRight: '64px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: 0,
    paddingLeft: '56px',
    paddingRight: '56px',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(0),
    paddingLeft: '36px',
    paddingRight: '36px',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0),
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  paddingTop: '64px',
}));

export const InnerBox = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(15),
}));

export const Spinner = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  alignItems: 'center',
});

export const FieldsGrid = styled(Grid)<GridProps>({
  width: '100%',
  maxWidth: '440px',
});

export const FormGrid = styled(Grid)<GridProps>(({ theme }) => ({
  width: '100%',
  maxWidth: '662px',
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  fontSize: '20px',
  color: theme.palette.text.primary,
  marginBottom: '16px',
}));

export const Description = styled(Typography)(({ theme }) => ({
  width: '100%',
  color: theme.palette.text.secondary,
  fontSize: '12px',
  lineHeight: '19px',
  marginBottom: '48px',
}));

export const AccordionSubTitle = styled(SubTitle)({
  marginBottom: 0,
});

export const AmountSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  color: theme.palette.text.primary,
}));

export const AddButtonGrid = styled(Grid)({
  justifyContent: 'flex-end',
  marginBottom: '32px',
});

export const ButtonText = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 1),
}));
