import { Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { SxProps, Theme } from '@mui/material/styles';

type TemplateDetailsProps = {
  title?: string;
  children: ReactNode;
  testid?: string;
  titleExtraClass?: SxProps<Theme>;
};

export default function TemplateDetails({
  testid,
  children,
  title,
  titleExtraClass = [],
}: TemplateDetailsProps) {
  return (
    <Grid item mb={4} data-testid={testid}>
      <Typography
        data-testid="template-title"
        variant="h6"
        component="h2"
        px={1}
        sx={[...(Array.isArray(titleExtraClass) ? titleExtraClass : [titleExtraClass])]}
      >
        {title}
      </Typography>
      {children}
    </Grid>
  );
}
