import {
  styled,
  Popover as MuiPopover,
  Typography,
  Grid as MuiGrid,
  Button as MuiButton,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  Box,
} from '@mui/material';

const Popover = styled(MuiPopover)({ overflow: 'visible ', cursor: 'pointer' });

const PopoverWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.red.main,
}));

const PopoverText = styled(Typography)(({ theme }) => ({ color: theme.palette.red.main }));

const Container = styled(MuiGrid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  height: 'auto',
}));

const HeaderWrapper = styled(MuiGrid)(({ theme }) => ({
  width: '94%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 0, 0, 2),
  textTransform: 'uppercase',
  color: theme.palette.text.disabled,
  marginTop: theme.spacing(4),
}));

const Button = styled(MuiButton)({
  width: 'fit-content',
});
const DeleteButton = styled(MuiButton)(({ theme }) => ({
  width: 'fit-content',
  color: theme.palette.danger.dark,
}));

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  'padding': theme.spacing(1),
  'margin': theme.spacing(0, 0, 2),
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&.MuiAccordion-root.Mui-expanded': {
    margin: theme.spacing(0, 0, 2),
  },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(2),
}));
const FormContainer = styled(MuiGrid)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const BoxLoanPart = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0, 2, 0),
}));

export {
  BoxLoanPart,
  FormContainer,
  AccordionDetails,
  Accordion,
  Popover,
  PopoverWrapper,
  PopoverText,
  Container,
  HeaderWrapper,
  Button,
  DeleteButton,
};
