import React, { useEffect } from 'react';
import FormTitleText from 'components/Form/FormTitle';
import { useTranslation } from 'react-i18next';
import { FieldsGrid } from 'components/Form/Forms.styled';
import { Grid } from '@mui/material';
import { FormInputCurrency, FormToggleButtonGroup } from 'components/Form';
import { yesNoGroupOptions } from '../../ConversionMutation/constants';
import { useWatch } from 'react-hook-form';
import SubForm from './Subform';
import useUnregisterFields from 'screens/Mutations/hooks/useUnregisterField';

export default function Collateral() {
  const { t } = useTranslation();
  const isRenovationField = useWatch({ name: 'collateral.isRenovation' });
  const unregisterMultiple = useUnregisterFields();

  useEffect(() => {
    if (!isRenovationField || isRenovationField === 'no') {
      unregisterMultiple([
        'collateral.renovationAmount',
        'collateral.EBVAmounts',
        'collateral.growMarketValue',
      ]);
    }
  }, [isRenovationField, unregisterMultiple]);

  return (
    <>
      <FormTitleText
        title={t('directMutations.collateral.title')}
        description={t('directMutations.collateral.subtitle')}
      />
      <FieldsGrid container>
        <Grid item xs={12}>
          <FormInputCurrency
            name="collateral.marketValue"
            label={t('directMutations.collateral.marketValueBeforeRenovation')}
            placeholder={t('directMutations.amountPlaceholder')}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormToggleButtonGroup
            name="collateral.isRenovation"
            options={yesNoGroupOptions}
            label={t('directMutations.collateral.isRenovation')}
            required
          />
        </Grid>
        {isRenovationField === 'yes' && <SubForm />}
        <Grid item xs={12}>
          <FormInputCurrency
            name="collateral.valuationCosts"
            label={t('directMutations.collateral.valuationCosts')}
            placeholder={t('directMutations.amountPlaceholder')}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormToggleButtonGroup
            name="collateral.NHGGuarantee"
            options={yesNoGroupOptions}
            label={t('directMutations.collateral.NHGGuarantee')}
            required
          />
        </Grid>
      </FieldsGrid>
    </>
  );
}
