import { SummaryCard, CardRow, CardSection } from 'components/SummaryCard';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SocialSecurityIncome } from 'screens/Mutations/utils/types';
import { formatAmount } from 'utils/formatAmount';
import { getTypeOfPensionLabel } from 'screens/Mutations/constants';
import formatDate from 'utils/formatDate';
import { TypeOfBenefit } from '__generated__/api';

type PensionIncomeProps = {
  income: SocialSecurityIncome;
  onClick: () => void;
};

const Pension: FC<PensionIncomeProps> = ({ income, onClick }) => {
  const { t } = useTranslation();

  const { typeOfBenefit, startDate, endDate, incomeAmount } = income;
  const root = 'directMutations.income.pension';

  const endDateVisible =
    typeOfBenefit === TypeOfBenefit.PrePension || typeOfBenefit === TypeOfBenefit.Insurance;

  return (
    <>
      <SummaryCard title={t('directMutations.income.formTitle')} onEditClick={onClick}>
        <CardSection>
          <CardRow
            name={t('directMutations.income.typeOfIncome')}
            value={t('directMutations.income.incomeTypes.Pension')}
          />
          <CardRow
            name={t(`${root}.typeOfBenefit`)}
            value={typeOfBenefit && getTypeOfPensionLabel(typeOfBenefit)}
          />
          <CardRow name={t(`${root}.startDate`)} value={formatDate(startDate, '-')} />
          {endDateVisible && (
            <CardRow name={t(`${root}.endDate`)} value={formatDate(endDate, '-')} />
          )}
          <CardRow name={t(`${root}.incomeAmount`)} value={formatAmount(incomeAmount)} />
        </CardSection>
      </SummaryCard>
    </>
  );
};

export default Pension;
