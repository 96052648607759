import { styled } from '@mui/material/styles';
import {
  Toolbar as MuiToolbar,
  AppBar as MuiAppBar,
  Typography as MuiTypo,
  Box as MuiBox,
} from '@mui/material';
import Logo from 'components/Logo';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.text.primary,
  position: 'fixed',
}));

const HeaderSubtitleBox = styled(MuiBox)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
});

const HeaderSubtitleText = styled(MuiTypo)(({ theme }) => ({
  fontSize: '12px',
  color: 'grey',
  padding: theme.spacing(0, 0.5, 0, 0),
}));

const HeaderTitleBox = styled(MuiBox)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  fontWeight: 'bolder',
});

const HeaderTitleText = styled(MuiTypo)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  fontWeight: 'bolder',
});

const MoneyLenderText = styled(MuiTypo)({ fontSize: '12px', color: 'black' });

const TitleBarButtonsContainer = styled(MuiBox)(({ theme }) => ({
  'display': 'flex',
  '& hr': {
    margin: theme.spacing(0, 1, 0, 2),
  },
  '& button': {
    minWidth: 44,
    minHeight: 44,
  },
}));

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
}));

const HeaderLogo = styled(Logo)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

export {
  MoneyLenderText,
  AppBar,
  TitleBarButtonsContainer,
  Toolbar,
  HeaderLogo,
  HeaderSubtitleBox,
  HeaderTitleBox,
  HeaderTitleText,
  HeaderSubtitleText,
};
