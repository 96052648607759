import React, { FC, useState, useEffect } from 'react';
import { FormControl, FormLabel, Grid, Icon, TextField, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import type { BtlApplicant, BtlApplication } from 'types/BtlFormApplication';
import TextInput from 'screens/BuyToLet/BuyToLetForm/form/TextInput';
import ToggleButtonQuestion from 'screens/BuyToLet/BuyToLetForm/form/ToggleButtonQuestion';
import { mapEnum } from 'utils/global';
import { CivilStatus, Gender, Nationality, TypeOfPermit } from '__generated__/api';
import { SelectOption } from 'types/SelectOption';
import { filteredTypeOfIdentification } from '../../enums/typeOfIdentification.enum';
import BuyToLetExistingMortgageQuestions from './BuyToLetExistingMortgageQuestions';
import DropDownQuestion from 'screens/BuyToLet/BuyToLetForm/form/DropDownQuestion';
import { DatePicker } from '@mui/x-date-pickers';
import { isValid, format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: theme.spacing(4),
  },
  description: {
    marginTop: theme.spacing(2),
    color: theme.palette.textBody.main,
  },
  formGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    maxWidth: 600,
  },
  alert: {
    padding: theme.spacing(2),
    borderRadius: 6,
    marginTop: 10,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  alertIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  alertText: {
    color: theme.palette.primary.main,
  },
}));

export type BuyToLetApplicantNaturalPersonProps = {
  currentApplicantIndex: number;
  application: BtlApplication;
  updateApplicant: (data: BtlApplicant, index: number) => void;
  errorMessage: (id: string, category: string) => React.ReactNode;
};

const BuyToLetApplicantNaturalPerson: FC<BuyToLetApplicantNaturalPersonProps> = ({
  currentApplicantIndex,
  application,
  updateApplicant,
  errorMessage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [date, setDate] = useState('');
  const applicant = application.applicants[currentApplicantIndex];
  const form = 'buyToLetForm.newApplicantNaturalPerson';
  const category = 'naturalPerson';

  useEffect(() => {
    if (applicant.dateOfBirth) {
      setDate(applicant.dateOfBirth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleChange = (questionId: string, event: any, value: any) => {
    const val = value === 'yes' ? true : value === 'no' ? false : value;
    const updatedApplicant = {
      ...applicant,
      [questionId]: val,
    };
    // reset value when switch to no false
    switch (questionId) {
      case 'hasExistingMortgageByMoneylender':
        if (val === false) {
          updatedApplicant.existingLoanNumber = undefined;
        }
        break;
      case 'isMortgageRejected':
        if (val === false) {
          updatedApplicant.reasonMortgageRejection = undefined;
        }
        break;
    }
    updateApplicant(updatedApplicant, currentApplicantIndex);
  };

  const handleTextChange = (event: any) => {
    const updatedApplicant = {
      ...applicant,
      [event.target.name]: event.target.value,
    };

    if (event.target.name === 'typeOfPermitWithNone') {
      if (event.target.value === 'None') {
        updatedApplicant.typeOfPermit = undefined;
      } else {
        updatedApplicant.typeOfPermit = event.target.value;
      }
    }

    updateApplicant(updatedApplicant, currentApplicantIndex);
  };

  const handleOnDateChange = (e: any, value: any) => {
    if (isValid(e)) {
      setDate(e);
      const updatedApplicant = {
        ...applicant,
        dateOfBirth: format(e, 'yyyy-MM-dd'),
      };
      updateApplicant(updatedApplicant, currentApplicantIndex);
    }
  };

  const translatedCivilStatus: SelectOption[] = mapEnum(CivilStatus)
    .map((status: string) => ({ value: status, label: t(`${form}.${status}`) }))
    .sort((a: SelectOption, b: SelectOption) => (a.label > b.label ? 1 : -1));

  const translatedNationalities: SelectOption[] = mapEnum(Nationality)
    .map((status: string) => ({ value: status, label: t(`${form}.${status}`) }))
    // filter out Dutch and Unknown, add later to list
    .filter(
      (x: SelectOption) => x.value !== Nationality.Mvbz0001 && x.value !== Nationality.Mvbz0000
    )
    .sort((a: SelectOption, b: SelectOption) => (a.label > b.label ? 1 : -1));

  const nationalities: SelectOption[] = [
    {
      value: Nationality.Mvbz0001.toString(),
      label: 'Nederlandse',
    },
    { value: Nationality.Mvbz0000.toString(), label: 'Onbekend' },
    ...translatedNationalities,
  ];

  const translatedTypeOfPermit: SelectOption[] = mapEnum(TypeOfPermit)
    .map((status: string) => ({ value: status, label: t(`${form}.${status}`) }))
    .sort((a: SelectOption, b: SelectOption) => (a.label > b.label ? 1 : -1));

  const answerOptions = translatedTypeOfPermit.map((x) => ({
    id: x.value,
    answerText: x.label,
  }));
  answerOptions.unshift({ id: 'None', answerText: t(`${form}.noPermit`) });

  return (
    <form>
      <Typography className={classes.description} variant="body1">
        {t('buyToLetForm.newApplicant.description')}
      </Typography>
      <Grid container spacing={3} className={classes.formGrid}>
        <BuyToLetExistingMortgageQuestions
          category="naturalPerson"
          applicant={applicant}
          errorMessage={errorMessage}
          handleTextChange={handleTextChange}
          handleToggleChange={handleToggleChange}
        />
        <Grid item xs={12}>
          <Typography variant="h5">{t(`${form}.generalInformation`)}</Typography>
        </Grid>
        <Grid container item xs={12} sm={12}>
          <ToggleButtonQuestion
            key="gender"
            label={t(`${form}.gender`)}
            answerOptions={[
              {
                id: Gender.Male.toString(),
                answerTextKey: `${form}.${Gender.Male}`,
              },
              {
                id: Gender.Female.toString(),
                answerTextKey: `${form}.${Gender.Female}`,
              },
            ]}
            containerWidth={6}
            required
            exclusive
            value={applicant.gender?.toString()}
            onChange={(e, value) => handleToggleChange('gender', e, value)}
            error={Boolean(errorMessage('.gender', category))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            label={t(`${form}.initials`)}
            required
            name="initials"
            inputProps={{ maxLength: 15, autoComplete: 'off' }}
            onChange={handleTextChange}
            value={applicant.initials || ''}
            placeholder={t(`${form}.initials`)}
            error={Boolean(errorMessage('.initials', category))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            label={t(`${form}.firstName`)}
            required
            inputProps={{ maxLength: 35, autoComplete: 'off' }}
            name="firstName"
            onChange={handleTextChange}
            value={applicant.firstName || ''}
            placeholder={t(`${form}.firstName`)}
            error={Boolean(errorMessage('.firstName', category))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            label={t(`${form}.middleName`)}
            name="middleName"
            inputProps={{ maxLength: 10, autoComplete: 'off' }}
            onChange={handleTextChange}
            value={applicant.middleName || ''}
            placeholder={t(`${form}.middleName`)}
            error={Boolean(errorMessage('.middleName', category))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            label={t(`${form}.lastName`)}
            required
            inputProps={{ maxLength: 100, autoComplete: 'off' }}
            name="lastName"
            onChange={handleTextChange}
            value={applicant.lastName || ''}
            placeholder={t(`${form}.lastName`)}
            error={Boolean(errorMessage('.lastName', category))}
          />
        </Grid>

        <Grid item xs={12}>
          <DropDownQuestion
            containerWidth={6}
            key="civilStatus"
            name="civilStatus"
            required
            label={t(`${form}.civilStatus`)}
            answerOptions={translatedCivilStatus.map((x) => ({
              id: x.value,
              answerText: x.label,
            }))}
            placeholder={t(`${form}.civilStatus`)}
            value={applicant.civilStatus || ''}
            onChange={handleTextChange}
            error={Boolean(errorMessage('.civilStatus', category))}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <FormLabel>{t(`${form}.dateOfBirth`)}</FormLabel>
            <DatePicker
              inputFormat="dd-MM-yyyy"
              value={date}
              onChange={(e, value) => handleOnDateChange(e as any, value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="dateOfBirth"
                  required
                  error={Boolean(errorMessage('.dateOfBirth', category))}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">{t(`${form}.nationality`)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <DropDownQuestion
            containerWidth={6}
            key="nationality"
            name="nationality"
            required
            label={t(`${form}.nationality`)}
            answerOptions={nationalities.map((x) => ({
              id: x.value,
              answerText: x.label,
            }))}
            value={applicant.nationality || ''}
            onChange={handleTextChange}
            error={Boolean(errorMessage('.nationality', category))}
          />

          {applicant.nationality !== Nationality.Mvbz0001.toString() && (
            <div className={classes.alert} data-testid="nationality-alert">
              <Icon className={classes.alertIcon}>alert_triangle</Icon>
              <Typography variant="body2" className={classes.alertText}>
                {t(`${form}.nationalityAbroad`)}
              </Typography>
            </div>
          )}
        </Grid>

        <Grid item xs={6}>
          <DropDownQuestion
            containerWidth={12}
            key="typeOfIdentification"
            name="typeOfIdentification"
            label={t(`${form}.typeOfIdentification`)}
            placeholder={t(`${form}.typeOfIdentification`)}
            answerOptions={filteredTypeOfIdentification
              .map((x: string) => ({
                id: x,
                answerText: t(`${form}.${x}`),
              }))
              .sort((a, b) => (a.answerText > b.answerText ? 1 : -1))}
            required
            value={applicant.typeOfIdentification ?? ''}
            onChange={handleTextChange}
            error={Boolean(errorMessage('.typeOfIdentification', category))}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            label={t(`${form}.identificationNumber`)}
            placeholder={t(`${form}.identificationNumber`)}
            required
            inputProps={{ maxLength: 20, autoComplete: 'off' }}
            value={applicant.identificationNumber || ''}
            name="identificationNumber"
            onChange={handleTextChange}
            error={Boolean(errorMessage('.identificationNumber', category))}
          />
        </Grid>

        <Grid item xs={6}>
          <DropDownQuestion
            containerWidth={12}
            key="typeOfPermitWithNone"
            name="typeOfPermitWithNone"
            label={t(`${form}.typeOfPermit`)}
            answerOptions={answerOptions}
            placeholder={t('lib.select')}
            value={applicant.typeOfPermitWithNone ?? ''}
            onChange={handleTextChange}
            error={Boolean(errorMessage('.typeOfPermit', category))}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            label={t(`${form}.permitNumber`)}
            placeholder={t(`${form}.permitNumber`)}
            inputProps={{ maxLength: 20, autoComplete: 'off' }}
            value={applicant.permitNumber || ''}
            name="permitNumber"
            onChange={handleTextChange}
            error={Boolean(errorMessage('.permitNumber', category))}
          />
        </Grid>
      </Grid>
    </form>
  );
};
export default BuyToLetApplicantNaturalPerson;
