import { format, isValid } from 'date-fns';
import nl from 'date-fns/locale/nl';

const formatDate = (
  date: string | undefined | null,
  emptyPlaceholder: string | undefined = ' ',
  dateFormat: string | undefined = 'dd-MM-yyyy'
): string => {
  if (!date) return emptyPlaceholder;
  const stringToDate = new Date(date);
  if (isValid(stringToDate)) {
    const formated = format(stringToDate, dateFormat, { locale: nl });
    return formated;
  }
  return emptyPlaceholder;
};

export default formatDate;
