import { FC } from 'react';
import { useRole } from 'use/role';

type Props = {
  role: 'observer' | 'advisor' | 'user';
  children: React.ReactNode;
};

const HasRole: FC<Props> = ({ role, children }) => {
  const { data: userRole } = useRole();

  if (userRole?.includes(role)) {
    return <>{children}</>;
  }
  return null;
};

export default HasRole;
