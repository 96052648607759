import { AccordionSummary, Divider, Grid } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LoanDetailsFormType, Option } from '../../../../../../utils/types';
import {
  FormInputCurrency,
  FormInputNumber,
  FormInputDropdown,
  FormToggleButtonGroup,
} from 'components/Form';
import { yesNoGroupOptions } from '../../../../constants';
import AccordionLoanPartHeader from '../AccordionLoanPartHeader';
import { Accordion, AccordionDetails } from '../../LoanPart.styled';

type SplitLoanProps = {
  loanPartIndex: number;
  splitLoanIndex: number;
  productOptions: Option[];
  expanded: boolean;
};

export const SplitLoan: FC<SplitLoanProps> = ({
  loanPartIndex,
  splitLoanIndex,
  productOptions,
  expanded,
}) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<LoanDetailsFormType>();
  const repaymentType = watch(
    `loanParts.${loanPartIndex}.splitLoan.${splitLoanIndex}.repaymentType`
  );
  const loanAmount = watch(`loanParts.${loanPartIndex}.splitLoan.${splitLoanIndex}.amount`);
  const fixedRatePeriod = watch(
    `loanParts.${loanPartIndex}.splitLoan.${splitLoanIndex}.fixedRatePeriod`
  );
  const rootFormField = `loanParts.${loanPartIndex}.splitLoan.${splitLoanIndex}`;

  return (
    <Accordion
      data-testid="split-loan"
      expanded={expanded}
      key={`splitLoan-${loanPartIndex}-${splitLoanIndex}`}
    >
      <AccordionSummary
        data-testid={`split-loan-header-${splitLoanIndex}`}
        aria-controls={`panel${loanPartIndex}-converLoan`}
        id={`panel${loanPartIndex}-converLoan`}
      >
        <AccordionLoanPartHeader
          loanPartNumber={`${t('directMutations.form.splittingPart')} ${splitLoanIndex + 1}`}
          oldOrNew={t('directMutations.form.new')}
          loanAmount={loanAmount}
          repaymentType={repaymentType}
          fixedRatePeriod={fixedRatePeriod}
        />
      </AccordionSummary>
      <Divider />
      <AccordionDetails data-testid={`split-loan-${splitLoanIndex}`}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }}>
          <Grid item xs={6}>
            <FormInputCurrency
              name={`${rootFormField}.amount`}
              label={t('directMutations.form.loanAmount')}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormInputDropdown
              name={`${rootFormField}.repaymentType`}
              label={t('directMutations.form.newRepaymentType')}
              options={productOptions}
              placeholder={t('directMutations.loanParts.repaymentTypePlaceholder')}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormInputNumber
              name={`${rootFormField}.fixedRatePeriod`}
              label={t('directMutations.form.newFixedInterestPeriod')}
              placeholder={t('directMutations.form.placeholderRvp')}
              min={0}
              max={999}
              thousandSeparator
            />
          </Grid>
          <Grid item xs={6}>
            <FormToggleButtonGroup
              name={`${rootFormField}.NHGGuarantee`}
              label={t('directMutations.loanParts.NHGGuarantee')}
              options={yesNoGroupOptions}
              required
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SplitLoan;
