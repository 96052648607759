import { CaseDetailsContactDataInfo } from '__generated__/api-di';
import { getAmountOrZero } from 'utils/global';
import { BtlApplicant, BtlLoan, BtlRealEstate, BtlApplication } from 'types/BtlFormApplication';
import { Nationality, Status, TypeOfAccumulatedIncome, TypeOfCollateral } from '__generated__/api';
import { maxBtlApplicants } from './constants';

export const getTotalRequiredAmount = (loan: BtlLoan): number => {
  return (
    getAmountOrZero(loan?.totalPurchasePrice) +
    getAmountOrZero(loan?.refinancingExistingMortgagesAmount) +
    getAmountOrZero(loan?.totalCostBuyerAmount) +
    getAmountOrZero(loan?.liquidityAmountToWithdraw) +
    getAmountOrZero(loan?.otherCosts) +
    getAmountOrZero(loan?.totalRenovationCosts)
  );
};

export const getTotalProvenAmount = (loan: BtlLoan): number => {
  return getAmountOrZero(loan?.amount) + getAmountOrZero(loan?.addedPersonalEquity);
};

export const hasErrorsInTotalLoanPartAmount = (loan: BtlLoan | undefined) => {
  if (loan?.loanParts === undefined || loan.loanParts.length === 0) {
    return false;
  }

  const loanPartsTotal = loan.loanParts.reduce((accumulator, loanPart) => {
    return accumulator + (loanPart?.amount ?? 0);
  }, 0);

  return loanPartsTotal !== loan.amount;
};

export const applicantCountValid = (applicants?: BtlApplicant[]): boolean => {
  if (!applicants) {
    return false;
  }
  const legalPersons = applicants?.filter((x) => x.isLegalPerson === true);
  const naturalPersons = applicants?.filter((x) => x.isLegalPerson === false);
  return (
    applicants?.length > 0 &&
    applicants?.length <= maxBtlApplicants &&
    legalPersons?.length <= 4 &&
    naturalPersons?.length <= 4
  );
};

export function formatAddress(realEstate: BtlRealEstate) {
  return [
    realEstate.street,
    realEstate.houseNumber,
    !realEstate?.street ? realEstate?.city : '',
    realEstate.buildingPlanNumber,
    realEstate.situationNumber,
  ]
    .filter((value) => value)
    .join(' ');
}

export function formatAddressEx(contactData?: CaseDetailsContactDataInfo): string {
  if (contactData) {
    const street = contactData.street ?? '';
    const houseNumber = contactData.houseNumber ?? '';
    const houseNumberAddition = contactData.houseNumberAddition ?? '';
    const postalCode = contactData.zipCode ?? '';
    const city = contactData.city ?? '';

    const streetPart = `${street} ${houseNumber}${houseNumberAddition}`;

    if (streetPart && postalCode && city) {
      return `${streetPart}, ${postalCode} ${city}`;
    }
    if (postalCode && city) {
      return `${postalCode} ${city}`;
    }
    return city;
  }
  return '';
}

export const getEmptyBtlApplicationForTesting = (): BtlApplication => {
  return {
    id: 0,
    applicants: [],
    intermediary: undefined,
    remark: undefined,
    moneylender: {},
    product: {},
    realEstates: [],
    loan: {
      loanParts: [],
      totalPurchasePrice: null,
      explanationTotalPurchasePrice: null,
      refinancingExistingMortgagesAmount: null,
      explanationRefinancingExistingMortgagesAmount: null,
      totalCostBuyerAmount: null,
      explanationTotalCostBuyerAmount: null,
      liquidityAmountToWithdraw: null,
      explanationLiquidityAmountToWithdraw: null,
      otherCosts: null,
      explanationOtherCosts: null,
      totalRenovationCosts: null,
      amount: null,
      explanationTotalRenovationCosts: null,
      addedPersonalEquity: null,
      explanationAddedPersonalEquity: null,
    },
    status: Status.New,
  };
};

export const newApplicant: BtlApplicant = {
  isLegalPerson: undefined,
  hasExistingMortgageByMoneylender: false,
  existingLoanNumber: undefined,
  isMortgageRejected: false,
  gender: undefined,
  initials: undefined,
  firstName: undefined,
  middleName: undefined,
  lastName: undefined,
  dateOfBirth: undefined,
  nationality: Nationality.Mvbz0001,

  typeOfIdentification: undefined,
  identificationNumber: undefined,
  typeOfPermit: undefined,
  typeOfPermitWithNone: 'None',
  permitNumber: undefined,

  accumulatedIncomes: [
    {
      explanation: '',
      income: null,
      typeOfAccumulatedIncome: TypeOfAccumulatedIncome.TotalIncome,
    },
  ],
  numberOfRentalCollateralsOwned: undefined,
  totalValueOfRentalCollateralsOwned: undefined,
  isVastgoedBelangMember: false,
  isShareholder: false,
  companyNameShareholdership: undefined,
  shareholderPercentage: undefined,
  isDirector: false,
  companyNameDirectorship: undefined,

  street: undefined,
  houseNumber: undefined,
  houseNumberExtension: undefined,
  postalCode: undefined,
  city: undefined,
  country: undefined,

  privatePhoneNumber: undefined,
  mobilePhoneNumber: undefined,
  workPhoneNumber: undefined,
  emailAddress: undefined,
};

export const newRealEstate: BtlRealEstate = {
  typeOfCollateral: TypeOfCollateral.Existing,
  hasLeasehold: false,
  isBeingRenovated: false,
  isRenovationDepotNecessary: false,
  renovationDepotAmount: undefined,
};
