export const formatYesNo = (yesOrNo: string | undefined): string => {
  switch (yesOrNo) {
    case 'yes':
      return 'Ja';
    case 'no':
      return 'Nee';
    default:
      return '-';
  }
};
