import { incomesSchema as baseIncomesSchema } from 'screens/Mutations/schemas/income/incomeSchema';
import { basePersonalInfoSchema } from 'screens/Mutations/schemas/personalInfo';
import { contactDetailsSchema } from 'screens/Mutations/schemas/contactDetails';
import baseObligationSchema from 'screens/Mutations/schemas/obligation';
import { array, object, mixed } from 'yup';
import { findApplicantLength } from '../../utils';

export const applicantSchema = object({
  personalInfo: basePersonalInfoSchema,
  contactDetails: contactDetailsSchema,
  income: baseIncomesSchema,
  obligation: baseObligationSchema,
});

export const applicantsSchema = object().shape({
  applicantsIncomes: mixed().test(
    'array',
    'Er moet een inkomen worden ingevuld voor minimaal één van de aanvragers',
    (_: any, meta: any) => {
      if (meta?.parent?.applicants) {
        return findApplicantLength(meta.parent.applicants) > 0;
      }
      return false;
    }
  ),
  applicants: array().of(applicantSchema),
});
