import React, { useState } from 'react';
import {
  BaseTextFieldProps,
  InputAdornment,
  InputProps,
  TextField,
  InputBaseComponentProps,
  FormLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { InputWrapper } from 'components/Form/Forms.styled';

const useStyles = makeStyles((theme) => ({
  startAdornment: {
    background: theme.palette.white.main,
  },
  disabled: {
    background: theme.palette.grey[200],
  },
}));

interface NumberFormatCustomProps extends InputBaseComponentProps {
  name?: string;
  onChange?: (value: any) => void;
  min?: number;
  max?: number;
}

const NumberFormatCustom: React.FC<NumberFormatCustomProps> = (props) => {
  const [fixedDecimalScale, setFixedDecimalScale] = useState(false);
  const { inputRef, onChange, min, max, ...other } = props;

  const handleChange = (values: NumberFormatValues) => {
    if (values.formattedValue.indexOf(',') >= 0 && values.formattedValue.split(',')[1] !== '00') {
      setFixedDecimalScale(true);
    } else {
      setFixedDecimalScale(false);
    }

    if (onChange) {
      onChange({
        target: {
          name: props.name ?? '',
          value: values.value,
        },
      });
    }
  };

  const validateInput = (values: NumberFormatValues) => {
    const { floatValue } = values;
    let valid = true;
    valid = valid && (max === undefined || floatValue === undefined || floatValue <= max);
    valid = valid && (min === undefined || floatValue === undefined || floatValue >= min);
    return valid;
  };

  return (
    <NumericFormat
      {...other}
      defaultValue=""
      valueIsNumericString
      getInputRef={inputRef}
      isAllowed={validateInput}
      onValueChange={handleChange}
      decimalSeparator=","
      thousandSeparator="."
      decimalScale={2}
      fixedDecimalScale={fixedDecimalScale}
      allowLeadingZeros={false}
      allowedDecimalSeparators={['.']}
    />
  );
};

export interface ChangeCurrencyFieldProps extends BaseTextFieldProps {
  onBlur?: InputProps['onBlur'];
  onFocus?: InputProps['onFocus'];
  onChange?: (value: number) => void;
}

const PercentageField: React.FC<ChangeCurrencyFieldProps> = (props) => {
  const { label, value, placeholder, name, disabled, onChange, onFocus, onBlur, error, required } =
    props;
  const classes = useStyles();
  const placeholderText = placeholder || label?.toString();

  return (
    <InputWrapper>
      <FormLabel data-testid="input-label" required={required}>
        {label}
      </FormLabel>
      <TextField
        type="percentage"
        name={name}
        disabled={disabled}
        placeholder={placeholderText}
        value={value === null ? '' : parseFloat(`${value}`)}
        variant="outlined"
        fullWidth
        error={error}
        onChange={(e) => {
          if (onChange) {
            onChange(parseFloat(e.target.value));
          }
        }}
        onFocus={(event) => {
          if (onFocus) {
            onFocus(event);
          }
        }}
        onBlur={(event) => {
          if (onBlur) {
            onBlur(event);
          }
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">%</InputAdornment>,
          classes: { adornedStart: classes.startAdornment, disabled: classes.disabled },
          inputComponent: NumberFormatCustom,
        }}
        inputProps={{ max: 100.0, min: 0, style: { textAlign: 'right' }, autoComplete: 'off' }}
      />
    </InputWrapper>
  );
};

export default PercentageField;
