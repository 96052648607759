import {
  deleteApplication,
  getApplicationRequestDetails,
  sendAsrApplication,
} from 'api/applicationRegistration';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { useGroup } from './group';
import { getConceptsData } from 'api/applicationdata';
import { ApplicationData } from '__generated__/api';

async function getConcepts(
  group: string | null,
  commercialRelation: string | null
): Promise<ApplicationData[]> {
  const applicationDataList = await getConceptsData(group, commercialRelation);
  return applicationDataList;
}

export function useQueryConcepts() {
  const commercialRelation = localStorage.getItem('commercialRelation');
  const group = useGroup();
  return useQuery(
    ['concepts', group, commercialRelation],
    () => getConcepts(group, commercialRelation),
    {
      enabled: !!group,
    }
  );
}

export function useQueryApplicationRequestDetails(id?: number) {
  const group = useGroup();
  const { status, error, isError, isFetching, data } = useQuery(
    ['application-request-details', id, group],
    () => (id ? getApplicationRequestDetails(id, group) : undefined),
    { enabled: !!group && !!id }
  );

  return {
    status,
    error,
    isError,
    isFetching,
    data,
  };
}

export function useMutationAsrApplication() {
  const group = useGroup();
  return useMutation((asrDetails: any) => sendAsrApplication(asrDetails, group as string));
}

export function useDeleteApplication(
  id: number | undefined,
  onSuccess: () => void,
  error: () => void
) {
  const group = useGroup();
  const queryClient = new QueryClient();
  return useMutation(() => deleteApplication(id, group), {
    onSuccess: async () => {
      onSuccess();
    },
    onError: () => {
      error();
    },
    onSettled: () => {
      queryClient.invalidateQueries(['applications', group]);
    },
  });
}
