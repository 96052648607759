import type { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { TextField } from '@mui/material';

type CustomNumberFormatProps = {
  value?: number;
  fixedDecimalScale?: boolean;
};

export const CustomNumberFormat: FC<CustomNumberFormatProps> = ({ value, fixedDecimalScale }) => {
  return (
    <NumericFormat
      customInput={TextField}
      variant="outlined"
      decimalSeparator=","
      thousandSeparator="."
      allowLeadingZeros={false}
      allowedDecimalSeparators={['.']}
      prefix="€ "
      value={value}
      decimalScale={2}
      displayType="text"
      fixedDecimalScale={fixedDecimalScale}
    />
  );
};

export default CustomNumberFormat;
