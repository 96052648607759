import { FC, useCallback } from 'react';
import LoanPartHeaderValues from '../LoanPartHeaderValues';
import CurrentLoanPart from '../CurrentLoanPart';
import ConvertLoan from '../ConvertLoan';
import { useFormContext, useWatch } from 'react-hook-form';
import { LoanFormType } from '../../../utils/types';
import { DeleteOutline, ExpandMore } from '@mui/icons-material';

import { useTranslation } from 'react-i18next';
import { renderSplitLoan } from '../utils/renderSplitLoan';
import NewLoanPart from '../NewLoanPart';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  BottomDivider,
  DeleteButton,
  HeaderDivider,
  LoanPart,
  Divider,
} from '../Loan.styled';
import { SelectOption } from 'types/SelectOption';
import { findRepaymentTypeDisplayName } from '../utils';

type LoanPartAccordionLoanPartProps = {
  expanded: boolean;
  loanPartIndex: number;
  fieldId: string;
  fieldSet: 'newLoanParts' | 'currentLoanParts';
  handleExpand: (id: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  handleDeleteLoanPart: (index: number, id: string) => void;
  shouldUnregister: boolean;
  productsData?: SelectOption[];
};

export const LoanPartAccordion: FC<LoanPartAccordionLoanPartProps> = ({
  expanded,
  loanPartIndex,
  fieldId,
  fieldSet,
  handleExpand,
  handleDeleteLoanPart,
  shouldUnregister,
  productsData,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<LoanFormType>();
  const formLoanParts = useWatch({ control, name: fieldSet });

  const onDelete = useCallback(() => {
    handleDeleteLoanPart(loanPartIndex, fieldId);
  }, [handleDeleteLoanPart, loanPartIndex, fieldId]);

  if (!formLoanParts) return null;
  const {
    numberOfSplitLoanParts,
    shouldSplitLoan,
    loanPartNumber,
    loanAmount,
    box1,
    box3,
    repaymentType,
    fixedRatePeriod,
    interestPercentage,
    endDateInterestDeduction,
  } = formLoanParts[loanPartIndex];
  const isCurrentLoan = fieldSet === 'currentLoanParts';
  const repaymentTypeDisplayName = findRepaymentTypeDisplayName(productsData, repaymentType);

  return (
    <LoanPart key={fieldId} data-testid={`${fieldSet}-part`}>
      <HeaderDivider />
      <Accordion defaultExpanded expanded={expanded} onChange={handleExpand(fieldId)}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <LoanPartHeaderValues
            typeOfPart={fieldSet}
            loanPartIndex={loanPartIndex}
            loanPartNumber={loanPartNumber}
            amount={loanAmount}
            box1={box1}
            box3={box3}
            repaymentType={repaymentTypeDisplayName}
            fixedRatePeriod={fixedRatePeriod}
            interestPercentage={interestPercentage}
            endDateInterestDeduction={endDateInterestDeduction}
          />
        </AccordionSummary>

        <AccordionDetails>
          <Divider />

          {isCurrentLoan ? (
            <CurrentLoanPart
              loanPartIndex={loanPartIndex}
              shouldUnregister={shouldUnregister}
              productsData={productsData}
            />
          ) : (
            <NewLoanPart
              loanPartIndex={loanPartIndex}
              shouldUnregister={shouldUnregister}
              productsData={productsData}
            />
          )}
        </AccordionDetails>
        <DeleteButton onClick={onDelete} size="medium">
          {t('directMutations.buttons.deleteLoanPartButton')} <DeleteOutline />
        </DeleteButton>
      </Accordion>
      {shouldSplitLoan && shouldSplitLoan === 'no' && (
        <ConvertLoan
          loanPartIndex={loanPartIndex}
          expanded={expanded}
          productsData={productsData}
        />
      )}
      {shouldSplitLoan &&
        shouldSplitLoan === 'yes' &&
        renderSplitLoan(
          loanPartIndex,
          expanded,
          shouldUnregister,
          productsData,
          numberOfSplitLoanParts
        )}
      {!expanded && <BottomDivider />}
    </LoanPart>
  );
};
export default LoanPartAccordion;
