import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormInputDropdown, FormToggleButtonGroup, FormInputNumber } from 'components/Form';
import LoanPartHeaderValues from '../LoanPartHeaderValues';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  Accordion,
  AccordionDetails,
  AccordionPaddingSummary,
  Divider,
  FieldWrapper,
  TopWrapper,
} from '../Loan.styled';
import { yesNoGroupOptions } from 'screens/Mutations/constants';
import { SelectOption } from 'types/SelectOption';
import { findRepaymentTypeDisplayName } from '../utils';

type ConvertLoanProps = {
  loanPartIndex: number;
  expanded: boolean;
  productsData?: SelectOption[];
};

export const ConvertLoan: FC<ConvertLoanProps> = ({ loanPartIndex, expanded, productsData }) => {
  const rootFormField = `currentLoanParts.${loanPartIndex}.convertLoan`;
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const productOptions = useSelector((state: RootState) => state.ohaForm.productOptions);
  const repaymentType = watch(`${rootFormField}.repaymentType`);
  const repaymentTypeDisplayName = findRepaymentTypeDisplayName(productsData, repaymentType);

  return (
    <Accordion expanded={expanded}>
      <AccordionPaddingSummary
        aria-controls={`panel${loanPartIndex}-convertLoan`}
        id={`panel${loanPartIndex}-convertLoan`}
      >
        <LoanPartHeaderValues
          typeOfPart="convertLoan"
          loanPartIndex={loanPartIndex}
          repaymentType={repaymentTypeDisplayName}
          fixedRatePeriod={watch(`${rootFormField}.fixedRatePeriod`)}
        />
      </AccordionPaddingSummary>
      <AccordionDetails>
        <Divider />

        <TopWrapper container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormInputDropdown
              name={`${rootFormField}.repaymentType`}
              label={t('directMutations.form.newRepaymentType')}
              options={productsData ?? productOptions}
              placeholder={t('directMutations.loanParts.repaymentTypePlaceholder')}
              required
              shouldUnregister
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputNumber
              name={`${rootFormField}.fixedRatePeriod`}
              label={t('directMutations.form.newFixedInterestPeriod')}
              placeholder={t('directMutations.form.placeholderRvp')}
              min={0}
              max={999}
              required
              shouldUnregister
            />
          </Grid>
        </TopWrapper>
        <FieldWrapper container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormToggleButtonGroup
              name={`${rootFormField}.NHGGuarantee`}
              label={t('directMutations.loanParts.NHGGuarantee')}
              options={yesNoGroupOptions}
              required
              shouldUnregister
            />
          </Grid>
        </FieldWrapper>
      </AccordionDetails>
    </Accordion>
  );
};

export default ConvertLoan;
