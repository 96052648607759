import { LoanFormType, TypeOfPart } from 'screens/Mutations/utils/types';
import { TypeOfFinancing } from '__generated__/api';
import { getTypeOfFinancingLabel } from 'screens/Mutations/constants';
import { Option } from '../../../utils/types';
import i18n from 'i18n';
import { isValid } from 'date-fns';
import { policyDefaultValues } from '../../../components/DismissalJointLiabilityMutation/constants';
import { SelectOption } from 'types/SelectOption';

export const financeTypeOptions = (): Option[] => [
  {
    value: TypeOfFinancing.PersonalEquity,
    label: getTypeOfFinancingLabel(TypeOfFinancing.PersonalEquity) ?? '',
  },
  {
    value: TypeOfFinancing.RaiseMortgage,
    label: getTypeOfFinancingLabel(TypeOfFinancing.RaiseMortgage) ?? '',
  },
];
export const formatDate = (date: string) => {
  if (!date) return '-';
  const dateObject = new Date(date);
  if (isValid(dateObject)) {
    return new Intl.DateTimeFormat('nl', {
      year: 'numeric',
      month: 'numeric',
      day: '2-digit',
    })
      .format(dateObject)
      .replace('/', '-');
  }
  return '-';
};

export const formatPartText = (
  typeOfPart: TypeOfPart,
  loanPartIndex: number,
  loanPartNumber?: number
) => {
  const index = loanPartIndex + 1;
  if (typeOfPart === 'currentLoanParts') {
    return `${i18n.t('directMutations.form.part')}${' '}${loanPartNumber || ''}`;
  }
  if (typeOfPart === 'newLoanParts') {
    return `${i18n.t('directMutations.form.part')} ${index}`;
  }
  if (typeOfPart === 'convertLoan') {
    return i18n.t('directMutations.form.convertedPart');
  }
  if (typeOfPart === 'splitLoan') {
    return `${i18n.t('directMutations.form.part')} ${index} ${i18n.t(
      'directMutations.form.split'
    )}`;
  }
  return '';
};

export const mapPoliciesToLoan = (loan: LoanFormType): LoanFormType => {
  let newLoan = { ...loan };

  if (loan.changeLifeInsurancePolicy === 'yes' && loan.policies.length === 0) {
    newLoan = {
      ...newLoan,
      policies: [
        {
          ...policyDefaultValues,
          policyHolders: { ...policyDefaultValues.policyHolders },
          insuredPersons: { ...policyDefaultValues.insuredPersons },
        },
      ],
    };
  }
  newLoan = {
    ...newLoan,
    currentLoanParts: loan.currentLoanParts.map((loanPart) => {
      if (
        loanPart.hasConnectedPolicy === 'yes' &&
        (!loanPart.policies || loanPart.policies?.length === 0)
      ) {
        return {
          ...loanPart,
          policies: [
            {
              ...policyDefaultValues,
              policyHolders: { ...policyDefaultValues.policyHolders },
              insuredPersons: { ...policyDefaultValues.insuredPersons },
            },
          ],
        };
      }
      return loanPart;
    }),
  };
  return newLoan;
};

export const isEmptyLoanPart = (currentLoanPart: any) => {
  return (
    !currentLoanPart.loanPartNumber &&
    currentLoanPart.policies?.[0] &&
    !currentLoanPart.policies?.some((policy: any) => policy.originalLoanPart)
  );
};

export const findRepaymentTypeDisplayName = (
  productArray?: SelectOption[],
  selectedValue?: string
) => {
  if (productArray) {
    return productArray.find(({ value }) => value === selectedValue)?.label;
  }

  return selectedValue;
};
