import { TypeOfFinancing } from '__generated__/api';
import { getTypeOfFinancingLabel } from 'screens/Mutations/constants';
import { Option } from '../../../../../utils/types';

export const costOptions = (): Option[] => [
  {
    label: getTypeOfFinancingLabel(TypeOfFinancing.PersonalEquity) ?? '',
    value: TypeOfFinancing.PersonalEquity,
  },
  {
    label: getTypeOfFinancingLabel(TypeOfFinancing.RaiseMortgage) ?? '',
    value: TypeOfFinancing.RaiseMortgage,
  },
  {
    label: getTypeOfFinancingLabel(TypeOfFinancing.PersonalEquityAndRaiseMortgage) ?? '',
    value: TypeOfFinancing.PersonalEquityAndRaiseMortgage,
  },
];

export const showPersonalEquity = (typeOfFinancing?: TypeOfFinancing) => {
  if (typeOfFinancing && typeOfFinancing === TypeOfFinancing.PersonalEquityAndRaiseMortgage) {
    return true;
  }
  return undefined;
};
