import { SelectChangeEvent } from '@mui/material';
import MultiSelect from 'components/MultiSelect';
import { useTranslation } from 'react-i18next';
import Select from 'components/Select';
import { ApplicationType } from 'constants/index';
import {
  APPLICATION_TYPES,
  APPLICATION_STATUSES,
  SORT_OPTIONS_CASES,
} from 'screens/Applications/constants';
import { FilterState } from 'types/ApplicationDataDetails';
import { Case } from '__generated__/api-di';
import FabMobile from 'components/FabMobile';

type ApplicationFiltersProps = {
  filterState: FilterState;
  items?: Case[];
  handleMultiSelectChange: (e: SelectChangeEvent<any>) => void;
  handleSingleFilterChange: (e: SelectChangeEvent<any>) => void;
  isMobile?: boolean;
  onClick?: () => void;
};

export default function ApplicationFilters({
  filterState,
  handleMultiSelectChange,
  handleSingleFilterChange,
  items,
  isMobile,
  onClick,
}: ApplicationFiltersProps) {
  const { t } = useTranslation();

  const ApplicationTypeOptions = APPLICATION_TYPES.filter((at) => {
    return at !== ApplicationType.Unknown;
  }).map((type) => ({
    value: type,
    text: t(`applications.type.${type}`),
  }));
  const availableStatuses = APPLICATION_STATUSES.filter(
    (status: any) =>
      status === 'all' ||
      items?.find((application: any) => application.application!.status!.value === status)
  );

  if (isMobile) {
    return (
      <FabMobile onClickNew={onClick}>
        <MultiSelect
          label="type"
          selectedValue={filterState.type}
          handleChange={handleMultiSelectChange}
          options={ApplicationTypeOptions}
          selectAll
        />
        <Select
          label="sortBy"
          selectedValue={filterState.sortBy}
          handleChange={handleSingleFilterChange}
          options={SORT_OPTIONS_CASES}
        />
      </FabMobile>
    );
  }
  return (
    <>
      <MultiSelect
        label="type"
        selectedValue={filterState.type}
        handleChange={handleMultiSelectChange}
        options={ApplicationTypeOptions}
        selectAll
      />
      <Select
        label="status"
        selectedValue={filterState.status}
        handleChange={handleSingleFilterChange}
        options={availableStatuses}
      />
      <Select
        label="sortBy"
        selectedValue={filterState.sortBy}
        handleChange={handleSingleFilterChange}
        options={SORT_OPTIONS_CASES}
      />
    </>
  );
}
