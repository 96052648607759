import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

const SELECTED_GROUP_KEY = 'EADVISOR_SELECTED_GROUP';

export const getGroup = () => localStorage.getItem(SELECTED_GROUP_KEY);

function setGroup(group: string | null) {
  if (group) {
    localStorage.setItem(SELECTED_GROUP_KEY, group);
  } else {
    removeSelectedGroup();
  }
}

export const removeSelectedGroup = () => {
  localStorage.removeItem(SELECTED_GROUP_KEY);
};
export const removeLogInFlag = () => {
  sessionStorage.removeItem('isLoggedIn');
};
export const appGroupSelected = createAsyncThunk('app/groupSelected', setGroup);

const initialState = {
  group: getGroup(),
  groupSelectVisible: false,
  mobileDrawerVisible: false,
  onboardingJourneyVisible: false,
  requestCsvButtonDisabled: false,
  isWelcomeClosed: false,
  isFirstVisit: true,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSelectedGroup: (state, action: PayloadAction<string>) => {
      state.group = action.payload;
    },
    groupSelectVisibleChanged: (state, action: PayloadAction<boolean>) => {
      state.groupSelectVisible = action.payload;
    },
    setMobileDrawerVisible: (state, action: PayloadAction<boolean>) => {
      state.mobileDrawerVisible = action.payload;
    },
    setOnboardingJourneyVisible: (state, action: PayloadAction<boolean>) => {
      state.onboardingJourneyVisible = action.payload;
    },
    setRequestCsvButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.requestCsvButtonDisabled = action.payload;
    },
    setWelcomeClosed: (state, action: PayloadAction<boolean>) => {
      state.isWelcomeClosed = action.payload;
    },
    setFirstVisit: (state, action: PayloadAction<boolean>) => {
      state.isFirstVisit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appGroupSelected.fulfilled, (state, action) => {
      state.group = action.meta.arg;
      state.groupSelectVisible = false;
    });
  },
});

export const {
  groupSelectVisibleChanged,
  setSelectedGroup,
  setMobileDrawerVisible,
  setOnboardingJourneyVisible,
  setRequestCsvButtonDisabled,
  setWelcomeClosed,
  setFirstVisit,
} = appSlice.actions;

export default appSlice.reducer;
