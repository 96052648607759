import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, ListItem, Hidden } from '@mui/material';
import TaskStatusIcon from './TaskStatusIcon';
import TaskDateText from './TaskDateText';
import TaskButtons from './TaskButtons';
import { TaskInfo, TaskInfoDelegationState } from '__generated__/api-di';

const useStyles = makeStyles((theme) => ({
  root: {
    'minHeight': 80,
    '&:hover': {
      backgroundColor: theme.palette.white.light,
    },
    'borderRadius': 6,
    'alignItems': 'flex-start',
    'padding': theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 0),
    },
  },
  interactive: {
    cursor: 'pointer',
  },
  text: {
    flex: 1,
  },
  textCancelled: {
    color: theme.palette.text.secondary,
    textDecoration: 'line-through',
  },
  text2: {
    marginBottom: theme.spacing(1),
  },
  rejectionText: {
    marginBottom: theme.spacing(0.5),
    maxWidth: '80%',
  },
  proofItemName: {
    textTransform: 'capitalize',
    maxWidth: '80%',
  },
}));

type TaskListItemProps = {
  task: TaskInfo;
  onClickResolve: () => void;
};

const TaskListItem = ({ task, onClickResolve }: TaskListItemProps) => {
  const classes = useStyles();

  const isCancelled = task.delegationState === TaskInfoDelegationState.Revoked;

  const [expanded, setExpanded] = React.useState(false);
  const onClickItem = () => {
    setExpanded(!expanded);
  };

  return (
    <ListItem className={clsx(classes.root)} onClick={onClickItem} data-testid="task-list-item">
      <Hidden mdDown>
        <TaskStatusIcon status={task.delegationState} />
      </Hidden>
      <div className={classes.text}>
        <div className={classes.text2}>
          <Typography
            className={clsx([classes.proofItemName, { [classes.textCancelled]: isCancelled }])}
            variant="body2"
          >
            {task.description}
          </Typography>
          <TaskDateText task={task} />
        </div>
      </div>

      <TaskButtons status={task.delegationState!} onClickResolve={onClickResolve} />
    </ListItem>
  );
};

export default TaskListItem;
