import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Box, Icon, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ReactComponent as ChevronDown } from '../../assets/images/icons/select-icon.svg';

const useStyles = makeStyles((theme) => ({
  label: {
    margin: '0 8px 0 0',
    verticalAlign: 'center',
    fontSize: '12px',
    fontWeight: 400,
  },
  selectMenu: {
    'maxHeight': 500,
    'padding': theme.spacing(0, 1),
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  logo: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
  menuItem: {
    'display': 'flex',
    'height': 50,
    'paddingLeft': theme.spacing(2),
    'paddingRight': theme.spacing(2),
    'color': theme.palette.text.secondary,
    '&:hover, &.Mui-selected:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette.lightest.light,
    },
    '&.Mui-selected': {
      color: 'black',
      fontWeight: 500,
    },
  },
  spacer: {
    flex: 1,
  },
  placeholder: {
    width: 20,
  },
  selectRoot: {
    [theme.breakpoints.down('lg')]: {
      flex: 1,
      textAlign: 'right',
    },
  },
}));

type Props = {
  label: string;
  selectedValue: string;
  handleChange: (e: SelectChangeEvent<string>) => void;
  options: string[];
  component?: string | null;
};

const SingleSelect: FC<Props> = ({
  label,
  selectedValue,
  handleChange,
  options,
  component = 'applications',
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center">
      <InputLabel className={classes.label} id={label}>
        {t(`select.${label}`)}
      </InputLabel>
      <Select
        data-testid={`singleSelect-input-${label}`}
        className={classes.selectRoot}
        labelId={label}
        name={label}
        value={selectedValue}
        onChange={handleChange}
        variant="standard"
        disableUnderline
        IconComponent={(props) => (
          <ChevronDown
            {...props}
            style={{ width: 12, right: 0, top: 0, position: 'absolute', pointerEvents: 'none' }}
          />
        )}
        MenuProps={{
          disableScrollLock: true,
          classes: { paper: classes.selectMenu },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }}
        renderValue={(value) => {
          return <>{t(`${component}.filter.${value}`)}</>;
        }}
      >
        {options.map((option) => (
          <MenuItem
            data-testid={option}
            className={classes.menuItem}
            key={option}
            value={option}
            disableTouchRipple
          >
            {t(`${component}.filter.${option}`)}
            <span className={classes.spacer} />
            <Icon
              style={{
                visibility: selectedValue === option ? 'visible' : 'hidden',
                marginLeft: 18,
              }}
              color="primary"
            >
              checked
            </Icon>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default SingleSelect;
