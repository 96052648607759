import { FC, useRef, useState, useEffect, useCallback } from 'react';
import { AccordionDetails, AccordionSummary, Divider, Grid } from '@mui/material';
import AccordionLoanPartHeader from '../AccordionLoanPartHeader';
import { useFormContext } from 'react-hook-form';
import { Option, UrlParams } from '../../../../../../utils/types';
import { DeleteOutline, ExpandMore } from '@mui/icons-material';
import {
  FormInputCurrency,
  FormInputDropdown,
  FormInputNumber,
  FormToggleButtonGroup,
} from 'components/Form';
import { twoOrThreeGroupOptions, yesNoGroupOptions } from '../../../../constants';
import ConvertLoan from '../ConvertLoan';
import { renderSplitLoan } from '../../utils/renderSplitLoan';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  BoxLoanPart,
  FormContainer,
  PopoverWrapper,
  PopoverText,
  Popover,
  DeleteButton,
} from '../../LoanPart.styled';
import { useParams } from 'react-router-dom';

type PartOfLoanProps = {
  expanded: boolean;
  loanPartIndex: number;
  fieldId: string;
  technicalProductOptions: Option[];
  loanNumber: number;
  handleExpand: (id: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  handleDeleteLoanPart: (index: number, id: string) => void;
};

const PartOfLoan: FC<PartOfLoanProps> = ({
  expanded,
  loanPartIndex,
  fieldId,
  technicalProductOptions,
  loanNumber,
  handleExpand,
  handleDeleteLoanPart,
}) => {
  const { t } = useTranslation();
  const [isPopoverOpen, setPopover] = useState(false);
  const hasConnectedPolicyInput = useRef<HTMLDivElement | null>(null);
  const { watch, resetField } = useFormContext();
  const { commercialLabel } = useParams<keyof UrlParams>() as UrlParams;
  const formLoanParts = watch('loanParts');
  const {
    loanPartNumber,
    shouldSplitLoan,
    numberOfSplitLoanParts,
    loanAmount,
    repaymentType,
    hasConnectedPolicy,
  } = formLoanParts[loanPartIndex];
  const rootForm = `loanParts.${loanPartIndex}`;
  const convertLoanRootFormField = `loanParts.${loanPartIndex}.convertLoan`;
  const splitLoanRootFormField = `loanParts.${loanPartIndex}.splitLoan`;

  const togglePopup = useCallback(
    (connectedPolicyValue: boolean) => {
      if (connectedPolicyValue && commercialLabel === 'ASR_CL_ASR') {
        setPopover(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasConnectedPolicy, setPopover, commercialLabel]
  );

  useEffect(() => {
    if (shouldSplitLoan && shouldSplitLoan === 'no') {
      resetField(splitLoanRootFormField);
    }
    if (shouldSplitLoan && shouldSplitLoan === 'yes') {
      resetField(convertLoanRootFormField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSplitLoan, resetField]);

  useEffect(() => {
    if (!hasConnectedPolicy || hasConnectedPolicy === 'no') resetField('loanPartPolicies');
  }, [hasConnectedPolicy, resetField]);

  return (
    <BoxLoanPart key={fieldId} data-testid={`partOfLoan-${loanPartIndex}`}>
      <Accordion defaultExpanded expanded={expanded} onChange={handleExpand(fieldId)}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`panel${loanPartIndex}-content`}
          id={`panel${loanPartIndex}-content`}
          data-testid={`loanPart-header-${loanPartIndex}`}
        >
          <AccordionLoanPartHeader
            loanPartNumber={loanPartNumber}
            oldOrNew={
              (shouldSplitLoan && numberOfSplitLoanParts) || shouldSplitLoan === 'no' ? 'Oud' : ''
            }
            loanAmount={loanAmount}
            repaymentType={repaymentType}
            fixedRatePeriod={shouldSplitLoan || shouldSplitLoan === 'no' ? '-' : ''}
          />
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <FormContainer container spacing={2}>
            <Grid item xs={3}>
              <FormInputNumber
                name={`${rootForm}.loanNumber`}
                label={t('directMutations.form.loanNumber')}
                disabled
                defaultValue={loanNumber}
                min={0}
                max={999999999}
                decimalScale={0}
                thousandSeparator
              />
            </Grid>
            <Grid item xs={3}>
              <FormInputNumber
                name={`${rootForm}.loanPartNumber`}
                label={t('directMutations.form.loanPartNumber')}
                min={1}
                max={999}
                required
                thousandSeparator
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputCurrency
                name={`${rootForm}.loanAmount`}
                label={t('directMutations.form.loanAmount')}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <FormInputDropdown
                name={`${rootForm}.repaymentType`}
                label={t('directMutations.form.repaymentType')}
                options={technicalProductOptions}
                placeholder={t('directMutations.loanParts.repaymentTypePlaceholder')}
                required
              />
            </Grid>
            <Grid item xs={6} ref={hasConnectedPolicyInput}>
              <FormToggleButtonGroup
                name={`${rootForm}.hasConnectedPolicy`}
                label={t('directMutations.loanParts.hasConnectedPolicy')}
                options={yesNoGroupOptions}
                togglePopup={togglePopup}
                required
              />
              <Popover
                data-testid="partOfLoan-popover"
                anchorEl={hasConnectedPolicyInput.current}
                open={isPopoverOpen}
                onClick={() => setPopover(false)}
                PaperProps={{
                  style: {
                    maxWidth: '386px',
                    borderRadius: '0',
                  },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 17,
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                disableRestoreFocus
              >
                <PopoverWrapper>
                  <PopoverText variant="body1">
                    {t('directMutations.loanParts.attentionMessage')}
                  </PopoverText>
                </PopoverWrapper>
              </Popover>
            </Grid>
            <Grid item xs={6}>
              <FormToggleButtonGroup
                name={`${rootForm}.shouldSplitLoan`}
                label={t('directMutations.loanParts.shouldSplitLoan')}
                options={yesNoGroupOptions}
                required
              />
            </Grid>
            <Grid item xs={6}>
              {shouldSplitLoan && shouldSplitLoan === 'yes' && (
                <FormToggleButtonGroup
                  name={`${rootForm}.numberOfSplitLoanParts`}
                  label={t('directMutations.loanParts.numberOfSplitLoanParts')}
                  options={twoOrThreeGroupOptions}
                  required
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <FormToggleButtonGroup
                name={`${rootForm}.NHGGuarantee`}
                label={t('directMutations.loanParts.NHGGuarantee')}
                options={yesNoGroupOptions}
                required
              />
            </Grid>
          </FormContainer>
        </AccordionDetails>
      </Accordion>
      {shouldSplitLoan && shouldSplitLoan === 'no' && (
        <ConvertLoan
          loanPartIndex={loanPartIndex}
          productOptions={technicalProductOptions}
          expanded={expanded}
        />
      )}
      {shouldSplitLoan &&
        shouldSplitLoan === 'yes' &&
        renderSplitLoan(loanPartIndex, technicalProductOptions, expanded, numberOfSplitLoanParts)}
      {loanPartIndex !== 0 && (
        <DeleteButton
          onClick={() => handleDeleteLoanPart(loanPartIndex, fieldId)}
          size="medium"
          data-testid={`delete-loanPart-${loanPartIndex}`}
        >
          {t('directMutations.buttons.deleteButton')} <DeleteOutline />
        </DeleteButton>
      )}
    </BoxLoanPart>
  );
};

export default PartOfLoan;
