import { ApplicationStatus } from 'constants/index';
import { Case } from '__generated__/api-di';
import { TabKey } from '../types';

export const getApplicationTab = ({ application }: Case) => {
  const status = application?.status?.value as keyof typeof ApplicationStatus;

  return getDefaultTab(status);
};

export const getDefaultTab = (status?: keyof typeof ApplicationStatus): TabKey => {
  switch (status) {
    case ApplicationStatus.offerAdministrativeDocuments:
    case ApplicationStatus.inAdministrativeReview:
    case ApplicationStatus.creditAccepted:
    case ApplicationStatus.notaryDocumentsSent:
      return 'administrativeDocuments';
    case ApplicationStatus.agreementMade:
      return 'finalContracts';
    default:
      return 'offerLetter';
  }
};
