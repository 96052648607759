import type { Option } from '../../../../../utils/types';
import SplitLoan from '../components/SplitLoan';

export const renderSplitLoan = (
  loanPartIndex: number,
  technicalProductOptions: Option[],
  expanded: boolean,
  numberOfSplitLoanParts?: number
) => {
  if (numberOfSplitLoanParts) {
    return Array.from(Array(numberOfSplitLoanParts)).map((part, splitLoanIndex) => (
      <SplitLoan
        key={`splitloan-${part}-${splitLoanIndex}`}
        loanPartIndex={loanPartIndex}
        splitLoanIndex={splitLoanIndex}
        productOptions={technicalProductOptions}
        expanded={expanded}
      />
    ));
  }
  return undefined;
};
