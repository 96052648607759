import { mapEnum } from 'utils/global';
import { TypeOfRedemption } from '__generated__/api';
import { FIXED_INTEREST_PERIOD_IN_YEARS, maximumNumber } from '../constants';
import { schemaDraft07 } from './constants';

const schema = {
  $schema: schemaDraft07,
  $id: 'https://e-adviseur.stater.com/buytolet/loan-parts',
  type: 'array',
  minItems: 1,
  maxItems: 25,
  items: {
    properties: {
      amount: {
        type: 'number',
        maximum: maximumNumber,
        minimum: 1,
        $comment: 'buy_to_let_validation_amount',
      },
      typeOfRedemption: {
        enum: mapEnum(TypeOfRedemption),
        description: 'Type of redemption',
        $comment: 'buy_to_let_type_of_redemption',
      },
      fixedInterestPeriod: {
        enum: FIXED_INTEREST_PERIOD_IN_YEARS,
        description: 'Fixed interest period',
        $comment: 'buy_to_let_fixed_interest_period',
      },
      duration: {
        type: 'number',
        maximum: 360,
        minimum: 1,
        $comment: 'buy_to_let_validation_duration',
      },
    },

    required: ['amount', 'typeOfRedemption', 'fixedInterestPeriod', 'duration'],
  },
};

export default schema;
