import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';

import {
  FormInputDropdown,
  FormInputText,
  FormToggleButtonGroup,
  FormInputDate,
  FormInputNumber,
} from 'components/Form';

import {
  yesNoGroupOptions,
  maritalStatus,
  numberDropdownValues,
} from 'screens/Mutations/constants';
import { ApplicantFormStepProps } from 'screens/Mutations/utils/types';
import FormTitleText from 'components/Form/FormTitle';
import { FieldsGrid, SubTitle } from 'screens/Mutations/molecules/styled';

export type PersonalInfoProps = ApplicantFormStepProps & {
  name: string;
};

const PersonalInfo: FC<PersonalInfoProps> = ({ title, subtitle, name, rootFormName }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const hasNewApplicant = watch('hasNewApplicant');

  return (
    <>
      <FormTitleText title={name.trim() || t(`${title}`)} description={t(`${subtitle}`)} />
      <SubTitle>{t('directMutations.personalInfo.formTitle')}</SubTitle>
      <FieldsGrid container>
        {rootFormName === 'newApplicant' && (
          <Grid container columnSpacing={3}>
            <Grid item xs={12}>
              <FormToggleButtonGroup
                name="hasNewApplicant"
                label={t('directMutations.personalInfo.hasNewApplicant')}
                options={yesNoGroupOptions}
                required
                testId="hasNewApplicant"
              />
            </Grid>
          </Grid>
        )}
        {((hasNewApplicant === 'yes' && rootFormName === 'newApplicant') ||
          rootFormName === 'currentApplicant') && (
          <>
            <Grid container columnSpacing={3}>
              <Grid item xs={3}>
                <FormInputText
                  name="initials"
                  label={t('directMutations.personalInfo.initials')}
                  required
                  maxLength={15}
                  shouldUnregister
                  testId="initials"
                />
              </Grid>
              <Grid item xs={9}>
                <FormInputText
                  name="firstNames"
                  label={t('directMutations.personalInfo.firstNames')}
                  maxLength={40}
                  required
                  shouldUnregister
                  testId="firstNames"
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3}>
              <Grid item xs={3}>
                <FormInputText
                  name="insertion"
                  label={t('directMutations.personalInfo.insertion')}
                  maxLength={15}
                  shouldUnregister
                  testId="insertion"
                />
              </Grid>
              <Grid item xs={9}>
                <FormInputText
                  name="surname"
                  label={t('directMutations.personalInfo.surname')}
                  maxLength={40}
                  required
                  shouldUnregister
                  testId="surname"
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3}>
              <Grid item xs={12}>
                <FormInputDate
                  name="dateOfBirth"
                  label={t('directMutations.personalInfo.dateOfBirth')}
                  required
                  shouldUnregister
                  testId="dateOfBirth"
                  disableFuture
                />
              </Grid>
            </Grid>
            {rootFormName === 'currentApplicant' && (
              <Grid container columnSpacing={3}>
                <Grid item xs={12}>
                  <FormInputDropdown
                    name="maritalStatusVSOld"
                    label={t('directMutations.personalInfo.maritalStatusVSOld')}
                    options={maritalStatus}
                    placeholder="Kies burgerlijke staat/relatie"
                    required
                    shouldUnregister
                    testId="maritalStatusVSOld"
                  />
                </Grid>
              </Grid>
            )}
            <Grid container columnSpacing={3}>
              <Grid item xs={12}>
                <FormInputDropdown
                  name="maritalStatus"
                  label={t('directMutations.personalInfo.maritalStatus')}
                  options={maritalStatus}
                  placeholder="Kies huidige burgerlijke staat/relatie"
                  required
                  shouldUnregister
                  testId="maritalStatus"
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3}>
              <Grid item xs={12}>
                <FormInputDropdown
                  name="numberOfDivorces"
                  label={t('directMutations.personalInfo.numberOfDivorces')}
                  options={numberDropdownValues}
                  placeholder="Vul een aantal in"
                  required
                  testId="numberOfDivorces"
                  shouldUnregister
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3}>
              <Grid item xs={12}>
                <FormInputNumber
                  name="children"
                  label={t('directMutations.personalInfo.children')}
                  placeholder="Vul een aantal in"
                  min={0}
                  max={99}
                  decimalScale={0}
                  required
                  shouldUnregister
                  testId="children"
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3}>
              <Grid item xs={12}>
                <FormToggleButtonGroup
                  name="homeOwner"
                  label={t(
                    `directMutations.personalInfo.${
                      rootFormName === 'currentApplicant' ? 'homeOwner' : 'newHomeOwner'
                    }`
                  )}
                  options={yesNoGroupOptions}
                  required
                  shouldUnregister
                  testId="homeOwner"
                />
              </Grid>
            </Grid>
          </>
        )}
      </FieldsGrid>
    </>
  );
};

export default PersonalInfo;
