import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { BtlApplicant } from 'types/BtlFormApplication';
import CurrencyFormat from 'screens/Applications/components/ApplicationNew/CurrencyFormat';

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    '& td': {
      borderBottom: 'none',
    },
    '&.error': {
      'backgroundColor': theme.palette.danger.light,
      '& :last-child': {
        color: theme.palette.danger.main,
      },
    },
  },
  errorIcon: {
    'float': 'right',
    'display': 'none',
    '.error &': {
      display: 'block',
    },
  },
  loanPartTitle: {
    marginBottom: theme.spacing(2),
  },
}));

type BuyToLetApplicantIncomesAndPropertiesSummaryProps = {
  applicant: BtlApplicant;
  applicantIndex: number;
  onClickField: (field: string) => void;
  errorMessage: (id: string, category: string) => void;
};

const BuyToLetApplicantIncomesAndPropertiesSummary: FC<
  BuyToLetApplicantIncomesAndPropertiesSummaryProps
> = ({ applicant, applicantIndex, onClickField, errorMessage }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const form = 'buyToLetForm.incomesAndProperties';

  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(
              errorMessage(`[${applicantIndex}].accumulatedIncomes[0].explanation`, 'applicants')
            ),
          })}
        >
          <TableCell>
            {t(`${form}.explanation`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('explanation')}>
            {applicant.accumulatedIncomes && applicant.accumulatedIncomes[0]
              ? applicant.accumulatedIncomes[0].explanation
              : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(
              errorMessage(`[${applicantIndex}].accumulatedIncomes[0].income`, 'applicants')
            ),
          })}
        >
          <TableCell>
            {t(`${form}.income`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('income')}>
            {applicant.accumulatedIncomes && applicant.accumulatedIncomes[0]
              ? applicant.accumulatedIncomes[0].income && (
                  <CurrencyFormat value={applicant.accumulatedIncomes[0].income} />
                )
              : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(
              errorMessage(`[${applicantIndex}].numberOfRentalCollateralsOwned`, 'applicants')
            ),
          })}
        >
          <TableCell>{t(`${form}.numberOfRentalCollateralsOwned`)}</TableCell>
          <TableCell onClick={() => onClickField('numberOfRentalCollateralsOwned')}>
            {applicant.numberOfRentalCollateralsOwned}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(
              errorMessage(`[${applicantIndex}].totalValueOfRentalCollateralsOwned`, 'applicants')
            ),
          })}
        >
          <TableCell>{t(`${form}.totalValueOfRentalCollateralsOwned`)}</TableCell>
          <TableCell onClick={() => onClickField('totalValueOfRentalCollateralsOwned')}>
            {!!applicant.totalValueOfRentalCollateralsOwned && (
              <CurrencyFormat value={applicant.totalValueOfRentalCollateralsOwned} />
            )}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(
              errorMessage(`[${applicantIndex}].isVastgoedBelangMember`, 'applicants')
            ),
          })}
        >
          <TableCell>
            {t(`${form}.isVastgoedBelangMember`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('isVastgoedBelangMember')}>
            {applicant.isVastgoedBelangMember !== (undefined || null)
              ? t(`${form}.${applicant.isVastgoedBelangMember}`)
              : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].isShareholder`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.shareholder`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('isShareholder')}>
            {applicant.isShareholder !== (undefined || null) &&
              t(`${form}.${applicant.isShareholder}`)}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        {applicant.isShareholder && (
          <>
            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(
                  errorMessage(`[${applicantIndex}].companyNameShareholdership`, 'applicants')
                ),
              })}
            >
              <TableCell>
                {t(`${form}.companyNameShareholdership`)}
                <span className="required">*</span>
              </TableCell>
              <TableCell onClick={() => onClickField('companyNameShareholdership')}>
                {applicant.companyNameShareholdership}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>

            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(
                  errorMessage(`[${applicantIndex}].shareholderPercentage`, 'applicants')
                ),
              })}
            >
              <TableCell>{t(`${form}.shareholderPercentage`)}</TableCell>
              <TableCell onClick={() => onClickField('shareholderPercentage')}>
                {applicant.shareholderPercentage?.toString().replace('.', ',')}
                {!!applicant.shareholderPercentage && <span>%</span>}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>
          </>
        )}

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].isDirector`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.director`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('isDirector')}>
            {applicant.isDirector !== (undefined || null) && t(`${form}.${applicant.isDirector}`)}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        {applicant.isDirector && (
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(
                errorMessage(`[${applicantIndex}].companyNameDirectorship`, 'applicants')
              ),
            })}
          >
            <TableCell>
              {t(`${form}.companyNameDirectorship`)}
              <span className="required">*</span>
            </TableCell>
            <TableCell onClick={() => onClickField('companyNameDirectorship')}>
              {applicant.companyNameDirectorship}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default BuyToLetApplicantIncomesAndPropertiesSummary;
