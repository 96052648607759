import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OverviewTable from './components/OverviewTable';
import { useQueryFunctionalitiesOverview } from 'use/functionalitiesOverview';
import LoadingIndicator from 'components/LoadingIndicator';
import { PageContainer } from './Overview.style';
import { useQueryProfiles } from 'use/profiles';
import { sortCommercialLabelsList } from './utils';
import HeaderTemplate from 'components/Templates/Header/HeaderTemplate';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setResetClientFilters } from 'store/genericClientListFiltersReducer';

export default function Overview() {
  const { t } = useTranslation();
  const { data: functionalitiesData, isLoading, isError } = useQueryFunctionalitiesOverview();
  const { data: profiles } = useQueryProfiles();
  const commercialLabels: string[] = sortCommercialLabelsList(
    functionalitiesData?.allCommercialLabels,
    profiles
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setResetClientFilters());
  }, [dispatch]);

  return (
    <>
      <HeaderTemplate sectionName={t('general.header.menu.functionalities-overview')} />
      {isLoading ? (
        <LoadingIndicator />
      ) : isError || !functionalitiesData ? (
        <Box
          height="50%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h6">{t('error.noData')}</Typography>
        </Box>
      ) : (
        <PageContainer maxWidth="lg">
          <OverviewTable
            categories={functionalitiesData.categories}
            allCommercialLabels={commercialLabels}
          />
        </PageContainer>
      )}
    </>
  );
}
