import React, { FC, MouseEventHandler } from 'react';
import { Grid, Typography, Icon, Paper as MUIPaper, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const Paper = styled(MUIPaper)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  padding: theme.spacing(4),
  borderRadius: 4,
  marginBottom: theme.spacing(3),
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontWeight: 'bold',
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  color: 'grey',
  fontWeight: 'normal',
}));

export type SummaryCardProps = {
  title: string;
  subtitle?: string;
  onEditClick?: MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  testdId?: string;
};

const SummaryCard: FC<SummaryCardProps> = ({ title, subtitle, onEditClick, children, testdId }) => {
  const { t } = useTranslation();

  return (
    <Paper elevation={2} data-testid={`${testdId}-summary-card`}>
      <Grid container justifyContent="space-between">
        <Title data-testid={`${testdId}-summary-card-title`}>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
        <Button
          variant="text"
          color="primary"
          endIcon={<Icon>pencil</Icon>}
          onClick={onEditClick}
          data-testid={`${testdId}-summary-card-button`}
        >
          {t('general.buttons.edit')}
        </Button>
      </Grid>
      <Grid data-testid={`${testdId}-summary-grid`}>{React.Children.toArray(children)}</Grid>
    </Paper>
  );
};

export default SummaryCard;
