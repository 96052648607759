import { useQuery } from 'react-query';
import { http, HEADER_SELECTED_GROUP_CLAIM } from '../http';
import { useGroup } from './group';

const NIBC_BTL_ID = 440;

async function getCommercialProducts(group: string | null): Promise<number[]> {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const response = await http.get('/api/authorizations/commercial-products', { headers });
  return response.data.commercialProducts;
}

export function useCommercialProducts() {
  const group = useGroup();
  const result = useQuery(['commercial-products', group], () => getCommercialProducts(group), {
    enabled: !!group,
  });

  // TODO: prefetch?
  const commercialProducts = result.data ?? [];
  const isBtlAllowed = commercialProducts.includes(NIBC_BTL_ID);

  return {
    isBtlAllowed,
    commercialProducts,
  };
}
