import { Grid } from '@mui/material';
import UserNotificationsPopup from 'components/UserNotifications/UserNotificationsPopup';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteConfig } from 'routes';
import { useGoogleTagManager } from 'use/gtm';
import HeaderMenu from './components/HeaderMenu';
import LogoutButton from './components/LogoutButton';
import NavListItem from './components/NavListItem';
import NotificationsPreferencesButton from './components/NotificationsPreferencesButton';
import OnboardingJourneyButton from './components/OnboardingJourneyButton';
import OpenManualButton from './components/OpenManualButton';
import OverviewRouteButton from './components/OverviewRouteButton';

type NavDrawerContentProps = {
  routes: RouteConfig[];
  commercialRelation: string | null;
  closeDrawer: () => void;
};

export default function NavDrawerContent({
  routes,
  commercialRelation,
  closeDrawer,
}: NavDrawerContentProps) {
  const googleTagManager = useGoogleTagManager();
  const navigate = useNavigate();

  const menuRoutes = routes.filter((r) => {
    if (commercialRelation) {
      return r.isLabeled && r.showInMenu;
    }
    return r.showInMenu && !r.isLabeled;
  });
  const notificationPreferenceRoute: RouteConfig | undefined = routes.find(
    ({ name }) => name === 'notification-preference'
  );
  const overviewRoute: RouteConfig | undefined = routes.find(
    ({ name }) => name === 'functionalities-overview'
  );

  const handleClick = useCallback(
    (routeName?: string) => {
      closeDrawer();
      if (routeName) {
        const btnName = routeName.replace(/^\w/, (c: string) => c.toUpperCase());

        googleTagManager({
          event: 'ButtonClick',
          btnName: `Navigation${btnName}`,
        });
        googleTagManager({
          event: 'Navigate',
          btnName: btnName,
        });
      }
    },
    [closeDrawer, googleTagManager]
  );

  const handleHomePageNavigation = useCallback(
    () => () => {
      navigate('/');
      handleClick('HomeLogo');
      localStorage.removeItem('commercialRelation');
    },
    [handleClick, navigate]
  );

  return (
    <>
      <HeaderMenu
        closeDrawer={closeDrawer}
        commercialRelation={commercialRelation}
        handleHomePageNavigation={handleHomePageNavigation}
      />

      <Grid container height="100%" justifyContent="space-between">
        <Grid item xs={12}>
          {menuRoutes.map((route) => (
            <NavListItem
              key={route.name}
              route={route}
              onClick={handleClick}
              commercialRelation={commercialRelation}
            />
          ))}
        </Grid>
        <Grid item alignSelf="end" xs={12}>
          {!commercialRelation && (
            <>
              <NotificationsPreferencesButton
                onClick={handleClick}
                route={notificationPreferenceRoute}
              />
              <OverviewRouteButton onClick={handleClick} route={overviewRoute} />
              <OnboardingJourneyButton />
            </>
          )}
          <OpenManualButton />
          <LogoutButton />
          <UserNotificationsPopup />
        </Grid>
      </Grid>
    </>
  );
}
