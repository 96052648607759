import { HEADER_SELECTED_GROUP_CLAIM, http } from '../http';

export const requestCsv = async (group: string | null): Promise<any> => {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };

  const response = await http({
    url: '/api/v1/lenders/client-extract-export',
    method: 'POST',
    headers,
  });

  return response;
};
