import {
  Policy,
  LoanPartType,
  LoanDetailsFormType,
  ConversionForm,
  LoanPartPolicy,
} from 'screens/Mutations/utils/types';
import { TypeOfPolicyMutation } from '__generated__/api';
import { Status, TypeOfLoan, Step } from './types';

export const convertLoanDefaultValues = {
  repaymentType: undefined,
  fixedRatePeriod: undefined,
  NHGGuarantee: undefined,
};

export const splitLoanDefaultValues = {
  amount: undefined,
  fixedRatePeriod: undefined,
  repaymentType: undefined,
  NHGGuarantee: undefined,
};

export const policyDefaultValues: Policy = {
  policyNumber: '',
  numberOfPolicyHolders: undefined,
  policyHolders: undefined,
  isPolicyForConnectedPersons: undefined,
  numberOfInsuredPersons: undefined,
  insuredPersons: undefined,
  typeOfPolicyMutation: undefined,
  mutationDescription: undefined,
};

export const loanPartDefaultValues: LoanPartType = {
  convertLoan: undefined,
  splitLoan: undefined,
  loanPartNumber: undefined,
  repaymentType: undefined,
  shouldSplitLoan: undefined,
  NHGGuarantee: undefined,
  loanAmount: undefined,
  hasConnectedPolicy: undefined,
  numberOfSplitLoanParts: undefined,
};

export const loanDetailDefaultValues: LoanDetailsFormType = {
  conversionQuestionnaire: {
    residualDebt: undefined,
    loanNumber: 0,
    toggleInterest: undefined,
    toggleFine: undefined,
    toggleCoFinancedFine: undefined,
  },
  loanParts: [loanPartDefaultValues],
  status: Status.New,
  typeOfLoan: TypeOfLoan.Conversion,
  commercialLabel: '',
  moneylenderName: '',
  id: undefined,
};

export const mutationFormDefaultValues: ConversionForm = {
  connectedPolicies: {
    loanPartPolicies: [
      {
        loanPartNumber: undefined,
        policies: [policyDefaultValues],
      },
    ],
  },
  loanDetails: { ...loanDetailDefaultValues },
  acceptedAgreement: undefined,
  applicants: [
    {
      id: 0,
      initials: '',
      preposition: '',
      surname: '',
    },
  ],
};

export const yesNoGroupOptions = [
  { label: 'Ja', value: 'yes' },
  { label: 'Nee', value: 'no' },
];

export const twoOrThreeGroupOptions = [
  { label: '2 delen', value: 2 },
  { label: '3 delen', value: 3 },
];

export const oneOrTwoGroupOption = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
];

export const source = 'e-advisor';

export const policyChange = [
  { label: 'Aangepast', value: TypeOfPolicyMutation.Altered },
  { label: 'Beëindigd', value: TypeOfPolicyMutation.Ended },
  { label: 'Voortgezet', value: TypeOfPolicyMutation.Continued },
];

/**
 *  Get state and loanparts to check if the loanpart policies need to be filled with state
 *  or if default values need to be added
 * @param loanPartPoliciesState
 * @param loanParts
 * @returns LoanPartPolicy[]
 */
export const getDefaultLoanpartPolicyValues = (
  loanPartPoliciesState: LoanPartPolicy[],
  loanParts: LoanPartType[]
): LoanPartPolicy[] => {
  const result: any[] = [];

  if (loanPartPoliciesState && loanPartPoliciesState.length > 0) {
    result.push(
      ...loanPartPoliciesState.map((loanPartPolicy) => {
        return {
          loanPartNumber: loanPartPolicy.loanPartNumber,
          policies: loanPartPolicy.policies.map((policy) => ({
            ...policy,
            policyHolders: { ...policy.policyHolders },
            insuredPersons: { ...policy.insuredPersons },
          })),
        };
      })
    );
  }

  const loanpartsLength = loanParts.filter(
    (loanPart) => loanPart.hasConnectedPolicy === 'yes'
  ).length;

  if (loanpartsLength > loanPartPoliciesState.length) {
    for (let i = 0; i < loanpartsLength - loanPartPoliciesState.length; i++) {
      result.push({
        loanPartNumber: undefined,
        policies: [
          {
            ...policyDefaultValues,
            policyHolders: { ...policyDefaultValues.policyHolders },
            insuredPersons: { ...policyDefaultValues.insuredPersons },
          },
        ],
      });
    }
  }
  return result;
};

export const formSteps: Step[] = [
  { name: 'loanDetails', index: 0 },
  { name: 'policies', index: 1 },
  { name: 'additionalInformation', index: 2 },
  { name: 'summary', index: 3 },
];

export const steps = {
  loanDetails: 'loanDetails',
  policies: 'policies',
  summary: 'summary',
};
