import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Sidebar as SidebarMolecule } from 'components/Molecules/components/SideBar/SideBar.styled';

const ContainerGrid = styled(Grid)(() => ({
  marginTop: '72px',
}));

const ContentGrid = styled(Grid)(({ theme }) => ({
  width: 'calc(100% - 360px)',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.only('md')]: {
    width: 'calc(100% - 300px)',
  },
}));

const SidebarGrid = styled(Grid)(({ theme }) => ({
  height: 'calc(100vh - 72px)',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  width: '360px',
  [theme.breakpoints.only('md')]: {
    width: '300px',
  },
}));

const SideBar = styled(SidebarMolecule)(({ theme }) => ({
  position: 'fixed',
  width: '360px',
  padding: '64px 40px',
  [theme.breakpoints.only('md')]: {
    width: '300px',
    padding: '64px 20px',
  },
  top: '72px',
  height: 'calc(100% - 72px)',
}));

export { ContainerGrid, ContentGrid, SidebarGrid, SideBar };
