import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { checkCookies } from '../utils/global';
import { getUserIdFromToken } from 'auth/oidc';
import { useLocation } from 'react-router-dom';
import history from '../history';

export type DataLayer = {
  event: string;
  path?: string;
  btnName?: string;
  statusPriority?: string;
  proofItemName?: string;
  notificationName?: string;
  newsTitle?: string;
  selectedGroup?: string;
  device?: string;
  pageNumber?: number;
  filterValue?: string[] | string;
  searchTerm?: string;
  filterCategory?: string[] | string;
  sortValue?: string;
  errorMessage?: string;
  file?: string;
  fileType?: string;
  totalApplications?: number;
  moneylenderName?: string;
  applicationStatus?: string;
  typeOfLetter?: string;
  errorType?: string;
  uploadSuccesful?: boolean;
  typeOfMutation?: string;
  page?: { url: string };
  funnel?: string;
  step?: string;
};

export function useGoogleTagManager() {
  const location = useLocation();

  const tagEvent = (dataLayer: DataLayer) => {
    googleTagManager({
      ...dataLayer,
      page: {
        url: removeLoanNumberFromURL(location.pathname),
      },
    });
  };

  return tagEvent;
}

export function googleTagManager(dataLayer: DataLayer) {
  const { event, ...otherProps } = dataLayer;

  const dataLayerArgs = {
    dataLayer: {
      event: event,
      ...otherProps,
    },
  };

  TagManager.dataLayer(dataLayerArgs);
}

export function removeLoanNumberFromURL(url: string) {
  return url.replace(/\/\d{5,7}/, '/#loanNumber#');
}

export async function useInitTagManager() {
  const location = useLocation();

  const cookiesAccepted = checkCookies();
  useEffect(() => {
    async function CB() {
      const gtmId: string = (window as any).REACT_APP?.GA_ID;
      const userId = await getUserIdFromToken();

      if (gtmId && cookiesAccepted === true) {
        TagManager.initialize({ gtmId });

        // tag first page on load
        const dataLayerArgs: any = {
          dataLayer: {
            event: 'pageview',
            page: {
              url: removeLoanNumberFromURL(location.pathname),
            },
            config: { anonymize_ip: true },
          },
        };
        if (userId) dataLayerArgs.dataLayer.userId = userId;
        TagManager.dataLayer(dataLayerArgs);
      }

      // tag route changes
      const unlisten = undefined;
      history.listen(() => {
        const dataLayerArgs: any = {
          dataLayer: {
            event: 'pageview',
            page: {
              url: removeLoanNumberFromURL(location.pathname),
            },
          },
        };
        if (userId) dataLayerArgs.dataLayer.userId = userId;
        TagManager.dataLayer(dataLayerArgs);
      });

      return unlisten;
    }
    CB();
  }, [cookiesAccepted]); // eslint-disable-line react-hooks/exhaustive-deps
}
