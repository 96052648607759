import { SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { FilterState, DataType } from 'types/ApplicationDataDetails';
import { useGoogleTagManager } from 'use/gtm';
import { ApplicationData } from '__generated__/api';
import { Case } from '__generated__/api-di';
import { handleFilterCase, handleFilterApplication } from './helpers';

const defaultCasesFilters: FilterState = {
  moneylender: ['all'],
  sortBy: 'highestPriority',
  type: ['all'],
  status: 'all' as string,
};
const defaultConceptFilters: FilterState = {
  moneylender: ['all'],
  sortBy: 'date',
  type: ['all'],
  status: 'all' as string,
};

export function useFilter(dataType: DataType, items?: Case[] | ApplicationData[]) {
  const defaultFilters = dataType === 'cases' ? defaultCasesFilters : defaultConceptFilters;
  const [filterState, setFilterState] = useState(defaultFilters);
  const [filteredItems, setFilteredItems] = useState<Case[] | ApplicationData[]>(() => items ?? []);
  const googleTagManager = useGoogleTagManager();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    googleTagManager({
      event: 'Search',
      searchTerm: value,
    });
  };

  useEffect(() => {
    if (items && Array.isArray(items)) {
      dataType === 'cases'
        ? setFilteredItems(handleFilterCase(items as Case[], searchTerm, filterState))
        : setFilteredItems(
            handleFilterApplication(items as ApplicationData[], searchTerm, filterState)
          );
    }
  }, [searchTerm, filterState, items, setFilteredItems, dataType]);

  const handleFilter = (name: string, value: string | string[]) => {
    setFilterState({ ...filterState, [name]: value });
    googleTagManager({
      event: name === 'sortBy' ? 'Sort' : 'Filter',
      filterCategory: name,
      filterValue: value,
    });
  };
  const handleSingleFilterChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    handleFilter(name, value);
  };
  const handleMultiSelectChange = (e: SelectChangeEvent<any>) => {
    const { name, value } = e.target;
    const newValues =
      value[value.length - 1] === 'all' || value.length === 0
        ? ['all']
        : (value as string[]).filter((item) => item !== 'all');
    handleFilter(name, newValues);
  };
  const emptySearch = () => {
    handleSearch('');
  };
  const handleInputChange = (e: React.ChangeEvent<any>) => {
    const value: string = e.currentTarget.value;
    handleSearch(value);
  };
  return {
    filterState,
    searchTerm,
    emptySearch,
    filteredItems,
    handleInputChange,
    handleMultiSelectChange,
    handleSingleFilterChange,
  };
}
