import { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { http, HEADER_SELECTED_GROUP_CLAIM } from '../http';
import { useGroup } from 'use/group';
import type {
  NotificationDateAccessed,
  NotificationResponse,
  Notification,
} from '../__generated__/api';

async function getNotifications(group: string | null): Promise<Notification[]> {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const response = await http.get<NotificationResponse>('/api/notifications', {
    headers,
  });
  return response.data.notifications;
}

async function getNotificationDocument(reference: string) {
  const response = await http.get(`/api/notifications/${reference}/document`, {
    responseType: 'arraybuffer',
  });
  return response.data;
}

async function updateNotification(reference: string): Promise<void> {
  const notificationDateAccessed: NotificationDateAccessed = {
    dateAccessed: new Date().toISOString(),
  };
  await http.patch(`/api/notifications/${reference}`, notificationDateAccessed);
}

export function useQueryNotifications() {
  const group = useGroup();
  return useQuery(['notifications', group], () => getNotifications(group), {
    enabled: !!group,
  });
}

export function useQueryNotificationDocument(reference: string) {
  const { status, data, isLoading } = useQuery(
    ['document', reference],
    () => getNotificationDocument(reference),
    {
      enabled: !!reference,
    }
  );

  return {
    status,
    data,
    isLoading,
  };
}

export function useMutationNotification() {
  return useMutation((reference: string) => updateNotification(reference), {});
}

export function useNotificationCount() {
  const [notificationCount, setCount] = useState(0);
  const notifications = useQueryNotifications();
  useEffect(() => {
    setCount(notifications?.data?.filter((item) => !item.dateAccessed).length ?? 0);
  }, [notifications]);
  return { notificationCount };
}
