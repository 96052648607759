import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useWizardStep } from 'screens/Mutations/Wizard/hooks';
import { hasConnectedPolicies } from '../../utils';
import BottomNavigationMolecule from 'components/Molecules/components/BottomNavigation';

const SUMMARY_INDEX = 3;

type BottomNavigationProps = {
  summaryDisabled: boolean;
  isLoadingSubmit: boolean;
  handleSave: (isNotification?: boolean) => Promise<boolean>;
  isNextDisabled: boolean;
};

const BottomNavigation: FC<BottomNavigationProps> = ({
  summaryDisabled,
  isLoadingSubmit,
  handleSave,
  isNextDisabled,
}) => {
  const { trigger } = useFormContext();
  const { activeStepIndex, goToStep, nextStep, previousStep } = useWizardStep();
  const loanParts = useWatch({ name: 'loanParts' });
  const isConnectedPolicy = hasConnectedPolicies(loanParts);
  const isSummary = activeStepIndex === SUMMARY_INDEX;

  const handleSummaryClick = () => {
    goToStep(SUMMARY_INDEX);
  };

  const goNext = async () => {
    const validate = await trigger();
    if (validate) {
      await handleSave();
      if (activeStepIndex === 0) {
        isConnectedPolicy ? goToStep(1) : goToStep(2);
      } else {
        nextStep();
      }
    }
  };

  const goPrevious = () => {
    if (activeStepIndex === 2) {
      isConnectedPolicy ? goToStep(1) : goToStep(0);
    } else {
      previousStep();
    }
  };

  return (
    <BottomNavigationMolecule
      isNextDisabled={isNextDisabled}
      nextButtonStyleType={isSummary ? 'submit' : 'button'}
      hasPreviousStep={activeStepIndex > 0}
      isSubmitting={isLoadingSubmit}
      isSummaryDisabled={summaryDisabled}
      onPreviousClick={goPrevious}
      onNextClick={goNext}
      onSummaryClick={handleSummaryClick}
    />
  );
};
export default BottomNavigation;
