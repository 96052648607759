import { Hidden, IconButton, Icon, Button, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { buttonStyles } from '../styling';

type DisabledButtonProps = {
  text: string;
};

export const DisabledUploadButton = ({ text }: DisabledButtonProps) => {
  const { t } = useTranslation();
  const classes = buttonStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Hidden mdUp>
        <div
          data-testid="disabledUpload-button"
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          title={t('applications.detail.upload')}
        >
          <IconButton color="primary" disabled data-testid="disabled-upload-button">
            <Icon>upload</Icon>
          </IconButton>
        </div>
      </Hidden>
      <Hidden mdDown>
        <div
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseOver={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Button
            data-testid="disabled-upload-button"
            color="primary"
            variant="contained"
            disabled
            className={classes.button}
            endIcon={<Icon className={classes.icon}>upload</Icon>}
          >
            {t('applications.detail.upload')}
          </Button>
        </div>
      </Hidden>
      <Popover
        id="mouse-over-popover"
        open={open}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{text}</Typography>
      </Popover>
    </>
  );
};
