import { styled } from '@mui/material/styles';

import {
  Box as MuiBox,
  Grid as MuiGrid,
  StepConnector as MuitStepConnector,
  StepLabel as MuiStepLabel,
  Stepper as MuiStepper,
  Typography as MuiTypography,
  Container as MuiContainer,
} from '@mui/material';

import backgroundImageStater from 'assets/images/half.png';

const Container = styled(MuiContainer)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
}));

const GridContainer = styled(MuiGrid)({
  maxWidth: '420px',
});

const Box = styled(MuiBox)(({ theme }) => ({
  width: 330,
  height: '100%',
  backgroundColor: theme.palette.purple.dark,
  position: 'fixed',
  backgroundImage: `url("${backgroundImageStater}")`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '0 70px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const Stepper = styled(MuiStepper)(({ theme }) => ({
  marginTop: '80px',
  padding: theme.spacing(0, 4, 6),
  backgroundColor: 'transparent',
}));

const StepConnector = styled(MuitStepConnector)({
  display: 'none',
});

const StepLabel = styled(MuiStepLabel)(({ theme }) => ({
  'display': 'flex',
  'alignItems': 'center',
  'width': 250,
  'height': 40,
  'lineHeight': '40px',
  'padding': theme.spacing(1, 3),
  'borderRadius': theme.shape.borderRadius,
  'backgroundColor': theme.palette.primary.main,
  '&.Mui-disabled': {
    backgroundColor: 'transparent',
  },
  '&.Mui-disabled .MuiTypography-body1': {
    color: 'rgba(255, 255, 255, 0.72)',
  },
}));

const Typography = styled(MuiTypography)(({ theme }) => ({
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '16px',
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '0.02em',
  color: theme.palette.white.main,
}));

export { Box, Container, GridContainer, Stepper, StepConnector, StepLabel, Typography };
