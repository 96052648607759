import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, ListItem, Collapse, Hidden } from '@mui/material';
import {
  getProofItemStatus,
  isUploadAllowed,
  isUploadAllowedForAssignedTeamType,
  ProofItemStatus,
} from '../../utils/proof-item';
import type { ProofItem } from '../../types/ProofItem';
import ProofItemStatusIcon from './ProofItemStatusIcon';
import ProofItemDateText from './ProofItemDateText';
import { getId, useQueryApplicationDetailsEx } from 'use/cases';
import { Case } from '../../__generated__/api-di';
import env from 'config/env';
import ReuploadButton from './components/ReuploadButton';
import UploadButton from './components/UploadButton';
import LoadingButton from './components/LoadingButton';
import { DisabledUploadButton } from './components/DisabledUploadButton';
import { getProofItemButtonOptions } from './utils/getProofItemButtonOptions';
import DownloadButton from './components/DownloadButton/DownloadButton';
import ProofItemButtonOptions from 'types/ProofItemsButtons';

const useStyles = makeStyles((theme) => ({
  root: {
    'minHeight': 80,
    '&:hover': {
      backgroundColor: theme.palette.white.light,
    },
    'borderRadius': 6,
    'alignItems': 'flex-start',
    'padding': theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 0),
    },
  },
  interactive: {
    cursor: 'pointer',
  },
  text: {
    flex: 1,
  },
  textCancelled: {
    color: theme.palette.text.secondary,
    textDecoration: 'line-through',
  },
  text2: {
    marginBottom: theme.spacing(1),
  },
  rejectionText: {
    marginBottom: theme.spacing(0.5),
    maxWidth: '80%',
  },
  proofItemName: {
    textTransform: 'capitalize',
  },
  buttons: {
    top: theme.spacing(2),
    transform: 'none',
    [theme.breakpoints.down('sm')]: {
      top: 0,
      right: 0,
    },
  },
}));

type ProofItemListItemProps = {
  proofItem: ProofItem;
  application: Case;
  onClickView: (proofItem: ProofItem) => void;
  onClickUpload?: () => void;
};

const ProofItemListItem: React.FC<ProofItemListItemProps> = ({
  proofItem,
  application,
  onClickView,
  onClickUpload,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const status = getProofItemStatus(proofItem.status);

  const isCancelled = status === ProofItemStatus.cancelled;
  const isRejected = status === ProofItemStatus.rejected || status === ProofItemStatus.turnedDown;
  const showRejectedText = isRejected && proofItem.reasonForRejection;

  const onClickItem = () => setExpanded(!expanded);

  const { data, isLoading } = useQueryApplicationDetailsEx(getId(application));

  const uploadAllowedForCommercialLabels = isUploadAllowed(application);
  const uploadAllowedForAssignedTeamTypes = isUploadAllowedForAssignedTeamType(application, data);

  const text = !uploadAllowedForCommercialLabels
    ? t('applications.detail.uploadDisabledForCommercialLabels')
    : !uploadAllowedForAssignedTeamTypes
    ? t('applications.detail.uploadDisabledForAssignedTeamTypes')
    : '';

  const numberOfUploads = proofItem.numberOfUploads ?? 0;
  const reuploadAvailable = env.FEATURES.STAT_398216_REUPLOAD;

  const handleUploadClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      onClickUpload && onClickUpload();
    },
    [onClickUpload]
  );

  const options: ProofItemButtonOptions | null = useMemo(() => {
    if (isLoading) return null;
    return getProofItemButtonOptions(
      status,
      proofItem.isUploadAllowed,
      proofItem.isOckto,
      reuploadAvailable,
      numberOfUploads
    );
  }, [
    isLoading,
    proofItem.isUploadAllowed,
    proofItem.isOckto,
    status,
    numberOfUploads,
    reuploadAvailable,
  ]);

  return (
    <ListItem
      className={clsx(classes.root, { [classes.interactive]: showRejectedText })}
      onClick={onClickItem}
      data-testid="proofItems-list-item"
    >
      <Hidden mdDown>
        <ProofItemStatusIcon status={status} />
      </Hidden>
      <div className={classes.text}>
        <div className={classes.text2}>
          <Typography
            className={clsx([classes.proofItemName, { [classes.textCancelled]: isCancelled }])}
            variant="body2"
          >
            {proofItem.description}
          </Typography>
          <ProofItemDateText proofItem={proofItem} application={application} />
        </div>
        {showRejectedText && (
          <ProofItemRejectedText
            expanded={expanded}
            rejectionText={proofItem.reasonForRejection!}
          />
        )}
      </div>
      {isLoading && <LoadingButton />}
      {options && (
        <div className={classes.buttons}>
          {options.hasDisabledUploadButton && <DisabledUploadButton text={text} />}
          {options.hasDownloadButton && (
            <DownloadButton onClick={onClickView} $case={application} proofItem={proofItem} />
          )}
          {options.hasUploadButton && <UploadButton onClick={handleUploadClick} />}
          {options.hasReuploadButton && <ReuploadButton onClick={handleUploadClick} />}
        </div>
      )}
    </ListItem>
  );
};

const ProofItemRejectedText = (props: { expanded: boolean; rejectionText: string }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Collapse in={props.expanded} data-testid="proofItemRejectedText">
        <div className={classes.rejectionText}>{props.rejectionText}</div>
      </Collapse>
      {props.expanded ? (
        <Typography color="primary" variant="subtitle2">
          {t('proofitem.hideRejectionText')}
        </Typography>
      ) : (
        <Typography color="primary" variant="subtitle2">
          {t('proofitem.showRejectionText')}
        </Typography>
      )}
    </div>
  );
};

export default ProofItemListItem;
