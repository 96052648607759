import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    'height': '100%',
    'backgroundColor': theme.palette.primary.main,
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'justifyContent': 'center',
    'color': theme.palette.white.main,
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  logo: {
    width: 48,
    height: 48,
  },
}));

const OnboardingJourneyFinish = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography variant="h3">{t('onboarding.step.titleFinish')}</Typography>
      <Typography>{t('onboarding.step.textFinish')}</Typography>
      <Button color="inherit" onClick={props.onFinish}>
        {t('onboarding.step.finish')}
      </Button>
      <Button color="inherit" onClick={props.onReplay}>
        {t('onboarding.step.replayDemo')}
      </Button>
    </div>
  );
};

export default OnboardingJourneyFinish;
