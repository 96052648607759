import React, { FC } from 'react';
import Unload from './Unload';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, IconButton, Slide, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TransitionProps } from '@mui/material/transitions/transition';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    padding: theme.spacing(4, 4),
  },
  title: {
    display: 'flex',
    alignItems: 'flex-start',
    color: theme.palette.text.secondary,
    padding: theme.spacing(0, 0, 2, 0),
  },
  close: {
    margin: theme.spacing(-2, 0, 0, 0),
  },
  buttons: {
    padding: theme.spacing(4, 0, 2, 0),
  },
  button: {
    margin: theme.spacing(0, 2, 0, 0),
  },
  spacer: {
    flexGrow: 1,
  },
}));

const Transition = React.forwardRef<unknown, TransitionProps>((props: any, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open?: boolean;
  pristine?: boolean;
  onContinue: () => void;
  onCancel: () => void;
};

const ExitWithoutSavingPopup: FC<Props> = ({
  open = false,
  pristine = true,
  onContinue,
  onCancel,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      {!pristine && <Unload />}
      <Dialog open={!pristine && open} TransitionComponent={Transition} onClose={onCancel}>
        <Box className={classes.content}>
          <div className={classes.title}>
            <Typography variant="h6">{t('general.exitWithoutSaving.header')}</Typography>
            <span className={classes.spacer} />
            <IconButton onClick={onCancel} className={classes.close}>
              <Close />
            </IconButton>
          </div>

          <Box className={classes.buttons}>
            <Button
              onClick={onContinue}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {t('general.exitWithoutSaving.continueButton')}
            </Button>
            <Button onClick={onCancel} variant="text" className={classes.button}>
              {t('general.exitWithoutSaving.cancelButton')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ExitWithoutSavingPopup;
