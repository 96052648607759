import { FC } from 'react';
import { CardRow, CardSection, SummaryCard } from 'components/SummaryCard';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import { formatYesNo } from 'utils/formatYesNo/formatYesNo';
import { formatAmount } from 'utils/formatAmount';
import { formatLoanNumber } from 'utils/global';

type LoanSummaryProps = {
  onClick: (step: string) => void;
};

export const LoanSummary: FC<LoanSummaryProps> = ({ onClick }) => {
  const { t } = useTranslation();

  const loan = useSelector((state: RootState) => state.ohaForm.loan);

  const handleOnClick = (step: string) => () => onClick(step);

  return (
    <SummaryCard
      title={t('directMutations.loan.formTitle')}
      onEditClick={handleOnClick('financingLoan')}
    >
      <CardSection>
        <CardRow
          name={t('directMutations.form.loanNumber')}
          value={formatLoanNumber(loan.loanNumber)}
        />
        <CardRow
          name={t('directMutations.summary.originalLoan')}
          value={formatAmount(loan.amount)}
        />
        <CardRow
          name={t('directMutations.summary.amountRaise')}
          value={formatAmount(loan.amountRaise)}
        />
        <CardRow
          name={t('directMutations.summary.extraPayOffAmount')}
          value={formatAmount(loan.extraPayOffAmount)}
        />
        <CardRow
          name={t('directMutations.summary.changeLifeInsurancePolicy')}
          value={formatYesNo(loan.changeLifeInsurancePolicy)}
        />
        <CardRow
          name={t('directMutations.summary.changeCurrentMortgage')}
          value={formatYesNo(loan.changeCurrentMortgage)}
        />
      </CardSection>
    </SummaryCard>
  );
};

export default LoanSummary;
