import { number, object, string } from 'yup';
import { errorMessages, getTypeOfPensionOptions } from 'screens/Mutations/constants';
import endDate from 'utils/form/validations/endDate';
import { defaultDate } from 'utils/form/validations';
import isNil from 'utils/predicates/isNil';

const pensionTypeValues = getTypeOfPensionOptions().map((f) => f.value);

export const pensionSchema = object({
  typeOfBenefit: string().oneOf(pensionTypeValues).required(errorMessages.required),
  startDate: defaultDate.when('typeOfBenefit', {
    is: (v: any) => !isNil(v),
    then: defaultDate.required(errorMessages.required),
  }),
  endDate: endDate.isFutureDate(errorMessages.FutureDate),
  incomeAmount: number()
    .nullable(true)
    .when('typeOfBenefit', {
      is: (v: any) => !isNil(v),
      then: number()
        .nullable(true)
        .min(1)
        .required(errorMessages.required)
        .typeError(errorMessages.number),
    }),
});
