import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { http } from '../http';
import { useGroup } from 'use/group';
import type { LetterResponse, Letter } from '../__generated__/api';

async function getClientLetters(commercialLabel: string, loanNumber: number): Promise<Letter[]> {
  const response = await http.get<LetterResponse>(
    `/api/v1/letters/client/${commercialLabel}/${loanNumber}`
  );

  return response.data.letters;
}

async function getClientLetterDocument(
  commercialLabel: string,
  letterId: string,
  loanNumber: number
) {
  const response = await http.get(
    `/api/v1/letters/client/${commercialLabel}/${loanNumber}/${letterId}/content `,
    {
      responseType: 'arraybuffer',
    }
  );
  return response.data;
}

export function useQueryClientLetters(commercialLabel: string, loanNumber: number) {
  const group = useGroup();
  return useQuery(
    ['letters', commercialLabel, loanNumber],
    () => getClientLetters(commercialLabel, loanNumber),
    {
      enabled: !!group,
    }
  );
}

async function getApplicationLetters(
  commercialLabel: string,
  applicationIndex: string,
  loanNumber: string
): Promise<Letter[]> {
  const response = await http.get<LetterResponse>(
    `/api/v1/letters/application/${commercialLabel}/${loanNumber}/${applicationIndex}`
  );
  return response.data.letters;
}

async function getApplicationLetterDocument(
  commercialLabel: string,
  applicationIndex: string,
  letterId: string,
  loanNumber: string
) {
  const response = await http.get(
    `/api/v1/letters/application/${commercialLabel}/${loanNumber}/${applicationIndex}/${letterId}/content `,
    {
      responseType: 'arraybuffer',
    }
  );
  return response.data;
}

export function useQueryApplicationLetters(
  commercialLabel: string,
  applicationIndex: string,
  loanNumber: string
) {
  const group = useGroup();
  return useQuery(
    ['letters', commercialLabel, loanNumber, applicationIndex],
    () => getApplicationLetters(commercialLabel, loanNumber, applicationIndex),
    {
      enabled: !!group,
    }
  );
}

export function useQueryClientLetterDocument(
  commercialLabel: string,
  letterId: string,
  loanNumber: number
) {
  const { status, data, isLoading } = useQuery(
    ['document', commercialLabel, loanNumber, letterId],
    () => getClientLetterDocument(commercialLabel, letterId, loanNumber),
    {
      enabled: !!letterId,
    }
  );

  return {
    status,
    data,
    isLoading,
  };
}

export function useQueryApplicationLetterDocument(
  commercialLabel: string,
  index: string,
  letterId: string,
  loanNumber: string
) {
  const { status, data, isLoading } = useQuery(
    ['document', commercialLabel, loanNumber, index, letterId],
    () => getApplicationLetterDocument(commercialLabel, index, letterId, loanNumber),
    {
      enabled: !!letterId,
    }
  );

  return {
    status,
    data,
    isLoading,
  };
}

export function useLetterCount(commercialLabel: string, index: string, loanNumber: string) {
  const [letterCount, setCount] = useState(0);
  const letters = useQueryApplicationLetters(commercialLabel, loanNumber, index);
  useEffect(() => {
    setCount(letters?.data?.filter((item) => !item.creationDate).length ?? 0);
  }, [letters]);
  return { letterCount };
}
