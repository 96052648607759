import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Hidden, Paper, Button, Icon, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProofItemSelect from '../AddProofitemSolution/ProofItemSelect';
import { ProofItem } from 'types/ProofItem';
import { Document } from 'types/Document';
import { Action, ReUploadSolution } from '../../../store/taskReducer';
import ViewDocument from 'components/DocumentViewer/ViewDocument';
import DocumentUpload from 'components/DocumentUpload';
import clsx from 'clsx';
import { Case } from '__generated__/api-di';

const iconNeutral = require('../../../assets/images/task-item/neutral.svg').default;

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 6,
    display: 'flex',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
  },
  fileName: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  icon: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
  },
  text: {
    flex: 1,
  },
  text2: {
    marginBottom: theme.spacing(0),
  },
  documentType: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
  },
  small: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    display: 'block',
    paddingTop: theme.spacing(0.5),
  },
  interpunct: {
    margin: theme.spacing(0, 1),
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.danger.main,
    flexDirection: 'column',
  },
  proofItemName: {
    textTransform: 'capitalize',
  },
}));

interface ViewButtonProps {
  onClick: () => void;
}
const ViewButton: React.FC<ViewButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <>
      <Hidden mdUp>
        <IconButton
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
          title={t('tasks.solutions.viewDocument')}
          size="large"
        >
          {/* TODO: Determine if we should show text or icon */}
          <Icon>file_text</Icon>
        </IconButton>
      </Hidden>
      <Hidden mdDown>
        <Button
          color="primary"
          variant="text"
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          {t('tasks.solutions.viewDocument')}
        </Button>
      </Hidden>
    </>
  );
};

interface RemoveButtonProps {
  onClick: () => void;
}
const RemoveButton: React.FC<RemoveButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Hidden mdUp>
        <IconButton
          className={classes.deleteButton}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
          size="large"
        >
          <Icon>trash</Icon>
        </IconButton>
      </Hidden>
      <Hidden mdDown>
        <Button
          className={classes.deleteButton}
          endIcon={<Icon className={classes.btnIcon}>trash</Icon>}
          variant="text"
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          {t('tasks.solutions.delete')}
        </Button>
      </Hidden>
    </>
  );
};

interface ReUploadProofItemSolutionProps {
  application: Case;
  issueId: string;
  solution: ReUploadSolution;
  proofItems: Array<ProofItem>;
  dispatch: React.Dispatch<Action>;
}

const ReUploadProofItemSolution: React.FC<ReUploadProofItemSolutionProps> = ({
  application,
  issueId,
  solution,
  dispatch,
  proofItems,
}) => {
  const classes = useStyles();

  const state = solution.state;

  const [previewVisible, setPreviewVisible] = React.useState(false);

  const openPreview = (proofItem: ProofItem) => {
    setPreviewVisible(true);

    dispatch({
      type: 'update',
      payload: {
        issueId: issueId,
        data: {
          ...solution,
          state: { ...solution.state, selectedProofItem: proofItem },
        },
      },
    });
  };
  const closePreview = () => {
    setPreviewVisible(false);

    dispatch({
      type: 'update',
      payload: {
        issueId: issueId,
        data: { ...solution, state: { ...solution.state } },
      },
    });
  };
  const openUpload = (proofItem: ProofItem) => {
    dispatch({
      type: 'update',
      payload: {
        issueId: issueId,
        data: {
          ...solution,
          state: { ...solution.state, selectedProofItem: proofItem, uploadVisible: true },
        },
      },
    });
  };
  const closeUpload = () => {
    dispatch({
      type: 'update',
      payload: {
        issueId: issueId,
        data: {
          ...solution,
          state: { ...solution.state, uploadVisible: false },
        },
      },
    });
  };
  const success = () => {
    closeUpload();
  };
  const handleUpload = (
    document: Document,
    updatePercentagProgress: (percent: number) => void,
    successResponse: () => void,
    errorResponse: (errorMessage: string) => void
  ) => {
    updatePercentagProgress(100);
    successResponse();

    dispatch({
      type: 'update',
      payload: {
        issueId: issueId,
        data: {
          ...solution,
          data: {
            ...solution.data,
            file: document.file,
            proofItem: {
              typeCode: solution.state.selectedProofItem?.code!,
              sequenceNumber: solution.state.selectedProofItem?.documentSequenceNumber!,
            },
          },
          state: {
            ...solution.state,
            uploadVisible: false,
            showProofItemSelect: false,
          },
        },
      },
    });
  };
  const deleteSolution = () => {
    dispatch({
      type: 'remove',
      payload: {
        issueId: issueId,
        data: solution,
      },
    });
  };
  const handleProofItemClose = () => {
    dispatch({
      type: 'remove',
      payload: {
        issueId: issueId,
        data: solution,
      },
    });
  };

  const icon = iconNeutral.default ?? iconNeutral;

  return (
    <>
      {solution.data.file && (
        <Paper className={classes.root}>
          <Hidden mdDown>
            <img className={classes.icon} src={icon} alt="" />
          </Hidden>
          <div className={classes.text}>
            <div className={classes.text2}>
              <Typography className={clsx([classes.proofItemName])} variant="body1">
                {solution.data.proofItem?.typeCode} {/* TODO: Translate */}
              </Typography>
              <div className={classes.fileName}>
                <Typography className={clsx([classes.small])} variant="body2">
                  {solution.data.file.name}
                </Typography>
                <Typography className={clsx([classes.small, classes.interpunct])}>‧</Typography>
                <Typography className={clsx([classes.small])} variant="body2">
                  {solution.data.file.size} kb
                </Typography>
              </div>
            </div>
          </div>

          <ViewButton onClick={() => setPreviewVisible(true)} />
          <RemoveButton onClick={deleteSolution} />
        </Paper>
      )}

      {previewVisible && (
        <ViewDocument
          commercialLabel={application.lender!.label!}
          loanNumberExternal={application.application!.loanNumberExternal!}
          documentId={state.selectedProofItem?.documentId ?? 0}
          open
          proofItem={state.selectedProofItem!}
          onClose={closePreview}
          file={solution.data.file ?? undefined}
        />
      )}
      <DocumentUpload
        commercialLabel={application.lender!.label!}
        loanNumber={application.application!.loanNumberExternal!}
        applicationIndexNumber={application.application!.index!}
        open={state.uploadVisible}
        proofItem={state.selectedProofItem!}
        uploadTarget={handleUpload}
        onSuccess={() => success()}
        onCancel={closeUpload}
      />
      <ProofItemSelect
        application={application}
        dispatch={dispatch}
        issueId={issueId}
        solution={solution}
        closeClick={handleProofItemClose}
        openUpload={(application, proofItem) => {
          openUpload(proofItem);
        }}
        openPreview={(application, proofItem) => {
          openPreview(proofItem);
        }}
        proofItems={proofItems}
        show={solution.state.showProofItemSelect}
      />
    </>
  );
};

export default ReUploadProofItemSolution;
