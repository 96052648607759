import React, { FC } from 'react';
import clsx from 'clsx';
import { LinearProgress, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

const BorderLinearProgress = withStyles((theme) =>
  createStyles({
    root: {
      height: 4,
      borderRadius: 4,
      width: '100%',
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[200],
    },
    bar: {
      borderRadius: 5,
    },
  })
)(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  bar: {
    '&.red': {
      backgroundColor: theme.palette.danger.dark,
    },
    '&.orange': {
      backgroundColor: theme.palette.warning.dark,
    },
    '&.grey': {
      backgroundColor: theme.palette.text.primary,
    },
    '&.green': {
      backgroundColor: theme.palette.success.dark,
    },
    '&.blue': {
      backgroundColor: theme.palette.info.dark,
    },
  },
  text: {
    fontSize: '12px',
    fontWeight: 400,
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
  },
}));

type Props = {
  label: string;
  urgency: string;
  progress: number;
};

const StatusTag: FC<Props> = ({ urgency, label, progress = 0 }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.text}>{label}</Typography>
      <BorderLinearProgress
        classes={{ bar: clsx([classes.bar, urgency]) }}
        variant="determinate"
        value={progress}
      />
    </div>
  );
};

export default StatusTag;
