import { CircularProgress } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollateralValuesFormWrapper from './components/CollateralValuesFormWrapper';
import { useForm, FormProvider } from 'react-hook-form';
import { useMoneylenders } from 'use/clients';
import TitleBar from 'components/TitleBar';
import { useParams } from 'react-router-dom';
import type { UrlParams } from '../../utils/types';
import type { CollateralLoanDetailsFormType } from 'screens/Mutations/utils/types';
import SpinnerWrapper from 'components/SpinnerWrapper/styled';
import { getCollateralValuesMutationFormSchema } from './collateralValueFormSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import BottomNavigation from 'components/Molecules/components/BottomNavigation';
import FormTemplate from 'components/Templates/FormTemplate';
import { jsDateToLocalISO8601DateString } from 'utils/form/validations/getDateValueFromInput';
import { useSubmitCollateral } from 'screens/Mutations/hooks/useMutations';
import { Form } from 'components/Molecules/components/Form/Form.styled';
import { setCollateralValuesSubmitStatus } from 'store/collateralValuesFormReducer';
import { useDispatch } from 'react-redux';
import useMutationForm from 'screens/Mutations/hooks/useMutationForm';
import { useGoogleTagManager } from 'use/gtm';
import { useClients } from 'screens/Clients/hooks/useClients';
import FormStepper from 'components/FormStepper';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

const CollateralValuesMutation: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleNavigateOnCloseForm } = useClients();
  const { commercialLabel, loanNumber } = useParams<keyof UrlParams>() as UrlParams;
  const { moneylenderName } = useMutationForm();
  const googleTagManager = useGoogleTagManager();
  const [snackbar, setSnackbar] = useState<{ status: string; text: string } | undefined>(undefined);
  const formMethods = useForm<CollateralLoanDetailsFormType>({
    defaultValues: {
      collateralValueQuestionnaire: {
        loanNumber: +loanNumber ?? 0,
      },
    },
    resolver: yupResolver(getCollateralValuesMutationFormSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { handleSubmit } = formMethods;
  const { isLoading: loadingMoneyLenders } = useMoneylenders();

  useEffect(() => {
    googleTagManager({
      funnel: 'Aanvraag',
      step: '1',
      event: 'pageview',
    });
  }, [googleTagManager]);

  // add moneylenders
  const titleBarProps = {
    moneyLender: moneylenderName,
    commercialLabel: commercialLabel,
    title: t('mutations.type.CollateralValue'),
    onCloseClick: handleNavigateOnCloseForm,
  };

  const handleError = (error: any) => {
    const errorMessage =
      error?.response?.data?.errors?.FormFile[0] ??
      t('directMutations.snackbar.sendMutation.error');

    setSnackbar({
      status: 'error',
      text: errorMessage,
    });

    googleTagManager({
      event: 'upload',
      btnName: 'UploadDocumentBrowse',
      uploadSuccesful: false,
      funnel: 'CollateralValues',
    });
  };

  const handleSuccess = () => {
    dispatch(setCollateralValuesSubmitStatus('success'));
    handleNavigateOnCloseForm();

    googleTagManager({
      event: 'submitForm',
      typeOfMutation: 'Collateral',
      moneylenderName: moneylenderName ?? '',
    });
  };

  const handleUploadError = (errorStatus: string, size: number) => {
    setSnackbar({
      status: 'error',
      text: t(`error.${errorStatus}`, { fileSize: size }),
    });
  };

  const { mutateAsync: uploadMutationForm, isLoading: isLoadingSendApplication } =
    useSubmitCollateral(handleSuccess, handleError);

  const submit = (values: any) => {
    const formValues = {
      ...values.collateralValueQuestionnaire,
      date: jsDateToLocalISO8601DateString(values.collateralValueQuestionnaire.date),
      commercialLabel,
    };

    uploadMutationForm(formValues);
  };

  if (loadingMoneyLenders) {
    return (
      <SpinnerWrapper>
        <CircularProgress size={50} />
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(submit)}>
          <FormTemplate
            header={<TitleBar {...titleBarProps} />}
            sidebar={<FormStepper />}
            footer={
              <BottomNavigation
                nextButtonStyleType="submit"
                hasPreviousStep={false}
                isSubmitting={isLoadingSendApplication}
                isSummaryDisabled
              />
            }
          >
            <CollateralValuesFormWrapper handleError={handleUploadError} />
          </FormTemplate>
        </Form>
      </FormProvider>
      <CustomSnackbar
        isOpen={!!snackbar}
        handleClose={() => setSnackbar(undefined)}
        severity={snackbar?.status === 'error' ? 'error' : 'success'}
        message={snackbar?.text ? snackbar?.text : ''}
      />
    </>
  );
};

export default CollateralValuesMutation;
