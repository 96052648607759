import { styled } from '@mui/styles';
import { Grid, Container, Box } from '@mui/material';

export const TableHeader = styled(Box)<any, any>(({ theme, isscroll }) => ({
  position: 'sticky',
  top: '0',
  backgroundColor: theme.palette.white.main,
  boxShadow: isscroll ? theme.shadows[13] : theme.shadows[0],
  transition: 'box-shadow 0.5s ease-in-out',
}));

export const PageContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4, 3, 8),
}));

export const FilterHeader = styled(Grid)(({ theme }) => ({
  borderColor: theme.palette.grey[300],
  borderStyle: 'solid',
  borderWidth: '1px 0 0 1px',
}));

export const FilterArea = styled(Grid)<any, any>(({ theme, grow }) => ({
  borderColor: theme.palette.grey[300],
  borderStyle: 'solid',
  borderWidth: '0 1px 1px 0',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexGrow: grow ? 100 : 0,
  width: 65,
}));

export const GridRowHeader = styled(Grid)<any, any>(({ theme, columnheader }) => {
  let headerProps = {};
  if (columnheader > 0) {
    headerProps = {
      flexGrow: 100,
      height: '48px',
      paddingTop: theme.spacing(1),
      textTransform: 'uppercase',
      color: theme.palette.grey[600],
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };
  } else {
    headerProps = {
      flexGrow: 100,
      height: '40px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };
  }

  return {
    ...headerProps,
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderWidth: '0 1px 1px 1px',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    fontWeight: 500,
    fontSize: '12px',
    minWidth: '184px',
    maxWidth: '340px',
  };
});

export const GridRow = styled(Grid)<any, any>(({ theme, columnHeader }) => ({
  height: columnHeader ? '48px' : '40px',
  borderColor: theme.palette.grey[300],
  borderStyle: 'solid',
  borderWidth: '0 1px 1px 0',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  minWidth: '58px',
  maxWidth: '58px',
  width: '58px',
  flexGrow: 1,
  padding: theme.spacing(0, 2),
}));
