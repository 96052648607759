import { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  category: {
    backgroundColor: theme.palette.grey[200],
    padding: '2px 8px',
    fontSize: '10px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderRadius: 15,
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

type Props = {
  category: string;
};

const CategoryNews: FC<Props> = ({ category }) => {
  const classes = useStyles();

  return <div className={classes.category}>{category} </div>;
};

export default CategoryNews;
