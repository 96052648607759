import { Hidden, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HomeHeaderWrapper } from '../Home.styled';

const HomeHeader = () => {
  const { t } = useTranslation();

  return (
    <HomeHeaderWrapper>
      <Hidden smDown>
        <Box height={107} style={{ marginBottom: 75 }}>
          <Typography variant="h4" style={{ textShadow: '#0008 0px 1px 10px' }}>
            {t('home.welcome')}
          </Typography>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Typography variant="h5">{t('home.welcome')}</Typography>
      </Hidden>
    </HomeHeaderWrapper>
  );
};
export default HomeHeader;
