import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ClockIcon from '@mui/icons-material/AccessTime';
import theme from 'theme';
import { ConsentStatus } from '__generated__/api-di';

export type ClientDataSharingIconProps = {
  status: ConsentStatus;
};

const ClientDataSharingIcon: FC<ClientDataSharingIconProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <>
      {status === ConsentStatus.Granted && (
        <span title={t('clients.dataSharing.granted')} data-testid="consent-granted">
          <CheckCircleIcon
            htmlColor={theme.palette.success.dark}
            fontSize="small"
            data-testid="clientdata-granted"
          />
        </span>
      )}

      {status === ConsentStatus.Requested && (
        <span title={t('clients.dataSharing.requested')} data-testid="consent-requested">
          <ClockIcon
            htmlColor={theme.palette.warning.main}
            fontSize="small"
            data-testid="clientdata-requested"
          >
            clock
          </ClockIcon>
        </span>
      )}

      {status === ConsentStatus.Revoked && (
        <span title={t('clients.dataSharing.revoked')} data-testid="consent-revoked">
          <CancelIcon
            htmlColor={theme.palette.error.dark}
            fontSize="small"
            data-testid="clientdata-revoked"
          />
        </span>
      )}

      {status === ConsentStatus.Denied && (
        <span title={t('clients.dataSharing.denied')} data-testid="consent-denied">
          <CancelIcon
            htmlColor={theme.palette.error.dark}
            fontSize="small"
            data-testid="clientdata-denied"
          />
        </span>
      )}
    </>
  );
};

export default ClientDataSharingIcon;
