import { Hidden, Box, Typography, Grid } from '@mui/material';
import Header from 'components/Header';
import Logo from 'components/Logo';
import { ReactElement } from 'react';
import { getProfileName } from 'utils/global';
import { HeaderBox, LogoBox } from './HeaderTemplate.styled';

type HeaderTemplateProps = {
  sectionName: string;
  filters?: ReactElement | null;
  extraButtons?: ReactElement | null;
  commercialRelation?: string | null;
  profiles?: any;
};

export default function HeaderTemplate({
  sectionName,
  filters,
  extraButtons,
  commercialRelation,
  profiles,
}: HeaderTemplateProps) {
  const moneyLenderName = getProfileName(profiles, commercialRelation);
  return (
    <Header paddingTop={commercialRelation ? 4 : 8}>
      <Hidden smDown>
        <HeaderBox width="100%">
          {commercialRelation ? (
            <Grid container direction="row" alignItems="center" mb={2}>
              <LogoBox>
                <Logo label={commercialRelation} size="M" isTitle={false} />
              </LogoBox>
              <Grid>
                <Typography data-testid="header-title" variant="h4">
                  {sectionName}
                </Typography>
                <Typography data-testid="header-moneyLenderName" variant="body1">
                  {moneyLenderName}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography data-testid="header-title" variant="h4">
              {sectionName}
            </Typography>
          )}
          <Box
            pt={1}
            pb={commercialRelation ? 2 : 1}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box> {filters}</Box>
            <Box> {extraButtons}</Box>
          </Box>
        </HeaderBox>
      </Hidden>
      <Hidden smUp>
        <Typography data-testid="header-title-mobile" variant="h5">
          {sectionName}
        </Typography>
      </Hidden>
    </Header>
  );
}
