import { FC, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box } from 'components/MutationPageContainer/styled';
import { useQueryMutationInfo } from 'components/MutationDialog/useMutation';
import { useGetTechnicalProducts, useGetMutationApplication } from '../../hooks/useMutations';
import type { ConnectedPoliciesFormType, LoanDetailsFormType, UrlParams } from '../../utils/types';
import { useMoneylenders } from 'use/clients';
import { loanDetailDefaultValues, mutationFormDefaultValues } from './constants';
import { RootState } from 'store';
import { mapConceptApplication } from '../../utils/mappers/conversion/apiToFormMapper';
import mapClientData from '../../utils/mappers/conversion/mapClientDataToForm';
import { mapProductsToOptions } from '../../utils/mappers/mapProductsToOptions';
import SpinnerWrapper from 'components/SpinnerWrapper/styled';
import { useClientData } from 'use/clientData';
import FormWizard from './components/FormWizard/FormWizard';
import Wizard from 'screens/Mutations/Wizard/Wizard';
import Steps from './components/FormWizard/Steps';
import { StepNames } from './types';
import { useGoogleTagManager } from 'use/gtm';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

const ConversionMutation: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const googleTagManager = useGoogleTagManager();
  const { state } = useLocation();
  const { loanNumber, commercialLabel } = useParams<keyof UrlParams>() as UrlParams;
  const [isLoading, setIsLoading] = useState(true);
  const [prefillDataStatus, setPrefillDataStatus] = useState<string | undefined>('isLoading');
  const { data: clientData, isLoading: isClientDataLoading } = useClientData(
    commercialLabel,
    +loanNumber
  );
  const [connectedPolicies, setConnectedPolicies] = useState<ConnectedPoliciesFormType>({
    ...mutationFormDefaultValues.connectedPolicies,
  });
  const applicationId = useSelector(
    (state: RootState) => state.conversionForm.loanDetails.id,
    (next, prev) => prev !== undefined && prev >= 0
  );
  const [loanDetails, setLoanDetails] = useState<LoanDetailsFormType>();
  const [moneyLender, setMoneyLender] = useState<string>();
  const [snackbar, setSnackbar] = useState<{ status: string; name: string } | undefined>(undefined);

  const {
    error: moneyLendersError,
    data: moneyLenders,
    isLoading: loadingMoneyLenders,
  } = useMoneylenders();
  const {
    refetch: getApplication,
    error: applicationError,
    data: applicationData,
    isLoading: loadingGetApplication,
  } = useGetMutationApplication(applicationId);

  const {
    error: mutationInfoError,
    data: mutationInfo,
    isLoading: loadingMutationInfo,
  } = useQueryMutationInfo(parseInt(loanNumber, 10), commercialLabel);

  const {
    error: technicalProductsError,
    data: technicalProductsData,
    isLoading: loadingTechnicalProducts,
  } = useGetTechnicalProducts(commercialLabel, parseInt(loanNumber, 10));

  useEffect(() => {
    if (applicationId && applicationId !== 0) {
      googleTagManager({
        funnel: 'Aanvraag',
        event: 'ContinueSavedForm',
      });
    } else {
      googleTagManager({
        funnel: 'Aanvraag',
        step: '1',
        event: 'pageview',
      });
    }
  }, [applicationId, googleTagManager]);

  useEffect(() => {
    // only once so not on change of its dependencies
    if (applicationId && applicationId !== 0) getApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isClientDataLoading) {
      setPrefillDataStatus('isLoading');
    }
    if (clientData && Object.keys(clientData).length !== 0) {
      setLoanDetails(mapClientData(clientData, commercialLabel));
      setPrefillDataStatus('Granted');
    }
    if (!clientData || Object.keys(clientData).length === 0) {
      setPrefillDataStatus('Revoked');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData]);

  useEffect(() => {
    if (applicationId === undefined || loanNumber === undefined) {
      navigate(state.from);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId, loanNumber, commercialLabel, navigate]);

  useEffect(() => {
    if (!clientData) {
      if (applicationId && applicationData) {
        const formattedData = mapConceptApplication(applicationData);
        setLoanDetails({ ...formattedData.loanDetails, id: applicationId });
        setConnectedPolicies(formattedData.connectedPolicies);
      } else {
        const defaultValues = {
          ...loanDetailDefaultValues,
          commercialLabel,
          id: applicationId,
          conversionQuestionnaire: {
            ...loanDetailDefaultValues.conversionQuestionnaire,
            loanNumber: +loanNumber,
          }
        };
        setLoanDetails(defaultValues);
      }
    }
  }, [applicationData, applicationId, clientData, commercialLabel, loanNumber]);

  useEffect(() => {
    if (moneyLenders) {
      const moneyLender = moneyLenders.find((lender) => lender.commercialLabel === commercialLabel);
      if (moneyLender) {
        setMoneyLender(moneyLender.moneylenderName);
      }
    }
  }, [moneyLenders, commercialLabel]);

  useEffect(() => {
    if (
      !loadingGetApplication &&
      !loadingTechnicalProducts &&
      !loadingMutationInfo &&
      !loadingMoneyLenders &&
      prefillDataStatus !== 'isLoading'
    ) {
      setIsLoading(false);
    }
  }, [
    loadingGetApplication,
    loadingTechnicalProducts,
    loadingMutationInfo,
    loadingMoneyLenders,
    prefillDataStatus,
  ]);
  const handleNotification = (status?: string, name?: string) => {
    if (status && name) {
      setSnackbar({ status: status, name: name });
    }
  };

  useEffect(() => {
    if (mutationInfoError) {
      // eslint-disable-next-line no-console
      console.warn('Could not fetch mutationInfo');
    } else if (moneyLendersError || technicalProductsError) {
      handleNotification('error', 'loadingForm');
    } else if (applicationError) {
      handleNotification('error', 'getApplication');
    }
  }, [moneyLendersError, mutationInfoError, applicationError, technicalProductsError]);

  return (
    <Box>
      {!isLoading && loanDetails ? (
        <>
          <Wizard>
            <FormWizard
              loanDetails={{ ...loanDetails, moneylenderName: moneyLender }}
              defaultConnectedPolicies={connectedPolicies}
              handleNotification={handleNotification}
              Steps={(props: { activeStep: StepNames }) => (
                <Steps
                  technicalProductOptions={mapProductsToOptions(technicalProductsData?.data)}
                  mutationInfo={mutationInfo}
                  {...props}
                />
              )}
            />
          </Wizard>
        </>
      ) : (
        <SpinnerWrapper>
          <CircularProgress size={50} />
        </SpinnerWrapper>
      )}
      {snackbar && (
        <CustomSnackbar
          isOpen={!!snackbar}
          handleClose={() => setSnackbar(undefined)}
          severity={snackbar.status === 'success' ? 'success' : 'error'}
          message={t(`directMutations.snackbar.${snackbar.name}.${snackbar.status}`)}
        />
      )}
    </Box>
  );
};

export default ConversionMutation;
