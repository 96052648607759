import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Icon } from '@mui/material';
import { useGoogleTagManager } from 'use/gtm';
import { useDispatch } from 'react-redux';
import { MutationInfo, LoanInfo } from 'components/MutationDialog/useMutation';
import MutationSelect from 'components/MutationDialog/MutationSelect';
import MutationDialog from './MutationDialog';
import LoadingIndicator from 'components/LoadingIndicator';
import isEnabledForLabel from '../../screens/Mutations/predicates/isEnabledForLabel';
import { setApplicationId } from '../../store/conversionFormReducer';
import { setApplicationId as setApplicationIdOha } from '../../store/ohaFormReducer';
import env from 'config/env';
import { steps } from '../../screens/Mutations/components/DismissalJointLiabilityMutation/constants';

const useStyles = makeStyles((theme) => ({
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
  buttonWrapper: {
    display: 'flex',
  },
  fillSpace: {
    flex: 1,
  },
}));

type MutationPreFlowProps = {
  from?: string;
  loanInfo: LoanInfo;
  loanNumber: string;
  commercialLabel: string;
  onCancelMutate: () => void;
};

const MutationPreFlow = ({
  from,
  loanInfo,
  loanNumber,
  commercialLabel,
  onCancelMutate,
}: MutationPreFlowProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const googleTagManager = useGoogleTagManager();
  const navigate = useNavigate();

  const [selectedMutation, setSelectedMutation] = useState<MutationInfo>();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    googleTagManager({
      event: 'ButtonClick',
      btnName: 'CancelMutation',
    });
  };

  const handleNext = () => {
    if (!selectedMutation) return;
    const { customFormUrl, type } = selectedMutation;

    if (type === 'CustomApplication') {
      window.open(customFormUrl);
      return;
    }
    if (type === 'Conversion' && isEnabledForLabel(type, commercialLabel)) {
      dispatch(setApplicationId(0));
      navigate(`/mutations/conversion/${commercialLabel}/${loanNumber}`, { state: { from } });
    } else if (
      // TODO: refactor into function when feature toggle is removed
      type === 'DismissalJointLiability' &&
      isEnabledForLabel(type, commercialLabel) &&
      env.FEATURES.STAT_458229_OHA_MUTATION
    ) {
      dispatch(setApplicationIdOha(0));
      navigate(
        `/mutaties/oha/${commercialLabel}/${loanNumber}/${steps.currentApplicant.personalInfo}`,
        { state: { from } }
      );
      return;
    }

    if (
      type === 'Raise' &&
      isEnabledForLabel(type, commercialLabel) &&
      env.FEATURES.STAT_423710_RAISE_MUTATION
    ) {
      navigate(`/mutaties/verhogen/${commercialLabel}/${loanNumber}`, { state: { from } });
      return;
    }

    if (
      type === 'ConversionWithRaise' &&
      isEnabledForLabel(type, commercialLabel) &&
      env.FEATURES.STAT_423710_RAISE_MUTATION
    ) {
      navigate(`/mutaties/verhogen-omzetten/${commercialLabel}/${loanNumber}`, { state: { from } });
      return;
    }

    if (
      type === 'CollateralValue' &&
      isEnabledForLabel(type, commercialLabel) &&
      env.FEATURES.STAT_460696_COLLATERAL_VALUE
    ) {
      navigate(`/mutaties/onderpandwaarde/${commercialLabel}/${loanNumber}`, { state: { from } });
      return;
    }

    setOpen(true);
    googleTagManager({
      event: 'ButtonClick',
      btnName: 'mutationFlow',
    });
  };

  const handleClickMutation = (mutation: MutationInfo) => () => {
    setSelectedMutation(mutation);
    googleTagManager({
      event: 'ButtonClick',
      btnName: 'TypeOfMutation',
      typeOfMutation: t(`mutations.typeDescription.${mutation.type}`),
    });
  };

  return (
    <div>
      {loanInfo?.mutations && loanInfo.mutations.length > 0 ? (
        <>
          <MutationSelect
            mutations={loanInfo.mutations}
            commercialLabel={commercialLabel}
            selectedMutation={selectedMutation}
            onClick={handleClickMutation}
          />
          <MutationDialog
            open={open}
            onClose={handleClose}
            loanNumber={loanNumber}
            loanInfo={loanInfo}
            commercialLabel={commercialLabel}
            selectedMutation={selectedMutation}
          />
          <div className={classes.buttonWrapper}>
            <Button
              size="medium"
              color="secondary"
              aria-label="cancel mutate"
              variant="contained"
              onClick={onCancelMutate}
            >
              <Icon>arrow_left</Icon>
            </Button>
            <span className={classes.fillSpace} />
            <Button
              color="primary"
              variant="contained"
              disabled={!selectedMutation}
              size="medium"
              onClick={handleNext}
            >
              {t('mutations.nextStep')}
              <Icon className={classes.buttonIcon}>arrow_right</Icon>
            </Button>
          </div>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
};

export default MutationPreFlow;
