import type { ConnectedPoliciesFormType, LoanDetailsFormType } from 'screens/Mutations/utils/types';
import type { FC, CElement } from 'react';
import type { ConversionMutationApplication } from '__generated__/api';
import type { AxiosResponse } from 'axios';
import type { StepNames } from '../../types';
import { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import TitleBar from 'components/TitleBar';
import BarActionButtons from '../BarActionButtons';
import { useTranslation } from 'react-i18next';
import BottomNavigation from '../BottomNavigation';
import { useWizardStep } from 'screens/Mutations/Wizard/hooks';
import { useSaveMutation, useSubmitMutation } from 'screens/Mutations/hooks/useMutations';
import {
  mapConversionMutationForm,
  mapSubmitConversionMutation,
} from 'screens/Mutations/utils/mappers/conversion/formToAPI/formToApiMapper';
import { isSummaryDisabled as summaryDisabled, findActiveStep } from '../../utils';
import { setApplicationId, setSubmitStatus } from 'store/conversionFormReducer';
import FormTemplate from 'components/Templates/FormTemplate';
import conversionReducer from '../../conversion.schema';
import FormStepper from '../FormStepper';
import { useGoogleTagManager } from 'use/gtm';
import { useClients } from 'screens/Clients/hooks/useClients';

type FormWizardProps = {
  loanDetails: LoanDetailsFormType;
  defaultConnectedPolicies: ConnectedPoliciesFormType;
  handleNotification: (status?: string, name?: string) => void;
  Steps: (props: { activeStep: StepNames }) => CElement<any, any> | null;
};
const FormWizard: FC<FormWizardProps> = ({
  loanDetails,
  defaultConnectedPolicies,
  handleNotification,
  Steps,
}) => {
  const { t } = useTranslation();
  const [isSummaryAccessed, setAccessSummary] = useState(false);
  const { handleNavigateOnCloseForm } = useClients();
  const dispatch = useDispatch();
  const googleTagManager = useGoogleTagManager();
  const { activeStepIndex } = useWizardStep();
  const activeStep: StepNames = findActiveStep(activeStepIndex);
  const [saveAndExitDialog, setSaveAndExitDialog] = useState<boolean>(false);
  const formMethods = useForm<LoanDetailsFormType>({
    defaultValues: {
      ...loanDetails,
      loanParts: loanDetails.loanParts.map((loanPart) => ({
        ...loanPart,
        convertLoan: { ...loanPart.convertLoan },
        splitLoan: loanPart.splitLoan?.map((splitLoan) => ({ ...splitLoan })),
      })),
    },
    resolver: conversionReducer(activeStep),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    watch,
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
  } = formMethods;
  const formState: any = watch();
  const connectedPolicies = formState?.loanPartPolicies
    ? { loanPartPolicies: formState.loanPartPolicies }
    : defaultConnectedPolicies;
  const isSummaryDisabled = summaryDisabled(isValid, isSummaryAccessed);

  const isInterest = watch('conversionQuestionnaire.toggleInterest') === 'yes';
  const isFine = watch('conversionQuestionnaire.toggleFine') === 'yes';
  const isFinancialFine = watch('conversionQuestionnaire.toggleCoFinancedFine') === 'yes';
  const isNextDisabled = isInterest && isFine && isFinancialFine;

  useEffect(() => {
    if (activeStep === 'summary') {
      setAccessSummary(true);
    }
  }, [activeStep]);

  const onSuccessSave = (result: AxiosResponse<ConversionMutationApplication>): void => {
    if (result.data && result.data.application) {
      dispatch(setApplicationId(result.data.application.id!));
      setValue('id', result.data.application.id!);

      googleTagManager({
        event: 'saveForm',
        typeOfMutation: 'Conversion',
        moneylenderName: loanDetails?.moneylenderName,
      });
    }
  };

  const onSuccessSubmit = (): void => {
    dispatch(setSubmitStatus('success'));
    handleNavigateOnCloseForm();
    googleTagManager({
      event: 'submitForm',
      typeOfMutation: 'Conversion',
      moneylenderName: loanDetails?.moneylenderName,
    });
  };

  const { mutateAsync: submitApplication, isLoading: isLoadingSubmitApplication } =
    useSubmitMutation(onSuccessSubmit, () => handleNotification('error', 'submit'));

  const { mutateAsync: saveApplication, isLoading: isLoadingSaveApplication } = useSaveMutation(
    onSuccessSave,
    () => handleNotification('error', 'saveApplication')
  );

  const handleSave = async (isNotification?: boolean): Promise<boolean> => {
    const { status } = await saveApplication(
      mapConversionMutationForm({ loanDetails: formState, connectedPolicies })
    );
    try {
      if (status === 200) {
        isNotification && handleNotification('success', 'saveApplication');
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const handleSaveOnButtonClick = async (isSaveEnabled: boolean): Promise<void> => {
    if (isSaveEnabled) {
      const isSaved = await handleSave(false);
      if (isSaved) {
        setSaveAndExitDialog(false);
        handleNavigateOnCloseForm();
      }
    } else {
      setSaveAndExitDialog(false);
      handleNavigateOnCloseForm();
    }
  };

  const handleClose = () => {
    if (isDirty) {
      setSaveAndExitDialog(true);
    } else {
      handleNavigateOnCloseForm();
    }
  };

  const onSubmit = async () => {
    submitApplication(mapSubmitConversionMutation({ loanDetails: formState, connectedPolicies }));
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTemplate
          header={
            <TitleBar
              onCloseClick={handleClose}
              title={t('directMutations.header.title')}
              moneyLender={loanDetails.moneylenderName}
            >
              <BarActionButtons
                handleSave={handleSave}
                handleNavigate={handleNavigateOnCloseForm}
                handleSaveOnButtonClick={handleSaveOnButtonClick}
                handleNotification={handleNotification}
                handleNavigateOnCloseForm={handleNavigateOnCloseForm}
                isLoadingSaveApplication={isLoadingSaveApplication}
                saveAndExitDialog={saveAndExitDialog}
              />
            </TitleBar>
          }
          sidebar={<FormStepper activeStep={activeStep} />}
          footer={
            <BottomNavigation
              summaryDisabled={isSummaryDisabled}
              isLoadingSubmit={isLoadingSaveApplication || isLoadingSubmitApplication}
              handleSave={handleSave}
              isNextDisabled={isNextDisabled}
            />
          }
        >
          <Steps activeStep={activeStep} />
        </FormTemplate>
      </form>
    </FormProvider>
  );
};
export default FormWizard;
