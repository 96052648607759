import { object, string, number } from 'yup';
import { errorMessages } from 'screens/Mutations/constants';

const costAndResourcesSchemaBase = object().shape({
  consultancyCosts: number().typeError(errorMessages.number).required(errorMessages.required),
  isPrivateIncrease: string().typeError(errorMessages.required).required(errorMessages.required),
  notaryCosts: number()
    .nullable(true)
    .when('isPrivateIncrease', {
      is: (val: any) => val === 'no',
      then: number().typeError(errorMessages.number).required(errorMessages.required),
    }),
  amountOfMortgage: number()
    .nullable(true)
    .when('isPrivateIncrease', {
      is: (val: any) => val === 'no',
      then: number().typeError(errorMessages.number).required(errorMessages.required),
    }),
  addedPersonalEquity: number().typeError(errorMessages.number).required(errorMessages.required),
  nhgCosts: number().typeError(errorMessages.number).required(errorMessages.required),
});
export const costAndResourcesSchema = object({
  financingCostAndResources: costAndResourcesSchemaBase,
});
