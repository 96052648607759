import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import NewsItemHero from '../News/components/NewsItemHero';
import HomeSectionNews from './components/HomeSectionNews';
import HomeSectionNotifications from './components/HomeSectionNotifications';
import HomeHeader from './components/HomeHeader';
import { Container, MainSection, VariousSections } from './Home.styled';
import HomeTilesArea from './components/HomeTilesArea';
import { useQueryMoneyLendersTiles } from 'use/moneyLendersTiles';
import { useUserNotifications } from 'use/userNotifications';
import { checkFirstVisitCookies, checkCookies } from 'utils/global';
import env from 'config/env';
import { setResetClientFilters } from 'store/genericClientListFiltersReducer';
import { useDispatch } from 'react-redux';

const Home = () => {
  const hideNews = localStorage.getItem('EADVISOR_HIDE_NEWS');
  const [showNewsHero, setShowNewsHero] = useState(hideNews !== 'true');
  const { data: moneyLenders, isLoading, isError } = useQueryMoneyLendersTiles();
  const { setFirstVisitTrigger } = useUserNotifications();
  const firstVisitCookie = checkFirstVisitCookies();
  const cookies = checkCookies();
  const dispatch = useDispatch();

  function onCloseNewsHero() {
    localStorage.setItem('EADVISOR_HIDE_NEWS', 'true');
    setShowNewsHero(false);
  }

  useEffect(() => {
    dispatch(setResetClientFilters());
  }, [dispatch]);

  useEffect(() => {
    if (!env.FEATURES.STAT_366304_ONBOARDING) {
      // This makes that the open just trigger after the cookies are handled
      if (cookies == null) return;
      if (!firstVisitCookie) {
        setFirstVisitTrigger(true);
      } else {
        setFirstVisitTrigger(false);
      }
    }
  }, [firstVisitCookie, cookies, setFirstVisitTrigger]);

  useEffect(() => {
    localStorage.removeItem('commercialRelation');
  }, []);

  return (
    <>
      <HomeHeader />
      <Container>
        <Grid container>
          <MainSection item sm={12} lg={9}>
            {showNewsHero && <NewsItemHero onClose={onCloseNewsHero} />}
            <HomeTilesArea moneyLenders={moneyLenders} isLoading={isLoading} isError={isError} />
          </MainSection>

          <VariousSections item sm={12} lg={3}>
            <HomeSectionNotifications />
            <HomeSectionNews />
          </VariousSections>
        </Grid>
      </Container>
    </>
  );
};

export default Home;
