import { ConversionMutationApplication } from '__generated__/api';
import { HEADER_SELECTED_GROUP_CLAIM, http } from '../http';

export interface CollateralData {
  commercialLabel: string;
  loanNumber: string;
  valuationType: string;
  value: string;
  date: string;
  formFile: File;
}

export async function saveMutationApplication(group: string, formData: any) {
  const url = '/api/mutations/save';
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };

  const response = await http({
    url: url,
    method: 'POST',
    headers: headers,
    data: JSON.stringify(formData),
  });
  return response;
}

export async function saveAndValidateMutationApplication(group: string, formData: any) {
  const url = '/api/mutations/save';
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };

  const response = await http({
    url: url,
    method: 'POST',
    headers: headers,
    data: JSON.stringify(formData),
  });
  return response;
}

export async function getMutationApplication(
  id: number,
  group: string
): Promise<ConversionMutationApplication> {
  const url = `/api/mutations/conversion/${id}`;
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const response = await http({
    url: url,
    method: 'GET',
    headers: headers,
  });
  return response.data;
}

export async function submitMutationApplication(group: string, formData: any) {
  const url = '/api/mutations/submit';
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };

  const response = await http({
    url: url,
    method: 'POST',
    headers: headers,
    data: JSON.stringify(formData),
  });
  return response;
}

export async function getTechnicalProductsForConvertLoan(
  group: string | null,
  commercialLabel: string,
  loanNumber: number
) {
  const url = `/api/v1/lenders/${commercialLabel}/loans/${loanNumber}/mutation/convertraiseenum/products`;
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  return http.get(url, {
    headers,
  });
}

export async function submitCollateralApplication(
  collateralData: CollateralData,
  group: string | null
) {
  const formData = new FormData();
  formData.append('commercialLabel', collateralData.commercialLabel);
  formData.append('loanNumber', collateralData.loanNumber);
  formData.append('valuationType', collateralData.valuationType);
  formData.append('value', collateralData.value);
  formData.append('date', collateralData.date);
  formData.append('formFile', collateralData.formFile);

  const response = await http({
    url: '/api/v1/collateral/valuation',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      [HEADER_SELECTED_GROUP_CLAIM]: group,
    },
    data: formData,
  });

  return response;
}
