import { ListOfMoneyLenders } from 'types/FunctionalitiesOverview';
import { Profile } from '__generated__/api';
import sortingArrayPerDisplayName from './sortingArrayPerDisplayName';

function addDisplayNameToObjectList(allCommercialLabeLs: string[], profiles: Profile[]) {
  return allCommercialLabeLs.map((label) => {
    const profile = profiles.find(({ identifier }) => label === identifier);
    return {
      label,
      displayName: profile?.displayName,
    };
  });
}

function sortCommercialLabelsList(allCommercialLabeLs?: string[], profiles?: Profile[]): string[] {
  if (!allCommercialLabeLs) return [];
  if (!profiles) return allCommercialLabeLs;

  const commercialLabeLsDisplayName: ListOfMoneyLenders[] = addDisplayNameToObjectList(
    allCommercialLabeLs,
    profiles
  );
  const labelArray: ListOfMoneyLenders[] = sortingArrayPerDisplayName(commercialLabeLsDisplayName);
  return labelArray.reduce((acc: string[], curr: ListOfMoneyLenders) => [...acc, curr.label], []);
}

export default sortCommercialLabelsList;
