import { styled } from '@mui/styles';
import { Typography, Grid } from '@mui/material';

const TogglesWrapper = styled(Grid)(({
  maxWidth: '630px',
}));

const RaiseMutationTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export { RaiseMutationTypography, TogglesWrapper };
