import { FC, useState, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TitleBar from '../TitleBar';
import BottomNavigation from '../BottomNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  ApplicantFormStepProps,
  EmploymentIncome,
  EntrepreneurIncome,
  Income,
  IncomeFormType,
  OtherIncome,
  SocialSecurityIncome,
} from '../../../../utils/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { saveIncome } from 'store/ohaFormReducer';
import { incomesSchema } from 'screens/Mutations/schemas/income';
import { useOhaFormStep } from 'screens/Mutations/hooks/useOhaFormStep';
import { mapOHAForm } from 'screens/Mutations/utils/mappers/oha/mapFormToApi';
import { formatISO } from 'date-fns';
import IncomeList from 'screens/Mutations/templates/IncomeList';
import FormTitleText from 'components/Form/FormTitle';
import FormTemplate from 'components/Templates/FormTemplate';
import FormStepper from '../FormStepper';
import { Form } from 'components/Molecules/components/Form/Form.styled';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

// TODO: when using Muiv5 datepicker, remove this
const mapEntrepreneurIncome = (income?: EntrepreneurIncome) => {
  if (!income) return undefined;

  const startDate = income.startDate
    ? formatISO(new Date(income.startDate) as Date, { representation: 'date' })
    : null;

  return { ...income, startDate };
};
const mapEmploymentIncome = (income?: EmploymentIncome) => {
  if (!income) return undefined;

  const startDate = income.startDate
    ? formatISO(new Date(income.startDate) as Date, { representation: 'date' })
    : null;
  const endDate = income.endDate
    ? formatISO(new Date(income.endDate) as Date, { representation: 'date' })
    : null;

  return { ...income, startDate, endDate };
};

const mapSocialSecurityIncome = (income?: SocialSecurityIncome) => {
  if (!income) return undefined;

  const startDate = income.startDate
    ? formatISO(new Date(income.startDate) as Date, { representation: 'date' })
    : null;
  const endDate = income.endDate
    ? formatISO(new Date(income.endDate) as Date, { representation: 'date' })
    : null;

  return { ...income, startDate, endDate };
};

const mapOtherIncome = (income?: OtherIncome) => {
  if (!income) return undefined;

  const endDate = income.endDate
    ? formatISO(new Date(income.endDate) as Date, { representation: 'date' })
    : null;

  return { ...income, endDate };
};

const mapPensionIncome = (income?: SocialSecurityIncome) => {
  if (!income) return undefined;

  const startDate = income.startDate
    ? formatISO(new Date(income.startDate) as Date, { representation: 'date' })
    : null;
  const endDate = income.endDate
    ? formatISO(new Date(income.endDate) as Date, { representation: 'date' })
    : null;

  return { ...income, startDate, endDate };
};

const mapIncome = ({
  typeOfIncome,
  entrepreneur,
  socialSecurity,
  pension,
  employment,
  otherIncome,
}: Income) => ({
  typeOfIncome,
  entrepreneur: mapEntrepreneurIncome(entrepreneur),
  socialSecurity: mapSocialSecurityIncome(socialSecurity),
  pension: mapPensionIncome(pension),
  employment: mapEmploymentIncome(employment),
  otherIncome: mapOtherIncome(otherIncome),
});

const IncomeForm: FC<ApplicantFormStepProps> = ({ title, subtitle, rootFormName }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [snackbar, setSnackbar] = useState<{ status: string; name: string }>();
  const ohaFormState = useSelector((state: RootState) => state.ohaForm);
  const { firstNames, insertion, surname } = ohaFormState[rootFormName].personalInfo;
  const fullName = `${firstNames || ''} ${insertion || ''} ${surname || ''}`;

  const rootFormIncome = ohaFormState[rootFormName].income;

  const formMethods = useForm<IncomeFormType>({
    defaultValues: {
      ...rootFormIncome,
      incomes: rootFormIncome.incomes.map(mapIncome),
    },
    resolver: yupResolver(incomesSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = formMethods;

  const [hasIncome, id, isIncome, incomes] = watch(['hasIncome', 'id', 'isIncome', 'incomes']);
  const incomeValues: IncomeFormType = useMemo(
    () => ({
      hasIncome,
      id,
      isIncome,
      incomes,
    }),
    [hasIncome, id, isIncome, incomes]
  );
  const completeForm = {
    ...ohaFormState,
    [rootFormName]: {
      ...ohaFormState[rootFormName],
      personalInfo: { ...ohaFormState[rootFormName].personalInfo },
      contactDetails: { ...ohaFormState[rootFormName].contactDetails },
      obligation: { ...ohaFormState[rootFormName].obligation },
      income: { ...incomeValues },
    },
  };

  const onSaveError = () => handleNotification('error', 'submit');

  const { save, isSaving, goToNextStep } = useOhaFormStep({ onSaveError });

  const onSubmit = async (data: IncomeFormType) => {
    const formData: IncomeFormType = { ...data, incomes: incomeValues.incomes.map(mapIncome) };

    dispatch(saveIncome({ formData, rootFormName }));

    if (isDirty) {
      await save(
        mapOHAForm({
          ...ohaFormState,
          [rootFormName]: { ...ohaFormState[rootFormName], income: formData },
        })
      );
    }
    goToNextStep();
  };

  const dispatchForm = useCallback(() => {
    const formData: IncomeFormType = {
      ...incomeValues,
      incomes: incomeValues.incomes.map(mapIncome),
    };

    dispatch(saveIncome({ formData, rootFormName }));
  }, [rootFormName, incomeValues, dispatch]);

  const handleNotification = (status: string, name: string) => setSnackbar({ status, name });

  const handleOnClose = useCallback(() => setSnackbar(undefined), []);

  return (
    <>
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(onSubmit)} data-testid="income-form">
          <FormTemplate
            header={
              <TitleBar
                onResult={handleNotification}
                formState={completeForm}
                resetForm={reset}
                isFormDirty={isDirty}
                dispatchCurrentForm={dispatchForm}
              />
            }
            sidebar={<FormStepper />}
            footer={
              <BottomNavigation dispatchCurrentForm={dispatchForm} isLoadingSubmit={isSaving} />
            }
          >
            <FormTitleText title={fullName.trim() || t(`${title}`)} description={t(subtitle)} />
            <IncomeList rootFormName={rootFormName} />
          </FormTemplate>
        </Form>
      </FormProvider>
      {snackbar && (
        <CustomSnackbar
          isOpen={!!snackbar}
          handleClose={handleOnClose}
          severity={snackbar.status === 'success' ? 'success' : 'error'}
          message={t(`directMutations.snackbar.${snackbar.name}.${snackbar.status}`)}
        />
      )}
    </>
  );
};

export default IncomeForm;
