import { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useClients } from 'screens/Clients/hooks/useClients';
import { RootState } from 'store';
import { setResultOhaMutation } from 'store/ohaFormReducer';
import { HeaderContainedButton, AddIcon } from '../../Clients.styled';
import { getDateText, getStatusColor } from '../../utils';
import { setSubmitStatus } from 'store/conversionFormReducer';
import { setCollateralValuesSubmitStatus } from 'store/collateralValuesFormReducer';
import { SelectFilter } from 'types/SelectOption';
import HeaderTemplate from 'screens/templates/Lists/ListHeader/HeaderTemplate';
import { useNavigate } from 'react-router';
import { setCreateClientSubmitStatus } from 'store/createClientFormReducer';
import { useExpoints } from 'screens/Clients/hooks/useExpoints';
import CsvRequestButton from './components/CsvRequestButton';
import RefetchButton from './components/RefetchButton';
import { allowAddNewClient } from 'screens/Clients/constants';
import FilterArea from './components/FilterArea';
import { FilterState } from 'types/Client';
import { useQueryProfiles } from 'use/profiles';
import { isLoggedInAsObserver } from 'auth/utils';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

type ClientHeaderProps = {
  handleFilter: (e: SelectFilter) => void;
  isCSV?: boolean;
  filterState: FilterState;
  isLabeled?: boolean;
  filter?: boolean;
};

const ClientsHeader: FC<ClientHeaderProps> = ({
  handleFilter,
  isCSV = true,
  filterState,
  isLabeled = false,
  filter = false,
}) => {
  const { refetch, refetchStatus, clientsQueryResult } = useClients();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isObserver = isLoggedInAsObserver();
  const syncDateText = getDateText(clientsQueryResult?.data?.syncedOnUtc);
  const [snackbar, setSnackbar] = useState<
    { status: string; name: string; message?: string } | undefined
  >();
  const commercialRelation = localStorage.getItem('commercialRelation');
  const { data: profiles } = useQueryProfiles();

  const conversionResult = useSelector((state: RootState) => state.conversionForm.result);
  const collateralValuesResult = useSelector(
    (state: RootState) => state.collateralValuesForm.result
  );
  const createClientResult = useSelector((state: RootState) => state.createClientForm.result);
  const ohaResult = useSelector((state: RootState) => state.ohaForm.result);
  const loanType = filterState.loanType;

  const { callMutationFinishedSurvey } = useExpoints();

  const handleCloseSnackbar = () => {
    setSnackbar(undefined);
  };
  const onRefetch = () => {
    refetch();
  };

  useEffect(() => {
    if (refetchStatus === 'error' || refetchStatus === 'success') {
      setSnackbar({ status: refetchStatus, name: 'refetchClients' });
    } else {
      if (conversionResult) {
        setSnackbar({ status: conversionResult, name: 'submitMutation' });
        dispatch(setSubmitStatus(undefined));
        if (conversionResult === 'success') {
          callMutationFinishedSurvey('conversion');
        }
      }
      if (collateralValuesResult) {
        setSnackbar({ status: collateralValuesResult, name: 'submitCollateralValues' });
        dispatch(setCollateralValuesSubmitStatus(undefined));
      }
      if (createClientResult) {
        setSnackbar({ status: createClientResult, name: 'submitCreateClient' });
        dispatch(setCreateClientSubmitStatus(undefined));
      }
      if (ohaResult) {
        setSnackbar({ status: ohaResult, name: 'submitMutation' });
        dispatch(setResultOhaMutation(undefined));
        if (ohaResult === 'success') {
          callMutationFinishedSurvey('oha');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversionResult, collateralValuesResult, createClientResult, ohaResult, refetchStatus]);

  const addCLient = () => {
    navigate('/clients/create');
  };

  const handleTypeOfLoanSelection = (type: string) => {
    if (handleFilter)
      handleFilter({
        value: type,
        name: 'loanType',
      });
  };
  return (
    <>
      <HeaderTemplate
        profiles={profiles}
        commercialRelation={commercialRelation}
        sectionName={
          commercialRelation
            ? t('general.header.menu.labeledClients')
            : t('general.header.menu.clients')
        }
        filters={
          allowAddNewClient && !isLabeled && !isObserver ? (
            <HeaderContainedButton
              size="small"
              variant="contained"
              disableElevation
              color="primary"
              onClick={addCLient}
              disabled={refetchStatus === 'loading'}
            >
              <AddIcon />
              {t('general.header.menu.addClient')}
            </HeaderContainedButton>
          ) : filter && loanType ? (
            <FilterArea loanType={loanType} handleTypeOfLoanSelection={handleTypeOfLoanSelection} />
          ) : null
        }
        extraButtons={
          <>
            {isCSV && <CsvRequestButton setSnackbar={setSnackbar} />}
            <RefetchButton
              syncDateText={syncDateText}
              onRefetch={onRefetch}
              refetchStatus={refetchStatus}
            />
          </>
        }
      />
      {snackbar ? (
        <CustomSnackbar
          isOpen={!!snackbar}
          handleClose={handleCloseSnackbar}
          verticalPosition="top"
          severity={getStatusColor(snackbar.status)}
          message={
            snackbar.message
              ? snackbar.message
              : t(`clients.header.${snackbar.name}.${snackbar.status}`)
          }
        />
      ) : null}
    </>
  );
};
export default ClientsHeader;
