/* eslint-disable */
import currency from 'currency.js';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { format, formatISO, parseISO, isValid } from 'date-fns';
import { ApplicationData, PersonName, Profile } from '__generated__/api';
import {
  Case,
  CaseDetails,
  CaseApplicantInfo,
  TaskInfo,
  TaskInfoDelegationState,
} from '../__generated__/api-di';
import { ProofItem } from 'types/ProofItem';
import { getProofItemStatus, ProofItemStatus } from './proof-item';
import { nl } from 'date-fns/locale';

export function getProfileName(profiles?: Profile[], commercialLabel?: string | null) {
  if (!profiles) return null;
  const profile = profiles?.find(({ identifier }) => identifier === commercialLabel);
  return profile?.displayName ?? profile?.name;
}

export function IsMobile() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });
}

export function formatTime(date: string) {
  try {
    const parsedDate = parseISO(date);
    return format(parsedDate, 'HH:mm', { locale: nl });
  } catch {
    return ''; // TODO: return something else?
  }
}

export function formatValidateDate(
  date: Date,
  options?: {
    format?: 'extended' | 'basic';
    representation?: 'complete' | 'date' | 'time';
  }
) {
  if (isValid(date)) {
    return formatISO(date, options);
  }
  return date;
}

export function formatLoanNumber(loanNumber: number): string {
  return currency(loanNumber, {
    separator: '.',
    symbol: '',
    precision: 0,
  }).format();
}

export function formatLoanAmount(loanAmount: number): string {
  return currency(loanAmount, {
    separator: '.',
    decimal: ',',
    symbol: '€\u00A0',
    precision: 0,
  }).format();
}

export function getLogo(commercialLabel: string | null | undefined) {
  try {
    const req = require(`../assets/images/moneylenders/${commercialLabel}.png`);
    return req.default ?? req;
  } catch {
    const req = require('../assets/images/logo_small.png');
    return req.default ?? req;
  }
}

export function getDefaultLogo() {
  const req = require('../assets/images/logo_small.png');
  return req.default ?? req;
}

export function getImg(imgName: string | null) {
  try {
    const req = require(`../assets/images/${imgName}`);
    return req.default ?? req;
  } catch {
    const req = require('../assets/images/announcement.png');
    return req.default ?? req;
  }
}

export function getBgImg(BgImgName?: string) {
  try {
    const req = require(`../assets/images/${BgImgName}`);
    return req.default ?? req;
  } catch {
    const req = require('../assets/images/bg.png');
    return req.default ?? req;
  }
}

export function fileSize(size: number | undefined) {
  if (size) {
    return (size / 1024).toFixed(1);
  }
}

export function getLastNotificationsDate() {
  const cookieValue = (document.cookie.match(
    /^(?:.*;)?\s*e-adviseur-notifications-date\s*=\s*([^;]+)(?:.*)?$/
  ) || ['', null])[1];
  return cookieValue;
}

export function storeLastNotificationsDate(date: string) {
  const expire = new Date();
  expire.setFullYear(expire.getFullYear() + 1);
  document.cookie = `e-adviseur-notifications-date=${date};expires=${expire.toUTCString()};path=/`;
}

export function checkCookies() {
  const cookieValue = (document.cookie.match(
    /^(?:.*;)?\s*e-adviseur-cookies\s*=\s*([^;]+)(?:.*)?$/
  ) || ['', null])[1];
  return cookieValue === '1' ? true : cookieValue === '0' ? false : null;
}

export function checkFirstVisitCookies() {
  const cookieValue = (document.cookie.match(
    /^(?:.*;)?\s*e-adviseur-firstVisit-cookies\s*=\s*([^;]+)(?:.*)?$/
  ) || ['', null])[1];
  return cookieValue;
}

export function countCompletedTasks(tasks: Array<TaskInfo>) {
  return tasks.filter((t) => t.delegationState === TaskInfoDelegationState.Completed).length;
}

export function isTaskEnded(task: TaskInfo) {
  return (
    task.delegationState !== TaskInfoDelegationState.Pending &&
    task.delegationState !== TaskInfoDelegationState.InProgress
  );
}

export function getUncompletedTasks(tasks: Array<TaskInfo>) {
  return tasks.filter((t) => !isTaskEnded(t));
}

export const getCustomerNames = (application: Case | CaseDetails | undefined) => {
  if (
    application == null ||
    application === undefined ||
    !application.applicants ||
    application.applicants.length === 0
  ) {
    return '';
  }

  return (
    combineNameParts(application.applicants[0]) +
    (application.applicants.length > 1 ? ` & ${combineNameParts(application.applicants[1])}` : '')
  );
};

function combineNameParts(person: CaseApplicantInfo) {
  const parts = [person.initials, person.prefix, person.name];
  const name = parts.filter((part) => !!part).join(' ');
  return name;
}

export const getCustomerNamesApplicationData = (application: ApplicationData | undefined) => {
  if (
    application == null ||
    application === undefined ||
    !application.person ||
    application.person.length === 0
  ) {
    return '';
  }

  return (
    combineNamePartsPersonName(application.person[0]) +
    (application.person.length > 1 ? ` & ${combineNamePartsPersonName(application.person[1])}` : '')
  );
};

function combineNamePartsPersonName(person: PersonName) {
  const parts = [person.initials, person.prefix, person.name];
  const name = parts.filter((part) => !!part).join(' ');
  return name;
}

export function setCookies(enabled: boolean) {
  const value = enabled ? '1' : '0';
  const expire = new Date();
  expire.setFullYear(expire.getFullYear() + 1);
  document.cookie = `e-adviseur-cookies=${value};expires=${expire.toUTCString()};path=/`;
}

export function setFirstVisitCookies() {
  const expire = new Date();
  expire.setFullYear(expire.getFullYear() + 1);
  document.cookie = `e-adviseur-firstVisit-cookies=1;expires=${expire.toUTCString()};path=/`;
}

type EnumType = { [s: string]: string };

export function mapEnum(enumerable: EnumType): string[] {
  // get all the members of the enum
  const enumMembers: string[] = Object.keys(enumerable).map((key) => enumerable[key]);
  return enumMembers;
}

export function nullableEnum(enumerable: any[]) {
  const nullableEnumerable: any[] = Object.values(enumerable);
  nullableEnumerable.push(null);
  return nullableEnumerable;
}

export function hasValue(any: any) {
  return any !== undefined && any !== null;
}

export function getAmountOrZero(value: number | null | undefined) {
  if (value === null || !value || isNaN(value)) {
    return 0;
  }
  return value;
}

export function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

export function base64ToArrayBuffer(base64: string) {
  const binary_string = atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export function readFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
      const b64 = (reader.result as string).replace(/^data:.+;base64,/, '');
      resolve(b64);
    };

    reader.onerror = function () {
      reject();
    };
    reader.readAsDataURL(file);
  });
}

const delegationStateSortMap = new Map<TaskInfoDelegationState | undefined, number>();
delegationStateSortMap.set(TaskInfoDelegationState.InProgress, 1);
delegationStateSortMap.set(TaskInfoDelegationState.Pending, 2);
delegationStateSortMap.set(TaskInfoDelegationState.Completed, 3);
delegationStateSortMap.set(TaskInfoDelegationState.Revoked, 4);
delegationStateSortMap.set(undefined, 99);

export function sortTaskPredicate(a: TaskInfo, b: TaskInfo) {
  if (
    delegationStateSortMap.get(a.delegationState)! < delegationStateSortMap.get(b.delegationState)!
  ) {
    return -1;
  }

  if (
    delegationStateSortMap.get(a.delegationState)! > delegationStateSortMap.get(b.delegationState)!
  ) {
    return 1;
  }

  return 0;
}
