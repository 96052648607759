import { FC } from 'react';
import { formatDuration } from 'utils/formatDuration';

export type DurationLabelProps = {
  months?: number;
};

export const DurationLabel: FC<DurationLabelProps> = ({ months }) => {
  if (months === undefined) {
    return <>-</>;
  }

  return <>{formatDuration(months)}</>;
};

export default DurationLabel;
