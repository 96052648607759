import { number, object, string } from 'yup';
import { errorMessages, getTypeOfBenefitOptions } from 'screens/Mutations/constants';
import { defaultDate, endDate } from 'utils/form/validations';
import isNil from 'utils/predicates/isNil';

const benefitValues = getTypeOfBenefitOptions().map((f) => f.value);

export const socialSecuritySchema = object({
  typeOfBenefit: string().oneOf(benefitValues).required(errorMessages.required),
  startDate: defaultDate.when('typeOfBenefit', {
    is: (v: any) => !isNil(v),
    then: defaultDate.isPastDate(errorMessages.PastDate).required(errorMessages.required),
  }),
  endDate,
  incomeAmount: number().when('typeOfBenefit', {
    is: (v: any) => !isNil(v),
    then: number().nullable(true).required(errorMessages.required),
  }),
});
