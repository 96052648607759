import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const iconImage = require('../../assets/images/proof-item/cancelled.svg');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 280,
    borderRadius: 8,
    border: 'solid 1px #EBEAEE',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  noBorder: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 280,
  },
  icon: {
    width: 72,
    height: 72,
    backgroundColor: theme.palette.grey[200],
    borderRadius: '50%',
    display: 'flex',
    backgroundImage: `url("${iconImage.default ?? iconImage}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    marginBottom: theme.spacing(2),
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 'bolder',
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
}));

type PlaceholderEmptyListProps = {
  title?: string | null;
  subtitle?: string | null;
  border?: boolean | null;
  children?: React.ReactElement;
};

const PlaceholderEmptyList: React.FC<PlaceholderEmptyListProps> = (props) => {
  const { title, subtitle, border, children } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      className={border === false ? classes.noBorder : classes.root}
      data-testid="placeholder-empty-list"
    >
      <div className={classes.icon} />
      <Typography className={classes.title} variant="subtitle1">
        {title || t('general.nothingToSeeHere')}
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle2">
        {subtitle}
      </Typography>
      {children}
    </div>
  );
};

export default PlaceholderEmptyList;
