import { FormLabel, FormHelperText } from '@mui/material';
import { useCallback } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { NumberFormatValues, NumericFormatProps, NumericFormat } from 'react-number-format';
import { InputWrapper, TextInput } from '../Forms.styled';

export interface FormInputNumberProps extends NumericFormatProps {
  placeholder?: string;
  required?: boolean;
  label: string;
  testId?: string;
  shouldUnregister?: boolean;
  name: string;
  handleIsAllowed: (values: NumberFormatValues) => boolean;
  getValueBy?: 'string' | 'float';
}

export default function NumberInputsTemplate({
  name,
  label,
  placeholder,
  required,
  defaultValue,
  disabled,
  prefix,
  suffix,
  decimalScale = 2,
  shouldUnregister = false,
  fixedDecimalScale = false,
  thousandSeparator = false,
  testId,
  handleIsAllowed,
  type,
  getValueBy,
}: FormInputNumberProps) {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    shouldUnregister,
  });

  const returnValidValue = (value: number | undefined) => {
    if (value !== undefined) return value;
    if (defaultValue !== undefined) return defaultValue;
    return '';
  };

  const handleChange = useCallback(
    (values: NumberFormatValues) => {
      if (getValueBy === 'string') {
        onChange(values?.value ?? null);
      } else {
        onChange(values?.floatValue ?? null);
      }
    },
    [getValueBy, onChange]
  );

  return (
    <InputWrapper data-testid={`${testId || name}-input-wrapper`}>
      <FormLabel data-testid={`${testId || name}-input-label`} required={required}>
        {label}
      </FormLabel>
      <NumericFormat
        allowLeadingZeros
        type={type}
        customInput={TextInput}
        inputProps={{
          'data-testid': testId ?? name,
        }}
        variant="outlined"
        size="small"
        decimalSeparator=","
        thousandSeparator={thousandSeparator ? '.' : ''}
        allowedDecimalSeparators={['.']}
        prefix={prefix}
        suffix={suffix}
        isAllowed={handleIsAllowed}
        onValueChange={handleChange}
        value={returnValidValue(value)}
        error={!!error}
        defaultValue={defaultValue}
        decimalScale={decimalScale}
        fixedDecimalScale={fixedDecimalScale}
        placeholder={placeholder}
        disabled={disabled}
      />
      <FormHelperText data-testid="error-message-input" error={!!error}>
        {error?.message}
      </FormHelperText>
    </InputWrapper>
  );
}
