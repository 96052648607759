import { SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { NewsItem } from '__generated__/api';

export function useSearch(items: NewsItem[] = []) {
  const [filterState, setFilterState] = useState({
    category: ['all'],
  });

  const [searchedTitleItems, setSearchedTitleItems] = useState(items);
  const [searchedTextItems, setSearchedTextItems] = useState(items);

  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (e: any) => setSearchTerm(e.currentTarget.value);
  const emptySearch = () => setSearchTerm('');

  const handleMultiSelectChange = (e: SelectChangeEvent<string[]>) => {
    const { name, value } = e.target;
    const newValues =
      value[value.length - 1] === 'all' || value.length === 0
        ? ['all']
        : (value as string[]).filter((item) => item !== 'all');
    setFilterState({ ...filterState, [name]: newValues });
  };

  useEffect(() => {
    setSearchedTitleItems(
      items
        .filter((item) => {
          if (!searchTerm) {
            return true;
          }
          return matchesSearchTerms(item.title, searchTerm);
        })
        .filter((item) => {
          if (filterState.category.includes('all')) {
            return true;
          }
          return filterState.category.indexOf(item.category!) !== -1;
        })
    );
  }, [searchTerm, filterState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSearchedTextItems(
      items
        .filter((item) => {
          if (!searchTerm) {
            return true;
          }
          return matchesSearchTerms(item.htmlContent!.toString(), searchTerm);
        })
        .filter((item) => {
          if (filterState.category.includes('all')) {
            return true;
          }
          return filterState.category.indexOf(item.category!) !== -1;
        })
    );
  }, [searchTerm, filterState]); // eslint-disable-line react-hooks/exhaustive-deps

  const matchesSearchTerms = (item: any, searchTerm: string) => {
    if (searchTerm.trim() === '') {
      return false;
    }

    const searchTerms = searchTerm.trim().toLowerCase().split(' ');

    if (searchTerms.length === 0) {
      return false;
    }

    const i = item?.toLowerCase();

    for (const word of searchTerms) {
      if (!foundWord(i, word)) {
        return false;
      }
    }
    return true;
  };

  const foundWord = (item: string, word: string) => {
    if (item?.includes(word)) {
      return true;
    }

    return false;
  };

  return {
    searchTerm,
    searchedTitleItems,
    searchedTextItems,
    filterState,
    setSearchTerm,
    handleSearch,
    handleMultiSelectChange,
    emptySearch,
  };
}
