import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StateProps = {
  result?: string;
};
const initialState: StateProps = {
  result: undefined,
};
const rootSlice = createSlice({
  name: 'collateralValuesForm',
  initialState,
  reducers: {
    setCollateralValuesSubmitStatus: (state, action: PayloadAction<string | undefined>) => {
      state.result = action.payload;
    },
  },
});

export default rootSlice.reducer;

export const { setCollateralValuesSubmitStatus } = rootSlice.actions;
