import { hasPolicy } from 'screens/Mutations/components/DismissalJointLiabilityMutation/utils';
import {
  steps,
  stepsOrder,
  stepsOrderNoNewApplicant,
} from '../../screens/Mutations/components/DismissalJointLiabilityMutation/constants';
import store from '../../store';

export const goToStep = (step: string, navigate: any, location: string) => {
  const loc = location.split('/');
  loc.pop();
  const root = loc.join('/');
  navigate(`${root}/${step}`);
};

export const goToPreviousStep = (history: any, location: string, currentStep?: string) => {
  const { ohaForm } = store.getState();
  const hasNewApplicant = ohaForm.newApplicant.personalInfo.hasNewApplicant;

  // Sets different order if there is a new Applicant, so the function knows what the next step is
  const orderOfSteps = hasNewApplicant === 'yes' ? stepsOrder : stepsOrderNoNewApplicant;

  if (currentStep) {
    const currentStepIndex = orderOfSteps.indexOf(currentStep);
    if (currentStepIndex !== 0 && currentStepIndex !== -1) {
      if (orderOfSteps[currentStepIndex] === steps.additionalInfo && !hasPolicy(ohaForm.loan)) {
        // if there is no connected policy, skip the policy step
        goToStep(orderOfSteps[currentStepIndex - 2], history, location);
      } else {
        goToStep(orderOfSteps[currentStepIndex - 1], history, location);
      }
    }
  }
};

export const goToNextStep = (history: any, location: string, currentStep?: string) => {
  const { ohaForm } = store.getState();
  const hasNewApplicant = ohaForm.newApplicant.personalInfo.hasNewApplicant;

  // Sets different order if there is a new Applicant, so the function knows what the next step is
  const orderOfSteps = hasNewApplicant === 'yes' ? stepsOrder : stepsOrderNoNewApplicant;

  if (currentStep) {
    const currentStepIndex = orderOfSteps.indexOf(currentStep);
    if (currentStepIndex !== orderOfSteps.length - 1 && currentStepIndex !== -1) {
      // if there is no connected policy, skip the policy step
      if (orderOfSteps[currentStepIndex] === steps.financingLoan && !hasPolicy(ohaForm.loan)) {
        goToStep(orderOfSteps[currentStepIndex + 2], history, location);
      } else {
        goToStep(orderOfSteps[currentStepIndex + 1], history, location);
      }
    }
  }
};
