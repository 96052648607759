import { useEffect } from 'react';

// useUnload takes care of the onbeforeunload event.
// In case there are unsaved changes and the user navigates away,
// he is asked for verification to leave.

const useUnload = (fn: any) => {
  useEffect(() => {
    window.addEventListener('beforeunload', fn);

    return () => window.removeEventListener('beforeunload', fn);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useUnload;
