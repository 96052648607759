import { mapEnum, nullableEnum } from 'utils/global';
import { TypeOfCollateral, TypeOfLeasehold } from '__generated__/api';
import { maximumNumber } from '../constants';
import { allowedTypesOfRealEstate, allowedEnergyClasses } from '../enums/realEstateEnums';
import { schemaDraft07 } from './constants';

const schema = {
  $schema: schemaDraft07,
  $id: 'https://e-adviseur.stater.com/buytolet/real-estate-newlybuild',
  title: 'Real estate',
  type: 'object',
  properties: {
    typeOfCollateral: {
      enum: mapEnum(TypeOfCollateral),
    },
    typeOfRealEstate: {
      enum: allowedTypesOfRealEstate,
    },
    energyClass: {
      enum: nullableEnum(allowedEnergyClasses),
    },
    street: {
      type: ['string', 'null'],
      minLength: 1,
      maxLength: 100,
    },
    houseNumber: {
      type: ['integer', 'null'],
      minimum: 0,
      maximum: 99999,
    },
    houseNumberExtension: {
      type: ['string', 'null'],
      maxLength: 10,
    },
    postalCode: {
      type: ['string', 'null'],
      minLength: 0,
      maxLength: 6,
      pattern: '^([1-9][0-9]{3}(?!sa|sd|ss)[a-zA-Z]{2}|.{0})$',
    },
    city: {
      type: 'string',
      minLength: 1,
      maxLength: 35,
    },
    buildingPlanNumber: {
      type: ['string', 'null'],
      minLength: 0,
      maxLength: 35,
    },
    situationNumber: {
      type: ['string', 'null'],
      minLength: 0,
      maxLength: 35,
    },
    deliveryDate: {
      type: 'string',
      format: 'date',
    },
    purchaseAmount: {
      type: 'number',
      maximum: maximumNumber,
    },
    isGroundInPossession: {
      type: 'boolean',
    },
    purchasePriceGround: {
      type: ['number', 'null'],
      maximum: maximumNumber,
    },
    additionalWorkAmount: {
      type: ['number', 'null'],
      maximum: maximumNumber,
    },
    hasLeasehold: {
      type: 'boolean',
    },
    typeOfLeasehold: {
      type: ['string', 'null'],
      enum: [...mapEnum(TypeOfLeasehold), null],
    },
    leaseholdAmount: {
      type: ['number', 'null'],
      minimum: 0,
      maximum: maximumNumber,
    },
    nameOfTenant: {
      type: ['string', 'null'],
      minLength: 0,
      maxLength: 100,
    },
    basicAnnualRentalIncome: {
      type: ['number', 'null'],
      maximum: maximumNumber,
    },
    rentalValue: {
      type: 'number',
      maximum: maximumNumber,
    },
    marketValue: {
      type: 'number',
      maximum: maximumNumber,
    },
    isRoomRental: {
      type: 'boolean',
    },
  },
  allOf: [
    {
      if: {
        properties: {
          isGroundInPossession: {
            const: true,
          },
        },
      },
      then: {
        required: ['purchasePriceGround'],
      },
    },
    {
      if: {
        properties: {
          hasLeasehold: {
            const: true,
          },
        },
      },
      then: {
        required: ['typeOfLeasehold'],
      },
    },
    {
      if: {
        properties: {
          isNewlyBuildAddressKnown: {
            const: 'yes',
          },
        },
      },
      then: {
        properties: {
          street: {
            type: 'string',
            minLength: 1,
          },
          houseNumber: {
            type: 'integer',
            min: 1,
          },
          postalCode: {
            type: 'string',
            minLength: 1,
          },
        },
        required: ['street', 'houseNumber', 'postalCode'],
      },
    },
    {
      if: {
        properties: {
          isNewlyBuildAddressKnown: {
            const: 'no',
          },
        },
      },
      then: {
        properties: {
          buildingPlanNumber: {
            type: 'string',
          },
          situationNumber: {
            type: 'string',
          },
        },
        required: ['buildingPlanNumber', 'situationNumber'],
      },
    },
  ],
  required: [
    'deliveryDate',
    'purchaseAmount',
    'isGroundInPossession',
    'typeOfCollateral',
    'typeOfRealEstate',
    'city',
    'hasLeasehold',
    'rentalValue',
    'marketValue',
    'isRoomRental',
  ],
};

export default schema;
