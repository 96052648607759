import { CardSection, CardRow } from 'components/SummaryCard';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getMaritalStatusValue, getGenderStatusValue } from 'screens/Mutations/utils';
import { BasePersonalInfo } from 'screens/Mutations/utils/types';
import { format } from 'date-fns';

type Props = {
  personalInfo: BasePersonalInfo;
};
const PersonalInfoSummary: FC<Props> = ({ personalInfo }) => {
  const { t } = useTranslation();
  const { initials, insertion, firstNames, surname, dateOfBirth, gender, maritalStatus } =
    personalInfo;
  const birthDate = dateOfBirth as Date | number;
  return (
    <CardSection>
      <CardRow name={t('directMutations.personalInfo.initials')} value={initials} />
      <CardRow name={t('directMutations.personalInfo.firstNames')} value={firstNames} />
      <CardRow name={t('directMutations.personalInfo.insertion')} value={insertion} />
      <CardRow name={t('directMutations.personalInfo.surname')} value={surname} />
      <CardRow
        name={t('directMutations.personalInfo.dateOfBirth')}
        value={birthDate ? format(birthDate, 'dd-MM-yyyy') : ''}
      />
      <CardRow
        name={t('directMutations.personalInfo.gender')}
        value={getGenderStatusValue(gender)}
      />
      <CardRow
        name={t('directMutations.personalInfo.conversionRaise.maritalStatus')}
        value={getMaritalStatusValue(maritalStatus)}
      />
    </CardSection>
  );
};
export default PersonalInfoSummary;
