import React from 'react';
import { NumericFormat } from 'react-number-format';

type NumberFormatCustomProps = {
  inputRef?: (instance: any | null) => void;
  name?: string;
  value?: number | string;
};

const CurrencyFormat = (props: NumberFormatCustomProps) => {
  const { inputRef, value, ...other } = props;
  if (value) {
    return (
      <NumericFormat
        thousandSeparator="."
        decimalSeparator=","
        valueIsNumericString
        prefix="€ "
        value={value}
        displayType="text"
        fixedDecimalScale
        renderText={(value) => <span>{value}</span>}
      />
    );
  }
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator="."
      decimalSeparator=","
      valueIsNumericString
      fixedDecimalScale
      prefix="€ "
    />
  );
};

export default CurrencyFormat;
