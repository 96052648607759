import { FC } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generalEnum } from '../../../utils/generalEnum';
import TemplateDetails from './TemplateDetails';
import { DetailsRow } from 'lib/ClientDataDetails/Fields';
import { ClientDetailsDepot } from 'types/ClientDetailsModel';

type ConstructionDepotDetailsProps = {
  depots: ClientDetailsDepot[];
};

const ConstructionDepotDetails: FC<ConstructionDepotDetailsProps> = ({ depots }) => {
  const { t } = useTranslation();
  const translationsRoot = 'clients.clientData.constructionDepot';

  if (!depots.length) {
    return null;
  }

  return (
    <Grid container data-testid="construction-depot-container">
      <Grid xs={12} md={6} item px={3} py={2}>
        {depots.map((depot, index) => (
          <TemplateDetails
            testid="construction-depot"
            title={t(`${translationsRoot}.constructionDepotHead`)}
            key={index}
          >
            <DetailsRow
              label={t(`${translationsRoot}.type`)}
              value={generalEnum('depotType', depot.type)}
            />
            <DetailsRow
              type="currency"
              label={t(`${translationsRoot}.amount`)}
              value={depot.amount}
            />
            <DetailsRow
              type="percentage"
              label={t(`${translationsRoot}.interestRate`)}
              value={depot.interestRate}
            />
            <DetailsRow
              type="date"
              label={t(`${translationsRoot}.endDate`)}
              value={depot.endDate}
            />
          </TemplateDetails>
        ))}
      </Grid>
    </Grid>
  );
};

export default ConstructionDepotDetails;
