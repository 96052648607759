import isNil from './isNil';
import isObject from './isObject';

/**
 * see: lodash isEmpty
 *
 * Checks if `value` is an empty object, collection..
 *
 * Objects are considered empty if they have no own enumerable string keyed
 * properties.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is empty, else `false`.
 * @example
 *
 * isEmpty(null)
 * // => true
 *
 * isEmpty([1, 2, 3])
 * // => false
 *
 * isEmpty('abc')
 * // => false
 *
 * isEmpty({ 'a': 1 })
 * // => false
 */
const isEmpty = (value?: any): value is null | undefined => {
  if (Array.isArray(value)) return !value || value.length === 0;

  if (isObject(value)) return Object.keys(value).length === 0;

  return isNil(value) || !value.length;
};

export default isEmpty;
