import { Fragment, useCallback } from 'react';
import { Container } from '@mui/material';
import FormTitleText from 'components/Form/FormTitle';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SubTitle } from 'screens/Mutations/molecules/styled';
import { RaiseForm } from '../types';
import { SummaryCard } from 'components/SummaryCard';
import { ContactDetailsSummary, PersonalInfoSummary } from './ApplicantSummary';
import { useWizardStep } from 'hooks/useWizard';

export default function Summary() {
  const { t } = useTranslation();
  const { getValues } = useFormContext<RaiseForm>();
  const formValues = getValues();
  const { goToStep } = useWizardStep();
  const STEPS_PER_APPLICANT = 4;

  const handleEdit = useCallback(
    (index: number, subformIndex: number) => () =>
      goToStep(STEPS_PER_APPLICANT * index + subformIndex),
    [goToStep]
  );

  return (
    <Container data-testid="summary-wrapper">
      <FormTitleText title={t('directMutations.summary.title')} />
      {formValues?.applicants.map((applicant, index) => (
        <Fragment key={`${applicant.personalInfo.surname}-${index}`}>
          <SubTitle variant="h5">
            {`${applicant?.personalInfo?.firstNames ?? ''} ${
              applicant?.personalInfo?.insertion ?? ''
            } ${applicant?.personalInfo?.surname ?? ''}`}
          </SubTitle>

          <SummaryCard
            title={t('directMutations.personalInfo.formTitle')}
            onEditClick={handleEdit(index, 1)}
          >
            <PersonalInfoSummary personalInfo={applicant.personalInfo} />
          </SummaryCard>

          <SummaryCard
            title={t('directMutations.contactDetails.formTitle')}
            onEditClick={handleEdit(index, 2)}
          >
            <ContactDetailsSummary contactDetails={applicant.contactDetails} />
          </SummaryCard>
        </Fragment>
      ))}
    </Container>
  );
}
