import { MutationType } from '../utils/types';

export const getAllowedCommercialLabels = (type: MutationType): string[] => {
  switch (type) {
    case 'Conversion':
      return (window as any).REACT_APP_CONVERSION_MUTATION_ALLOWED_FOR_COMMERCIAL_LABELS ?? [];
    case 'Raise':
      return (window as any).REACT_APP_RAISE_MUTATION_ALLOWED_FOR_COMMERCIAL_LABELS ?? [];
    case 'DismissalJointLiability':
      return (
        (window as any)
          .REACT_APP_DISMISSAL_JOINT_LIABILITY_MUTATION_ALLOWED_FOR_COMMERCIAL_LABELS ?? []
      );
    case 'CollateralValue':
      return (window as any).REACT_APP_MUTATE_COLLATERAL_VALUE_FOR_COMMERCIAL_LABELS ?? [];

    case 'ConversionWithRaise':
      return (
        (window as any).REACT_APP_CONVERSION_RAISE_MUTATION_ALLOWED_FOR_COMMERCIAL_LABELS ?? []
      );
    default:
      return [];
  }
};
export const hasAllowedLabel = (labels: string[], label: string): boolean =>
  labels.some((commercialLabel) => commercialLabel === label);

export default function isEnabledForLabel(type: MutationType, label: string): boolean {
  const allowedCommercialLabels = getAllowedCommercialLabels(type);
  return hasAllowedLabel(allowedCommercialLabels, label);
}
