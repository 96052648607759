import { Env } from 'config/env';
import { LoginType } from '../types';

const getLoginType = (env: Env, isLoggedInAsObserver = false): LoginType => {
  if (isLoggedInAsObserver) return 'keycloak-observer';
  if (env.FEATURES?.STAT_430611_DUMMY_LOGIN) return 'keycloak-dummy';
  return 'keycloak';
};

export default getLoginType;
