import { FeatureOverview } from 'types/FunctionalitiesOverview';
import { http } from '../http';

export const getFunctionalitiesOverview = async (): Promise<FeatureOverview> => {
  const response = await http({
    url: '/api/v1/functionalitiesoverview',
    method: 'GET',
  });

  return response.data;
};
