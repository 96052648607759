import { maximumNumber } from '../constants';

const schemaPart = {
  numberOfRentalCollateralsOwned: {
    type: ['integer', 'null'],
    minimum: 0,
    maximum: 99999,
  },
  totalValueOfRentalCollateralsOwned: {
    type: ['number', 'null'],
    minimum: 0,
    maximum: maximumNumber,
  },
};

export default schemaPart;
