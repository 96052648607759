import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  getTypeOfCompanyOptions,
  getLegalFormOptions,
  yesNoGroupOptions,
} from 'screens/Mutations/constants';
import {
  FormInputCurrency,
  FormInputDate,
  FormInputDropdown,
  FormToggleButtonGroup,
} from 'components/Form';

type Props = {
  index?: number;
  rootFormName?: string;
};

const Entrepreneur: FC<Props> = ({ index = 0, rootFormName }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const formIncomes = useWatch({ control, name: `${rootFormName || ''}incomes` });

  const income = formIncomes && formIncomes.length ? formIncomes[index] : null;

  const typeOfCompany = income && income.entrepreneur?.typeOfCompany;
  const legalForm = income && income.entrepreneur?.legalForm;
  const hasIncomeStatement = income && income.entrepreneur?.hasIncomeStatement;

  const prefix = `${rootFormName || ''}incomes.${index}.entrepreneur`;
  const testPrefix = `incomes.${index}`;

  return (
    <div data-testid="entrepreneur-fieldset">
      <FormInputDropdown
        name={`${prefix}.typeOfCompany`}
        label={t('directMutations.income.entrepreneur.typeOfCompany')}
        options={getTypeOfCompanyOptions()}
        placeholder={t('directMutations.income.entrepreneur.chooseTypeOfCompany')}
        required
        testId={`${testPrefix}.typeOfCompany`}
      />
      {typeOfCompany && (
        <>
          <FormInputDropdown
            name={`${prefix}.legalForm`}
            label={t('directMutations.income.entrepreneur.typeOfLegalForm')}
            options={getLegalFormOptions()}
            placeholder={t('directMutations.income.entrepreneur.chooseTypeOfLegalForm')}
            required
            testId={`${testPrefix}.legalForm`}
          />

          {legalForm && (
            <>
              <FormInputDate
                name={`${prefix}.startDate`}
                label={t('directMutations.income.entrepreneur.startDate')}
                required
                maxDate={new Date()}
                testId={`${testPrefix}.startDate`}
              />
              <FormToggleButtonGroup
                name={`${prefix}.hasIncomeStatement`}
                label={t('directMutations.income.entrepreneur.hasIncomeStatement')}
                options={yesNoGroupOptions}
                required
                testId={`${testPrefix}.hasIncomeStatement`}
              />
              {hasIncomeStatement === 'yes' && (
                <FormInputCurrency
                  name={`${prefix}.incomeAmount`}
                  label={t('directMutations.income.entrepreneur.testIncome')}
                  required
                  testId={`${testPrefix}.incomeAmount`}
                />
              )}
              {hasIncomeStatement === 'no' && (
                <>
                  <FormInputCurrency
                    name={`${prefix}.incomePreviousFirstYear`}
                    label={t('directMutations.income.entrepreneur.incomePreviousFirstYear')}
                    required
                    min={0}
                    testId={`${testPrefix}.incomePreviousFirstYear`}
                  />
                  <FormInputCurrency
                    name={`${prefix}.incomePreviousSecondYear`}
                    label={t('directMutations.income.entrepreneur.incomePreviousSecondYear')}
                    required
                    min={0}
                    testId={`${testPrefix}.incomePreviousSecondYear`}
                  />
                  <FormInputCurrency
                    name={`${prefix}.incomePreviousThirdYear`}
                    label={t('directMutations.income.entrepreneur.incomePreviousThirdYear')}
                    required
                    min={0}
                    testId={`${testPrefix}.incomePreviousThirdYear`}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Entrepreneur;
