import { CommercialLabelUploadSettings } from 'types/CommercialLabelUploadSettings';
import { Case, CaseDetails } from '__generated__/api-di';

export type Status =
  | 'waiting'
  | 'pendingApproval'
  | 'approved'
  | 'rejected'
  | 'turnedDown'
  | 'cancelled';

export const ProofItemStatus = Object.freeze<Record<string, Status>>({
  waiting: 'waiting',
  pendingApproval: 'pendingApproval',
  approved: 'approved',
  rejected: 'rejected',
  turnedDown: 'turnedDown',
  cancelled: 'cancelled',
});

export function getProofItemStatus(status?: string): Status {
  switch (status) {
    case 'ToBeConfirmed':
    case 'Confirmed':
    case 'ToBeReceived':
      return ProofItemStatus.waiting;
    case 'ReceivedForApproval':
    case 'WaitForDocuments':
      return ProofItemStatus.pendingApproval;
    case 'ApprovedAndProccessed':
    case 'ApprovedAndProcessingPending':
    case 'Approved':
      return ProofItemStatus.approved;
    case 'ToBeReceivedAfterRejection':
      return ProofItemStatus.rejected;
    case 'Rejected':
      return ProofItemStatus.turnedDown;
    case 'Inactive':
    default:
      return ProofItemStatus.cancelled;
  }
}

const allowedCommercialLabelUploadSettings: CommercialLabelUploadSettings[] =
  (window as any).REACT_APP_UPLOAD_ALLOWED_FOR_COMMERCIAL_LABELS ?? [];

const getCommercialLabelUploadSettings = (label?: string) =>
  label
    ? allowedCommercialLabelUploadSettings.find(
        (commercialLabel) => commercialLabel.commercialLabel === label
      )
    : undefined;

export const isUploadAllowed = (application: Case) =>
  !!getCommercialLabelUploadSettings(application.lender?.label);

export function isUploadAllowedForAssignedTeamType($case: Case, applicationDetails?: CaseDetails) {
  const commercialLabel = getCommercialLabelUploadSettings($case.lender?.label);

  if (!commercialLabel || !applicationDetails) return false;

  if (commercialLabel.assignedTeamTypes.length === 0) {
    return applicationDetails.application?.assignedTeamType == null;
  }

  return commercialLabel.assignedTeamTypes.some(
    (a) => applicationDetails.application?.assignedTeamType === a
  );
}
