import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    marginRight: theme.spacing(1),
    fontWeight: 'bolder',
    [theme.breakpoints.down('md')]: {
      flexGrow: 1,
    },
  },
  emphasize: {
    fontWeight: 'bold',
    fontSize: '10px',
  },
}));

interface SolutionTaskListIssueGroupHeadingProps {
  description: string;
}

const SolutionTaskListIssueGroupHeading: React.FC<SolutionTaskListIssueGroupHeadingProps> = (
  props
) => {
  const { description } = props;
  const classes = useStyles();

  return (
    <div className={classes.heading}>
      <Typography className={classes.title} variant="h5">
        {description}
      </Typography>
    </div>
  );
};

export default SolutionTaskListIssueGroupHeading;
