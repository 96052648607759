import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  headerName: {
    color: theme.palette.text.primary,
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
}));
