import React from 'react';
import useUnload from 'use/useUnload';

// Unload takes care of the rendering of useUnload, for the onbeforeunload event.
// In case there are unsaved changes and the user navigates away
// he is asked for verification to leave.

const Unload = () => {
  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue = '';
  });

  return <></>;
};

export default Unload;
