import { useState } from 'react';
import {
  CircularProgress,
  TableBody,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  Grid,
  Alert,
  TableRow,
} from '@mui/material';
import PlaceholderEmptyList from 'components/PlaceholderEmptyList';
import { useTranslation } from 'react-i18next';
import DownloadItem from '../DownloadItem/DownloadItem';
import { IsMobile as isMobileFunction } from 'utils/global';
import DownloadListMobile from './Mobile/DownloadListMobile';
import { useQueryClientDataDownloadFiles } from 'use/downloads';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

const DownloadList = () => {
  const { t } = useTranslation();
  const isMobile = isMobileFunction();
  const [isError, setError] = useState<boolean>(false);
  const { status, data: files } = useQueryClientDataDownloadFiles();

  if (status === 'loading') {
    return (
      <Grid container justifyContent="center">
        <CircularProgress size={30} data-testid="downloadList-loadingProgress" />
      </Grid>
    );
  }

  if (status === 'error') {
    return (
      <Alert severity="error" data-testid="downloadList-alert">
        {t('clients.detail.error')}
      </Alert>
    );
  }

  if (files?.length === 0 || !files) {
    return <PlaceholderEmptyList subtitle={t('downloads.empty')} />;
  }
  if (isMobile) {
    return <DownloadListMobile files={files} />;
  }

  return (
    <>
      <Grid data-testid="downloadList-desktop" container justifyContent="center">
        <Grid item xs={10}>
          <TableContainer>
            <Table aria-label="clients table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>{t('downloads.table.date')}</TableCell>
                  <TableCell>{t('downloads.table.type')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((file, index) => (
                  <DownloadItem file={file} key={file.fileId} setError={setError} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <CustomSnackbar
        isOpen={isError}
        handleClose={() => setError(false)}
        severity="error"
        message={t('error.default')}
      />
    </>
  );
};

export default DownloadList;
