import authReducer from '../auth/reducer';
import appReducer from './appReducer';
import conversionFormReducer from './conversionFormReducer';
import collateralValuesFormReducer from './collateralValuesFormReducer';
import dataSharingReducer from './dataSharingReducer';
import ohaFormReducer from './ohaFormReducer';
import locationReducer from './locationReducer';
import createClientFormReducer from './createClientFormReducer';
import genericClientListFiltersReducer from './genericClientListFiltersReducer';

const rootReducer = {
  app: appReducer,
  auth: authReducer,
  conversionForm: conversionFormReducer,
  collateralValuesForm: collateralValuesFormReducer,
  createClientForm: createClientFormReducer,
  dataSharing: dataSharingReducer,
  ohaForm: ohaFormReducer,
  location: locationReducer,
  genericClientFilters: genericClientListFiltersReducer,
};

export default rootReducer;
