import { Box, Step, Stepper } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StepConnector, StepLabel, Typography } from './FormStepper.styled';

type StepType = {
  name: string;
  text: string;
};

type Props = {
  steps?: StepType[];
};

const FormStepper: FC<Props> = ({ steps }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);

  return (
    <Box>
      <Stepper orientation="vertical" activeStep={activeStep} connector={<StepConnector />}>
        {steps?.map((step, index) => {
          return (
            <Step
              data-testid="formStepper_step"
              key={step.name}
              onClick={() => setActiveStep(index)}
            >
              <StepLabel>
                <Typography>{t(step.text)}</Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default FormStepper;
