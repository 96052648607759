import { Container, Box, Hidden } from '@mui/material';
import { IsMobile } from 'utils/global';
import { ReactElement } from 'react';
import { Wrapper, SearchBoxWrapper, FilterAreaWrapper } from './Wrapper.styled';

type ClientTemplateProps = {
  filterArea: ReactElement;
  header?: ReactElement;
  searchBar?: ReactElement;
  mobileFilterArea?: ReactElement;
  list: ReactElement;
};

export default function ClientTemplate({
  header,
  searchBar,
  filterArea,
  mobileFilterArea,
  list,
}: ClientTemplateProps) {
  const isMobile = IsMobile();
  return (
    <Wrapper>
      {header}
      <Container>
        <Box
          my={isMobile ? 1 : 3}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <SearchBoxWrapper>{searchBar}</SearchBoxWrapper>
          <Hidden smDown>
            <FilterAreaWrapper display="grid">{filterArea}</FilterAreaWrapper>
          </Hidden>
        </Box>
        {list}
      </Container>
      {mobileFilterArea}
    </Wrapper>
  );
}
