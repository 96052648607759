import React from 'react';
import { Typography, Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SolutionState } from '../../store/taskReducer';
import { TaskDetailsIssue } from '../../__generated__/api-di';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    borderBottom: 'solid 1px #EBEAEE',
  },
  title: {
    marginRight: theme.spacing(1),
    fontWeight: 'bolder',
    [theme.breakpoints.down('md')]: {
      flexGrow: 1,
    },
  },
  emphasize: {
    fontWeight: 'bold',
    fontSize: '10px',
  },
}));

interface TaskListIssueGroupHeadingProps {
  description: string;
  issues: Array<TaskDetailsIssue>;
  solutionState: SolutionState;
}

const TaskListIssueGroupHeading: React.FC<TaskListIssueGroupHeadingProps> = (props) => {
  const { description, issues, solutionState } = props;
  const classes = useStyles();

  const issuesWithSolution = issues.filter(
    (issue) => solutionState.get(issue.id!) !== undefined
  ).length;

  const issueCountLabel = `${issuesWithSolution}/${issues.length}`;

  return (
    <div className={classes.heading}>
      <Typography className={classes.title} variant="subtitle1">
        {description}
      </Typography>
      <Chip className={classes.emphasize} size="small" label={issueCountLabel} />
    </div>
  );
};

export default TaskListIssueGroupHeading;
