import { SummaryCard, CardRow, CardSection } from 'components/SummaryCard';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getTypeOfBenefitOption } from 'screens/Mutations/constants';
import { SocialSecurityIncome } from 'screens/Mutations/utils/types';
import { formatAmount } from 'utils/formatAmount';
import formatDate from 'utils/formatDate';

type SocialSecurityIncomeProps = {
  income: SocialSecurityIncome;
  onClick: () => void;
};

const SocialSecurity: FC<SocialSecurityIncomeProps> = ({ income, onClick }) => {
  const { t } = useTranslation();

  const root = 'directMutations.income.socialSecurity';

  return (
    <>
      <SummaryCard title={t('directMutations.income.formTitle')} onEditClick={onClick}>
        <CardSection>
          <CardRow
            name={t('directMutations.income.typeOfIncome')}
            value={t('directMutations.income.incomeTypes.SocialSecurity')}
          />
          <CardRow
            name={t(`${root}.typeOfBenefit`)}
            value={income.typeOfBenefit && getTypeOfBenefitOption(income.typeOfBenefit).label}
          />
          <CardRow name={t(`${root}.startDate`)} value={formatDate(income.startDate, '-')} />
          <CardRow name={t(`${root}.endDate`)} value={formatDate(income.endDate, '-')} />
          <CardRow name={t(`${root}.incomeAmount`)} value={formatAmount(income.incomeAmount)} />
        </CardSection>
      </SummaryCard>
    </>
  );
};

export default SocialSecurity;
