import React, { useState, FC } from 'react';
import printJs from 'print-js';
import { Dialog, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ProofItem } from '../../types/ProofItem';
import { Document } from '../../types/Document';
import { axiosUpload } from './axiosUpload';
import UploadForm from './UploadForm';
import Uploading from './Uploading';
import Footer from './Footer';
import FileViewer from '../DocumentViewer';
import DocumentHeader from '../../components/DocumentViewer/DocumentHeader';
import { useGoogleTagManager } from 'use/gtm';
import { SlideUpTransition } from '../../lib/Transition/transition';
import { useGroup } from 'use/group';
import { getProofItemDescription } from 'components/DocumentViewer/utils';

const useStyles = makeStyles((theme) => ({
  inputFile: {
    width: '100%',
    height: '100%',
    opacity: '0',
    top: '0px',
    left: '0px',
    overflow: 'hidden',
    position: 'absolute',
    display: 'block',
  },
  check: {
    color: theme.palette.success.main,
    margin: theme.spacing(0, 1, -0.5, 1),
  },
  error: {
    color: theme.palette.error.main,
    margin: theme.spacing(0, 1, -0.5, 1),
  },
  main: {
    flexGrow: 100,
  },
  fullHeight: {
    height: '100%',
    flexGrow: 100,
  },
}));

type Props = {
  proofItem: ProofItem;
  commercialLabel: string;
  loanNumber: number;
  applicationIndexNumber: number;
  open: boolean;
  onSuccess: (file: string) => void;
  onCancel: () => void;
  uploadTarget?: (
    document: Document,
    updatePercentagProgress: (percent: number) => void,
    successResponse: () => void,
    errorResponse: (errorMessage: string) => void
  ) => void;
};

const UploadDialog: FC<Props> = ({
  proofItem,
  commercialLabel,
  loanNumber,
  applicationIndexNumber,
  open,
  onCancel,
  onSuccess,
  uploadTarget,
}) => {
  const [state, setState] = useState('');
  const [selectedFile, setSelectedFile] = useState<File>();
  const [percentage, setPercentage] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const googleTagManager = useGoogleTagManager();

  const classes = useStyles();
  const [scale, setScale] = useState(1);
  const group = useGroup();
  const handleUpload = uploadTarget ?? axiosUpload;

  const reset = () => {
    setState('');
    setSelectedFile(undefined);
  };

  const handleSubmit = () => {
    if (!selectedFile) return;

    setState('uploading');
    const document: Document = {
      commercialLabel,
      loanNumber,
      applicationIndexNumber,
      code: proofItem?.code ?? 0,
      file: selectedFile,
      sequenceNumber: proofItem?.documentSequenceNumber ?? 1,
    };

    handleUpload(document, setPercentage, successResponse, errorResponse, group);

    googleTagManager({
      event: 'ButtonClick',
      btnName: 'SubmitDocumentUpload',
      file: selectedFile?.name,
      fileType: selectedFile?.type,
    });
  };

  const successResponse = () => {
    reset();
    onSuccess(selectedFile?.name ?? '');
    googleTagManager({
      event: 'UploadSucces',
      file: selectedFile?.name,
      fileType: selectedFile?.type,
    });
  };

  const errorResponse = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setState('error');
    setPercentage(0);
    googleTagManager({ event: 'Error', errorMessage: errorMessage });
  };

  const handleSelect = (file: File) => {
    setState('selected');
    setSelectedFile(file);
  };

  const handleCancel = () => {
    googleTagManager({
      event: 'ButtonClick',
      btnName: 'ExitDocumentUpload',
      statusPriority: proofItem.status,
      file: selectedFile?.name ?? '',
    });
    reset();
    onCancel();
  };

  const handleZoom = (scalePercentage: number) => {
    setScale(scalePercentage / 100);
  };

  const handleDownload = () => {
    const url =
      selectedFile !== undefined ? window.URL.createObjectURL(new Blob([selectedFile])) : '';
    const link = window.document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${selectedFile?.name}.pdf`);
    window.document.body.appendChild(link);
    link.click();
  };

  const handlePrint = () => {
    const print =
      selectedFile !== undefined ? window.URL.createObjectURL(new Blob([selectedFile])) : '';
    printJs(print);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={SlideUpTransition}
      onClose={onCancel}
      TransitionProps={{ onExited: reset }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="stretch"
        className={classes.fullHeight}
      >
        <DocumentHeader
          description={getProofItemDescription(proofItem)}
          shortDescription={proofItem?.shortDescription}
          onZoom={handleZoom}
          onClose={handleCancel}
          zoomVisible={state === 'selected'}
        />

        <div className={classes.main}>
          {state === 'uploading' && (
            <Uploading
              selectedFile={selectedFile}
              percentage={percentage}
              onCancel={handleCancel}
            />
          )}
          {state === 'selected' && <FileViewer file={selectedFile} scale={scale} hasFooter />}
          {state !== 'uploading' && state !== 'selected' && (
            <UploadForm
              showError={state === 'error'}
              errorMessage={errorMessage}
              onSelect={handleSelect}
            />
          )}
        </div>
        {state === 'selected' && (
          <Footer
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            onDownloadPdf={handleDownload}
            onPrintPdf={handlePrint}
          />
        )}
      </Box>
    </Dialog>
  );
};

export default UploadDialog;
