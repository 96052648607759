import { styled } from '@mui/styles';
import { Menu as MuiMenu } from '@mui/material';

const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiPaper-root': {
    'borderRadius': 6,
    'minWidth': 180,
    'color': theme.palette.text.primary,
    'boxShadow':
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: theme.palette.grey[100],
      },
    },
  },
}));

export default Menu;
