import Hidden from '@mui/material/Hidden';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Logo from 'components/Logo';
import { formatLoanNumber } from 'utils/global';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Client, getCustomerNames } from 'types/Client';
import { isDataSharingEnabledForLoan } from 'use/clientConsent';
import {
  TableBodyRow,
  MoneyLenderName,
  LoanBox,
  NavLinkIcon,
} from 'screens/Clients/Clients.styled';
import { dataSharingEnabled } from '../../../constants';
import { Emphasize } from 'screens/templates/Lists/ListWrapper/Wrapper.styled';
import DisplayClientDataSharing from 'lib/ClientDataSharingField/DisplayClientdataSharing';

type ClientListDesktopProps = {
  clients: Client[] | [];
  onClickItem: (item: Client, location?: string) => void;
};

const ClientListDesktop: FC<ClientListDesktopProps> = ({ clients, onClickItem }) => {
  const { t } = useTranslation();
  const referrer = window.location.href;
  const pathName = window.location.pathname;

  return (
    <Hidden smDown>
      <TableContainer data-testid="clientList-desktop">
        <Table aria-label="clients table">
          <TableHead>
            <TableRow>
              <TableCell>{t('clients.table.client')}</TableCell>
              <TableCell>{t('clients.table.moneylender')}</TableCell>
              <TableCell>{t('clients.table.loanNumber')}</TableCell>
              <TableCell>{t('clients.table.type')}</TableCell>
              {dataSharingEnabled && <TableCell>{t('clients.table.dataSharing')}</TableCell>}
              <TableCell>{t('clients.table.eConsumerLink')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((item, index) => (
              <TableBodyRow
                hover
                key={`${item.customerId}-${index}`}
                onClick={() => onClickItem(item, pathName)}
              >
                <TableCell data-testid={`applicant-${index}`}>
                  <Emphasize>{getCustomerNames(item)}</Emphasize>
                </TableCell>
                <TableCell>
                  {item.loans.map((loan) => (
                    <Box
                      key={`${loan.loanNumber}-${loan.applicationIndexNumber}`}
                      display="flex"
                      gap={1}
                      alignItems="center"
                    >
                      <Logo label={loan.commercialLabel} size="S" />
                      <MoneyLenderName>{loan.moneylenderName}</MoneyLenderName>
                    </Box>
                  ))}
                </TableCell>
                <TableCell>
                  {item.loans.map((loan) => (
                    <LoanBox key={`${loan.loanNumber}-${loan.applicationIndexNumber}`}>
                      {formatLoanNumber(loan.loanNumber)}
                    </LoanBox>
                  ))}
                </TableCell>
                <TableCell>
                  {item.loans.map((loan) => (
                    <LoanBox key={`${loan.loanNumber}-${loan.applicationIndexNumber}`}>
                      {t(`clients.loanType.${loan.loanType}`)}
                    </LoanBox>
                  ))}
                </TableCell>
                {dataSharingEnabled && (
                  <TableCell>
                    {item.loans.map((loan) => (
                      <LoanBox
                        key={`consent-${loan.loanNumber}-${loan.applicationIndexNumber}`}
                        iscentered={1}
                      >
                        {isDataSharingEnabledForLoan(loan) ? (
                          <DisplayClientDataSharing client={item} loan={loan} />
                        ) : (
                          <>&nbsp;</>
                        )}
                      </LoanBox>
                    ))}
                  </TableCell>
                )}
                <TableCell>
                  {item.loans.map((loan) => (
                    <p key={`${loan.loanNumber}-${loan.applicationIndexNumber}`}>
                      {loan.eConsumerLink ? (
                        <Link
                          onClick={(e: any) => e.stopPropagation()}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${loan.eConsumerLink}&referrer=${referrer}`}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {t('clients.table.goToEconsumer')}
                          <NavLinkIcon>logout</NavLinkIcon>
                        </Link>
                      ) : (
                        <>&nbsp;</>
                      )}
                    </p>
                  ))}
                </TableCell>
              </TableBodyRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Hidden>
  );
};

export default ClientListDesktop;
