import { AxiosPromise } from 'axios';
import { DismissalJointLiabilityMutationApplication } from '__generated__/api';
import { HEADER_SELECTED_GROUP_CLAIM, http } from '../http';

export const saveOhaMutation =
  (group: string | null) =>
  (
    mutation: DismissalJointLiabilityMutationApplication
  ): AxiosPromise<DismissalJointLiabilityMutationApplication> =>
    http({
      url: '/api/oha/save',
      method: 'POST',
      headers: { [HEADER_SELECTED_GROUP_CLAIM]: group },
      data: JSON.stringify(mutation),
    });

export const saveAndValidateOhaMutation = (
  group: string | null,
  mutation: DismissalJointLiabilityMutationApplication
): AxiosPromise<DismissalJointLiabilityMutationApplication> =>
  http({
    url: '/api/oha/saveAndValidate',
    method: 'POST',
    headers: { [HEADER_SELECTED_GROUP_CLAIM]: group },
    data: JSON.stringify(mutation),
  });

export const getOhaById = async (
  id: number,
  group: string
): Promise<DismissalJointLiabilityMutationApplication> => {
  const response = await http({
    url: `/api/oha/${id}`,
    method: 'GET',
    headers: { [HEADER_SELECTED_GROUP_CLAIM]: group },
  });

  return response.data;
};
