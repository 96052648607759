import { Button, Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const WarningButton = styled(Button)(({ theme }) => ({
  color: theme.palette.danger.dark,
}));

const ConnectedPoliciesWrapper = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
}));

const LoanPartsGrid = styled(Grid)(({ theme }) => ({
  maxWidth: 700,
  paddingBottom: theme.spacing(8),
}));

const TitleWrapper = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));
const Title = styled(Typography)({
  fontWeight: 400,
  fontSize: '19px',
  lineHeight: '1.334',
});

export { WarningButton, ConnectedPoliciesWrapper, LoanPartsGrid, TitleWrapper, Title };
