import { useEffect, FC, useState, useRef, useCallback } from 'react';
import { getUserManager } from '../auth/oidc';
import { User, UserManager } from 'oidc-client';
import { OBSERVER_ENTRY_URL } from 'auth/constants';
import LoadingIndicator from 'components/LoadingIndicator';
import { getUnixTime } from 'date-fns';
import ErrorComponent from './ErrorComponent/ErrorComponent';

const getUrl = ({ state }: User) => {
  if (state && state.originalPath) return state.originalPath.replace(OBSERVER_ENTRY_URL, '');

  return '/';
};

const hasCodeInUrl = (location: Location): boolean => {
  const searchParams = new URLSearchParams(location.search);
  const hashParams = new URLSearchParams(location.hash.replace('#', '?'));

  return Boolean(
    searchParams.get('code') ||
      searchParams.get('id_token') ||
      searchParams.get('session_state') ||
      hashParams.get('code') ||
      hashParams.get('id_token') ||
      hashParams.get('session_state')
  );
};

type Props = {
  onBeforeSignIn?: () => void;
  onSignIn?: (user: User) => void;
  children?: React.ReactNode;
};

const AuthProvider: FC<Props> = ({ children, onBeforeSignIn, onSignIn }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isClockError, setClockError] = useState(false);
  const [isGeneralError, setGeneralError] = useState(false);

  const [userData, setUserData] = useState<User | null>(null);

  // TODO: use React.Context to provide SignIn / Signout / UserManager
  // Then way we can remove the global userManager from oidc.ts
  const [userManager] = useState<UserManager>(() => getUserManager());
  const isMountedRef = useRef(true);
  const { location, history } = window;

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const onMount = useCallback(async () => {
    // Check if the user is returning back from OIDC.
    if (hasCodeInUrl(location)) {
      let user = null;
      try {
        user = await userManager.signinCallback();
      } catch (error) {
        if (
          error instanceof Error &&
          (error.message.indexOf('is in the future') > 0 ||
            error.message.indexOf('is in the past') > 0)
        ) {
          setClockError(true);
        } else {
          setGeneralError(true);
        }
      } finally {
        setIsLoading(false);

        if (user) {
          setUserData(user);
          onSignIn && onSignIn(user);

          history.replaceState({}, window.document.title, getUrl(user));
        }
      }

      return;
    }

    const user = await userManager.getUser();
    if (!user || user.expired) {
      onBeforeSignIn && onBeforeSignIn();

      // signin
      userManager.signinRedirect({
        state: { originalPath: location.pathname },
        extraQueryParams: {
          sessionstarttime: getUnixTime(new Date()),
        },
      });

      return;
    }

    if (isMountedRef.current) {
      setUserData(user);
      setIsLoading(false);
    }
  }, [history, location, onBeforeSignIn, onSignIn, userManager]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (isClockError)
    return (
      <ErrorComponent
        message="Voor het veilig inloggen is het van belang dat uw systeemklok synchroon loopt. U kunt hier uw datum- en tijdinstellingen openen"
        isClockError={isClockError}
      />
    );
  if (isGeneralError) return <ErrorComponent message="Probeert u het later nog eens" />;
  if (isLoading) return <LoadingIndicator />;
  if (userData) return <>{children}</>;

  return null;
};

export default AuthProvider;
