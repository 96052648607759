import { useQuery } from 'react-query';
import { http } from '../http';
import { useAppSelector } from '../store';

export function useGroup() {
  const group = useAppSelector((state) => state.app.group);
  return group;
}

export type GroupClaim = {
  name: string;
  description: string;
};

export const GROUPS_API_URL = '/api/authorizations/groups';

async function getGroups(): Promise<GroupClaim[]> {
  const response = await http.get(GROUPS_API_URL);
  return response.data.groupClaims;
}

export function useQueryGroups(enabled = true) {
  return useQuery(['groups'], () => getGroups(), { enabled });
}
