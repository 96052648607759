import { Grid, Divider, Button, Alert, CircularProgress } from '@mui/material';
import Logo from 'components/Logo';
import StatusTag from 'components/StatusTag';
import { IsMobile, formatLoanNumber, formatLoanAmount } from 'utils/global';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  ApplicationContentDetailsSpan,
  ActionButtonOnDetails,
} from 'screens/Clients/Clients.styled';
import { Emphasize } from 'screens/Home/Home.styled';
import { useGoogleTagManager } from 'use/gtm';
import { CaseDetails } from '__generated__/api-di';
import { useTranslation } from 'react-i18next';

export type ClientApplicationItemProps = {
  application?: CaseDetails;
  isError: boolean;
  isLoading: boolean;
  commercialRelation?: string;
};

export default function ClientApplicationItem({
  application,
  isError,
  isLoading,
  commercialRelation,
}: ClientApplicationItemProps) {
  const isMobile = IsMobile();
  const googleTagManager = useGoogleTagManager();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  if (isLoading) {
    return <CircularProgress size={30} data-testid="loading" />;
  }

  if (!application || isError) {
    return (
      <Alert data-testid="alert-message" severity="error">
        {t('clients.detail.error')}
      </Alert>
    );
  }

  const handleItemClick = () => {
    navigate(
      `/${commercialRelation}/applications/${application.application!.loanNumberExternal}/${
        application.application!.index
      }`,
      { state: { application, from: location } }
    );
    googleTagManager({
      event: 'ButtonClick',
      btnName: 'GoToApplication',
      applicationStatus: application.application!.status!.value,
    });
  };

  return (
    <Grid mt={2} data-testid="applicationItem-container">
      <Divider />
      <Grid mt={1} spacing={isMobile ? 0 : 3} container alignItems="center" flex="nowrap">
        <Grid item xs={12} md={5}>
          <Grid container alignItems="center" flex="wrap">
            {!isMobile && (
              <Grid item xs={12} md="auto" pr={2} data-testid="logo">
                <Logo label={application.lender!.label!} size="M" />
              </Grid>
            )}
            <Grid item xs={12} md="auto">
              <Emphasize truncate={1}>{application.lender!.name}</Emphasize>
              <ApplicationContentDetailsSpan>
                {formatLoanNumber(application.application!.loanNumberExternal!)}
                <span>‧</span>
                {t('clients.loanType.Application')}
                <span>‧</span>
                {formatLoanAmount(application.application!.loanAmount!)}
              </ApplicationContentDetailsSpan>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container justifyContent="space-between">
            {!isMobile && (
              <Grid item xs={6} data-testid="status-bar">
                <StatusTag
                  urgency={application.application!.priority!.urgency!}
                  progress={application.application!.progress!}
                  label={t(`applications.filter.${application.application!.status!.value}`)}
                />
              </Grid>
            )}
            <ActionButtonOnDetails item xs={12} md="auto">
              <Button color="primary" onClick={handleItemClick}>
                {t('clients.detail.goToApplication')}
              </Button>
            </ActionButtonOnDetails>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
