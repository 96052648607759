import React from 'react';
import { Action, Solution, SolutionState } from '../../store/taskReducer';
import { ProofItem } from '../../types/ProofItem';
import ReUploadProofItemSolution from './ReuploadProofItemSolution/ReuploadProofItemSolution';
import AddProofItemSolution from './AddProofitemSolution/AddProofitemSolution';
import CommentSolutionData from './CommentSolution/CommentSolution';
import SolvedByOtherSolutionData from './SolvedByOtherSolution/SolvedByOtherSolution';
import { Case, TaskDetailsIssue } from '__generated__/api-di';

interface IssueListItemProps {
  application: Case;
  dispatch: React.Dispatch<Action>;
  proofItems: Array<ProofItem>;
  issue: TaskDetailsIssue;
  solution: Solution;
  solutionState: SolutionState;
}

const IssueListItem: React.FC<IssueListItemProps> = ({
  application,
  dispatch,
  issue,
  proofItems,
  solutionState,
  solution,
}) => {
  return (
    <>
      {solution.type === 'comment' && (
        <CommentSolutionData dispatch={dispatch} solution={solution} issueId={issue.id!} />
      )}
      {solution.type === 'solvedByOther' && (
        <SolvedByOtherSolutionData dispatch={dispatch} solution={solution} issueId={issue.id!} />
      )}
      {solution.type === 're-upload' && (
        <ReUploadProofItemSolution
          application={application}
          issueId={issue.id!}
          solution={solution}
          dispatch={dispatch}
          proofItems={proofItems}
        />
      )}
      {solution.type === 'addNew' && (
        <AddProofItemSolution
          application={application}
          issueId={issue.id!}
          solution={solution}
          dispatch={dispatch}
          proofItems={proofItems}
        />
      )}
    </>
  );
};

export default IssueListItem;
