import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import TemplateContactDetails from '../../../../templates/ContactDetails';

type ContactDetailsProps = {
  index?: number;
};
const ContactDetails: FC<ContactDetailsProps> = ({ index = 0 }) => {
  const applicant = useWatch({ name: `applicants.${index}` });
  if (!applicant || !applicant.personalInfo) return null;
  const applicationTitle =
    applicant.personalInfo.surname &&
    `${applicant.personalInfo.firstNames ?? ''} ${applicant.personalInfo.middleName ?? ''} ${
      applicant.personalInfo.surname
    }`;

  return (
    <TemplateContactDetails
      title="directMutations.stepper.applicant"
      name={applicationTitle}
      subtitle="directMutations.contactDetails.subtitle"
      rootFormName={`applicants.${index}.contactDetails.`}
    />
  );
};
export default ContactDetails;
