import { addMethod, date, DateSchema } from 'yup';
import { jsDateToLocalISO8601DateString } from './getDateValueFromInput';

const errorMessage = 'Datum niet herkend, gebruik het formaat DD-MM-JJJJ';

// eslint-disable-next-line func-names
addMethod<DateSchema>(date, 'isFutureDate', function (message: string | undefined) {
  return this.test({
    name: 'isFutureDate',
    message,
    test: (value) => (value ? value > new Date() : true),
  });
});

// eslint-disable-next-line func-names
addMethod<DateSchema>(date, 'isPastDate', function (message: string | undefined) {
  return this.test({
    name: 'isPastDate',
    message,
    test: (value) => (value ? value < new Date() : true),
  });
});

const defaultDate = date()
  .default(undefined)
  .nullable(true)
  .transform((value) => (!value ? null : new Date(jsDateToLocalISO8601DateString(value))))
  .typeError(errorMessage);

export default defaultDate;
