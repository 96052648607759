import { Collapse, Grid, IconButton } from '@mui/material';
import { DetailsRow } from 'lib/ClientDataDetails/Fields';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { calcPrincipalSum } from 'screens/Clients/utils/calculateLoanParts';
import { LoanPartsTableProps } from 'types/Client';
import { calculateCompletion } from 'utils/calculateCompletion';
import { LoanPartsProgress } from './BorderLinearProgress';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TableCollapseArea from './TableCollapseArea';
import TemplateDetails from '../../ClientData/components/TemplateDetails';

function LoanPartsMobileTable({ loanPart, translationsRoot }: LoanPartsTableProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const principalSum = calcPrincipalSum(loanPart);
  const progress = calculateCompletion(principalSum, loanPart.residualDebt, 0) ?? 0;

  return (
    <Grid container item justifyContent="center" alignItems="baseline" mt={2}>
      <Grid item xs={11} onClick={() => setOpen(!open)}>
        <TemplateDetails
          title={loanPart.product}
          titleExtraClass={(theme: any) => ({ fontWeight: theme.typography.fontWeightBold })}
        >
          <DetailsRow
            type="currency"
            label={t(`${translationsRoot}.originalAmount`)}
            value={principalSum}
          />
          <DetailsRow
            type="currency"
            label={t(`${translationsRoot}.remainingAmount`)}
            value={loanPart.residualDebt}
          />
          <DetailsRow
            type="percentage"
            label={t(`${translationsRoot}.interestRate`)}
            value={loanPart.interestPeriod?.effectiveInterestRate}
          />
          <DetailsRow type="string" label={t(`${translationsRoot}.progress`)}>
            <LoanPartsProgress variant="determinate" value={progress} />
          </DetailsRow>
        </TemplateDetails>
      </Grid>
      <Grid item xs={1}>
        <IconButton aria-label="expand row">{open ? <RemoveIcon /> : <AddIcon />}</IconButton>
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <TableCollapseArea loanPart={loanPart} translationsRoot={translationsRoot} />
      </Collapse>
    </Grid>
  );
}
export default LoanPartsMobileTable;
