import Logo from 'components/Logo';
import React from 'react';
import { TileCounter, MoneyLenderName } from '../Home.styled';
import { MoneyLenderTilesProps } from 'types/MoneyLenders';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

type TileProps = {
  tileInfo: MoneyLenderTilesProps;
};
export default function Tile({ tileInfo }: TileProps) {
  const { t } = useTranslation();
  const { name, displayName, applicationsCallToAction, conceptsCallToAction } = tileInfo;
  const counter = conceptsCallToAction + applicationsCallToAction;
  const isCallToAction = !!conceptsCallToAction || !!applicationsCallToAction;

  const tooltip =
    (applicationsCallToAction ? `${t('home.applications')}: ${applicationsCallToAction}\n` : '') +
    (conceptsCallToAction ? `${t('home.concepts')}: ${conceptsCallToAction}\n` : '');

  return (
    <>
      {isCallToAction && (
        <TileCounter data-testid="tile-counter" title={tooltip}>
          {counter}
        </TileCounter>
      )}
      <Grid height="96px">
        <Logo label={name} size="L" alt={name} />
      </Grid>
      <MoneyLenderName data-testid="moneyLenderTiles-name" color="textPrimary">
        {displayName}
      </MoneyLenderName>
    </>
  );
}
