import { ButtonProps, Hidden, IconButton, Icon, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { buttonStyles } from '../styling';

const ReuploadButton = ({ onClick }: ButtonProps) => {
  const { t } = useTranslation();
  const classes = buttonStyles();

  return (
    <>
      <Hidden mdUp>
        <IconButton
          color="primary"
          onClick={onClick}
          title={t('applications.detail.reuploadTooltip')}
          data-testid="reupload-button"
        >
          <Icon>reupload</Icon>
        </IconButton>
      </Hidden>
      <Hidden mdDown>
        <Button
          color="success"
          variant="contained"
          onClick={onClick}
          title={t('applications.detail.reuploadTooltip')}
          data-testid="reupload-button"
          className={classes.button}
          endIcon={<Icon className={classes.icon}>reupload</Icon>}
        >
          {t('applications.detail.reuploadButton')}
        </Button>
      </Hidden>
    </>
  );
};

export default ReuploadButton;
