import { number, object, string } from 'yup';
import {
  errorMessages,
  getLegalFormOptions,
  getTypeOfCompanyOptions,
} from 'screens/Mutations/constants';
import isNil from 'utils/predicates/isNil';
import { defaultDate } from 'utils/form/validations';

const isYes = (val: any) => val === 'yes';
const isNo = (val: any) => val === 'no';

const legalFormValues = getLegalFormOptions().map((f) => f.value);
const companyTypeValues = getTypeOfCompanyOptions().map((f) => f.value);

const defaultNumber = number().nullable(true).typeError(errorMessages.number);
const requiredNumber = defaultNumber.required(errorMessages.required);

const entrepreneurSchema = object({
  typeOfCompany: string().nullable(true).oneOf(companyTypeValues).required(errorMessages.required),
  legalForm: string().when('typeOfCompany', {
    is: (v: any) => !isNil(v),
    then: string().oneOf(legalFormValues).required(errorMessages.required),
  }),
  startDate: defaultDate.when('legalForm', {
    is: (v: any) => !isNil(v),
    then: defaultDate.isPastDate(errorMessages.PastDate).required(errorMessages.required),
  }),
  hasIncomeStatement: string().when('legalForm', {
    is: (v: any) => !isNil(v),
    then: string().oneOf(['yes', 'no']).nullable().required(errorMessages.required),
  }),
  incomeAmount: defaultNumber.when('hasIncomeStatement', {
    is: isYes,
    then: requiredNumber.min(1),
  }),
  incomePreviousFirstYear: defaultNumber.when('hasIncomeStatement', {
    is: isNo,
    then: requiredNumber.min(0),
  }),
  incomePreviousSecondYear: defaultNumber.when('hasIncomeStatement', {
    is: isNo,
    then: requiredNumber.min(0),
  }),
  incomePreviousThirdYear: defaultNumber.when('hasIncomeStatement', {
    is: isNo,
    then: requiredNumber.min(0),
  }),
});

export default entrepreneurSchema;
