import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Icon } from '../NavDrawer.styled';
import { RouteConfig } from 'react-router-config';
import Item from './Item';
import env from 'config/env';

type NotificationsPreferencesButtonProps = {
  onClick: (buttonName: string) => void;
  route?: RouteConfig;
};

const NotificationsPreferencesButton: FC<NotificationsPreferencesButtonProps> = ({
  onClick,
  route,
}) => {
  const { t } = useTranslation();
  const openRoute = () => {
    onClick(route?.name);
  };

  if (!route || !env.FEATURES.STAT_470139_PUSHNOTIFICATIES) {
    return null;
  }

  return (
    <NavLink to={route.path as string} key={route.name}>
      <Item
        icon={<Icon fontSize="inherit">{route.icon}</Icon>}
        menuName={t(`general.header.menu.${route.name}`)}
        handleClick={openRoute}
      />
    </NavLink>
  );
};
export default NotificationsPreferencesButton;
