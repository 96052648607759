import { styled } from '@mui/styles';
import {
  Toolbar as MuiToolbar,
  Box as MuiBox,
  Typography,
  AppBar,
  IconButton as MuiIconButton,
} from '@mui/material';

const AppBarWrapper = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.text.primary,
}));

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',

  [theme.breakpoints.up('sm')]: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
}));

const HeaderTitleWrapper = styled(MuiBox)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  fontWeight: 'bolder',
});
const HeaderSubtitleWrapper = styled(MuiBox)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
});

const Title = styled(Typography)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  fontWeight: 'bolder',
});
const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color: 'grey',
  padding: theme.spacing(0, 0.5, 0, 0),
}));

const MoneyLender = styled(Typography)({
  fontSize: '12px',
  color: 'black',
});
const ButtonText = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 1),
}));
const ButtonWrapper = styled(MuiBox)(({ theme }) => ({
  'display': 'flex',
  '& hr': {
    margin: theme.spacing(0, 1, 0, 2),
  },
}));
const IconButton = styled(MuiIconButton)({
  hover: { borderRadius: '30px' },
});

export {
  IconButton,
  ButtonText,
  ButtonWrapper,
  AppBarWrapper,
  Toolbar,
  HeaderTitleWrapper,
  HeaderSubtitleWrapper,
  MoneyLender,
  Subtitle,
  Title,
};
