import { SelectChangeEvent } from '@mui/material';
import type { Consent, ConsentStatus } from '../__generated__/api-di';
import { ClientDetailsLoanParts } from './ClientDetailsModel';

export type LoanPartsTableProps = {
  loanPart: ClientDetailsLoanParts;
  translationsRoot: string;
};

export type ClientFilterAreaProps = {
  activeMoneylenders?: ActiveMoneylender[];
  filterState: FilterState;
  handleSelectSingleValues: (e: SelectChangeEvent<string>) => void;
  handleSelectMultiValues?: (e: SelectChangeEvent<string[]>) => void;
};

export type ActiveMoneylender = {
  value: string;
  text: string;
};

export type FilterState = {
  moneylenderName: Array<string>;
  eConsumerLink: string;
  loanType: string;
  status: string;
};

export type Client = {
  customerId: number;
  intermediaryId: number;
  loans: ClientLoan[];
  persons: Person[];
};

export type Person = {
  id?: number;
  initials: string;
  preposition: string;
  surname: string;
};

export type ClientLoan = {
  eConsumerLink: string;
  hasPermission: boolean;
  loanNumber: number;
  loanType: LoanType
  moneylender: number;
  moneylenderName: string;
  consentStatus?: ConsentStatus;
  applicationIndexNumber: number;
  remainingDebt: number;
  loanAmount: number;
  assetAdress: string;
  commercialLabel: string;
  commercialRelation: string;
};

export type LoanType= 'Application' | 'LiveMortgage';

export type GetClientConsentStatusResponse = {
  consent?: Consent;
};

export const getCustomerNames = (client: Client | undefined) => {
  if (client == null || client === undefined || !client.persons || client.persons.length === 0) {
    return '';
  }

  return (
    combineNameParts(client.persons[0]) +
    (client.persons.length > 1 ? ` & ${combineNameParts(client.persons[1])}` : '')
  );
};

function combineNameParts(person: Person) {
  const parts = [person.initials, person.preposition, person.surname];
  const name = parts.filter((part) => !!part).join(' ');
  return name;
}

export const mapMaritalStatus = (maritalStatus: string | undefined): string | undefined => {
  switch (maritalStatus) {
    case 'MarriedInCommunityOfGoodsOnce':
    case 'MarriedInCommunityOfGoodsMoreThanOnce':
    case 'Married1':
    case 'MarriedN':
      return 'Married';
    case 'MarriedInMatrimonialTermsOnce':
    case 'MarriedInMatrimonialTermsMoreThanOnce':
    case 'CondMarriedN':
    case 'CondMarried1':
      return 'MarriedWithSettlement';
    case 'WasMarriedOnce':
    case 'WasMarriedMoreThanOnce':
    case 'WidowOrWidower':
    case 'BeenMarried1':
    case 'BeenMarriedN':
      return 'Single';
    case 'Cohabitant':
      return 'LivingTogetherWithContract';
    case 'RegPartnership':
    case 'RegisteredPartnership':
      return 'CivilPartnership';
    case 'MarriedInLimitedCommunityOfGoodsOnce':
    case 'MarriedInLimitedCommunityOfGoodsMoreThanOnce':
      return 'MarriedLimited';
    case 'LimitedMarried1':
    case 'LimitedMarriedN':
      return 'LimitedMarried';
    case 'CondSeparated':
    case 'LimitedSeparated':
    case 'Separated':
      return 'Separated';

    default:
      return maritalStatus;
  }
};
