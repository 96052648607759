import { getOhaById } from 'api/oha';
import { useMutation, useQuery } from 'react-query';
import {
  getMutationApplication,
  getTechnicalProductsForConvertLoan,
  saveMutationApplication,
  submitMutationApplication,
  submitCollateralApplication,
  CollateralData,
} from 'api/mutations';
import { useGroup } from 'use/group';
import { ApplicationReadDto, ConversionMutationApplication } from '__generated__/api';

export function useSaveMutation(onSuccess: (data: any) => void, onError: (error: any) => void) {
  const group = useGroup();

  return useMutation(
    (formData: ConversionMutationApplication) => saveMutationApplication(group as string, formData),
    {
      onSuccess,
      onError,
    }
  );
}

export function useSubmitMutation(onSuccess: (result: any) => void, onError: (error: any) => void) {
  const group = useGroup();
  return useMutation(
    (formData: ApplicationReadDto) => submitMutationApplication(group as string, formData),
    {
      onSuccess,
      onError,
    }
  );
}

export function useSubmitCollateral(
  onSuccess: (result: any) => void,
  onError: (error: any) => void
) {
  const group = useGroup();
  return useMutation(
    (formData: CollateralData) => submitCollateralApplication(formData, group as string),
    {
      onSuccess,
      onError,
    }
  );
}

export const useGetMutationApplication = (id = 0) => {
  const group = useGroup();
  return useQuery(
    ['get-mutation-application', group],
    () => getMutationApplication(id, group as string),
    { enabled: false }
  );
};

export const useOhaMutation = (id = 0) => {
  const group = useGroup();
  return useQuery(['get-oha-by-id', group, id], () => getOhaById(id, group as string), {
    enabled: id !== 0,
  });
};

export const useGetTechnicalProducts = (commercialLabel: string, loanNumber: number) => {
  const group = useGroup();
  return useQuery(['get-technical-products', group, commercialLabel, loanNumber], () =>
    getTechnicalProductsForConvertLoan(group as string, commercialLabel, loanNumber)
  );
};
