import { FC } from 'react';
import { FormInputProps } from '../FormInputProps';
import { NumberFormatValues } from 'react-number-format';
import NumberInputsTemplate from '../NumberInputsTemplate/NumberInputsTemplate';

const TEN_DIGITS = 9999999999;

export interface FormInputNumberProps extends FormInputProps {
  placeholder?: string;
  defaultValue?: string | number;
  decimalScale?: number;
  required?: boolean;
  min?: 0 | 1;
  max?: number;
  fixedDecimalScale?: boolean;
  thousandSeparator?: boolean;
  testId?: string;
}

export const FormInputNumber: FC<FormInputNumberProps> = ({
  name,
  label,
  placeholder,
  required,
  defaultValue,
  disabled,
  min = 1,
  max = TEN_DIGITS,
  decimalScale = 2,
  shouldUnregister = false,
  fixedDecimalScale = false,
  thousandSeparator = false,
  testId,
}) => {
  const validateUsingMaxAndMinProp = (values: NumberFormatValues): boolean => {
    const { floatValue } = values;
    let valid = true;
    valid = valid && (max === undefined || floatValue === undefined || floatValue <= max);
    valid = valid && (min === undefined || floatValue === undefined || floatValue >= min);

    return valid;
  };

  return (
    <NumberInputsTemplate
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
      disabled={disabled}
      decimalScale={decimalScale}
      shouldUnregister={shouldUnregister}
      fixedDecimalScale={fixedDecimalScale}
      thousandSeparator={thousandSeparator}
      testId={testId}
      handleIsAllowed={validateUsingMaxAndMinProp}
    />
  );
};

export default FormInputNumber;
