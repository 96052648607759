import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BtlApplicant, BtlApplication } from 'types/BtlFormApplication';
import { useTranslation } from 'react-i18next';
import TextArea from 'lib/TextArea/TextArea';
import CurrencyField from 'lib/CurrencyField/CurrencyField';
import PercentageField from 'lib/PercentageField/PercentageField';
import ToggleButtonQuestion from 'screens/BuyToLet/BuyToLetForm/form/ToggleButtonQuestion';
import TextInput from 'screens/BuyToLet/BuyToLetForm/form/TextInput';
import BuyToLetCollaterals from './BuyToLetCollaterals';
import { ButToLetDecimalAmountEnabled } from 'config/constants';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: theme.spacing(4),
  },
  description: {
    marginTop: theme.spacing(2),
    color: theme.palette.textBody.main,
  },
  formGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    maxWidth: 600,
  },
}));

export type BuyToLetIncomesAndPropertiesProps = {
  currentApplicantIndex: number;
  application: BtlApplication;
  updateIncomesAndProperties: (data: BtlApplicant, index: number) => void;
  errorMessage: (id: string, category: string) => React.ReactNode;
};

const BuyToLetIncomesAndProperties: FC<BuyToLetIncomesAndPropertiesProps> = ({
  currentApplicantIndex,
  application,
  updateIncomesAndProperties,
  errorMessage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const applicant = application.applicants[currentApplicantIndex];

  const form = 'buyToLetForm.incomesAndProperties';
  const category = 'incomesAndProperties';

  const handleTextChange = (event: any) => {
    const updatedApplicant = {
      ...applicant,
      [event.target.name]: event.target.value,
    };
    updateIncomesAndProperties(updatedApplicant, currentApplicantIndex);
  };

  const handleAccumulatedIncomeTextChange = (event: any) => {
    const accumulatedIncomes = { ...applicant.accumulatedIncomes };
    const updatedApplicant = {
      ...applicant,
      accumulatedIncomes: [
        {
          ...accumulatedIncomes[0],
          [event.target.name]: event.target.value,
        },
      ],
    };
    updateIncomesAndProperties(updatedApplicant, currentApplicantIndex);
  };

  const handleAccumulatedCurrencyChange = (amount: number | null, name: string) => {
    const accumulatedIncomes = { ...applicant.accumulatedIncomes };
    const updatedApplicant = {
      ...applicant,
      accumulatedIncomes: [
        {
          ...accumulatedIncomes[0],
          income: amount === null || isNaN(amount) ? null : amount,
        },
      ],
    };

    updateIncomesAndProperties(updatedApplicant, currentApplicantIndex);
  };

  const handleCurrencyChange = (amount: number | null, name: string) => {
    const updatedApplicant = {
      ...applicant,
      [name]: amount === null || isNaN(amount) ? undefined : amount,
    };

    updateIncomesAndProperties(updatedApplicant, currentApplicantIndex);
  };

  const handleToggleChange = (questionId: string, event: any, value: any) => {
    const val = value === 'yes' ? true : value === 'no' ? false : value;
    const updatedApplicant = {
      ...applicant,
      [questionId]: val,
    };
    // reset value when switch to no false
    switch (questionId) {
      case 'isShareholder':
        if (val === false) {
          updatedApplicant.companyNameShareholdership = undefined;
          updatedApplicant.shareholderPercentage = undefined;
        }
        break;
      case 'isDirector':
        if (val === false) {
          updatedApplicant.companyNameDirectorship = undefined;
        }
        break;
    }
    updateIncomesAndProperties(updatedApplicant, currentApplicantIndex);
  };

  return (
    <form>
      <Typography className={classes.description} variant="body1">
        {t(`${form}.description`)}
      </Typography>
      <Grid container spacing={3} className={classes.formGrid}>
        <Grid item xs={12}>
          <Typography variant="h5">{t(`${form}.financialData`)}</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextArea
            label={t(`${form}.explanation`)}
            required
            name="explanation"
            onChange={handleAccumulatedIncomeTextChange}
            value={
              (applicant.accumulatedIncomes && applicant.accumulatedIncomes[0]
                ? applicant.accumulatedIncomes[0].explanation
                : '') || ''
            }
            placeholder={t(`${form}.explanation`)}
            InputLabelProps={{
              shrink: true,
            }}
            maxRows={8}
            inputProps={{ maxLength: 600, minLength: 5 }}
            charactersLeftThreshold={50}
            error={Boolean(errorMessage('.accumulatedIncomes[0].explanation', category))}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <CurrencyField
            label={t(`${form}.income`)}
            placeholder={t(`${form}.income`)}
            name="income"
            containerWidth={6}
            required
            onChange={(number: number | null) => handleAccumulatedCurrencyChange(number, 'income')}
            allowDecimals={ButToLetDecimalAmountEnabled}
            value={
              applicant.accumulatedIncomes && applicant.accumulatedIncomes[0]
                ? applicant.accumulatedIncomes[0].income
                : null
            }
            error={Boolean(errorMessage('.accumulatedIncomes[0].income', category))}
          />
        </Grid>

        <BuyToLetCollaterals
          category="naturalPerson"
          applicant={applicant}
          handleTextChange={handleTextChange}
          handleCurrencyChange={handleCurrencyChange}
          errorMessage={errorMessage}
        />

        <Grid item xs={12}>
          <Typography variant="h5">{t(`${form}.vastgoedBelang`)}</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <ToggleButtonQuestion
            key="isVastgoedBelangMember"
            required
            label={t(`${form}.isVastgoedBelangMember`)}
            answerOptions={[
              {
                id: 'yes',
                value: true,
                answerTextKey: 'Ja',
              },
              {
                id: 'no',
                value: false,
                answerTextKey: 'Nee',
              },
            ]}
            containerWidth={6}
            exclusive
            value={applicant.isVastgoedBelangMember ?? undefined}
            error={Boolean(errorMessage('.isVastgoedBelangMember', category))}
            onChange={(e, value) => handleToggleChange('isVastgoedBelangMember', e, value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">{t(`${form}.shareholder`)}</Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} sm={12}>
            <ToggleButtonQuestion
              key="isShareholder"
              label={t(`${form}.isShareholder`)}
              required
              toolTip={t(`${form}.isShareholderTooltip`)}
              answerOptions={[
                {
                  id: 'yes',
                  value: true,
                  answerTextKey: 'Ja',
                },
                {
                  id: 'no',
                  value: false,
                  answerTextKey: 'Nee',
                },
              ]}
              containerWidth={6}
              exclusive
              value={applicant.isShareholder ?? undefined}
              error={Boolean(errorMessage('.isShareholder', category))}
              onChange={(e, value) => handleToggleChange('isShareholder', e, value)}
            />
          </Grid>
        </Grid>
        {applicant.isShareholder && (
          <>
            <Grid item xs={12} sm={12}>
              <TextInput
                label={t(`${form}.companyNameShareholdership`)}
                required
                name="companyNameShareholdership"
                onChange={handleTextChange}
                value={applicant.companyNameShareholdership || ''}
                placeholder={t(`${form}.companyNameShareholdership`)}
                inputProps={{ maxLength: 100 }}
                error={Boolean(errorMessage('.companyNameShareholdership', category))}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <PercentageField
                label={t(`${form}.shareholderPercentage`)}
                placeholder={' '}
                required={applicant.isShareholder || false}
                name="shareholderPercentage"
                onChange={(number: number | null) =>
                  handleCurrencyChange(number, 'shareholderPercentage')
                }
                value={applicant.shareholderPercentage}
                error={Boolean(errorMessage('.shareholderPercentage', category))}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Typography variant="h5">{t(`${form}.director`)}</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <ToggleButtonQuestion
            key="isDirector"
            label={t(`${form}.isDirector`)}
            required
            answerOptions={[
              {
                id: 'yes',
                value: true,
                answerTextKey: 'Ja',
              },
              {
                id: 'no',
                value: false,
                answerTextKey: 'Nee',
              },
            ]}
            containerWidth={6}
            exclusive
            value={applicant.isDirector ?? undefined}
            error={Boolean(errorMessage('.isDirector', category))}
            onChange={(e, value) => handleToggleChange('isDirector', e, value)}
          />
        </Grid>
        {applicant.isDirector && (
          <Grid item xs={12} sm={12} style={{ alignItems: 'flex-end' }}>
            <TextInput
              label={t(`${form}.companyNameDirectorship`)}
              required
              name="companyNameDirectorship"
              onChange={handleTextChange}
              value={applicant.companyNameDirectorship || ''}
              placeholder={t(`${form}.companyNameDirectorship`)}
              inputProps={{ maxLength: 100 }}
              error={Boolean(errorMessage('.companyNameDirectorship', category))}
            />
          </Grid>
        )}
      </Grid>
    </form>
  );
};
export default BuyToLetIncomesAndProperties;
