import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { ListItemSecondaryAction, Button, Icon, Hidden, IconButton } from '@mui/material';
import { TaskInfoDelegationState } from '__generated__/api-di';

const useStyles = makeStyles((theme) => ({
  root: {
    top: theme.spacing(2),
    transform: 'none',
    [theme.breakpoints.down('md')]: {
      top: 0,
      right: 0,
    },
  },
  button: {
    width: 'auto',
  },
  icon: {
    transform: 'scale(0.8)',
  },
}));

type TaskButtonsProps = {
  status: TaskInfoDelegationState;
  onClickResolve: () => void;
};

const TaskButtons: React.FC<TaskButtonsProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClickResolve = () => {
    props.onClickResolve();
  };

  switch (props.status) {
    case TaskInfoDelegationState.Pending:
    // Fallthrough
    case TaskInfoDelegationState.InProgress:
      return (
        <ListItemSecondaryAction className={classes.root}>
          <>
            <Hidden mdUp>
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickResolve();
                }}
                size="large"
              >
                <Icon>edit</Icon>
              </IconButton>
            </Hidden>
            <Hidden mdDown>
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickResolve();
                }}
                className={classes.button}
                endIcon={<Icon className={classes.icon}>edit</Icon>}
              >
                {t('tasks.actions.resolve')}
              </Button>
            </Hidden>
          </>
        </ListItemSecondaryAction>
      );
    case TaskInfoDelegationState.Completed:
      return (
        <ListItemSecondaryAction className={classes.root}>
          <>
            <Hidden mdUp>
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickResolve();
                }}
                size="large"
              >
                <AssignmentTurnedInIcon />
              </IconButton>
            </Hidden>
            <Hidden mdDown>
              <Button
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickResolve();
                }}
                className={classes.button}
              >
                {t('tasks.actions.view')}
              </Button>
            </Hidden>
          </>
        </ListItemSecondaryAction>
      );
    case TaskInfoDelegationState.Revoked:
    // Fallthrough
    default:
      return null;
  }
};

export default TaskButtons;
