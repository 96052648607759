import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Hidden, ListItem, List } from '@mui/material';
import { Action, Solution, SolutionState } from '../../store/taskReducer';
import clsx from 'clsx';
import TaskStatusIcon from '../TaskListItem/TaskStatusIcon';
import { ProofItem } from '../../types/ProofItem';
import TaskButtons from './TaskButtons';
import IssueListItem from './IssueListItem';
import {
  Case,
  TaskDetails,
  TaskDetailsIssue,
  TaskInfoDelegationState,
} from '../../__generated__/api-di';

const useStyles = makeStyles((theme) => ({
  root: {
    'display': 'flex',
    'minHeight': 80,
    '&:hover': {
      backgroundColor: theme.palette.white.light,
    },
    'borderRadius': 6,
    'alignItems': 'flex-start',
    'padding': theme.spacing(2),
    'paddingRight': '116px',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 0),
      paddingRight: '26px',
    },
  },
  listItemText: {
    flex: '1 1 auto',
  },
  proofItemName: {
    textTransform: 'capitalize',
  },
  solutions: {
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  item: {
    display: 'block',
  },
}));

interface TaskListItemProps {
  application: Case;
  dispatch: React.Dispatch<Action>;
  issue: TaskDetailsIssue;
  task: TaskDetails;
  proofItems: Array<ProofItem>;
  solutions: Array<Solution>;
  solutionState: SolutionState;
}

const TaskListItem: React.FC<TaskListItemProps> = ({
  application,
  dispatch,
  issue,
  proofItems,
  solutionState,
  solutions,
  task,
}) => {
  const classes = useStyles();

  const taskState = solutions.length > 0 ? TaskInfoDelegationState.Completed : task.delegationState;
  const hasCommentSolution = solutions.some((s) => s.type === 'comment');
  const hasSolvedByOtherIssue = solutions.some((s) => s.type === 'solvedByOther');

  const addComment = () => {
    dispatch({
      type: 'add',
      payload: {
        issueId: issue.id!,
        data: {
          id: uuidv4(),
          type: 'comment',
          data: { comment: '' },
        },
      },
    });
  };

  const addUpload = () => {
    dispatch({
      type: 'add',
      payload: {
        issueId: issue.id!,
        data: {
          id: uuidv4(),
          type: 'addNew',
          data: {
            action: 'AddUpload',
            label: null,
            name: '',
            file: null,
          },
          state: {
            selectedProofItem: null,
            showProofItemSelect: true,
            uploadVisible: false,
          },
        },
      },
    });
  };

  const addSolvedByOther = () => {
    dispatch({
      type: 'add',
      payload: {
        issueId: issue.id!,
        data: {
          id: uuidv4(),
          type: 'solvedByOther',
          data: {},
        },
      },
    });
  };

  const addReUpload = () => {
    dispatch({
      type: 'add',
      payload: {
        issueId: issue.id!,
        data: {
          id: uuidv4(),
          type: 're-upload',
          data: {
            action: 'ReUpload',
            proofItem: null,
            name: '',
            file: null,
          },
          state: {
            showProofItemSelect: true,
            selectedProofItem: null,
            uploadVisible: false,
          },
        },
      },
    });
  };

  return (
    <>
      <div className={classes.root}>
        <Hidden mdDown>
          <TaskStatusIcon status={taskState} />
        </Hidden>
        <div className={classes.listItemText}>
          <Typography>{issue.description}</Typography>
        </div>
      </div>
      <TaskButtons
        status={task.delegationState!}
        commentAvailable={!hasCommentSolution}
        solvedByOtherIssueAvailable={!hasSolvedByOtherIssue}
        onClickAddComment={addComment}
        onClickAddProofItem={addUpload}
        onClickSolvedBytOtherIssue={addSolvedByOther}
        onClickUploadProofItem={addReUpload}
      />

      <List className={clsx([classes.solutions])}>
        {solutions.map((solution) => (
          <ListItem className={clsx([classes.item])}>
            <IssueListItem
              key={solution.id}
              application={application}
              dispatch={dispatch}
              issue={issue}
              proofItems={proofItems}
              solution={solution}
              solutionState={solutionState}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default TaskListItem;
