import { useQuery } from 'react-query';
import { http } from '../http';
import type { NewsItem } from '../__generated__/api';

async function getNewsItems(): Promise<Array<NewsItem>> {
  return http
    .get<Array<NewsItem>>('/api/news-items', { responseType: 'json' })
    .then((response) => {
      return response.data.sort(
        (a, b) => new Date(b.dateCreated!).getTime() - new Date(a.dateCreated!).getTime()
      );
    });
}

export function useQueryNewsItems() {
  return useQuery(['newsItems'], getNewsItems);
}
