import { object, string, number } from 'yup';
import { errorMessages } from '../../../constants';

const getLoanDetailSchema = () =>
  object({
    loanNumber: string().required(errorMessages.required),
    toggleInterest: string().required(errorMessages.required).typeError(errorMessages.required),
    toggleFine: string().when('toggleInterest', {
      is: (val: any) => val === 'yes',
      then: string().required(errorMessages.required).typeError(errorMessages.required),
    }),
    toggleCoFinancedFine: string().when('toggleFine', {
      is: (val: any) => val === 'yes',
      then: string().required(errorMessages.required).typeError(errorMessages.required),
    }),
    residualDebt: number().typeError(errorMessages.number).required(errorMessages.required),
  });

export default getLoanDetailSchema;
