import { AxiosError } from 'axios';
import { AdvisorOffices } from 'types/AdvisorOffices';
import { http } from '../http';

export const ADVISORS_OFFICES_API_URL = '/api/v1/advisor/search/offices';

async function postAdvisorOffices(namepart: string, kvkNumber: string): Promise<AdvisorOffices> {
  try {
    const response = await http.post(`${ADVISORS_OFFICES_API_URL}`, { kvkNumber, namepart });
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    const axiosError = error.response;
    let errorMessage = '';

    if (axiosError?.data.errors?.KvkNumber) {
      errorMessage = axiosError?.data.errors.KvkNumber[0];
    }

    if (axiosError?.data.errors?.namepart) {
      errorMessage = axiosError?.data.errors.namepart[0];
    }
    throw new Error(errorMessage, { cause: axiosError?.data.status });
  }
}

export default postAdvisorOffices;
