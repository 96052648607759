import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { BtlRealEstate } from 'types/BtlFormApplication';
import formatDate from 'utils/formatDate';
import CurrencyFormat from 'screens/Applications/components/ApplicationNew/CurrencyFormat';

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    '& td': {
      borderBottom: 'none',
      wordBreak: 'break-all',
    },
    '&.error': {
      'backgroundColor': theme.palette.danger.light,
      '& :last-child': {
        color: theme.palette.danger.main,
      },
    },
  },
  errorIcon: {
    'float': 'right',
    'display': 'none',
    '.error &': {
      display: 'block',
    },
  },
  loanPartTitle: {
    marginBottom: theme.spacing(2),
  },
}));

type BuyToLetNewlyBuildSummaryProps = {
  realEstate: BtlRealEstate;
  realEstateNr: number;
  onClickField: (field: string) => void;
  errorMessage: (id: string, category: string) => void;
};

const BuyToLetNewlyBuildSummary: FC<BuyToLetNewlyBuildSummaryProps> = ({
  realEstate,
  realEstateNr,
  onClickField,
  errorMessage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const form = 'buyToLetForm.newRealEstate';

  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].typeOfCollateral`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.typeOfCollateral`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('typeOfCollateral')}>
            {realEstate.typeOfCollateral ? t(`${form}.${realEstate.typeOfCollateral}`) : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].typeOfRealEstate`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.typeOfRealEstate`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('typeOfRealEstate')}>
            {realEstate.typeOfRealEstate
              ? t(`${form}.realEstateTypes.${realEstate.typeOfRealEstate}`)
              : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].energyClass`, 'realEstates')),
          })}
        >
          <TableCell>{t(`${form}.energyClass`)}</TableCell>
          <TableCell onClick={() => onClickField('energyClass')}>
            {t(`buyToLetForm.newRealEstate.energyClasses.${realEstate.energyClass}`)}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        {realEstate.postalCode !== null && (
          <>
            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(errorMessage(`[${realEstateNr}].street`, 'realEstates')),
              })}
            >
              <TableCell>
                {t(`${form}.street`)}
                {realEstate.postalCode !== null && <span className="required">*</span>}
              </TableCell>
              <TableCell onClick={() => onClickField('street')}>
                {realEstate.street}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>
            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(errorMessage(`[${realEstateNr}].houseNumber`, 'realEstates')),
              })}
            >
              <TableCell>
                {t(`${form}.houseNumber`)}
                {realEstate.postalCode !== null && <span className="required">*</span>}
              </TableCell>
              <TableCell onClick={() => onClickField('houseNumber')}>
                {realEstate.houseNumber}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>
            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(
                  errorMessage(`[${realEstateNr}].houseNumberExtension`, 'realEstates')
                ),
              })}
            >
              <TableCell>{t(`${form}.houseNumberExtension`)}</TableCell>
              <TableCell onClick={() => onClickField('houseNumberExtension')}>
                {realEstate.houseNumberExtension}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>
            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(errorMessage(`[${realEstateNr}].postalCode`, 'realEstates')),
              })}
            >
              <TableCell>
                {t(`${form}.postalCode`)}
                {realEstate.postalCode !== null && <span className="required">*</span>}
              </TableCell>
              <TableCell onClick={() => onClickField('postalCode')}>
                {realEstate.postalCode}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>
          </>
        )}
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].city`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.city`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('city')}>
            {realEstate.city}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        {realEstate.postalCode === null && (
          <>
            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(errorMessage(`[${realEstateNr}].buildingPlanNumber`, 'realEstates')),
              })}
            >
              <TableCell>
                {t(`${form}.buildingPlanNumber`)} <span className="required">*</span>
              </TableCell>
              <TableCell onClick={() => onClickField('buildingPlanNumber')}>
                {realEstate.buildingPlanNumber}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>

            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(errorMessage(`[${realEstateNr}].situationNumber`, 'realEstates')),
              })}
            >
              <TableCell>
                {t(`${form}.situationNumber`)} <span className="required">*</span>
              </TableCell>
              <TableCell onClick={() => onClickField('situationNumber')}>
                {realEstate.situationNumber}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>
          </>
        )}
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].deliveryDate`, 'realEstates')),
          })}
        >
          <TableCell>{t(`${form}.deliveryDate`)}</TableCell>
          <TableCell onClick={() => onClickField('deliveryDate')}>
            {realEstate.deliveryDate && formatDate(realEstate.deliveryDate)}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].isGroundInPossession`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.isGroundInPossession`)} <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('isGroundInPossession')}>
            {realEstate.isGroundInPossession === false
              ? t(`${form}.no`)
              : realEstate.isGroundInPossession === true
              ? t(`${form}.yes`)
              : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        {realEstate.isGroundInPossession && (
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage(`[${realEstateNr}].purchasePriceGround`, 'realEstates')),
            })}
          >
            <TableCell>
              {t(`${form}.purchasePriceGround`)} <span className="required">*</span>
            </TableCell>
            <TableCell onClick={() => onClickField('purchasePriceGround')}>
              {realEstate.purchasePriceGround && (
                <CurrencyFormat value={realEstate.purchasePriceGround} />
              )}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
        )}
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].additionalWorkAmount`, 'realEstates')),
          })}
        >
          <TableCell>{t(`${form}.additionalWorkAmount`)}</TableCell>
          <TableCell onClick={() => onClickField('additionalWorkAmount')}>
            {!!realEstate.additionalWorkAmount && (
              <CurrencyFormat value={realEstate.additionalWorkAmount} />
            )}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].hasLeasehold`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.hasLeasehold`)} <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('hasLeasehold')}>
            {realEstate.hasLeasehold === false
              ? t(`${form}.no`)
              : realEstate.hasLeasehold === true
              ? t(`${form}.yes`)
              : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        {realEstate.hasLeasehold && (
          <>
            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(errorMessage(`[${realEstateNr}].leaseholdAmount`, 'realEstates')),
              })}
            >
              <TableCell>{t(`${form}.leaseholdAmount`)}</TableCell>
              <TableCell onClick={() => onClickField('leaseholdAmount')}>
                {!!realEstate.leaseholdAmount && (
                  <CurrencyFormat value={realEstate.leaseholdAmount} />
                )}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>

            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(errorMessage(`[${realEstateNr}].typeOfLeasehold`, 'realEstates')),
              })}
            >
              <TableCell>
                {t(`${form}.typeOfLeasehold`)} <span className="required">*</span>
              </TableCell>
              <TableCell onClick={() => onClickField('typeOfLeasehold')}>
                {realEstate.typeOfLeasehold}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>
          </>
        )}

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].nameOfTenant`, 'realEstates')),
          })}
        >
          <TableCell>{t(`${form}.nameOfTenant`)}</TableCell>
          <TableCell onClick={() => onClickField('nameOfTenant')}>
            {realEstate.nameOfTenant}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(
              errorMessage(`[${realEstateNr}].basicAnnualRentalIncome`, 'realEstates')
            ),
          })}
        >
          <TableCell>{t(`${form}.basicAnnualRentalIncome`)}</TableCell>
          <TableCell onClick={() => onClickField('basicAnnualRentalIncome')}>
            {!!realEstate.basicAnnualRentalIncome && (
              <CurrencyFormat value={realEstate.basicAnnualRentalIncome} />
            )}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].rentalValue`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.rentalValue`)} <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('rentalValue')}>
            {!!realEstate.rentalValue && <CurrencyFormat value={realEstate.rentalValue} />}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].marketValue`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.marketValue`)} <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('marketValue')}>
            {!!realEstate.marketValue && <CurrencyFormat value={realEstate.marketValue} />}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].marketValue`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.isRoomRental`)} <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('isRoomRental')}>
            {realEstate.isRoomRental === false
              ? t(`${form}.no`)
              : realEstate.isRoomRental === true
              ? t(`${form}.yes`)
              : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default BuyToLetNewlyBuildSummary;
