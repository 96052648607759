import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import { enableMapSet } from 'immer';

enableMapSet();

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_API === 'true') {
  const { worker } = require('./mocks/browser');
  // see options: https://mswjs.io/docs/api/setup-worker/start
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

const render = () => {
  const App = require('./App').default; // NOSONAR
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render);
}
