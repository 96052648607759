import React, { useState } from 'react';
import { BaseTextFieldProps, InputBaseComponentProps, InputProps, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { FormField } from '../../screens/BuyToLet/BuyToLetForm/form/FormField';
import { Grid, GridSize } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  disabled: {
    background: theme.palette.grey[200],
  },
  input: {
    '& input': {
      padding: 15,
    },
  },
}));

interface NumberFormatCustomProps extends InputBaseComponentProps {
  name?: string;
  onChange?: (value: any) => void;
  min?: number;
  max?: number;
}

const NumberFormatCustom: React.FC<NumberFormatCustomProps> = (props) => {
  const { inputRef, onChange, min, max, ...other } = props;

  const validateInput = (values: NumberFormatValues) => {
    const { floatValue } = values;
    let valid = true;
    valid = valid && (max === undefined || floatValue === undefined || floatValue <= max);
    valid = valid && (min === undefined || floatValue === undefined || floatValue >= min);
    return valid;
  };

  const handleChange = (values: NumberFormatValues) => {
    if (onChange) {
      onChange({
        target: {
          name: props.name ?? '',
          value: values.value,
        },
      });
    }
  };

  return (
    <NumericFormat
      {...other}
      max={max}
      defaultValue=""
      valueIsNumericString
      getInputRef={inputRef}
      isAllowed={validateInput}
      onValueChange={handleChange}
      decimalScale={0}
      allowLeadingZeros={false}
      allowedDecimalSeparators={[]}
    />
  );
};

export interface ChangeIntegerNumberFieldProps extends BaseTextFieldProps {
  onBlur?: InputProps['onBlur'];
  onFocus?: InputProps['onFocus'];
  onChange?: InputProps['onChange'];
  min?: number;
  max?: number;
  pattern?: string;
  allowNegative?: boolean;
  containerWidth?: GridSize;
  textAlign?: 'left' | 'right';
}

const IntegerNumberField: React.FC<ChangeIntegerNumberFieldProps> = (props) => {
  const {
    label,
    value,
    placeholder,
    name,
    disabled,
    onChange,
    onFocus,
    onBlur,
    error,
    required,
    containerWidth,
    min,
    max,
    pattern,
    allowNegative,
    textAlign = 'right',
    ...other
  } = props;
  const classes = useStyles();
  const placeholderText = placeholder || label?.toString();

  const [focused, setFocused] = useState<boolean>(false);

  return (
    <FormField label={label} required={required} open className={focused ? 'Mui-focused' : ''}>
      <Grid container item spacing={3}>
        <Grid item container xs={containerWidth || 6}>
          <TextField
            {...other}
            type="text"
            style={{ marginTop: '0' }}
            className={classes.input}
            label={null}
            hiddenLabel
            name={name}
            disabled={disabled}
            placeholder={placeholderText}
            value={
              value === null || value === '' || value === undefined ? '' : parseInt(`${value}`)
            }
            error={error}
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={onChange}
            onFocus={(event) => {
              setFocused(true);
              if (onFocus) {
                onFocus(event);
              }
            }}
            onBlur={(event) => {
              setFocused(false);
              if (onBlur) {
                onBlur(event);
              }
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              classes: { disabled: classes.disabled },
            }}
            inputProps={{
              min: min ?? 0,
              max: max,
              pattern: pattern,
              allowNegative: allowNegative ?? false,
              style: { textAlign: textAlign },
              autoComplete: 'off',
            }}
          />
        </Grid>
      </Grid>
    </FormField>
  );
};

export default IntegerNumberField;
