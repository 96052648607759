import * as Yup from 'yup';
import { toNestError, validateFieldsNatively } from '@hookform/resolvers';
import { appendErrors, FieldError } from 'react-hook-form';
import { Resolver } from '@hookform/resolvers/yup';

const parseErrorSchema = (error: Yup.ValidationError, validateAllFieldCriteria: boolean) => {
  return (error.inner || []).reduce<Record<string, FieldError>>((previous, error) => {
    if (!previous[error.path!]) {
      previous[error.path!] = { message: error.message, type: error.type! };
    }

    if (validateAllFieldCriteria) {
      const types = previous[error.path!].types;
      const messages = types && types[error.type!];

      previous[error.path!] = appendErrors(
        error.path!,
        validateAllFieldCriteria,
        previous,
        error.type!,
        messages ? ([] as string[]).concat(messages as string[], error.message) : error.message
      ) as FieldError;
    }

    return previous;
  }, {});
};

// This function is based the yupResolver from @hookform/resolvers
// https://github.com/react-hook-form/resolvers/blob/master/yup/src/yup.ts
const yupValidateAtResolver =
  (path: string): Resolver =>
  (schema, schemaOptions = {}, resolverOptions = {}) =>
  async (values, context, options) => {
    try {
      if (schemaOptions.context && process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn(
          "You should not used the yup options context. Please, use the 'useForm' context object instead"
        );
      }

      // this line is different from the original yup resolver
      // we can use validateAt to pass a path
      const result = await schema.validateAt(path, values, {
        abortEarly: false,
        ...schemaOptions,
        context,
      });

      options.shouldUseNativeValidation && validateFieldsNatively({}, options);

      return {
        values: resolverOptions.rawValues ? values : result,
        errors: {},
      };
    } catch (e: any) {
      if (!e.inner) {
        throw e;
      }

      return {
        values: {},
        errors: toNestError(
          parseErrorSchema(
            e,
            !options.shouldUseNativeValidation && options.criteriaMode === 'all'
          ) as any,
          options
        ),
      };
    }
  };

export default yupValidateAtResolver;
