import { LoginType } from '../types';

const getClientId = (loginType: LoginType) => {
  const KEYCLOAK = (window as any).REACT_APP?.KEYCLOAK ?? {};

  if (loginType === 'keycloak-observer') return KEYCLOAK.CLIENT_OBSERVER_ID;
  if (loginType === 'keycloak-dummy') return KEYCLOAK.CLIENT_TEST_ID;

  return KEYCLOAK.CLIENT_ID;
};

export default getClientId;
