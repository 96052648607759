import ReactHtmlParser from 'react-html-parser';

const replace = (html: string, find: string, replacement: string): string => {
  while (html.indexOf(find) > -1) {
    html = html.replace(find, replacement);
  }
  return html;
};

export const parseHtml = (content: string | null | undefined) => {
  if (content === undefined) return undefined;
  if (content === null) return null;

  const setTargetBlank = (html: string) => {
    return replace(
      html,
      '<a href="http',
      '<a target="_blank" rel="noreferrer noopener" href="http'
    );
  };
  content = setTargetBlank(content);
  return ReactHtmlParser(content);
};
