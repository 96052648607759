import { useEffect, useState } from 'react';
import { useQueryAllCases } from 'use/cases';
import { googleTagManager } from 'use/gtm';
import type { Notification } from '__generated__/api';

export function useNotificationFilter(
  notifications: Notification[] | undefined,
  status: string,
  isFetching: boolean
) {
  const [filter, setFilter] = useState('all');
  const [filteredNotifications, setFilteredNotifications] = useState(notifications);
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (e: any) => {
    setSearchTerm(e.currentTarget.value);
    googleTagManager({
      event: 'Search',
      searchTerm: searchTerm,
    });
  };
  const emptySearch = () => setSearchTerm('');
  const applications = useQueryAllCases();

  useEffect(() => {
    if (!isFetching) {
      const belongsToApplication = (loanNumber: number) => {
        return applications.data?.some(
          (application) => application.application?.loanNumberExternal === loanNumber
        );
      };
      setFilteredNotifications(() =>
        notifications
          ?.filter((notification: Notification) => {
            if (!searchTerm) {
              return true;
            }
            return (
              notification.consumerName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              notification.loanNumber?.toLocaleString().includes(searchTerm.replace(/\./g, ''))
            );
          })
          .filter((notification: Notification) => {
            const loanNumber =
              notification.loanNumber !== null && notification.loanNumber !== undefined
                ? Number.parseInt(notification.loanNumber, 10)
                : -1;

            if (filter === 'applications') {
              return belongsToApplication(loanNumber);
            }
            if (filter === 'liveMortgages') {
              return loanNumber !== -1 && !belongsToApplication(loanNumber);
            }
            return true;
          })
          .sort(
            (a: Notification, b: Notification) =>
              (new Date(b.dateCreated) as any) - (new Date(a.dateCreated) as any)
          )
      );
    }
  }, [searchTerm, filter, status, isFetching, notifications, applications.data]);

  const handleFilterChange = (value: string) => {
    setFilter(value);
  };

  return {
    filter,
    searchTerm,
    filteredNotifications,
    handleSearch,
    emptySearch,
    handleFilterChange,
  };
}
