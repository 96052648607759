import { useQuery } from 'react-query';
import { http } from '../http';
import type { Profile } from '__generated__/api';

async function getProfiles(): Promise<Array<Profile>> {
  return http
    .get<Array<Profile>>('/api/profiles', { responseType: 'json' })
    .then((response) => {
      return response.data;
    });
}

export function useQueryProfiles() {
  return useQuery(['profiles'], getProfiles, { staleTime: Infinity });
}
