// TODO: Merge this component with the shared BottomNavigation
import { Box as MUIBox, Fab, Icon as MUIIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingFab } from 'components/LoadingButton';

export const Box = styled(MUIBox)(({ theme }) => ({
  position: 'fixed',
  [theme.breakpoints.up('sm')]: {
    right: theme.spacing(8),
  },
  right: theme.spacing(1),
  bottom: theme.spacing(4),
  display: 'flex',
}));

export const PreviousButton = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  left: theme.spacing(3),
  bottom: theme.spacing(4),
  [theme.breakpoints.up('lg')]: {
    left: theme.spacing(49),
    bottom: theme.spacing(4),
  },
}));

export const SubmitButton = styled(LoadingFab)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

export const SummaryButton = styled(Fab)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(0),
  },
  minWidth: 150,
}));

export const Icon = styled(MUIIcon)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));
