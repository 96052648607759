import { FC, ReactNode } from 'react';
import { UseWizard, useWizard, WizardContext } from './hooks';

type Props = {
  children: ((useWizard: UseWizard) => ReactNode) | ReactNode;
  initialStepIndex?: number;
};

const Wizard: FC<Props> = ({ initialStepIndex, children }: Props) => {
  const internalState = useWizard({
    initialStepIndex,
  });

  return (
    <WizardContext.Provider value={internalState}>
      {typeof children === 'function' ? children(internalState) : children}
    </WizardContext.Provider>
  );
};

export default Wizard;
