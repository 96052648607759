import { FC, useState } from 'react';
import { ZoomIn, ZoomOut } from '@mui/icons-material';

import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { googleTagManager } from 'use/gtm';

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    'margin': theme.spacing(0, 1, 0, 1),
    'color': theme.palette.grey[600],
    '&:hover': {
      color: theme.palette.grey[900],
    },
  },
}));

type Props = {
  onZoom: (scale: number) => void;
};

const Zoom: FC<Props> = ({ onZoom }) => {
  const classes = useStyles();
  const [scaleIndex, setScaleIndex] = useState(4);
  const scales = [10, 25, 50, 75, 100, 125, 150, 200, 400, 800];
  const scale = scales[scaleIndex];

  const zoomOut = () => {
    if (scaleIndex > 0) {
      setScaleIndex(scaleIndex - 1);
      onZoom(scales[scaleIndex - 1]);
    }
    googleTagManager({ event: 'ButtonClick', btnName: 'ZoomIn' });
  };

  const zoomIn = () => {
    if (scaleIndex < scales.length - 1) {
      setScaleIndex(scaleIndex + 1);
      onZoom(scales[scaleIndex + 1]);
    }
    googleTagManager({ event: 'ButtonClick', btnName: 'ZoomOut' });
  };

  return (
    <>
      <ZoomOut className={classes.headerIcon} onClick={zoomOut} />
      <Typography variant="body2">{`${scale}%`}</Typography>
      <ZoomIn className={classes.headerIcon} onClick={zoomIn} />
    </>
  );
};

export default Zoom;
