import { SummaryCard, CardRow, CardSection } from 'components/SummaryCard';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { typeOfObligation } from 'screens/Mutations/constants';
import { Obligation as ObligationType } from 'screens/Mutations/utils/types';
import { formatAmount } from 'utils/formatAmount';
import { formatYesNo } from 'utils/formatYesNo';
import formatDate from 'utils/formatDate';
import { TypeOfObligation } from '__generated__/api';
import { formatPercentage } from 'utils/formatPercentage';
import { formatDuration } from 'utils/formatDuration';

type ObligationProps = {
  obligation: ObligationType;
  onClick: () => void;
};

const Obligation: FC<ObligationProps> = ({ obligation, onClick }) => {
  const { t } = useTranslation();
  const root = 'directMutations.obligation';

  const isExistingMortgage = obligation.typeOfObligation === TypeOfObligation.ExistingMortgage;

  return (
    <>
      <SummaryCard title={t(`${root}.formTitle`)} onEditClick={onClick}>
        <CardSection>
          <CardRow
            name={t(`${root}.typeOfObligation`)}
            value={typeOfObligation(obligation.typeOfObligation).label}
          />

          {!isExistingMortgage && (
            <CardRow
              name={t(`${root}.isObligationByBKR`)}
              value={formatYesNo(obligation.isObligationByBKR)}
            />
          )}
          <CardRow
            name={t(`${root}.principalDebt`)}
            value={formatAmount(obligation.principalDebt)}
          />

          <CardRow name={t(`${root}.actualDebt`)} value={formatAmount(obligation.actualDebt)} />

          <CardRow
            name={t(`${root}.obligationAmount`)}
            value={formatAmount(obligation.obligationAmount)}
          />

          <CardRow
            name={t(`${root}.partialRedemptionByPersonalEquity`)}
            value={formatYesNo(obligation.partialRedemptionByPersonalEquity)}
          />
          <CardRow
            name={t(`${root}.redemptionByNewMortgage`)}
            value={formatYesNo(obligation.redemptionByNewMortgage)}
          />
          <CardRow
            name={t(`${root}.repayableAmount`)}
            value={formatAmount(obligation.repayableAmount)}
          />
          {isExistingMortgage && (
            <>
              <CardRow
                name={t(`${root}.typeOfRedemption`)}
                value={obligation.redemptionTypeDisplayName}
              />
              <CardRow
                name={t(`${root}.startDate`)}
                value={formatDate(obligation.startDate, '-')}
              />
              <CardRow name={t(`${root}.endDate`)} value={formatDate(obligation.endDate, '-')} />
              <CardRow
                name={t(`${root}.fixedInterestPeriod`)}
                value={formatDuration(obligation.fixedInterestPeriod)}
              />
              <CardRow
                name={t(`${root}.interestPercentage`)}
                value={formatPercentage(obligation.interestPercentage)}
              />
              <CardRow
                name={t(`${root}.amountTaxBox1`)}
                value={formatAmount(obligation.amountTaxBox1)}
              />
              <CardRow
                name={t(`${root}.endDateInterestDeduction`)}
                value={formatDate(obligation.endDateInterestDeduction, '-')}
              />
              <CardRow
                name={t(`${root}.amountTaxBox3`)}
                value={formatAmount(obligation.amountTaxBox3)}
              />
              <CardRow name={t(`${root}.registryRank`)} value={obligation.registryRank} />
            </>
          )}
        </CardSection>
      </SummaryCard>
    </>
  );
};

export default Obligation;
