import { styled } from '@mui/material/styles';
import { Box as MuiBox } from '@mui/material';

const Box = styled(MuiBox)(({ theme }) => ({
  width: '100%',
  maxWidth: '1232px', // 1040px + padding
  padding: theme.spacing(4, 12, 16),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(4, 8, 16),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4, 4, 16),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 2, 16),
  },
}));

export { Box };
