import FormTitleText from 'components/Form/FormTitle';
import { FC, useCallback, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DropDownButton, DeleteButton, DeleteOutline } from './Income.styled';
import { MenuItem, Grid } from '@mui/material';
import { getTypeOfIncomeOptions } from 'screens/Mutations/constants';
import TemplateIncomeField from 'screens/Mutations/templates/IncomeList/Income';
import { findApplicantLength } from '../../utils';
import { TypeOfIncome } from 'screens/Mutations/utils/types';
import { ConfirmationDialog } from 'components/CustomDialog';
import TotalIncome from 'screens/Mutations/templates/IncomeList/components/TotalIncome';

type Props = {
  index?: number;
};
const typeOfIncomes = getTypeOfIncomeOptions();

const Income: FC<Props> = ({ index = 0 }) => {
  const { t } = useTranslation();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [selectedIncome, setSelectedIncome] = useState<number>();
  const { watch, control } = useFormContext();
  const applicants = watch('applicants');
  const applicant = watch(`applicants.${index}`);

  const rootFormName = `applicants.${index}.income.`;
  const { fields, append, remove } = useFieldArray({
    name: `applicants.${index}.income.incomes`,
    control,
  });

  const handleAddIncome = (value: TypeOfIncome) => {
    append({
      typeOfIncome: value,
      [value.toLowerCase()]: { typeOfCompany: undefined },
    });
  };
  const closeDialog = useCallback(() => setDialogIsOpen(false), []);

  const handleDeleteIncome = useCallback(
    (index: number) => () => {
      setDialogIsOpen(true);
      setSelectedIncome(index);
    },
    []
  );
  const handleConfirmationDialog = () => {
    remove(selectedIncome);
    setSelectedIncome(undefined);
    closeDialog();
  };

  if (!applicant || !applicant.personalInfo) return null;

  const applicationTitle = applicant.personalInfo.surname
    ? `${applicant.personalInfo.firstNames ?? ''} ${applicant.personalInfo.middleName ?? ''} ${
        applicant.personalInfo.surname
      }`
    : t('directMutations.stepper.applicant');

  return (
    <section data-testid="personal-info" key={index}>
      <FormTitleText title={applicationTitle} description={t('directMutations.income.subtitle')} />
      <Grid container>
        <Grid item xs={12}>
          {fields.map(({ id }, incomeIndex) => (
            <TemplateIncomeField
              key={id}
              incomeIndex={incomeIndex}
              isInputDropdown={false}
              rootFormName={rootFormName}
              shouldUnregister={false}
            >
              {findApplicantLength(applicants) > 1 && (
                <DeleteButton
                  onClick={handleDeleteIncome(incomeIndex)}
                  size="medium"
                  data-testid="btn-delete-income"
                >
                  {t('directMutations.buttons.deleteButton')}
                  <DeleteOutline />
                </DeleteButton>
              )}
            </TemplateIncomeField>
          ))}
        </Grid>
        <Grid item xs={12} py={3}>
          <DropDownButton
            label={t('directMutations.buttons.addIncomeButton')}
            data-testid="addIncomeButton"
          >
            {typeOfIncomes.map(({ label, value }) => (
              <MenuItem
                key={value}
                data-testid={value}
                onClick={() => handleAddIncome(value as TypeOfIncome)}
              >
                {t(label)}
              </MenuItem>
            ))}
          </DropDownButton>
        </Grid>
        <Grid item xs={12}>
          {fields.length > 0 && <TotalIncome rootFormName={rootFormName} />}
        </Grid>
      </Grid>
      <ConfirmationDialog
        isOpen={dialogIsOpen}
        onConfirmation={handleConfirmationDialog}
        onDecline={closeDialog}
        onClose={closeDialog}
        confirmationText={t('directMutations.buttons.deleteButton')}
        declineText={t('directMutations.buttons.cancelButton')}
        bodyText={t('directMutations.confirmDelete.bodyIncome')}
      />
    </section>
  );
};

export default Income;
