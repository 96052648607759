import { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TitleBar from '../TitleBar';
import BottomNavigation from '../BottomNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { RootState } from 'store';
import { saveAdditionalInformation } from 'store/ohaFormReducer';
import { useOhaFormStep } from 'screens/Mutations/hooks/useOhaFormStep';
import { mapOHAForm } from 'screens/Mutations/utils/mappers/oha/mapFormToApi';
import { AdditionalInfoFormType, FormStepProps } from 'screens/Mutations/utils/types';
import FormTemplate from 'components/Templates/FormTemplate';
import FormStepper from '../FormStepper';
import { Form } from 'components/Molecules/components/Form/Form.styled';
import AdditionalInformation from 'screens/Mutations/templates/AdditionalInformation';
import { additionalInformationSchema } from 'screens/Mutations/schemas/additionalInformationSchema';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

const AdditionalInformationForm: FC<FormStepProps> = ({ title, subtitle }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [snackbar, setSnackbar] = useState<{ status: string; name: string } | undefined>();
  const ohaFormState = useSelector((state: RootState) => state.ohaForm);

  const formMethods = useForm({
    defaultValues: { ...ohaFormState.additionalInformation },
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(additionalInformationSchema),
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = formMethods;

  const additionalInformation: AdditionalInfoFormType = watch();

  const completeForm = {
    ...ohaFormState,
    additionalInformation,
  };

  const onSaveError = () => handleNotification('error', 'submit');

  const { save, isSaving, goToNextStep } = useOhaFormStep({ onSaveError });

  const onSubmit = async () => {
    dispatch(saveAdditionalInformation(additionalInformation));

    if (isDirty) {
      await save(mapOHAForm(completeForm));
    }
    goToNextStep();
  };

  const dispatchForm = useCallback(() => {
    dispatch(saveAdditionalInformation(additionalInformation));
  }, [additionalInformation, dispatch]);

  const handleNotification = useCallback(
    (status: string, name: string) => setSnackbar({ status, name }),
    []
  );

  const handleOnClose = useCallback(() => setSnackbar(undefined), []);
  return (
    <>
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormTemplate
            header={
              <TitleBar
                onResult={handleNotification}
                formState={completeForm}
                resetForm={reset}
                isFormDirty={isDirty}
                dispatchCurrentForm={dispatchForm}
              />
            }
            sidebar={<FormStepper />}
            footer={
              <BottomNavigation dispatchCurrentForm={dispatchForm} isLoadingSubmit={isSaving} />
            }
          >
            <AdditionalInformation title={title} subtitle={subtitle} />
          </FormTemplate>
        </Form>
      </FormProvider>
      {snackbar && (
        <CustomSnackbar
          isOpen={!!snackbar}
          handleClose={handleOnClose}
          severity={snackbar.status === 'success' ? 'success' : 'error'}
          message={t(`directMutations.snackbar.${snackbar.name}.${snackbar.status}`)}
        />
      )}
    </>
  );
};

export default AdditionalInformationForm;
