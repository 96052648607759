import { Box, Grid, Typography, Button } from '@mui/material';
import { logout } from 'auth/oidc';

function ErrorComponent({ message, isClockError }: { message: string; isClockError?: boolean }) {
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <Grid
        sx={{
          padding: '32px',
          borderRadius: 6,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column',
          boxShadow: 2,
        }}
      >
        <Box justifyItems="center" mb={4}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Er is een fout opgetreden bij het inloggen
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {message}
          </Typography>
        </Box>
        <Box>
          {isClockError ? (
            <Button
              sx={{
                marginRight: '10px',
              }}
              href="ms-settings:dateandtime?activationSource=SMC-IA-4026213"
              color="primary"
              variant="contained"
            >
              Datum- en tijdinstellingen openen
            </Button>
          ) : null}

          <Button color="primary" variant="contained" onClick={logout}>
            Logout
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ErrorComponent;
