import { useEffect, useState } from 'react';

export function useDebounce(searchTerm: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(searchTerm);
  useEffect(() => {
    const timer = setTimeout(() => {
      // don't keep searching while typing
      setDebouncedValue(searchTerm);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay, searchTerm]);

  return debouncedValue;
}
