import type { FC, MouseEventHandler } from 'react';
import { CircularProgress, FabProps } from '@mui/material';
import { FabButton } from './LoadingButton.styled';

interface LoadingFabProps extends FabProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  styleType?: string;
  testid?: string;
  disabled?: boolean;
}

export const LoadingFab: FC<LoadingFabProps> = ({
  className,
  onClick,
  isLoading,
  children,
  type,
  color = 'primary',
  size = 'medium',
  variant = 'extended',
  styleType = 'button',
  testid,
  disabled = false,
}) => {
  const SpinnerAdornment = () => <CircularProgress size={20} />;

  const loadingFab = (
    <FabButton
      className={className}
      type={type}
      color={color}
      size={size}
      variant={variant}
      onClick={onClick}
      disabled={isLoading || disabled}
      styletype={styleType}
      data-testid={testid}
    >
      {isLoading ? null : children}
      {isLoading && <SpinnerAdornment />}
    </FabButton>
  );

  return loadingFab;
};
