import React, { ReactNode, FC, useEffect } from 'react';
import ExitWithoutSavingPopup from '.';

type Props = {
  askClose?: boolean;
  pristine?: boolean;
  onContinue: () => void;
  onChange?: () => void;
  onCancel: () => void;
  children: ReactNode | Array<ReactNode>;
};

const ExitWithoutSavingWrapper: FC<Props> = ({
  askClose = false,
  pristine = true,
  onContinue,
  onChange,
  onCancel,
  children,
}) => {
  const [popupVisible, setPopupVisible] = React.useState(false);

  const onContinueAndExit = () => {
    setPopupVisible(false);
    onContinue();
  };

  const onCloseSavePopup = () => {
    setPopupVisible(false);
    onCancel();
  };

  const onCloseWrapper = () => {
    if (pristine) {
      onContinue();
    } else {
      setPopupVisible(true);
    }
  };

  useEffect(() => {
    if (askClose === true) {
      onCloseWrapper();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [askClose]);

  return (
    <div onChange={onChange} style={{ height: '100vh' }}>
      {children}
      <ExitWithoutSavingPopup
        open={popupVisible}
        pristine={pristine}
        onContinue={onContinueAndExit}
        onCancel={onCloseSavePopup}
      />
    </div>
  );
};

export default ExitWithoutSavingWrapper;
