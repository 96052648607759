import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormStepper from './FormStepper';
import { FormProvider, useForm } from 'react-hook-form';
import TitleBar from 'components/TitleBar';
import { conversionRaiseResolver, getStep, getSteps, navigator } from './utils';
import { useWizardStep } from 'hooks/useWizard';
import FormTemplate from 'components/Templates/FormTemplate';
import { RaiseForm, Step } from './types';
import { useTranslation } from 'react-i18next';
import Wizard from 'components/Wizard';
import { Form as StyledForm } from 'components/Molecules/components/Form/Form.styled';
import BottomNavigation from 'components/Molecules/components/BottomNavigation';
import useMutationForm from 'screens/Mutations/hooks/useMutationForm';
import { ButtonText, Spinner } from 'screens/Mutations/molecules/styled';
import { CircularProgress } from '@mui/material';
import Applicants from './Applicants';
import { raiseFormDefaultValues } from './constants';
import PersonalInfo from './Applicants/PersonalInfo';
import { LoadingIconButton } from '../shared/LoadingButton';
import { DeleteOutlined, SaveOutlined } from '@mui/icons-material';
import Collateral from './Collateral';
import Income from './Applicants/Income/Income';
import ContactDetails from './Applicants/ContactDetails';
import Obligations from './Applicants/Obligations/Obligations';
import Summary from './Summary';
import AdditionalInformation from './AdditionalInformation';
import CostAndResources from './Financing/CostAndResources';
import ConnectedPolicies from './Financing/ConnectedPolicies';
import { UrlParams } from 'screens/Mutations/utils/types';
import { hasConnectedPolicies } from '../ConversionMutation/utils';
import Loan from './Financing/Loan';
import { useClients } from 'screens/Clients/hooks/useClients';

// sub component so we can use the useWizardStep hook
const Form: FC = () => {
  const { t } = useTranslation();
  const { handleNavigateOnCloseForm } = useClients();
  // todo: this hook can be used in OHA
  const { type, isLoading, moneylenderName } = useMutationForm();

  const [steps, setSteps] = useState<Record<string, Step>>(getSteps([]));
  const { activeStepIndex, goToStep } = useWizardStep();
  const { loanNumber } = useParams<keyof UrlParams>() as UrlParams;

  const formMethods = useForm<RaiseForm>({
    defaultValues: { ...raiseFormDefaultValues, loanNumber: loanNumber ? +loanNumber : 0 },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: conversionRaiseResolver(steps, activeStepIndex),
  });

  const { handleSubmit, watch, trigger } = formMethods;

  const applicants = watch('applicants');
  const step = getStep(steps, activeStepIndex);
  const { name: activeStep, index } = step;

  const nav = navigator(steps, step);

  const handleOnCloseClick = () => handleNavigateOnCloseForm();

  const handleNextStep = async () => {
    const isValid = await trigger();
    if (isValid) goToStep(nav.goTo('next'));
  };

  const handlePrevious = () => goToStep(nav.goTo('prev'));

  const hasNextStep = activeStepIndex + 1 < Object.keys(steps).length;
  const hasPreviousStep = !!nav.get(step).prev;

  const onSubmit = (values: any) => {
    // To do implement submit logic
    // eslint-disable-next-line
    console.log(values);
  };

  useEffect(() => {
    setSteps(getSteps(applicants));
  }, [applicants]);

  const changeCurrentMortgage = watch('financingLoan.changeCurrentMortgage');
  const changeLifeInsurancePolicy = watch('financingLoan.changeLifeInsurancePolicy');
  const currentLoanPartsFromForm = watch('currentLoanParts');
  const currentLoanParts = currentLoanPartsFromForm ?? [];
  const stringifyLoanParts = JSON.stringify(currentLoanParts);

  useEffect(() => {
    if (changeLifeInsurancePolicy === 'yes') {
      setSteps(getSteps(applicants, true));
    } else if (changeCurrentMortgage === 'yes' && currentLoanParts) {
      setSteps(getSteps(applicants, hasConnectedPolicies(currentLoanParts)));
    } else {
      setSteps(getSteps(applicants, false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCurrentMortgage, changeLifeInsurancePolicy, stringifyLoanParts]);

  if (isLoading) {
    return (
      <Spinner>
        <CircularProgress size={50} data-testid="progress" />
      </Spinner>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FormTemplate
          header={
            <TitleBar
              title={t(
                `directMutations.header.${type === 'Raise' ? 'raiseTitle' : 'conversionRaiseTitle'}`
              )}
              moneyLender={moneylenderName}
              onCloseClick={handleOnCloseClick}
            >
              <LoadingIconButton size="medium">
                <SaveOutlined />
                <ButtonText>{t('directMutations.header.save')}</ButtonText>
              </LoadingIconButton>
              <LoadingIconButton size="medium">
                <DeleteOutlined />
                <ButtonText>{t('directMutations.header.delete')}</ButtonText>
              </LoadingIconButton>
            </TitleBar>
          }
          sidebar={<FormStepper steps={steps} />}
          footer={
            <BottomNavigation
              hasPreviousStep={hasPreviousStep}
              onPreviousClick={handlePrevious}
              onNextClick={handleNextStep}
              nextButtonStyleType={hasNextStep ? 'button' : 'submit'}
            />
          }
        >
          {activeStep === 'applicants' && <Applicants />}
          {activeStep === 'personalInfo' && <PersonalInfo index={index} />}
          {activeStep === 'contactDetails' && <ContactDetails index={index} />}
          {activeStep === 'income' && <Income index={index} />}
          {activeStep === 'obligation' && <Obligations index={index} />}
          {activeStep === 'collateral' && <Collateral />}
          {activeStep === 'summary' && <Summary />}
          {activeStep === 'additionalInfo' && <AdditionalInformation />}
          {activeStep === 'financingLoan' && <Loan />}
          {activeStep === 'financingConnectedPolicy' && <ConnectedPolicies />}
          {activeStep === 'financingCostAndResources' && <CostAndResources />}

          {activeStep !== 'applicants' &&
            activeStep !== 'personalInfo' &&
            activeStep !== 'contactDetails' &&
            activeStep !== 'income' &&
            activeStep !== 'obligation' &&
            activeStep !== 'additionalInfo' &&
            activeStep !== 'collateral' &&
            activeStep !== 'summary' &&
            activeStep !== 'financingLoan' &&
            activeStep !== 'financingConnectedPolicy' &&
            activeStep !== 'financingCostAndResources' && <div>{activeStep}</div>}
        </FormTemplate>
      </StyledForm>
    </FormProvider>
  );
};

const ConversionRaiseMutation = () => {
  return (
    <Wizard>
      <Form />
    </Wizard>
  );
};

export default ConversionRaiseMutation;
