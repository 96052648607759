import { Option } from '../types';

/**
 *  maps the products to options
 * @param products
 * @returns Option[]
 */
export const mapProductsToOptions = (
  products?: {
    displayName: string;
    name: string;
  }[]
): Option[] | [] => {
  if (!products || !Array.isArray(products)) {
    return [];
  }
  return products.map((product) => ({ label: product.displayName, value: product.displayName }));
};
