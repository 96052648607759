import { errorMessages } from '../../../constants';
import { object, string } from 'yup';

const insuredPersonsSchema = () => {
  return object()
    .when('numberOfInsuredPersons', {
      is: (val: number) => val === 1,
      then: object({
        personOne: string().required(errorMessages.required),
      }),
    })
    .when('numberOfInsuredPersons', {
      is: (val: number) => val === 2,
      then: object({
        personOne: string().required(errorMessages.required),
        personTwo: string().required(errorMessages.required),
      }),
    });
};
export default insuredPersonsSchema;
