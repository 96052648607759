import { FC, useState, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TitleBar from '../TitleBar';
import BottomNavigation from '../BottomNavigation';
import { ApplicantFormStepProps, ObligationFormType } from 'screens/Mutations/utils/types';
import { RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import ObligationList from 'screens/Mutations/templates/ObligationList';
import obligationsSchema from '../../../../schemas/obligation';
import FormTitleText from 'components/Form/FormTitle';
import { yupResolver } from '@hookform/resolvers/yup';
import { saveObligation } from 'store/ohaFormReducer';
import { useOhaFormStep } from 'screens/Mutations/hooks/useOhaFormStep';
import { mapOHAForm } from 'screens/Mutations/utils/mappers/oha/mapFormToApi';
import FormTemplate from 'components/Templates/FormTemplate';
import FormStepper from '../FormStepper';
import { Form } from 'components/Molecules/components/Form/Form.styled';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

const ObligationForm: FC<ApplicantFormStepProps> = ({ title, subtitle, rootFormName }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [snackbar, setSnackbar] = useState<{ status: string; name: string }>();
  const ohaFormState = useSelector((state: RootState) => state.ohaForm);
  const { firstNames, insertion, surname } = ohaFormState[rootFormName].personalInfo;
  const fullName = `${firstNames || ''} ${insertion || ''} ${surname || ''}`;
  const defaultValues = ohaFormState[rootFormName].obligation;

  const formMethods = useForm<ObligationFormType>({
    defaultValues: {
      ...defaultValues,
    },
    resolver: yupResolver(obligationsSchema),
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = formMethods;

  const [id, obligations] = watch(['id', 'obligations']);

  const obligationFormInput: ObligationFormType = useMemo(
    () => ({
      id,
      obligations,
    }),
    [id, obligations]
  );

  const completeForm = {
    ...ohaFormState,
    [rootFormName]: {
      ...ohaFormState[rootFormName],
      personalInfo: { ...ohaFormState[rootFormName].personalInfo },
      contactDetails: { ...ohaFormState[rootFormName].contactDetails },
      income: { ...ohaFormState[rootFormName].income },
      obligation: { ...obligationFormInput },
    },
  };

  const dispatchForm = useCallback(() => {
    const formData: ObligationFormType = { ...obligationFormInput };
    dispatch(saveObligation({ formData, rootFormName }));
  }, [obligationFormInput, dispatch, rootFormName]);

  const onSaveError = () => handleNotification('error', 'submit');

  const { save, isSaving, goToNextStep } = useOhaFormStep({ onSaveError });

  const onSubmit = async (data: ObligationFormType) => {
    const formData: ObligationFormType = { ...data };

    dispatch(saveObligation({ formData, rootFormName }));

    if (isDirty) {
      await save(
        mapOHAForm({
          ...ohaFormState,
          [rootFormName]: { ...ohaFormState[rootFormName], obligation: formData },
        })
      );
    }
    goToNextStep();
  };

  const handleNotification = (status: string, name: string) => setSnackbar({ status, name });

  const handleOnClose = useCallback(() => setSnackbar(undefined), []);

  const name = fullName.trim() || t(`${title}`);

  return (
    <>
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(onSubmit)} data-testid="obligation-form">
          <FormTemplate
            header={
              <TitleBar
                onResult={handleNotification}
                formState={completeForm}
                resetForm={reset}
                isFormDirty={isDirty}
                dispatchCurrentForm={dispatchForm}
              />
            }
            sidebar={<FormStepper />}
            footer={
              <BottomNavigation dispatchCurrentForm={dispatchForm} isLoadingSubmit={isSaving} />
            }
          >
            <FormTitleText title={name} description={t(subtitle, { name })} />
            <ObligationList />
          </FormTemplate>
        </Form>
      </FormProvider>
      {snackbar && (
        <CustomSnackbar
          isOpen={!!snackbar}
          handleClose={handleOnClose}
          severity={snackbar.status === 'success' ? 'success' : 'error'}
          message={t(`directMutations.snackbar.${snackbar.name}.${snackbar.status}`)}
        />
      )}
    </>
  );
};

export default ObligationForm;
