import type { ClientLoan } from 'types/Client';
import { useQueryApplicationDetailsEx } from 'use/cases';
import ClientApplicationItem from './ClientApplicationItem';

type ClientDetailApplicationContentProps = {
  loan: ClientLoan;
};

const ClientDetailApplicationContent = ({ loan }: ClientDetailApplicationContentProps) => {
  const { commercialLabel, loanNumber, applicationIndexNumber, commercialRelation } = loan;
  const {
    data: application,
    isLoading,
    isError,
  } = useQueryApplicationDetailsEx({
    commercialLabel,
    loanNumberExternal: loanNumber,
    applicationIndexNumber,
  });

  return (
    <ClientApplicationItem
      commercialRelation={commercialRelation}
      application={application}
      isError={isError}
      isLoading={isLoading}
    />
  );
};

export default ClientDetailApplicationContent;
