import { SummaryCard, CardRow, CardSection } from 'components/SummaryCard';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAmount } from 'utils/formatAmount';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

type CollateralProps = {
  onClick: () => void;
};

const Collateral: FC<CollateralProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const root = 'directMutations.collateral';
  const collateral = useSelector((state: RootState) => state.ohaForm.collateral) ?? {};

  return (
    <SummaryCard title={t(`${root}.formTitle`)} onEditClick={onClick}>
      <CardSection>
        <CardRow
          name={t(`${root}.distributionValue`)}
          value={formatAmount(collateral.distributionValue)}
        />
        <CardRow name={t(`${root}.marketValue`)} value={formatAmount(collateral.marketValue)} />
        <CardRow
          name={t(`${root}.valuationCosts`)}
          value={formatAmount(collateral.valuationCosts)}
        />
      </CardSection>
    </SummaryCard>
  );
};

export default Collateral;
