import { useTranslation } from 'react-i18next';
import { Box, Icon, IconButton, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'fixed',
    top: 60,
    bottom: 85,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 740,
    maxWidth: '100%',
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '12px 12px 0 0',
    [theme.breakpoints.down('sm')]: {
      bottom: 230,
    },
  },
  content: {
    'height': '100%',
    'paddingRight': theme.spacing(2),
    'overflowY': 'scroll',
    '&::-webkit-scrollbar': {
      width: 4,
      marginLeft: -4,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey[200],
      borderRadius: 2,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.text.secondary,
      borderRadius: 2,
      outline: 'none',
    },
  },
  closeBtn: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  subheader: {
    fontWeight: 500,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0.5),
  },
}));

type DisclaimerProps = {
  onClose: () => void;
};

const Disclaimer = (props: DisclaimerProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.paper}>
      <IconButton onClick={props.onClose} className={classes.closeBtn} size="large">
        <Icon>close</Icon>
      </IconButton>
      <Typography className={classes.title} variant="h4">
        {t('cookies.disclaimer.title')}
      </Typography>
      <Box className={classes.content}>
        <Typography variant="body2">{t('cookies.disclaimer.paragraph0')}</Typography>
        <Typography className={classes.subheader} variant="body2">
          {t('cookies.disclaimer.subheader1')}
        </Typography>
        <Typography variant="body2">{t('cookies.disclaimer.paragraph1')}</Typography>
        <Typography className={classes.subheader} variant="body2">
          {t('cookies.disclaimer.subheader2')}
        </Typography>
        <Typography variant="body2">{t('cookies.disclaimer.paragraph2')}</Typography>
        <Typography className={classes.subheader} variant="body2">
          {t('cookies.disclaimer.subheader3')}
        </Typography>
        <Typography variant="body2">{t('cookies.disclaimer.paragraph3')}</Typography>
        <Typography className={classes.subheader} variant="body2">
          {t('cookies.disclaimer.subheader4')}
        </Typography>
        <Typography variant="body2">{t('cookies.disclaimer.paragraph4')}</Typography>
        <Typography className={classes.subheader} variant="body2">
          {t('cookies.disclaimer.subheader5')}
        </Typography>
        <Typography variant="body2">{t('cookies.disclaimer.paragraph5')}</Typography>
        <Typography className={classes.subheader} variant="body2">
          {t('cookies.disclaimer.subheader6')}
        </Typography>
        <Typography variant="body2">{t('cookies.disclaimer.paragraph6')}</Typography>
        <Typography className={classes.subheader} variant="body2">
          {t('cookies.disclaimer.subheader7')}
        </Typography>
        <Typography variant="body2">{t('cookies.disclaimer.paragraph7')}</Typography>
        <Typography className={classes.subheader} variant="body2">
          {t('cookies.disclaimer.subheader8')}
        </Typography>
        <Typography variant="body2">{t('cookies.disclaimer.paragraph8')}</Typography>
        <Typography className={classes.subheader} variant="body2">
          {t('cookies.disclaimer.subheader9')}
        </Typography>
        <Typography variant="body2">{t('cookies.disclaimer.paragraph9')}</Typography>
        <Typography className={classes.subheader} variant="body2">
          {t('cookies.disclaimer.subheader10')}
        </Typography>
        <Typography variant="body2">{t('cookies.disclaimer.paragraph10')}</Typography>
      </Box>
    </Paper>
  );
};

export default Disclaimer;
