import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, List, ListItem, ListItemSecondaryAction, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { Notification } from '../../__generated__/api';
import { formatLoanNumber } from '../../utils/global';
import formatDate from 'utils/formatDate';

const useStyles = makeStyles((theme) => ({
  text: {
    'maxWidth': '90%',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
  new: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
  textDate: {
    fontWeight: 'bolder',
  },
  textOther: {
    'display': 'flex',
    'color': theme.palette.text.secondary,
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  listItem: {
    paddingRight: theme.spacing(4),
    cursor: 'pointer',
  },
}));

type NotificationListMobileProps = {
  notifications?: Notification[];
  onClickView: (item: Notification) => void;
};

const NotificationListMobile = (props: NotificationListMobileProps) => {
  const { notifications, onClickView } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <List disablePadding>
      {notifications?.map((item, index) => (
        <Fragment key={`${item.dateCreated}${item.consumerName}${index}`}>
          <ListItem className={classes.listItem} disableGutters onClick={() => onClickView(item)}>
            <div className={classes.text}>
              <Typography className={classes.textDate}>{formatDate(item.dateCreated)}</Typography>
              <Typography color="textSecondary">{item.name}</Typography>
              <div className={classes.textOther}>
                <Typography>{item.consumerName || t('general.unknown')}</Typography>
                <span>‧</span>
                <Typography>
                  {item.loanNumber !== null && item.loanNumber !== undefined
                    ? formatLoanNumber(Number.parseInt(item.loanNumber, 10))
                    : t('general.unknown')}
                </Typography>
              </div>
            </div>
            <ListItemSecondaryAction>
              {item.dateAccessed === null && <div className={classes.new} />}
            </ListItemSecondaryAction>
          </ListItem>
          {index !== notifications.length - 1 && <Divider />}
        </Fragment>
      ))}
    </List>
  );
};

export default NotificationListMobile;
