import { Applicant, Step } from '../types';

const getSteps = (
  applicants: Applicant[] = [],
  isConnectedPolicy: boolean = false
): Record<string, Step> => {
  const mappedApplicants = (applicants ?? []).reduce((acc: Record<string, Step>, _, index) => {
    acc[`personalInfo-${index}`] = { name: 'personalInfo', index };
    acc[`contactDetails-${index}`] = {
      name: 'contactDetails',
      index,
    };
    acc[`income-${index}`] = {
      name: 'income',
      index,
    };
    acc[`obligation-${index}`] = {
      name: 'obligation',
      index,
    };

    return acc;
  }, {});
  let financingConnectedPolicy = {};

  if (isConnectedPolicy) {
    financingConnectedPolicy = {
      financingConnectedPolicy: { name: 'financingConnectedPolicy' },
    };
  }
  return {
    applicants: { name: 'applicants' },
    ...mappedApplicants,
    collateral: { name: 'collateral' },
    financingCostAndResources: { name: 'financingCostAndResources' },
    financingLoan: { name: 'financingLoan' },
    ...financingConnectedPolicy,
    additionalInfo: { name: 'additionalInfo' },
    summary: { name: 'summary' },
  };
};

export default getSteps;
