import { jsDateToLocalISO8601DateString } from 'utils/form/validations/getDateValueFromInput';
import { array, date, number, object, string } from 'yup';
import { errorMessages } from 'screens/Mutations/constants';

const endDateInterestDeduction = () =>
  date()
    // TODO: when using Muiv5 datepicker, refactor this nonsense
    .transform((value) => {
      if (value) return new Date(jsDateToLocalISO8601DateString(value));
      return undefined;
    })
    .min(new Date(), errorMessages.FutureDate)
    .required(errorMessages.required)
    .typeError(errorMessages.date)
    .nullable();

const getSplitLoanSchema = () =>
  object().shape({
    amount: number().typeError(errorMessages.number).required(errorMessages.required),
    fixedRatePeriod: number().typeError(errorMessages.number).required(errorMessages.required),
    NHGGuarantee: string().required(errorMessages.required).nullable(),
    repaymentType: string().required(errorMessages.required),
    box1: number().typeError(errorMessages.number).required(errorMessages.required),
    box3: number().typeError(errorMessages.number).required(errorMessages.required),
    interestPercentage: number().typeError(errorMessages.number).required(errorMessages.required),
    endDateInterestDeduction: endDateInterestDeduction(),
    hasDurationPeriodChange: string().required(errorMessages.required).nullable(),
    newDuration: number().when('hasDurationPeriodChange', {
      is: (val: any) => val === 'yes',
      then: number().typeError(errorMessages.number).required(errorMessages.required),
    }),
  });

const getConvertLoanSchema = () =>
  object().shape({
    repaymentType: string().required(errorMessages.required),
    fixedRatePeriod: number().required(errorMessages.required).nullable(),
    NHGGuarantee: string().required(errorMessages.required).nullable(),
  });

export const currentLoanPartSchema = () =>
  array().of(
    object().shape({
      convertLoan: object().when('shouldSplitLoan', {
        is: (val: any) => val === 'no',
        then: getConvertLoanSchema(),
      }),
      splitLoan: array().when('shouldSplitLoan', {
        is: (val: any) => val === 'yes',
        then: array().of(getSplitLoanSchema()),
      }),
      loanPartNumber: number().typeError(errorMessages.number).required(errorMessages.required),
      repaymentType: string().required(errorMessages.required),
      shouldSplitLoan: string().required(errorMessages.required).nullable(),
      NHGGuarantee: string().required(errorMessages.required).nullable(),
      loanAmount: number().typeError(errorMessages.number).required(errorMessages.required),
      hasConnectedPolicy: string().required(errorMessages.required).nullable(),
      numberOfSplitLoanParts: number().nullable(),
      box1: number().typeError(errorMessages.number).required(errorMessages.required),
      box3: number().typeError(errorMessages.number).required(errorMessages.required),
      interestPercentage: number().typeError(errorMessages.number).required(errorMessages.required),
      endDateInterestDeduction: endDateInterestDeduction(),
      hasDurationPeriodChange: string().required(errorMessages.required).nullable(),
      newDuration: number().when('hasDurationPeriodChange', {
        is: (val: any) => val === 'yes',
        then: number().typeError(errorMessages.number).required(errorMessages.required),
      }),
      hasInterestRedemption: string().required(errorMessages.required).nullable(),
      fixedRatePeriod: number().typeError(errorMessages.number).required(errorMessages.required),
      typeOfFinancing: string()
        .nullable()
        .when('hasInterestRedemption', {
          is: (val: any) => val === 'yes',
          then: string().required(errorMessages.required).nullable(),
        }),
    })
  );
export const newLoanPartsSchema = () =>
  array().of(
    object().shape({
      repaymentType: string().required(errorMessages.required),
      NHGGuarantee: string().required(errorMessages.required).nullable(),
      loanAmount: number().typeError(errorMessages.number).required(errorMessages.required),
      box1: number().typeError(errorMessages.number).required(errorMessages.required),
      box3: number().typeError(errorMessages.number).required(errorMessages.required),
      interestPercentage: number().typeError(errorMessages.number).required(errorMessages.required),
      endDateInterestDeduction: endDateInterestDeduction(),
      newDuration: number().typeError(errorMessages.number).required(errorMessages.required),
      fixedRatePeriod: number().typeError(errorMessages.number).required(errorMessages.required),
    })
  );

export const loanSchema = object().shape({
  loanNumber: number(),
  amount: number().typeError(errorMessages.number).required(errorMessages.required),
  amountRaise: number().typeError(errorMessages.number).required(errorMessages.required),
  extraPayOffAmount: number().typeError(errorMessages.number).required(errorMessages.required),
  changeLifeInsurancePolicy: string().required(errorMessages.required).nullable(),
  changeCurrentMortgage: string().required(errorMessages.required).nullable(),
  currentLoanParts: array().when('changeCurrentMortgage', {
    is: (val: any) => val === 'yes',
    then: currentLoanPartSchema(),
  }),
  newLoanParts: array().when('changeCurrentMortgage', {
    is: (val: any) => val === 'yes',
    then: newLoanPartsSchema(),
  }),
});
