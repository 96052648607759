import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { SlideUpTransition } from '../../lib/Transition/transition';
import { parseHtml } from '../../utils/cms';
import { useTranslation } from 'react-i18next';
import { useUserNotifications } from 'use/userNotifications';
import LoadingIndicator from '../LoadingIndicator';
import { useEffect, useState } from 'react';
import { UserNotification } from '../../__generated__/api';
import { NewsImageQuality } from '../../config/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  notification: {
    'margin': theme.spacing(2, 0, 1),
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'justifyContent': 'center',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    '& p': {
      color: theme.palette.text.secondary,
      fontSize: '14px',
      lineHeight: 1.5,
    },
    '& a': {
      'textDecoration': 'none',
      'color': theme.palette.primary.main,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  close: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  logo: {
    width: 42,
    height: 42,
  },
}));

const UserNotificationsPopup = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    userNotificationsLoading,
    userNotifications,
    closePopUp,
    isPopOverClosed,
    storeNotificationClosed,
    notificationClosed,
    isWelcomeClosed,
    storeCookie,
    isFirstVisit,
  } = useUserNotifications();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const welcomeIsToggled = isFirstVisit ? isWelcomeClosed : true;

    const shouldOpenPopUp =
      welcomeIsToggled &&
      userNotifications &&
      userNotifications.length > 0 &&
      !isPopOverClosed &&
      !notificationClosed;

    if (shouldOpenPopUp) {
      setIsOpen(true);
    }

    if (isPopOverClosed) {
      setIsOpen(false);
    }
  }, [
    userNotifications,
    isWelcomeClosed,
    notificationClosed,
    isPopOverClosed,
    storeNotificationClosed,
    setIsOpen,
    isFirstVisit,
  ]);

  const close = () => {
    closePopUp(true);
    storeNotificationClosed();
    storeCookie();
  };

  const image = (notification: UserNotification) => {
    return notification.source?.avatar?.url ? (
      <img
        className={classes.logo}
        alt={`Logo ${notification.source?.name}`}
        title={notification.source?.name ?? ''}
        src={`${notification.source?.avatar?.url}?w=40&h=40&q=${NewsImageQuality}`}
      />
    ) : null;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      TransitionComponent={SlideUpTransition}
      fullWidth
      maxWidth="md"
    >
      <div className={classes.root}>
        <IconButton className={classes.close} onClick={close} size="large">
          <Close />
        </IconButton>
        {userNotificationsLoading && <LoadingIndicator />}
        {userNotifications?.map((notification) => (
          <Box className={classes.notification} key={notification.id}>
            {image(notification)}

            <Typography variant="h3" color="textSecondary">
              {notification.title}
            </Typography>
            {notification.message && parseHtml(notification.message)}
          </Box>
        ))}
        <Button color="primary" onClick={close}>
          {t('home.userNotifications.ok')}
        </Button>
      </div>
    </Dialog>
  );
};

export default UserNotificationsPopup;
