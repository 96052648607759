import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { BtlLoan } from 'types/BtlFormApplication';
import CurrencyFormat from 'screens/Applications/components/ApplicationNew/CurrencyFormat';
import { getTotalProvenAmount, getTotalRequiredAmount } from '../../utils';

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    '& td': {
      borderBottom: 'none',
      wordBreak: 'break-all',
    },
    '&.error': {
      'backgroundColor': theme.palette.danger.light,
      '& :last-child': {
        color: theme.palette.danger.main,
      },
    },
  },
  errorIcon: {
    'float': 'right',
    'display': 'none',
    '.error &': {
      display: 'block',
    },
  },
  loanPartTitle: {
    marginBottom: theme.spacing(2),
  },
}));

type BuyToLetLoanSummaryProps = {
  loan: BtlLoan;
  errorMessage: (id: string, category: string) => void;
  onClickField: (field: string) => void;
};

const BuyToLetLoanSummary: FC<BuyToLetLoanSummaryProps> = ({
  loan,
  errorMessage,
  onClickField,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const form = 'buyToLetForm.loan';

  const totalRequiredAmount = getTotalRequiredAmount(loan);
  const totalProvenAmount = getTotalProvenAmount(loan);

  return (
    <>
      <Table className={classes.table}>
        <TableBody>
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.totalPurchasePrice', 'loan')),
            })}
          >
            <TableCell>{t(`${form}.totalPurchasePrice`)}</TableCell>
            <TableCell onClick={() => onClickField('totalPurchasePrice')}>
              {!!loan.totalPurchasePrice && <CurrencyFormat value={loan.totalPurchasePrice} />}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.explanationTotalPurchasePrice', 'loan')),
            })}
          >
            <TableCell>{t(`${form}.explanationTotalPurchasePrice`)}</TableCell>
            <TableCell onClick={() => onClickField('explanationTotalPurchasePrice')}>
              {loan.explanationTotalPurchasePrice && loan.explanationTotalPurchasePrice}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.refinancingExistingMortgagesAmount', 'loan')),
            })}
          >
            <TableCell>{t(`${form}.refinancingExistingMortgagesAmount`)}</TableCell>
            <TableCell onClick={() => onClickField('refinancingExistingMortgagesAmount')}>
              {!!loan.refinancingExistingMortgagesAmount && (
                <CurrencyFormat value={loan.refinancingExistingMortgagesAmount} />
              )}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(
                errorMessage('.explanationRefinancingExistingMortgagesAmount', 'loan')
              ),
            })}
          >
            <TableCell>{t(`${form}.explanationRefinancingExistingMortgagesAmount`)}</TableCell>
            <TableCell
              onClick={() => onClickField('explanationRefinancingExistingMortgagesAmount')}
            >
              {loan.explanationRefinancingExistingMortgagesAmount}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.totalCostBuyerAmount', 'loan')),
            })}
          >
            <TableCell>{t(`${form}.totalCostBuyerAmount`)}</TableCell>
            <TableCell onClick={() => onClickField('totalCostBuyerAmount')}>
              {!!loan.totalCostBuyerAmount && <CurrencyFormat value={loan.totalCostBuyerAmount} />}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.explanationTotalCostBuyerAmount', 'loan')),
            })}
          >
            <TableCell>{t(`${form}.explanationTotalCostBuyerAmount`)}</TableCell>
            <TableCell onClick={() => onClickField('explanationTotalCostBuyerAmount')}>
              {loan.explanationTotalCostBuyerAmount}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.liquidityAmountToWithdraw', 'loan')),
            })}
          >
            <TableCell>{t(`${form}.liquidityAmountToWithdraw`)}</TableCell>
            <TableCell onClick={() => onClickField('liquidityAmountToWithdraw')}>
              {!!loan.liquidityAmountToWithdraw && (
                <CurrencyFormat value={loan.liquidityAmountToWithdraw} />
              )}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.explanationLiquidityAmountToWithdraw', 'loan')),
            })}
          >
            <TableCell>
              {t(`${form}.explanationLiquidityAmountToWithdraw`)}
              {!!loan.liquidityAmountToWithdraw && <span className="required">*</span>}
            </TableCell>
            <TableCell onClick={() => onClickField('explanationLiquidityAmountToWithdraw')}>
              {loan.explanationLiquidityAmountToWithdraw}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>

          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.otherCosts', 'loan')),
            })}
          >
            <TableCell>{t(`${form}.otherCosts`)}</TableCell>
            <TableCell onClick={() => onClickField('otherCosts')}>
              {!!loan.otherCosts && <CurrencyFormat value={loan.otherCosts} />}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.explanationOtherCosts', 'loan')),
            })}
          >
            <TableCell>{t(`${form}.explanationOtherCosts`)}</TableCell>
            <TableCell onClick={() => onClickField('explanationOtherCosts')}>
              {loan.explanationOtherCosts}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>

          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.totalRenovationCosts', 'loan')),
            })}
          >
            <TableCell>{t(`${form}.totalRenovationCosts`)}</TableCell>
            <TableCell onClick={() => onClickField('totalRenovationCosts')}>
              {!!loan.totalRenovationCosts && <CurrencyFormat value={loan.totalRenovationCosts} />}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.explanationTotalRenovationCosts', 'loan')),
            })}
          >
            <TableCell>
              {t(`${form}.explanationTotalRenovationCosts`)}
              {!!loan.totalRenovationCosts && <span className="required">*</span>}
            </TableCell>
            <TableCell onClick={() => onClickField('explanationTotalRenovationCosts')}>
              {loan.explanationTotalRenovationCosts}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>

          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.totalRequiredAmount', 'loan')),
            })}
          >
            <TableCell>{t(`${form}.totalRequiredAmount`)}</TableCell>
            <TableCell onClick={() => onClickField('totalRequiredAmount')}>
              {!!totalRequiredAmount && <CurrencyFormat value={totalRequiredAmount} />}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>

          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.amount', 'loan')),
            })}
          >
            <TableCell>{t(`${form}.amount`)}</TableCell>
            <TableCell onClick={() => onClickField('amount')}>
              {!!loan.amount && <CurrencyFormat value={loan.amount} />}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>

          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.addedPersonalEquity', 'loan')),
            })}
          >
            <TableCell>{t(`${form}.addedPersonalEquity`)}</TableCell>
            <TableCell onClick={() => onClickField('addedPersonalEquity')}>
              {!!loan.addedPersonalEquity && <CurrencyFormat value={loan.addedPersonalEquity} />}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>

          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.explanationAddedPersonalEquity', 'loan')),
            })}
          >
            <TableCell>
              {t(`${form}.explanationAddedPersonalEquity`)}
              {!!loan.addedPersonalEquity && <span className="required">*</span>}
            </TableCell>
            <TableCell onClick={() => onClickField('explanationAddedPersonalEquity')}>
              {loan.explanationAddedPersonalEquity}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>

          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage('.totalProvenAmount', 'loan')),
            })}
          >
            <TableCell>{t(`${form}.totalProvenAmount`)}</TableCell>
            <TableCell onClick={() => onClickField('totalProvenAmount')}>
              {totalProvenAmount !== 0 && <CurrencyFormat value={totalProvenAmount} />}

              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default BuyToLetLoanSummary;
