import React, { FC, useEffect } from 'react';
import { useStyles } from './Obligation.styled';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  FormInputCurrency,
  FormInputDate,
  FormInputDropdown,
  FormInputNumber,
  FormToggleButtonGroup,
  FormInputText,
} from 'components/Form';
import { useFormContext } from 'react-hook-form';
import { ExpandMore } from '@mui/icons-material';
import { TypeOfObligation } from '__generated__/api';
import { typeOfObligations, yesNoGroupOptions } from 'screens/Mutations/constants';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'screens/Mutations/molecules/Accordion/Accordion';
import { formatIncome } from 'screens/Mutations/components/DismissalJointLiabilityMutation/utils';
import { FormGrid } from 'screens/Mutations/components/DismissalJointLiabilityMutation/DismissalJointLiabilityMutation.styled';
import { FieldsGrid } from 'components/Form/Forms.styled';
import useUnregisterFields from 'screens/Mutations/hooks/useUnregisterField';
import { SubTitle } from 'screens/Mutations/molecules/styled';
import FormInputPercentage from 'components/Form/FormInputPercentage';

type Props = {
  index: number;
  rootFormName?: string;
  shouldUnregister?: boolean;
  children?: React.ReactNode;
};

export const Obligation: FC<Props> = ({ index, children, rootFormName, shouldUnregister }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const unregisterMultiple = useUnregisterFields();
  const { watch } = useFormContext();
  const rootForm = `${rootFormName ?? ''}obligations.${index}`;
  const testPrefix = `obligations.${index}`;
  const typeOfObligation: TypeOfObligation | undefined = watch(`${rootForm}.typeOfObligation`);
  const principalDebt: number | undefined = watch(`${rootForm}.principalDebt`);
  const startDate: string | undefined = watch(`${rootForm}.startDate`);
  const isExistingMortgage = typeOfObligation === TypeOfObligation.ExistingMortgage;

  const title = t(
    `directMutations.obligation.${
      typeOfObligation ? `obligationTypes.${typeOfObligation}` : 'accordionHeader'
    }`
  );

  const fields = {
    ExistingMortgage: [
      `${rootForm}.redemptionTypeDisplayName`,
      `${rootForm}.startDate`,
      `${rootForm}.endDate`,
      `${rootForm}.fixedInterestPeriod`,
      `${rootForm}.interestPercentage`,
      `${rootForm}.amountTaxBox1`,
      `${rootForm}.endDateInterestDeduction`,
      `${rootForm}.amountTaxBox3`,
      `${rootForm}.registryRank`,
    ],
    NotExistingMortgage: [
      `${rootForm}.isObligationByBKR`,
      `${rootForm}.partialRedemptionByPersonalEquity`,
      `${rootForm}.redemptionByNewMortgage`,
      `${rootForm}.repayableAmount`,
    ],
  };

  useEffect(() => {
    if (isExistingMortgage) {
      unregisterMultiple(fields.NotExistingMortgage);
    } else {
      unregisterMultiple(fields.ExistingMortgage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeOfObligation]);

  return (
    <FormGrid container>
      <Grid item xs={12}>
        <Accordion data-testid="obligation-section" defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-content`}
          >
            <Grid container item justifyContent="space-between" className={classes.disabled}>
              <Grid xs={8} item>
                <SubTitle variant="h5">{title}</SubTitle>
              </Grid>
              <Grid xs={4} item>
                <Typography variant="h6" className={classes.headerName}>
                  {formatIncome(principalDebt)}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <FieldsGrid item>
                <FormInputDropdown
                  name={`${rootForm}.typeOfObligation`}
                  label={t('directMutations.obligation.typeOfObligation')}
                  options={typeOfObligations()}
                  placeholder={t('directMutations.obligation.chooseTypeOfObligation')}
                  required
                  testId={`${testPrefix}.typeOfObligation`}
                />
                {typeOfObligation && (
                  <>
                    {!isExistingMortgage && (
                      <FormToggleButtonGroup
                        name={`${rootForm}.isObligationByBKR`}
                        label={t('directMutations.obligation.isObligationByBKR')}
                        options={yesNoGroupOptions}
                        required
                        testId={`${testPrefix}.isObligationByBKR`}
                      />
                    )}
                    <FormInputCurrency
                      name={`${rootForm}.principalDebt`}
                      label={t('directMutations.obligation.principalDebt')}
                      required
                      testId={`${testPrefix}.principalDebt`}
                    />

                    <FormInputCurrency
                      name={`${rootForm}.actualDebt`}
                      label={t('directMutations.obligation.actualDebt')}
                      required
                      testId={`${testPrefix}.actualDebt`}
                    />

                    <FormInputCurrency
                      name={`${rootForm}.obligationAmount`}
                      label={t('directMutations.obligation.obligationAmount')}
                      testId={`${testPrefix}.obligationAmount`}
                    />
                    <FormToggleButtonGroup
                      name={`${rootForm}.partialRedemptionByPersonalEquity`}
                      label={t('directMutations.obligation.partialRedemptionByPersonalEquity')}
                      options={yesNoGroupOptions}
                      required
                      testId={`${testPrefix}.partialRedemptionByPersonalEquity`}
                    />
                    <FormToggleButtonGroup
                      name={`${rootForm}.redemptionByNewMortgage`}
                      label={t('directMutations.obligation.redemptionByNewMortgage')}
                      options={yesNoGroupOptions}
                      required
                      testId={`${testPrefix}.redemptionByNewMortgage`}
                    />
                    <FormInputCurrency
                      name={`${rootForm}.repayableAmount`}
                      label={t('directMutations.obligation.repayableAmount')}
                      required
                      testId={`${testPrefix}.repayableAmount`}
                    />
                    {isExistingMortgage && (
                      <>
                        <FormInputText
                          name={`${rootForm}.redemptionTypeDisplayName`}
                          label={t('directMutations.obligation.typeOfRedemption')}
                          required
                          testId={`${testPrefix}.redemptionTypeDisplayName`}
                          shouldUnregister={shouldUnregister}
                        />
                        <Grid container columnSpacing={3}>
                          <Grid item xs={12} sm={6}>
                            <FormInputDate
                              name={`${rootForm}.startDate`}
                              label={t('directMutations.obligation.startDate')}
                              required
                              testId={`${testPrefix}.startDate`}
                              maxDate={new Date()}
                              shouldUnregister={shouldUnregister}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormInputDate
                              name={`${rootForm}.endDate`}
                              label={t('directMutations.obligation.endDate')}
                              required
                              testId={`${testPrefix}.endDate`}
                              minDate={startDate && new Date(startDate)}
                              shouldUnregister={shouldUnregister}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormInputNumber
                              name={`${rootForm}.fixedInterestPeriod`}
                              label={t('directMutations.obligation.fixedInterestPeriod')}
                              placeholder={t('directMutations.obligation.placeholderRvp')}
                              required
                              testId={`${testPrefix}.fixedInterestPeriod`}
                              max={999}
                              shouldUnregister={shouldUnregister}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormInputPercentage
                              name={`${rootForm}.interestPercentage`}
                              label={t('directMutations.obligation.interestPercentage')}
                              required
                              testId={`${testPrefix}.interestPercentage`}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormInputCurrency
                              name={`${rootForm}.amountTaxBox1`}
                              label={t('directMutations.obligation.amountTaxBox1')}
                              required
                              testId={`${testPrefix}.amountTaxBox1`}
                              shouldUnregister={shouldUnregister}
                              min={0}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormInputDate
                              name={`${rootForm}.endDateInterestDeduction`}
                              label={t('directMutations.obligation.endDateInterestDeduction')}
                              required
                              testId={`${testPrefix}.endDateInterestDeduction`}
                              minDate={new Date()}
                              shouldUnregister={shouldUnregister}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormInputCurrency
                              name={`${rootForm}.amountTaxBox3`}
                              label={t('directMutations.obligation.amountTaxBox3')}
                              required
                              testId={`${testPrefix}.amountTaxBox3`}
                              shouldUnregister={shouldUnregister}
                              min={0}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormInputNumber
                              name={`${rootForm}.registryRank`}
                              label={t('directMutations.obligation.registryRank')}
                              shouldUnregister={shouldUnregister}
                              required
                              testId={`${testPrefix}.registryRank`}
                              min={0}
                              max={999}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </FieldsGrid>
            </Grid>
            {React.Children.toArray(children)}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </FormGrid>
  );
};

export default Obligation;
