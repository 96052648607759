import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import type { ProofItem } from '../../types/ProofItem';
import ProofItemListItem from '../ProofItemListItem';
import { Case } from '../../__generated__/api-di';
import { groupBy } from 'utils/array';
import { GroupedProofItems } from './types';
import ProofItemsList from './components/ProofItemsList';
import getProofItemsCountLabel from './utils/getProofItemsCountLabel';
import { getProofItemStatus, ProofItemStatus } from 'utils/proof-item';

const useStyles = makeStyles((theme) => ({
  heading: {
    float: 'left',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
  },
  title: {
    marginRight: theme.spacing(1),
  },
  category: {
    marginTop: theme.spacing(2),
  },
  divider: {
    clear: 'both',
  },
}));

type Props = {
  allProofItems: GroupedProofItems;
  filteredProofItems: GroupedProofItems;
  application: Case;
  onClickView: (proofitem: ProofItem) => void;
  onClickUpload: (proofitem: ProofItem) => void;
};

const ProofItemListProvide: FC<Props> = ({
  allProofItems: { provide: proofItems },
  filteredProofItems,
  application,
  onClickView,
  onClickUpload,
}) => {
  const { t } = useTranslation();
  const groupedProofItems = groupBy(filteredProofItems.provide, (proofItem) => proofItem.category);

  function countReceivedProofItems() {
    return Number(
      proofItems.filter((proofItem) => {
        const status = getProofItemStatus(proofItem.status);
        return status === ProofItemStatus.pendingApproval || status === ProofItemStatus.approved;
      }).length
    );
  }

  function countTotalProofItems() {
    return Number(
      proofItems.filter((proofItem) => {
        const status = getProofItemStatus(proofItem.status);
        return (
          status === ProofItemStatus.waiting ||
          status === ProofItemStatus.pendingApproval ||
          status === ProofItemStatus.approved ||
          status === ProofItemStatus.rejected
        );
      }).length
    );
  }
  return (
    <>
      <ProofItemsList
        title={t('applications.detail.proofItems.title')}
        label={getProofItemsCountLabel(countReceivedProofItems(), countTotalProofItems())}
        data-testid="proofItems-list"
      >
        <Group
          groupKey="Personalia"
          group={groupedProofItems}
          application={application}
          onClickView={onClickView}
          onClickUpload={onClickUpload}
        />
        <Group
          groupKey="Earnings"
          group={groupedProofItems}
          application={application}
          onClickView={onClickView}
          onClickUpload={onClickUpload}
        />
        <Group
          groupKey="Collateral"
          group={groupedProofItems}
          application={application}
          onClickView={onClickView}
          onClickUpload={onClickUpload}
        />
        <Group
          groupKey="Miscellaneous"
          group={groupedProofItems}
          application={application}
          onClickView={onClickView}
          onClickUpload={onClickUpload}
        />
        <Group
          groupKey="Generated"
          group={groupedProofItems}
          application={application}
          onClickView={onClickView}
          onClickUpload={onClickUpload}
        />
      </ProofItemsList>

      <ProofItemsList title={t('proofitem.generated')}>
        {filteredProofItems.generated.map((item, index) => (
          <ProofItemListItem
            key={`${item.code}${index}`}
            proofItem={item}
            application={application}
            onClickView={onClickView}
            onClickUpload={() => onClickUpload(item)}
          />
        ))}
      </ProofItemsList>
    </>
  );
};

const Group = (props: {
  groupKey: string;
  group: Map<string, ProofItem[]>;
  application: Case;
  onClickView: (proofitem: ProofItem) => void;
  onClickUpload: (proofitem: ProofItem) => void;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const proofItems = props.group.get(props.groupKey) ?? [];

  if (proofItems.length === 0) return null;

  return (
    <>
      <Typography className={classes.category} variant="subtitle2">
        {t(`applications.detail.proofItems.category.${props.groupKey}`)}
      </Typography>

      {proofItems.map((item, index) => (
        <ProofItemListItem
          key={`${item.code}${index}`}
          proofItem={item}
          application={props.application}
          onClickView={props.onClickView}
          onClickUpload={() => props.onClickUpload(item)}
        />
      ))}
    </>
  );
};

export default ProofItemListProvide;
