import React, { useState } from 'react';
import { Hidden } from '@mui/material';
import { useMutationNotification } from 'use/notifications';
import type { Notification } from '../../__generated__/api';

import NotificationListDesktop from './NotificationListDesktop';
import NotificationListMobile from './NotificationListMobile';
import ViewFile from './ViewerDialog';
import { useGroup } from 'use/group';
import { queryClient } from '../../config/react-query';

type NotificationListProps = {
  notifications?: Notification[];
};

const NotificationList = (props: NotificationListProps) => {
  const { notifications } = props;

  const [reference, setReference] = useState<string | null>(null);
  const [showDocument, setShowDocument] = useState(false);
  const [markedAsRead, setMarkedAsRead] = useState(false);
  const { mutateAsync: markAsRead } = useMutationNotification();
  const group = useGroup();

  const readDocument = (notification: Notification) => {
    openDocumentView(notification.reference);
    if (!notification.dateAccessed) {
      markAsRead(notification.reference);
      setMarkedAsRead(true);
    }
  };
  const openDocumentView = (reference: string) => {
    setReference(reference);
    setShowDocument(true);
  };
  const closeDocumentView = () => {
    setReference(null);
    setShowDocument(false);
    // if document is marked as read, update the notifications query cache
    if (markedAsRead) {
      const previousNotifications = queryClient.getQueryData<Notification[] | undefined>([
        'notifications',
        group,
      ]);

      if (previousNotifications) {
        const notificationToUpdate: Notification | undefined = previousNotifications?.find(
          (notification) => notification.reference === reference
        );
        if (notificationToUpdate) {
          notificationToUpdate.dateAccessed = new Date().toISOString();

          queryClient.setQueryData<Notification[] | undefined>(['notifications', group], () => {
            if (previousNotifications && notificationToUpdate) {
              return [...previousNotifications];
            }
            return undefined;
          });

          // prevent a new request because it should be fetched from the cache
          queryClient.cancelQueries(['notifications', group]);
        }
      }
      setMarkedAsRead(false);
    }
  };

  return (
    <>
      <Hidden mdDown>
        <NotificationListDesktop notifications={notifications} onClickView={readDocument} />
      </Hidden>
      <Hidden mdUp>
        <NotificationListMobile notifications={notifications} onClickView={readDocument} />
      </Hidden>

      <ViewFile reference={reference ?? ''} open={showDocument} onClose={closeDocumentView} />
    </>
  );
};

export default NotificationList;
