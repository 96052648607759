import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { renderLoanPart } from './utils/renderLoanPart';

type LoanPartSummaryProps = {
  onClick: (step: string) => void;
};

export const LoanPartSummary: FC<LoanPartSummaryProps> = ({ onClick }) => {
  const loan = useSelector((state: RootState) => state.ohaForm.loan);

  const handleOnClick = (step: string) => () => onClick(step);
  if (
    (loan.currentLoanParts.length === 0 && loan.newLoanParts.length === 0) ||
    loan.changeCurrentMortgage === 'no'
  )
    return null;
  return (
    <>
      {loan.currentLoanParts.map((part, i) =>
        renderLoanPart(part, loan.loanNumber, handleOnClick, 'current', i)
      )}
      {loan.newLoanParts.map((part, i) =>
        renderLoanPart(part, loan.loanNumber, handleOnClick, 'new', i)
      )}
    </>
  );
};

export default LoanPartSummary;
