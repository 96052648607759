import { FC } from 'react';
import { NumericFormat } from 'react-number-format';

export type PercentageDisplayProps = {
  value: number | undefined | null;
  emptyValue?: string;
};

export const PercentageDisplay: FC<PercentageDisplayProps> = ({ value, emptyValue }) => {
  return value === null || value === undefined ? (
    <>{emptyValue ?? ''}</>
  ) : (
    <NumericFormat
      thousandSeparator="."
      decimalSeparator=","
      valueIsNumericString
      decimalScale={2}
      value={value ?? 0}
      displayType="text"
      renderText={(value) => (
        <>
          {value ?? 0}
          <span>%</span>
        </>
      )}
    />
  );
};
export default PercentageDisplay;
