import { styled } from '@mui/styles';
import { Box as MuiBox, Container as MuiContainer, TableRow } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
}));

export const SearchBoxWrapper = styled(MuiContainer)(({ theme }) => ({
  flexGrow: 1,
  width: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const FilterAreaWrapper = styled(MuiBox)({
  'width': '100%',
  'display': 'flex',
  'flexGrow': 3,
  'flexDirection': 'row',
  'flexWrap': 'wrap',
  'gridColumnGap': 32,
  'gridRowGap': 8,
  'justifyContent': 'flex-end',
  '& div:nth-child(1)': {
    msGridColumn: 1,
  },
  '& div:nth-child(2)': {
    msGridColumn: 3,
  },
  '& div:nth-child(3)': {
    msGridColumn: 5,
  },
});

export const PaginationWrapper = styled(TableRow)(({ theme }) => ({
  margin: theme.spacing(4, 0),
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}));

// @ts-ignore
export const Emphasize = styled('span')<any, any>((props: any) => {
  const { theme, truncate } = props;
  const extraClass = truncate
    ? {
        display: 'table-cell',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }
    : {};
  return {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: 14,
    maxWidth: 180,
    ...extraClass,
  };
});

export const MobileTableRow = styled('div')<any, any>(({ theme, color }) => ({
  '& > *': {
    marginRight: theme.spacing(0.5),
  },
  'color': color ? theme.palette.text[color] : '',
}));
