import { ToggleType } from '../types';

const mapToggleButton = (value: ToggleType | undefined): boolean | undefined => {
  if (value === 'yes') {
    return true;
  } if (value === 'no') {
    return false;
  }
    return undefined;
};

export default mapToggleButton;
