import type { Filter } from 'api/clients';
import type { FilterState } from 'types/Client';

const mapFilters = (filterState: FilterState, delayedTerm: string): Array<Filter> => {
  const filterValuesMoneylender = filterState.moneylenderName.join(' ');

  const filterValuesEconsumerLink =
    filterState.eConsumerLink === 'hasEconsumerLink'
      ? 'true'
      : filterState.eConsumerLink === 'hasNoEconsumerLink'
      ? 'false'
      : 'all';

  const filters: Array<Filter> = [];
  if (filterValuesMoneylender !== 'all') {
    filters.push({
      filterField: 'commercialLabel',
      filterOperator: 'like',
      value: filterValuesMoneylender,
    });
  }

  if (filterValuesEconsumerLink !== 'all') {
    filters.push({
      filterField: 'HasPermission',
      filterOperator: 'equal',
      value: filterValuesEconsumerLink,
    });
  }

  if (filterState.loanType !== 'all') {
    filters.push({
      filterField: 'loanType',
      filterOperator: 'equal',
      value: filterState.loanType,
    });
  }

  if (delayedTerm && delayedTerm !== '') {
    if (isNaN(parseInt(delayedTerm, 10))) {
      filters.push({
        filterField: 'Surname',
        filterOperator: 'like',
        value: delayedTerm,
      });
    } else {
      filters.push({
        filterField: 'loanNumber',
        filterOperator: 'like',
        value: delayedTerm,
      });
    }
  }
  return filters;
};

export default mapFilters;
