import { Hidden, CircularProgress, Button } from '@mui/material';
import { buttonStyles } from '../styling';

const LoadingButton = () => {
  const classes = buttonStyles();

  return (
    <>
      <Hidden mdUp>
        <CircularProgress color="primary" size={24} />
      </Hidden>
      <Hidden smDown>
        <Button
          data-testid="upload-button"
          color="primary"
          disabled
          variant="contained"
          className={classes.button}
        >
          <CircularProgress color="inherit" size={24} />
        </Button>
      </Hidden>
    </>
  );
};

export default LoadingButton;
