import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from '../NavDrawer.styled';
import { RouteConfig } from 'react-router-config';
import Item from './Item';
import env from 'config/env';
import getLoginType from 'auth/utils/getLoginType';
import TuneIcon from '@mui/icons-material/Tune';

type OverviewRouteButtonProps = {
  onClick: (buttonName: string) => void;
  route?: RouteConfig;
};

const OverviewRouteButton: FC<OverviewRouteButtonProps> = ({ onClick, route }) => {
  const { t } = useTranslation();
  const isObserver = getLoginType(env, false) === 'keycloak-observer';

  const openRoute = () => {
    onClick(route?.name);
  };

  if (!route || isObserver) {
    return null;
  }

  return (
    <NavLink to={route.path as string} key={route.name}>
      <Item
        icon={<TuneIcon fontSize="inherit" />}
        menuName={t(`general.header.menu.${route.name}`)}
        handleClick={openRoute}
      />
    </NavLink>
  );
};
export default OverviewRouteButton;
