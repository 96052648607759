import { useQuery } from 'react-query';
import { useGroup } from './group';
import {
  Filter,
  getClientsList,
  ClientListResponse,
  getMoneylenders,
  MoneylenderResponse,
  getRelatedMoneylenders,
  RelatedMoneylenderResponse,
} from '../api/clients';

export function useQueryClients(
  isLabeled: boolean,
  commercialRelation: string | undefined,
  page: number,
  take: number,
  filters: Filter[]
) {
  const group = useGroup();
  return useQuery(
    ['client-list', isLabeled, commercialRelation, page, take, filters],
    (): Promise<ClientListResponse> => {
      const skipEntriesIndex = page * take;
      return getClientsList(isLabeled, commercialRelation, group, skipEntriesIndex, take, filters);
    }
  );
}

export function useRelatedMoneylenders() {
  const group = useGroup();
  return useQuery(['clients-relatedMoneylenders', group], () => fetchRelatedMoneylenders(group));
}

async function fetchRelatedMoneylenders(
  group: string | null
): Promise<RelatedMoneylenderResponse[]> {
  return getRelatedMoneylenders(group);
}

export function useMoneylenders() {
  const group = useGroup();
  return useQuery(
    ['clients-moneylenders', group],
    (): Promise<MoneylenderResponse[]> => getMoneylenders(group)
  );
}
