import { Person } from 'types/Client';
import {
  ApplicantReadDto,
  ConversionMutationApplication,
  CurrentLoanPartReadDto,
  CurrentPolicyReadDto,
  LoanPartInfo,
  Status,
  TypeOfLoan,
} from '__generated__/api';
import { policyDefaultValues } from 'screens/Mutations/components/ConversionMutation/constants';
import { ConversionForm, LoanPartPolicy, Policy } from '../../types';
import mapBooleanToToggleType from '../mapBooleanToToggleType';
import { mapLoanParts } from '../mapLoans';

const mapApplicants = (applicants: ApplicantReadDto[]): Person[] => {
  return applicants.map((applicant) => {
    return {
      id: applicant.id ?? 0,
      initials: applicant.initials ?? '',
      surname: applicant.lastName ?? '',
      preposition: applicant.middleName ?? '',
    };
  });
};

const mapLoanPartPolicies = (
  currentLoanParts: CurrentLoanPartReadDto[],
  loanPartsExtraInfo: LoanPartInfo[]
): LoanPartPolicy[] => {
  return currentLoanParts.map((currentLoanPart) => {
    const connectedPoliciesInfo = loanPartsExtraInfo.filter(
      (part) => part.loanPartNumber === currentLoanPart.loanPartNumber
    )[0];
    return {
      loanPartNumber: currentLoanPart.loanPartNumber ?? undefined,
      policies: mapPolicies(currentLoanPart.currentPolicies ?? [], connectedPoliciesInfo),
    };
  });
};

const mapPolicies = (
  currentPolicies: CurrentPolicyReadDto[],
  connectedPoliciesInfo: LoanPartInfo
): Policy[] => {
  if (currentPolicies.length === 0) {
    return [policyDefaultValues];
  }

  return currentPolicies.map((policy) => {
    const extrPolicyInfo = connectedPoliciesInfo?.policies?.filter(
      (policyInfo) => policyInfo.policyNumber === policy.policyNumber
    )[0];
    return {
      policyNumber: policy.policyNumber ?? undefined,
      numberOfPolicyHolders: extrPolicyInfo?.numberOfPolicyHolders ?? undefined,
      policyHolders: {
        personOne: policy.policyHolders?.[0]?.fullName ?? '',
        personTwo: policy.policyHolders?.[1]?.fullName ?? '',
      },
      numberOfInsuredPersons: extrPolicyInfo?.numberOfInsuredPeople ?? undefined,
      isPolicyForConnectedPersons: mapBooleanToToggleType(
        extrPolicyInfo?.hasPolicyInNameOfInsuredPerson
      ),
      insuredPersons: !extrPolicyInfo?.hasPolicyInNameOfInsuredPerson
        ? {
            personOne: policy.insuredPeople?.[0]?.fullName ?? '',
            personTwo: policy.insuredPeople?.[1]?.fullName ?? '',
          }
        : {},
      typeOfPolicyMutation: policy.typeOfMutation ?? undefined,
      mutationDescription: policy.mutationDescription ?? undefined,
    };
  });
};

export const mapConceptApplication = (apiData: ConversionMutationApplication): ConversionForm => {
  const { application, conversionMutationStateInfo } = apiData;
  const loanPartsExtraInfo = conversionMutationStateInfo?.loanParts;
  return {
    loanDetails: {
      additionalInformation: {
        name: application?.intermediary?.name,
        phoneNumber: application?.intermediary?.phoneNumber,
        info: application?.loan?.additionalInformation || undefined,
      },
      conversionQuestionnaire: {
        residualDebt:
          application?.currentRealEstates?.[0]?.currentLoans?.[0]?.principalAmount ?? undefined,
        loanNumber: application?.loanNumber ?? 0,
        toggleInterest: mapBooleanToToggleType(conversionMutationStateInfo?.toggleInterest),
        toggleCoFinancedFine: mapBooleanToToggleType(
          conversionMutationStateInfo?.toggleCoFinancedFine
        ),
        toggleFine: mapBooleanToToggleType(conversionMutationStateInfo?.toggleFine),
      },
      loanParts: mapLoanParts(
        application?.currentRealEstates?.[0]?.currentLoans?.[0]?.currentLoanParts ?? [],
        application?.loan?.loanParts ?? [],
        loanPartsExtraInfo ?? []
      ),
      status: application?.status ?? Status.New,
      typeOfLoan: application?.loan?.typeOfLoan ?? TypeOfLoan.Conversion,
      commercialLabel: application?.moneylender?.commercialLabel ?? '',
      moneylenderName: application?.moneylender?.name ?? '',
      id: 0,
    },
    connectedPolicies: {
      loanPartPolicies: mapLoanPartPolicies(
        application?.currentRealEstates?.[0]?.currentLoans?.[0]?.currentLoanParts ?? [],
        loanPartsExtraInfo ?? []
      ),
    },

    applicants: mapApplicants(application?.applicants ?? []),
    intermediary: application?.intermediary,
  };
};
