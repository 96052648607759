import { useMutation } from 'react-query';
import { useGroup } from 'use/group';
import { HEADER_SELECTED_GROUP_CLAIM, http } from '../../http';
import { SubmittedFeedback } from './Feedback';

export const useMutationFeedback = (feedbackObj: SubmittedFeedback) => {
  const group = useGroup();
  return useMutation(['feedback', group, feedbackObj], () => postFeedback(group, feedbackObj));
};

const postFeedback = async (group: string | null, feedbackObj: SubmittedFeedback) => {
  const response = await http.post('/api/feedback', feedbackObj, {
    headers: {
      [HEADER_SELECTED_GROUP_CLAIM]: group,
    },
  });
  return { data: response.data, status: response.status };
};
