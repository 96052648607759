import { FC, MouseEventHandler } from 'react';
import { ListItem, ListItemText, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  button: {
    width: '100px',
  },
  selected: {
    color: theme.palette.primary.main,
  },
}));

type Props = {
  name: string;
  selected?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const OfficeListItem: FC<Props> = ({ name, onClick, selected }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ListItem className={classes.listItem}>
      <ListItemText
        className={clsx([{ [classes.selected]: selected }])}
        primary={name}
      />
      <Button color="primary" variant="contained" className={classes.button} onClick={onClick}>
        {t('offices.continue')}
      </Button>
    </ListItem>
  );
};

export default OfficeListItem;
