import { FC } from 'react';
import { Grid } from '@mui/material';
import FormTitleText from 'components/Form/FormTitle';
import { yesNoGroupOptions } from 'screens/Mutations/components/ConversionMutation/constants';
import { FormInputCurrency, FormInputDropdown, FormToggleButtonGroup } from 'components/Form';
import { useTranslation } from 'react-i18next';
import { costOptions, showPersonalEquity } from '../../utils';
import { TypeOfFinancing } from '__generated__/api';
import { FieldsGrid } from 'screens/Mutations/components/DismissalJointLiabilityMutation/DismissalJointLiabilityMutation.styled';
import { SubTitle } from 'screens/Mutations/molecules/styled';

type CostAndResourcesProps = {
  title: string;
  subtitle: string;
  hasBuyoutAmount?: string;
  typeOfBuyoutFinancing?: TypeOfFinancing;
};

const CostAndResources: FC<CostAndResourcesProps> = ({
  title,
  subtitle,
  hasBuyoutAmount,
  typeOfBuyoutFinancing,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FormTitleText title={t(title)} description={t(subtitle)} />
      <SubTitle variant="h5">{t('directMutations.costAndResources.formTitle')}</SubTitle>
      <FieldsGrid container>
        <Grid item xs={12}>
          <FormToggleButtonGroup
            name="hasBuyoutAmount"
            options={yesNoGroupOptions}
            label={t('directMutations.costAndResources.hasBuyoutAmount')}
            required
          />
        </Grid>
        {hasBuyoutAmount === 'yes' && (
          <>
            <Grid item xs={12}>
              <FormInputCurrency
                name="buyoutAmount"
                label={t('directMutations.costAndResources.buyoutAmount')}
                placeholder={t('directMutations.amountPlaceholder')}
                required
                shouldUnregister
              />
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <FormInputDropdown
                  name="typeOfBuyoutFinancing"
                  label={t('directMutations.costAndResources.typeOfBuyoutFinancing')}
                  options={costOptions()}
                  required
                  shouldUnregister
                />
              </Grid>
            </Grid>
            {showPersonalEquity(typeOfBuyoutFinancing) && (
              <Grid item xs={12}>
                <FormInputCurrency
                  name="buyoutAmountByPersonalEquity"
                  label={t('directMutations.costAndResources.buyoutAmountByPersonalEquity')}
                  placeholder={t('directMutations.amountPlaceholder')}
                  required
                  shouldUnregister
                />
              </Grid>
            )}
          </>
        )}
      </FieldsGrid>
      <FieldsGrid container>
        <SubTitle variant="h5">{t('directMutations.costAndResources.costFormTitle')}</SubTitle>

        <Grid item xs={12}>
          <FormInputCurrency
            name="consultancyCosts"
            label={t('directMutations.costAndResources.consultancyCosts')}
            placeholder={t('directMutations.amountPlaceholder')}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputCurrency
            name="nhgCosts"
            label={t('directMutations.costAndResources.nhgCosts')}
            placeholder={t('directMutations.amountPlaceholder')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputCurrency
            name="notaryCosts"
            label={t('directMutations.costAndResources.notaryCosts')}
            placeholder={t('directMutations.amountPlaceholder')}
            required
          />
        </Grid>
      </FieldsGrid>
      <FieldsGrid container>
        <SubTitle variant="h5">
          {t('directMutations.costAndResources.addedPersonalEquityFormTitle')}
        </SubTitle>
        <Grid item xs={12}>
          <FormInputCurrency
            name="addedPersonalEquity"
            label={t('directMutations.costAndResources.addedPersonalEquity')}
            placeholder={t('directMutations.amountPlaceholder')}
            required
            min={0}
          />
        </Grid>
      </FieldsGrid>
    </>
  );
};

export default CostAndResources;
