import { Box, Grid, GridProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootBox = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  height: '100%',
  flexDirection: 'row',
});

export const Spinner = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  alignItems: 'center',
});

export const FieldsGrid = styled(Grid)<GridProps>({
  width: '100%',
  maxWidth: '440px',
});

export const FormGrid = styled(Grid)<GridProps>({
  width: '100%',
  maxWidth: '662px',
});

export const FormTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const AddButtonGrid = styled(Grid)({
  justifyContent: 'flex-end',
});

export const GridContainer = styled(Grid)({
  marginBottom: '24px',
});

export const GridItem = styled(Grid)({
  'marginBottom': '-36px',
  'paddingBottom': '0',
  '&.MuiGrid-item': {
    paddingRight: 0,
  },
});
