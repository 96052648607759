import type { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { IconButton, CircularProgress, IconButtonProps } from '@mui/material';

interface LoadingIconButtonProps extends IconButtonProps {
  onClick?: () => void;
  isLoading?: boolean;
  children: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  button: {
    'display': 'flex',
    'flexWrap': 'wrap',
    '&:hover': {
      borderRadius: '30px',
    },
  },
}));

export const LoadingIconButton: FC<LoadingIconButtonProps> = ({
  onClick,
  isLoading,
  children,
  className,
  type,
  color,
  size,
}) => {
  const classes = useStyles();
  const SpinnerAdornment = () => <CircularProgress size={20} />;

  return (
    <IconButton
      type={type}
      color={color}
      size={size}
      onClick={onClick}
      className={`${classes.button} ${className}`}
      disabled={isLoading}
    >
      {isLoading ? null : children}
      {isLoading && <SpinnerAdornment />}
    </IconButton>
  );
};
