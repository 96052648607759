import { FC, useState } from 'react';
import type { ClientLoan, Person } from 'types/Client';
import ClientDataItem from '../ClientData/ClientDataItem';
import { CustomerNameTypo } from 'screens/Clients/Clients.styled';
import ClientDetailInfoApplicationItem from './ClientDetailInfoApplicationItem';
import formatFullName from 'utils/formatFullName/formatFullName';

type ClientDetailHeaderProps = {
  persons: Person[];
  applications: ClientLoan[];
  mortgages: ClientLoan[];
};

const ClientDetailHeader: FC<ClientDetailHeaderProps> = ({ persons, applications, mortgages }) => {
  const [hasApplicationClientDetails, setHasApplicationClientDetails] = useState(false);

  const handleCompleteApplicationDetails = () => {
    setHasApplicationClientDetails(true);
  };

  return (
    <>
      <CustomerNameTypo>
        {persons
          .map((person) => formatFullName(person.initials, person.preposition, person.surname))
          .join(' & ')}
      </CustomerNameTypo>

      {applications.map((application, i) => (
        <ClientDetailInfoApplicationItem
          key={i}
          application={application}
          onComplete={handleCompleteApplicationDetails}
        />
      ))}
      {mortgages.map((mortgage) => (
        <ClientDataItem
          key={mortgage?.loanNumber}
          mortgage={mortgage}
          showHeader={!hasApplicationClientDetails}
        />
      ))}
    </>
  );
};

export default ClientDetailHeader;
