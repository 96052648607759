import { object, string } from 'yup';

type FromMessages = {
  emailType: string;
  emailRequired: string;
};

export const notificationPreferenceSchema = (formMessages: FromMessages) =>
  object().shape({
    contactDetails: object().shape({
      emailAddress: string()
        .email(formMessages.emailType)
        .required(formMessages.emailRequired)
        .nullable(),
    }),
  });
