import { FC, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { LoanFormType } from '../../../utils/types';
import {
  FormInputDropdown,
  FormInputNumber,
  FormInputCurrency,
  FormToggleButtonGroup,
  FormInputDate,
} from 'components/Form';
import {
  twoOrThreeGroupOptions,
  yesNoGroupOptions,
} from '../../../components/ConversionMutation/constants';
import { useTranslation } from 'react-i18next';
import { financeTypeOptions } from '../utils';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { FieldWrapper, PaddingRightGrid, TopWrapper } from '../Loan.styled';
import useUnregisterFields from 'screens/Mutations/hooks/useUnregisterField';
import { SelectOption } from 'types/SelectOption';
import FormInputPercentage from 'components/Form/FormInputPercentage';

type CurrentLoanPartProps = {
  loanPartIndex: number;
  shouldUnregister: boolean;
  productsData?: SelectOption[];
};

const CurrentLoanPart: FC<CurrentLoanPartProps> = ({
  loanPartIndex,
  shouldUnregister,
  productsData,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<LoanFormType>();
  const formLoanParts = useWatch({ control, name: 'currentLoanParts' });
  const productOptions = useSelector((state: RootState) => state.ohaForm.productOptions);
  const unregisterMultiple = useUnregisterFields();
  const {
    shouldSplitLoan,
    hasDurationPeriodChange,
    hasInterestRedemption,
    numberOfSplitLoanParts,
  } = formLoanParts[loanPartIndex];
  const rootForm = `currentLoanParts[${loanPartIndex}]`;

  useEffect(() => {
    if (!shouldUnregister) {
      if (!hasDurationPeriodChange || hasDurationPeriodChange === 'no')
        unregisterMultiple([`${rootForm}.newDuration`]);
      if (!hasInterestRedemption || hasInterestRedemption === 'no')
        unregisterMultiple([`${rootForm}.typeOfFinancing`]);
      if (!shouldSplitLoan || shouldSplitLoan === 'no')
        unregisterMultiple([`${rootForm}.numberOfSplitLoanParts`, `${rootForm}.splitLoan`]);
      if (!numberOfSplitLoanParts) unregisterMultiple([`${rootForm}.splitLoan`]);
      if (numberOfSplitLoanParts && numberOfSplitLoanParts === 2)
        unregisterMultiple([`${rootForm}.splitLoan.2`]);
      if (!shouldSplitLoan || shouldSplitLoan === 'yes')
        unregisterMultiple([`${rootForm}.convertLoan`]);
    }
  }, [
    numberOfSplitLoanParts,
    hasDurationPeriodChange,
    hasInterestRedemption,
    shouldSplitLoan,
    unregisterMultiple,
    shouldUnregister,
    rootForm,
  ]);

  return (
    <>
      <TopWrapper>
        <Grid container columnSpacing={3}>
          <Grid container item xs={12} sm={6}>
            <PaddingRightGrid item xs={8}>
              <FormInputNumber
                name="loanNumber"
                label={t('directMutations.loan.loanNumber')}
                disabled
                required
                thousandSeparator
              />
            </PaddingRightGrid>
            <Grid item xs={4}>
              <FormInputNumber
                name={`${rootForm}.loanPartNumber`}
                label={t('directMutations.loanParts.loanPartNumber')}
                min={1}
                max={999}
                required
                thousandSeparator
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormToggleButtonGroup
              name={`${rootForm}.hasConnectedPolicy`}
              label={t('directMutations.loanParts.hasConnectedPolicy')}
              options={yesNoGroupOptions}
              required
            />
          </Grid>
        </Grid>
      </TopWrapper>
      <FieldWrapper>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <FormToggleButtonGroup
              name={`${rootForm}.hasDurationPeriodChange`}
              label={t('directMutations.loanParts.hasDurationPeriodChange')}
              options={yesNoGroupOptions}
              required
            />
          </Grid>
          {hasDurationPeriodChange === 'yes' && (
            <Grid item xs={12} sm={6}>
              <FormInputNumber
                name={`${rootForm}.newDuration`}
                label={t('directMutations.loanParts.newDuration')}
                min={1}
                max={999}
                required
                shouldUnregister={shouldUnregister}
              />
            </Grid>
          )}
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <FormInputDropdown
              name={`${rootForm}.repaymentType`}
              label={t('directMutations.loanParts.repaymentType')}
              options={productsData ?? productOptions}
              placeholder={t('directMutations.loanParts.repaymentTypePlaceholder')}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputCurrency
              name={`${rootForm}.loanAmount`}
              label={t('directMutations.loanParts.amount')}
              required
              placeholder={t('directMutations.amountPlaceholder')}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <FormToggleButtonGroup
              name={`${rootForm}.hasInterestRedemption`}
              label={t('directMutations.loanParts.hasInterestRedemption')}
              options={yesNoGroupOptions}
              required
            />
          </Grid>
          {hasInterestRedemption === 'yes' && (
            <Grid item xs={12} sm={6}>
              <FormToggleButtonGroup
                name={`${rootForm}.typeOfFinancing`}
                label={t('directMutations.loanParts.typeOfFinancing')}
                options={financeTypeOptions()}
                required
                shouldUnregister={shouldUnregister}
              />
            </Grid>
          )}
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <FormInputPercentage
              name={`${rootForm}.interestPercentage`}
              label={t('directMutations.loanParts.interestPercentage')}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputNumber
              name={`${rootForm}.fixedRatePeriod`}
              label={t('directMutations.loanParts.ramainingFixedRatePeriod')}
              placeholder={t('directMutations.loanParts.placeholderRvp')}
              max={999}
              required
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <FormInputCurrency
              name={`${rootForm}.box1`}
              label={t('directMutations.loanParts.box1')}
              placeholder={t('directMutations.amountPlaceholder')}
              min={0}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputDate
              name={`${rootForm}.endDateInterestDeduction`}
              label={t('directMutations.loanParts.endDateInterestDeduction')}
              required
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <FormInputCurrency
              name={`${rootForm}.box3`}
              label={t('directMutations.loanParts.box3')}
              placeholder={t('directMutations.amountPlaceholder')}
              required
              min={0}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <FormToggleButtonGroup
              name={`${rootForm}.NHGGuarantee`}
              label={t('directMutations.loanParts.NHGGuarantee')}
              options={yesNoGroupOptions}
              required
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <FormToggleButtonGroup
              name={`${rootForm}.shouldSplitLoan`}
              label={t('directMutations.loanParts.shouldSplitLoan')}
              options={yesNoGroupOptions}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {shouldSplitLoan && shouldSplitLoan === 'yes' && (
              <FormToggleButtonGroup
                name={`${rootForm}.numberOfSplitLoanParts`}
                label={t('directMutations.loanParts.numberOfSplitLoanParts')}
                options={twoOrThreeGroupOptions}
                required
                shouldUnregister={shouldUnregister}
              />
            )}
          </Grid>
        </Grid>
      </FieldWrapper>
    </>
  );
};
export default CurrentLoanPart;
