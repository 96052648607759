import Menu from 'components/Menu';
import Item from 'components/Menu/Item';
import { useWizardStep } from 'hooks/useWizard';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { applicantName } from 'screens/Mutations/utils';
import { isFinancingActive } from '../predicates';
import { RaiseForm, Step, StepName } from '../types';
import { getStep, isApplicantStepActive } from '../utils';

type Props = {
  steps: Record<string, Step>;
};

const FormStepper: FC<Props> = ({ steps }) => {
  const { t } = useTranslation();
  const { goToStep, activeStepIndex } = useWizardStep();
  const { watch } = useFormContext<RaiseForm>();

  const applicants = watch('applicants') || [];
  const step = getStep(steps, activeStepIndex);
  let activeStep;
  if (step) {
    activeStep = step.name;
  }
  const indexOf = (steps: Record<string, Step>, name: string) => Object.keys(steps).indexOf(name);

  const goToStepByName = (name: string) => () => goToStep(indexOf(steps, name));

  const isActive = isApplicantStepActive(steps, activeStepIndex);
  const isPersonalInfoActive = isActive('personalInfo');
  const isContactDetailsActive = isActive('contactDetails');
  const isIncomeActive = isActive('income');
  const isObligationsActive = isActive('obligation');

  const isApplicantActive = (index: number) =>
    isPersonalInfoActive(index) ||
    isContactDetailsActive(index) ||
    isIncomeActive(index) ||
    isObligationsActive(index);

  const getText = (name: StepName) => t(`directMutations.stepper.${name}`);

  return (
    <Menu>
      <Item
        active={activeStep === 'applicants'}
        text={getText('applicants')}
        onClick={goToStepByName('applicants')}
      />
      {applicants.map((applicant, index) => (
        <Item
          key={index}
          text={applicantName(applicant) || t('directMutations.stepper.applicant')}
          active={isApplicantActive(index)}
          onClick={goToStepByName(`personalInfo-${index}`)}
        >
          <Item
            active={isPersonalInfoActive(index)}
            text={getText('personalInfo')}
            onClick={goToStepByName(`personalInfo-${index}`)}
          />
          <Item
            active={isContactDetailsActive(index)}
            text={getText('contactDetails')}
            onClick={goToStepByName(`contactDetails-${index}`)}
          />
          <Item
            active={isIncomeActive(index)}
            text={getText('income')}
            onClick={goToStepByName(`income-${index}`)}
          />
          <Item
            active={isObligationsActive(index)}
            text={getText('obligation')}
            onClick={goToStepByName(`obligation-${index}`)}
          />
        </Item>
      ))}

      <Item
        active={activeStep === 'collateral'}
        text={getText('collateral')}
        onClick={goToStepByName('collateral')}
      />
      <Item
        active={isFinancingActive(activeStep)}
        text={t('directMutations.stepper.financing')}
        onClick={goToStepByName('financingCostAndResources')}
      >
        <Item
          active={activeStep === 'financingCostAndResources'}
          text={getText('financingCostAndResources')}
          onClick={goToStepByName('financingCostAndResources')}
        />
        <Item
          active={activeStep === 'financingLoan'}
          text={getText('financingLoan')}
          onClick={goToStepByName('financingLoan')}
        />
        {steps?.financingConnectedPolicy && (
          <Item
            active={activeStep === 'financingConnectedPolicy'}
            text={getText('financingConnectedPolicy')}
            onClick={goToStepByName('financingConnectedPolicy')}
          />
        )}
      </Item>

      <Item
        active={activeStep === 'additionalInfo'}
        text={getText('additionalInfo')}
        onClick={goToStepByName('additionalInfo')}
      />
      <Item
        active={activeStep === 'summary'}
        text={getText('summary')}
        onClick={goToStepByName('summary')}
      />
    </Menu>
  );
};

export default FormStepper;
