import type { FC } from 'react';
import { Grid, GridProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { getIncomeAmount } from '../../utils';
import { useFormContext, useWatch } from 'react-hook-form';
import { sum } from 'utils/array';
import { formatIncome } from 'screens/Mutations/components/DismissalJointLiabilityMutation/utils';

const StyledGrid = styled(Grid)<GridProps>(({ theme }) => ({
  maxWidth: '662px',
  padding: theme.spacing(2, 9, 2, 4),
  backgroundColor: theme.palette.grey[200],
}));

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '12px',
  lineHeight: '19px',
}));

type Props = {
  rootFormName?: string;
};
const TotalIncome: FC<Props> = ({ rootFormName }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const incomes = useWatch({ name: `${rootFormName || ''}incomes`, control });
  const totalIncome = sum(incomes.map(getIncomeAmount));

  return (
    <StyledGrid container>
      <Grid item xs={8}>
        <Text>{t('directMutations.income.totalIncome')}</Text>
      </Grid>
      <Grid item xs={4}>
        <Text>{formatIncome(totalIncome)}</Text>
      </Grid>
    </StyledGrid>
  );
};

export default TotalIncome;
