import { Close } from '@mui/icons-material';
import { Divider, Grid, Hidden, IconButton } from '@mui/material';
import Logo from 'components/Logo';
import { useTranslation } from 'react-i18next';
import { Icon, MenuItemWapper } from '../NavDrawer.styled';
import Item from './Item';

type HeaderMenuProps = {
  commercialRelation: string | null;
  handleHomePageNavigation: () => () => void;
  closeDrawer: () => void;
};

export default function HeaderMenu({
  commercialRelation,
  handleHomePageNavigation,
  closeDrawer,
}: HeaderMenuProps) {
  const { t } = useTranslation();

  if (!commercialRelation) {
    return (
      <Grid container p={0} direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Hidden smUp>
            <IconButton onClick={closeDrawer}>
              <Close />
            </IconButton>
          </Hidden>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container direction="column" pl={1} mt={-1} mb={1}>
        <Grid>
          <Logo label={commercialRelation} size="M" isTitle={false} />
        </Grid>

        <Grid item>
          <Hidden smUp>
            <IconButton onClick={closeDrawer}>
              <Close />
            </IconButton>
          </Hidden>
        </Grid>
      </Grid>
      <Divider />
      <MenuItemWapper
        container
        mt={0}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pb={1}
      >
        <Grid item xs={12}>
          <Item
            icon={<Icon>chevron_left</Icon>}
            menuName={t('general.header.menu.back')}
            handleClick={handleHomePageNavigation()}
          />
        </Grid>
      </MenuItemWapper>
    </>
  );
}
