import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Icon,
  IconButton,
} from '@mui/material/';

const useStyles = makeStyles((theme) => ({
  title: {
    width: 400,
  },
  padding: {
    padding: theme.spacing(2, 3),
  },
  dialogBtns: {
    padding: theme.spacing(3),
    justifyContent: 'flex-start',
  },
}));

export type ConfirmationDialogProps = {
  dialogTitle: string;
  confirmButtonText: string;
  cancelButtonText: string;
  open: boolean;
  handleClose: () => void;
  onConfirm: () => void;
};

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  dialogTitle,
  confirmButtonText,
  cancelButtonText,
  open,
  handleClose,
  onConfirm,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="delete-applicant-confirmation-dialog"
    >
      <Box className={classes.padding}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <DialogTitle className={classes.title} id="delete-applicant-confirmation-dialog">
            {dialogTitle}
          </DialogTitle>
          <IconButton onClick={handleClose} size="large">
            <Icon>close</Icon>
          </IconButton>
        </Box>
        <DialogActions classes={{ root: classes.dialogBtns }}>
          <Button onClick={onConfirm} variant="contained" color="primary">
            {confirmButtonText}
          </Button>
          <Button autoFocus onClick={handleClose} variant="outlined" color="primary">
            {cancelButtonText}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
