import i18n from 'i18n';
import { ReasonIncreaseOptions } from '../types';

// todo the variable names has to be in sync with the backend values.
export const getReasonIncreaseOpt = (type: ReasonIncreaseOptions) => ({
  label: i18n.t(`directMutations.loan.reasonIncreaseType.${type}`),
  value: type,
});

export const getReasonIncreaseOptions = () => [
  getReasonIncreaseOpt('redemptionRight'),
  getReasonIncreaseOpt('consumer'),
  getReasonIncreaseOpt('fundingChangeCosts'),
  getReasonIncreaseOpt('qualityImprovement'),
  getReasonIncreaseOpt('restructuringPlan'),
  getReasonIncreaseOpt('multipleFundingGoals'),
  getReasonIncreaseOpt('Overdraft'),
  getReasonIncreaseOpt('buyoutHeiress'),
  getReasonIncreaseOpt('acquisitionInOwnership'),
];
