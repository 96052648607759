import { FC, useEffect } from 'react';
import { Divider, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LoanFormType } from 'screens/Mutations/utils/types';
import { RootState } from 'store';
import {
  FormInputNumber,
  FormInputDropdown,
  FormToggleButtonGroup,
  FormInputDate,
  FormInputCurrency,
} from 'components/Form';

import {
  Accordion,
  AccordionDetails,
  AccordionPaddingSummary,
  FieldWrapper,
  TopWrapper,
} from '../Loan.styled';
import LoanPartHeaderValues from '../LoanPartHeaderValues';
import { yesNoGroupOptions } from 'screens/Mutations/constants';
import useUnregisterFields from 'screens/Mutations/hooks/useUnregisterField';
import { SelectOption } from 'types/SelectOption';
import { findRepaymentTypeDisplayName } from '../utils';
import FormInputPercentage from 'components/Form/FormInputPercentage';

type SplitLoanProps = {
  loanPartIndex: number;
  splitLoanIndex: number;
  expanded: boolean;
  shouldUnregister: boolean;
  productsData?: SelectOption[];
};

export const SplitLoan: FC<SplitLoanProps> = ({
  loanPartIndex,
  splitLoanIndex,
  expanded,
  shouldUnregister,
  productsData,
}) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<LoanFormType>();
  const unregisterMultiple = useUnregisterFields();
  const rootFormField = `currentLoanParts.${loanPartIndex}.splitLoan.${splitLoanIndex}`;
  const {
    hasDurationPeriodChange,
    amount,
    repaymentType,
    fixedRatePeriod,
    box1,
    box3,
    interestPercentage,
    endDateInterestDeduction,
  } = watch(`currentLoanParts.${loanPartIndex}.splitLoan.${splitLoanIndex}`) || {};

  const productOptions = useSelector((state: RootState) => state.ohaForm.productOptions);
  const repaymentTypeDisplayName = findRepaymentTypeDisplayName(productsData, repaymentType);

  useEffect(() => {
    if (!shouldUnregister) {
      if (!hasDurationPeriodChange || hasDurationPeriodChange === 'no')
        unregisterMultiple([`${rootFormField}.hasDurationPeriodChange`]);
    }
  }, [hasDurationPeriodChange, unregisterMultiple, rootFormField, shouldUnregister]);

  return (
    <Accordion expanded={expanded} key={`splitLoan-${loanPartIndex}-${splitLoanIndex}`}>
      <AccordionPaddingSummary
        aria-controls={`panel${loanPartIndex}-splitLoan`}
        id={`panel${loanPartIndex}-splitLoan`}
      >
        <LoanPartHeaderValues
          typeOfPart="splitLoan"
          loanPartIndex={splitLoanIndex}
          amount={amount}
          repaymentType={repaymentTypeDisplayName}
          fixedRatePeriod={fixedRatePeriod}
          box1={box1}
          box3={box3}
          interestPercentage={interestPercentage}
          endDateInterestDeduction={endDateInterestDeduction}
        />
      </AccordionPaddingSummary>
      <AccordionDetails>
        <Divider />
        <TopWrapper>
          <Grid container columnSpacing={3}>
            <Grid item xs={12} sm={6}>
              <FormToggleButtonGroup
                name={`${rootFormField}.hasDurationPeriodChange`}
                label={t('directMutations.loanParts.hasDurationPeriodChange')}
                options={yesNoGroupOptions}
                required
                shouldUnregister={shouldUnregister}
              />
            </Grid>
            {hasDurationPeriodChange === 'yes' && (
              <Grid item xs={12} sm={6}>
                <FormInputNumber
                  name={`${rootFormField}.newDuration`}
                  label={t('directMutations.loanParts.newDuration')}
                  max={999}
                  required
                />
              </Grid>
            )}
          </Grid>
        </TopWrapper>
        <FieldWrapper>
          <Grid container columnSpacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInputDropdown
                name={`${rootFormField}.repaymentType`}
                label={t('directMutations.loanParts.repaymentType')}
                options={productsData ?? productOptions}
                placeholder={t('directMutations.loanParts.repaymentTypePlaceholder')}
                required
                shouldUnregister={shouldUnregister}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInputCurrency
                name={`${rootFormField}.amount`}
                label={t('directMutations.loanParts.amount')}
                placeholder={t('directMutations.amountPlaceholder')}
                required
                shouldUnregister={shouldUnregister}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInputPercentage
                name={`${rootFormField}.interestPercentage`}
                label={t('directMutations.loanParts.interestPercentage')}
                required
                shouldUnregister={shouldUnregister}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInputNumber
                name={`${rootFormField}.fixedRatePeriod`}
                label={t('directMutations.loanParts.fixedRatePeriod')}
                placeholder={t('directMutations.loanParts.placeholderRvp')}
                max={999}
                required
                shouldUnregister={shouldUnregister}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInputCurrency
                name={`${rootFormField}.box1`}
                label={t('directMutations.loanParts.box1')}
                placeholder={t('directMutations.amountPlaceholder')}
                required
                min={0}
                shouldUnregister={shouldUnregister}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInputDate
                name={`${rootFormField}.endDateInterestDeduction`}
                label={t('directMutations.loanParts.endDateInterestDeduction')}
                required
                shouldUnregister={shouldUnregister}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInputCurrency
                name={`${rootFormField}.box3`}
                label={t('directMutations.loanParts.box3')}
                placeholder={t('directMutations.amountPlaceholder')}
                required
                min={0}
                shouldUnregister={shouldUnregister}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={3}>
            <Grid item xs={12} sm={6}>
              <FormToggleButtonGroup
                name={`${rootFormField}.NHGGuarantee`}
                label={t('directMutations.loanParts.NHGGuarantee')}
                options={yesNoGroupOptions}
                required
                shouldUnregister={shouldUnregister}
              />
            </Grid>
          </Grid>
        </FieldWrapper>
      </AccordionDetails>
    </Accordion>
  );
};

export default SplitLoan;
