import React, { FC } from 'react';
import { List, ListItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SolutionState, Action } from '../../store/taskReducer';
import { ProofItem } from 'types/ProofItem';
import TaskListIssueGroupHeading from './TaskListIssueGroupHeading';
import TaskListItem from './TaskListItem';
import clsx from 'clsx';
import { Case, TaskDetails } from '../../__generated__/api-di';
import SolveRequestAlert from './SolveRequestAlert';
import { groupBy } from 'utils/array';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    alignItems: 'flex-start',
  },
  item: {
    display: 'block',
  },
}));

const TaskList: FC<{
  task: TaskDetails;
  application: Case;
  proofItems: Array<ProofItem>;
  solutionState: SolutionState;
  dispatch: React.Dispatch<Action>;
}> = ({ task, application, proofItems, solutionState, dispatch }) => {
  const classes = useStyles();
  const groupedIssues = groupBy(task.context?.issues ?? [], (issue) => issue.description!);

  return (
    <>
      <SolveRequestAlert task={task} />
      <List className={clsx([classes.root])}>
        {Array.from(groupedIssues.keys()).map((key) => {
          const issues = groupedIssues.get(key) ?? [];
          return (
            <ListItem key={key} className={clsx([classes.item])} data-testid="issue-list-item">
              <TaskListIssueGroupHeading
                description={key}
                issues={issues}
                solutionState={solutionState}
              />
              <List disablePadding>
                {issues.map((issue) => {
                  const solutions = solutionState.get(issue.id!) ?? [];
                  return (
                    <ListItem key={`${issue.id}`} className={clsx([classes.item])}>
                      <TaskListItem
                        application={application}
                        task={task}
                        issue={issue}
                        proofItems={proofItems}
                        solutions={solutions}
                        solutionState={solutionState}
                        dispatch={dispatch}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default TaskList;
