import { Grid, Container } from '@mui/material';
import { FC } from 'react';
import { uploadOptionsArray, valuationTypeDropdownOptions } from '../../constants';
import { useTranslation } from 'react-i18next';
import { FormInputDropdown, FormInputNumber } from 'components/Form';
import SubForm from './SubForm';
import FormTitleText from 'components/Form/FormTitle';
import { CustomSubText } from 'components/Form/Forms.styled';
import { useFormContext } from 'react-hook-form';
import UploadArea from 'components/UploadArea';
import UploadOption from 'components/UploadArea/components/UploadOption/UploadOption';
import { useGoogleTagManager } from 'use/gtm';

export type ValuationType = 'valuationReport' | 'WOZ-order' | 'hybridValuation';

type CollateralWrapperProps = {
  handleError: (errorStatus: string, size: number) => void;
};

const CollateralValuesFormWrapper: FC<CollateralWrapperProps> = ({ handleError }) => {
  const { watch, setValue } = useFormContext();
  const { t } = useTranslation();
  const googleTagManager = useGoogleTagManager();

  const valuationType: ValuationType | undefined = watch(
    'collateralValueQuestionnaire.valuationType'
  );

  const handleUploadSuccess = (documentData: Blob) => {
    setValue('collateralValueQuestionnaire.formFile', documentData);

    googleTagManager({
      event: 'upload',
      btnName: 'UploadDocumentBrowse',
      fileType: documentData.type,
      uploadSuccesful: true,
      funnel: 'CollateralValues',
    });
  };

  const handleDeleteFile = () => {
    setValue('collateralValueQuestionnaire.formFile', undefined, {
      shouldValidate: true,
    });
  };

  const descriptionLines = t(
    'directMutations.collateralValueStepper.firstStep.pageDescription'
  ).split('\n');

  return (
    <Container>
      <FormTitleText
        title={t('directMutations.collateralValueStepper.firstStep.pageTitle')}
        description={descriptionLines}
      />
      <Grid item xs={12} sm={6}>
        <CustomSubText variant="h5">
          {t('directMutations.collateralValueStepper.firstStep.pageSubTitle')}
        </CustomSubText>
        <FormInputNumber
          name="collateralValueQuestionnaire.loanNumber"
          label={t('directMutations.form.loanNumber')}
          disabled
          thousandSeparator
          decimalScale={0}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomSubText variant="h5">
          {t('directMutations.collateralValueStepper.firstStep.pageFormSubTitle')}
        </CustomSubText>
        <FormInputDropdown
          required
          name="collateralValueQuestionnaire.valuationType"
          label={t('directMutations.form.valuationType')}
          options={valuationTypeDropdownOptions}
          placeholder={t('directMutations.form.valuationTypePlaceHolder')}
        />
      </Grid>
      {valuationType && (
        <Grid item xs={12} sm={6}>
          <SubForm valuationType={valuationType} />
        </Grid>
      )}
      <Grid item sm={12}>
        <UploadArea
          title={t('directMutations.collateralValueStepper.firstStep.uploadAreaTitle')}
          description={t('directMutations.collateralValueStepper.firstStep.uploadAreaSubTitle')}
        >
          <UploadOption
            handleUploadSuccess={handleUploadSuccess}
            handleDeleteFile={handleDeleteFile}
            handleError={handleError}
            handleOnChange={(e: any) => {
              setValue('collateralValueQuestionnaire.formFile', e.target.files[0], {
                shouldValidate: true,
              });
            }}
            name="collateralValueQuestionnaire.formFile"
            required
            {...uploadOptionsArray[0]}
          />
        </UploadArea>
      </Grid>
    </Container>
  );
};

export default CollateralValuesFormWrapper;
