import { ClientDetailsModel } from 'types/ClientDetailsModel';
import { HEADER_SELECTED_GROUP_CLAIM, http } from '../http';
import currentDeployment from './helper/currentDeployment';

export const getClientData = async (
  group: string | null,
  commercialLabel: string,
  loanNumber: number
): Promise<ClientDetailsModel> => {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group, 'x-deployment': currentDeployment() };

  const response = await http.get(`/api/v1/clients/${commercialLabel}/${loanNumber}/loandata`, {
    headers,
  });
  return response.data;
};
