import { useTranslation } from 'react-i18next';
import { FC, MouseEventHandler } from 'react';
import { Box, Icon, PreviousButton, SubmitButton, SummaryButton } from './BottomNavigation.styled';
import { Icon as MUIIcon } from '@mui/material';
import { LoadingFab } from 'components/LoadingButton';

export type BottomNavigationProps = {
  nextButtonStyleType?: 'submit' | 'button';
  hasPreviousStep?: boolean;
  isSubmitting?: boolean;
  isSummaryDisabled?: boolean;
  onPreviousClick?: MouseEventHandler<HTMLButtonElement>;
  onNextClick?: MouseEventHandler<HTMLButtonElement>;
  onSummaryClick?: MouseEventHandler<HTMLButtonElement>;
  nextButtonType?: 'submit' | 'button';
  isNextDisabled?: boolean;
};

const BottomNavigation: FC<BottomNavigationProps> = ({
  nextButtonStyleType = 'button',
  hasPreviousStep,
  isSubmitting,
  isSummaryDisabled = true,
  onPreviousClick,
  onNextClick,
  onSummaryClick,
  nextButtonType = 'button',
  isNextDisabled,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {hasPreviousStep && (
        <PreviousButton
          color="secondary"
          size="medium"
          onClick={onPreviousClick}
          data-testid="mutation-previous-button"
        >
          <MUIIcon color="primary">arrow_left</MUIIcon>
        </PreviousButton>
      )}
      <Box>
        {nextButtonStyleType !== 'submit' && (
          <SummaryButton
            color="secondary"
            variant="extended"
            size="medium"
            disabled={isSummaryDisabled}
            onClick={onSummaryClick}
            data-testid="mutation-summary-button"
          >
            {t('directMutations.navigate.summary')}
            <Icon>list</Icon>
          </SummaryButton>
        )}
        {nextButtonStyleType === 'submit' && (
          <SubmitButton
            type="submit"
            styleType="submit"
            isLoading={isSubmitting ?? false}
            data-testid="mutation-submit-button"
          >
            {t('directMutations.navigate.submitForm')}
            <Icon>arrow_right</Icon>
          </SubmitButton>
        )}
        {nextButtonStyleType === 'button' && (
          <LoadingFab
            isLoading={isSubmitting ?? false}
            type={nextButtonType}
            onClick={onNextClick}
            disabled={isNextDisabled}
            testid="mutation-next-button"
          >
            {t('directMutations.navigate.nextStep')}
            <Icon>arrow_right</Icon>
          </LoadingFab>
        )}
      </Box>
    </>
  );
};
export default BottomNavigation;
