import { CardRow, CardSection } from 'components/SummaryCard';
import { formatLoanNumber } from 'utils/global';
import { t } from 'i18next';
import { Policy } from 'screens/Mutations/utils/types';
import { formatAmount } from 'utils/formatAmount';
import { formatTypeOfMutation } from 'utils/formatTypeOfMutation';
import { formatYesNo } from 'utils/formatYesNo/formatYesNo';

export const renderConnectedPolicies = (
  title: string,
  loanNumber: number,
  loanAmount?: number,
  policies?: Policy[]
) => {
  if (!policies || policies.length === 0) return undefined;

  return policies.map((policy) => (
    <CardSection title={t(title)} key={policy.policyNumber}>
      <CardRow name={t('directMutations.form.loanNumber')} value={formatLoanNumber(loanNumber)} />
      <CardRow name={t('directMutations.loanParts.loanAmount')} value={formatAmount(loanAmount)} />
      <CardRow name={t('directMutations.summary.loanParts.policy')} value={policy.policyNumber} />
      <CardRow
        name={`${t('directMutations.form.loanAmount')} ${t(
          'directMutations.summary.loanParts.policy'
        )}`}
        value={formatAmount(policy.policyAmount)}
      />
      <CardRow
        name={t('directMutations.summary.loanParts.policyHolders')}
        value={policy.numberOfPolicyHolders}
      />
      <CardRow
        name={t('directMutations.policies.namePolicyHolder')}
        value={policy.policyHolders?.personOne}
      />
      {policy.numberOfPolicyHolders === 2 && (
        <CardRow
          name={t('directMutations.policies.namePolicyHolder')}
          value={policy.policyHolders?.personTwo}
        />
      )}
      <CardRow
        name={t('directMutations.summary.loanParts.policyOnName')}
        value={formatYesNo(policy.isPolicyForConnectedPersons)}
      />
      {policy.isPolicyForConnectedPersons === 'no' && (
        <CardRow
          name={t('directMutations.summary.loanParts.insuredPersons')}
          value={policy.numberOfInsuredPersons}
        />
      )}

      {policy.isPolicyForConnectedPersons === 'no' && (
        <CardRow
          name={t('directMutations.summary.loanParts.insuredPerson')}
          value={policy.insuredPersons?.personOne}
        />
      )}
      {policy.numberOfInsuredPersons === 2 && (
        <CardRow
          name={t('directMutations.summary.loanParts.insuredPerson')}
          value={policy.insuredPersons?.personTwo}
        />
      )}
      <CardRow
        name={t('directMutations.summary.loanParts.typeOfChange')}
        value={formatTypeOfMutation(policy.typeOfPolicyMutation)}
      />
      <CardRow
        name={t('directMutations.summary.loanParts.description')}
        value={policy.mutationDescription}
      />
    </CardSection>
  ));
};
