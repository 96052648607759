import { TypeOfPolicyMutation } from '../../__generated__/api';

export const formatTypeOfMutation = (value: string | undefined) => {
  switch (value) {
    case TypeOfPolicyMutation.Altered:
      return 'Aangepast';
    case TypeOfPolicyMutation.Ended:
      return 'Beëindigd';
    case TypeOfPolicyMutation.Continued:
      return 'Voortgezet';
    default:
      return '-';
  }
};
