import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    'display': 'flex',
    'flexDirection': 'row',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  step: {
    'height': 4,
    'width': 48,
    'backgroundColor': theme.palette.lightest.dark,
    '&:first-of-type': {
      borderRadius: '2px 0 0 2px',
    },
    '&:last-child': {
      borderRadius: '0 2px 2px 0',
    },
  },
  filled: {
    backgroundColor: theme.palette.success.main,
  },
}));

const OnboardingStepProgress = (props: any) => {
  const { currentStep } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={clsx([classes.step, { [classes.filled]: currentStep > 0 }])} />
      <div className={clsx([classes.step, { [classes.filled]: currentStep > 1 }])} />
      <div className={clsx([classes.step, { [classes.filled]: currentStep > 2 }])} />
      <div className={clsx([classes.step, { [classes.filled]: currentStep > 3 }])} />
      <div className={clsx([classes.step, { [classes.filled]: currentStep > 4 }])} />
    </div>
  );
};

export default OnboardingStepProgress;
