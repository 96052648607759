import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { setOnboardingJourneyVisible } from 'store/appReducer';

export function useOnboardingNotification() {
  const dispatch = useAppDispatch();

  const setJourneyVisible = useCallback(
    (visible: boolean) => dispatch(setOnboardingJourneyVisible(visible)),
    [dispatch]
  );
  const onboardingJourneyVisible = useAppSelector((state) => !!state.app.onboardingJourneyVisible);

  return {
    setJourneyVisible,
    onboardingJourneyVisible,
  };
}
