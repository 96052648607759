import { errorMessages } from '../../../constants';
import { object, string } from 'yup';

const getPoliceHolders = () =>
  object().when('numberOfPolicyHolders', {
    is: (val: number) => val === 1,
    then: object().shape({
      personOne: string().required(errorMessages.required),
    }),
    otherwise: object().shape({
      personOne: string().required(errorMessages.required),
      personTwo: string().required(errorMessages.required),
    }),
  });
export default getPoliceHolders;
