export const errorMessages = {
  required: 'Dit veld is verplicht',
  date: 'Datum niet herkend, gebruik het formaat DD-MM-JJJJ',
  number: 'Dit veld mag alleen cijfers bevatten',
  notNumber: 'Dit veld mag geen cijfers bevatten',
  // eslint-disable-next-line no-template-curly-in-string
  maxNumber: 'Dit veld mag niet groter zijn dan ${max}',
  noSpecialCharacters: 'Dit veld mag geen speciale tekens bevatten',
  email: 'Dit is geen geldig e-mailadres',
  postal: 'Postcode niet herkend, gebruik het formaat 1111AA',
  noNumberAndSpecialCharacter: 'Dit veld mag geen cijfers en/of speciale tekens bevatten',
  noFutureDate: 'De datum moet in het verleden liggen',
  noPastDate: 'De datum moet in de toekomst liggen',
  afterStartDate: 'De einddatum moet na de startdatum komen.',
  onlyLetterAndSpecialCharacters: 'Dit veld mag alleen uit letters en leestekens bestaan',
  max250Letters: 'Dit veld mag maximaal 250 tekens bevatten',
};
