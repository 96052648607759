import { LinearProgress } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

export const BorderLinearProgress = withStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1, 0),
      height: 32,
      borderRadius: 5,
      width: '100%',
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[200],
    },
    bar: {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: 1,
    },
  })
)(LinearProgress);

export const LoanPartsProgress = withStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1, 0),
      height: 16,
      borderRadius: 3,
      width: '100%',
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[200],
    },
    bar: {
      borderRadius: 1,
      backgroundColor: theme.palette.primary.light,
    },
  })
)(LinearProgress);
