import { SummaryCard, CardRow, CardSection } from 'components/SummaryCard';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { OtherIncome } from 'screens/Mutations/utils/types';
import { formatAmount } from 'utils/formatAmount';
import { getTypeOfOtherIncomeOption } from 'screens/Mutations/constants';
import formatDate from 'utils/formatDate';

type OtherProps = {
  income: OtherIncome;
  onClick: () => void;
};

const Other: FC<OtherProps> = ({ income, onClick }) => {
  const { t } = useTranslation();

  const root = 'directMutations.income.otherIncome';

  return (
    <>
      <SummaryCard title={t('directMutations.income.formTitle')} onEditClick={onClick}>
        <CardSection>
          <CardRow
            name={t('directMutations.income.typeOfIncome')}
            value={t('directMutations.income.incomeTypes.Other')}
          />
          <CardRow
            name={t(`${root}.typeOfOtherIncome`)}
            value={
              income.typeOfOtherIncome && getTypeOfOtherIncomeOption(income.typeOfOtherIncome).label
            }
          />
          <CardRow name={t(`${root}.incomeAmount`)} value={formatAmount(income.incomeAmount)} />
          <CardRow name={t(`${root}.endDate`)} value={formatDate(income.endDate, '-')} />
        </CardSection>
      </SummaryCard>
    </>
  );
};

export default Other;
