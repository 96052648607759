import { object, string, number, array } from 'yup';
import { errorMessages } from 'screens/Mutations/constants';
import {
  currentLoanPartSchema,
  newLoanPartsSchema,
} from 'screens/Mutations/components/DismissalJointLiabilityMutation/schema/loan';

const loanSchemaBase = object().shape({
  loanNumber: number().nullable(),
  amount: number().typeError(errorMessages.number).required(errorMessages.required),
  amountRaise: number().typeError(errorMessages.number).required(errorMessages.required),
  reasonIncrease: string().required(errorMessages.required),
  changeLifeInsurancePolicy: string().required(errorMessages.required).nullable(),
  changeCurrentMortgage: string().required(errorMessages.required).nullable(),
});

export const loanSchema = object({
  financingLoan: loanSchemaBase,
  currentLoanParts: array().when('financingLoan.changeCurrentMortgage', {
    is: (val: any) => val === 'yes',
    then: currentLoanPartSchema(),
  }),
  newLoanParts: newLoanPartsSchema(),
});
