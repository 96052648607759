import currency from 'currency.js';

export const formatPercentage = (
  percentage: string | number | undefined | null
): string | undefined => {
  if (!percentage) return undefined;

  return `${currency(percentage, {
    separator: '.',
    decimal: ',',
    symbol: '',
    precision: 1,
  }).format()}%`;
};

export default formatPercentage;
