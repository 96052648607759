import cityName from 'utils/form/validations/cityName';
import { object, string, number } from 'yup';
import {
  errorMessages,
  NO_NUMBER_AND_SPECIAL_CHAR_REGX,
  NO_SPECIAL_CHAR_REGX,
} from 'screens/Mutations/constants';

export const contactDetailsSchema = object().shape({
  street: string()
    .matches(NO_NUMBER_AND_SPECIAL_CHAR_REGX, errorMessages.noNumberAndSpecialCharacter)
    .required(errorMessages.required),
  houseNumber: number().required(errorMessages.required).typeError(errorMessages.required),
  houseNumberSuffix: string().matches(NO_SPECIAL_CHAR_REGX, errorMessages.noSpecialCharacters),
  postalCode: string()
    .matches(NO_SPECIAL_CHAR_REGX, errorMessages.noSpecialCharacters)
    .required(errorMessages.required),
  city: cityName.required(errorMessages.required),
  country: string().nullable().required(errorMessages.required),
  phoneNumber: string(),
  email: string().email(errorMessages.email).required(errorMessages.required),
});
