import { useState, FC } from 'react';
import {
  BaseTextFieldProps,
  InputAdornment,
  InputProps,
  TextField,
  InputBaseComponentProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { FormField } from '../../screens/BuyToLet/BuyToLetForm/form/FormField';
import { Grid, GridSize } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  startAdornment: {
    background: theme.palette.white.main,
  },
  disabled: {
    background: theme.palette.grey[200],
  },
}));

export interface NumberFormatCustomProps extends InputBaseComponentProps {
  name?: string;
  onChange?: (value: any) => void;
  min?: number;
  max?: number;
  allowNegative?: boolean;
  containerWidth?: GridSize;
  fixedDecimalScale?: boolean;
}

const NumberFormatCustom: FC<NumberFormatCustomProps> = (props) => {
  const { inputRef, onChange, min, max, ...other } = props;

  const handleChange = (values: NumberFormatValues) => {
    onChange &&
      onChange({
        target: {
          name: props.name,
          value: values.value,
        },
      });
  };

  const validateInput = (values: NumberFormatValues) => {
    const { floatValue } = values;
    let valid = true;
    valid = valid && (max === undefined || floatValue === undefined || floatValue <= max);
    valid = valid && (min === undefined || floatValue === undefined || floatValue >= min);
    return valid;
  };

  return (
    <NumericFormat
      {...other}
      defaultValue=""
      valueIsNumericString
      getInputRef={inputRef}
      isAllowed={validateInput}
      onValueChange={handleChange}
      decimalSeparator=","
      thousandSeparator="."
      allowLeadingZeros={false}
      allowedDecimalSeparators={['.']}
      fixedDecimalScale={true}
    />
  );
};

export interface ChangeCurrencyFieldProps extends BaseTextFieldProps {
  onBlur?: InputProps['onBlur'];
  onFocus?: InputProps['onFocus'];
  onChange?: (value: number | null) => void;
  min?: number;
  max?: number;
  allowNegative?: boolean;
  allowDecimals?: boolean;
  containerWidth?: GridSize;
  fixedDecimalScale?: boolean;
}

const CurrencyField: FC<ChangeCurrencyFieldProps> = (props) => {
  const {
    label,
    value,
    placeholder,
    name,
    disabled,
    onChange,
    onFocus,
    onBlur,
    error,
    required,
    containerWidth,
    min,
    max = 999999999999.99,
    allowNegative = false,
    allowDecimals = true,
    ...other
  } = props;
  const classes = useStyles();
  const placeholderText = placeholder || label?.toString();

  const [focused, setFocused] = useState<boolean>(false);
  const [fixedDecimalScale, setFixedDecimalScale] = useState(props.fixedDecimalScale ?? false);

  const parsedValue: any = typeof value === 'number' ? parseFloat(`${value}`) : '';

  return (
    <FormField label={label} required={required} open className={focused ? 'Mui-focused' : ''}>
      <Grid container item spacing={3}>
        <Grid item container xs={containerWidth || 6}>
          <TextField
            {...other}
            type="text"
            style={{ marginTop: '0' }}
            label={null}
            hiddenLabel
            name={name}
            disabled={disabled}
            placeholder={placeholderText}
            value={parsedValue}
            error={error}
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              if (onChange) {
                onChange(e.target.value !== '' ? parseFloat(e.target.value) : null);
              }
            }}
            onFocus={(event) => {
              setFocused(true);
              if (onFocus) {
                onFocus(event);
              }
            }}
            onBlur={(event) => {
              setFocused(false);
              setFixedDecimalScale(Boolean(props.fixedDecimalScale) || parsedValue % 1 !== 0);
              if (onBlur) {
                onBlur(event);
              }
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">€ </InputAdornment>,
              classes: { adornedStart: classes.startAdornment, disabled: classes.disabled },
              inputComponent: NumberFormatCustom,
            }}
            inputProps={{
              min: min ?? 0,
              max: max,
              allowNegative: allowNegative,
              fixedDecimalScale: fixedDecimalScale,
              decimalScale: allowDecimals ? 2 : 0,
              style: { textAlign: 'right' },
              autoComplete: 'off',
            }}
          />
        </Grid>
      </Grid>
    </FormField>
  );
};

export default CurrencyField;
