import { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Icon, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ReactComponent as ChevronDown } from '../../assets/images/icons/select-icon.svg';

const useStyles = makeStyles((theme) => ({
  label: {
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
  box: {
    width: '100%',
  },
  selectMenu: {
    'maxHeight': 500,
    'padding': theme.spacing(0, 1),
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  menuItem: {
    'display': 'flex',
    'height': 50,
    'paddingLeft': theme.spacing(2),
    'paddingRight': theme.spacing(2),
    'color': theme.palette.text.secondary,
    '&:hover, &.Mui-selected:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette.lightest.light,
    },
  },
  menuItemSelected: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  spacer: {
    flex: 1,
  },
}));

type Props = {
  label: string;
  selectedValue: string;
  handleChange: (e: SelectChangeEvent) => void;
  options: string[];
  component?: string | null;
};

const SingleSelect: FC<Props> = ({ label, selectedValue, handleChange, options }) => {
  const classes = useStyles();
  return (
    <Box className={classes.box} mr={2}>
      <InputLabel className={classes.label} id={label}>
        {label}
      </InputLabel>
      <Select
        labelId={label}
        name={label}
        value={selectedValue}
        onChange={handleChange}
        IconComponent={(props) => (
          <ChevronDown
            {...props}
            style={{ width: 12, right: 12, top: 12, position: 'absolute', pointerEvents: 'none' }}
          />
        )}
        data-testid="select-input-subject"
        MenuProps={{
          disableScrollLock: true,
          classes: { paper: classes.selectMenu },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        renderValue={(value) => {
          return <>{value}</>;
        }}
        style={{ height: '53px' }}
        fullWidth
      >
        {options.map((option) => (
          <MenuItem className={classes.menuItem} key={option} value={option} disableTouchRipple>
            {option}
            <span className={classes.spacer} />
            <Icon
              style={{
                visibility: selectedValue === option ? 'visible' : 'hidden',
                marginLeft: 18,
              }}
              color="primary"
            >
              checked
            </Icon>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default SingleSelect;
