import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Hidden, Fab, Icon, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import ScreenModal from '../ScreenModal';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  content: {
    'padding': theme.spacing(0, 3),
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  filtersHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  filtersHeaderTitle: {
    flex: 1,
  },
  iconEnd: {
    marginRight: theme.spacing(-2),
  },
  plusBtn: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  plusIcon: {
    color: theme.palette.white.main,
  },
}));
type FabMobileProps = {
  onClickNew?: () => void;
  children?: React.ReactNode;
};
const FabMobile: FC<FabMobileProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <Hidden smUp>
      <div className={classes.root}>
        <Fab className={classes.plusBtn} onClick={props.onClickNew}>
          <AddRoundedIcon className={classes.plusIcon} />
        </Fab>
        <Fab aria-label="filter" onClick={() => setOpen(true)}>
          <Icon>chart_candle</Icon>
        </Fab>
        <ScreenModal open={open} onClose={() => setOpen(false)}>
          <div className={classes.content}>
            <div className={classes.filtersHeader}>
              <Typography className={classes.filtersHeaderTitle} variant="h6">
                {t('general.filter')}
              </Typography>
              <IconButton className={classes.iconEnd} onClick={() => setOpen(false)} size="large">
                <Close />
              </IconButton>
            </div>
            {props.children}
          </div>
        </ScreenModal>
      </div>
    </Hidden>
  );
};

export default FabMobile;
