import type { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { CustomNumberFormat } from 'components/CustomNumberFormat';
import { Wrapper, Name } from './AccordionLoanPartHeader.styled';

type AccordionLoanPartHeaderProps = {
  loanPartNumber?: number | string | null;
  oldOrNew?: string;
  loanAmount?: number;
  repaymentType?: string;
  fixedRatePeriod?: number | string;
};

export const AccordionLoanPartHeader: FC<AccordionLoanPartHeaderProps> = ({
  loanPartNumber,
  oldOrNew,
  loanAmount,
  repaymentType,
  fixedRatePeriod,
}) => {
  return (
    <Wrapper container item justifyContent="space-between">
      <Grid xs={2} item>
        <Name>{loanPartNumber}</Name>
      </Grid>
      <Grid xs={2} item>
        <Typography>{oldOrNew}</Typography>
      </Grid>
      <Grid xs={2} item>
        <Typography>
          <CustomNumberFormat fixedDecimalScale value={loanAmount} />
        </Typography>
      </Grid>
      <Grid xs={3} item>
        <Typography>{repaymentType}</Typography>
      </Grid>
      <Grid xs={2} item>
        <Typography>{fixedRatePeriod}</Typography>
      </Grid>
    </Wrapper>
  );
};

export default AccordionLoanPartHeader;
