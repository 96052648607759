import { useQuery } from 'react-query';
import { getClientData } from '../api/clientData';
import { useGroup } from './group';

export const useClientData = (commercialLabel: string, loanNumber: number, enabled = true) => {
  const group = useGroup();
  return useQuery(
    ['client-data', commercialLabel, loanNumber],
    () => getClientData(group, commercialLabel, loanNumber),
    { enabled }
  );
};
