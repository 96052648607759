import React, { FC, useState } from 'react';
import LoadingIndicator from '../LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { Dialog, Fab, Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { ProofItem } from '../../types/ProofItem';
import { useQueryDocument } from 'use/documents';
import DocumentHeader from './DocumentHeader';
import FileViewer from './FileViewer';
import { SlideUpTransition } from '../../lib/Transition/transition';
import { getProofItemDescription } from './utils';

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100%',
    flexGrow: 100,
  },
  statusBtn: {
    'backgroundColor': theme.palette.success.main,
    'borderColor': theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
    'zIndex': 99,
    'position': 'fixed',
    'bottom': theme.spacing(2),
    'right': theme.spacing(5),
  },
}));

type Props = {
  commercialLabel: string;
  loanNumberExternal: number;
  documentId: number;
  proofItem: ProofItem;
  open: boolean;
  file?: File;
  onClose: () => void;
};

const ViewDocument: FC<Props> = ({
  open,
  proofItem,
  onClose,
  commercialLabel,
  loanNumberExternal,
  documentId,
  file,
}) => {
  const [scale, setScale] = useState(1);
  const { t } = useTranslation();

  const handleZoom = (scalePercentage: number) => {
    setScale(scalePercentage / 100);
  };

  const fileDownload = require('js-file-download');
  const handleDownload = () => {
    fileDownload(data, `${proofItem.shortDescription}.pdf`);
  };

  const classes = useStyles();

  const document = useQueryDocument(commercialLabel, loanNumberExternal, documentId);
  const data =
    file === undefined && document.data
      ? new File([document.data!], '', {
          type: document.data!.type,
        })
      : file;

  React.useEffect(() => {
    if (proofItem && proofItem.code) {
      document.refetch();
    }
  }, [proofItem]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!proofItem) {
    return null;
  }

  return (
    <Dialog fullScreen open={open} TransitionComponent={SlideUpTransition} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="stretch"
        className={classes.fullHeight}
      >
        <DocumentHeader
          description={getProofItemDescription(proofItem)}
          shortDescription={proofItem.shortDescription}
          onZoom={handleZoom}
          onDownloadPdf={handleDownload}
          onClose={onClose}
          zoomVisible
          downloadVisible
          status={document.status}
        />

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="stretch"
          className={classes.fullHeight}
        >
          {data === undefined && document.status === 'idle' && <LoadingIndicator />}
          {data === undefined && document.status === 'loading' && <LoadingIndicator />}
          {(data !== undefined || document.status === 'success') && (
            <FileViewer file={data} scale={scale} />
          )}
          {document.status === 'error' && (
            <Typography variant="h6">{t('error.default')}</Typography>
          )}

          <Fab
            color="primary"
            variant="extended"
            size="medium"
            onClick={onClose}
            className={classes.statusBtn}
          >
            {t('fileViewer.done') as string}
          </Fab>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ViewDocument;
