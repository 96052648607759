import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  ListItem,
  Hidden,
  Paper,
  Button,
  Icon,
  IconButton,
  Dialog,
  List,
  ListItemText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ProofItem } from 'types/ProofItem';
import { Document } from 'types/Document';
import { Action, AddNewSolution } from '../../../store/taskReducer';
import ViewDocument from 'components/DocumentViewer/ViewDocument';
import DocumentUpload from 'components/DocumentUpload';
import { createProofItem } from '../utils';
import clsx from 'clsx';
import { Case, TaskSolutionResolutionFileDocumentType } from '__generated__/api-di';

const iconNeutral = require('../../../assets/images/task-item/neutral.svg').default;

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 6,
    display: 'flex',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
  },
  fileName: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  icon: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
  },
  text: {
    flex: 1,
  },
  text2: {
    marginBottom: theme.spacing(0),
  },
  documentType: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
  },
  small: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    display: 'block',
    paddingTop: theme.spacing(0.5),
  },
  interpunct: {
    margin: theme.spacing(0, 1),
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.danger.main,
    flexDirection: 'column',
  },
  proofItemName: {
    textTransform: 'capitalize',
  },
}));

interface ViewButtonProps {
  onClick: () => void;
}
const ViewButton: React.FC<ViewButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <>
      <Hidden mdUp>
        <IconButton
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
          title={t('tasks.solutions.view')}
          size="large"
        >
          <Icon>trash</Icon>
        </IconButton>
      </Hidden>
      <Hidden mdDown>
        <Button
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          {t('tasks.solutions.view')}
        </Button>
      </Hidden>
    </>
  );
};

interface RemoveButtonProps {
  onClick: () => void;
}
const RemoveButton: React.FC<RemoveButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Hidden mdUp>
        <IconButton
          className={classes.deleteButton}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
          size="large"
        >
          <Icon>trash</Icon>
        </IconButton>
      </Hidden>
      <Hidden mdDown>
        <Button
          className={classes.deleteButton}
          endIcon={<Icon className={classes.btnIcon}>trash</Icon>}
          variant="text"
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          {t('tasks.solutions.delete')}
        </Button>
      </Hidden>
    </>
  );
};

interface AddProofitemSolutionProps {
  application: Case;
  issueId: string;
  solution: AddNewSolution;
  proofItems: Array<ProofItem>;
  dispatch: React.Dispatch<Action>;
}

const AddProofitemSolution: React.FC<AddProofitemSolutionProps> = ({
  application,
  issueId,
  solution,
  dispatch,
  proofItems,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const state = solution.state;
  const [previewVisible, setPreviewVisible] = React.useState(false);

  const closePreview = () => {
    setPreviewVisible(false);

    dispatch({
      type: 'update',
      payload: {
        issueId: issueId,
        data: { ...solution, state: { ...solution.state } },
      },
    });
  };

  const closeUpload = () => {
    deleteSolution();
  };
  const success = () => {
    dispatch({
      type: 'update',
      payload: {
        issueId: issueId,
        data: {
          ...solution,
          state: { ...solution.state, uploadVisible: false },
        },
      },
    });
  };
  const handleUpload = (
    document: Document,
    updatePercentagProgress: (percent: number) => void,
    successResponse: () => void,
    errorResponse: (errorMessage: string) => void
  ) => {
    updatePercentagProgress(100);
    successResponse();

    dispatch({
      type: 'update',
      payload: {
        issueId: issueId,
        data: {
          ...solution,
          data: { ...solution.data, file: document.file },
          state: {
            ...solution.state,
            uploadVisible: false,
          },
        },
      },
    });
  };
  const deleteSolution = () => {
    dispatch({
      type: 'remove',
      payload: {
        issueId: issueId,
        data: solution,
      },
    });
  };
  const handleDialogClose = () => {
    deleteSolution();
  };
  const handleDocumentTypeClick = (documentLabel: TaskSolutionResolutionFileDocumentType) => {
    dispatch({
      type: 'update',
      payload: {
        issueId: issueId,
        data: {
          ...solution,
          data: {
            ...solution.data,
            label: documentLabel,
          },
          state: {
            ...solution.state,
            showProofItemSelect: false,
            selectedProofItem: createProofItem(0, 0, t(`tasks.documentType.${documentLabel}`)),
            uploadVisible: true,
          },
        },
      },
    });
  };

  const icon = iconNeutral.default ?? iconNeutral;

  return (
    <>
      <Dialog
        open={solution.state.showProofItemSelect}
        fullWidth
        maxWidth="xs"
        scroll="paper"
        onClose={handleDialogClose}
      >
        <DialogTitle>{t('tasks.solutions.proofItemSelectTitel')}</DialogTitle>
        <List>
          <AddProofItemOption
            type={TaskSolutionResolutionFileDocumentType.IdentityDocument}
            handleDocumentTypeClick={handleDocumentTypeClick}
          />
          <AddProofItemOption
            type={TaskSolutionResolutionFileDocumentType.EmployerStatement}
            handleDocumentTypeClick={handleDocumentTypeClick}
          />
          <AddProofItemOption
            type={TaskSolutionResolutionFileDocumentType.BankStatement}
            handleDocumentTypeClick={handleDocumentTypeClick}
          />
          <AddProofItemOption
            type={TaskSolutionResolutionFileDocumentType.ExplanationCollateral}
            handleDocumentTypeClick={handleDocumentTypeClick}
          />
          <AddProofItemOption
            type={TaskSolutionResolutionFileDocumentType.ExplanationIncome}
            handleDocumentTypeClick={handleDocumentTypeClick}
          />
          <AddProofItemOption
            type={TaskSolutionResolutionFileDocumentType.ExplanationCredits}
            handleDocumentTypeClick={handleDocumentTypeClick}
          />
        </List>
      </Dialog>
      {solution.data.file && (
        <Paper className={classes.root}>
          <Hidden mdDown>
            <img className={classes.icon} src={icon} alt="" />
          </Hidden>
          <div className={classes.text}>
            <div className={classes.text2}>
              <Typography className={clsx([classes.proofItemName])} variant="body1">
                {t(`tasks.documentType.${solution.data.label}`)}
              </Typography>
              <div className={classes.fileName}>
                <Typography className={clsx([classes.small])} variant="body2">
                  {solution.data.file.name}
                </Typography>
                <Typography className={clsx([classes.small, classes.interpunct])}>‧</Typography>
                <Typography className={clsx([classes.small])} variant="body2">
                  {solution.data.file.size} kb
                </Typography>
              </div>
            </div>
          </div>
          <ViewButton onClick={() => setPreviewVisible(true)} />
          <RemoveButton onClick={deleteSolution} />
        </Paper>
      )}
      {previewVisible && (
        <ViewDocument
          commercialLabel={application.lender!.label!}
          loanNumberExternal={application.application!.loanNumberExternal!}
          documentId={state.selectedProofItem?.documentId ?? 0}
          open
          proofItem={state.selectedProofItem!}
          onClose={closePreview}
          file={solution.data.file ?? undefined}
        />
      )}
      <DocumentUpload
        commercialLabel={application.lender!.label!}
        loanNumber={application.application!.loanNumberExternal!}
        applicationIndexNumber={application.application!.index!}
        open={state.uploadVisible}
        proofItem={state.selectedProofItem!}
        uploadTarget={handleUpload}
        onSuccess={() => success()}
        onCancel={closeUpload}
      />
    </>
  );
};

interface AddProofItemOptionProps {
  type: TaskSolutionResolutionFileDocumentType;
  handleDocumentTypeClick(type: TaskSolutionResolutionFileDocumentType): void;
}
const AddProofItemOption: React.FC<AddProofItemOptionProps> = ({
  handleDocumentTypeClick,
  type,
}) => {
  const { t } = useTranslation();
  return (
    <ListItem button onClick={() => handleDocumentTypeClick(type)} key={type}>
      <ListItemText primary={t(`tasks.documentType.${type}`)} />
    </ListItem>
  );
};

export default AddProofitemSolution;
