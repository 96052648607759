import React from 'react';
import { Box, alpha, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { TaskDetails } from '__generated__/api-di';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  taskIcon: {
    color: theme.palette.primary.main,
    verticalAlign: 'text-top',
    margin: theme.spacing(2),
  },
}));

type SolveRequestAlertProps = {
  task: TaskDetails;
};

const SolveRequestAlert: React.FC<SolveRequestAlertProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root}`}>
      <Box display="flex" alignItems="center" fontWeight={500} fontSize={12} color="textPrimary">
        <AnnouncementIcon className={`${classes.taskIcon}`} />
        <Typography variant="body2" color="textSecondary">
          {props.task.description}
        </Typography>
      </Box>
    </div>
  );
};

export default SolveRequestAlert;
