import type { ClientListResponse } from 'api/clients';
import type { UrlParams } from 'screens/Mutations/utils/types';
import type { QueryStatus } from 'react-query';
import type { Client } from 'types/Client';
import { useQueryClients } from 'use/clients';
import { useGroup } from 'use/group';
import { syncClientList } from 'api/clients';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { mapFilters } from './helpers';
import { useDebounce } from 'hooks/useDebounce';
import { SelectFilter } from 'types/SelectOption';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setLocation } from 'store/locationReducer';
import { setFilters, setPageNumber } from 'store/genericClientListFiltersReducer';

const ENTRIES_PER_PAGE = 20;

const ClientLoanType = {
  Application: 'Application',
  LiveMortgage: 'LiveMortgage',
};

export type State = {
  client?: Client | undefined;
  from?: string;
  fromMutation?: boolean;
  loanNumber?: number;
};

export const useClients = (filterLoanNumber?: string) => {
  const params = useParams<keyof UrlParams>() as UrlParams;
  const navigate = useNavigate();
  const state: State = useLocation().state;
  const { filters: rootFilters, pageNumber: page } = useSelector(
    (state: RootState) => state.genericClientFilters
  );

  const group = useGroup();
  const [selectedClient, setSelectedClient] = useState<Client>();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState(filterLoanNumber ?? '');
  const [refetchStatus, setRefetchStatus] = useState<QueryStatus>();
  const delayedSearchTerm = useDebounce(searchTerm, 200);
  const filters = mapFilters(rootFilters, delayedSearchTerm);
  const isLabeled = Boolean(params.commercialRelation || state?.fromMutation);
  const resetedPageAfterFiltering = filterLoanNumber ? 0 : page;
  const clientsQueryResult = useQueryClients(
    isLabeled,
    params.commercialRelation,
    resetedPageAfterFiltering,
    ENTRIES_PER_PAGE,
    filters
  );
  const { clients, isExportAvailable } = clientsQueryResult.data ?? ({} as ClientListResponse);
  const queryKey = ['client-list', resetedPageAfterFiltering, filters];
  const { prevLocation } = useSelector((state: RootState) => state.location);

  useEffect(() => {
    !!params.commercialRelation &&
      localStorage.setItem('commercialRelation', params.commercialRelation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state?.loanNumber && state?.fromMutation) {
      const previousClient = clients.find((x) =>
        x.loans.some((x) => x.loanNumber === state.loanNumber)
      );
      setSelectedClient(previousClient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, state]);

  useEffect(() => {
    if (state && !!state?.from) {
      dispatch(setLocation(state.from));
    }
  }, [state, dispatch]);

  useEffect(() => {
    if (state && state.client) {
      setSelectedClient(state.client);
    }
  }, [params, state, setSelectedClient]);

  const onSelectClient = (client: Client, location?: string): void => {
    const { loanType, commercialRelation, loanNumber, applicationIndexNumber } = client.loans[0];
    switch (loanType) {
      case ClientLoanType.Application:
        navigate(`/${commercialRelation}/clients/${loanNumber}/${applicationIndexNumber}`, {
          state: {
            client,
            from: location,
          },
        });
        break;
      case ClientLoanType.LiveMortgage:
        navigate(`/${commercialRelation}/clients/${loanNumber}`, {
          state: { client, from: location },
        });
        break;
    }
  };

  async function refetch() {
    if (group == null) {
      setRefetchStatus('error');
      return;
    }
    try {
      setRefetchStatus('loading');

      // Call the synchronisation on the server
      await syncClientList(group);

      // Fetch the clients again
      await clientsQueryResult.refetch();

      setRefetchStatus(clientsQueryResult.status);
    } catch (e: any) {
      setRefetchStatus('error');
    }
  }

  const onCloseClient = (): void => {
    setSelectedClient(undefined);
    const pathName = state.from;
    if (pathName === '/clients') {
      localStorage.removeItem('commercialRelation');
    }
    navigate(pathName!);
  };

  const handleSearch = (value: string): void => {
    dispatch(setPageNumber(0));
    setSearchTerm(value.replaceAll('.', ''));
  };

  const handleFilter = ({ name, value }: SelectFilter): void => {
    dispatch(setPageNumber(0));
    dispatch(setFilters({ ...rootFilters, [name]: value }));
  };
  const handlePage = (p: number): void => {
    dispatch(setPageNumber(p));
  };

  const handleNavigateOnCloseForm = (innerState?: State): void => {
    if (innerState && innerState.fromMutation) {
      !!prevLocation && navigate(prevLocation, { state: innerState });
    } else {
      !!prevLocation && navigate(prevLocation);
    }
  };

  const handleNavigateToClient = (innerState: State): void => {
    const commercialRelation = localStorage.getItem('commercialRelation');
    if (prevLocation === '/clients') {
      navigate(`/${commercialRelation}/clients/${innerState.loanNumber}`, { state: innerState });
    } else {
      !!prevLocation && navigate(prevLocation, { state: innerState });
    }
  };

  return {
    handleNavigateOnCloseForm,
    handleNavigateToClient,
    clientsQueryResult,
    isExportAvailable,
    selectedClient,
    filterState: rootFilters,
    searchTerm,
    filteredClients: clients ?? [],
    page,
    refetchStatus,
    refetch,
    onCloseClient,
    onSelectClient,
    handleSearch,
    handleFilter,
    handlePage,
    queryKey,
  };
};
