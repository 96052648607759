import { FC, useState } from 'react';
import { Dialog, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FileViewer from 'components/DocumentViewer';
import IconTag from 'components/IconTag/IconTag';
import ViewMutationDocumentHeader from './ViewMutationDocumentHeader';
import { useGoogleTagManager } from 'use/gtm';
import { useTranslation } from 'react-i18next';
import { SlideUpTransition } from '../../lib/Transition/transition';

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100%',
    flexGrow: 100,
  },
  statusBtn: {
    'zIndex': 99,
    'position': 'fixed',
    'bottom': theme.spacing(2),
    'right': theme.spacing(5),
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

type Props = {
  open: boolean;
  file: File;
  filename: string;
  onClose: () => void;
};

const ViewMutationDocument: FC<Props> = ({ file, filename, open, onClose }) => {
  const reset = () => {};
  const [scale, setScale] = useState(1);
  const googleTagManager = useGoogleTagManager();
  const { t } = useTranslation();

  const handleCancel = () => {
    reset();
    onClose();
    googleTagManager({ event: 'ButtonClick', btnName: 'ExitMutation' });
  };

  const handleZoom = (scalePercentage: number) => {
    setScale(scalePercentage / 100);
    googleTagManager({ event: 'Zoom' });
  };

  const fileDownload = require('js-file-download');
  const handleDownload = () => {
    fileDownload(file, filename);
    googleTagManager({ event: 'ButtonClick', btnName: 'DownloadMutationFile' });
  };

  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={SlideUpTransition}
      onClose={onClose}
      TransitionProps={{ onExited: reset }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="stretch"
        className={classes.fullHeight}
      >
        <ViewMutationDocumentHeader
          filename={filename}
          onZoom={handleZoom}
          onDownloadPdf={handleDownload}
          onClose={handleCancel}
          zoomVisible
          downloadVisible
        />

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="stretch"
          className={classes.fullHeight}
        >
          {document && <FileViewer file={file} scale={scale} />}

          <div className={classes.statusBtn} onClick={handleCancel}>
            <IconTag status="done" label={t('fileViewer.done')} />
          </div>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ViewMutationDocument;
