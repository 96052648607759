import { Applicant } from './types';

const applicantName = (applicant: Applicant) => {
  if (!applicant || !applicant.personalInfo || !applicant.personalInfo.surname) {
    return undefined;
  }
  const info = applicant.personalInfo;
  const name = `${info.firstNames ?? ''} ${info.insertion ?? ''} ${info.surname}`;
  return name.replace('  ', ' ').trim();
};

export default applicantName;
