import type { FC } from 'react';
import FormTitleText from 'components/Form/FormTitle';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { FormInputPhoneNumber, FormInputText } from 'components/Form';
import { FieldsGrid, SubTitle } from 'screens/Mutations/molecules/styled';

export type AdditionalInformationProps = {
  title: string;
  subtitle: string;
  rootFormName?: string;
};

const AdditionalInformation: FC<AdditionalInformationProps> = ({
  title,
  subtitle,
  rootFormName = '',
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FormTitleText title={t(`${title}`)} description={t(`${subtitle}`)} />

      <FieldsGrid container data-testid="additionalInformation-form-wrapper">
        <SubTitle>{t('directMutations.additionalInformation.contactAdvisor')}</SubTitle>

        <Grid item xs={12}>
          <FormInputText
            name={`${rootFormName}name`}
            label={t('directMutations.additionalInformation.name')}
            required
            testId="name"
            maxLength={250}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputPhoneNumber
            name={`${rootFormName}phoneNumber`}
            label={t('directMutations.additionalInformation.phoneNumber')}
            testId="phone"
            required
          />
        </Grid>

        <Grid item xs={12}>
          <FormInputText
            name={`${rootFormName}info`}
            label={t('directMutations.additionalInformation.info')}
            maxLength={600}
            multiline
            rows={8}
            customExtraStyle={{ fontSize: '20px', fontWeight: 400 }}
          />
        </Grid>
      </FieldsGrid>
    </>
  );
};

export default AdditionalInformation;
