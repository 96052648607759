import React, { FC, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dropBackgroundImage from '../../assets/images/drop.png';
import { Button, Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DescriptionOutlined } from '@mui/icons-material';
import { useGoogleTagManager } from 'use/gtm';
import { IsMobile } from 'utils/global';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

const useStyles = makeStyles((theme) => ({
  box: {
    height: '100%',
  },
  bigIcon: {
    width: 80,
    height: 80,
    color: theme.palette.grey[400],
  },
  bigIconWhite: {
    width: 80,
    height: 80,
    color: theme.palette.white.main,
  },
  white: {
    color: theme.palette.white.main,
  },
  dropArea: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  dropAreaHover: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundImage: `url("${dropBackgroundImage}")`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.primary.main,
    backgroundPositionX: 'left',
  },
  inputFile: {
    width: '100%',
    height: '100%',
    opacity: '0',
    top: '0px',
    left: '0px',
    overflow: 'hidden',
    position: 'absolute',
    display: 'block',
  },
  label: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  placeholder: {
    minHeight: '200px',
  },
  mobileUpload: {
    margin: theme.spacing(1, 0, 4),
  },
}));

const maxFileSizeMb = (window as any).REACT_APP_UPLOAD_MAX_FILE_SIZE_IN_MB;
const maxFileSize = maxFileSizeMb * 1024 * 1024;

type Props = {
  showError: boolean;
  errorMessage: string | null | undefined;
  onSelect: (file: File) => void;
};

const UploadForm: FC<Props> = ({ showError = false, errorMessage = null, onSelect }) => {
  const [fileName, setFileName] = useState('');
  const [hover, setHover] = useState(false);
  const [exception, setException] = useState(showError ? errorMessage || 'general' : null);
  const googleTagManager = useGoogleTagManager();

  const fileInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = IsMobile();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files != null) {
      const file = event.target.files[0];
      setFileName(file.name);
      if (file.size > maxFileSize) {
        setException('fileTooLarge');
      } else {
        googleTagManager({ event: 'Drag', file: file.name });

        onSelect(file);
        googleTagManager({
          event: 'ButtonClick',
          btnName: 'UploadDocumentBrowse',
          file: file.name,
        });
      }
    }
  };

  const onDragLeave = () => {
    setHover(false);
  };

  const onDragEnter = () => {
    setHover(true);
  };

  const onDrop = () => {
    setHover(false);
  };

  const closeSnackbar = () => {
    setException(null);
  };

  const errorLabel = () => {
    switch (exception) {
      case 'fileTooLarge':
        return t('applications.upload.fileTooLarge', {
          file: fileName,
          maxMB: maxFileSizeMb,
        });
      case 'fileNotAllowed':
        return t('applications.upload.fileNotAllowed');
      case 'fileEncrypted':
        return t('applications.upload.fileEncrypted');
      default:
        return t('error.default');
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      className={hover ? classes.dropAreaHover : classes.dropArea}
      data-testid="upload-area"
    >
      <CustomSnackbar
        isOpen={exception !== null}
        handleClose={closeSnackbar}
        severity="error"
        message={errorLabel()}
      />

      <label
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        className={classes.label}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          className={classes.box}
        >
          <div className={classes.placeholder}>
            {!hover && (
              <>
                <DescriptionOutlined className={classes.bigIcon} />
                {isMobile ? (
                  <Typography variant="h5" className={classes.mobileUpload}>
                    {t('applications.upload.uploadDocument')}
                  </Typography>
                ) : (
                  <>
                    <Typography variant="h5">{t('applications.upload.dragAndDropHere')}</Typography>
                    <p>{t('applications.upload.or')}</p>
                  </>
                )}
                <Button color="primary" variant="contained" data-testid="upload-button">
                  {t('applications.upload.browseButton')}
                </Button>
              </>
            )}
            {hover && (
              <>
                <DescriptionOutlined className={classes.bigIconWhite} />
                <Typography variant="h5" className={classes.white}>
                  {t('applications.upload.dropHere')}
                </Typography>
              </>
            )}
          </div>

          <input
            ref={fileInput}
            name="file"
            type="file"
            onChange={onChange}
            className={classes.inputFile}
          />
        </Box>
      </label>
    </Box>
  );
};

export default UploadForm;
