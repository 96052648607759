import { errorMessages } from 'screens/Mutations/constants';
import name from 'utils/form/validations/name';
import { array, number, object, string } from 'yup';

export const getPoliciesFormSchema = () =>
  object().shape({
    currentLoanParts: array().of(
      object().shape({
        policies: getPoliciesSchema(),
      })
    ),
    policies: getPoliciesSchema(),
  });

export const getPoliciesSchema = () =>
  array().of(
    object().shape({
      policyNumber: string().required(errorMessages.required),
      numberOfPolicyHolders: number().required(errorMessages.required),
      policyHolders: object().when('numberOfPolicyHolders', {
        is: (val: number) => val === 1,
        then: object().shape({
          personOne: name.required(errorMessages.required),
        }),
        otherwise: object().shape({
          personOne: name.required(errorMessages.required),
          personTwo: name.required(errorMessages.required),
        }),
      }),
      isPolicyForConnectedPersons: string().required(errorMessages.required),
      numberOfInsuredPersons: number().when('isPolicyForConnectedPersons', {
        is: (val: string) => val === 'no',
        then: number().required(errorMessages.required),
      }),
      insuredPersons: object()
        .when('numberOfInsuredPersons', {
          is: (val: number) => val === 1,
          then: object().shape({
            personOne: name.required(errorMessages.required),
          }),
        })
        .when('numberOfInsuredPersons', {
          is: (val: number) => val === 2,
          then: object().shape({
            personOne: name.required(errorMessages.required),
            personTwo: name.required(errorMessages.required),
          }),
        }),
      typeOfPolicyMutation: string().required(errorMessages.required).nullable(),
      mutationDescription: string().when('typeOfPolicyMutation', {
        is: (val: any) => val === 'Altered',
        then: string().required(errorMessages.required),
      }),
      policyAmount: number().nullable(),
    })
  );

export default getPoliciesSchema;
