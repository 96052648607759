import { FilterButton } from 'components/Header/Header.styled';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import BuyToLetWidget from '../BTL/BuyToLetWidget';
import { useEffect, useState } from 'react';
import { useQueryProfiles } from 'use/profiles';
import HeaderTemplate from 'screens/templates/Lists/ListHeader/HeaderTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setCollateralValuesSubmitStatus } from 'store/collateralValuesFormReducer';
import { setSubmitStatus } from 'store/conversionFormReducer';
import { setCreateClientSubmitStatus } from 'store/createClientFormReducer';
import { setResultOhaMutation } from 'store/ohaFormReducer';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import { getStatusColor } from 'screens/Clients/utils';

type ApplicationHeaderProps = {
  type: 'cases' | 'concepts';
  handleType: (type: 'cases' | 'concepts') => void;
  onClickNew: () => void;
};

export default function ApplicationHeader({
  type = 'cases',
  handleType,
  onClickNew,
}: ApplicationHeaderProps) {
  const conversionResult = useSelector((state: RootState) => state.conversionForm.result);
  const collateralValuesResult = useSelector(
    (state: RootState) => state.collateralValuesForm.result
  );
  const createClientResult = useSelector((state: RootState) => state.createClientForm.result);
  const ohaResult = useSelector((state: RootState) => state.ohaForm.result);
  const { t } = useTranslation();
  const [snackbar, setSnackbar] = useState<
    { status: string; name: string; message?: string } | undefined
  >();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const { data: profiles } = useQueryProfiles();
  const commercialRelation = localStorage.getItem('commercialRelation');

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar(undefined);
  };

  useEffect(() => {
    if (conversionResult) {
      setSnackbar({ status: conversionResult, name: 'submitMutation' });
      dispatch(setSubmitStatus(undefined));
    }
    if (collateralValuesResult) {
      setSnackbar({ status: collateralValuesResult, name: 'submitCollateralValues' });
      dispatch(setCollateralValuesSubmitStatus(undefined));
    }
    if (createClientResult) {
      setSnackbar({ status: createClientResult, name: 'submitCreateClient' });
      dispatch(setCreateClientSubmitStatus(undefined));
    }
    if (ohaResult) {
      setSnackbar({ status: ohaResult, name: 'submitMutation' });
      dispatch(setResultOhaMutation(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversionResult, collateralValuesResult, createClientResult, ohaResult]);

  return (
    <>
      <HeaderTemplate
        profiles={profiles}
        commercialRelation={commercialRelation}
        sectionName={t('general.header.menu.applications')}
        filters={
          <Box>
            <FilterButton
              disableRipple
              variant="text"
              disableElevation
              isselected={type === 'cases' ? 1 : 0}
              onClick={() => handleType('cases')}
            >
              {t('applications.cases')}
            </FilterButton>
            <FilterButton
              disableRipple
              variant="text"
              disableElevation
              isselected={type === 'concepts' ? 1 : 0}
              onClick={() => {
                handleType('concepts');
              }}
            >
              {t('applications.concepts')}
            </FilterButton>
          </Box>
        }
        extraButtons={
          <BuyToLetWidget open={open} onClickNew={onClickNew} handleClose={handleClose} />
        }
      />
      {snackbar ? (
        <CustomSnackbar
          isOpen={!!snackbar}
          handleClose={handleCloseSnackbar}
          verticalPosition="top"
          severity={getStatusColor(snackbar.status)}
          message={
            snackbar.message
              ? snackbar.message
              : t(`clients.header.${snackbar.name}.${snackbar.status}`)
          }
        />
      ) : null}
    </>
  );
}
