import { ToggleType } from '../types';

const mapBooleanToToggleType = (value?: boolean | null): ToggleType | undefined => {
  if (value === true) {
    return 'yes';
  }
  if (value === false) {
    return 'no';
  }

  return undefined;
};

export default mapBooleanToToggleType;
