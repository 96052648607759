import React, { useContext, useState } from 'react';

export type UseWizard = {
  activeStepIndex: number;
  goToStep: (step: number) => void;
  nextStep: () => void;
  previousStep: () => void;
};

export const WizardContext = React.createContext<UseWizard | null>(null);

type Props = {
  initialStepIndex?: number;
};

export const useWizard = ({ initialStepIndex = 0 }: Props): UseWizard => {
  const [activeStepIndex, setActiveStepIndex] = useState(initialStepIndex);

  const goToStep = (stepIndex: number) => {
    if (activeStepIndex !== stepIndex) {
      setActiveStepIndex(stepIndex);
    }
  };

  const nextStep = () => goToStep(activeStepIndex + 1);

  const previousStep = () => goToStep(Math.max(activeStepIndex - 1, 0));

  return {
    activeStepIndex,
    goToStep,
    nextStep,
    previousStep,
  };
};

export const useWizardStep = () => {
  const wizardContext = useContext(WizardContext);

  if (wizardContext === null) {
    throw new Error('Wizard Step must be used as a child within a Wizard Component');
  }

  return wizardContext;
};
