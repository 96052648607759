import { Alert, Box, Button, Icon, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BtlApplicant } from 'types/BtlFormApplication';
import ConfirmationDialog from 'lib/ConfirmationDialog/ConfirmationDialog';
import { maxBtlApplicants } from '../constants';
import { applicantCountValid } from '../utils';

const useStyles = makeStyles((theme) => ({
  userIcon: {
    color: theme.palette.text.secondary,
    fontSize: '20px',
    marginRight: theme.spacing(2),
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
  createButton: {
    marginTop: theme.spacing(2),
    maxWidth: '200px',
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.danger.main,
  },
  applicantsValid: {
    marginTop: theme.spacing(2),
  },
}));

type BuyToLetApplicantsProps = {
  setActiveStep: (value: number) => void;
  createApplicant: () => void;
  deleteApplicant: (index: number) => void;
  setActiveApplicant: (index: number) => void;
  applicants: BtlApplicant[];
  showValidations: boolean;
};

function shouldAddApplicantButtonBeDisabled(applicants?: BtlApplicant[]): boolean {
  return applicants?.length === maxBtlApplicants;
}

const BuyToLetApplicants: FC<BuyToLetApplicantsProps> = ({
  setActiveStep,
  createApplicant,
  deleteApplicant,
  setActiveApplicant,
  applicants,
  showValidations,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [toBeDeleted, setToBeDeleted] = React.useState(-1);

  return (
    <>
      {applicants.length === 0 ? (
        <Box my={4} data-testid="buytolet-applicants">
          {showValidations && (
            <Alert severity="error">{t('buyToLetForm.applicants.applicantsMissing')}</Alert>
          )}
        </Box>
      ) : (
        <Box my={4} data-testid="buytolet-applicants">
          <Paper>
            {applicants.map((applicant, index) => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={3}
                py={2}
                key={`${
                  applicant.isLegalPerson ? applicant.companyName : applicant.lastName
                }${index}`}
              >
                <Box display="flex">
                  <Icon className={classes.userIcon}>user</Icon>
                  <Typography>
                    {!applicant.isLegalPerson && applicant.lastName
                      ? `${[applicant.firstName, applicant.middleName, applicant.lastName]
                          .filter((value) => value)
                          .join(' ')} (NP)`
                      : applicant.isLegalPerson && `${applicant.companyName} (RP)`
                      ? `${applicant.companyName} (RP)`
                      : t('buyToLetForm.applicants.newApplicant')}
                  </Typography>
                </Box>
                <Box mx={2}>
                  <Button
                    color="primary"
                    endIcon={<Icon className={classes.btnIcon}>pencil</Icon>}
                    variant="text"
                    onClick={() => {
                      setActiveApplicant(index);
                      setActiveStep(index + 1);
                    }}
                  >
                    {t('buyToLetForm.applicants.edit')}
                  </Button>
                  <Button
                    className={classes.deleteButton}
                    endIcon={<Icon className={classes.btnIcon}>trash</Icon>}
                    variant="text"
                    onClick={() => {
                      setToBeDeleted(index);
                    }}
                  >
                    {t('buyToLetForm.applicants.delete')}
                  </Button>
                </Box>
              </Box>
            ))}
          </Paper>
          {!applicantCountValid(applicants) && (
            <Alert severity="error" className={classes.applicantsValid}>
              {t('buyToLetForm.applicants.applicantsInvalid')}
            </Alert>
          )}
        </Box>
      )}
      <Button
        variant="contained"
        color="primary"
        className={classes.createButton}
        disabled={shouldAddApplicantButtonBeDisabled(applicants)}
        onClick={createApplicant}
        endIcon={<Icon className={classes.btnIcon}>plus</Icon>}
      >
        {t('buyToLetForm.applicants.addNew')}
      </Button>

      <ConfirmationDialog
        dialogTitle={t('buyToLetForm.applicants.deleteConfirmation')}
        confirmButtonText={t('buyToLetForm.applicants.delete')}
        cancelButtonText={t('buyToLetForm.applicants.cancel')}
        open={toBeDeleted >= 0}
        handleClose={() => setToBeDeleted(-1)}
        onConfirm={() => {
          setToBeDeleted(-1);
          deleteApplicant(toBeDeleted);
        }}
      />
    </>
  );
};

export default BuyToLetApplicants;
