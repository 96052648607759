import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Alert, CheckboxProps, FormControlLabel } from '@mui/material';
import type { ProofItem } from '../../types/ProofItem';
import { optimisticUpdateUploadedProofItemStatus } from 'use/documents';
import ActionListExtra from './ActionListExtra';
import ProofItemListProvide from './ProofItemListProvide';
import ViewDocument from '../DocumentViewer/ViewDocument';
import DocumentUpload from '../DocumentUpload';
import { ApplicationStatus } from 'constants/index';
import LoadingIndicator from '../LoadingIndicator';
import { Case, CaseDetails, TaskInfo } from '__generated__/api-di';
import { useGroup } from 'use/group';
import useProofItems from './hooks/useProofItems';
import { Checkbox } from 'components/Form/Forms.styled';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginBottom: theme.spacing(4),
    },
  },
  filterBtn: {
    marginLeft: 'auto',
    marginBottom: 0,
  },
  toggleReceived: {
    marginBottom: 0,
    marginLeft: 0,
    display: 'flex',
  },
}));

const ToggleReceived = ({ checked, onChange, disabled }: CheckboxProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          color="primary"
          checked={checked}
          onChange={onChange}
          name="toggleReceived"
        />
      }
      label={t('applications.detail.proofItems.toggleUnreceived')}
      className={classes.toggleReceived}
      labelPlacement="start"
    />
  );
};

export type ProofItemsSectionProps = {
  application: Case;
  caseDetails?: CaseDetails;
  tasks: Array<TaskInfo>;
  openResolve: (task: TaskInfo) => void;
  children?: React.ReactNode;
};

const ProofItemsSection: React.FC<ProofItemsSectionProps> = ({
  application,
  caseDetails,
  tasks,
  openResolve,
  children,
}) => {
  const classes = useStyles();
  const group = useGroup();
  const { t } = useTranslation();

  const {
    allProofItems,
    filteredProofItems,
    isLoading,
    isError,
    toggleFilter,
    hasFilter,
    canFilter,
  } = useProofItems(application, caseDetails);

  const [selectedProofItem, setSelectedProofItem] = useState<ProofItem | null>(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [uploadVisible, setUploadVisible] = useState(false);

  const openPreview = (proofItem: ProofItem) => {
    setSelectedProofItem(proofItem);
    setPreviewVisible(true);
  };
  const closePreview = () => {
    setSelectedProofItem(null);
    setPreviewVisible(false);
  };

  const openUpload = (proofItem: ProofItem) => {
    setSelectedProofItem(proofItem);
    setUploadVisible(true);
  };
  const closeUpload = () => {
    setSelectedProofItem(null);
    setUploadVisible(false);
  };

  const toggleToBeReceived = useCallback(
    () => toggleFilter({ hasUploadButton: true }),
    [toggleFilter]
  );

  const success = () => {
    closeUpload();
    if (application && selectedProofItem) {
      optimisticUpdateUploadedProofItemStatus(
        group,
        application.lender!.label!,
        application.application!.loanNumberExternal!,
        application.application!.index!,
        selectedProofItem
      );
    }
  };

  if (
    application.application?.status?.value === ApplicationStatus.rejected ||
    application.application?.status?.value === ApplicationStatus.terminated
  ) {
    return null;
  }

  if (isLoading || isError) {
    return (
      <>
        {React.Children.toArray(children)}
        <div className={classes.root} data-testid="proof-items-section-error">
          {isLoading && <LoadingIndicator />}
          {isError && <Alert severity="error">{t('proofitem.error')}</Alert>}
        </div>
      </>
    );
  }

  return (
    <>
      {React.Children.toArray(children)}

      <div className={classes.root} data-testid="proof-items-section">
        <ToggleReceived checked={hasFilter} onChange={toggleToBeReceived} disabled={!canFilter} />

        <ActionListExtra
          applicationData={application}
          tasks={tasks}
          allProofItems={allProofItems}
          filteredProofItems={filteredProofItems}
          onClickView={openPreview}
          onClickUpload={openUpload}
          onClickResolve={openResolve}
        />

        <ProofItemListProvide
          application={application}
          allProofItems={allProofItems}
          filteredProofItems={filteredProofItems}
          onClickView={openPreview}
          onClickUpload={openUpload}
        />
      </div>

      {previewVisible && (
        <ViewDocument
          commercialLabel={application.lender!.label!}
          loanNumberExternal={application.application!.loanNumberExternal!}
          documentId={selectedProofItem?.documentId ?? 0}
          open
          proofItem={selectedProofItem!}
          onClose={closePreview}
        />
      )}
      <DocumentUpload
        commercialLabel={application.lender!.label}
        loanNumber={application.application!.loanNumberExternal}
        applicationIndexNumber={application.application!.index}
        open={uploadVisible}
        proofItem={selectedProofItem!}
        onSuccess={success}
        onCancel={closeUpload}
      />
    </>
  );
};

export default ProofItemsSection;
