import React, { FC, useState } from 'react';
import env from 'config/env';
import BuyToLetOrientation from './BuyToLetForm/BuyToLetOrientation';
import BuyToLetForm from './BuyToLetForm/BuyToLetForm';
import { ApplicationReadDto } from '__generated__/api';
import BuyToLetUpload from './BuyToLetUpload';

enum FlowType {
  orientation,
  new,
  old,
}

export type QuestionState = {
  visible?: boolean;
  answerIds?: string[];
  answer?: string;
  answerText?: string;
  questionText?: string;
  hiddenInHyarchis?: boolean;
};

export type BuyToLetProps = {
  open: boolean;
  application?: ApplicationReadDto;
  onClose: () => void;
  showSuccess: (value: boolean) => void;
};

const BuyToLet: FC<BuyToLetProps> = ({ open, onClose, showSuccess, application }) => {
  const [currentFlowType, setFlowType] = useState<FlowType>(FlowType.orientation);

  const activeFlowType = application?.id !== undefined ? FlowType.new : currentFlowType;

  const [wizardState, setWizardState] = useState<Map<string, QuestionState>>(new Map());

  function handleOnClose() {
    setFlowType(FlowType.orientation);
    onClose();
  }

  return (
    <>
      {env.FEATURES.STAT_402813_BUYTOLETFLOW ? (
        <>
          <BuyToLetForm
            open={open && activeFlowType === FlowType.new}
            wizardState={wizardState}
            id={application?.id}
            onClose={handleOnClose}
          />
          <BuyToLetUpload
            open={open && activeFlowType === FlowType.old}
            onClose={handleOnClose}
            showSuccess={showSuccess}
          />
          <BuyToLetOrientation
            open={open && activeFlowType === FlowType.orientation}
            wizardState={wizardState}
            setWizardState={setWizardState}
            onClose={handleOnClose}
            onFailure={() => setFlowType(FlowType.old)}
            onSuccess={() => setFlowType(FlowType.new)}
          />
        </>
      ) : (
        <BuyToLetUpload open={open} onClose={handleOnClose} showSuccess={showSuccess} />
      )}
    </>
  );
};

export default BuyToLet;
