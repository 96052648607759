import { ApplicationData } from '__generated__/api';
import { Case } from '__generated__/api-di';

// https://eslint.org/docs/rules/no-prototype-builtins
const hasOwnProperty = (object: Case | ApplicationData, prop: string) =>
  Object.prototype.hasOwnProperty.call(object, prop);

export const isConceptDismissalJointLiability = ({ type, subType }: ApplicationData) =>
  type === 'Mutation' && subType === 'DismissalJointLiability';

export const isConceptConversion = ({ type, subType }: ApplicationData) =>
  type === 'Mutation' && subType === 'Conversion';

export const isApplicationData = (object: Case | ApplicationData): object is ApplicationData =>
  hasOwnProperty(object, 'type') && hasOwnProperty(object, 'subType');

export const isCase = (object: Case | ApplicationData): object is Case =>
  hasOwnProperty(object, 'application') && hasOwnProperty(object, 'lender');

export const isNewLoan = (object: ApplicationData) => {
  return hasOwnProperty(object, 'type') && object.type === 'NewLoan';
};
