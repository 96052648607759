import React, { FC, useEffect } from 'react';
import SaveAndExitPopup from '.';

type Props = {
  askClose?: boolean;
  pristine?: boolean;
  onSave: () => void;
  onClose: () => void;
  onCloseSaveDialog: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  children?: React.ReactNode;
};

const SaveAndExitWrapper: FC<Props> = ({
  askClose = false,
  pristine = false,
  onSave,
  onClose,
  onCloseSaveDialog,
}) => {
  const [savePopupVisible, setSavePopupVisible] = React.useState(false);

  const onSaveAndExit = () => {
    onSave();
    setSavePopupVisible(false);
    onClose();
  };

  const onContinueAndExit = () => {
    setSavePopupVisible(false);
    onClose();
  };

  const onCloseSavePopup = () => {
    setSavePopupVisible(false);
    onCloseSaveDialog();
  };

  const onCloseWrapper = () => {
    if (pristine) {
      onClose();
    } else {
      setSavePopupVisible(true);
    }
  };

  useEffect(() => {
    if (askClose === true) {
      onCloseWrapper();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [askClose]);

  return (
    <SaveAndExitPopup
      open={savePopupVisible}
      pristine={pristine}
      onSave={onSaveAndExit}
      onContinue={onContinueAndExit}
      onClose={onCloseSavePopup}
    />
  );
};

export default SaveAndExitWrapper;
