import { useQueryClientDataDownloadFiles } from 'use/downloads';
import { FC } from 'react';
import { Button, TableCell, TableRow } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { handleContentFileType } from '../utils';
import { useTranslation } from 'react-i18next';
import { Dot, TableCellText } from 'screens/Downloads/Downloads.styled';
import fileDownload from 'js-file-download';
import { DownloadFiles, getDownloadFile } from 'api/downloads';
import formatDate from 'utils/formatDate';

type DownLoadItemProps = {
  file: DownloadFiles;
  setError: any;
};

const DownLoadItem: FC<DownLoadItemProps> = ({ file, setError }) => {
  const { t } = useTranslation();
  const { refetch } = useQueryClientDataDownloadFiles();
  const { description, dateCreated, fileId } = file;
  const formatedDate = formatDate(dateCreated, ' ', "dd-MM-yyyy' ('HH:mm)");

  const handleDownload = async () => {
    try {
      const response = await getDownloadFile(fileId);
      if (response) {
        const { data, headers } = response;
        fileDownload(data, `${description}.${handleContentFileType(headers['content-type'])}`);
        refetch();
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
  };

  return (
    <TableRow>
      <TableCell data-testid="downloadItem-dot">
        {!file?.dateAccessed && <Dot fontSize="small" />}
      </TableCell>
      <TableCell>
        {formatedDate && (
          <TableCellText isaccessed={file?.dateAccessed ? 1 : 0} data-testid="downloadItem-date">
            {formatedDate}
          </TableCellText>
        )}
      </TableCell>
      <TableCell data-testid="downloadItem-type">
        <TableCellText isaccessed={file?.dateAccessed ? 1 : 0}>{description}</TableCellText>
      </TableCell>
      <TableCell>
        <Button variant="text" color="primary" onClick={handleDownload}>
          <FileDownloadIcon />
          {t('manual.viewer.download')}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default DownLoadItem;
