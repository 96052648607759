import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootFormName, TypeOfIncome } from 'screens/Mutations/utils/types';
import { RootState } from 'store';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { format } from 'date-fns';
import { SummaryCard, CardRow, CardSection } from 'components/SummaryCard';
import { Employment, Entrepreneur, Other, Pension, SocialSecurity } from '../Summary/Income';
import Obligation from '../Summary/Obligation';
import mapYesNo from 'screens/Mutations/utils/mappers/mapYesNoValuesForSummary';
import { getMaritalStatusValue } from 'screens/Mutations/utils';

type Step = 'personalInfo' | 'contactDetails' | 'income' | 'obligations';

type ApplicantSummaryProps = {
  type: RootFormName;
  onClick: (step: Step, type?: RootFormName, applicantType?: string) => void;
};

const ApplicantSummary: FC<ApplicantSummaryProps> = ({ type, onClick }) => {
  const { t } = useTranslation();

  const applicant = useSelector((state: RootState) => state.ohaForm[type]);
  const isNewApplicant = type === 'newApplicant';

  const handleOnClick = useCallback(
    (step: Step) => () => {
      onClick(step, type);
    },
    [onClick, type]
  );

  const { personalInfo, contactDetails } = applicant;

  const visible = (isNewApplicant && personalInfo.hasNewApplicant === 'yes') || !isNewApplicant;

  if (!visible) {
    return (
      <SummaryCard
        title={t('directMutations.personalInfo.formTitle')}
        onEditClick={handleOnClick('personalInfo')}
      >
        <CardSection>
          {isNewApplicant && (
            <CardRow
              name={t('directMutations.personalInfo.hasNewApplicant')}
              value={mapYesNo(personalInfo.hasNewApplicant)}
            />
          )}
        </CardSection>
      </SummaryCard>
    );
  }

  return (
    <>
      <SummaryCard
        title={t('directMutations.personalInfo.formTitle')}
        onEditClick={handleOnClick('personalInfo')}
      >
        <CardSection>
          {isNewApplicant && (
            <CardRow
              name={t('directMutations.personalInfo.hasNewApplicant')}
              value={mapYesNo(personalInfo.hasNewApplicant)}
            />
          )}
          <CardRow
            name={t('directMutations.personalInfo.initials')}
            value={personalInfo.initials}
          />
          <CardRow
            name={t('directMutations.personalInfo.firstNames')}
            value={personalInfo.firstNames}
          />
          <CardRow
            name={t('directMutations.personalInfo.insertion')}
            value={personalInfo.insertion}
          />
          <CardRow name={t('directMutations.personalInfo.surname')} value={personalInfo.surname} />
          <CardRow
            name={t('directMutations.personalInfo.dateOfBirth')}
            value={
              personalInfo.dateOfBirth
                ? format(new Date(personalInfo.dateOfBirth), 'dd-MM-yyyy')
                : undefined
            }
          />
          {!isNewApplicant && (
            <CardRow
              name={t('directMutations.personalInfo.maritalStatusVSOld')}
              value={getMaritalStatusValue(personalInfo.maritalStatusVSOld)}
            />
          )}
          <CardRow
            name={t('directMutations.personalInfo.maritalStatus')}
            value={getMaritalStatusValue(personalInfo.maritalStatus)}
          />
          <CardRow
            name={t('directMutations.personalInfo.numberOfDivorces')}
            value={personalInfo.numberOfDivorces}
          />
          <CardRow
            name={t('directMutations.personalInfo.children')}
            value={personalInfo.children}
          />
          <CardRow
            name={t(
              `directMutations.personalInfo.${isNewApplicant ? 'newHomeOwner' : 'homeOwner'}`
            )}
            value={mapYesNo(personalInfo.homeOwner)}
          />
        </CardSection>
      </SummaryCard>

      <SummaryCard
        title={t('directMutations.contactDetails.formTitle')}
        onEditClick={handleOnClick('contactDetails')}
      >
        <CardSection>
          <CardRow
            name={t('directMutations.contactDetails.street')}
            value={contactDetails.street}
          />
          <CardRow
            name={t('directMutations.contactDetails.houseNumber')}
            value={contactDetails.houseNumber}
          />
          <CardRow
            name={t('directMutations.contactDetails.houseNumberSuffix')}
            value={contactDetails.houseNumberSuffix}
          />
          <CardRow
            name={t('directMutations.contactDetails.postalCode')}
            value={contactDetails.postalCode}
          />
          <CardRow name={t('directMutations.contactDetails.city')} value={contactDetails.city} />
          <CardRow
            name={t('directMutations.contactDetails.country')}
            value={contactDetails.country && i18nIsoCountries.getName(contactDetails.country, 'nl')}
          />
          <CardRow
            name={t('directMutations.contactDetails.phoneNumber')}
            value={contactDetails.phoneNumber}
          />
          <CardRow name={t('directMutations.contactDetails.email')} value={contactDetails.email} />
        </CardSection>
      </SummaryCard>

      {applicant.income?.incomes?.map((income, index) => (
        <React.Fragment key={index}>
          {income.typeOfIncome === TypeOfIncome.Employment && income.employment && (
            <Employment income={income.employment} onClick={handleOnClick('income')} />
          )}
          {income.typeOfIncome === TypeOfIncome.Entrepreneur && income.entrepreneur && (
            <Entrepreneur income={income.entrepreneur} onClick={handleOnClick('income')} />
          )}
          {income.typeOfIncome === TypeOfIncome.SocialSecurity && income.socialSecurity && (
            <SocialSecurity income={income.socialSecurity} onClick={handleOnClick('income')} />
          )}
          {income.typeOfIncome === TypeOfIncome.Pension && income.pension && (
            <Pension income={income.pension} onClick={handleOnClick('income')} />
          )}
          {income.typeOfIncome === TypeOfIncome.Other && income.otherIncome && (
            <Other income={income.otherIncome} onClick={handleOnClick('income')} />
          )}
        </React.Fragment>
      ))}

      {applicant.obligation?.obligations?.map((obligation, index) => (
        <Obligation key={index} obligation={obligation} onClick={handleOnClick('obligations')} />
      ))}
    </>
  );
};

export default ApplicantSummary;
