import { parseISO } from 'date-fns';
import { ClientDetailsInterestPeriod, ClientDetailsLoanParts } from 'types/ClientDetailsModel';

export const calcPrincipalSum = (loanPart: ClientDetailsLoanParts) => {
  return loanPart?.principalSum ?? 0;
};

export const getStartdate = (interestPeriod: ClientDetailsInterestPeriod | undefined) => {
  if (!interestPeriod || !interestPeriod.renewalDate || !interestPeriod.duration) {
    return null;
  }

  const parsedDate = parseISO(interestPeriod.renewalDate);
  parsedDate.setMonth(parsedDate.getMonth() - interestPeriod.duration);

  return parsedDate;
};
