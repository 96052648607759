/* eslint-disable no-console */
import { FC, useEffect, useState } from 'react';
import { Box, Button, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { getBearerToken } from 'auth/oidc';
import jwt from 'jwt-decode';

export type tokenObject = {
  id_token: string;
  session_state: string;
  access_token: string;
  refresh_token: string;
  token_type: 'bearer';
  scope: 'openid';
  expires_in: number;
  refresh_expires_in: number;
  notBeforePolicy: number;
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    borderRadius: 6,
    padding: '32px 64px',
    fontSize: '14px',
  },
  box: {
    padding: '8px',
    justifyContent: 'space-between',
    display: 'flex',
  },
  button: {
    width: '100px',
    margin: '4px 0 4px 32px',
    float: 'right',
  },
}));
const envVariables = process.env;
const clientIdDefault = envVariables.REACT_APP_KEYCLOAK_CLIENT_TEST_ID_DEFAULT!;
const clientIdWildcard = envVariables.REACT_APP_KEYCLOAK_CLIENT_TEST_ID_WILDCARD!;
const clientIdNibc = envVariables.REACT_APP_KEYCLOAK_CLIENT_TEST_ID_NIBC!;
const clientIdAsr = envVariables.REACT_APP_KEYCLOAK_CLIENT_TEST_ID_ASR!;

const secretDefault = envVariables.REACT_APP_KEYCLOAK_TOKEN_API_SECRET_DEFAULT!;
const secretWildcard = envVariables.REACT_APP_KEYCLOAK_TOKEN_API_SECRET_WILDCARD!;
const secretNibc = envVariables.REACT_APP_KEYCLOAK_TOKEN_API_SECRET_NIBC!;
const secretAsr = envVariables.REACT_APP_KEYCLOAK_TOKEN_API_SECRET_ASR!;

const oidcUserKey = envVariables.REACT_APP_KEYCLOAK_SESSION_STORAGE_KEY!;
const keyCloakDomain = envVariables.REACT_APP_KEYCLOAK_DOMAIN;
const endpoint = `${keyCloakDomain}/auth/realms/StaterSSO/protocol/openid-connect/token`;
const http = axios.create({
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
  },
});

const DummyLogin: FC<{ children: React.ReactNode }> = ({ children }) => {
  const classes = useStyles();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    getBearerToken().then((token) => {
      const decoded: any = token ? jwt(token) : {};
      if (decoded.exp && Date.now() < decoded.exp * 1000) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  }, []);

  const login = (client: string, secret: string): Promise<tokenObject> => {
    const postVariables = `grant_type=client_credentials&scope=openid&client_id=${client}&client_secret=${secret}`;

    return http
      .post(endpoint, postVariables)
      .then((res) => {
        console.warn('setting token');
        window.sessionStorage.setItem(oidcUserKey, JSON.stringify(res.data));
        setIsLoggedIn(true);
        return res.data;
      })
      .catch((err) => {
        console.warn('error on getting token, error:', err);
      });
  };

  const loginAsAdvisor = () => {
    login(clientIdDefault, secretDefault);
  };

  const loginAsStater = () => {
    login(clientIdWildcard, secretWildcard);
  };

  const loginAsNibc = () => {
    login(clientIdNibc, secretNibc);
  };

  const loginAsAsrLot = () => {
    login(clientIdAsr, secretAsr);
  };

  if (isLoggedIn) return <>{children}</>;

  return (
    <div className={classes.root}>
      <Paper className={classes.card}>
        <Box className={classes.box}>
          <p>Inloggen als adviseur</p>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={loginAsAdvisor}
          >
            Adviseur
          </Button>
        </Box>

        <Box className={classes.box}>
          <p>Inloggen als Stater medewerker</p>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={loginAsStater}
          >
            Stater
          </Button>
        </Box>

        <Box className={classes.box}>
          <p>Inloggen als medewerker van NIBC</p>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={loginAsNibc}
          >
            NIBC
          </Button>
        </Box>

        <Box className={classes.box}>
          <p>Inloggen als medewerker van ASR en LOT</p>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={loginAsAsrLot}
          >
            ASR &amp; LOT
          </Button>
        </Box>
      </Paper>
    </div>
  );
};

export default DummyLogin;
