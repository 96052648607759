import { findConnectedPolicies } from 'screens/Mutations/utils/selectors/findConnectedPolicies';
import {
  LoanPartType,
  SplitLoan,
  ConvertLoan,
  ToggleType,
  LoanPartPolicy,
  ConnectedPersons,
  Policy,
  ConversionForm,
} from 'screens/Mutations/utils/types';
import {
  LoanPartReadDto,
  CurrentLoanPartReadDto,
  PolicyHolderReadDto,
  PolicyInfo,
  LoanPartInfo,
  CurrentPolicyReadDto,
  IntermediaryReadDto,
} from '__generated__/api';

const mapIntermediaryData = (formData: ConversionForm): IntermediaryReadDto | undefined => {
  const { loanDetails, intermediary } = formData;
  if (
    intermediary ||
    loanDetails?.additionalInformation?.name ||
    loanDetails?.additionalInformation?.phoneNumber
  ) {
    return {
      ...intermediary,
      name: loanDetails.additionalInformation?.name,
      phoneNumber: loanDetails.additionalInformation?.phoneNumber,
    };
  }
  return undefined;
};

const mapPolicies = (policies: Policy[]): CurrentPolicyReadDto[] => {
  return policies.map((policy) => {
    return {
      policyNumber: policy.policyNumber,
      policyHolders: mapPolicyHolders(policy.policyHolders),
      insuredPeople: policy.insuredPersons
        ? mapPolicyHolders(policy.insuredPersons)
        : mapPolicyHolders(policy.policyHolders),
      typeOfMutation: policy.typeOfPolicyMutation,
      mutationDescription: policy.mutationDescription,
    };
  });
};

const mapNewLoanParts = (loanParts: LoanPartType[]): LoanPartReadDto[] | null => {
  const newLoanParts: LoanPartReadDto[] = [];
  loanParts.forEach((loanPart: LoanPartType) => {
    if (loanPart.splitLoan && loanPart.splitLoan.length > 0) {
      loanPart.splitLoan.forEach((splitPart) => {
        newLoanParts.push(mappedSplitPart(splitPart, loanPart.loanPartNumber!));
      });
    } else if (loanPart.convertLoan) {
      newLoanParts.push(mappedConvertedPart(loanPart.convertLoan, loanPart.loanPartNumber!));
    }
  });

  return newLoanParts;
};

const mappedSplitPart = (splitPart: SplitLoan, loanPartNumber: number): LoanPartReadDto => {
  return {
    originalLoanPart: loanPartNumber,
    amount: splitPart.amount,
    fixedInterestPeriod: splitPart.fixedRatePeriod,
    hasNhg: mapToggleButton(splitPart.NHGGuarantee),
    redemptionTypeDisplayName: splitPart.repaymentType,
  };
};

const mappedConvertedPart = (convertLoan: ConvertLoan, loanPartNumber: number): LoanPartReadDto => {
  return {
    originalLoanPart: loanPartNumber,
    fixedInterestPeriod: convertLoan.fixedRatePeriod,
    hasNhg: mapToggleButton(convertLoan.NHGGuarantee),
    redemptionTypeDisplayName: convertLoan.repaymentType,
  };
};

const mapToggleButton = (value: ToggleType | undefined): boolean | null | undefined => {
  if (value === 'yes') {
    return true;
  }
  if (value === 'no') {
    return false;
  }
  return undefined;
};

const mapCurrentLoanParts = (
  loanParts: LoanPartType[],
  policies: LoanPartPolicy[]
): CurrentLoanPartReadDto[] => {
  return loanParts.map((loanPart) => {
    return {
      loanPartNumber: loanPart.loanPartNumber,
      PrincipalAmount: loanPart.loanAmount,
      hasNhg: mapToggleButton(loanPart.NHGGuarantee),
      redemptionTypeDisplayName: loanPart.repaymentType,
      shouldLoanPartSplit: mapToggleButton(loanPart.shouldSplitLoan),
      currentPolicies: mapPolicies(findConnectedPolicies(loanPart, policies)),
    };
  });
};

const mapPolicyHolders = (persons: ConnectedPersons = {}): PolicyHolderReadDto[] => {
  const holders: PolicyHolderReadDto[] = [];
  persons.personOne && holders.push({ fullName: persons.personOne });
  persons.personTwo && holders.push({ fullName: persons.personTwo });
  return holders;
};

const mapPolicyInfo = (policies: Policy[]): PolicyInfo[] => {
  return policies.map((policy) => {
    return {
      policyNumber: policy.policyNumber,
      hasPolicyInNameOfInsuredPerson: mapToggleButton(policy.isPolicyForConnectedPersons),
      numberOfPolicyHolders: policy.numberOfPolicyHolders,
      numberOfInsuredPeople: policy.numberOfInsuredPersons,
    };
  });
};

const mapLoanPartInfo = (loanParts: LoanPartType[], policies: LoanPartPolicy[]): LoanPartInfo[] => {
  return loanParts.map((loanPart) => {
    return {
      loanPartNumber: loanPart.loanPartNumber,
      hasConnectedPolicy: mapToggleButton(loanPart.hasConnectedPolicy),
      policies: mapPolicyInfo(findConnectedPolicies(loanPart, policies)),
    };
  });
};

export {
  mapPolicies,
  mapLoanPartInfo,
  mapPolicyInfo,
  mapPolicyHolders,
  mapCurrentLoanParts,
  mapToggleButton,
  mappedConvertedPart,
  mapNewLoanParts,
  mapIntermediaryData,
  mappedSplitPart,
};
