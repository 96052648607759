import { styled } from '@mui/material/styles';

const Small = styled('img')({
  width: 32,
});

const Medium = styled('img')({
  width: 48,
});

const Large = styled('img')({
  width: 96,
});

export { Small, Medium, Large };
