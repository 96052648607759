import { ListOfMoneyLenders } from 'types/FunctionalitiesOverview';

const sortCallback = (a: ListOfMoneyLenders, b: ListOfMoneyLenders) => {
  const displayNameFirst = a?.displayName ? a?.displayName.toLowerCase() : 'z';
  const displayNameSecond = b?.displayName ? b?.displayName.toLowerCase() : 'z';
  if (displayNameFirst < displayNameSecond) {
    return -1;
  }
  if (displayNameFirst > displayNameSecond) {
    return 1;
  }
  return 0;
};

const sortingArrayPerDisplayName = (
  moneyLendersArray: ListOfMoneyLenders[]
): ListOfMoneyLenders[] => {
  return moneyLendersArray.sort(sortCallback);
};

export default sortingArrayPerDisplayName;
