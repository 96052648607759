import { Grid } from '@mui/material';
import { DetailsRow } from 'lib/ClientDataDetails/Fields';
import { generalEnum } from 'screens/Clients/utils/generalEnum';
import TemplateDetails from '../../ClientData/components/TemplateDetails';
import { useTranslation } from 'react-i18next';
import { LoanPartsTableProps } from 'types/Client';
import { getStartdate } from 'screens/Clients/utils/calculateLoanParts';

export default function TableCollapseArea({ translationsRoot, loanPart }: LoanPartsTableProps) {
  const { t } = useTranslation();
  const startDate = getStartdate(loanPart.interestPeriod);

  return (
    <Grid container>
      <Grid item xs={12} md={6} px={3} py={2}>
        <TemplateDetails>
          <DetailsRow
            type="date"
            label={t(`${translationsRoot}.startDateLoanpart`)}
            value={loanPart.startDate}
          />
          <DetailsRow
            type="date"
            label={t(`${translationsRoot}.endDateLoanpart`)}
            value={loanPart.endDate}
          />
          <DetailsRow
            type="string"
            label={t(`${translationsRoot}.warrantytype`)}
            value={generalEnum('guarantee', loanPart.guarantee)}
          />
        </TemplateDetails>
      </Grid>
      <Grid item xs={12} md={6} p={2}>
        <TemplateDetails>
          <DetailsRow
            type="percentage"
            label={t(`${translationsRoot}.interestRate`)}
            value={loanPart.interestPeriod?.effectiveInterestRate}
          />
          <DetailsRow
            type="duration"
            label={t(`${translationsRoot}.fixedInterestPeriod`)}
            value={loanPart.interestPeriod?.duration}
          />
          <DetailsRow
            type="date"
            label={t(`${translationsRoot}.startDateInterestPeriod`)}
            value={startDate}
          />
          <DetailsRow
            type="date"
            label={t(`${translationsRoot}.endDateInterestPeriod`)}
            value={loanPart.interestPeriod?.renewalDate}
          />
        </TemplateDetails>
      </Grid>
    </Grid>
  );
}
