import { FC } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DetailsRow } from 'lib/ClientDataDetails/Fields';
import { generalEnum } from '../../../utils/generalEnum';
import { ClientExtractPersonType } from '__generated__/api-di';
import { mapMaritalStatus } from 'types/Client';
import TemplateDetails from './TemplateDetails';
import { ClientDetailsPerson } from 'types/ClientDetailsModel';
import formatFullName from 'utils/formatFullName/formatFullName';

type PersonalDetailsProps = {
  persons: ClientDetailsPerson[];
};

const PersonalDetails: FC<PersonalDetailsProps> = ({ persons }) => {
  const { t } = useTranslation();
  const translationRoot = 'clients.clientData.personalDetails';

  if (!persons.length) return null;

  return (
    <Grid container data-testid="personalDetails-container">
      {persons.map(({ natural, type }, index) => {
        const fullName = formatFullName(
          natural?.name?.initials,
          natural?.name?.lastNamePrefix,
          natural?.name?.lastName
        );
        return (
          <Grid xs={12} md={6} item px={3} py={2} key={`${fullName}-${index}`}>
            {type !== ClientExtractPersonType.NaturalPerson ? (
              <TemplateDetails title={fullName}>
                <></>
              </TemplateDetails>
            ) : (
              <TemplateDetails title={fullName}>
                <DetailsRow
                  type="string"
                  label={t(`${translationRoot}.initials`)}
                  value={natural?.name?.initials}
                />
                <DetailsRow
                  type="string"
                  label={t(`${translationRoot}.firstName`)}
                  value={natural?.name?.firstName}
                />
                <DetailsRow
                  type="string"
                  label={t(`${translationRoot}.lastNamePrefix`)}
                  value={natural?.name?.lastNamePrefix}
                />
                <DetailsRow
                  type="string"
                  label={t(`${translationRoot}.lastName`)}
                  value={natural?.name?.lastName}
                />
                <DetailsRow
                  type="date"
                  label={t(`${translationRoot}.dateOfBirth`)}
                  value={natural?.dateOfBirth}
                />
                <DetailsRow
                  type="string"
                  label={t(`${translationRoot}.placeOfBirth`)}
                  value={natural?.placeOfBirth}
                />
                <DetailsRow
                  type="string"
                  label={t(`${translationRoot}.gender`)}
                  value={generalEnum('gender', natural?.gender)}
                />
                <DetailsRow
                  type="string"
                  label={t(`${translationRoot}.nationality`)}
                  value={natural?.nationality}
                />
                <DetailsRow
                  type="string"
                  label={t(`${translationRoot}.civilStatus`)}
                  value={generalEnum('maritalStatus', mapMaritalStatus(natural?.maritalStatus))}
                />
              </TemplateDetails>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PersonalDetails;
