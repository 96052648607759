import { queryClient } from 'config/react-query';
import { QueryKey } from 'react-query';
import { DataUpdateFunction } from 'react-query/types/core/utils';
import { ConsentStatus, RequestConsent } from '__generated__/api-di';
import { postConsentRequest } from '../api/clientConsent';
import { Client, ClientLoan } from '../types/Client';

export const submitConsentRequest = async (group: string | null, loan: ClientLoan) => {
  const requestConsent: RequestConsent = {
    commercialLabel: loan.commercialLabel,
    loanNumber: loan.loanNumber,
  };

  const result = await postConsentRequest(group, requestConsent);
  switch (result.status) {
    case 200:
      return 'success';
    case 400:
    case 401:
      return 'badRequest';
    default:
      return 'error';
  }
};

export const isDataSharingEnabledForLoan = (loan: ClientLoan): boolean => {
  if (loan.loanType !== 'LiveMortgage') {
    return false;
  }

  const allowedCommercialLabelsDataSharing: string[] =
    (window as any).REACT_APP_CLIENT_DATA_SHARING_ALLOWED_FOR_COMMERCIAL_LABELS ?? [];

  const label = allowedCommercialLabelsDataSharing.find(
    (commercialLabel) => commercialLabel === loan.commercialLabel
  );

  return label !== undefined;
};
type CachedDataProps = {
  clients: Client[];
  total: number;
  syncedOnUtc: string;
};
export const optimisticUpdateClientConsent = (queryKey: QueryKey, loan: ClientLoan) => {
  const updatedConsent: ConsentStatus = ConsentStatus.Requested;
  queryClient.setQueryData<DataUpdateFunction<CachedDataProps, CachedDataProps>>(
    queryKey,
    (cachedData: CachedDataProps) => {
      if (!cachedData?.clients) return cachedData;
      const clients = cachedData.clients;
      const previousClientData = clients.find(({ loans }) =>
        loans.some(({ loanNumber }) => loan.loanNumber === loanNumber)
      );
      if (!previousClientData) return cachedData;

      const updatedLoanConsentStatusProperty = previousClientData.loans.map((innerLoan) => {
        if (innerLoan.loanNumber === loan.loanNumber) {
          return {
            ...innerLoan,
            consentStatus: updatedConsent,
          };
        }
        return innerLoan;
      });
      return { ...cachedData, updatedLoanConsentStatusProperty };
    }
  );
};
