import yupValidateAtResolver from 'utils/form/yupValidateAtResolver';
import { yupResolver } from '@hookform/resolvers/yup';
import { Step } from '../types';
import getSchema from '../schema/getSchema';
import getStep from './getStep';

const conversionRaiseResolver = (steps: Record<string, Step>, activeStepIndex: number) => {
  const step = getStep(steps, activeStepIndex);

  if (!step) return undefined;

  const { name: activeStep, index } = step;
  const schema = getSchema(activeStep);

  if (!schema) return undefined;
  if (
    activeStep === 'personalInfo' ||
    activeStep === 'contactDetails' ||
    activeStep === 'income' ||
    activeStep === 'obligation'
  ) {
    return yupValidateAtResolver(`applicants[${index}].${activeStep}`)(schema);
  }

  return yupResolver(schema);
};

export default conversionRaiseResolver;
