import { HEADER_SELECTED_GROUP_CLAIM, http } from '../http';

export type DownloadFiles = {
  dateCreated: string;
  description: string;
  fileId: string;
  dateAccessed?: string;
};
const getDownloadFile = async (reference: string) => {
  const headers = { responseType: 'arraybuffer' };
  const response = await http.get(`/api/v1/downloads/${reference}`, { headers });

  return response;
};
const getClientDataFiles = async (group: string | null): Promise<DownloadFiles[]> => {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };

  const response = await http.get<DownloadFiles[]>('/api/v1/downloads', { headers });
  return response.data;
};

export { getClientDataFiles, getDownloadFile };
