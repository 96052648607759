import { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { getId, useQueryApplicationDetailsEx } from 'use/cases';
import LettersAlert from './LettersAlert';
import StatusAlert from './StatusAlert';
import ProofItemsSection from 'components/ActionsSection';
import ApplicationDetailHeader from './ApplicationDetailHeader';
import ApplicationDetailInfo from './ApplicationDetailInfo';
import ScreenModal from 'components/ScreenModal';
import { useGoogleTagManager } from 'use/gtm';
import TasksAlert from './TaskAlert';
import { useQueryTasks } from 'use/tasks';
import ResolveTasks from 'components/ResolveTasks';
import { Case, TaskInfo } from '__generated__/api-di';
import Skeleton from '@mui/material/Skeleton';
import getCaseAddress from './utils/getCaseAddress';
import UploadDisabledAlert from './UploadDisabledAlert';
import { useTranslation } from 'react-i18next';
import { isUploadAllowed, isUploadAllowedForAssignedTeamType } from 'utils/proof-item';
import ViewApplicationFile from 'components/LetterList/ViewApplicationFile';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    padding: theme.spacing(2, 6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 3),
    },
  },
  detail: {
    '& > *': {
      marginBottom: theme.spacing(4),
    },
  },
  alertWrapper: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
}));

type ApplicationDetailProps = {
  application: { application: Case; from: string };
  onClose: () => void;
};

const ApplicationDetail = ({ application, onClose }: ApplicationDetailProps) => {
  const [modalOpen, setModalOpen] = useState(true);
  const hideModal = () => setModalOpen(false);
  const tagEvent = useGoogleTagManager();
  const classes = useStyles();

  const onClosed = () => {
    tagEvent({
      event: 'ButtonClick',
      btnName: 'CloseApplicationDetail',
    });
    onClose();
  };

  return (
    <ScreenModal open={modalOpen} onClose={hideModal} onClosed={onClosed}>
      <div className={classes.root}>
        <div className={classes.detail} data-testid="application-details">
          <ApplicationDetailHeader applicationData={application} onClose={onClose} />
          <ApplicationDetailContent applicationData={application} />
        </div>
      </div>
    </ScreenModal>
  );
};

type ApplicationDetailContentProps = {
  applicationData: { application: Case; from: string };
};

type DocumentReference = {
  displayName: string;
  commercialLabel: string;
  index: string;
  letterId: string;
  loanNumber?: string | null;
};

const ApplicationDetailContent = ({
  applicationData: { application },
}: ApplicationDetailContentProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [documentReference, setDocumentReference] = useState<DocumentReference | null>(null);
  const [showDocument, setShowDocument] = useState(false);
  const [selectedTask, setSelectedTask] = useState<TaskInfo>();
  const [resolveVisible, setResolveVisible] = useState(false);
  const { isLoading, data: caseDetails } = useQueryApplicationDetailsEx(getId(application));

  const tasksResponse = useQueryTasks({
    commercialLabel: application.lender!.label!,
    loanNumber: application.application!.loanNumberExternal!,
    index: application.application!.index!,
  });

  const tasks = tasksResponse.data ?? [];

  const openDocumentView = (
    displayName: string,
    commercialLabel: string,
    index: string,
    letterId: string,
    loanNumber?: string | null
  ) => {
    setDocumentReference({ displayName, commercialLabel, loanNumber, index, letterId });
    setShowDocument(true);
  };
  const closeDocumentView = () => {
    setDocumentReference(null);
    setShowDocument(false);
  };

  const openResolve = (task: TaskInfo) => {
    setSelectedTask(task);
    setResolveVisible(true);
  };

  const closeResolve = () => {
    setSelectedTask(undefined);
    setResolveVisible(false);
  };

  const info = application.application;

  const address = getCaseAddress(caseDetails);

  const uploadAllowedForCommercialLabels = isUploadAllowed(application);

  const uploadAllowedForAssignedTeamTypes = isUploadAllowedForAssignedTeamType(
    application,
    caseDetails
  );

  const applicationIndex = application.application!.index
    ? application.application!.index.toString()
    : null;

  const loanNumber = application.application!.loanNumberExternal
    ? application.application!.loanNumberExternal.toString()
    : null;

  const commercialLabel = application.lender!.label ? application.lender!.label : null;

  return (
    <>
      <ApplicationDetailInfo applicationData={application}>
        {isLoading ? <Skeleton width="60%" /> : address}
      </ApplicationDetailInfo>

      <div className={classes.alertWrapper} data-testid="applicationDetail-letterAlert">
        {applicationIndex && loanNumber && commercialLabel && (
          <LettersAlert
            urgency={application.application!.priority!.urgency!}
            loanNumber={loanNumber}
            commercialLabel={commercialLabel}
            applicationIndex={applicationIndex}
            openDocumentView={openDocumentView}
          />
        )}
        <TasksAlert tasks={tasks} openResolve={openResolve} />
        <StatusAlert status={info!.status!.value!} urgency={info!.priority?.urgency!} />
      </div>
      <ProofItemsSection
        application={application}
        caseDetails={caseDetails}
        tasks={tasks}
        openResolve={openResolve}
      >
        {caseDetails && !uploadAllowedForCommercialLabels ? (
          <UploadDisabledAlert
            message={t('applications.detail.uploadDisabledForCommercialLabels')}
          />
        ) : caseDetails && !uploadAllowedForAssignedTeamTypes ? (
          <UploadDisabledAlert
            message={t('applications.detail.uploadDisabledForAssignedTeamTypes')}
          />
        ) : null}
      </ProofItemsSection>

      <ResolveTasks
        application={application}
        open={resolveVisible}
        task={selectedTask}
        onClose={closeResolve}
      />

      {documentReference && documentReference.loanNumber && (
        <ViewApplicationFile
          displayName={documentReference.displayName}
          commercialLabel={documentReference.commercialLabel}
          loanNumber={documentReference.loanNumber}
          index={documentReference.index}
          letterId={documentReference.letterId}
          open={showDocument}
          onClose={closeDocumentView}
        />
      )}
    </>
  );
};

export default ApplicationDetail;
