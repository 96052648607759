import { useTranslation } from 'react-i18next';
import { Typography, Icon } from '@mui/material';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ApplicationStatus } from 'constants/index';
import ApplicationCardAlert from 'screens/Applications/components/ApplicationCard/ApplicationCardAlert';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderRadius: 6,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));

type StatusAlertProps = {
  status: string;
  urgency: string;
};

const StatusAlert = ({ status, urgency }: StatusAlertProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  switch (status) {
    case ApplicationStatus.rejected:
      return <ApplicationCardAlert urgency={urgency} text={t('home.applicationStatusRejected')} />;
    case ApplicationStatus.terminated:
      return (
        <ApplicationCardAlert urgency={urgency} text={t('home.applicationStatusTerminated')} />
      );
    case ApplicationStatus.new:
    case ApplicationStatus.inProgress:
    case ApplicationStatus.agreementMade:
    case ApplicationStatus.offerAdministrativeDocuments:
    case ApplicationStatus.inAdministrativeReview:
    case ApplicationStatus.creditAccepted:
    case ApplicationStatus.notaryDocumentsSent:
    case ApplicationStatus.managingApplications:
      return null;
    default:
      return (
        <div className={classes.root} data-testid="status-alert">
          <Icon className={classes.icon}>ban</Icon>
          <Typography variant="body2" color="textSecondary">
            {t('applications.detail.bsnText')}
          </Typography>
        </div>
      );
  }
};

export default StatusAlert;
