import { maximumNumber } from '../constants';
import { schemaDraft07 } from './constants';

const schema = {
  $schema: schemaDraft07,
  $id: 'https://e-adviseur.stater.com/buytolet/applicant-accumulated-income',
  title: 'Applicant accumulated income',
  type: 'array',
  minItems: 1,
  maxItems: 1,
  items: [
    {
      properties: {
        explanation: {
          type: 'string',
          isNotEmpty: true,
          minLength: 1,
          maxLength: 600,
        },
        income: {
          type: 'number',
          maximum: maximumNumber,
          minimum: 0,
        },
        typeOfAccumulatedIncome: {
          type: 'string',
        },
      },
      required: ['explanation', 'income', 'typeOfAccumulatedIncome'],
    },
  ],
};

export default schema;
