import styled from '@mui/styles/styled';
import {
  Box,
  TextField,
  Checkbox as MuiCheckbox,
  Grid,
  GridProps,
  Typography,
  Select,
  FormLabel as MUIFormLabel,
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export const ToggleButtonGroup = styled(MuiToggleButtonGroup)<any, any>(({ error, theme }) => ({
  border: error && `1px solid ${theme.palette.error.main}`,
}));
export const ToggleButton = styled(MuiToggleButton)({
  width: '50%',
});

export const InputWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 0, 3, 0),
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
}));

export const TextInput = styled(TextField)({
  '& .MuiInputBase-root': {
    minHeight: '48px',
  },
  '& .MuiOutlinedInput-inputMarginDense': {
    padding: '16px',
  },
});
export const DatePickerText = styled(TextField)({
  '& .MuiInputBase-formControl': {
    'minHeight': '48px',
    'marginBottom': '3px',
    '& .MuiOutlinedInput-input': {
      padding: '14.5px',
    },
  },
});

export const FieldsGrid = styled(Grid)<GridProps>({
  width: '100%',
  maxWidth: '440px',
  marginBottom: '32px',
});

export const InlineFieldsGrid = styled(Grid)<GridProps>({
  width: '100%',
  maxWidth: '440px',
});

export const Checkbox = styled(MuiCheckbox)(({ theme }) => ({
  '&.MuiCheckbox-colorError': { color: theme.palette.danger.dark },
}));

export const CustomSubText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '23px',
  letterSpacing: '0.01em',
  color: theme.palette.grey[900],
  padding: theme.spacing(0, 0, 3, 0),
}));

export const SelectInput = styled(Select)<any, any>(({ theme, isvalue }) => ({
  'width': '100%',
  '&.MuiInputBase-root': {
    height: '48px',
  },
  'color': isvalue && theme.palette.text.disabled,
}));

export const TextClip = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const Info = styled(InfoIcon)(({ theme }) => ({
  height: '16px',
  color: theme.palette.primary.main,
}));

export const FormLabel = styled(MUIFormLabel)(({ theme }) => ({
  margin: theme.spacing(1, 0, 0),
  lineHeight: '1.5',
  display: 'inline-block',
}));

export const Column = styled(Box)({
  display: 'inline-block',
});
