import { styled } from '@mui/material/styles';
import { Box as MuiBox } from '@mui/material';

const SpinnerWrapper = styled(MuiBox)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  alignItems: 'center',
});

export default SpinnerWrapper;
