type Props = {
  value: any;
  tabKey: any;
  children: React.ReactNode;
};

const TabPanel: React.FC<Props> = ({ children, value, tabKey }) => (
  <div role="tabpanel" hidden={value !== tabKey}>
    {value === tabKey && children}
  </div>
);

export default TabPanel;
