import { styled } from '@mui/material/styles';
import { Box as MuiBox, Typography } from '@mui/material';

const TitleBox = styled(MuiBox)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
}));

const Box = styled(MuiBox)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
}));

const Title = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(4, 0, 1),
  fontWeight: 400,
  fontSize: '32px',
  lineHeight: '38px',
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '12px',
  lineHeight: '19px',
}));

export { TitleBox, Box, Description, Title };
