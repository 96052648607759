import { lazy, number, object, string } from 'yup';
import { errorMessages, getTypeOfEmploymentOptions } from 'screens/Mutations/constants';
import { TypeOfEmployment } from '__generated__/api';
import { defaultDate, endDate } from 'utils/form/validations';
import isNil from 'utils/predicates/isNil';

const isSocialInsuranceWage = (v: TypeOfEmployment) =>
  v && v === TypeOfEmployment.SocialInsuranceWage;
const isFlexible = (v: TypeOfEmployment) =>
  v &&
  (v === TypeOfEmployment.FlexibleWithoutPerspective ||
    v === TypeOfEmployment.FlexibleWithPerspective);
const isPermanent = (v: TypeOfEmployment) => v && v === TypeOfEmployment.Permanent;
const isTemporaryWithIntent = (v: TypeOfEmployment) =>
  v && v === TypeOfEmployment.TemporaryWithIntent;

const employmentTypeValues = getTypeOfEmploymentOptions().map((f) => f.value);

// todo: move these to shared util
const defaultNumber = number().min(1).typeError(errorMessages.number).nullable(true);
const requiredNumber = defaultNumber.required(errorMessages.required);
const typeOfEmployment = string()
  .nullable(true)
  .oneOf(employmentTypeValues)
  .required(errorMessages.required);

const baseSchema = {
  typeOfEmployment,
};

const socialInsuranceWageSchema = {
  typeOfEmployment,
  incomeAmount: requiredNumber,
  numberOfEmployments: requiredNumber,
  pensionContribution: requiredNumber,
};

const defaultSchema = {
  ...baseSchema,
  startDate: defaultDate.isPastDate(errorMessages.PastDate).required(errorMessages.required),
  // max 2 digits
  averageHoursPerWeek: requiredNumber.max(99, errorMessages.maxNumber),
  grossSalary: requiredNumber,
  holidayAllowance: requiredNumber,
  irregularHoursAllowance: defaultNumber,
  thirteenthMonth: defaultNumber,
  lumpSumFee: defaultNumber,
  yearEndBonus: defaultNumber,
  overtimeAllowance: defaultNumber,
};

const flexibleSchema = {
  ...defaultSchema,
  endDate: endDate.required(errorMessages.required),
  flexibleAnnualIncome1: requiredNumber.min(0),
  flexibleAnnualIncome2: requiredNumber.min(0),
  flexibleAnnualIncome3: requiredNumber.min(0),
};

const incomeWithEndDateSchema = {
  ...defaultSchema,
  endDate: endDate.required(errorMessages.required),
};

const employmentSchema = lazy(({ typeOfEmployment }) => {
  if (isNil(typeOfEmployment)) return object().shape(baseSchema);

  if (isSocialInsuranceWage(typeOfEmployment)) {
    return object().shape(socialInsuranceWageSchema);
  }
  if (isFlexible(typeOfEmployment)) {
    return object().shape(flexibleSchema);
  }

  if (!isTemporaryWithIntent(typeOfEmployment) && !isPermanent(typeOfEmployment))
    return object().shape(incomeWithEndDateSchema);

  return object().shape(defaultSchema);
});

export default employmentSchema;
