import React, { FC } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Icon, InputBase } from '@mui/material';

type StyleProps = {
  width: string | number | undefined;
};

const useStyles = makeStyles((theme) => ({
  searchBox: (props: StyleProps) => ({
    width: props.width,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: props.width,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      boxShadow: '0 6px 12px 0 rgba(22, 20, 47, 0.12)',
      borderRadius: 6,
      padding: theme.spacing(1, 2),
      marginTop: theme.spacing(-4),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      maxWidth: 'none',
    },
  }),
  searchIcon: {
    position: 'absolute',
    pointerEvents: 'none',
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.icon.main,
  },
  searchIconFocus: {
    '& span': {
      color: theme.palette.icon.dark,
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: theme.spacing(1),
    color: theme.palette.icon.main,
  },
  inputRoot: {
    'display': 'block',
    'color': 'inherit',
    '&.Mui-focused::after': {
      width: '100%',
      background: theme.palette.primary.main,
    },
    '&::after': {
      content: '""',
      width: 0,
      height: 2,
      display: 'block',
      transition: 'width .2s ease-in-out',
      webkitTransition: 'width .2s ease-in-out',
    },
  },
  inputInput: {
    'paddingLeft': `calc(1em + ${theme.spacing(1)})`,
    'width': `calc(100% - ${theme.spacing(1)})`,
    'color': theme.palette.text.primary,
    'fontWeight': 500,
    '&::-ms-clear': {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: `calc(1em + ${theme.spacing(3)})`,
      width: `calc(100% - ${theme.spacing(3)})`,
    },
  },
  inputInputError: {
    color: theme.palette.error.dark,
  },
  inputRootError: {
    '&.Mui-focused::after': {
      background: theme.palette.error.dark,
    },
  },
}));

type Props = {
  placeholder: string;
  searchTerm: string;
  handleInputChange: (e: React.ChangeEvent<any>) => void;
  emptySearch: () => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  width?: string | number | undefined;
  isInvalid?: boolean;
};

const SearchBar: FC<Props> = ({
  placeholder,
  searchTerm,
  handleInputChange,
  emptySearch,
  onKeyDown,
  width,
  isInvalid,
}) => {
  const classes = useStyles({ width: width });
  const [iconDark, setIconDark] = React.useState(false);
  return (
    <Box position="relative" className={classes.searchBox}>
      <div className={clsx(classes.searchIcon, { [classes.searchIconFocus]: iconDark })}>
        <Icon fontSize="small">search</Icon>
      </div>
      <InputBase
        inputProps={{ 'data-testid': 'search-bar-input-wrapper' }}
        placeholder={placeholder}
        classes={{
          root: `${classes.inputRoot} ${isInvalid ? classes.inputRootError : ''}`,
          input: `${classes.inputInput} ${isInvalid ? classes.inputInputError : ''}`,
        }}
        value={searchTerm}
        onClick={(e) => e.stopPropagation()}
        onChange={handleInputChange}
        onKeyDown={onKeyDown}
        onFocus={() => setIconDark(true)}
        onBlur={() => setIconDark(false)}
      />
      {searchTerm.length > 0 && (
        <div
          data-testid="search-bar-clear-input"
          className={classes.closeIcon}
          onClick={(e) => {
            e.stopPropagation();
            emptySearch();
          }}
        >
          <Icon fontSize="small">close</Icon>
        </div>
      )}
    </Box>
  );
};

export default SearchBar;
