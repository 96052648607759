import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Step, StepConnector, Stepper, StepLabel } from '@mui/material';
import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(1, 3, 2),
    backgroundColor: 'rgba(54, 26, 96, 0.5)',
    borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 1),
    },
  },
  step: {
    cursor: 'pointer',
  },
  noPadding: {
    paddingTop: 8,
    paddingBottom: 0,
  },
  stepDisabled: {
    cursor: 'default',
  },
  stepLabel: {
    'color': alpha(theme.palette.common.white, 0.4),
    'height': 36,
    'display': 'flex',
    'fontSize': 14,
    'fontWeight': 300,
    'justifyContent': 'space-between',
    'alignItems': 'center',
    '&.Mui-active': {
      color: theme.palette.common.white,
      fontWeight: 400,
    },
    '&.Mui-completed': {
      color: alpha(theme.palette.common.white, 0.4),
      fontWeight: 300,
    },
  },
  stepConnector: {
    borderColor: 'transparent',
  },
}));

type Props = {
  activeApplicantIndex: number;
  activeSubStep: number;
  changeSubStep: (index: number) => void;
  isLegalPerson?: boolean | null;
  latestSubStepSeen: any;
};

const BuyToLetApplicantSteps: FC<Props> = ({
  activeApplicantIndex,
  activeSubStep,
  changeSubStep,
  isLegalPerson,
  latestSubStepSeen,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const applicantSteps =
    isLegalPerson === false
      ? ['personalData', 'incomesAndProperties', 'contactData']
      : isLegalPerson === true
      ? ['personalData', 'contactData']
      : [];

  return applicantSteps.length === 0 ? null : (
    <Stepper
      className={classes.stepper}
      activeStep={activeSubStep}
      orientation="vertical"
      connector={
        <StepConnector
          classes={{
            line: classes.stepConnector,
          }}
        />
      }
    >
      {applicantSteps.map((label, index) => {
        const stepIsDisabled = index > latestSubStepSeen[activeApplicantIndex];
        return (
          <Step
            key={`${label}-${index}`}
            disabled={stepIsDisabled}
            className={`${!stepIsDisabled && classes.step} ${
              stepIsDisabled && classes.stepDisabled
            }`}
          >
            <StepLabel
              classes={{
                label: classes.stepLabel,
              }}
              className={classes.noPadding}
              onClick={() => !stepIsDisabled && changeSubStep(index)}
              // disabled={stepIsDisabled}
            >
              {t(`buyToLetForm.subStepTitle.${label}`)}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default BuyToLetApplicantSteps;
