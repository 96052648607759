import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Hidden } from '@mui/material';
import { ReUploadSolution } from '../../../store/taskReducer';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    'display': 'flex',
    'minHeight': 80,
    '&:hover': {
      backgroundColor: theme.palette.white.light,
    },
    'borderRadius': 6,
    'alignItems': 'flex-start',
    'padding': theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 0),
    },
  },
  icon: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
  },
  text: {
    flex: 1,
  },
  text2: {
    marginBottom: theme.spacing(0),
  },
  interpunct: {
    margin: theme.spacing(0, 1),
  },
  small: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    display: 'block',
    paddingTop: theme.spacing(0.5),
  },
  proofItemName: {
    textTransform: 'capitalize',
  },
  fileName: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
}));

interface ReUploadCardProps {
  issueId: string;
  solution: ReUploadSolution;
}

const iconNeutral = require('../../../assets/images/proof-item/neutral.svg');

const ReUploadCard: React.FC<ReUploadCardProps> = ({ issueId, solution }) => {
  const classes = useStyles();

  if (!solution.data.file) {
    return null;
  }

  const icon = iconNeutral.default ?? iconNeutral;

  return (
    <div className={classes.root}>
      <Hidden mdDown>
        <img className={classes.icon} src={icon} alt="" />
      </Hidden>
      <div className={classes.text}>
        <div className={classes.text2}>
          <Typography className={clsx([classes.proofItemName])} variant="body2">
            {solution.data.proofItem?.typeCode} {/* TODO: Translate */}
          </Typography>
          <div className={classes.fileName}>
            <Typography className={clsx([classes.small])} variant="body2">
              {solution.data.file.name}
            </Typography>
            <Typography className={clsx([classes.small, classes.interpunct])}>‧</Typography>
            <Typography className={clsx([classes.small])} variant="body2">
              {solution.data.file.size} kb
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReUploadCard;
