import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  main: {
    flexGrow: 100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  footer: {
    textAlign: 'right',
    minHeight: 40,
    flexGrow: 1,
  },
  fullHeight: {
    height: '100%',
  },
  buttonIcon: {
    width: 20,
    marginLeft: -4,
    marginRight: 8,
  },
  cancel: {
    'margin': theme.spacing(2, 2, 2, 0),
    'color': theme.palette.error.main,
    'borderColor': theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.dark,
      borderColor: theme.palette.error.dark,
      backgroundColor: theme.palette.white.main,
    },
  },
}));

type Props = {
  percentage: number;
  selectedFile?: File;
  onCancel: () => void;
};

const Uploading: FC<Props> = ({ percentage, selectedFile, onCancel }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="stretch"
      className={classes.fullHeight}
    >
      <div className={classes.main}>
        <div className={classes.center}>
          <p>Uploading {selectedFile?.name}</p>
          <ProgressBar percentage={percentage} />
        </div>
      </div>

      <div className={classes.footer}>
        <Button color="secondary" variant="contained" onClick={onCancel} className={classes.cancel}>
          <NotInterestedIcon className={classes.buttonIcon} />
          {t('applications.upload.cancelButton')}
        </Button>
      </div>
    </Box>
  );
};

export default Uploading;
