import { Hidden } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Container,
  HeaderText,
  AmountColumn,
  TextColumn,
  RentColumn,
  DateColumn,
} from '../Loan.styled';

export const LoanPartHeader = () => {
  const { t } = useTranslation();

  return (
    <Hidden smDown>
      <Container container justifyContent="space-between">
        <TextColumn item>
          <HeaderText variant="overline">{t('directMutations.form.part')}</HeaderText>
        </TextColumn>
        <AmountColumn item>
          <HeaderText variant="overline">{t('directMutations.form.loanAmount')}</HeaderText>
        </AmountColumn>
        <Hidden xlDown>
          <AmountColumn item>
            <HeaderText variant="overline">{t('directMutations.form.box1')}</HeaderText>
          </AmountColumn>
          <AmountColumn item>
            <HeaderText variant="overline">{t('directMutations.form.box3')}</HeaderText>
          </AmountColumn>
        </Hidden>
        <TextColumn item>
          <HeaderText variant="overline">{t('directMutations.form.repaymentType')}</HeaderText>
        </TextColumn>
        <RentColumn item>
          <HeaderText variant="overline">{t('directMutations.form.fixedRatePeriod')}</HeaderText>
        </RentColumn>
        <RentColumn item>
          <HeaderText variant="overline">{t('directMutations.form.interestPercentage')}</HeaderText>
        </RentColumn>
        <Hidden xlDown>
          <DateColumn item>
            <HeaderText variant="overline">
              {t('directMutations.form.endDateInterestDeduction')}
            </HeaderText>
          </DateColumn>
        </Hidden>
      </Container>
    </Hidden>
  );
};
export default LoanPartHeader;
