import { Grid } from '@mui/material';
import { FC, useState, useEffect, useCallback } from 'react';
import { FeatureOverview, Category } from 'types/FunctionalitiesOverview';
import Table from './Table/Table';
import handleRowsPerPageToScreenSize from '../utils/handleRowsPerPage';
import TablePagination from './Table/TablePagination/TablePagination';
import Logo from 'components/Logo';
import { GridRow, GridRowHeader, TableHeader } from '../Overview.style';

type OverviewTableProps = FeatureOverview;

const OverviewTable: FC<OverviewTableProps> = ({ categories, allCommercialLabels }) => {
  const [entriesperPage, setEntriesPerPage] = useState<number>(
    handleRowsPerPageToScreenSize(window.innerWidth)
  );
  const [page, setPage] = useState<number>(0);
  const [isScroll, setScroll] = useState<1 | 0>(0);
  const totalEntries = allCommercialLabels.length;
  const isPreviousButtonDisabled = page === 0;
  const isNextButtonDisabled = totalEntries - page * entriesperPage <= entriesperPage;

  useEffect(() => {
    function handleScrolling() {
      const scrollY = window.scrollY;
      setScroll(scrollY > 200 ? 1 : 0);
    }

    window.addEventListener('scroll', handleScrolling);

    return () => {
      window.removeEventListener('scroll', handleScrolling);
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      setEntriesPerPage(handleRowsPerPageToScreenSize(window.innerWidth));
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    setPage(0);
  }, []);

  const handlePageChange = useCallback(
    (isNext: boolean) => () => {
      if (!isNext && !isPreviousButtonDisabled) {
        const targetPage = page - 1;

        setPage(targetPage);
      }
      if (isNext && !isNextButtonDisabled) {
        const targetPage = page + 1;
        setPage(targetPage);
      }
    },
    [page, isNextButtonDisabled, isPreviousButtonDisabled]
  );

  return (
    <Grid container>
      <Grid container direction="column" wrap="nowrap" sx={{ width: 'unset' }}>
        <TableHeader isscroll={isScroll}>
          <TablePagination
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            isNextButtonDisabled={isNextButtonDisabled}
            handlePageChange={handlePageChange}
          />
          <Grid container direction="row">
            <GridRowHeader item />
            {allCommercialLabels
              .slice(page * entriesperPage, page * entriesperPage + entriesperPage)
              .map((commercialLabel) => (
                <GridRow item key={commercialLabel}>
                  <Logo label={commercialLabel} />
                </GridRow>
              ))}
          </Grid>
        </TableHeader>
        {categories.map((category: Category) => (
          <Table
            key={category.name}
            category={category}
            page={page}
            entriesperPage={entriesperPage}
          />
        ))}
      </Grid>
    </Grid>
  );
};
export default OverviewTable;
