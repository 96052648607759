import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const OnboardingPopover = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!props.anchorEl) {
    return null;
  }

  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      anchorReference="anchorEl"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={props.onClose}
      style={{ overflow: 'visible' }}
    >
      <div className={classes.root}>
        <Typography color="textSecondary" variant="h5">
          {t('onboarding.popover.title')}
        </Typography>
        <Typography color="textSecondary">{t('onboarding.popover.text')}</Typography>
        <Button color="primary" onClick={props.onClose}>
          {t('onboarding.popover.close')}
        </Button>
      </div>
    </Popover>
  );
};

export default OnboardingPopover;
