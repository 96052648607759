import { FC, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getTypeOfEmploymentOptions } from 'screens/Mutations/constants';
import {
  FormInputCurrency,
  FormInputDate,
  FormInputDropdown,
  FormInputNumber,
} from 'components/Form';
import { TypeOfEmployment } from '__generated__/api';
import useUnregisterFields from 'screens/Mutations/hooks/useUnregisterField';
import { Grid } from '@mui/material';

type Props = {
  index?: number;
  rootFormName?: string;
  // Omzetten en verhogen has a single form this results in shouldUnregister not working correctly
  shouldUnregister?: boolean;
};

const Employment: FC<Props> = ({ index = 0, rootFormName, shouldUnregister = true }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const formIncomes = useWatch({ control, name: `${rootFormName || ''}incomes` });
  const unregisterMultiple = useUnregisterFields();
  const income = formIncomes && formIncomes.length ? formIncomes[index] : null;
  const typeOfEmployment = income && income.employment?.typeOfEmployment;
  const prefix = `${rootFormName || ''}incomes.${index}.employment`;

  const rootTranslations = 'directMutations.income.employment';

  const isFlexible =
    typeOfEmployment === TypeOfEmployment.FlexibleWithoutPerspective ||
    typeOfEmployment === TypeOfEmployment.FlexibleWithPerspective;
  const isSocialInsuranceWage = typeOfEmployment === TypeOfEmployment.SocialInsuranceWage;

  const hasEndDate =
    typeOfEmployment !== TypeOfEmployment.Permanent &&
    typeOfEmployment !== TypeOfEmployment.TemporaryWithIntent;

  const fields = {
    commonFields: [
      `${prefix}.startDate`,
      `${prefix}.overtimeAllowance`,
      `${prefix}.averageHoursPerWeek`,
      `${prefix}.grossSalary`,
      `${prefix}.holidayAllowance`,
      `${prefix}.yearEndBonus`,
      `${prefix}.irregularHoursAllowance`,
      `${prefix}.thirteenthMonth`,
      `${prefix}.lumpSumFee`,
    ],
    SocialInsuranceWage: [
      `${prefix}.incomeAmount`,
      `${prefix}.numberOfEmployments`,
      `${prefix}.pensionContribution`,
    ],
    Flexible: [
      `${prefix}.endDate`,
      `${prefix}.flexibleAnnualIncome1`,
      `${prefix}.flexibleAnnualIncome2`,
      `${prefix}.flexibleAnnualIncome3`,
    ],
  };
  useEffect(() => {
    if (!shouldUnregister) {
      if (!isFlexible) {
        unregisterMultiple(fields.Flexible);
      }
      if (!hasEndDate) {
        unregisterMultiple([`${prefix}.endDate`]);
      }
      if (!isSocialInsuranceWage) {
        unregisterMultiple(fields.SocialInsuranceWage);
      }
      if (isSocialInsuranceWage) {
        unregisterMultiple(fields.commonFields);
        unregisterMultiple(fields.Flexible);
      }
    }
    // eslint-disable-next-line
  }, [typeOfEmployment]);
  const testIdPrefix = `incomes.${index}`;
  return (
    <div data-testid="employment-fieldset">
      <FormInputDropdown
        name={`${prefix}.typeOfEmployment`}
        label={t(`${rootTranslations}.typeOfEmployment`)}
        options={getTypeOfEmploymentOptions()}
        placeholder={t(`${rootTranslations}.chooseTypeOfEmployment`)}
        required
        testId={`${testIdPrefix}.typeOfEmployment`}
      />
      {isSocialInsuranceWage && (
        <>
          <FormInputCurrency
            name={`${prefix}.incomeAmount`}
            label={t(`${rootTranslations}.incomeAmount`)}
            required
            testId={`${testIdPrefix}.incomeAmount`}
          />
          <FormInputNumber
            name={`${prefix}.numberOfEmployments`}
            label={t(`${rootTranslations}.numberOfEmployments`)}
            required
            testId={`${testIdPrefix}.numberOfEmployments`}
          />
          <FormInputCurrency
            name={`${prefix}.pensionContribution`}
            label={t(`${rootTranslations}.pensionContribution`)}
            required
            testId={`${testIdPrefix}.pensionContribution`}
          />
        </>
      )}

      {typeOfEmployment && !isSocialInsuranceWage && (
        <>
          <Grid container columnSpacing={3}>
            <Grid item xs={12} sm={hasEndDate ? 6 : 12}>
              <FormInputDate
                name={`${prefix}.startDate`}
                label={t(`${rootTranslations}.startDate`)}
                required
                disableFuture
                testId={`${testIdPrefix}.startDate`}
              />
            </Grid>
            {hasEndDate && (
              <Grid item xs={12} sm={6}>
                <FormInputDate
                  name={`${prefix}.endDate`}
                  label={t(`${rootTranslations}.endDate`)}
                  required
                  disablePast
                  shouldUnregister={shouldUnregister}
                  testId={`${testIdPrefix}.endDate`}
                />
              </Grid>
            )}
          </Grid>
          <FormInputNumber
            name={`${prefix}.averageHoursPerWeek`}
            label={t(`${rootTranslations}.averageHoursPerWeek`)}
            required
            testId={`${testIdPrefix}.averageHoursPerWeek`}
            max={99}
            decimalScale={0}
          />
          <FormInputCurrency
            name={`${prefix}.grossSalary`}
            label={t(`${rootTranslations}.grossSalary`)}
            required
            testId={`${testIdPrefix}.grossSalary`}
          />
          {isFlexible && (
            <>
              <FormInputCurrency
                name={`${prefix}.flexibleAnnualIncome1`}
                label={t(`${rootTranslations}.flexibleAnnualIncome1`)}
                required
                shouldUnregister={shouldUnregister}
                min={0}
                testId={`${testIdPrefix}.flexibleAnnualIncome1`}
              />
              <FormInputCurrency
                name={`${prefix}.flexibleAnnualIncome2`}
                label={t(`${rootTranslations}.flexibleAnnualIncome2`)}
                required
                shouldUnregister={shouldUnregister}
                min={0}
                testId={`${testIdPrefix}.flexibleAnnualIncome2`}
              />
              <FormInputCurrency
                name={`${prefix}.flexibleAnnualIncome3`}
                label={t(`${rootTranslations}.flexibleAnnualIncome3`)}
                required
                shouldUnregister={shouldUnregister}
                min={0}
                testId={`${testIdPrefix}.flexibleAnnualIncome3`}
              />
            </>
          )}
          <FormInputCurrency
            name={`${prefix}.holidayAllowance`}
            label={t(`${rootTranslations}.holidayAllowance`)}
            required
            testId={`${testIdPrefix}.holidayAllowance`}
          />
          <FormInputCurrency
            name={`${prefix}.irregularHoursAllowance`}
            label={t(`${rootTranslations}.irregularHoursAllowance`)}
            testId={`${testIdPrefix}.irregularHoursAllowance`}
          />
          <FormInputCurrency
            name={`${prefix}.thirteenthMonth`}
            label={t(`${rootTranslations}.thirteenthMonth`)}
            testId={`${testIdPrefix}.thirteenthMonth`}
          />
          <FormInputCurrency
            name={`${prefix}.lumpSumFee`}
            label={t(`${rootTranslations}.lumpSumFee`)}
            testId={`${testIdPrefix}.lumpSumFee`}
          />
          <FormInputCurrency
            name={`${prefix}.yearEndBonus`}
            label={t(`${rootTranslations}.yearEndBonus`)}
            testId={`${testIdPrefix}.yearEndBonus`}
          />
          <FormInputCurrency
            name={`${prefix}.overtimeAllowance`}
            label={t(`${rootTranslations}.overtimeAllowance`)}
            testId={`${testIdPrefix}.overtimeAllowance`}
          />
        </>
      )}
    </div>
  );
};

export default Employment;
