import type { Labels } from 'types/FunctionalitiesOverview';
import type { Profile } from '__generated__/api';
import sortingArrayPerDisplayName from './sortingArrayPerDisplayName';

interface featureCommercialLabels extends Labels {
  displayName?: string;
}
function addDisplayNameToObjectList(
  lables: Labels[],
  profiles: Profile[]
): featureCommercialLabels[] {
  return lables.map((element) => {
    const profile = profiles.find(({ identifier }) => element.label === identifier);
    return {
      ...element,
      displayName: profile?.displayName,
    };
  });
}
function sortTableList(lables: Labels[], profiles?: Profile[]) {
  if (!profiles) return lables;
  const displayNameLabels: featureCommercialLabels[] = addDisplayNameToObjectList(lables, profiles);

  return sortingArrayPerDisplayName(displayNameLabels);
}

export default sortTableList;
