import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Fab, Icon, Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import OnboardingIcon from './OnboardingStepIcon';
import OnboardingProgress from './OnboardingStepProgress';
import OnboardingStepFinish from './OnboardingStepFinish';
import videos from './videos.json';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(4),
    width: 32,
    height: 32,
  },
  aside: {
    zIndex: 99,
  },
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowWrap: 'break-word',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(8, 12),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(8, 12),
    },
    transition: 'opacity 0.5s',
  },
  icon: {
    margin: theme.spacing(4, 0),
  },
  title: {
    flexShrink: 1,
    marginBottom: theme.spacing(1),
  },

  buttons: {
    '& > *': {
      marginRight: theme.spacing(6),
      marginTop: theme.spacing(2),
    },
  },
  videos: {
    position: 'relative',
    minHeight: '400px',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  video: {
    // stack videos, prevent flickering
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'transparent',
    pointerEvents: 'none',
    width: '100%',
  },
  hidden: {
    opacity: 0,
  },
}));

interface OnboardingStepProps {
  onClose: () => void;
}

const OnboardingStep: React.FC<OnboardingStepProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [step, setStep] = React.useState(0);
  const prevStep = () => setStep(step === 1 ? step : step - 1);
  const nextStep = () => setStep(step + 1);

  React.useEffect(() => {
    if (step === 0) {
      // TODO: maybe wait for all videos to load?
      setTimeout(() => setStep(1), 1500);
    }
  }, [step]);

  if (step === 6) {
    // Show outro and move to step 7
    setTimeout(() => setStep(7), 700);
  }
  if (step === 7) {
    return <OnboardingStepFinish onFinish={props.onClose} onReplay={() => setStep(0)} />;
  }

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.aside} md={3} xs={12}>
        <div className={clsx([classes.content, { [classes.hidden]: step === 0 }])}>
          <OnboardingProgress currentStep={step} />
          <div className={classes.icon}>
            <OnboardingIcon icon={videos[step].icon} />
          </div>
          <Typography variant="h3" className={classes.title}>
            {t(`onboarding.step.title${step}`)}
          </Typography>
          <Typography color="textSecondary">{t(`onboarding.step.text${step}`)}</Typography>
          <div className={classes.buttons}>
            <Fab color="secondary" size="medium" onClick={prevStep} disabled={step === 1}>
              <Icon>arrow_left</Icon>
            </Fab>
            <Fab color="primary" size="medium" onClick={nextStep}>
              <Icon>arrow_right</Icon>
            </Fab>
          </div>
        </div>
      </Grid>
      <Grid item md={9} xs={12} className={classes.videos}>
        {videos.map((video, index) => (
          <OnboardingVideo key={video.src} currentStep={step} index={index} src={video.src} />
        ))}
      </Grid>
    </Grid>
  );
};

interface OnboardingVideoProps {
  currentStep: number;
  index: number;
  src: string;
}

const OnboardingVideo: React.FC<OnboardingVideoProps> = (props) => {
  const { currentStep, index, src } = props;
  const classes = useStyles();
  const videoRef = React.useRef<HTMLVideoElement | null>(null);

  React.useEffect(() => {
    const video = videoRef.current;

    if (video) {
      if (currentStep === index) {
        video.currentTime = 0;
        video.play();
      } else {
        video.pause();
        video.currentTime = 0;
      }
    }
  }, [currentStep]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <video
      autoPlay
      height="100%"
      ref={videoRef}
      className={clsx([classes.video, { [classes.hidden]: currentStep < index }])}
    >
      <source type="video/mp4" src={src} />
    </video>
  );
};

export default OnboardingStep;
