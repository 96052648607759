import { Box, Button, Grid, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import LoanParts from '../LoanParts';
import { LoanInfo } from 'components/MutationDialog/useMutation';
import { yesNoGroupOptions } from '../../constants';
import { Option } from '../../../../utils/types';
import {
  FormInputCurrency,
  FormInputNumber,
  FormTitleAndSubtitleText,
  FormToggleButtonGroup,
} from 'components/Form';
import { RaiseMutationTypography, TogglesWrapper } from './LoanDetails.styled';
import { useClients } from 'screens/Clients/hooks/useClients';

type LoanDetailsProps = {
  mutationInfo?: LoanInfo;
  technicalProductOptions: Option[];
};

export const LoanDetails: FC<LoanDetailsProps> = ({ technicalProductOptions, mutationInfo }) => {
  const { handleNavigateToClient } = useClients();
  const { resetField, watch } = useFormContext();
  const { t } = useTranslation();
  const loanNumber = watch('conversionQuestionnaire.loanNumber');
  const toggleInterest = watch('conversionQuestionnaire.toggleInterest');
  const toggleFine = watch('conversionQuestionnaire.toggleFine');
  const toggleCoFinancedFine = watch('conversionQuestionnaire.toggleCoFinancedFine');
  const raiseMutation = mutationInfo?.mutations?.find((mutation) => mutation.type === 'Raise');

  const needsIncreaseMutation = () => {
    if (toggleInterest === 'yes' && toggleFine === 'yes' && toggleCoFinancedFine === 'yes')
      return true;

    return undefined;
  };

  const continueMutation = () => {
    if (toggleInterest === 'no' || toggleFine === 'no' || toggleCoFinancedFine === 'no')
      return true;

    return undefined;
  };

  // reset toggleFine and toggleCoFinancedFine when toggleInterest changes
  useEffect(() => {
    if (!toggleInterest || toggleInterest === 'no') {
      resetField('conversionQuestionnaire.toggleFine');
      resetField('conversionQuestionnaire.toggleCoFinancedFine');
    }
  }, [toggleInterest, resetField]);

  // reset toggleCoFinancedFine when toggleFine changes
  useEffect(() => {
    if (!toggleFine || toggleFine === 'no') {
      resetField('conversionQuestionnaire.toggleCoFinancedFine');
    }
  }, [toggleFine, resetField]);
  return (
    <Container data-testid="loanDetails-wrapper">
      <FormTitleAndSubtitleText
        title={t('directMutations.loanDetails.title')}
        description={t('directMutations.loanDetails.description')}
      />
      <TogglesWrapper>
        <Grid item xs={12} sm={8}>
          <FormInputNumber
            name="conversionQuestionnaire.loanNumber"
            label={t('directMutations.form.loanNumber')}
            disabled
            min={0}
            decimalScale={0}
            thousandSeparator
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <FormToggleButtonGroup
            name="conversionQuestionnaire.toggleInterest"
            options={yesNoGroupOptions}
            label={t('directMutations.loanDetails.toggleInterest')}
            required
          />
        </Grid>

        {toggleInterest === 'yes' && (
          <Grid item xs={12} sm={8}>
            <FormToggleButtonGroup
              name="conversionQuestionnaire.toggleFine"
              options={yesNoGroupOptions}
              label={t('directMutations.loanDetails.toggleFine')}
              required
            />
          </Grid>
        )}

        {toggleFine === 'yes' && (
          <Grid item xs={12} sm={8}>
            <FormToggleButtonGroup
              name="conversionQuestionnaire.toggleCoFinancedFine"
              options={yesNoGroupOptions}
              label={t('directMutations.loanDetails.toggleCoFinancedFine')}
              required
            />
          </Grid>
        )}

        {continueMutation() && (
          <Grid item xs={12} sm={8}>
            <FormInputCurrency
              name="conversionQuestionnaire.residualDebt"
              label={t('directMutations.loanDetails.residualDebt')}
              required
            />
          </Grid>
        )}
      </TogglesWrapper>
      {continueMutation() && (
        <LoanParts technicalProductOptions={technicalProductOptions} loanNumber={loanNumber} />
      )}
      {needsIncreaseMutation() && (
        <Box data-testid="loanDetails-increaseMutation">
          {mutationInfo && raiseMutation ? (
            <>
              <RaiseMutationTypography variant="body1">
                {t('directMutations.loanDetails.raiseMutation')}
              </RaiseMutationTypography>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  handleNavigateToClient({
                    fromMutation: true,
                    loanNumber: loanNumber,
                  })
                }
              >
                {t('directMutations.loanDetails.goToRaiseMutation')}
              </Button>
            </>
          ) : (
            <>
              <RaiseMutationTypography variant="body1">
                {t(`directMutations.loanDetails.${mutationInfo ? 'noRaiseMutation' : 'error'}`)}
              </RaiseMutationTypography>
            </>
          )}
        </Box>
      )}
    </Container>
  );
};

export default LoanDetails;
