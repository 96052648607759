import React, { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { List, ListItem, Paper, TextField } from '@mui/material';
import { Action, CommentSolution, SolutionState } from '../../../store/taskReducer';
import CommentCard from './CommentCard';
import AddNewCard from './AddNewCard';
import ReUploadCard from './ReUploadCard';
import SolvedByOtherCard from './SolvedByOtherCard';
import { isTaskEnded } from 'utils/global';
import SolutionTaskListIssueGroupHeading from './SolutionTaskListIssueGroupHeading';
import IssueCard from './IssueCard';
import { taskSolutionGenericCommentKey } from '../../../constants';
import { Case, TaskDetails, TaskInfoDelegationState } from '__generated__/api-di';
import { groupBy } from 'utils/array';

const iconWidth = 40;
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      display: 'flex',
    },
  },
  header: {
    height: 72,
    padding: theme.spacing(2, 4),
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    boxShadow: '0 6px 12px 0 rgba(22,20,47,0.12)',
    position: 'relative',
    zIndex: 1300,
  },
  logo: {
    width: 48,
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(1),
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
  },
  wrapper: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100% - 72px)',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 900,
    // margin: '60px 100px',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4),
    },
  },
  stepper: {
    padding: theme.spacing(7.5, 4),
    backgroundColor: 'transparent',
  },
  stepConnector: {
    borderColor: 'transparent',
  },
  stepLabel: {
    'width': 250,
    'height': 40,
    'lineHeight': '40px',
    'padding': theme.spacing(0, 3),
    'borderRadius': theme.shape.borderRadius,
    'color': theme.palette.common.white,
    'fontSize': 14,
    'fontWeight': 500,
    '&.Mui-active': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-completed': {
      color: theme.palette.common.white,
    },
  },
  spacer: {
    flexGrow: 1,
  },
  bottomNav: {
    position: 'fixed',
    bottom: theme.spacing(3),
  },
  fabLeft: {
    marginLeft: theme.spacing(-8),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(0),
    },
  },
  fabRight: {
    right: theme.spacing(3),
  },
  fabIcon: {
    marginLeft: theme.spacing(1),
    transform: 'scale(0.8)',
  },
  paper: {
    'width': '100%',
    'padding': theme.spacing(1, 0),
    'borderRadius': '8px',
    '& hr': {
      marginLeft: `calc(${iconWidth}px + ${theme.spacing(2)})`,
      backgroundColor: 'rgba(0,0,0,0.08)',
    },
    'marginBottom': theme.spacing(2),
  },
  fileIcon: {
    width: iconWidth,
    marginRight: theme.spacing(2),
  },
  input: {
    // display: 'none',
  },
  multiline: {
    minHeight: 120,
  },
  description: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
  dangerBtn: {
    marginLeft: theme.spacing(1),
    color: theme.palette.danger.main,
  },
  mobileDiv: {
    display: 'flex',
    flexDirection: 'column',
  },
  viewIcon: {
    color: theme.palette.grey[700],
    marginBottom: '6px',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  small: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    display: 'block',
    paddingTop: theme.spacing(0.5),
  },
  proofItemName: {
    textTransform: 'capitalize',
  },
  solutions: {
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const Summary: FC<{
  task: TaskDetails;
  application: Case;
  solutionState: SolutionState;
  dispatch: React.Dispatch<Action>;
}> = ({ task, application, solutionState, dispatch }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const genericCommentSolution = solutionState.get(taskSolutionGenericCommentKey);
  const handleFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: genericCommentSolution ? 'update' : 'add',
      payload: {
        issueId: taskSolutionGenericCommentKey,
        data: {
          type: 'comment',
          id: genericCommentSolution?.[0].id ?? uuidv4(),
          data: {
            comment: e.target.value,
          },
        },
      },
    });
  };

  const groupedIssues = groupBy(task.context!.issues ?? [], (issue) => issue.description!);

  return (
    <>
      <List disablePadding>
        {Array.from(groupedIssues.keys()).map((key) => {
          const issues = groupedIssues.get(key) ?? [];
          return (
            <>
              <SolutionTaskListIssueGroupHeading description={key} />
              <Paper className={classes.paper}>
                {issues.map((issue) => {
                  const solutions = solutionState.get(issue.id!) ?? [];
                  const taskState =
                    solutions.length > 0 ? TaskInfoDelegationState.Completed : task.delegationState;
                  return (
                    <ListItem key={`${issue.id}`} data-testid="issue-list-item">
                      <div className={classes.content}>
                        <IssueCard issue={issue} taskState={taskState!} />
                        <div className={classes.solutions}>
                          {solutions.map((solution) => {
                            return (
                              <React.Fragment key={solution.id}>
                                {solution.type === 'comment' && (
                                  <CommentCard issueId={issue.id!} solution={solution} />
                                )}
                                {solution.type === 're-upload' && (
                                  <ReUploadCard issueId={issue.id!} solution={solution} />
                                )}
                                {solution.type === 'addNew' && (
                                  <AddNewCard issueId={issue.id!} solution={solution} />
                                )}
                                {solution.type === 'solvedByOther' && <SolvedByOtherCard />}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    </ListItem>
                  );
                })}
              </Paper>
            </>
          );
        })}
      </List>
      <TextField
        className={classes.input}
        name="comment"
        // required
        disabled={isTaskEnded(task)}
        fullWidth
        onChange={handleFormData}
        variant="outlined"
        label={t('tasks.solutions.enterGenericCommentLabel')}
        placeholder={t('tasks.solutions.enterGenericCommentPlaceHolder')}
        InputLabelProps={{
          shrink: true,
          classes: { root: 'formLabel' },
        }}
        value={(genericCommentSolution?.[0] as CommentSolution | undefined)?.data.comment ?? ''}
      />
    </>
  );
};

export default Summary;
