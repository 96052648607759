import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { formatLoanAmount, formatLoanNumber, getCustomerNames } from '../../utils/global';
import { Box, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Case } from '../../__generated__/api-di';
import Logo from '../Logo';
import formatDate from 'utils/formatDate';

const useStyles = makeStyles((theme) => ({
  header: {
    height: 72,
    padding: theme.spacing(2, 4),
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    boxShadow: '0 6px 12px 0 rgba(22,20,47,0.12)',
    position: 'relative',
    zIndex: 1300,
  },
  logo: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(1),
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
  },
  spacer: {
    flexGrow: 1,
  },
  infoList: {
    display: 'flex',
    flexDirection: 'row',
  },
  infoItem: {
    margin: theme.spacing(1, 1),
  },
  infoItemLabel: {
    fontWeight: 500,
    fontSize: '12px',
  },
  infoItemText: {
    fontSize: '16px',
    margin: '4px 0',
  },
}));

interface HeaderProps {
  reset: () => void;
  application: Case;
}

const Header: React.FC<HeaderProps> = ({ reset, application }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const loanAmount = formatLoanAmount(application.application!.loanAmount!);
  const loanNumber = formatLoanNumber(application.application!.loanNumberExternal!);
  const documentsUploaded = t('applications.detail.withProofItems', {
    received: application.proofItem?.received,
    total: application.proofItem?.amount,
  });
  const creationDate = formatDate(application.application!.creationDate!);
  const applicationDeadlineDate = application.application!.passingDate!.planned
    ? formatDate(application.application!.passingDate!.planned!)
    : formatDate(application.application!.passingDate!.utmost!);
  const applicationType = t(`applications.type.${application.application?.type}`);

  return (
    <div className={classes.header}>
      <Logo label={application?.lender?.label} size="M" className={classes.logo} />
      <Box>
        <Typography className={classes.title}>{getCustomerNames(application)}</Typography>
        <Typography className={classes.subTitle}>{application?.lender?.name}</Typography>
      </Box>
      <div className={classes.infoList}>
        <div className={classes.infoItem}>
          <Typography className={classes.infoItemLabel} color="textSecondary">
            {t('applications.detail.loanAmount')}
          </Typography>
          <Typography className={classes.infoItemText}>{loanAmount}</Typography>
        </div>
        <div className={classes.infoItem}>
          <Typography className={classes.infoItemLabel} color="textSecondary">
            {t('applications.detail.loanNumber')}
          </Typography>
          <Typography className={classes.infoItemText}>{loanNumber}</Typography>
        </div>
        <div className={classes.infoItem}>
          <Typography className={classes.infoItemLabel} color="textSecondary">
            {t('applications.detail.documentsUploaded')}
          </Typography>
          <Typography className={classes.infoItemText}>{documentsUploaded}</Typography>
        </div>
        <div className={classes.infoItem}>
          <Typography className={classes.infoItemLabel} color="textSecondary">
            {t('applications.detail.creationDate')}
          </Typography>
          <Typography className={classes.infoItemText}>{creationDate}</Typography>
        </div>
        <div className={classes.infoItem}>
          <Typography className={classes.infoItemLabel} color="textSecondary">
            {t('applications.detail.applicationDeadline')}
          </Typography>
          <Typography className={classes.infoItemText}>{applicationDeadlineDate}</Typography>
        </div>
        <div className={classes.infoItem}>
          <Typography className={classes.infoItemLabel} color="textSecondary">
            {t('applications.detail.applicationType')}
          </Typography>
          <Typography className={classes.infoItemText}>{applicationType}</Typography>
        </div>
      </div>
      <span className={classes.spacer} />
      <IconButton onClick={reset} size="large">
        <Close />
      </IconButton>
    </div>
  );
};

export default Header;
