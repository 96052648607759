import { FC } from 'react';
import { useSelector } from 'react-redux';
import Menu from 'components/Menu';
import { RootState } from 'store';
import { applicantName } from '../../../../utils';
import { steps } from '../../constants';
import MenuItem from './components/MenuItem';
import { hasPolicy } from '../../utils';

export const FormStepper: FC = () => {
  const newApplicant = useSelector((state: RootState) => state.ohaForm.newApplicant);
  const currentApplicant = useSelector((state: RootState) => state.ohaForm.currentApplicant);
  const loan = useSelector((state: RootState) => state.ohaForm.loan);

  const hasNewApplicantState = newApplicant.personalInfo.hasNewApplicant;

  const newApplicantName = applicantName(newApplicant);
  const currentApplicantName = applicantName(currentApplicant);

  return (
    <Menu>
      <MenuItem
        step={steps.currentApplicant.personalInfo}
        mainStep={steps.currentApplicant.default}
        label="currentApplicant"
        text={currentApplicantName}
      >
        <MenuItem step={steps.currentApplicant.personalInfo} label="PersonalInfo" />
        <MenuItem step={steps.currentApplicant.contactDetails} label="ContactDetails" />
        <MenuItem step={steps.currentApplicant.income} label="Income" />
        <MenuItem step={steps.currentApplicant.obligations} label="Obligations" />
      </MenuItem>

      <MenuItem
        step={steps.newApplicant.personalInfo}
        mainStep={steps.newApplicant.default}
        label="newApplicant"
        text={newApplicantName}
      >
        <MenuItem step={steps.newApplicant.personalInfo} label="PersonalInfo" />
        <MenuItem
          step={steps.newApplicant.contactDetails}
          label="ContactDetails"
          visible={hasNewApplicantState === 'yes'}
        />
        <MenuItem
          step={steps.newApplicant.income}
          label="Income"
          visible={hasNewApplicantState === 'yes'}
        />
        <MenuItem
          step={steps.newApplicant.obligations}
          label="Obligations"
          visible={hasNewApplicantState === 'yes'}
        />
      </MenuItem>

      <MenuItem step={steps.collateral} label="collateral" />

      <MenuItem step={steps.financingCostAndResources} mainStep={steps.financing} label="financing">
        <MenuItem step={steps.financingCostAndResources} label="financingCostAndResources" />
        <MenuItem step={steps.financingLoan} label="financingLoan" />
        {hasPolicy(loan) && (
          <MenuItem step={steps.financingConnectedPolicy} label="financingConnectedPolicy" />
        )}
      </MenuItem>

      <MenuItem step={steps.additionalInfo} label="additionalInfo" />

      <MenuItem step={steps.summary} label="summary" />
    </Menu>
  );
};

export default FormStepper;
