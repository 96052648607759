import type { FC } from 'react';
import { Hidden } from '@mui/material';
import { formatPartText } from '../utils';
import { CustomNumberFormat } from 'components/CustomNumberFormat';
import PercentageDisplay from 'lib/PercentageDisplay';
import { TypeOfPart } from 'screens/Mutations/utils/types';
import {
  AmountColumn,
  LoanPartText,
  TextColumn,
  BorderTextColumn,
  RentColumn,
  DateColumn,
  ValuesContainer,
} from '../Loan.styled';
import formatDate from 'utils/formatDate';

type CurrentLoanPartHeaderValueProps = {
  typeOfPart: TypeOfPart;
  loanPartIndex: number;
  loanPartNumber?: number;
  amount?: number;
  box1?: number;
  box3?: number;
  repaymentType?: string;
  fixedRatePeriod?: number;
  interestPercentage?: number;
  endDateInterestDeduction?: any;
};

const LoanPartHeaderValues: FC<CurrentLoanPartHeaderValueProps> = ({
  typeOfPart,
  loanPartIndex,
  loanPartNumber,
  amount,
  box1,
  box3,
  repaymentType,
  fixedRatePeriod,
  interestPercentage,
  endDateInterestDeduction,
}) => {
  const loanPartText = formatPartText(typeOfPart, loanPartIndex, loanPartNumber);

  return (
    <ValuesContainer container justifyContent="space-between">
      {typeOfPart !== 'newLoanParts' && typeOfPart !== 'currentLoanParts' ? (
        <BorderTextColumn container>
          <LoanPartText>{loanPartText}</LoanPartText>
        </BorderTextColumn>
      ) : (
        <TextColumn container>
          <LoanPartText>{loanPartText}</LoanPartText>
        </TextColumn>
      )}
      <AmountColumn>
        <CustomNumberFormat value={amount} fixedDecimalScale />
      </AmountColumn>
      <Hidden xlDown>
        <AmountColumn>
          <CustomNumberFormat value={box1} fixedDecimalScale />
        </AmountColumn>
        <AmountColumn>
          <CustomNumberFormat value={box3} fixedDecimalScale />
        </AmountColumn>
      </Hidden>
      <TextColumn title={repaymentType}>{repaymentType}</TextColumn>
      <RentColumn>{fixedRatePeriod}</RentColumn>
      <RentColumn>
        <PercentageDisplay value={interestPercentage} />
      </RentColumn>
      <Hidden xlDown>
        <DateColumn>
          {endDateInterestDeduction && formatDate(endDateInterestDeduction, 'dd-mm-yyyy')}
        </DateColumn>
      </Hidden>
    </ValuesContainer>
  );
};
export default LoanPartHeaderValues;
