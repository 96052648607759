import React, { FC, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import LoadingIndicator from '../../components/LoadingIndicator';
import { IsMobile } from '../../utils/global';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  preview: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    margin: `${theme.spacing(6)} auto`,

    marginLeft: 280,
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      marginBottom: 168,
    },
  },
  pages: {
    position: 'fixed',
    width: 280,
    top: 72,
    bottom: 0,
    overflow: 'auto',
    padding: '48px 0px',
    backgroundColor: theme.palette.lightest.light,
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('md')]: {
      top: 'unset',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: 120,
      flexDirection: 'row',
      padding: theme.spacing(2, 4),
    },
  },
  page: {
    textAlign: 'center',
    marginBottom: 40,
    [theme.breakpoints.down('md')]: {
      width: 120,
      marginBottom: 0,
      margin: theme.spacing(0, 2),
    },
  },
  thumbnail: {
    height: 140,
    boxShadow: theme.shadows[6],

    [theme.breakpoints.down('md')]: {
      height: 60,
    },
  },
  documentPage: {
    boxShadow: theme.shadows[6],
  },
  purple: {
    display: 'inline-block',
    border: `${theme.palette.primary.main} solid 2px`,
  },
  invisibleBorder: {
    display: 'inline-block',
    border: `${theme.palette.background.default} solid 2px`,
  },
  pageNumber: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
  activePageNumber: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

type Props = {
  file: File | string | undefined;
  scale: number;
  hasFooter?: boolean;
};

const PdfViewer: FC<Props> = ({ file, scale, hasFooter }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const previewRef = React.useRef<any>();
  const isMobile = IsMobile();

  const onLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const navigateToPage = (index: number) => {
    setPageNumber(index + 1);
  };

  return (
    <Document
      className={classes.root}
      file={file}
      onLoadSuccess={onLoadSuccess}
      loading={<LoadingIndicator />}
    >
      <div ref={previewRef} className={classes.preview}>
        <Page
          className={classes.documentPage}
          pageNumber={pageNumber}
          scale={scale}
          height={isMobile ? previewRef.current?.getBoundingClientRect().height : undefined}
        />
      </div>

      <div className={classes.pages}>
        {Array.from(new Array(numPages), (el, index) => (
          <div className={classes.page} key={`page_div_${index + 1}`}>
            <div
              className={pageNumber === index + 1 ? classes.purple : classes.invisibleBorder}
              onClick={() => navigateToPage(index)}
            >
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                className={classes.thumbnail}
                height={isMobile ? 60 : 140}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </div>
            <p
              className={`${classes.pageNumber} ${
                pageNumber === index + 1 ? classes.activePageNumber : ''
              }`}
              key={`pageNumber_${index + 1}`}
            >
              {`${t('fileViewer.page')} ${index + 1}`}
            </p>
          </div>
        ))}
      </div>
    </Document>
  );
};

export default PdfViewer;
