import type { FC } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import FormTitleText from 'components/Form/FormTitle';
import { FormInputCurrency, FormInputNumber, FormToggleButtonGroup } from 'components/Form';
import { yesNoGroupOptions } from 'screens/Mutations/components/ConversionMutation/constants';
import LoanPartList from '../../../../../../templates/Loan/LoanPartList';
import { FormGrid } from 'screens/Mutations/components/DismissalJointLiabilityMutation/DismissalJointLiabilityMutation.styled';
import { FieldsGrid } from 'components/Form/Forms.styled';
import { SubTitle } from 'screens/Mutations/molecules/styled';

type LoanProps = {
  title: string;
  subtitle: string;
};

export const Loan: FC<LoanProps> = ({ title, subtitle }) => {
  const { t } = useTranslation();
  const changeCurrentMortgage = useWatch({ name: 'changeCurrentMortgage' });
  return (
    <>
      <FormTitleText title={t(title)} description={t(subtitle)} />
      <SubTitle variant="h5">{t('directMutations.loan.formTitle')}</SubTitle>
      <FormGrid container>
        <FieldsGrid container>
          <Grid item xs={12}>
            <FormInputNumber
              name="loanNumber"
              label={t('directMutations.loan.loanNumber')}
              disabled
              required
              thousandSeparator
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputCurrency
              name="amount"
              label={t('directMutations.loan.amount')}
              placeholder={t('directMutations.amountPlaceholder')}
              required
              shouldUnregister
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputCurrency
              name="amountRaise"
              label={t('directMutations.loan.amountRaise')}
              placeholder={t('directMutations.amountPlaceholder')}
              required
              shouldUnregister
              min={0}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputCurrency
              name="extraPayOffAmount"
              label={t('directMutations.loan.extraPayOffAmount')}
              placeholder={t('directMutations.amountPlaceholder')}
              required
              min={0}
              shouldUnregister
            />
          </Grid>
          <Grid item xs={12}>
            <FormToggleButtonGroup
              name="changeLifeInsurancePolicy"
              options={yesNoGroupOptions}
              label={t('directMutations.loan.changeLifeInsurancePolicy')}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormToggleButtonGroup
              name="changeCurrentMortgage"
              options={yesNoGroupOptions}
              label={t('directMutations.loan.changeCurrentMortgage')}
              required
            />
          </Grid>
        </FieldsGrid>
      </FormGrid>
      {changeCurrentMortgage === 'yes' && (
        <>
          <LoanPartList fieldSet="currentLoanParts" />
          <LoanPartList fieldSet="newLoanParts" />
        </>
      )}
    </>
  );
};

export default Loan;
