import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type FilterProps = {
  moneylenderName: string[];
  eConsumerLink: string;
  loanType: string;
  status: string;
};
type StateProps = {
  filters: FilterProps;
  pageNumber: number;
};
const initialState: StateProps = {
  filters: {
    moneylenderName: ['all'],
    eConsumerLink: 'all' as string,
    loanType: 'all' as string,
    status: 'all' as string,
  },
  pageNumber: 0,
};
const rootSlice = createSlice({
  name: 'genericClientFilters',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<FilterProps>) => {
      state.filters = action.payload;
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    setResetClientFilters: (state) => {
      state.filters = {
        moneylenderName: ['all'],
        eConsumerLink: 'all' as string,
        loanType: 'all' as string,
        status: 'all' as string,
      };
      state.pageNumber = 0;
    },
  },
});

export default rootSlice.reducer;

export const { setFilters, setPageNumber, setResetClientFilters } = rootSlice.actions;
