import styled from '@mui/styles/styled';
import {
  ListItem,
  Icon as MuiIcon,
  ListItemIcon,
  Grid,
  Typography,
  Drawer as MuiDrawer,
} from '@mui/material';

import { NavLink as RouterNav } from 'react-router-dom';

const drawerWidth = 200;

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
  'width': '75%',
  'zIndex': 1275,
  [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
    paddingTop: theme.spacing(4),
  },
  '& .MuiDrawer-paper': {
    'width': '75%',
    'backgroundColor': theme.palette.common.white,
    'border': 'none',
    'borderRadius': 0,
    'boxShadow': theme.shadows[6],
    'padding': theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      paddingTop: theme.spacing(4),
    },
    'overflow': 'auto',
    '&::-webkit-scrollbar': {
      width: 18,
      height: 18,
    },
    '&::-webkit-scrollbar-corner': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      border: '6px solid transparent',
      background: 'rgba(0,0,0,.2)',
      borderRadius: '10px',
      backgroundClip: 'padding-box',
    },
  },
}));

export const MoneyLenderName = styled(Typography)({
  fontSize: '12px',
  fontWeight: 'bold',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
});

export const StyledCount = styled('div')<any, any>(({ theme }) => ({
  minWidth: 16,
  height: 16,
  borderRadius: 16,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 500,
  fontSize: 9,
  position: 'absolute',
  right: 12,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const LogoWrapper = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(-2, 0, -0.25),
  display: 'flex',
  justifyContent: 'center',
}));

export const Icon = styled(MuiIcon)({
  fontSize: '18px',
});

export const NavLink = styled(RouterNav)(({ theme }) => ({
  'textDecoration': 'none',
  'color': theme.palette.text.secondary,
  '&.active': {
    color: theme.palette.menuItem.main,
    backgroundColor: theme.palette.menuItem.light,
  },
}));

export const ItemContainer = styled(ListItem)(({ theme }) => ({
  'fontSize': '12px',
  'textTransform': 'none',
  'borderRadius': theme.shape.borderRadius,
  'fontWeight': 'bold',
  'alignItems': 'center',
  'cursor': 'pointer',
  'color': 'inherit',
  'padding': theme.spacing(1, 2),
  'margin': theme.spacing(1, 0),
  '&:hover': {
    backgroundColor: theme.palette.menuItem.light,
    color: theme.palette.menuItem.main,
  },
}));

export const MenuItemWapper = styled(Grid)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(1),
}));

export const IconContainer = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 'auto',
  marginRight: theme.spacing(1),
  fontSize: '18px',
}));

export const DownloadNotificationNumber = styled('div')<any, any>(({ theme }) => {
  return {
    minWidth: '16px',
    padding: '0 5px',
    height: 16,
    borderRadius: 8,
    marginLeft: theme.spacing(1),
    fontSize: 8,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
});
