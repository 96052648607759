import { CurrentLoanPartReadDto, LoanPartInfo, LoanPartReadDto } from '__generated__/api';
import { ConvertLoan, LoanPartType, SplitLoan } from '../types';
import mapBooleanToToggleType from './mapBooleanToToggleType';

export const mapConvertedLoan = (newLoanParts: LoanPartReadDto[]): ConvertLoan => ({
  fixedRatePeriod: newLoanParts[0].fixedInterestPeriod ?? undefined,
  NHGGuarantee: mapBooleanToToggleType(newLoanParts[0].hasNhg),
  repaymentType: newLoanParts[0].redemptionTypeDisplayName ?? undefined,
});

export const mapSplitLoan = (newLoanParts: LoanPartReadDto[]): SplitLoan[] =>
  newLoanParts.map((newLoanPart) => ({
    amount: newLoanPart.amount ?? 0,
    fixedRatePeriod: newLoanPart.fixedInterestPeriod ?? undefined,
    repaymentType: newLoanPart.redemptionTypeDisplayName ?? undefined,
    NHGGuarantee: mapBooleanToToggleType(newLoanPart.hasNhg),
  }));

export const mapHasConnectedPolicy = (
  loanPartsExtraInfo: LoanPartInfo[],
  loanPartNumber: number | null | undefined
) => {
  const isConnected = loanPartsExtraInfo.find((infoPart) => {
    return infoPart.loanPartNumber === loanPartNumber;
  });
  return mapBooleanToToggleType(isConnected ? isConnected.hasConnectedPolicy : undefined);
};

export const mapLoanParts = (
  currentLoanParts: CurrentLoanPartReadDto[],
  newLoanParts: LoanPartReadDto[],
  loanPartsExtraInfo: LoanPartInfo[]
): LoanPartType[] =>
  currentLoanParts.map((currentLoanPart) => {
    const connectedParts = newLoanParts.filter(
      (newPart) => newPart.originalLoanPart === currentLoanPart.loanPartNumber
    );
    const isSplitLoan = connectedParts.length > 1;
    const isConvertLoan = connectedParts.length > 0 && connectedParts.length < 2;

    return {
      loanPartNumber: currentLoanPart.loanPartNumber ?? undefined,
      repaymentType: currentLoanPart.redemptionTypeDisplayName ?? undefined,
      shouldSplitLoan: mapBooleanToToggleType(currentLoanPart.shouldLoanPartSplit),
      NHGGuarantee: mapBooleanToToggleType(currentLoanPart.hasNhg),
      loanAmount: currentLoanPart.principalAmount ?? undefined,
      hasConnectedPolicy: mapHasConnectedPolicy(loanPartsExtraInfo, currentLoanPart.loanPartNumber),
      convertLoan: isConvertLoan ? mapConvertedLoan(connectedParts) : undefined,
      splitLoan: isSplitLoan ? mapSplitLoan(connectedParts) : undefined,
      numberOfSplitLoanParts: connectedParts.length > 1 ? connectedParts.length : 0,
    };
  });
