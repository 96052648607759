import { Document } from '../../types/Document';
import { uploadLoanDocument } from 'api/loanDocuments';
import mapUploadError from 'utils/upload/mapUploadError';

export const axiosUpload = async (
  { commercialLabel, loanNumber, applicationIndexNumber, code, file, sequenceNumber }: Document,
  updatePercentagProgress: (percentage: number) => void,
  onSuccess: (result: any) => void,
  onError: (result: any) => void,
  group: string | null
) => {
  const config = {
    onUploadProgress: (progressEvent: any) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      updatePercentagProgress(percentCompleted);
    },
  };

  const json = JSON.stringify({
    commercialLabel,
    loanNumber,
    applicationIndexNumber,
    code,
    sequenceNumber,
  });

  const data = new FormData();
  data.append('json', json);
  data.append('file', file);

  try {
    return onSuccess(await uploadLoanDocument(data, group, config));
  } catch (err: any) {
    return onError(mapUploadError(err));
  }
};
