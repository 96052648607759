import React from 'react';
import { Dialog, Icon, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ReactPlayer from 'react-player/lazy';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { IsMobile } from 'utils/global';

const useStyles = makeStyles((theme) => ({
  playBtn: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playIcon: {
    width: '60%',
    height: '60%',
  },
  box: {
    position: 'relative',
  },
  dialog: {
    alignSelf: 'flex-start',
    marginTop: 120,
    [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
      marginTop: theme.spacing(1),
    },
    overflow: 'hidden', // Needed for IE11
  },
  closeBtn: {
    width: 28,
    height: 28,
    opacity: 0.6,
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, 0.32)',
    zIndex: 100,
  },
  closeIcon: {
    fontSize: '14px',
    color: theme.palette.common.white,
    position: 'absolute', // ie11 fix to align icon
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  reactPlayer: {
    borderRadius: '8px',
  },
}));

type VideoProps = {
  src: string | null;
  className?: any;
};

const Video = ({ src, className }: VideoProps) => {
  const classes = useStyles();
  const [playVideo, setPlayVideo] = React.useState(false);

  const isMobileSize = IsMobile() ? '100%' : undefined;

  return src === null ? null : (
    <>
      <div
        className={`${className} ${classes.playBtn}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setPlayVideo(true);
          return false;
        }}
      >
        <PlayArrowIcon className={classes.playIcon} color="primary" />
      </div>
      {playVideo && src !== null && (
        <Dialog open={playVideo} maxWidth="lg" classes={{ paper: classes.dialog }}>
          <IconButton onClick={() => setPlayVideo(false)} className={classes.closeBtn} size="large">
            <Icon className={classes.closeIcon}>close</Icon>
          </IconButton>
          <ReactPlayer
            playing
            controls
            url={src}
            className={classes.reactPlayer}
            width={isMobileSize}
            height={isMobileSize}
          />
        </Dialog>
      )}
    </>
  );
};

export default Video;
