import { CalendarPickerView } from '@mui/x-date-pickers';

export const maxFileSizeMb = 60;
export const maxFileSize = maxFileSizeMb * 1024 * 1024;

export type ValuationTypes = 'valuationReport' | 'WOZ-order' | 'hybridValuation';
export type FormFieldObjectProps = {
  [key in ValuationTypes]: {
    inputDate: {
      name: string;
      label: string;
      placeholder?: string;
      views?: CalendarPickerView[];
      fixDate?: string;
      maxDate?: Date;
    };
    inputNumber: {
      name: string;
      label: string;
    };
  };
};

export const valuationTypeDropdownOptions = [
  { label: 'directMutations.form.valuationReport', value: 'valuationReport' },
  { label: 'directMutations.form.WOZ-order', value: 'WOZ-order' },
  { label: 'directMutations.form.hybridValuation', value: 'hybridValuation' },
];
export const formFields: FormFieldObjectProps = {
  'WOZ-order': {
    inputDate: {
      name: 'collateralValueQuestionnaire.date',
      label: 'directMutations.form.WOZ-date',
      placeholder: '01-01-JJJJ',
      views: ['year'],
      fixDate: '*-01-01',
    },
    inputNumber: {
      name: 'collateralValueQuestionnaire.value',
      label: 'directMutations.form.WOZ-takeAwayValue',
    },
  },
  'valuationReport': {
    inputDate: {
      name: 'collateralValueQuestionnaire.date',
      label: 'directMutations.form.taxationDate',
    },
    inputNumber: {
      name: 'collateralValueQuestionnaire.value',
      label: 'directMutations.form.marketValue',
    },
  },
  'hybridValuation': {
    inputDate: {
      name: 'collateralValueQuestionnaire.date',
      label: 'directMutations.form.hybridValuationDate',
      maxDate: new Date(),
    },
    inputNumber: {
      name: 'collateralValueQuestionnaire.value',
      label: 'directMutations.form.marketValue',
    },
  },
};

export const uploadAreaTitle = 'directMutations.collateralValueStepper.firstStep.uploadAreaTitle';
export const downloadAreaDescription =
  'directMutations.collateralValueStepper.firstStep.uploadAreaSubTitle';
export const uploadOptionsArray = [
  {
    type: 'pdf',
    subtitleText: 'directMutations.collateralValueStepper.firstStep.fileSubtitleText',
    label: 'directMutations.collateralValueStepper.firstStep.fileLabel',
    acceptanceFileDetails: {
      fileType: 'application/pdf',
      maxFileSizeMb,
    },
  },
];
