import { FC, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { steps } from './constants';
import { RootBox, Spinner } from './DismissalJointLiabilityMutation.styled';
import { useTranslation } from 'react-i18next';
import { useMoneylenders } from 'use/clients';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetForm as resetOHAForm,
  saveCommercialLabel,
  saveLoanNumber,
  saveMoneyLender,
  saveProductsData,
  setOHAForm,
  savePrefill,
} from 'store/ohaFormReducer';
import PersonalInfoForm from './components/PersonalInfoForm';
import ContactDetailsForm from './components/ContactDetailsForm';
import IncomeForm from './components/IncomeForm';
import ObligationForm from './components/ObligationForm';
import CollateralForm from '../CollateralForm';
import CostAndResourcesForm from './components/CostAndResourcesForm';
import LoanForm from './components/LoanForm';
import ConnectedPoliciesForm from './components/ConnectedPolicies';
import AdditionalInformationForm from './components/ExtraInformationForm';
import SummaryForm from './components/SummaryForm';
import PrefillOptions from './components/PrefillOptions';
import { UrlParams } from 'screens/Mutations/utils/types';
import { RootState } from 'store';
import { useOhaMutation, useGetTechnicalProducts } from 'screens/Mutations/hooks/useMutations';
import { mapProductsToOptions } from 'screens/Mutations/utils/mappers/mapProductsToOptions';
import mapOhaApiToForm from 'screens/Mutations/utils/mappers/oha/mapApiToForm';
import { BaseDialog } from 'components/CustomDialog';
import mapClientData from 'screens/Mutations/utils/mappers/oha/mapClientDataToForm';
import { getIncompleteStep } from './components/FormStepper/utils/stepValidator';
import { goToStep } from 'utils/goToStep';
import { useClientData } from 'use/clientData';
import { useClients } from 'screens/Clients/hooks/useClients';
import { ClientLoan } from 'types/Client';
import { useGoogleTagManager } from 'use/gtm';
import { ClientDetailsPerson } from 'types/ClientDetailsModel';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

const DismissalJointLiabilityMutation: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const googleTagManager = useGoogleTagManager();
  const [snackbar, setSnackbar] = useState<{ status: string; name: string } | undefined>(undefined);
  const [prefillPopup, setPrefillPopup] = useState(false);
  const applicationId = useSelector(
    (state: RootState) => state.ohaForm.id,
    (next, prev) => prev !== undefined && prev >= 0
  );
  const { step } = useParams();
  const { loanNumber, commercialLabel } = useParams<keyof UrlParams>() as UrlParams;
  const [prefillDataStatus, setPrefillDataStatus] = useState<string | undefined>('isLoading');
  const { data: clientData } = useClientData(commercialLabel, +loanNumber);
  const { filteredClients } = useClients(loanNumber);
  const { data: moneyLenders, isLoading: loadingMoneyLenders } = useMoneylenders();
  const { data: apiData, isLoading: loadingGetApplication } = useOhaMutation(applicationId);
  const { data: productsData, isLoading: loadingProducts } = useGetTechnicalProducts(
    commercialLabel,
    parseInt(loanNumber, 10)
  );

  useEffect(() => {
    if (applicationId && applicationId !== 0) {
      googleTagManager({
        funnel: 'Aanvraag',
        event: 'ContinueSavedForm',
      });
    } else {
      googleTagManager({
        funnel: 'Aanvraag',
        step: '1',
        event: 'pageview',
      });
    }
  }, [googleTagManager, applicationId]);

  useEffect(() => {
    if (filteredClients[0]) {
      const loanDetails: ClientLoan | undefined = filteredClients[0].loans.find(
        ({ loanNumber: loanId }) => +loanId === +loanNumber
      );
      setPrefillDataStatus(loanDetails ? loanDetails.consentStatus : 'isLoading');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredClients]);

  useEffect(() => {
    dispatch(saveLoanNumber(parseInt(loanNumber, 10)));
  }, [loanNumber, dispatch]);

  useEffect(() => {
    dispatch(saveCommercialLabel(commercialLabel));
  }, [commercialLabel, dispatch]);

  useEffect(() => {
    dispatch(saveProductsData(mapProductsToOptions(productsData?.data)));
  }, [productsData, dispatch]);

  useEffect(() => {
    if (moneyLenders) {
      const moneyLender = moneyLenders.find((lender) => lender.commercialLabel === commercialLabel);
      if (moneyLender) {
        dispatch(saveMoneyLender(moneyLender.moneylenderName));
      }
    }
  }, [moneyLenders, commercialLabel, dispatch]);

  useEffect(() => {
    if (applicationId === undefined || loanNumber === undefined) {
      if (location.state) {
        navigate(location.state.from);
      } else {
        navigate('/clients');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId, loanNumber, navigate]);

  useEffect(() => {
    if (applicationId && apiData) {
      const mapped = mapOhaApiToForm(apiData);
      dispatch(setOHAForm(mapped));
      goToStep(getIncompleteStep(mapped), navigate, location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId, apiData]);

  useEffect(() => {
    // clear redux state unmount
    return () => {
      dispatch(resetOHAForm());
    };
  }, [dispatch]);

  useEffect(() => {
    if (clientData && Object.keys(clientData).length !== 0 && applicationId === 0) {
      setPrefillPopup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData]);

  const handleClosePopup = () => {
    setPrefillPopup(false);
  };

  const handleSelectPerson = (person?: ClientDetailsPerson) => {
    if (person?.type !== 'noPerson') {
      dispatch(savePrefill(mapClientData(clientData?.loanParts, person, clientData?.loan)));
    }
    setPrefillPopup(false);
  };

  const isLoading =
    loadingProducts ||
    loadingGetApplication ||
    loadingMoneyLenders ||
    prefillDataStatus === 'isLoading';

  return (
    <RootBox>
      {!isLoading ? (
        <>
          {step === steps.currentApplicant.personalInfo && (
            <PersonalInfoForm
              title="directMutations.currentApplicant"
              subtitle="directMutations.personalInfo.subtitle"
              rootFormName="currentApplicant"
            />
          )}
          {step === steps.currentApplicant.contactDetails && (
            <ContactDetailsForm
              title="directMutations.currentApplicant"
              subtitle="directMutations.contactDetails.subtitle"
              rootFormName="currentApplicant"
            />
          )}
          {step === steps.currentApplicant.income && (
            <IncomeForm
              title="directMutations.currentApplicant"
              subtitle="directMutations.income.subtitle"
              rootFormName="currentApplicant"
            />
          )}
          {step === steps.currentApplicant.obligations && (
            <ObligationForm
              title="directMutations.currentApplicant"
              subtitle="directMutations.obligation.subtitle"
              rootFormName="currentApplicant"
            />
          )}
          {step === steps.newApplicant.personalInfo && (
            <PersonalInfoForm
              title="directMutations.newApplicant"
              subtitle="directMutations.personalInfo.subtitle"
              rootFormName="newApplicant"
            />
          )}
          {step === steps.newApplicant.contactDetails && (
            <ContactDetailsForm
              title="directMutations.newApplicant"
              subtitle="directMutations.contactDetails.subtitle"
              rootFormName="newApplicant"
            />
          )}
          {step === steps.newApplicant.income && (
            <IncomeForm
              title="directMutations.newApplicant"
              subtitle="directMutations.income.subtitle"
              rootFormName="newApplicant"
            />
          )}

          {step === steps.newApplicant.obligations && (
            <ObligationForm
              title="directMutations.newApplicant"
              subtitle="directMutations.obligation.subtitle"
              rootFormName="newApplicant"
            />
          )}
          {step === steps.collateral && (
            <CollateralForm
              title="directMutations.collateral.title"
              subtitle="directMutations.collateral.subtitle"
            />
          )}
          {step === steps.financingCostAndResources && (
            <CostAndResourcesForm
              title="directMutations.costAndResources.title"
              subtitle="directMutations.costAndResources.subtitle"
            />
          )}
          {step === steps.financingLoan && (
            <LoanForm title="directMutations.loan.title" subtitle="directMutations.loan.subtitle" />
          )}
          {step === steps.financingConnectedPolicy && <ConnectedPoliciesForm />}
          {step === steps.additionalInfo && (
            <AdditionalInformationForm
              title="directMutations.additionalInformation.title"
              subtitle="directMutations.additionalInformation.subtitle"
              secondParagraphDescription="directMutations.additionalInformation.secondParagraphDescription"
            />
          )}
          {step === steps.summary && <SummaryForm />}
        </>
      ) : (
        <Spinner>
          <CircularProgress size={50} data-testid="progress" />
        </Spinner>
      )}
      {snackbar ? (
        <CustomSnackbar
          isOpen={!!snackbar}
          handleClose={() => setSnackbar(undefined)}
          severity={snackbar.status === 'success' ? 'success' : 'error'}
          message={t(`directMutations.snackbar.${snackbar.name}.${snackbar.status}`)}
        />
      ) : null}

      <BaseDialog
        isOpen={prefillPopup}
        onClose={handleClosePopup}
        buttonGroup={<PrefillOptions onClick={handleSelectPerson} persons={clientData?.persons} />}
        bodyText={t('directMutations.prefill.bodyText')}
      />
    </RootBox>
  );
};
export default DismissalJointLiabilityMutation;
