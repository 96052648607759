import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { ClientLoan } from 'types/Client';
import { useQueryApplicationDetailsEx } from 'use/cases';

type ClientDetailInfoApplicationItemProps = {
  application: ClientLoan;
  onComplete?: () => void;
};

const ClientDetailInfoApplicationItem: FC<ClientDetailInfoApplicationItemProps> = ({
  application,
  onComplete,
}) => {
  const { commercialLabel, loanNumber, applicationIndexNumber } = application;
  const { t } = useTranslation();
  const { isLoading, data: applicationData } = useQueryApplicationDetailsEx({
    commercialLabel,
    loanNumberExternal: loanNumber,
    applicationIndexNumber,
  });
  const dataLoaded =
    !isLoading &&
    applicationData?.applicants?.length &&
    applicationData?.applicants[0].contactData?.length;

  useEffect(() => {
    if (dataLoaded && onComplete) {
      onComplete();
    }
  }, [dataLoaded, onComplete]);

  return (
    <Grid container>
      {applicationData?.applicants?.map((person, index) =>
        person?.contactData?.map((contact) => (
          <Grid item xs={12} md={6} key={`person${index}`}>
            <Grid item xs={12} my={1}>
              <Typography variant="subtitle2">{`${person.initials} ${person.name}`}</Typography>
            </Grid>
            <Grid container>
              <Grid item md={6} xs={12} my={1}>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('clients.detail.phone')}
                </Typography>
                <Typography>
                  {contact.phoneNumberFixed ?? contact.phoneNumberWork ?? t('clients.detail.empty')}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} my={1}>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('clients.detail.email')}
                </Typography>
                <Typography>{contact.emailAddress ?? t('clients.detail.empty')}</Typography>
              </Grid>
            </Grid>
            {index === 0 && (
              <Grid item md={12} xs={12} my={1}>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('clients.detail.address')}
                </Typography>
                <Typography>
                  {`${contact.street} ${contact.houseNumber}${
                    contact.houseNumberAddition ? ` ${contact.houseNumberAddition}` : ''
                  }, `}
                  {contact.zipCode} {contact.city}
                </Typography>
              </Grid>
            )}
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default ClientDetailInfoApplicationItem;
