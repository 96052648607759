import { FC } from 'react';
import { getDefaultLogo, getProfileName } from 'utils/global';
import { useQueryProfiles } from 'use/profiles';
import { Large, Medium, Small } from './Logo.styled';
import { CircularProgress } from '@mui/material/';

type Props = {
  label?: string | null;
  className?: string;
  alt?: string;
  size?: 'S' | 'M' | 'L';
  isTitle?: boolean;
};

const Logo: FC<Props> = ({ label, className, alt = 'logo', size = 'M', isTitle = true }) => {
  const Image = size === 'S' ? Small : size === 'M' ? Medium : Large;

  const { data: profiles, isLoading } = useQueryProfiles();

  let url: string | null | undefined;
  let profileName: string | null | undefined;

  if (isLoading) {
    return <CircularProgress />;
  }

  if (profiles && Array.isArray(profiles)) {
    url = profiles.find((item) => item.identifier === label)?.avatar?.url;
    profileName = getProfileName(profiles, label);
  }

  const imageUrl = url ?? getDefaultLogo();
  const customTitle = isTitle ? profileName || alt : undefined;

  return <Image src={imageUrl} className={className} title={customTitle} alt={alt} />;
};

export default Logo;
