import { Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderContainedButton } from 'screens/Clients/Clients.styled';

type RefetchButtonProps = {
  syncDateText: string;
  onRefetch: () => void;
  refetchStatus?: string;
};
export default function RefetchButton({
  syncDateText,
  onRefetch,
  refetchStatus,
}: RefetchButtonProps) {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        syncDateText ? `${t('clients.header.lastUpdatedTooltip', { date: syncDateText })}` : ''
      }
      data-testid="refetchButton-tooltip"
      disableFocusListener
      disableTouchListener
    >
      <span>
        <HeaderContainedButton
          data-testid="refetchButton-button"
          size="small"
          variant="contained"
          disableElevation
          color="primary"
          onClick={onRefetch}
          disabled={refetchStatus === 'loading'}
        >
          {refetchStatus === 'loading'
            ? t('clients.header.loading')
            : t('clients.header.refreshButton')}
        </HeaderContainedButton>
      </span>
    </Tooltip>
  );
}
