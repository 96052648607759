import { object, string, number } from 'yup';
import { errorMessages } from 'utils/form/errorMessages';

const createClientSchema = object({
  loanNumber: number().required(errorMessages.required).typeError(errorMessages.required),
  commercialRelation: string().required(errorMessages.required),
  clientName: string().required(errorMessages.required),
});

export default createClientSchema;
