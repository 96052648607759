import { Box, Typography, Button, Icon, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BTLCard } from 'screens/Applications/Applications.styled';
import { useCommercialProducts } from 'use/commercial-products';

type BuyToLetWidgetProps = {
  open: boolean;
  onClickNew?: () => void;
  handleClose: () => void;
};

export default function BuyToLetWidget({ open, onClickNew, handleClose }: BuyToLetWidgetProps) {
  const { t } = useTranslation();
  const { isBtlAllowed } = useCommercialProducts();
  const commercialRelation = localStorage.getItem('commercialRelation');

  const isNibc = commercialRelation === 'NIBCD_CR_NIBCD';

  if (!isBtlAllowed || !open || !isNibc) {
    return null;
  }

  return (
    <>
      <BTLCard elevation={3} data-testid="BTL-wrapper">
        <Box
          mb={1}
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography variant="body1">{t('home.btlDescription')}</Typography>
          <IconButton onClick={handleClose} data-testid="close-icon">
            <Icon>close</Icon>
          </IconButton>
        </Box>
        <Button
          data-testid="startApplication-button"
          size="small"
          variant="contained"
          disableElevation
          color="primary"
          fullWidth
          onClick={onClickNew}
        >
          {t('home.startApplication')}
        </Button>
      </BTLCard>
    </>
  );
}
