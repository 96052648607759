import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Consent } from '../__generated__/api-di';
import { getClientData } from '../api/clientData';
import { ClientDetailsModel } from 'types/ClientDetailsModel';

type Client = {
  loanNumber: number;
  clientConsent: Consent;
  clientData: ClientDetailsModel | {};
};

const initialState = {
  status: 'idle',
  clients: [] as Client[],
};

type FetchProps = {
  group: string | null;
  commercialLabel: string;
  loanNumber: number;
};

export const loadClientData = createAsyncThunk(
  'dataSharing/getClientData',
  async (payload: FetchProps) => {
    const response = await getClientData(
      payload.group,
      payload.commercialLabel,
      payload.loanNumber
    );
    return response;
  }
);

const rootSlice = createSlice({
  name: 'dataSharing',
  initialState,
  reducers: {
    saveClientConsent: (state, action: PayloadAction<Consent>) => {
      const inState = state.clients.find((item) => item.loanNumber === action.payload.loanNumber);
      if (!inState)
        state.clients.push({
          loanNumber: action.payload.loanNumber,
          clientConsent: action.payload,
          clientData: {},
        });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadClientData.pending, (state, action) => {
      state.status = 'isLoading';
    });
    builder.addCase(loadClientData.rejected, (state, action) => {
      state.status = 'isError';
    });
    builder.addCase(loadClientData.fulfilled, (state, action) => {
      state.status = 'idle';
      state.clients = state.clients.map((client) => {
        if (client.loanNumber === action.payload.loan?.number) {
          client.clientData = action.payload;
        } else {
          state.clients.push({
            loanNumber: action.payload.loan?.number ?? 0,
            clientConsent: {} as Consent,
            clientData: action.payload,
          });
        }
        return client;
      });
    });
  },
});

export default rootSlice.reducer;

export const { saveClientConsent } = rootSlice.actions;
