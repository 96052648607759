import {
  Hidden,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import ConceptListItem from './ConceptListItem';
import { useTranslation } from 'react-i18next';
import { ApplicationData } from '__generated__/api';

type ConceptsDesktopProps = {
  onClickItem: (item: ApplicationData) => void;
  items: ApplicationData[];
  page: number;
  rowsPerPage: number;
};

export default function ConceptsDesktop({
  onClickItem,
  items,
  page,
  rowsPerPage,
}: ConceptsDesktopProps) {
  const { t } = useTranslation();

  return (
    <Hidden smDown>
      <TableContainer data-testid="conceptList-desktop">
        <Table aria-label="applications table">
          <TableHead data-testid="table-head">
            <TableRow>
              <TableCell colSpan={2}>{t('applications.table.applicationName')}</TableCell>
              <TableCell>{t('applications.table.type')}</TableCell>
              <TableCell>{t('applications.table.loanNumber')}</TableCell>
              <TableCell>{t('applications.table.created')}</TableCell>
              <TableCell>{t('applications.table.modified')}</TableCell>
              <TableCell>{t('applications.table.status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="items">
            {(rowsPerPage > 0
              ? items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : items
            ).map((item: any, index: any) => (
              <ConceptListItem
                key={`${item.commercialLabel}-${item.id}`}
                item={item}
                onClickItem={onClickItem}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Hidden>
  );
}
