import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGoogleTagManager } from 'use/gtm';
import { TableCell, Tooltip, Fade } from '@mui/material';
import { ApplicationData } from '__generated__/api';
import StatusTag from 'components/StatusTag/StatusTag';
import { formatLoanAmount, getCustomerNamesApplicationData, formatLoanNumber } from 'utils/global';
import Logo from 'components/Logo';
import formatDate from 'utils/formatDate';
import {
  LenderName,
  InterPunt,
  useClasses,
  TableRow,
} from 'screens/Applications/Applications.styled';
import { Emphasize } from 'screens/templates/Lists/ListWrapper/Wrapper.styled';

type ConceptListItemPropType = {
  item: ApplicationData;
  onClickItem: (item: ApplicationData) => void;
};
const ConceptListItem: FC<ConceptListItemPropType> = ({ item, onClickItem }) => {
  const { t } = useTranslation();
  const classes = useClasses();
  const tagEvent = useGoogleTagManager();

  const handleRowClick = () => {
    tagEvent({
      event: 'ButtonClick',
      btnName: 'ApplicationConceptItem',
    });
    onClickItem(item);
  };

  return (
    <TableRow hover onClick={handleRowClick} data-testid="concept-application-item">
      <TableCell>
        <Logo label={item.commercialLabel} size="M" />
      </TableCell>
      <TableCell>
        {getCustomerNamesApplicationData(item).length > 24 ? (
          <Tooltip
            title={getCustomerNamesApplicationData(item)}
            placement="top-start"
            TransitionComponent={Fade}
            classes={{ tooltip: `${classes.tooltip} name` }}
            PopperProps={{
              popperOptions: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: '-8px, -40px',
                    },
                  },
                ],
              },
            }}
          >
            <Emphasize data-testid="customerName-tooltip" truncate={1}>
              {getCustomerNamesApplicationData(item)}
            </Emphasize>
          </Tooltip>
        ) : (
          <Emphasize data-testid="tooltip" truncate={1}>
            {getCustomerNamesApplicationData(item)}
          </Emphasize>
        )}
        <LenderName data-testid="lenderName-amount">
          {item.commercialName}
          <InterPunt>‧</InterPunt>
          {formatLoanAmount(item.loanAmount!)}
        </LenderName>
      </TableCell>
      <TableCell data-testid="type">{t(`type.${item.type}`)}</TableCell>
      <TableCell data-testid="loanNumber">
        {item.loanNumber ? formatLoanNumber(item.loanNumber) : ''}
      </TableCell>
      <TableCell>{formatDate(item.dateCreated!)}</TableCell>
      <TableCell>{formatDate(item.dateModified!)}</TableCell>
      <TableCell sx={{ width: 218 }}>
        <StatusTag urgency="blue" progress={0} label={t('applications.filter.concept')} />
      </TableCell>
    </TableRow>
  );
};

export default ConceptListItem;
