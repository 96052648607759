import { formatLoanNumber } from 'utils/global';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import formatDate from 'utils/formatDate';
import {
  NotificationTitleContainer,
  FieldContainer,
  NewDateBox,
  Emphasize,
  Interpunt,
} from '../Home.styled';
import type { Notification } from '__generated__/api';
import { Link } from '@mui/material';

type NotificationProps = {
  notification: Notification;
};

const NotificationTile = ({ notification }: NotificationProps) => {
  const { t } = useTranslation();

  return (
    <NotificationTitleContainer>
      <Link underline="none" to="/notifications" component={NavLink}>
        <FieldContainer>
          {formatDate(notification.dateCreated)}
          {!notification.dateAccessed && <NewDateBox />}
        </FieldContainer>
        <Emphasize>{notification.name}</Emphasize>
        <FieldContainer>
          {notification.consumerName}
          <Interpunt>‧</Interpunt>
          {notification.loanNumber !== null && notification.loanNumber !== undefined
            ? formatLoanNumber(Number.parseInt(notification.loanNumber, 10))
            : t('general.unknown')}
        </FieldContainer>
      </Link>
    </NotificationTitleContainer>
  );
};

export default NotificationTile;
