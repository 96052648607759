import { Grid, GridProps, Typography as MUITypography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Grid)<GridProps>(({ theme }) => ({
  'padding': theme.spacing(2, 2),
  '&.error': {
    'backgroundColor': theme.palette.danger.light,
    '& :last-child': {
      color: theme.palette.error.main,
    },
  },
}));

export const StyledGrid = styled(Grid)<GridProps>(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

export const Typography = styled(MUITypography)(({ theme }) => ({
  fontSize: '13px',
  color: theme.palette.grey[900],
}));
