import { useMutation, useQuery } from 'react-query';

import { HEADER_SELECTED_GROUP_CLAIM, http } from '../http';

import {
  TaskSolutionIssue,
  CompleteTaskBody,
  TaskDetails,
  TaskInfo,
} from '../__generated__/api-di';
import { useGroup } from './group';
import { queryClient } from '../config/react-query';
import env from '../config/env';

interface Id {
  commercialLabel: string;
  loanNumber: number;
  index: number;
}

interface DetailId extends Id {
  taskId: string;
}

async function getTasks(id: Id, group: string): Promise<TaskInfo[]> {
  if (!env.FEATURES.TASKS) {
    return [];
  }

  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const { loanNumber, index, commercialLabel } = id ?? {};
  const response = await http.get<TaskInfo[]>(
    `/api/v1/lenders/${commercialLabel}/cases/${loanNumber}-${index}/tasks`,
    {
      headers: headers,
    }
  );
  return response.data;
}

async function completeTask(
  id: DetailId,
  group: string,
  completeTaskBody?: CompleteTaskBody
): Promise<any> {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const { loanNumber, index, commercialLabel, taskId } = id ?? {};
  await http.post(
    `/api/v1/lenders/${commercialLabel}/cases/${loanNumber}-${index}/tasks/${taskId}/complete`,
    JSON.stringify(completeTaskBody),
    {
      headers: headers,
    }
  );
}

const tasks = 'tasks';
export function useQueryTasks(id: Id) {
  const group = useGroup();
  return useQuery([tasks, id], () => getTasks(id, group!));
}

async function getTaskDetails(id: DetailId, group: string | null): Promise<TaskDetails> {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const { loanNumber, index, taskId, commercialLabel } = id ?? {};
  const response = await http.get<TaskDetails>(
    `/api/v1/lenders/${commercialLabel}/cases/${loanNumber}-${index}/tasks/${taskId}`,
    {
      headers: headers,
    }
  );
  return response.data;
}

const taskDetails = 'taskDetails';
export function useQueryTaskDetails(id?: DetailId) {
  const group = useGroup();
  return useQuery([taskDetails, id], () => getTaskDetails(id!, group), {
    enabled: !!group && !!id,
  });
}

export function useSubmitIssues(succesCallback: () => void, errorCallback: () => void) {
  const group = useGroup();
  return useMutation(
    (variables: { id?: DetailId; issues: Array<TaskSolutionIssue>; comment?: string }) =>
      completeTask(variables.id!, group!, {
        data: {
          issues: variables.issues,
          comment: variables.comment,
        },
      }),
    {
      onSuccess: (data, variables) => {
        const { taskId, ...nonDetailId } = variables.id!;
        const queryKey = [tasks, nonDetailId];
        const queryKeyDetails = [taskDetails, variables.id];
        // Does this need a timeout?
        queryClient.invalidateQueries(queryKey);
        queryClient.invalidateQueries(queryKeyDetails);
        succesCallback();
      },
      onError: () => {
        errorCallback();
      },
    }
  );
}
