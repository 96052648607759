import i18n from 'i18n';
import { mapEnum } from 'utils/global';
import {
  CivilStatus,
  Gender,
  LegalForm,
  TypeOfBenefit,
  TypeOfCompany,
  TypeOfEmployment,
  TypeOfFinancing,
  TypeOfObligation,
  TypeOfOtherIncome,
  TypeOfRedemption,
} from '__generated__/api';
import isTypeOfBenefitForPension from './predicates/isTypeOfBenefitForPension';
import {
  EmploymentIncome,
  EntrepreneurIncome,
  Income,
  IncomeFormType,
  Option,
  OtherIncome,
  SocialSecurityIncome,
  TypeOfIncome,
} from './utils/types';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { COUNTRIES } from 'constants/index';

export const errorMessages = {
  required: 'Dit veld is verplicht',
  date: 'Datum niet herkend, gebruik het formaat DD-MM-JJJJ',
  number: 'Dit veld mag alleen cijfers bevatten',
  notNumber: 'Dit veld mag geen cijfers bevatten',
  // eslint-disable-next-line no-template-curly-in-string
  maxNumber: 'Dit veld mag niet groter zijn dan ${max}',
  noSpecialCharacters: 'Dit veld mag geen speciale tekens bevatten',
  email: 'Dit is geen geldig e-mailadres',
  postal: 'Postcode niet herkend, gebruik het formaat 1111AA',
  noNumberAndSpecialCharacter: 'Dit veld mag geen cijfers en/of speciale tekens bevatten',
  PastDate: 'De datum moet in het verleden liggen',
  FutureDate: 'De datum moet in de toekomst liggen',
  afterStartDate: 'De einddatum moet na de startdatum komen.',
  onlyLetterAndSpecialCharacters: 'Dit veld mag alleen uit letters en leestekens bestaan',
  max250Letters: 'Dit veld mag maximaal 250 tekens bevatten',
  fileTooLarge: 'Het bestand is te groot. Het mag maximaal {{maxMB}} MB groot zijn.',
  fileTypePdf: 'Het geüploade bestand is geen PDF-bestand.',
  phoneNumber: 'Dit veld mag alleen een geldig telefoonnummer bevatten.',
};

export const NO_NUMBER_REGX = /^([^0-9]*)$/;
export const NO_SPECIAL_CHAR_REGX = /^[ A-Za-z0-9 ]*$/;
export const NO_NUMBER_AND_SPECIAL_CHAR_REGX = /^[ A-Za-zÀ-ú\-\s]*$/;
export const VALID_PHONE_NUMBER = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

export const countryOptions = () =>
  COUNTRIES.map((country) => {
    return {
      label: country ? i18nIsoCountries.getName(country, 'nl') : '',
      value: country || '',
    };
  });

export const yesNoGroupOptions: Option[] = [
  { label: 'Ja', value: 'yes' },
  { label: 'Nee', value: 'no' },
];

export const genders = [
  {
    value: Gender.Male.toString(),
    label: 'Man', // TODO: From nl.json
  },
  {
    value: Gender.Female.toString(),
    label: 'Vrouw', // TODO: From nl.json
  },
];

export const maritalStatus: { label: string; value: CivilStatus }[] = [
  {
    label: 'Alleenstaand',
    value: CivilStatus.Single,
  },
  {
    label: 'Samenwonend met samenlevingscontract',
    value: CivilStatus.LivingTogetherWithContract,
  },
  {
    label: 'Samenwonend zonder samenlevingscontract',
    value: CivilStatus.LivingTogetherWithoutContract,
  },
  {
    label: 'Gehuwd in gemeenschap van goederen',
    value: CivilStatus.Married,
  },
  {
    label: 'Gehuwd in beperkte gemeenschap van goederen',
    value: CivilStatus.MarriedLimited,
  },
  {
    label: 'Gehuwd met huwelijkse voorwaarden',
    value: CivilStatus.MarriedWithSettlement,
  },
  {
    label: 'Gehuwd volgens buitenlands recht',
    value: CivilStatus.MarriedForeignLaw,
  },
  {
    label: 'Partnerregistratie in gemeenschap van goederen',
    value: CivilStatus.CivilPartnership,
  },
  {
    label: 'Partnerregistratie onder registratievoorwaarden',
    value: CivilStatus.CivilPartnershipLimited,
  },
  {
    label: 'Partnerregistratie in beperkte gemeenschap van goederen',
    value: CivilStatus.CivilPartnershipWithConditions,
  },
  {
    label: 'Mede-erfgenaam',
    value: CivilStatus.Coheir,
  },
];

export const numberDropdownValues: Option[] = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
];

export const typeOfObligation = (value: TypeOfObligation): Option => ({
  label: i18n.t(`directMutations.obligation.obligationTypes.${value}`),
  value,
});

export const typeOfObligations = (): Option[] => [
  typeOfObligation(TypeOfObligation.AlimonyPartner),
  typeOfObligation(TypeOfObligation.PersonalLoan),
  typeOfObligation(TypeOfObligation.RevolvingCredit),
  typeOfObligation(TypeOfObligation.ExistingMortgage),
  typeOfObligation(TypeOfObligation.LoanEmployer),
  typeOfObligation(TypeOfObligation.MailOrderCredit),
  typeOfObligation(TypeOfObligation.DebtRepayment),
  typeOfObligation(TypeOfObligation.Telecommunication),
  typeOfObligation(TypeOfObligation.RentalCosts),
  typeOfObligation(TypeOfObligation.StudentLoan),
  typeOfObligation(TypeOfObligation.PrivateLease),
  typeOfObligation(TypeOfObligation.AdvancedPaymentStudy),
  typeOfObligation(TypeOfObligation.ResidualDebtNhg),
  typeOfObligation(TypeOfObligation.ResidualDebtNoNhg),
  typeOfObligation(TypeOfObligation.Remaining),
];

const mapFormOption = (getLabel: (v: string) => string) => (value: string) => ({
  label: getLabel(value),
  value,
});

export const typeOfRedemption = (value: TypeOfRedemption | undefined) => {
  return value ? i18n.t(`general.enumerations.redemptionTypes.${value}`) : null;
};

export const getCompanyTypeLabel = (v: string) =>
  i18n.t(`directMutations.income.entrepreneur.companyTypes.${v}`);

export const getLegalFormLabel = (v: string) =>
  i18n.t(`directMutations.income.entrepreneur.legalFormTypes.${v}`);

export const getTypeOfFinancingLabel = (value: string | TypeOfFinancing | undefined) => {
  return value ? i18n.t(`directMutations.costAndResources.financingType.${value}`) : null;
};

export const getTypeOfCompanyOptions = () =>
  mapEnum(TypeOfCompany).map(mapFormOption(getCompanyTypeLabel));

export const getTypeOfIncomeLabel = (v: string) =>
  i18n.t(`directMutations.income.incomeTypes.${v}`);

export const getLegalFormOptions = () => mapEnum(LegalForm).map(mapFormOption(getLegalFormLabel));

export const getTypeOfEmploymentOption = (type: TypeOfEmployment) => ({
  label: i18n.t(`directMutations.income.employment.employmentTypes.${type}`),
  value: type,
});

export const getTypeOfEmploymentOptions = () => [
  getTypeOfEmploymentOption(TypeOfEmployment.Permanent),
  getTypeOfEmploymentOption(TypeOfEmployment.TemporaryWithIntent),
  getTypeOfEmploymentOption(TypeOfEmployment.TemporaryWithoutIntent),
  getTypeOfEmploymentOption(TypeOfEmployment.FlexibleWithPerspective),
  getTypeOfEmploymentOption(TypeOfEmployment.FlexibleWithoutPerspective),
  getTypeOfEmploymentOption(TypeOfEmployment.SocialInsuranceWage),
];

export const getTypeOfIncomeOptions = () =>
  mapEnum(TypeOfIncome).map(mapFormOption(getTypeOfIncomeLabel));

export const getTypeOfBenefitOption = (type: TypeOfBenefit) => ({
  label: i18n.t(`directMutations.income.socialSecurity.benefitTypes.${type}`),
  value: type,
});

export const getTypeOfBenefitOptions = () => [
  getTypeOfBenefitOption(TypeOfBenefit.Wajong),
  getTypeOfBenefitOption(TypeOfBenefit.StudyGrant),
  getTypeOfBenefitOption(TypeOfBenefit.ProvisionFullyIncapacitated),
  getTypeOfBenefitOption(TypeOfBenefit.DisablityInsuranceWithReinspection),
  getTypeOfBenefitOption(TypeOfBenefit.UnemploymentBenefits),
  getTypeOfBenefitOption(TypeOfBenefit.TideoverAllowance),
  getTypeOfBenefitOption(TypeOfBenefit.InvalidInsuranceSelfEmployed),
  getTypeOfBenefitOption(TypeOfBenefit.LawForSurvivingDependants),
  getTypeOfBenefitOption(TypeOfBenefit.ResumptionPartiallyIncapacitated),
];

export const getTypeOfPensionLabel = (v: string) =>
  i18n.t(`directMutations.income.pension.benefitTypes.${v}`);

export const getTypeOfPensionOptions = () =>
  mapEnum(TypeOfBenefit)
    .filter(isTypeOfBenefitForPension)
    .map(mapFormOption(getTypeOfPensionLabel));

export const getTypeOfOtherIncomeOption = (type: TypeOfOtherIncome) => ({
  label: i18n.t(`directMutations.income.otherIncome.otherIncomeTypes.${type}`),
  value: type,
});

export const getTypeOfOtherIncomeOptions = () => [
  getTypeOfOtherIncomeOption(TypeOfOtherIncome.Rental),
  getTypeOfOtherIncomeOption(TypeOfOtherIncome.PartnerAlimony),
  getTypeOfOtherIncomeOption(TypeOfOtherIncome.Capital),
  getTypeOfOtherIncomeOption(TypeOfOtherIncome.AnnuityInsurance),
  getTypeOfOtherIncomeOption(TypeOfOtherIncome.OwnerOccupiedHomeReserve),
  getTypeOfOtherIncomeOption(TypeOfOtherIncome.Different),
];

export const incomeDefaultValues: Income = {
  typeOfIncome: undefined,
  entrepreneur: {
    startDate: null,
  } as EntrepreneurIncome,
  socialSecurity: {
    startDate: null,
    endDate: null,
  } as SocialSecurityIncome,
  pension: {
    startDate: null,
    endDate: null,
  } as SocialSecurityIncome,
  employment: {
    startDate: null,
    endDate: null,
  } as EmploymentIncome,
  otherIncome: {
    endDate: null,
  } as OtherIncome,
};

export const incomeFormTypeDefaultValues: IncomeFormType = {
  id: undefined,
  incomes: [],
};
