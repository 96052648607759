import { t } from 'i18next';
import formatDate from 'utils/formatDate';
import { Case } from '__generated__/api-di';
import { ApplicationStatus } from 'constants/index';

export function translateApplicationEndDate(application: Case) {
  if (!application.application?.endDate) {
    return t('applications.date.none');
  }

  const formattedDate = formatDate(application.application.endDate);

  switch (application.application?.status?.value) {
    case ApplicationStatus.inProgress:
    case ApplicationStatus.offerMade:
    case ApplicationStatus.offerDocuments:
    case ApplicationStatus.waitingForReview:
    case ApplicationStatus.offerAdditionalDocuments:
    case ApplicationStatus.inFirstReview:
    case ApplicationStatus.inSecondReview:
    case ApplicationStatus.agreementMade:
    case ApplicationStatus.offerAdministrativeDocuments:
    case ApplicationStatus.inAdministrativeReview:
    case ApplicationStatus.creditAccepted:
    case ApplicationStatus.notaryDocumentsSent:
      return t('applications.date.final', { date: formattedDate });

    case ApplicationStatus.rejected:
      return t('applications.date.rejected', { date: formattedDate });

    case ApplicationStatus.terminated:
      return t('applications.date.terminated', { date: formattedDate });

    case ApplicationStatus.managingApplications:
    case ApplicationStatus.new:
    case ApplicationStatus.unknown:
    default:
      return t('applications.date.none');
  }
}
