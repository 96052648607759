import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Box, Button, Icon, IconButton, Paper, Typography, Grid } from '@mui/material';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { googleTagManager } from 'use/gtm';
import NewsCard from './NewsCard';
import { useQueryNewsItems } from 'use/newsItems';
import { parseHtml } from 'utils/cms';
import formatDate from 'utils/formatDate';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  card: {
    borderRadius: 6,
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },
  grid: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  label: {
    flexGrow: 0,
    backgroundColor: alpha(theme.palette.grey[500], 0.25),
    padding: '4px 8px',
    fontSize: '10px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderRadius: 13,
    height: 20,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
  },
  title: {
    margin: theme.spacing(1, 0),
  },
  text: {
    'display': '-webkit-box',
    'width': '100%',
    'height': 14 * 1.5 * 2,
    'margin': '0 auto',
    'fontSize': 14,
    'lineHeight': 1.5,
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
    '-ms-text-overflow': 'ellipsis',
    'color': theme.palette.textBody.main,
    '& p': {
      margin: 0,
    },
  },
  readBtn: {
    marginLeft: '-14px',
    marginTop: theme.spacing(1),
  },
  closeBtn: {
    width: 28,
    height: 28,
    opacity: 0.6,
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.32)',
  },
  closeIcon: {
    fontSize: '14px',
    color: theme.palette.common.white,
    position: 'absolute', // ie11 fix to align icon
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
  info: {
    flex: '1 1 auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  media: {
    flex: '1 1 auto',
    position: 'relative',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1.5),
    },
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      height: 164,
    },
  },
}));

type NewsItemHeroProps = {
  closable?: boolean;
  onClose?: () => void;
};

const NewsItemHero: React.FC<NewsItemHeroProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { closable = true, onClose = () => {} } = props;
  const { data: news, isLoading } = useQueryNewsItems();

  const newsItem = news?.filter((n) => n.isImportant).shift();

  return (
    <>
      {!isLoading && newsItem && (
        <div className={classes.root} id="news-item-hero">
          <Paper className={classes.card}>
            <Grid container className={classes.grid}>
              <Grid item className={classes.info} sm={12} md={9}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" color="textSecondary">
                    {formatDate(newsItem.dateCreated!)}
                  </Typography>
                  <div className={classes.label}>{newsItem.author?.name}</div>
                </Box>
                <Typography variant="h6" className={classes.title}>
                  {newsItem.title}
                </Typography>
                <Box display="block" className={classes.text}>
                  {newsItem.htmlContent && parseHtml(newsItem.htmlContent)}
                </Box>
                <Button
                  variant="text"
                  color="primary"
                  className={classes.readBtn}
                  to={`/news/${newsItem.slug}`}
                  component={NavLink}
                  onClick={() => {
                    googleTagManager({
                      event: 'ButtonClick',
                      btnName: 'NewsHeroReadFullArticle',
                      newsTitle: newsItem.title!,
                    });
                  }}
                  endIcon={<Icon className={classes.btnIcon}>chevron_right</Icon>}
                  data-testid="hero.news.readFullArticle"
                >
                  {t('news.readFullArticle')}
                </Button>
              </Grid>
              <Grid item className={classes.media} sm={12} md={3}>
                <NewsCard newsItem={newsItem} className={classes.bannerImage} />
                {closable && (
                  <IconButton
                    className={classes.closeBtn}
                    onClick={() => {
                      googleTagManager({
                        event: 'ButtonClick',
                        btnName: 'NewsHeroExit',
                        newsTitle: newsItem.title!,
                      });
                      onClose();
                    }}
                    size="large"
                  >
                    <Icon className={classes.closeIcon}>close</Icon>
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}
    </>
  );
};

export default NewsItemHero;
