import { date, mixed, number, object, string } from 'yup';
import { errorMessages } from '../../constants';
import { jsDateToLocalISO8601DateString } from 'utils/form/validations/getDateValueFromInput';
import { maxFileSize, maxFileSizeMb } from './constants';

const dateTest = date()
  .transform((value: Date) => {
    if (value) return new Date(jsDateToLocalISO8601DateString(value));
    return undefined;
  })
  .required(errorMessages.required)
  .max(new Date(), errorMessages.PastDate)
  .typeError(errorMessages.date);

export const getCollateralValuesMutationFormSchema = object().shape({
  // eslint-disable-next-line react/forbid-prop-types
  collateralValueQuestionnaire: object({
    loanNumber: number().required(errorMessages.required),
    valuationType: string().required(errorMessages.required),
    date: date().when('valuationType', {
      is: 'WOZ-order',
      then: dateTest.test('invalidDate', 'De datum moet altijd beginnen met 01-01-jjjj', (date) => {
        if (!date) return true;
        const day: number = date?.getDate();
        const month: number = date?.getMonth();
        return day === 1 && month === 0;
      }),
      otherwise: dateTest,
    }),
    value: number().required(errorMessages.required).nullable(),
    formFile: mixed()
      .required(errorMessages.required)
      .nullable()
      .test(
        'fileSize',
        errorMessages.fileTooLarge.replace('{{maxMB}}', `${maxFileSizeMb}`),
        (value) => {
          if (!value || !value.size) return true;
          return value.size <= maxFileSize;
        }
      )
      .test('fileType', errorMessages.fileTypePdf, (value) => {
        if (!value || !value.name) return true;
        return value.name.toLowerCase().endsWith('.pdf');
      }),
  }),
});
