import { FC, useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LoanFormType, Policy } from 'screens/Mutations/utils/types';
import { policyDefaultValues } from 'screens/Mutations/components/DismissalJointLiabilityMutation/constants';
import { Button, Icon } from '@mui/material';
import { AddButtonGrid } from 'screens/Mutations/components/DismissalJointLiabilityMutation/DismissalJointLiabilityMutation.styled';
import { InlineFieldsGrid } from 'components/Form/Forms.styled';

import PoliciesForm from './PoliciesForm/PoliciesForm';

type PoliciesName =
  | `currentLoanParts.${number}.policies`
  | 'policies'
  | `newLoanParts.${number}.policies`;

type PoliciesProps = {
  name: PoliciesName;
  shouldUnregister: boolean;
};

export const Policies: FC<PoliciesProps> = ({ name, shouldUnregister }) => {
  const { t } = useTranslation();
  const { control } = useFormContext<LoanFormType>();
  const { fields, append, remove } = useFieldArray({
    name,
    control,
  });
  const formPolicies = useWatch({
    control,
    name,
  });

  useEffect(() => {
    if (fields.length === 0) {
      const defaultPolicy = {} as Policy;
      append(defaultPolicy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <InlineFieldsGrid item>
        {fields.map((_, policyIndex) => {
          const rootForm = `${name}.${policyIndex}`;

          if (!formPolicies || !formPolicies[policyIndex]) return null;

          return (
            <PoliciesForm
              rootForm={rootForm}
              policyIndex={policyIndex}
              formPolicies={formPolicies}
              remove={remove}
              shouldUnregister={shouldUnregister}
            />
          );
        })}
      </InlineFieldsGrid>

      <AddButtonGrid container>
        <Button
          onClick={() => append(policyDefaultValues)}
          color="primary"
          variant="contained"
          size="medium"
        >
          {t('directMutations.buttons.addPolicyButton')}
          <Icon>plus</Icon>
        </Button>
      </AddButtonGrid>
    </>
  );
};

export default Policies;
