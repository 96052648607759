import axios from 'axios';
import { getBearerToken } from '../auth/oidc';
import { getNewTicketId } from './ticket-id';

export const HEADER_TICKET_ID = 'TicketId';
export const HEADER_CORRELATION_ID = 'X-Correlation-Id';
export const HEADER_SELECTED_GROUP_CLAIM = 'Selected-Group-Claim';
const currentDeployment = () => {
  const url = window.location.href.toLocaleLowerCase();
  let environment: string;
  if (url.includes('adviseur-')) {
    environment = url.split('adviseur-')[1].split('.')[0];
  } else {
    environment = 'default';
  }
  const capitalizedEnv = environment.charAt(0).toUpperCase() + environment.slice(1);
  return capitalizedEnv;
};

export const http = axios.create({
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'x-environment': (window as any).REACT_APP?.ENVIRONMENT,
    'x-deployment': currentDeployment(),
  },
});

http.interceptors.request.use(async (config) => {
  const token = await getBearerToken();
  config.headers.Authorization = `Bearer ${token}`;

  if (!config.baseURL && !config.url?.startsWith('//')) {
    const ticketId = getNewTicketId();
    config.headers[HEADER_TICKET_ID] = ticketId;
    config.headers[HEADER_CORRELATION_ID] = ticketId;
  }

  return config;
});
