import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getTypeOfPensionOptions } from 'screens/Mutations/constants';
import { FormInputDate, FormInputDropdown, FormInputCurrency } from 'components/Form';
import { TypeOfBenefit } from '__generated__/api';
import { Grid } from '@mui/material';

type Props = {
  index?: number;
  rootFormName?: string;
};

const Pension: FC<Props> = ({ index = 0, rootFormName }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const formIncomes = useWatch({ control, name: `${rootFormName || ''}incomes` });

  const income = formIncomes?.length ? formIncomes[index] : null;
  const typeOfBenefit = income?.pension?.typeOfBenefit;

  const endDateVisible =
    typeOfBenefit === TypeOfBenefit.PrePension || typeOfBenefit === TypeOfBenefit.Insurance;

  const prefix = `${rootFormName || ''}incomes.${index}.pension`;
  const testPrefix = `incomes.${index}`;

  return (
    <div data-testid="pension-fieldset">
      <FormInputDropdown
        name={`${prefix}.typeOfBenefit`}
        label={t('directMutations.income.pension.typeOfBenefit')}
        options={getTypeOfPensionOptions()}
        placeholder={t('directMutations.income.pension.chooseTypeOfBenefit')}
        required
        testId={`${testPrefix}.typeOfBenefit`}
      />
      {typeOfBenefit && (
        <>
          <Grid container columnSpacing={3}>
            <Grid item xs={12} sm={endDateVisible ? 6 : 12}>
              <FormInputDate
                name={`${prefix}.startDate`}
                label={t('directMutations.income.pension.startDate')}
                required
                testId={`${testPrefix}.startDate`}
              />
            </Grid>
            {endDateVisible && (
              <Grid item xs={12} sm={6}>
                <FormInputDate
                  name={`${prefix}.endDate`}
                  label={t('directMutations.income.pension.endDate')}
                  minDate={new Date()}
                  testId={`${testPrefix}.endDate`}
                />
              </Grid>
            )}
          </Grid>
          <FormInputCurrency
            name={`${prefix}.incomeAmount`}
            label={t('directMutations.income.pension.incomeAmount')}
            required
            testId={`${testPrefix}.incomeAmount`}
          />
        </>
      )}
    </div>
  );
};

export default Pension;
