import React, { FC } from 'react';
import { CardValues } from '../../../../../../utils/types';
import { useTranslation } from 'react-i18next';
import { CardSection, CardRow } from 'components/SummaryCard';

type CardShapeProps = {
  cardValues: CardValues[];
};

const CardShape: FC<CardShapeProps> = ({ cardValues }) => {
  const { t } = useTranslation();

  return (
    <>
      {cardValues.map((cardValue, i) => {
        const { headerName } = cardValue;
        return (
          <CardSection title={t(headerName)} key={`${headerName}-${i}`}>
            {cardValue.values.map((row, index) => {
              return (
                <React.Fragment key={`${row.label}-${headerName}-${index}`}>
                  <CardRow name={t(row.label)} value={row.value} testId={`${row.testId}-${i}`} />
                </React.Fragment>
              );
            })}
          </CardSection>
        );
      })}
    </>
  );
};

export default CardShape;
