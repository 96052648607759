import { FC, useEffect } from 'react';
import { Typography, FormLabel, MenuItem, FormHelperText, Fade, Tooltip } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { FormInputProps } from '../FormInputProps';
import { Option } from 'screens/Mutations/utils/types';
import { useTranslation } from 'react-i18next';
import isNil from 'utils/predicates/isNil';
import { InputWrapper, SelectInput, TextClip, Info } from '../Forms.styled';

interface FormInputDropdownProps extends FormInputProps {
  options: Option[];
  placeholder?: string;
  tooltip?: string;
  required?: boolean;
  testId?: string;
}

export const FormInputDropdown: FC<FormInputDropdownProps> = ({
  name,
  label,
  options,
  placeholder = '',
  tooltip = '',
  required,
  shouldUnregister = false,
  testId,
}) => {
  const { control, setValue } = useFormContext();
  const { t } = useTranslation();
  const {
    field: { onChange, value: fieldValue },
    fieldState: { error },
  } = useController({
    name,
    control,
    shouldUnregister,
  });
  useEffect(() => {
    if (fieldValue) {
      const field = options.find((opt) => opt.value === fieldValue);
      field ? setValue(name, fieldValue) : setValue(name, undefined);
    } else {
      setValue(name, undefined);
    }
    // only when the component render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateSingleOptions = () => {
    return options.map(({ value, label }: Option, index) => {
      return (
        <MenuItem key={`${value}-${index}`} value={value}>
          <TextClip data-testid={`menuItem-${value}`}>{t(label)}</TextClip>
        </MenuItem>
      );
    });
  };

  return (
    <InputWrapper data-testid={`${testId || name}-input-wrapper`}>
      <FormLabel data-testid={`${testId || name}-input-label`} required={required}>
        {label}
        {tooltip && (
          <Tooltip title={tooltip} placement="right" TransitionComponent={Fade}>
            <Info />
          </Tooltip>
        )}
      </FormLabel>
      <SelectInput
        id={`${testId ?? name}-dropdown-select`}
        onChange={onChange}
        value={!isNil(fieldValue) ? fieldValue : ''}
        variant="outlined"
        error={!!error}
        displayEmpty
        inputProps={{
          'data-testid': testId ?? name,
        }}
      >
        <MenuItem disabled value="">
          <Typography data-testid="menuItem-placeholder">{placeholder}</Typography>
        </MenuItem>
        {generateSingleOptions()}
      </SelectInput>
      <FormHelperText data-testid="error-message-input" error={!!error}>
        {error?.message}
      </FormHelperText>
    </InputWrapper>
  );
};

export default FormInputDropdown;
