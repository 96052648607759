import { IconButton } from '@mui/material';
import { FC } from 'react';
import { FilterHeader, FilterArea } from 'screens/Overview/Overview.style';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

type TablePaginationProps = {
  isNextButtonDisabled: boolean;
  isPreviousButtonDisabled: boolean;
  handlePageChange: (isNext: boolean) => () => void;
};
const TablePagination: FC<TablePaginationProps> = ({
  isNextButtonDisabled,
  isPreviousButtonDisabled,
  handlePageChange,
}) => {
  return (
    <FilterHeader wrap="nowrap" container sx={{ maxHeight: '40px' }}>
      <FilterArea item grow={1} />

      <FilterArea item>
        <IconButton
          data-testid="previous-button"
          disabled={isPreviousButtonDisabled}
          onClick={handlePageChange(false)}
        >
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>
      </FilterArea>

      <FilterArea item>
        <IconButton
          data-testid="next-button"
          disabled={isNextButtonDisabled}
          onClick={handlePageChange(true)}
        >
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      </FilterArea>
    </FilterHeader>
  );
};

export default TablePagination;
