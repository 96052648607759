import { EnergyClass, TypeOfRealEstate } from '__generated__/api';

export const allowedTypesOfRealEstate: TypeOfRealEstate[] = [
  TypeOfRealEstate.FamilyHome,
  TypeOfRealEstate.Appartement,
  TypeOfRealEstate.Studio,
  TypeOfRealEstate.Store,
  TypeOfRealEstate.CommercialProperty,
  TypeOfRealEstate.CommercialRealEstate,
  TypeOfRealEstate.Garage,
  TypeOfRealEstate.Other,
];

export const allowedEnergyClasses: EnergyClass[] = [
  EnergyClass.A,
  EnergyClass.B,
  EnergyClass.C,
  EnergyClass.D,
  EnergyClass.E,
  EnergyClass.F,
  EnergyClass.G,
];
