import { Hidden, Box, Typography, Button } from '@mui/material';
import clsx from 'clsx';
import Header from 'components/Header';
import NotificationCount from 'components/NotificationList/NotificationCount';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { googleTagManager } from 'use/gtm';
import useStyles from '../Notifications.styled';

type HeaderProps = {
  filter: string;
  onFilter: (value: string) => void;
  status: string;
  onRefetch: () => Promise<unknown>;
  isFetching: boolean;
};

const NotificationsHeader = (props: HeaderProps) => {
  const { filter, onFilter, status, onRefetch, isFetching } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [refetchRequested, setRefetchRequested] = useState(false);
  const [showRefreshSnackbar, setShowRefreshSnackbar] = useState(false);

  const handleCloseSnackbar = () => {
    setShowRefreshSnackbar(false);
    setRefetchRequested(false);
  };

  const handleRefresh = async () => {
    await onRefetch();
    setRefetchRequested(true);
  };

  useEffect(() => {
    if (refetchRequested === true) {
      setShowRefreshSnackbar(true);
    }
  }, [refetchRequested]);

  return (
    <Header>
      <Hidden smDown>
        <Box width="100%" style={{ minHeight: '108px' }}>
          <Box display="flex" alignItems="center" mb={2}>
            <Typography data-testid="header-title" variant="h4">
              {t('general.header.menu.notifications')}
            </Typography>
            <NotificationCount size="L" />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Button
                className={clsx(classes.filterButton, { [classes.activeButton]: filter === 'all' })}
                disableRipple
                variant="text"
                disableElevation
                onClick={() => {
                  onFilter('all');
                  googleTagManager({
                    event: 'Filter',
                    filterCategory: 'all',
                    filterValue: filter,
                  });
                }}
              >
                {t('notifications.filter.all')}
              </Button>
              <Button
                className={clsx(classes.filterButton, {
                  [classes.activeButton]: filter === 'applications',
                })}
                disableRipple
                variant="text"
                disableElevation
                onClick={() => {
                  onFilter('applications');
                  googleTagManager({
                    event: 'Filter',
                    filterCategory: 'applications',
                    filterValue: filter,
                  });
                }}
              >
                {t('notifications.filter.applications')}
              </Button>
              <Button
                className={clsx(classes.filterButton, {
                  [classes.activeButton]: filter === 'liveMortgages',
                })}
                disableRipple
                variant="text"
                disableElevation
                onClick={() => {
                  onFilter('liveMortgages');
                  googleTagManager({
                    event: 'Filter',
                    filterCategory: 'liveMortgages',
                    filterValue: filter,
                  });
                }}
              >
                {t('notifications.filter.liveMortgages')}
              </Button>
            </Box>
            <Box>
              <Button
                size="small"
                variant="contained"
                disableElevation
                color="primary"
                onClick={handleRefresh}
                disabled={status === 'loading' || isFetching}
              >
                {status === 'loading' || isFetching
                  ? t('notifications.header.loading')
                  : t('notifications.header.refreshButton')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Typography data-testid="header-title-mobile" variant="h5">
          {t('general.header.menu.notifications')}
        </Typography>
        <NotificationCount />
        <Box pl={2}>
          <Button
            size="small"
            variant="contained"
            disableElevation
            color="primary"
            onClick={handleRefresh}
            disabled={status === 'loading' || isFetching}
          >
            {status === 'loading' || isFetching
              ? t('notifications.header.loading')
              : t('notifications.header.refreshButtonMobile')}
          </Button>
        </Box>
      </Hidden>
      <CustomSnackbar
        verticalPosition="top"
        isOpen={showRefreshSnackbar}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={t('notifications.header.refreshSuccess')}
      />
    </Header>
  );
};
export default NotificationsHeader;
