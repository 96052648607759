import { MenuItem as MUIMenuItem, styled } from '@mui/material';

export const MenuItem = styled(MUIMenuItem)(({ theme }) => ({
  'display': 'flex',
  'height': 50,
  'paddingLeft': theme.spacing(2),
  'paddingRight': theme.spacing(2),
  'color': theme.palette.text.secondary,
  '&:hover, &.Mui-selected:hover, &.Mui-focusVisible': {
    backgroundColor: theme.palette.lightest.light,
  },

  '&.Mui-selected': {
    color: theme.palette.text.primary,
    fontWeight: 500,
    background: 'none',
  },
}));
