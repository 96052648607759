import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TextArea from 'lib/TextArea/TextArea';
import TextInput from 'screens/BuyToLet/BuyToLetForm/form/TextInput';
import ToggleButtonQuestion from 'screens/BuyToLet/BuyToLetForm/form/ToggleButtonQuestion';
import { BtlApplicant } from 'types/BtlFormApplication';
import { maxExistingLoanNumber, minExistingLoanNumber } from '../../constants';

export type BuyToLetExistingMortgageQuestionsProps = {
  category: 'naturalPerson' | 'legalPerson';
  handleToggleChange: (questionId: string, event: any, value: any) => void;
  handleTextChange: (event: any) => void;
  applicant: BtlApplicant;
  errorMessage: (id: string, category: string) => React.ReactNode;
};

const BuyToLetExistingMortgageQuestions: FC<BuyToLetExistingMortgageQuestionsProps> = ({
  category,
  applicant,
  handleToggleChange,
  handleTextChange,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const form = 'buyToLetForm.buyToLetExistingMortgageQuestions';

  return (
    <>
      <Grid item xs={12} sm={6}>
        <ToggleButtonQuestion
          label={t(`${form}.hasExistingMortgageByMoneylender`)}
          key="hasExistingMortgageByMoneylender"
          required
          answerOptions={[
            {
              id: 'yes',
              value: true,
              answerTextKey: 'Ja',
            },
            {
              id: 'no',
              value: false,
              answerTextKey: 'Nee',
            },
          ]}
          containerWidth={12}
          exclusive
          value={applicant.hasExistingMortgageByMoneylender ?? undefined}
          error={Boolean(errorMessage('.hasExistingMortgageByMoneylender', category))}
          onChange={(e, value) => handleToggleChange('hasExistingMortgageByMoneylender', e, value)}
        />
      </Grid>
      {applicant.hasExistingMortgageByMoneylender && (
        <Grid item xs={12} sm={6}>
          <TextInput
            label={t(`${form}.existingLoanNumber`)}
            required
            type="number"
            name="existingLoanNumber"
            onChange={(e) => {
              const value = e.target.value;
              handleTextChange({
                target: {
                  name: e.target.name,
                  value:
                    value === '' || value === undefined || value === null
                      ? undefined
                      : parseInt(value, 10),
                },
              });
            }}
            value={applicant.existingLoanNumber || ''}
            placeholder={t(`${form}.existingLoanNumberPlaceholder`)}
            error={Boolean(errorMessage('.existingLoanNumber', category))}
            inputProps={{
              autoComplete: 'off',
              min: minExistingLoanNumber,
              max: maxExistingLoanNumber,
            }}
            onInput={(e: any) => {
              e.target.value = e.target.value
                .toString()
                .slice(0, maxExistingLoanNumber.toString().length);
            }}
          />
        </Grid>
      )}
      <Grid container item spacing={3}>
        <Grid item xs={12} sm={12}>
          <ToggleButtonQuestion
            containerWidth={6}
            exclusive
            required
            label={t(`${form}.isMortgageRejected`)}
            toolTip={t(`${form}.isMortgageRejectedTooltip`)}
            answerOptions={[
              {
                id: 'yes',
                value: true,
                answerTextKey: 'Ja',
              },
              {
                id: 'no',
                value: false,
                answerTextKey: 'Nee',
              },
            ]}
            value={applicant.isMortgageRejected ?? undefined}
            error={Boolean(errorMessage('.isMortgageRejected', category))}
            onChange={(e, value) => handleToggleChange('isMortgageRejected', e, value)}
          />
        </Grid>

        {applicant.isMortgageRejected && (
          <Grid item xs={12} sm={12}>
            <TextArea
              label={t(`${form}.reasonMortgageRejectionLabel`)}
              required
              name="reasonMortgageRejection"
              onChange={handleTextChange}
              value={applicant.reasonMortgageRejection || ''}
              placeholder={t(`${form}.reasonMortgageRejection`)}
              InputLabelProps={{
                shrink: true,
              }}
              maxRows={8}
              inputProps={{ maxLength: 600, minLength: 5 }}
              charactersLeftThreshold={50}
              error={Boolean(errorMessage('.reasonMortgageRejection', category))}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default BuyToLetExistingMortgageQuestions;
