import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Icon, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BtlApplicant, BtlApplication } from 'types/BtlFormApplication';
import BuyToLetNaturalApplicantSummary from './BuyToLetApplicants/BuyToLetNaturalPerson/BuyToLetNaturalApplicantSummary';
import BuyToLetLoanSummary from './BuyToLetLoanSummary';
import BuyToLetLoanPartsSummary from './BuyToLetLoanPartsSummary';
import BuyToLetLegalApplicantSummary from './BuyToLetApplicants/BuyToLetLegalPerson/BuyToLetLegalApplicantSummary';
import { TypeOfCollateral } from '__generated__/api';
import BuyToLetExistingBuildSummary from './BuyToLetRealEstates/BuyToLetExistingBuildSummary';
import BuyToLetNewlyBuildSummary from './BuyToLetRealEstates/BuyToLetNewlyBuildSummary';
import { formatAddress } from '../../utils';

export function formatName(applicant: BtlApplicant) {
  return [applicant.firstName, applicant.middleName, applicant.lastName]
    .filter((value) => value)
    .join(' ');
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: 60,
    marginTop: theme.spacing(5),
  },
  card: {
    'padding': theme.spacing(3),
    'borderRadius': 4,
    '&.margin': {
      marginBottom: theme.spacing(3),
    },
  },

  subHeader: {
    marginBottom: theme.spacing(3),
  },
}));

export type BuyToLetSummaryProps = {
  application: BtlApplication;
  steps: { label: string; applicant?: number; realEstate?: number }[];
  changeStep: (step: number, subStep?: number, field?: string) => void;
  errorMessage: (id: string, category: string) => void;
  validate: () => void;
};

const BuyToLetSummary = (props: BuyToLetSummaryProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const form = 'buyToLetForm.summary';
  const { application, steps, changeStep, errorMessage, validate } = props;
  const totalApplicants = application.applicants.length;
  const totalRealEstates = application.realEstates.length;

  function navigateTo(step: string, field?: string) {
    const toStep = steps.findIndex((s) => s.label === step);
    changeStep(toStep, 0, field);
  }

  function navigateToApplicant(applicant: number, subStep: number) {
    const toStep = steps.findIndex((s) => s.applicant === applicant);
    changeStep(toStep, subStep);
  }

  function navigateToRealEstate(realEstateIndex: number) {
    const toStep = steps.findIndex((s) => s.realEstate === realEstateIndex);
    changeStep(toStep);
  }

  function navigateToApplicantField(applicant: number, subStep: number, field: string) {
    const toStep = steps.findIndex((s) => s.applicant === applicant);
    changeStep(toStep, subStep, field);
  }

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className={classes.wrapper}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.subHeader} variant="h4">
            {t(`${form}.applicants`)}
          </Typography>
          <Button
            variant="text"
            color="primary"
            endIcon={<Icon>pencil</Icon>}
            onClick={() => navigateTo('applicants')}
          >
            {t(`${form}.edit`)}
          </Button>
        </Box>
        <Paper className={classes.card}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">{t(`${form}.nrOfApplicants`)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">{totalApplicants}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </div>

      {application.applicants.map((applicant, index) =>
        applicant.isLegalPerson ? (
          <BuyToLetLegalApplicantSummary
            key={index}
            applicant={applicant}
            applicantIndex={index}
            navigateToApplicant={navigateToApplicant}
            navigateToApplicantField={navigateToApplicantField}
            errorMessage={errorMessage}
          />
        ) : (
          <BuyToLetNaturalApplicantSummary
            key={index}
            applicant={applicant}
            applicantIndex={index}
            navigateToApplicant={navigateToApplicant}
            navigateToApplicantField={navigateToApplicantField}
            errorMessage={errorMessage}
          />
        )
      )}

      <div className={classes.wrapper}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.subHeader} variant="h4">
            {t(`${form}.realEstates`)}
          </Typography>
          <Button
            variant="text"
            color="primary"
            endIcon={<Icon>pencil</Icon>}
            onClick={() => navigateTo('realEstates')}
          >
            {t(`${form}.edit`)}
          </Button>
        </Box>
        <Paper className={classes.card}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">{t(`${form}.nrOfRealEstates`)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">{totalRealEstates}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </div>

      {application.realEstates.map((realEstate, index) => (
        <div className={classes.wrapper} key={index}>
          <Typography className={classes.subHeader} variant="h4">
            {formatAddress(realEstate) || t('buyToLetForm.realEstates.newRealEstate')}
          </Typography>
          <Paper className={`${classes.card} margin`}>
            <Box display="flex" justifyContent="space-between">
              <Typography className={classes.subHeader} variant="h6">
                {`${formatAddress(realEstate) || t('buyToLetForm.realEstates.newRealEstate')} - ${t(
                  'buyToLetForm.summary.realEstate'
                )} ${index + 1}`}
              </Typography>
              <Button
                variant="text"
                color="primary"
                endIcon={<Icon>pencil</Icon>}
                onClick={() => navigateToRealEstate(index)}
              >
                {t(`${form}.edit`)}
              </Button>
            </Box>
            {realEstate.typeOfCollateral === TypeOfCollateral.Existing ? (
              <BuyToLetExistingBuildSummary
                realEstate={realEstate}
                realEstateNr={index}
                errorMessage={errorMessage}
                onClickField={() => navigateToRealEstate(index)}
              />
            ) : realEstate.typeOfCollateral === TypeOfCollateral.NewlyBuild ? (
              <BuyToLetNewlyBuildSummary
                realEstate={realEstate}
                realEstateNr={index}
                errorMessage={errorMessage}
                onClickField={() => navigateToRealEstate(index)}
              />
            ) : (
              <></>
            )}
          </Paper>
        </div>
      ))}

      <div className={classes.wrapper}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.subHeader} variant="h4">
            {t(`${form}.loan`)}
          </Typography>
          <Button
            variant="text"
            color="primary"
            endIcon={<Icon>pencil</Icon>}
            onClick={() => navigateTo('loan')}
          >
            {t(`${form}.edit`)}
          </Button>
        </Box>
        <Paper className={classes.card}>
          <BuyToLetLoanSummary
            loan={application.loan}
            errorMessage={errorMessage}
            onClickField={(field: any) => navigateTo('loan', field)}
          />
        </Paper>
      </div>

      <div className={classes.wrapper}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.subHeader} variant="h4">
            {t(`${form}.loanParts`)}
          </Typography>
          <Button
            variant="text"
            color="primary"
            endIcon={<Icon>pencil</Icon>}
            onClick={() => navigateTo('loanParts')}
          >
            {t(`${form}.edit`)}
          </Button>
        </Box>
        <Paper className={classes.card}>
          <BuyToLetLoanPartsSummary
            loan={application.loan}
            errorMessage={errorMessage}
            onClickField={(field: any) => navigateTo('loanParts', field)}
          />
        </Paper>
      </div>
    </div>
  );
};

export default BuyToLetSummary;
