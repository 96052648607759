import React, { FC, useEffect, useState } from 'react';
import { FormControl, FormLabel, Grid, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import type { BtlApplicant, BtlApplication } from 'types/BtlFormApplication';
import TextArea from 'lib/TextArea/TextArea';
import TextInput from 'screens/BuyToLet/BuyToLetForm/form/TextInput';
import { formatValidateDate } from 'utils/global';
import BuyToLetExistingMortgageQuestions from './BuyToLetExistingMortgageQuestions';
import BuyToLetCollaterals from './BuyToLetCollaterals';
import { filteredLegalForms } from '../../enums/legalForm.enum';
import DropDownQuestion from 'screens/BuyToLet/BuyToLetForm/form/DropDownQuestion';
import { isValid } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: theme.spacing(4),
  },
  description: {
    marginTop: theme.spacing(2),
    color: theme.palette.textBody.main,
  },
  formGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    maxWidth: 600,
  },
}));

export type BuyToLetApplicantLegalPersonProps = {
  currentApplicantIndex: number;
  application: BtlApplication;
  updateApplicant: (data: BtlApplicant, index: number) => void;
  errorMessage: (id: string, category: string) => React.ReactNode;
};

const BuyToLetApplicantLegalPerson: FC<BuyToLetApplicantLegalPersonProps> = ({
  currentApplicantIndex,
  application,
  updateApplicant,
  errorMessage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const applicant = application.applicants[currentApplicantIndex];
  const [date, setDate] = useState('');
  const form = 'buyToLetForm.newApplicantLegalPerson';
  const category = 'legalPerson';

  useEffect(() => {
    if (applicant.companyStartDate) {
      setDate(applicant.companyStartDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleChange = (questionId: string, event: any, value: any) => {
    const val = value === 'yes' ? true : value === 'no' ? false : value;
    const updatedApplicant = {
      ...applicant,
      [questionId]: val,
    };
    // reset value when switch to no false
    switch (questionId) {
      case 'hasExistingMortgageByMoneylender':
        if (val === false) {
          updatedApplicant.existingLoanNumber = undefined;
        }
        break;
      case 'isMortgageRejected':
        if (val === false) {
          updatedApplicant.reasonMortgageRejection = undefined;
        }
        break;
    }
    updateApplicant(updatedApplicant, currentApplicantIndex);
  };
  const handleTextChange = (event: any) => {
    const updatedApplicant = {
      ...applicant,
      [event.target.name]: event.target.value,
    };
    updateApplicant(updatedApplicant, currentApplicantIndex);
  };

  const handleOnDateChange = (e: any, newName: string) => {
    if (isValid(e)) {
      setDate(e);
      const values = {
        target: { name: newName, value: e ? formatValidateDate(e, { representation: 'date' }) : e },
      };
      const updatedApplicant = {
        ...applicant,
        companyStartDate: values.target.value,
      };
      updateApplicant(updatedApplicant, currentApplicantIndex);
    }
  };

  const handleCurrencyChange = (amount: number, name: string) => {
    const updatedApplicant = {
      ...applicant,
      [name]: isNaN(amount) ? undefined : amount,
    };

    updateApplicant(updatedApplicant, currentApplicantIndex);
  };

  return (
    <form>
      <Typography className={classes.description} variant="body1">
        {t('buyToLetForm.newApplicant.description')}
      </Typography>
      <Grid container spacing={3} className={classes.formGrid}>
        <BuyToLetExistingMortgageQuestions
          category="legalPerson"
          applicant={applicant}
          errorMessage={errorMessage}
          handleTextChange={handleTextChange}
          handleToggleChange={handleToggleChange}
        />
        <Grid item xs={12}>
          <Typography variant="h5">{t(`${form}.generalInformation`)}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            label={t(`${form}.companyName`)}
            required
            name="companyName"
            inputProps={{ maxLength: 100 }}
            onChange={handleTextChange}
            value={applicant.companyName || ''}
            placeholder={t(`${form}.companyName`)}
            error={Boolean(errorMessage('.companyName', category))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            label={t(`${form}.chamberOfCommerceNumber`)}
            placeholder={t(`${form}.chamberOfCommerceNumberPlaceholder`)}
            required
            inputProps={{ maxLength: 8, minLength: 8 }}
            name="chamberOfCommerceNumber"
            onChange={handleTextChange}
            value={applicant.chamberOfCommerceNumber || ''}
            error={Boolean(errorMessage('.chamberOfCommerceNumber', category))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DropDownQuestion
            key="legalForm"
            name="legalForm"
            label={t(`${form}.legalForm`)}
            placeholder={t(`${form}.legalForm`)}
            containerWidth={12}
            answerOptions={filteredLegalForms
              .map((x: string) => ({
                id: x,
                answerText: t(`${form}.${x}`),
              }))
              .sort((a, b) => (a.answerText > b.answerText ? 1 : -1))}
            required
            value={applicant.legalForm ?? ''}
            onChange={handleTextChange}
            error={Boolean(errorMessage('.legalForm', category))}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel>{t(`${form}.companyStartDate`)}</FormLabel>
            <DatePicker
              inputFormat="dd-MM-yyyy"
              value={date}
              onChange={(e) => handleOnDateChange(e as any, 'companyStartDate')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="companyStartDate"
                  required
                  label={null}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errorMessage('.companyStartDate', category))}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextArea
            label={t(`${form}.kindOfActivitiesInCompany`)}
            required
            name="kindOfActivitiesInCompany"
            onChange={handleTextChange}
            value={applicant.kindOfActivitiesInCompany || ''}
            placeholder={t(`${form}.kindOfActivitiesInCompany`)}
            InputLabelProps={{
              shrink: true,
            }}
            maxRows={8}
            inputProps={{ maxLength: 600, minLength: 1 }}
            charactersLeftThreshold={50}
            error={Boolean(errorMessage('.kindOfActivitiesInCompany', category))}
          />
        </Grid>

        <BuyToLetCollaterals
          category="legalPerson"
          applicant={applicant}
          handleTextChange={handleTextChange}
          handleCurrencyChange={handleCurrencyChange}
          errorMessage={errorMessage}
        />
      </Grid>
    </form>
  );
};
export default BuyToLetApplicantLegalPerson;
