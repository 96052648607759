import { ApplicantStepName, Step } from '../types';

// This is a partial application. (read: https://en.wikipedia.org/wiki/Partial_application)
// We have a function with several arguments that returns a function with one argument.
const isApplicantStepActive =
  (steps: Record<string, Step>, activeStepIndex: number) =>
  (name: ApplicantStepName) =>
  (applicantIndex: number) =>
    Object.keys(steps).indexOf(`${name}-${applicantIndex}`) === activeStepIndex;

export default isApplicantStepActive;
