import { Alert, Grid, FormControl, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TextArea from 'lib/TextArea/TextArea';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { BtlLoan } from 'types/BtlFormApplication';
import CurrencyField from 'lib/CurrencyField/CurrencyField';
import { ButToLetDecimalAmountEnabled } from 'config/constants';

const useStyles = makeStyles((theme) => ({
  formGrid: { marginTop: theme.spacing(2), marginBottom: theme.spacing(1) },
  description: {
    marginTop: theme.spacing(2),
    color: theme.palette.textBody.main,
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 900,
    margin: '60px 100px',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4),
    },
  },
  line: {
    flexGrow: 1,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
}));

const RowItem: FC<{ left: React.ReactElement; right?: React.ReactElement | false }> = ({
  left,
  right,
}) => {
  return (
    <Grid container item spacing={3}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        {left}
      </Grid>
      {right && (
        <Grid item xs={12} sm={6} md={8} lg={9}>
          {right}
        </Grid>
      )}
    </Grid>
  );
};

export type BuyToLetLoanProps = {
  loan: BtlLoan;
  totalRequired: number;
  totalProven: number;
  updateLoan: (loan: BtlLoan) => void;
  errorMessage: (id: string, category: string) => React.ReactNode;
  showValidations: boolean;
};

const BuyToLetLoan: FC<BuyToLetLoanProps> = ({
  loan,
  totalRequired,
  totalProven,
  updateLoan,
  errorMessage,
  showValidations,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const form = 'buyToLetForm.loan';
  const textAreaRowsMax = 4;
  const textAreaMaxCharacters = 600;
  const textAreaCharactersLeftThreshold = 50;

  const category = 'loan';

  const handleOnCurrencyChange = (amount: number | null, name: string) => {
    (loan as any)[name] = amount;
    const updatedLoan = {
      ...loan,
      [name]: amount,
    };

    updateLoan(updatedLoan);
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedLoan = {
      ...loan,
      [e.currentTarget.name]: e.currentTarget.value === '' ? null : e.currentTarget.value,
    };
    updateLoan(updatedLoan);
  };

  const showTotalRequiredError = showValidations && totalRequired === 0;

  const showErrorTotals = showValidations && totalRequired !== totalProven;

  return (
    <form>
      <Grid container spacing={4} className={classes.formGrid}>
        <RowItem left={<Typography variant="h5">{t(`${form}.financingNeed`)}</Typography>} />

        <RowItem
          key="row1"
          left={
            <CurrencyField
              label={t(`${form}.totalPurchasePrice`)}
              name="totalPurchasePrice"
              containerWidth={12}
              onChange={(number: number | null) =>
                handleOnCurrencyChange(number, 'totalPurchasePrice')
              }
              value={loan.totalPurchasePrice}
              error={Boolean(errorMessage('.totalPurchasePrice', category))}
              allowDecimals={ButToLetDecimalAmountEnabled}
            />
          }
          right={
            <TextArea
              value={loan.explanationTotalPurchasePrice ?? ''}
              name="explanationTotalPurchasePrice"
              onChange={handleOnChange}
              label={t(`${form}.explanationTotalPurchasePrice`)}
              error={Boolean(errorMessage('.explanationTotalPurchasePrice', category))}
              maxRows={textAreaRowsMax}
              inputProps={{ maxLength: textAreaMaxCharacters, minLength: 2 }}
              charactersLeftThreshold={textAreaCharactersLeftThreshold}
            />
          }
        />

        <RowItem
          key="row2"
          left={
            <FormControl
              fullWidth
              error={Boolean(errorMessage('.refinancingExistingMortgagesAmount', category))}
            >
              <CurrencyField
                label={t(`${form}.refinancingExistingMortgagesAmount`)}
                name="refinancingExistingMortgagesAmount"
                containerWidth={12}
                onChange={(amount: number | null) =>
                  handleOnCurrencyChange(amount, 'refinancingExistingMortgagesAmount')
                }
                value={loan.refinancingExistingMortgagesAmount}
                error={Boolean(errorMessage('.refinancingExistingMortgagesAmount', category))}
                placeholder={t(`${form}.refinancingExistingMortgagesAmount`)}
                allowDecimals={ButToLetDecimalAmountEnabled}
              />
            </FormControl>
          }
          right={
            <TextArea
              value={loan.explanationRefinancingExistingMortgagesAmount || ''}
              name="explanationRefinancingExistingMortgagesAmount"
              onChange={handleOnChange}
              label={t(`${form}.explanationRefinancingExistingMortgagesAmount`)}
              error={Boolean(
                errorMessage('.explanationRefinancingExistingMortgagesAmount', category)
              )}
              maxRows={textAreaRowsMax}
              inputProps={{ maxLength: textAreaMaxCharacters }}
              charactersLeftThreshold={textAreaCharactersLeftThreshold}
            />
          }
        />

        <RowItem
          key="row3"
          left={
            <FormControl fullWidth error={Boolean(errorMessage('.totalCostBuyerAmount', category))}>
              <CurrencyField
                label={t(`${form}.totalCostBuyerAmount`)}
                name="totalCostBuyerAmount"
                containerWidth={12}
                onChange={(number: number | null) =>
                  handleOnCurrencyChange(number, 'totalCostBuyerAmount')
                }
                error={Boolean(errorMessage('.totalCostBuyerAmount', category))}
                value={loan.totalCostBuyerAmount}
                placeholder={t(`${form}.totalCostBuyerAmount`)}
                allowDecimals={ButToLetDecimalAmountEnabled}
              />
            </FormControl>
          }
          right={
            <TextArea
              value={loan.explanationTotalCostBuyerAmount || ''}
              name="explanationTotalCostBuyerAmount"
              onChange={handleOnChange}
              label={t(`${form}.explanationTotalCostBuyerAmount`)}
              error={Boolean(errorMessage('.explanationTotalCostBuyerAmount', category))}
              maxRows={textAreaRowsMax}
              inputProps={{ maxLength: textAreaMaxCharacters }}
              charactersLeftThreshold={textAreaCharactersLeftThreshold}
            />
          }
        />

        <RowItem
          key="row4"
          left={
            <FormControl
              fullWidth
              error={Boolean(errorMessage('.liquidityAmountToWithdraw', category))}
            >
              <CurrencyField
                label={t(`${form}.liquidityAmountToWithdraw`)}
                name="liquidityAmountToWithdraw"
                containerWidth={12}
                onChange={(number: number | null) =>
                  handleOnCurrencyChange(number, 'liquidityAmountToWithdraw')
                }
                value={loan.liquidityAmountToWithdraw}
                error={Boolean(errorMessage('.liquidityAmountToWithdraw', category))}
                placeholder={t(`${form}.liquidityAmountToWithdraw`)}
                allowDecimals={ButToLetDecimalAmountEnabled}
              />
            </FormControl>
          }
          right={
            <TextArea
              value={loan.explanationLiquidityAmountToWithdraw || ''}
              name="explanationLiquidityAmountToWithdraw"
              required={(loan?.liquidityAmountToWithdraw ?? 0) > 0}
              onChange={handleOnChange}
              label={t(`${form}.explanationLiquidityAmountToWithdraw`)}
              error={Boolean(errorMessage('.explanationLiquidityAmountToWithdraw', category))}
              maxRows={textAreaRowsMax}
              inputProps={{ maxLength: textAreaMaxCharacters }}
              charactersLeftThreshold={textAreaCharactersLeftThreshold}
            />
          }
        />

        <RowItem
          key="row5"
          left={
            <FormControl fullWidth error={Boolean(errorMessage('.otherCosts', category))}>
              <CurrencyField
                label={t(`${form}.otherCosts`)}
                name="otherCosts"
                containerWidth={12}
                onChange={(number: number | null) => handleOnCurrencyChange(number, 'otherCosts')}
                error={Boolean(errorMessage('.otherCosts', category))}
                value={loan.otherCosts}
                placeholder={t(`${form}.otherCosts`)}
                allowDecimals={ButToLetDecimalAmountEnabled}
              />
            </FormControl>
          }
          right={
            <TextArea
              value={loan.explanationOtherCosts || ''}
              name="explanationOtherCosts"
              onChange={handleOnChange}
              label={t(`${form}.explanationOtherCosts`)}
              error={Boolean(errorMessage('.explanationOtherCosts', category))}
              maxRows={textAreaRowsMax}
              inputProps={{ maxLength: textAreaMaxCharacters }}
              charactersLeftThreshold={textAreaCharactersLeftThreshold}
            />
          }
        />

        <RowItem
          key="row6"
          left={
            <FormControl fullWidth error={Boolean(errorMessage('.totalRenovationCosts', category))}>
              <CurrencyField
                label={t(`${form}.totalRenovationCosts`)}
                name="totalRenovationCosts"
                containerWidth={12}
                onChange={(number: number | null) =>
                  handleOnCurrencyChange(number, 'totalRenovationCosts')
                }
                error={Boolean(errorMessage('.totalRenovationCosts', category))}
                value={loan.totalRenovationCosts}
                placeholder={t(`${form}.totalRenovationCosts`)}
                allowDecimals={ButToLetDecimalAmountEnabled}
              />
            </FormControl>
          }
          right={
            <TextArea
              value={loan.explanationTotalRenovationCosts || ''}
              required={(loan?.totalRenovationCosts ?? 0) > 0}
              name="explanationTotalRenovationCosts"
              onChange={handleOnChange}
              label={t(`${form}.explanationTotalRenovationCosts`)}
              error={Boolean(errorMessage('.explanationTotalRenovationCosts', category))}
              maxRows={textAreaRowsMax}
              inputProps={{ maxLength: textAreaMaxCharacters }}
              charactersLeftThreshold={textAreaCharactersLeftThreshold}
            />
          }
        />

        <RowItem key="row7" left={<div className={classes.line} />} />

        <RowItem
          key="row8"
          left={
            <FormControl fullWidth error={showTotalRequiredError}>
              <CurrencyField
                label={t(`${form}.totalRequiredAmount`)}
                name="totalRequiredAmount"
                containerWidth={12}
                value={totalRequired}
                onChange={(number: number | null) =>
                  handleOnCurrencyChange(number, 'totalRequiredAmount')
                }
                error={showTotalRequiredError}
                disabled
                allowDecimals={ButToLetDecimalAmountEnabled}
              />
            </FormControl>
          }
          right={
            showTotalRequiredError && (
              <Alert severity="error" style={{ marginTop: '22px' }}>
                {t(`${form}.totalRequiredIsZero`)}
              </Alert>
            )
          }
        />

        <RowItem
          key="row9"
          left={<Typography variant="h5">{t(`${form}.financingProve`)}</Typography>}
        />

        <RowItem
          key="row10"
          left={
            <FormControl fullWidth error={Boolean(errorMessage('.amount', category))}>
              <CurrencyField
                label={t(`${form}.amount`)}
                name="amount"
                containerWidth={12}
                onChange={(number: number | null) => handleOnCurrencyChange(number, 'amount')}
                error={Boolean(errorMessage('.amount', category))}
                value={loan.amount}
                placeholder={t(`${form}.amount`)}
                allowDecimals={ButToLetDecimalAmountEnabled}
              />
            </FormControl>
          }
        />

        <RowItem
          key="row11"
          left={
            <FormControl fullWidth error={Boolean(errorMessage('.addedPersonalEquity', category))}>
              <CurrencyField
                label={t(`${form}.addedPersonalEquity`)}
                name="addedPersonalEquity"
                containerWidth={12}
                onChange={(number: number | null) =>
                  handleOnCurrencyChange(number, 'addedPersonalEquity')
                }
                error={Boolean(errorMessage('.addedPersonalEquity', category))}
                value={loan.addedPersonalEquity}
                placeholder={t(`${form}.addedPersonalEquity`)}
                allowDecimals={ButToLetDecimalAmountEnabled}
              />
            </FormControl>
          }
          right={
            <TextArea
              value={loan.explanationAddedPersonalEquity || ''}
              name="explanationAddedPersonalEquity"
              required={(loan?.addedPersonalEquity ?? 0) > 0}
              onChange={handleOnChange}
              error={Boolean(errorMessage('.explanationAddedPersonalEquity', category))}
              label="Toelichting"
              maxRows={textAreaRowsMax}
              inputProps={{ maxLength: textAreaMaxCharacters }}
              charactersLeftThreshold={textAreaCharactersLeftThreshold}
            />
          }
        />

        <RowItem key="row12" left={<div className={classes.line} />} />

        <RowItem
          key="row13"
          left={
            <FormControl fullWidth error={showErrorTotals}>
              <CurrencyField
                label={t(`${form}.totalProvenAmount`)}
                disabled
                name="totalProvenAmount"
                containerWidth={12}
                error={showErrorTotals}
                value={totalProven}
                placeholder={t(`${form}.totalProvenAmount`)}
                allowDecimals={ButToLetDecimalAmountEnabled}
              />
            </FormControl>
          }
          right={
            showErrorTotals && (
              <Alert severity="error" style={{ marginTop: '22px' }}>
                {t(`${form}.amountNotEqualToTotalProvenAmount`)}
              </Alert>
            )
          }
        />
      </Grid>
    </form>
  );
};

export default BuyToLetLoan;
