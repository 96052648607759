import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, DialogContent, Icon, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Disclaimer from './Disclaimer';
import { setCookies } from '../../utils/global';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    alignSelf: 'flex-end',
    margin: 0,
    width: '100vw',
    maxWidth: '100vw',
    borderRadius: 0,
  },
  dialogContent: {
    '&:first-of-type': {
      padding: theme.spacing(0),
    },
  },
  cookieBar: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    position: 'fixed',
    bottom: 0,
    zIndex: 2000,
    boxShadow: '0 -6px 12px 0 rgba(22,20,47,0.12)',
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  cookieDescriptionWrapper: {
    width: '60%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  cookieDescription: {
    flex: 1,
  },
  warningIcon: {
    'width': 40,
    'height': 40,
    'marginRight': theme.spacing(4),
    'borderRadius': 8,
    'backgroundColor': alpha(theme.palette.warning.main, 0.1),
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    '& .MuiIcon-root': {
      color: theme.palette.warning.main,
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0, 1, 0),
    },
  },
  link: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  btnWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      marginTop: theme.spacing(3),
      justifyContent: 'center',
    },
  },
  acceptButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0, 1, 0),
    },
  },
  disclaimer: {
    position: 'absolute',
    top: 60,
    width: 750,
  },
}));

type CookieProps = {
  open: boolean;
  closeDialog: () => void;
};

const CookieBar = (props: CookieProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showDisclaimer, setShowDisclaimer] = React.useState(false);
  const closeDisclaimer = () => setShowDisclaimer(false);

  function acceptCookies() {
    setCookies(true);
    props.closeDialog();
  }

  function declineCookies() {
    setCookies(false);
    props.closeDialog();
  }

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={props.open}
      fullWidth
      maxWidth={false}
      onClose={(e, reason: 'backdropClick') => {
        if (reason === 'backdropClick') {
          // do nothing
        } else {
          props.closeDialog();
        }
      }}
      disableEscapeKeyDown
    >
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.cookieBar} display="flex" justifyContent="space-between">
          <div className={classes.cookieDescriptionWrapper}>
            <div className={classes.warningIcon}>
              <Icon>alert_triangle</Icon>
            </div>
            <Typography className={classes.cookieDescription} variant="body2" color="textPrimary">
              {t('cookies.cookieDescription')}
              <span onClick={() => setShowDisclaimer(true)} className={classes.link}>
                {t('cookies.link')}
              </span>
            </Typography>
          </div>
          <div className={classes.btnWrapper}>
            <Button
              onClick={acceptCookies}
              className={classes.acceptButton}
              variant="contained"
              color="primary"
            >
              {t('cookies.acceptButton')}
            </Button>
            <Button onClick={declineCookies} variant="text" color="primary">
              {t('cookies.declineButton')}
            </Button>
          </div>
        </Box>
      </DialogContent>
      {showDisclaimer && <Disclaimer onClose={closeDisclaimer} />}
    </Dialog>
  );
};

export default CookieBar;
