import { FC, useCallback, useState } from 'react';
import { Box, Button, FormHelperText, Icon, Paper } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialog } from 'components/CustomDialog';
import FormTitleText from 'components/Form/FormTitle';
import { defaultApplicant } from '../../DismissalJointLiabilityMutation/constants';
import { applicantName } from 'screens/Mutations/utils';
import { UserIcon, BoxWrapper, AddApplicantButton, ApplicantName } from './Applicants.styled';
import { RaiseForm } from '../types';
import { useWizardStep } from 'hooks/useWizard';

const ApplicantList: FC = () => {
  const { t } = useTranslation();
  const { control, getFieldState, formState } = useFormContext<RaiseForm>();
  const { goToStep } = useWizardStep();

  const { fields, append, remove } = useFieldArray({
    name: 'applicants',
    control,
  });
  const { error: applicantsError }: any = getFieldState('applicants', formState);
  const { error: applicantIncomeError }: any = getFieldState('applicantsIncomes', formState);

  const [selectedApplicant, setSelectedApplicant] = useState<number>();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const closeDialog = useCallback(() => setDialogIsOpen(false), []);

  // there are two solutions
  // - or: we do the same as in FormStepper (and we need to pass the props)
  // - or: because there are 4 screens per applicant,
  //   we can calculate the position based of the index
  const handleEdit = useCallback((index: number) => () => goToStep(4 * index + 1), [goToStep]);

  const handleDelete = useCallback(
    (index: number) => () => {
      setDialogIsOpen(true);
      setSelectedApplicant(index);
    },
    []
  );

  const handleConfirmationDialog = useCallback(() => {
    remove(selectedApplicant);
    setSelectedApplicant(undefined);
    closeDialog();
  }, [closeDialog, remove, selectedApplicant]);

  const handleAddApplicant = useCallback(() => {
    const newApplicant = {
      ...defaultApplicant,
      income: {
        id: undefined,
        incomes: [],
      },
    };
    append(newApplicant);

    handleEdit(fields.length)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <section data-testid="applicants">
      <FormTitleText
        title={t('directMutations.applicants.title')}
        description={t('directMutations.applicants.subtitle')}
      />
      <BoxWrapper my={4}>
        <Paper>
          {applicantsError || applicantIncomeError ? (
            <Box display="flex" alignItems="center" px={3} pt={1}>
              <FormHelperText error={applicantIncomeError || applicantsError}>
                {applicantsError
                  ? t('directMutations.applicants.invalidApplicant')
                  : applicantIncomeError?.message}
              </FormHelperText>
            </Box>
          ) : null}

          {fields.map((item, index) => {
            const isErrorInApplicantForm = applicantsError ? !!applicantsError[index] : false;
            return (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={3}
                py={2}
                key={item.id}
              >
                <Box display="flex">
                  <UserIcon>user</UserIcon>
                  <ApplicantName isvalid={isErrorInApplicantForm ? 1 : 0}>
                    {applicantName(item) || t('directMutations.stepper.applicant')}
                  </ApplicantName>
                </Box>
                <Box whiteSpace="nowrap">
                  <Button
                    color="error"
                    endIcon={<Icon>trash</Icon>}
                    variant="text"
                    onClick={handleDelete(index)}
                    disabled={fields.length === 1}
                    data-testid="applicant-delete-button"
                  >
                    {t('directMutations.buttons.deleteButton')}
                  </Button>
                  <Button
                    onClick={handleEdit(index)}
                    color="primary"
                    endIcon={<Icon>pencil</Icon>}
                    variant="text"
                    data-testid="applicant-edit-button"
                  >
                    {t('directMutations.buttons.editButton')}
                  </Button>
                </Box>
              </Box>
            );
          })}
        </Paper>
      </BoxWrapper>

      {fields.length < 4 && (
        <AddApplicantButton
          onClick={handleAddApplicant}
          variant="contained"
          color="primary"
          size="medium"
        >
          {t('directMutations.buttons.addApplicantButton')}
          <Icon>plus</Icon>
        </AddApplicantButton>
      )}
      <ConfirmationDialog
        isOpen={dialogIsOpen}
        onConfirmation={handleConfirmationDialog}
        onDecline={closeDialog}
        onClose={closeDialog}
        confirmationText={t('directMutations.buttons.deleteButton')}
        declineText={t('directMutations.buttons.cancelButton')}
        bodyText={t('directMutations.confirmDelete.bodyObligation')}
      />
    </section>
  );
};

export default ApplicantList;
