import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  Typography,
  List,
  ListItemText,
  IconButton,
  Button,
  Chip,
  Icon,
  Menu,
  MenuProps,
  MenuItem,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Close } from '@mui/icons-material';
import { ProofItem } from 'types/ProofItem';
import ProofItemListItem from 'components/ProofItemListItem';
import { createProofItem } from '../utils';
import { Action, Solution } from '../../../store/taskReducer';
import { TaskSolutionResolutionFileDocumentType, Case } from '__generated__/api-di';
import getProofItemsCountLabel from 'components/ActionsSection/utils/getProofItemsCountLabel';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    marginRight: theme.spacing(1),
    fontWeight: 'bolder',
    [theme.breakpoints.down('md')]: {
      flexGrow: 1,
    },
  },
  header: {
    padding: theme.spacing(4, 4, 2, 4),
  },
  dialogActions: {
    justifyContent: 'center',
  },
  logo: {
    height: 36,
    width: 36,
    marginBottom: theme.spacing(2),
  },
  list: {
    padding: theme.spacing(0, 4),
  },
  listItem: {
    padding: theme.spacing(2, 0),
  },
  text: {
    maxWidth: '60%',
  },
  selected: {
    color: theme.palette.primary.main,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 0),
  },
  emphasize: {
    fontWeight: 'bold',
    fontSize: '10px',
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
  close: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

interface ProofItemSelectProps {
  application: Case;
  proofItems: ProofItem[];
  openUpload: (application: Case, proofItem: ProofItem) => void;
  openPreview: (application: Case, proofItem: ProofItem) => void;
  show: boolean;
  closeClick: () => void;
  issueId: string;
  solution: Solution;
  dispatch: React.Dispatch<Action>;
}

const ProofItemSelect: React.FC<ProofItemSelectProps> = ({
  application,
  show,
  closeClick,
  openUpload,
  openPreview,
  proofItems,
  issueId,
  solution,
  dispatch,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const closeDialog = () => {
    closeClick();
  };

  if (!show) {
    return null;
  }

  const proofItemsCountLabel = getProofItemsCountLabel(
    application.proofItem?.received,
    application.proofItem?.amount
  );

  return (
    <Dialog open={show} fullWidth maxWidth="md" scroll="paper" onClose={closeDialog}>
      <DialogTitle className={classes.header}>
        <Typography variant="h4">{t('tasks.solutions.currentProofItems')}</Typography>
        <IconButton className={classes.close} onClick={closeDialog} size="large">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.list}>
        <div className={classes.heading}>
          <span>
            <Typography className={classes.title} variant="subtitle1">
              {t('applications.detail.proofItems.title')}
            </Typography>
            <Chip className={classes.emphasize} size="small" label={proofItemsCountLabel} />
          </span>
        </div>
        <List disablePadding>
          {proofItems.map((item) => (
            <ProofItemListItem
              key={item.code}
              proofItem={item}
              application={application}
              onClickView={(i) => {
                openPreview(application, i);
              }}
              onClickUpload={() => {
                openUpload(application, item);
              }}
            />
          ))}
        </List>
        <DialogActions className={classes.dialogActions}>
          <AddProofItemButton issueId={issueId} solution={solution} dispatch={dispatch} />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      'backgroundColor': theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    'minWidth': 'initial',
  },
}))(MenuItem);

interface AddProofItemButtonProps {
  issueId: string;
  solution: Solution;
  dispatch: React.Dispatch<Action>;
}

const AddProofItemButton: React.FC<AddProofItemButtonProps> = ({ issueId, solution, dispatch }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClickAdd = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAdd = () => {
    setAnchorEl(null);
  };

  const handleDocumentTypeClick = (documentLabel: TaskSolutionResolutionFileDocumentType) => {
    dispatch({
      type: 'update',
      payload: {
        issueId: issueId,
        data: {
          type: 'addNew',
          id: solution.id,
          data: {
            action: 'AddUpload',
            label: documentLabel,
            file: null,
            name: '',
          },
          state: {
            showProofItemSelect: false,
            selectedProofItem: createProofItem(0, 0, t(`tasks.documentType.${documentLabel}`)),
            uploadVisible: true,
          },
        },
      },
    });
  };

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        endIcon={<Icon className={classes.btnIcon}>plus</Icon>}
        onClick={(e) => {
          e.stopPropagation();
          handleClickAdd(e);
        }}
      >
        {t('tasks.solutions.addNewProofItemFromReUpload')}
      </Button>
      <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseAdd}>
        <AddProofItemOption
          type={TaskSolutionResolutionFileDocumentType.IdentityDocument}
          handleDocumentTypeClick={handleDocumentTypeClick}
        />
        <AddProofItemOption
          type={TaskSolutionResolutionFileDocumentType.EmployerStatement}
          handleDocumentTypeClick={handleDocumentTypeClick}
        />
        <AddProofItemOption
          type={TaskSolutionResolutionFileDocumentType.BankStatement}
          handleDocumentTypeClick={handleDocumentTypeClick}
        />
        <AddProofItemOption
          type={TaskSolutionResolutionFileDocumentType.ExplanationCollateral}
          handleDocumentTypeClick={handleDocumentTypeClick}
        />
        <AddProofItemOption
          type={TaskSolutionResolutionFileDocumentType.ExplanationIncome}
          handleDocumentTypeClick={handleDocumentTypeClick}
        />
        <AddProofItemOption
          type={TaskSolutionResolutionFileDocumentType.ExplanationCredits}
          handleDocumentTypeClick={handleDocumentTypeClick}
        />
      </StyledMenu>
    </>
  );
};

interface AddProofItemOptionProps {
  type: TaskSolutionResolutionFileDocumentType;
  handleDocumentTypeClick(type: TaskSolutionResolutionFileDocumentType): void;
}
const AddProofItemOption: React.FC<AddProofItemOptionProps> = ({
  handleDocumentTypeClick,
  type,
}) => {
  const { t } = useTranslation();
  return (
    <StyledMenuItem onClick={() => handleDocumentTypeClick(type)}>
      <ListItemText primary={t(`tasks.documentType.${type}`)} />
    </StyledMenuItem>
  );
};

export default ProofItemSelect;
