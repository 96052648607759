import { FC, useCallback, useState } from 'react';
import { Box, Button, FormHelperText, Grid, MenuItem } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStyles } from './IncomeList.styled';
import Income from './Income';
import { ConfirmationDialog } from 'components/CustomDialog';
import TotalIncome from './components/TotalIncome';
import { DeleteOutline } from '@mui/icons-material';
import { getTypeOfIncomeOptions, incomeDefaultValues } from 'screens/Mutations/constants';
import { RootFormName, IncomeFormType, TypeOfIncome } from 'screens/Mutations/utils/types';
import DropdownButton from 'components/DropdownButton';

export type IncomeListProps = {
  rootFormName: RootFormName | string;
};

const IncomeList: FC<IncomeListProps> = ({ rootFormName }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, getFieldState, formState, trigger } = useFormContext<IncomeFormType>();
  const { error: hasIncomeError }: any = getFieldState('hasIncome', formState);

  const { fields, append, remove } = useFieldArray({ name: 'incomes', control });
  const [selectedIncome, setSelectedIncome] = useState<number>();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const typeOfIncomes = getTypeOfIncomeOptions();

  const closeDialog = useCallback(() => setDialogIsOpen(false), []);

  const handleDeleteIncome = useCallback(
    (index: number, id: string) => () => {
      setDialogIsOpen(true);
      setSelectedIncome(index);
    },
    []
  );

  const handleConfirmationDialog = () => {
    remove(selectedIncome);

    setSelectedIncome(undefined);
    closeDialog();
  };

  const handleAddIncome = useCallback(
    (typeOfIncome: TypeOfIncome) => {
      append({ ...incomeDefaultValues, typeOfIncome: typeOfIncome });
      trigger('hasIncome');
    },
    [append, trigger]
  );

  const deleteIsDisabled = fields.length <= 1 && rootFormName === 'currentApplicant';

  return (
    <>
      {hasIncomeError ? (
        <Box display="flex" alignItems="center" px={0} pt={1}>
          <FormHelperText error={hasIncomeError}>{hasIncomeError?.message}</FormHelperText>
        </Box>
      ) : null}

      {fields.map((field, incomeIndex) => (
        <Income key={field.id} incomeIndex={incomeIndex} isInputDropdown={false}>
          <Button
            disabled={deleteIsDisabled}
            onClick={handleDeleteIncome(incomeIndex, field.id)}
            size="medium"
            className={`${classes.deleteButton} ${classes.warning}`}
            data-testid="btn-delete-income"
          >
            {t('directMutations.buttons.deleteButton')}
            <DeleteOutline className={classes.buttonIcon} />
          </Button>
        </Income>
      ))}
      {fields.length <= 10 && (
        <Grid item xs={12} py={3}>
          <DropdownButton
            label={t('directMutations.buttons.addIncomeButton')}
            data-testid="addIncomeButton"
            name="incomes"
          >
            {typeOfIncomes.map(({ label, value }) => (
              <MenuItem
                key={value}
                data-testid={value}
                onClick={() => handleAddIncome(value as TypeOfIncome)}
              >
                {t(label)}
              </MenuItem>
            ))}
          </DropdownButton>
        </Grid>
      )}
      {fields.length > 0 && <TotalIncome />}
      <ConfirmationDialog
        isOpen={dialogIsOpen}
        onConfirmation={handleConfirmationDialog}
        onDecline={closeDialog}
        onClose={closeDialog}
        confirmationText={t('directMutations.buttons.deleteButton')}
        declineText={t('directMutations.buttons.cancelButton')}
        bodyText={t('directMutations.confirmDelete.bodyIncome')}
      />
    </>
  );
};

export default IncomeList;
