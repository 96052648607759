import { useLocation, useParams } from 'react-router-dom';
import { MutationType, UrlParams } from '../utils/types';
import { useMoneylenders } from 'use/clients';
import { useGetTechnicalProducts } from './useMutations';
import { mapProductsToOptions } from '../utils/mappers/mapProductsToOptions';

// This hook exposes data necessary for mutation forms
const useMutationForm = () => {
  const { pathname } = useLocation();
  const { loanNumber: strLoanNumber, commercialLabel } = useParams<keyof UrlParams>() as UrlParams;

  const type: MutationType = pathname.includes('/verhogen/') ? 'Raise' : 'ConversionWithRaise';

  const loanNumber = +strLoanNumber;

  const { data: moneyLenders, isLoading: loadingMoneyLenders } = useMoneylenders();
  const { data: productsData, isLoading: loadingProducts } = useGetTechnicalProducts(
    commercialLabel,
    +loanNumber
  );

  const isLoading = loadingProducts || loadingMoneyLenders;

  const technicalProducts = mapProductsToOptions(productsData?.data);

  const moneylenderName =
    moneyLenders?.find((lender) => lender.commercialLabel === commercialLabel)?.moneylenderName ||
    '';

  return {
    type,
    loanNumber: +loanNumber,
    isLoading,
    commercialLabel,
    moneylenderName,
    technicalProducts,
  };
};

export default useMutationForm;
