import { FC } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generalEnum } from '../../../utils/generalEnum';
import TemplateDetails from './TemplateDetails';
import { DetailsRow } from 'lib/ClientDataDetails/Fields';
import { ClientDetailsRealEstate } from 'types/ClientDetailsModel';

type RealEstateDetailsProps = {
  realEstate?: ClientDetailsRealEstate;
};

const RealEstateDetails: FC<RealEstateDetailsProps> = ({ realEstate }) => {
  const { t } = useTranslation();
  if (!realEstate) return null;
  const { type, location: address, valuation } = realEstate;
  const translationsRoot = 'clients.clientData.realEstate';
  const addressLabel = address?.street ? `${address?.street} ${address?.houseNumber || ''}` : null;
  const cityLabel = address?.city ? `${address?.city} ${address?.country?.code ?? ''}` : null;

  return (
    <Grid container data-testid="realEstateDetails-container">
      <Grid xs={12} md={6} item px={3} py={2}>
        <TemplateDetails title={t(`${translationsRoot}.realEstateHead`)}>
          <DetailsRow
            label={t(`${translationsRoot}.typeOfRealEstate`)}
            value={generalEnum('typeOfRealEstate', type)}
          />
        </TemplateDetails>

        <TemplateDetails title={t(`${translationsRoot}.address`)}>
          <DetailsRow label={t(`${translationsRoot}.address`)} value={addressLabel} />
          <DetailsRow label={t(`${translationsRoot}.postalCode`)} value={address?.postalCode} />
          <DetailsRow label={t(`${translationsRoot}.city`)} value={cityLabel} />
        </TemplateDetails>

        <TemplateDetails title={t(`${translationsRoot}.lastQuotation`)}>
          <DetailsRow
            type="currency"
            label={t(`${translationsRoot}.marketValue`)}
            value={valuation?.marketValue}
          />
          <DetailsRow
            type="currency"
            label={t(`${translationsRoot}.forcedSaleValue`)}
            value={valuation?.forcedSaleValue}
          />
          <DetailsRow type="date" label={t(`${translationsRoot}.date`)} value={valuation?.date} />
        </TemplateDetails>
      </Grid>
    </Grid>
  );
};

export default RealEstateDetails;
