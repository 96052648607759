import { errorMessages, NO_NUMBER_AND_SPECIAL_CHAR_REGX } from 'screens/Mutations/constants';
import { object, string } from 'yup';

export const additionalInformationSchema = object({
  info: string().nullable(),
  name: string()
    .matches(NO_NUMBER_AND_SPECIAL_CHAR_REGX, errorMessages.noNumberAndSpecialCharacter)
    .required(errorMessages.required)
    .nullable(),
  phoneNumber: string().required(errorMessages.required).nullable(),
});
const additionalInformationBaseSchema = object({
  additionalInformation: additionalInformationSchema,
});

export default additionalInformationBaseSchema;
