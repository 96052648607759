import { useCallback, useState } from 'react';
import { ProofItem } from 'types/ProofItem';
import ProofItemButtonOptions from 'types/ProofItemsButtons';
import { useQueryProofItems } from 'use/documents';
import { Case, CaseDetails } from '__generated__/api-di';
import groupProofItems from '../utils/groupProofItems';
import { mapToProofItemButtonOptions } from '../utils/mapToProofItemButtonOptions';

const filterProofItem = (proofItem: ProofItem, filterOptions: ProofItemButtonOptions) => {
  const buttons = mapToProofItemButtonOptions(proofItem);

  if (!buttons) return false;

  return buttons.hasUploadButton === filterOptions.hasUploadButton;
};
const canApplyFilter = (proofItems?: ProofItem[]) => proofItems && proofItems.length > 0;

const useProofItems = ($case: Case, caseDetails?: CaseDetails) => {
  const { data: proofItems, isLoading, isError } = useQueryProofItems($case, false);
  const [filter, setFilter] = useState<ProofItemButtonOptions>();
  const canFilter = canApplyFilter(proofItems);
  const allProofItems = groupProofItems(proofItems);
  let preFilteredProofItems = proofItems;
  if (proofItems && filter && caseDetails) {
    preFilteredProofItems = proofItems.filter((item) => filterProofItem(item, filter));
  }
  const filteredProofItems = groupProofItems(preFilteredProofItems);

  const toggleFilter = useCallback(
    (options: ProofItemButtonOptions) => setFilter(filter ? undefined : options),
    [filter]
  );

  return {
    allProofItems,
    filteredProofItems,
    isLoading: isLoading,
    isError: isError,
    toggleFilter,
    hasFilter: Boolean(filter),
    canFilter,
  };
};

export default useProofItems;
