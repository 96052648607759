import { Grid } from '@mui/material';
import {
  FormInputNumber,
  FormInputCurrency,
  FormToggleButtonGroup,
  FormInputDropdown,
} from 'components/Form';
import { FieldsGrid } from 'components/Form/Forms.styled';
import FormTitleText from 'components/Form/FormTitle';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yesNoGroupOptions } from 'screens/Mutations/constants';
import { SubTitle } from 'screens/Mutations/molecules/styled';
import { getReasonIncreaseOptions } from '../../utils/getReasonIncreaseOptions';
import LoanPartList from 'screens/Mutations/templates/Loan/LoanPartList';
import { useEffect, useState } from 'react';
import useUnregisterFields from 'screens/Mutations/hooks/useUnregisterField';
import { useParams } from 'react-router-dom';
import { useGetTechnicalProducts } from 'screens/Mutations/hooks/useMutations';
import { UrlParams } from 'screens/Mutations/utils/types';
import { SelectOption } from 'types/SelectOption';

export default function Loan() {
  const { t } = useTranslation();
  const changeCurrentMortgage = useWatch({ name: 'financingLoan.changeCurrentMortgage' });
  const unregisterMultiple = useUnregisterFields();
  const { loanNumber, commercialLabel } = useParams<keyof UrlParams>() as UrlParams;
  const [productsData, setProductsData] = useState<SelectOption[] | []>([]);
  const { data: products } = useGetTechnicalProducts(commercialLabel, loanNumber ? +loanNumber : 0);

  useEffect(() => {
    if (products) {
      const prodData = products.data.map((product: { name: string; displayName: string }) => ({
        value: product.name,
        label: product.displayName,
      }));
      setProductsData(prodData);
    }
  }, [products]);

  useEffect(() => {
    if (!changeCurrentMortgage) {
      unregisterMultiple(['newLoanParts', 'currentLoanParts']);
    }
    if (changeCurrentMortgage && changeCurrentMortgage === 'no') {
      unregisterMultiple(['currentLoanParts']);
    }
  }, [changeCurrentMortgage, unregisterMultiple]);
  return (
    <>
      <FormTitleText
        title={t('directMutations.loan.title')}
        description={t('directMutations.loan.subtitle')}
      />
      <FieldsGrid container>
        <SubTitle variant="h5">{t('directMutations.loan.formTitle')}</SubTitle>
        <Grid item xs={12}>
          <FormInputNumber
            name="loanNumber"
            label={t('directMutations.loan.loanNumber')}
            disabled
            required
            thousandSeparator
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputCurrency
            name="financingLoan.amount"
            label={t('directMutations.loan.amount')}
            placeholder={t('directMutations.amountPlaceholder')}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputCurrency
            name="financingLoan.amountRaise"
            label={t('directMutations.loan.amountRaise')}
            placeholder={t('directMutations.amountPlaceholder')}
            required
            min={0}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputDropdown
            name="financingLoan.reasonIncrease"
            label={t('directMutations.loan.reasonIncrease')}
            options={getReasonIncreaseOptions()}
            placeholder={t('directMutations.loan.reasonIncrease')}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormToggleButtonGroup
            name="financingLoan.changeLifeInsurancePolicy"
            options={yesNoGroupOptions}
            label={t('directMutations.loan.changeLifeInsurancePolicy')}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormToggleButtonGroup
            name="financingLoan.changeCurrentMortgage"
            options={yesNoGroupOptions}
            label={t('directMutations.loan.changeCurrentMortgage')}
            required
          />
        </Grid>
      </FieldsGrid>
      {changeCurrentMortgage === 'yes' && (
        <LoanPartList
          productsData={productsData}
          fieldSet="currentLoanParts"
          maxLoanPartsAllowed={25}
          shouldUnregister={false}
        />
      )}

      <LoanPartList
        productsData={productsData}
        fieldSet="newLoanParts"
        maxLoanPartsAllowed={25}
        shouldUnregister={false}
      />
    </>
  );
}
