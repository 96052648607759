import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, alpha, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { getUncompletedTasks } from 'utils/global';
import { TaskInfo } from '__generated__/api-di';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  taskIcon: {
    color: theme.palette.primary.main,
    verticalAlign: 'text-top',
    marginRight: theme.spacing(2),
  },
}));

type TasksAlertProps = {
  openResolve: (task: TaskInfo) => void;
  tasks: Array<TaskInfo>;
};

const TasksAlert: React.FC<TasksAlertProps> = (props) => {
  const { openResolve, tasks } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const uncompletedTasks = getUncompletedTasks(tasks);

  if (uncompletedTasks.length === 0) {
    return null;
  }

  const openIssuesClick = () => {
    openResolve(uncompletedTasks[0]);
  };

  return (
    <div className={`${classes.root}`}>
      <Box display="flex" alignItems="center" fontWeight={500} fontSize={12} color="textPrimary">
        <AnnouncementIcon className={`${classes.taskIcon}`} />
        <Typography variant="body2" color="textSecondary">
          {t('applications.detail.tasks.alert.description')}
        </Typography>
      </Box>
      <Button color="primary" variant="text" onClick={openIssuesClick}>
        {t('applications.detail.tasks.alert.openIssues')}
      </Button>
    </div>
  );
};

export default TasksAlert;
