import type { ProofItem } from 'types/ProofItem';
import { GroupedProofItems } from '../types';

const filterProofItems = (accumulator: GroupedProofItems, proofItem: ProofItem) => {
  const { code, utmostDeliveryMoment, supplier, isOckto } = proofItem;
  if (isOckto) {
    accumulator.generated.push(proofItem);
  } else if (code === 100) {
    accumulator.offerLetter = proofItem;
  } else if (code === 101) {
    accumulator.finalContracts.push(proofItem);
  } else if (
    utmostDeliveryMoment === 'BeforePassingDeed' &&
    supplier === 'Consumer' &&
    code === 103
  ) {
    accumulator.finalContracts.push(proofItem);
  } else if (utmostDeliveryMoment === 'BeforePassingDeed' && supplier === 'Consumer') {
    accumulator.administrativeDocuments.push(proofItem);
  } else {
    accumulator.provide.push(proofItem);
  }

  return accumulator;
};

const groupProofItems = (proofItems?: ProofItem[]): GroupedProofItems => {
  const defaultGroup = {
    finalContracts: [],
    administrativeDocuments: [],
    provide: [],
    generated: [],
  } as GroupedProofItems;

  if (!proofItems) return defaultGroup;

  return proofItems.reduce((accumulator: GroupedProofItems, proofItem: ProofItem) => {
    return filterProofItems(accumulator, proofItem);
  }, defaultGroup);
};

export default groupProofItems;
