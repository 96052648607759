import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, Hidden, Icon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FileViewer from 'components/DocumentViewer';
import Zoom from 'components/DocumentViewer/Zoom';
import LoadingIndicator from 'components/LoadingIndicator';
import { useQueryNotificationDocument } from 'use/notifications';
import { SlideUpTransition } from '../../lib/Transition/transition';

const useStyles = makeStyles((theme) => ({
  main: {
    flexGrow: 100,
  },
  fullHeight: {
    height: '100%',
    flexGrow: 100,
  },
  header: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    minHeight: 72,
    flexGrow: 1,
    boxShadow: theme.shadows[6],
    zIndex: 3,
  },
  headerLeft: {
    flexGrow: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  headerCenter: {
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: theme.spacing(1),
    color: theme.palette.grey[600],
    fontWeight: 'bold',
  },
  headerRight: {
    flexGrow: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: theme.palette.grey[600],
    fontWeight: 'bold',
  },
  headerIcon: {
    'margin': 0,
    'color': theme.palette.grey[600],
    '&:hover': {
      color: theme.palette.grey[900],
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 1, 0, 1),
    },
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
  closeIcon: {
    width: 24,
    height: 24,
  },
  closeButton: {
    'margin': theme.spacing(1, 3, 1, 1),
    'float': 'right',
    'backgroundColor': 'transparent',
    'borderColor': 'transparent',
    'color': theme.palette.grey[600],
    '&:hover': {
      color: theme.palette.grey[900],
      cursor: 'pointer',
    },
  },
  documentIcon: {
    width: 24,
    height: 24,
    margin: theme.spacing(1, 1, 1, 3),
    color: theme.palette.grey[600],
  },
  button: {
    display: 'flex',
    alignItems: 'center',
  },
  actionBtn: {
    'color': theme.palette.grey[600],
    'paddingRight': theme.spacing(2),
    '&:hover': {
      color: theme.palette.grey[900],
    },
  },
}));

type Props = {
  reference: string;
  open: boolean;
  onClose: () => void;
};

const ViewFile: FC<Props> = ({ reference, open, onClose }) => {
  const [state, setState] = useState('');
  const classes = useStyles();
  const [scale, setScale] = useState(1);
  const { t } = useTranslation();
  const document = useQueryNotificationDocument(reference);
  const file = document as any;
  let fileInfo;

  const fileName = file?.name;
  const fileDate = file?.lastModifiedDate;

  const uploadFileInfo = {
    name: fileName,
    date: fileDate,
  };

  if (uploadFileInfo !== null) {
    fileInfo = uploadFileInfo;
  }

  const reset = () => {
    setState('');
  };

  const handleZoom = (scalePercentage: number) => {
    setScale(scalePercentage / 100);
  };

  const handleDownload = () => {
    const url = window.URL.createObjectURL(new Blob([file.data]));
    const link = window.document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.pdf`);
    window.document.body.appendChild(link);
    link.click();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={SlideUpTransition}
      onClose={onClose}
      TransitionProps={{ onExited: reset }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="stretch"
        className={classes.fullHeight}
      >
        {state !== 'error' ? (
          <>
            <Header
              fileInfo={fileInfo}
              status={document.status}
              onZoom={handleZoom}
              onClose={onClose}
              onDownloadPdf={handleDownload}
            />

            <div className={classes.main}>
              {document.status === 'loading' && <LoadingIndicator />}
              {document.status === 'success' && <FileViewer file={file} scale={scale} />}
              {document.status === 'error' && (
                <Box height="50%" display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="h6">{t('error.default')}</Typography>
                </Box>
              )}
            </div>
          </>
        ) : (
          t('error.default')
        )}
      </Box>
    </Dialog>
  );
};

type HeaderProps = {
  fileInfo?: any;
  status: string;
  onZoom: (scale: number) => void;
  onClose: () => void;
  onDownloadPdf: () => void;
};

const Header: FC<HeaderProps> = ({ fileInfo, status, onZoom, onDownloadPdf, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.header}>
      <div className={classes.headerLeft}>
        <div className={classes.headerIcon}>
          <Icon className={classes.documentIcon}>file_text</Icon>
        </div>
        <Typography variant="h6">{fileInfo.name}</Typography>
      </div>

      <div className={classes.headerCenter}>
        <Zoom onZoom={onZoom} />
      </div>

      <div className={classes.headerRight}>
        <div className={classes.button}>
          <Button
            disabled={status !== 'success'}
            onClick={onDownloadPdf}
            variant="text"
            className={classes.actionBtn}
            type="submit"
            startIcon={<Icon className={classes.btnIcon}>download</Icon>}
          >
            <Hidden smDown>{t('notifications.viewer.download')}</Hidden>
          </Button>
        </div>
        <button type="button" onClick={onClose} className={classes.closeButton}>
          <Icon className={classes.closeIcon}>close</Icon>
        </button>
      </div>
    </div>
  );
};

export default ViewFile;
