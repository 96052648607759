import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    height: 'auto',
    width: '100%',
  },
  header: {
    width: '94%',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    color: theme.palette.text.disabled,
  },
  greyText: {
    color: theme.palette.text.disabled,
  },
  title: {
    margin: theme.spacing(0, 0, 4, 0),
  },
  button: {
    margin: theme.spacing(4, 0, 4, 0),
    width: 'fit-content',
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
  deleteButton: {
    width: 'fit-content',
  },
  headerName: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  income: {},
  warning: {
    color: theme.palette.danger.dark,
  },
}));
