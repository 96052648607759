import { TaskInfo } from '__generated__/api-di';
import { GroupedProofItems, TabKey } from '../types';

const getAllTabs = (
  { offerLetter, finalContracts, administrativeDocuments }: GroupedProofItems,
  tasks: TaskInfo[]
): TabKey[] => {
  const tabs: TabKey[] = [];

  if (tasks.length) tabs.push('tasks');
  if (offerLetter) tabs.push('offerLetter');
  if (finalContracts.length > 0) tabs.push('finalContracts');
  if (administrativeDocuments.length > 0) tabs.push('administrativeDocuments');

  return tabs;
};

export default getAllTabs;
