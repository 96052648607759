import { FC } from 'react';
import FormTitleText from 'components/Form/FormTitle';

import { Section, Paper } from './UploadArea.styled';
import { UploadAreaProps } from './types';
import { useTranslation } from 'react-i18next';

const UploadArea: FC<UploadAreaProps> = ({ children, title, description }) => {
  const { t } = useTranslation();
  return (
    <Section>
      <FormTitleText title={t(title)} description={t(description)} />
      <Paper>{children}</Paper>
    </Section>
  );
};

export default UploadArea;
