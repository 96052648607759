import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { BtlApplicant } from 'types/BtlFormApplication';
import * as i18nIsoCountries from 'i18n-iso-countries';

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    '& td': {
      borderBottom: 'none',
      wordBreak: 'break-all',
    },
    '&.error': {
      'backgroundColor': theme.palette.danger.light,
      '& :last-child': {
        color: theme.palette.danger.main,
      },
    },
  },
  errorIcon: {
    'float': 'right',
    'display': 'none',
    '.error &': {
      display: 'block',
    },
  },
  loanPartTitle: {
    marginBottom: theme.spacing(2),
  },
}));

type BuyToLetApplicantContactDataSummaryProps = {
  applicant: BtlApplicant;
  applicantIndex: number;
  onClickField: (field: string) => void;
  errorMessage: (id: string, category: string) => void;
};

const BuyToLetApplicantContactDataSummary: FC<BuyToLetApplicantContactDataSummaryProps> = ({
  applicant,
  applicantIndex,
  onClickField,
  errorMessage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const form = 'buyToLetForm.contactData';

  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].street`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.street`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('street')}>
            {applicant.street}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].houseNumber`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.houseNumber`)}

            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('houseNumber')}>
            {applicant.houseNumber}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].houseNumberExtension`, 'applicants')),
          })}
        >
          <TableCell>{t(`${form}.houseNumberExtension`)}</TableCell>
          <TableCell onClick={() => onClickField('houseNumberExtension')}>
            {applicant.houseNumberExtension}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].postalCode`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.postalCode`)}

            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('postalCode')}>
            {applicant.postalCode}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].city`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.city`)}

            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('city')}>
            {applicant.city}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].country`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.country`)}

            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('country')}>
            {applicant.country && i18nIsoCountries.getName(applicant.country, 'nl')}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].privatePhoneNumber`, 'applicants')),
          })}
        >
          <TableCell> {t(`${form}.privatePhoneNumber`)}</TableCell>
          <TableCell onClick={() => onClickField('privatePhoneNumber')}>
            {applicant.privatePhoneNumber}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].mobilePhoneNumber`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.mobilePhoneNumber`)} <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('mobilePhoneNumber')}>
            {applicant.mobilePhoneNumber}

            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].workPhoneNumber`, 'applicants')),
          })}
        >
          <TableCell> {t(`${form}.workPhoneNumber`)}</TableCell>
          <TableCell onClick={() => onClickField('workPhoneNumber')}>
            {applicant.workPhoneNumber}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${applicantIndex}].emailAddress`, 'applicants')),
          })}
        >
          <TableCell>
            {t(`${form}.emailAddress`)} <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('emailAddress')}>
            {applicant.emailAddress}

            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default BuyToLetApplicantContactDataSummary;
