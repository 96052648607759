import { stepsOrderAll } from '../../../constants';

export enum StepState {
  Done = 'Done',
  Parent = 'Parent',
  Current = 'Current',
  Todo = 'Todo',
}

export const getStepState = (
  firstUrlPart: string,
  secondUrlPart: string,
  currentStep?: string,
  parentStep?: string
): StepState => {
  // incomplete parameters
  if (!firstUrlPart || !secondUrlPart || !currentStep) return StepState.Todo;

  const combinedURL = firstUrlPart.concat('/', secondUrlPart);
  let indexOfRoute = stepsOrderAll.map((step) => step).indexOf(combinedURL);
  if (indexOfRoute === -1) {
    indexOfRoute = stepsOrderAll.map((step) => step).indexOf(secondUrlPart);
  }
  const indexOfParentStep = parentStep ? stepsOrderAll.map((step) => step).indexOf(parentStep) : -1;
  const indexOfCurrentStep = stepsOrderAll.map((step) => step).indexOf(currentStep);

  // current page
  if (indexOfCurrentStep === indexOfRoute) return StepState.Current;

  // parent. The MenuItem is the parent of the current page
  if (indexOfParentStep === indexOfRoute) return StepState.Parent;

  if (parentStep && secondUrlPart.indexOf(parentStep) > -1) {
    return StepState.Parent;
  }

  if (secondUrlPart.indexOf(currentStep) > -1) {
    return StepState.Parent;
  }

  // Done
  if (indexOfCurrentStep > -1 && indexOfCurrentStep < indexOfRoute) return StepState.Done;

  return StepState.Todo;
};
