import React, { FC } from 'react';
import i18n from 'i18next';
import HttpBackend, { BackendOptions } from 'i18next-http-backend';
import { initReactI18next, I18nextProvider } from 'react-i18next';

i18n
  // load translations using http
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(HttpBackend)
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  // init i18next
  .init<BackendOptions>({
    backend: {
      loadPath: '/locales/{{lng}}.json',
      requestOptions: {
        cache: 'no-cache',
      },
    },
    fallbackLng: 'nl',
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
    returnNull: false,
  });

const i18nStorybook = i18n.cloneInstance();

export const I18nProvider: FC<{ children: React.ReactNode }> = ({ children }) => (
  <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
);

// Disable suspense in storybook
export const I18nStorybookProvider: FC<{ children: React.ReactNode }> = ({ children }) => (
  <I18nextProvider i18n={i18nStorybook}>{children}</I18nextProvider>
);

export default i18n;
