import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Fab, Hidden, Popover, Button, Icon, List, ListItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Check, Close, MoreVert } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  footer: {
    'position': 'fixed',
    'bottom': theme.spacing(4),
    'right': theme.spacing(4),
    '& > *': {
      marginLeft: theme.spacing(2),
    },

    [theme.breakpoints.down('md')]: {
      'right': theme.spacing(2),
      'bottom': 136,
      'display': 'flex',
      'flexDirection': 'column',
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  },
  cancel: {
    'color': theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.dark,
      backgroundColor: theme.palette.white.main,
    },
  },
  submit: {
    'backgroundColor': theme.palette.success.main,
    'borderColor': theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));

type Props = {
  onSubmit: () => void;
  onCancel: () => void;
  onDownloadPdf?: () => void;
  onPrintPdf?: () => void;
  submitLabel?: string;
};

const Footer: FC<Props> = ({ onSubmit, onCancel, submitLabel, onDownloadPdf, onPrintPdf }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.footer}>
      <Hidden mdDown>
        <Fab variant="extended" size="medium" onClick={onCancel} className={classes.cancel}>
          {t('applications.upload.cancelButton') as string}
        </Fab>
        <Fab
          color="primary"
          variant="extended"
          size="medium"
          onClick={onSubmit}
          className={classes.submit}
        >
          {submitLabel || (t('applications.upload.submitButton') as string)}
        </Fab>
      </Hidden>
      <Hidden mdUp>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <List>
            <ListItem>
              <Button onClick={onPrintPdf} variant="text" startIcon={<Icon>printer</Icon>}>
                {t('fileViewer.print')}
              </Button>
            </ListItem>
            <ListItem>
              <Button
                onClick={onDownloadPdf}
                variant="text"
                type="submit"
                startIcon={<Icon>download</Icon>}
              >
                {t('fileViewer.download')}
              </Button>
            </ListItem>
          </List>
        </Popover>

        <div onClick={handleClick}>
          {!open && (
            <Fab size="medium">
              <MoreVert />
            </Fab>
          )}
        </div>
        {open ? (
          <Fab size="medium" onClick={handleClose} aria-describedby={id}>
            <Close />
          </Fab>
        ) : (
          <>
            <Fab size="medium" onClick={onCancel} className={classes.cancel}>
              <Close />
            </Fab>
            <Fab color="primary" size="medium" onClick={onSubmit} className={classes.submit}>
              <Check />
            </Fab>
          </>
        )}
      </Hidden>
    </div>
  );
};

export default Footer;
