import { useTranslation } from 'react-i18next';
import { ClientLoan } from 'types/Client';
import { Container, Typography } from '@mui/material';
import ClientDetailContentApplication from './ClientDetailApplicationContent';

export type ClientDetailApplicationListProps = {
  applications: ClientLoan[];
};

const ClientDetailApplicationList = ({ applications }: ClientDetailApplicationListProps) => {
  const { t } = useTranslation();

  if (!applications.length) {
    return null;
  }
  return (
    <Container disableGutters sx={{ marginTop: '35px' }}>
      <Typography variant="h6" component="div">
        {t('clients.detail.applications')}
      </Typography>
      {applications.map((loan, i) => (
        <ClientDetailContentApplication loan={loan} key={i} />
      ))}
    </Container>
  );
};

export default ClientDetailApplicationList;
