import { FC, useState, useEffect } from 'react';
import TitleBar from 'components/TitleBar';
import { useForm, FormProvider } from 'react-hook-form';
import FormStepper from 'components/FormStepper';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import createClientSchema from '../../schema/createClientSchema';
import { useMutateCreateClient } from 'use/useMutateCreateClient';
import CreateClient from './components/CreateClient/CreateClient';
import BottomNavigation from 'components/Molecules/components/BottomNavigation';
import FormTemplate from 'components/Templates/FormTemplate';
import { useDispatch } from 'react-redux';
import { setCreateClientSubmitStatus } from 'store/createClientFormReducer';
import MoneyLenderSubmitBody from 'types/MoneyLenderSubmitBody';
import { mapRelatedMoneylendersToOptions } from 'screens/Clients/mappers/clients/mapRelatedMoneylendersToOptions';
import { useRelatedMoneylenders } from 'use/clients';
import { isLoggedInAsObserver } from 'auth/utils';
import env from 'config/env';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

const CreateClientForm: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState<{ status: string; message?: string }>();
  const formMethods = useForm<MoneyLenderSubmitBody>({
    resolver: yupResolver(createClientSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { data: relatedMoneylenders, isLoading } = useRelatedMoneylenders();
  const { watch, handleSubmit } = formMethods;
  const commercialRelationInput = watch('commercialRelation');
  const clientNameInput = watch('clientName');
  const loanNumberInput = watch('loanNumber');
  const isObserver = isLoggedInAsObserver();

  useEffect(() => {
    if (!env.FEATURES.STAT_482932_ADD_NEW_CLIENT || isObserver) {
      navigate('/clients');
    }
  }, [navigate, isObserver]);

  const steps = [
    {
      name: t('clients.createClient.formStepperName'),
      text: t('clients.createClient.formStepperText'),
    },
  ];

  const handleOnCloseClick = () => navigate('/clients');

  const handleSuccess = () => {
    dispatch(setCreateClientSubmitStatus('success'));
    navigate('/clients/');
  };

  const handleError = (error: any) => {
    if (error.response.data.title) {
      setSnackbar({
        status: 'Error',
        message: error.response.data.title,
      });
    } else {
      setSnackbar({
        status: 'Error',
      });
    }
  };

  const handleOnClose = () => setSnackbar(undefined);

  const { mutateAsync: submitApplication, isLoading: isSubmitting } = useMutateCreateClient(
    handleSuccess,
    handleError
  );

  const submit = () => {
    if (!isSubmitting) {
      const newClient: MoneyLenderSubmitBody = {
        commercialRelation: commercialRelationInput,
        clientName: clientNameInput,
        loanNumber: loanNumberInput,
      };
      submitApplication(newClient);
    }
  };

  const relatedMoneylendersOptions = mapRelatedMoneylendersToOptions(relatedMoneylenders);
  const hasMoneyLenderOptions = relatedMoneylendersOptions.length;

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submit)}>
          <FormTemplate
            header={<TitleBar title="Voeg klant toe" onCloseClick={handleOnCloseClick} />}
            sidebar={<FormStepper steps={hasMoneyLenderOptions ? steps : []} />}
            footer={
              hasMoneyLenderOptions ? (
                <BottomNavigation
                  nextButtonStyleType="submit"
                  hasPreviousStep={false}
                  isSummaryDisabled
                  isSubmitting={isSubmitting}
                />
              ) : null
            }
          >
            <CreateClient
              relatedMoneylendersOptions={relatedMoneylendersOptions}
              hasMoneyLenderOptions={hasMoneyLenderOptions > 0}
              isLoading={isLoading}
            />
          </FormTemplate>
        </form>
      </FormProvider>
      {snackbar && (
        <CustomSnackbar
          isOpen={!!snackbar}
          handleClose={handleOnClose}
          severity={snackbar.status === 'success' ? 'success' : 'error'}
          message={
            snackbar.message
              ? snackbar.message
              : t(`clients.header.submitCreateClient.${snackbar.status}`)
          }
        />
      )}
    </>
  );
};

export default CreateClientForm;
