import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Notification } from 'types/NotificationPreference';
import { Grid, Typography, Divider } from '@mui/material';
import { EmailTypeText, ListItemPadding } from '../NotificationPreference.styled';
import { FormInputSwitch } from 'components/Form';

type ListItemProps = {
  notification: Notification;
  index: number;
};

const NotificationListItem: FC<ListItemProps> = ({ notification, index }) => {
  const { t } = useTranslation();
  return (
    <>
      <ListItemPadding data-testid="notification-list-item">
        <Grid container>
          <Grid container item xs={4} alignContent="center">
            <Typography variant="inherit">{notification.eventDescription}</Typography>
          </Grid>
          <Grid container item xs={4}>
            <Grid container item justifyContent="center" alignContent="center">
              <EmailTypeText variant="inherit">
                {t('notificationPreference.list-item.email')}
              </EmailTypeText>
            </Grid>
          </Grid>
          <Grid container item xs={4} justifyContent="flex-end" alignContent="center">
            <FormInputSwitch
              name={`notifications[${index}].useEmail`}
              label=""
              type={notification?.eventName}
            />
          </Grid>
        </Grid>
      </ListItemPadding>
      <Divider />
    </>
  );
};
export default NotificationListItem;
