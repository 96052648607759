import { useTranslation } from 'react-i18next';
import { Box, Button, Icon } from '@mui/material';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import type { Letter } from '__generated__/api';
import { useQueryApplicationLetters } from 'use/letters';

const useStyles = makeStyles((theme) => ({
  root: {
    'padding': theme.spacing(2),
    'borderRadius': 6,
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    'backgroundColor': alpha(theme.palette.primary.main, 0.1),
    '&.red': {
      backgroundColor: alpha(theme.palette.danger.main, 0.1),
    },
    '&.orange': {
      backgroundColor: alpha(theme.palette.warning.main, 0.1),
    },
    '&.grey': {
      backgroundColor: alpha(theme.palette.text.primary, 0.1),
    },
    '&.green': {
      backgroundColor: alpha(theme.palette.success.main, 0.1),
    },
    '&.blue': {
      backgroundColor: alpha(theme.palette.info.main, 0.1),
    },
  },
  icon: {
    'marginRight': theme.spacing(2),
    'color': theme.palette.primary.dark,
    '&.red': {
      color: theme.palette.danger.dark,
    },
    '&.orange': {
      color: theme.palette.warning.dark,
    },
    '&.grey': {
      color: theme.palette.text.primary,
    },
    '&.green': {
      color: theme.palette.success.dark,
    },
    '&.blue': {
      color: theme.palette.info.dark,
    },
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
}));

type Props = {
  urgency: string;
  commercialLabel: string;
  loanNumber: string;
  applicationIndex: string;
  openDocumentView: (
    displayName: string,
    commercialLabel: string,
    index: string,
    letterId: string,
    loanNumber?: string | null
  ) => void;
};

const LettersAlert: React.FC<Props> = (props) => {
  const { urgency, commercialLabel, loanNumber, applicationIndex, openDocumentView } = props;
  const classes = useStyles({ urgency: urgency });
  const { t } = useTranslation();

  const { data, isLoading } = useQueryApplicationLetters(
    commercialLabel,
    loanNumber,
    applicationIndex
  );

  if (isLoading) return null;

  const letters = data
    ? data.sort(
        (a: Letter, b: Letter) =>
          (new Date(b.creationDate) as any) - (new Date(a.creationDate) as any)
      )
    : [];

  return (
    <>
      {letters?.map(({ creationDate, displayName, id }) => (
        <div key={`${creationDate}${loanNumber}`} className={`${classes.root} ${urgency}`}>
          <Box
            display="flex"
            alignItems="center"
            fontWeight={500}
            fontSize={12}
            color="textPrimary"
          >
            {/* Temporary icon, waiting for right icon to update icon font */}
            <Icon className={`${classes.icon} ${urgency}`}>alert_triangle</Icon>
            {displayName}
          </Box>
          <Button
            color="primary"
            variant="text"
            endIcon={<Icon className={classes.btnIcon}>paperclip</Icon>}
            onClick={() =>
              openDocumentView(displayName, commercialLabel, applicationIndex, id, loanNumber)
            }
          >
            {t('letters.viewLetter')}
          </Button>
        </div>
      ))}
    </>
  );
};

export default LettersAlert;
