import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getTypeOfOtherIncomeOptions } from 'screens/Mutations/constants';
import { FormInputDate, FormInputDropdown, FormInputCurrency } from 'components/Form';
import { Grid } from '@mui/material';

type Props = {
  index?: number;
  rootFormName?: string;
};

const Other: FC<Props> = ({ index = 0, rootFormName }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const formIncomes = useWatch({ control, name: `${rootFormName || ''}incomes` });

  const income = formIncomes?.length ? formIncomes[index] : null;
  const typeOfOtherIncome = income?.otherIncome?.typeOfOtherIncome;

  const prefix = `${rootFormName || ''}incomes.${index}.otherIncome`;
  const testPrefix = `incomes.${index}`;

  return (
    <div data-testid="other-fieldset">
      <FormInputDropdown
        name={`${prefix}.typeOfOtherIncome`}
        label={t('directMutations.income.otherIncome.typeOfOtherIncome')}
        options={getTypeOfOtherIncomeOptions()}
        placeholder={t('directMutations.income.otherIncome.chooseTypeOfOtherIncome')}
        required
        testId={`${testPrefix}.typeOfOtherIncome`}
      />
      {typeOfOtherIncome && (
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <FormInputCurrency
              name={`${prefix}.incomeAmount`}
              label={t('directMutations.income.otherIncome.incomeAmount')}
              required
              testId={`${testPrefix}.incomeAmount`}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInputDate
              name={`${prefix}.endDate`}
              label={t('directMutations.income.otherIncome.endDate')}
              testId={`${testPrefix}.endDate`}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Other;
