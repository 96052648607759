import {
  BuyToLetApplicationInfo,
  getBuyToLetApplicationRequestDetailsInfo,
  getBuyToLetConfigurationForOrientationPage,
  saveBtlApplication,
  submitBtlApplication,
  validateBuyToLetOrientationPage,
} from '../api/buyToLetForm';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { WizardData } from 'types/Wizard';
import { ApplicationReadDto, CreateBuyToLetEventResponse } from '__generated__/api';
import { useGroup } from './group';

export function useQueryBuyToLetApplicationRequestDetails(id?: number) {
  const group = useGroup();
  const { status, error, isError, isFetching, data } = useQuery(
    ['buy-to-let-application-request-details-info', id, group],
    () => getBuyToLetApplicationRequestDetailsInfo(id ?? 0, group),
    { enabled: id !== undefined && !!group }
  );

  return {
    status,
    error,
    isError,
    isFetching,
    // To prevent users from mutating the object from the react query cache
    data:
      data !== undefined
        ? (JSON.parse(JSON.stringify(data)) as BuyToLetApplicationInfo)
        : undefined,
  };
}

export function useMutateBuyToLetDetails(
  succesCallback: ({ data, status }: { data: BuyToLetApplicationInfo; status: number }) => void,
  errorCallback: () => void
) {
  const queryClient = useQueryClient();
  const group = useGroup();
  return useMutation((variables: BuyToLetApplicationInfo) => saveBtlApplication(variables, group), {
    onSuccess: (response) => {
      queryClient.setQueryData(
        ['buy-to-let-application-request-details-info', response.data.application.id, group],
        response.data
      );

      succesCallback(response);
    },
    onError: () => {
      errorCallback();
    },
  });
}

export function useSubmitBuyToLetApplication(
  succesCallback: () => void,
  errorCallback: () => void
) {
  const queryClient = useQueryClient();
  const group = useGroup();
  return useMutation((variables: ApplicationReadDto) => submitBtlApplication(variables, group), {
    onSuccess: (response) => {
      const currentData = queryClient.getQueryData<CreateBuyToLetEventResponse>([
        'buy-to-let-application-request-details-info',
        response.data?.application?.id,
        group,
      ]);
      queryClient.setQueryData(
        ['buy-to-let-application-request-details-info', response.data?.application?.id, group],
        {
          ...currentData,
          application: response.data?.application,
        }
      );

      succesCallback();
    },
    onError: () => {
      errorCallback();
    },
  });
}

export function useQueryBuyToLetConfiguration() {
  return useQuery(['application-details'], () => getBuyToLetConfigurationForOrientationPage());
}

export function useValidateBuyToLetOrientation() {
  const group = useGroup();
  return useMutation((wizardData: WizardData) =>
    validateBuyToLetOrientationPage(wizardData, group)
  );
}
