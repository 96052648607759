import makeStyles from '@mui/styles/makeStyles';
import { ProofItemStatus } from 'utils/proof-item';

const iconInvalid = require('../../../assets/images/proof-item/invalid.svg');
const iconMissing = require('../../../assets/images/proof-item/missing.svg');
const iconPending = require('../../../assets/images/proof-item/pending.svg');
const iconValid = require('../../../assets/images/proof-item/valid.svg');
const iconCancelled = require('../../../assets/images/proof-item/cancelled.svg');

const useStyles = makeStyles((theme) => ({
  root: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
  },
}));

type Props = {
  status?: string;
};

const ProofItemStatusIcon: React.FC<Props> = ({ status }) => {
  const classes = useStyles();

  return <img className={classes.root} src={getIcon(status)} alt="Icon" />;
};

function getIcon(status?: string) {
  switch (status) {
    case ProofItemStatus.pendingApproval:
      return iconPending.default ?? iconPending;
    case ProofItemStatus.approved:
      return iconValid.default ?? iconValid;
    case ProofItemStatus.rejected:
    case ProofItemStatus.turnedDown:
      return iconInvalid.default ?? iconInvalid;
    case ProofItemStatus.cancelled:
      return iconCancelled.default ?? iconCancelled;
    default:
      return iconMissing.default ?? iconMissing;
  }
}

export default ProofItemStatusIcon;
