import { Icon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 72,
    height: 72,
    borderRadius: 12,
    backgroundColor: theme.palette.lightest.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '3em',
  },
}));

const OnboardingStepIcon = (props: any) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Icon className={classes.icon}>{props.icon}</Icon>
    </div>
  );
};

export default OnboardingStepIcon;
