import { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Video from './Video';
import { useNavigate } from 'react-router-dom';
import { NewsItem } from '__generated__/api';
import { NewsImageQuality } from '../../../config/constants';

const useStyles = makeStyles((theme) => ({
  img: {
    width: '100%',
    borderRadius: '8px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  },
  isLogo: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  videoIcon: {
    position: 'absolute',
    left: 16,
    bottom: 16,
  },
}));

function GetThumbnailUrl(item: NewsItem, thumbnailSize: Number = 400): string {
  return (
    item.thumbnail?.url ??
    `${item.image!.url}?w=${thumbnailSize}&h=${thumbnailSize}&q=${NewsImageQuality}`
  );
}

function GetImageUrl(item: NewsItem, size: Number = 1244): string {
  return `${item.image!.url}?w=${size}&h=${size}&q=${NewsImageQuality}`;
}

type Props = {
  newsItem: NewsItem;
  useThumbnail?: boolean;
  className?: string;
  width?: number;
  height?: number;
  children?: React.ReactNode;
};

const NewsCard: FC<Props> = (props) => {
  const classes = useStyles();
  const { newsItem, useThumbnail, className, children, width, height } = props;
  const navigate = useNavigate();

  const imageUrl = useThumbnail ? GetThumbnailUrl(newsItem) : GetImageUrl(newsItem);

  const onClickNewsItem = (newsArticle: NewsItem) => {
    navigate(`/news/${newsArticle.slug}`);
  };

  return (
    <div
      className={`${classes.img} ${className} ${newsItem.isLogo ? classes.isLogo : null}`}
      style={{ backgroundImage: `url("${imageUrl}")`, cursor: 'pointer', width, height }}
      onClick={() => onClickNewsItem(newsItem)}
    >
      {children}
      {newsItem.video && <Video src={newsItem.video!.url!} className={classes.videoIcon} />}
    </div>
  );
};

export default NewsCard;
