import { useQuery } from 'react-query';
import { getFunctionalitiesOverview } from 'api/functionalitiesOverview';

export function useQueryFunctionalitiesOverview() {
  const { data, isLoading, isError } = useQuery(['functionalities-overview'], () =>
    getFunctionalitiesOverview()
  );

  return {
    data,
    isLoading,
    isError,
  };
}
