import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Title = styled(Typography)({
  marginBottom: '16px',
});

export const SubTitle = styled(Typography)({
  fontSize: '12px',
});
