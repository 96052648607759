import { useQuery } from 'react-query';
import { http } from '../../http';
import { UserManual } from '../../__generated__/api';

async function getUserManual(): Promise<UserManual> {
  return http
    .get<UserManual>('/api/user-manual', { responseType: 'json' })
    .then((response) => {
      return response.data;
    });
}

async function getManual(): Promise<File | undefined> {
  const userManual = await getUserManual();
  if (!userManual.pdf?.url) {
    return undefined;
  }

  const response = await http(userManual.pdf?.url, {
    responseType: 'blob',
  });
  return new File(
    [new Blob([response.data, { type: 'application/pdf' }])],
    'Handleiding E-adviseur.pdf'
  );
}

export function useManual() {
  return useQuery(['manual/Handleiding E-adviseur.pdf'], getManual, { enabled: false });
}
