import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getTypeOfBenefitOptions } from 'screens/Mutations/constants';
import { FormInputDate, FormInputDropdown, FormInputCurrency } from 'components/Form';
import { Grid } from '@mui/material';

type Props = {
  index?: number;
  rootFormName?: string;
};

const SocialSecurity: FC<Props> = ({ index = 0, rootFormName }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const formIncomes = useWatch({ control, name: `${rootFormName || ''}incomes` });

  const income = formIncomes?.length ? formIncomes[index] : null;
  const typeOfBenefit = income?.socialSecurity?.typeOfBenefit;

  const prefix = `${rootFormName || ''}incomes.${index}.socialSecurity`;
  const testPrefix = `incomes.${index}`;

  return (
    <div data-testid="socialSecurity-fieldset">
      <FormInputDropdown
        name={`${prefix}.typeOfBenefit`}
        label={t('directMutations.income.socialSecurity.typeOfBenefit')}
        options={getTypeOfBenefitOptions()}
        placeholder={t('directMutations.income.socialSecurity.chooseTypeOfBenefit')}
        required
        testId={`${testPrefix}.typeOfBenefit`}
      />
      {typeOfBenefit && (
        <>
          <Grid container columnSpacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInputDate
                name={`${prefix}.startDate`}
                label={t('directMutations.income.socialSecurity.startDate')}
                required
                testId={`${testPrefix}.startDate`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInputDate
                name={`${prefix}.endDate`}
                label={t('directMutations.income.socialSecurity.endDate')}
                testId={`${testPrefix}.endDate`}
              />
            </Grid>
          </Grid>
          <FormInputCurrency
            name={`${prefix}.incomeAmount`}
            label={t('directMutations.income.socialSecurity.incomeAmount')}
            required
            testId={`${testPrefix}.incomeAmount`}
          />
        </>
      )}
    </div>
  );
};

export default SocialSecurity;
