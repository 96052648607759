import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { requestCsv } from '../../../api/csv';
import { useGroup } from 'use/group';

const useMutateRequestCsv = (onSuccess: () => void, onError: (error: AxiosError) => void) => {
  const group = useGroup();
  return useMutation<{}, AxiosError>(() => requestCsv(group), {
    onSuccess,
    onError,
  });
};
export default useMutateRequestCsv;
