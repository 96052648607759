import { useMutation } from 'react-query';
import { useGroup } from 'use/group';
import { saveOhaMutation } from 'api/oha';
import { goToNextStep as goToStep } from 'utils/goToStep/goToStep';
import { useNavigate, useLocation } from 'react-router-dom';
import { DismissalJointLiabilityMutationApplication } from '__generated__/api';
import { useDispatch } from 'react-redux';
import { setApplicationId } from 'store/ohaFormReducer';

type Props = {
  onSaveSuccess?: () => void;
  onSaveError?: (error: any) => void | Promise<unknown> | undefined;
};

export const useOhaFormStep = (props?: Props) => {
  const group = useGroup();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentStep = location.pathname.split('/').pop();

  const goToNextStep = () => goToStep(navigate, location.pathname, currentStep);

  const { mutateAsync, isLoading: isSaving } = useMutation(saveOhaMutation(group as string), {
    onSuccess: props?.onSaveSuccess,
    onError: props?.onSaveError,
  });

  const save = async (mutation: DismissalJointLiabilityMutationApplication) => {
    const result = await mutateAsync(mutation);

    if (result.data && result.data.application) {
      dispatch(setApplicationId(result.data.application.id!));
    }
  };

  return { save, isSaving, goToNextStep };
};
