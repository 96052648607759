const schema = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Buy to let application',
  type: 'object',
  properties: {
    applicantName: {
      type: 'string',
      description: 'The name of the applicant',
      $comment: 'buy_to_let_validation_applicantName',
      minLength: 1,
    },
    extraInformation: {
      type: 'string',
      description: 'The last name of the applicant',
      $comment: 'buy_to_let_validation_extraInformation',
      maxLength: 500,
    },
    XLSX: {
      type: 'string',
      contentEncoding: 'base64',
      contentMediaType: 'file/xlsx',
      description: 'The request form as XLSX',
    },
    PDF: {
      type: 'string',
      contentEncoding: 'base64',
      contentMediaType: 'file/pdf',
      description: 'The request form as PDF',
    },
  },
  required: ['applicantName'],
};

export default schema;
