import React, { FC } from 'react';
import PdfViewer from './PdfViewer';
import PngViewer from './PngViewer';
import TifViewer from './TifViewer';
import XlsxViewer from './XlsxViewer';

type Props = {
  file: File | string | undefined;
  scale: number;
  hasFooter?: boolean;
};

const fileType = (file: File | string) => {
  const fileExtension =
    (file as any)?.data?.resource?.extension?.toUpperCase() ?? (file as any)?.type?.toUpperCase();

  if (fileExtension === 'PNG' || fileExtension === 'IMAGE/PNG') {
    return 'PNG';
  }

  if (fileExtension === 'PDF' || fileExtension === 'APPLICATION/PDF') {
    return 'PDF';
  }

  if (
    fileExtension === 'TIF' ||
    fileExtension === 'IMAGE/TIF' ||
    fileExtension === 'TIFF' ||
    fileExtension === 'IMAGE/TIFF'
  ) {
    return 'TIF';
  }

  const data = (file as any)?.data;

  if (
    fileExtension === 'APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.SPREADSHEETML.SHEET' ||
    fileExtension === 'XLSX'
  ) {
    return 'XLSX';
  }

  if (data != null && data.substring && data.substring(1, 4) === 'PNG') {
    return 'PNG';
  }

  if (data != null && data.substring && data.substring(1, 4) === 'TIF') {
    return 'TIF';
  }

  return 'PDF';
};

const FileViewer: FC<Props> = ({ file, scale, hasFooter }) => {
  if (!file) {
    return null;
  }

  if (fileType(file) === 'PNG') {
    return <PngViewer file={file} />;
  } if (fileType(file) === 'TIF') {
    return <TifViewer file={file} scale={scale} />;
  } if (fileType(file) === 'XLSX') {
    return <XlsxViewer file={file} />; // to do: decide if/how to display xlsx files
  }
    return <PdfViewer file={file} scale={scale} hasFooter={hasFooter} />;
};

export default FileViewer;
