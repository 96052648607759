const conditionals = [
  {
    if: {
      not: {
        properties: {
          totalValueOfRentalCollateralsOwned: {
            const: 'null',
          },
        },
      },
    },
    then: {
      properties: {
        numberOfRentalCollateralsOwned: {
          type: 'integer',
          minimum: 1,
        },
      },
      required: ['numberOfRentalCollateralsOwned'],
    },
  },
  {
    if: {
      not: {
        properties: {
          numberOfRentalCollateralsOwned: {
            const: 'null',
          },
        },
      },
    },
    then: {
      properties: {
        totalValueOfRentalCollateralsOwned: {
          type: 'number',
          minimum: 1,
        },
      },
      required: ['totalValueOfRentalCollateralsOwned'],
    },
  },
];

export default conditionals;
