const conditionalFieldsValidations = [
  {
    if: {
      properties: {
        hasExistingMortgageByMoneylender: {
          const: true,
        },
      },
    },
    then: {
      properties: {
        existingLoanNumber: {
          type: 'integer',
        },
      },
      required: ['existingLoanNumber'],
    },
  },
  {
    if: {
      properties: {
        isMortgageRejected: {
          const: true,
        },
      },
    },
    then: {
      properties: {
        reasonMortgageRejection: {
          type: 'string',
          minLength: 5,
          maxLength: 600,
        },
      },
      required: ['reasonMortgageRejection'],
    },
  },
];
export default conditionalFieldsValidations;
