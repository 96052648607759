import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

const useUnregisterFields = () => {
  const { unregister, clearErrors } = useFormContext();

  const unregisterMultiple = useCallback(
    (fieldArray: string[]) => {
      unregister(fieldArray);
      clearErrors(fieldArray);
    },
    [clearErrors, unregister]
  );

  return unregisterMultiple;
};

export default useUnregisterFields;
