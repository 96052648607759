import { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import formatDate from 'utils/formatDate';
import CategoryNews from './CategoryNews';
import { NewsItem } from '__generated__/api';

const useStyles = makeStyles((theme) => ({
  date: {
    marginTop: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  span: {
    display: 'flex',
    alignItems: 'baseline',
    margin: theme.spacing(1, 0),
  },
}));

type Props = {
  newsItem: NewsItem;
};

const SubInfoNews: FC<Props> = ({ newsItem }) => {
  const classes = useStyles();

  return (
    <span className={classes.span}>
      <div className={classes.date}>{formatDate(newsItem.dateCreated!)}</div>
      <CategoryNews category={newsItem.category!} />
    </span>
  );
};

export default SubInfoNews;
