import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CollateralFormType,
  ContactDetailsFormType,
  CostAndResourcesFormType,
  IncomeFormType,
  LoanFormType,
  ToggleType,
  ObligationFormType,
  Option,
  OHAForm,
  PersonalInfoFormType,
  RootFormName,
  AdditionalInfoFormType,
} from 'screens/Mutations/utils/types';
import { IntermediaryReadDto } from '__generated__/api';
import { OHAFormDefaultValues } from '../screens/Mutations/components/DismissalJointLiabilityMutation/constants';

const initialState = {
  ...OHAFormDefaultValues,
};

const rootSlice = createSlice({
  name: 'ohaForm',
  initialState,
  reducers: {
    setOHAForm: (
      state,
      {
        payload: {
          currentApplicant,
          collateral,
          costAndResources,
          loan,
          loanNumber,
          newApplicant,
          commercialLabel,
          id,
          reference,
          intermediary,
          moneylenderName,
          additionalInformation,
        },
      }: PayloadAction<OHAForm>
    ) => {
      state.id = id;
      state.reference = reference;
      state.currentApplicant = { ...currentApplicant };
      state.newApplicant = { ...newApplicant };
      state.loan = { ...loan };
      state.collateral = { ...collateral };
      state.additionalInformation = additionalInformation;
      state.costAndResources = { ...costAndResources };
      state.intermediary = { ...intermediary };
      state.moneylenderName = moneylenderName;
      state.commercialLabel = commercialLabel;
      state.loanNumber = loanNumber;
    },
    savePrefill: (state, action: PayloadAction<OHAForm>) => {
      state.currentApplicant = { ...action.payload.currentApplicant };
      state.loan = { ...action.payload.loan };
      state.loan.currentLoanParts = action.payload.loan.currentLoanParts.map((loanPart) => ({
        ...loanPart,
      }));
    },
    saveLoanNumber: (state, action: PayloadAction<number>) => {
      state.loan.loanNumber = action.payload;
    },
    saveCommercialLabel: (state, action: PayloadAction<string>) => {
      state.commercialLabel = action.payload;
    },
    saveMoneyLender: (state, action: PayloadAction<string>) => {
      state.moneylenderName = action.payload;
    },
    setApplicationId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    saveIntermediary: (state, action: PayloadAction<IntermediaryReadDto>) => {
      state.intermediary = action.payload;
    },
    savePersonalInfo: (
      state,
      action: PayloadAction<{
        formData: PersonalInfoFormType;
        rootFormName: RootFormName;
      }>
    ) => {
      if (action.payload.rootFormName === 'newApplicant') {
        state.newApplicant.personalInfo = action.payload.formData;
      }
      if (action.payload.rootFormName === 'currentApplicant') {
        state.currentApplicant.personalInfo = action.payload.formData;
      }
    },
    saveContactDetails: (
      state,
      action: PayloadAction<{
        formData: ContactDetailsFormType;
        rootFormName: RootFormName;
      }>
    ) => {
      if (action.payload.rootFormName === 'newApplicant') {
        state.newApplicant.contactDetails = action.payload.formData;
      }
      if (action.payload.rootFormName === 'currentApplicant') {
        state.currentApplicant.contactDetails = action.payload.formData;
      }
    },
    saveIncome: (
      state,
      action: PayloadAction<{
        formData: IncomeFormType;
        rootFormName: RootFormName;
      }>
    ) => {
      const { rootFormName, formData } = action.payload;
      const { id, incomes } = formData;

      state[rootFormName].income = {
        ...state[rootFormName].income,
        id,
        incomes: incomes.map(
          ({ typeOfIncome, entrepreneur, socialSecurity, pension, employment, otherIncome }) => ({
            typeOfIncome,
            entrepreneur: entrepreneur ? { ...entrepreneur } : undefined,
            socialSecurity: socialSecurity ? { ...socialSecurity } : undefined,
            pension: pension ? { ...pension } : undefined,
            employment: employment ? { ...employment } : undefined,
            otherIncome: otherIncome ? { ...otherIncome } : undefined,
          })
        ),
      };
    },
    saveObligation: (
      state,
      action: PayloadAction<{
        formData: ObligationFormType;
        rootFormName: RootFormName;
      }>
    ) => {
      const { rootFormName, formData } = action.payload;
      const { id, obligations } = formData;

      state[rootFormName].obligation = {
        ...state[rootFormName].obligation,
        id,
        obligations: obligations.map((obligation) => ({
          ...obligation,
        })),
      };
    },
    saveCollateral: (state, action: PayloadAction<CollateralFormType>) => {
      state.collateral = action.payload;
    },
    saveLoan: (state, action: PayloadAction<LoanFormType>) => {
      state.loan = action.payload;
      state.loan.policies = action.payload.policies?.map((policy) => ({
        ...policy,
        policyHolders: { ...policy.policyHolders },
        insuredPersons: { ...policy.insuredPersons },
      }));
      state.loan.currentLoanParts = action.payload.currentLoanParts.map((loanPart) => ({
        ...loanPart,
        convertLoan: { ...loanPart.convertLoan },
        splitLoan: loanPart.splitLoan?.map((splitLoan) => ({ ...splitLoan })),
        policies: loanPart.policies?.map((policy) => ({
          ...policy,
          policyHolders: { ...policy.policyHolders },
          insuredPersons: { ...policy.insuredPersons },
        })),
      }));
      state.loan.newLoanParts = action.payload.newLoanParts.map((loanPart) => ({
        ...loanPart,
      }));
    },
    resetForm: () => initialState,
    saveHasNewApplicant: (state, action: PayloadAction<ToggleType>) => {
      state.newApplicant.personalInfo.hasNewApplicant = action.payload;
    },
    saveCostAndResources: (state, action: PayloadAction<CostAndResourcesFormType>) => {
      state.costAndResources = action.payload;
    },
    saveProductsData: (state, action: PayloadAction<Option[]>) => {
      state.productOptions = action.payload;
    },
    saveAdditionalInformation: (state, action: PayloadAction<AdditionalInfoFormType>) => {
      state.additionalInformation = action.payload;
    },
    setResultOhaMutation: (state, action: PayloadAction<string | undefined>) => {
      state.result = action.payload;
    },
  },
});

export default rootSlice.reducer;

export const {
  setOHAForm,
  saveLoanNumber,
  saveCommercialLabel,
  saveMoneyLender,
  setApplicationId,
  saveIntermediary,
  savePersonalInfo,
  saveContactDetails,
  saveIncome,
  saveObligation,
  resetForm,
  saveHasNewApplicant,
  saveCollateral,
  saveLoan,
  saveCostAndResources,
  saveProductsData,
  saveAdditionalInformation,
  savePrefill,
  setResultOhaMutation,
} = rootSlice.actions;
