import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from './components/Form';
import {
  useGetNotificationPreference,
  useSaveNotificationPreference,
} from './hooks/useNotificationPreference';
import { NotificationPreferenceFormType } from '../../types/NotificationPreference';
import { useTranslation } from 'react-i18next';
import useCustomRouterPrompt from 'use/useCustomRouterPrompt';
import { ConfirmationDialog } from 'components/CustomDialog';
import LoadingIndicator from 'components/LoadingIndicator';
import { notificationPreferenceSchema } from './schema/notificationPreferenceSchema';
import HeaderTemplate from 'components/Templates/Header/HeaderTemplate';
import { useDispatch } from 'react-redux';
import { setResetClientFilters } from 'store/genericClientListFiltersReducer';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

const NotificationPreference = () => {
  const { t } = useTranslation();
  const [snackbar, setSnackbar] = useState<{ status: string; name: string }>();
  const formMessages = {
    emailType: t('notificationPreference.form.email'),
    emailRequired: t('notificationPreference.form.required'),
  };
  const formMethods = useForm<NotificationPreferenceFormType>({
    defaultValues: {
      contactDetails: { emailAddress: null },
      notifications: [],
    },
    resolver: yupResolver(notificationPreferenceSchema(formMessages)),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setResetClientFilters());
  }, [dispatch]);

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = formMethods;

  const onSuccessSave = () => {
    setSnackbar({ status: 'success', name: 'savePreference' });
    reset(watch());
  };

  const onErrorSave = () => {
    setSnackbar({ status: 'error', name: 'savePreference' });
  };

  const { data, isLoading } = useGetNotificationPreference();
  const { mutateAsync } = useSaveNotificationPreference(onSuccessSave, onErrorSave);
  const { handleRoute, showPrompt } = useCustomRouterPrompt(isDirty);
  const handleOnClose = () => setSnackbar(undefined);

  useEffect(() => {
    // loading the api data into the form
    reset(data);

    return () => {};
  }, [data, reset]);

  const onSubmit = async (formData: any) => {
    isDirty && mutateAsync(formData).catch(() => {});
  };

  const onNavigate = () => {
    handleRoute(true);
  };

  const onCancel = () => {
    handleRoute(false);
  };

  const handleConfirmationAndSave = async () => {
    mutateAsync(watch()).catch(() => {});
    onNavigate();
  };
  return (
    <>
      <HeaderTemplate sectionName={t('general.header.menu.notification-preference')} />
      <FormProvider {...formMethods}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)} />
        )}
      </FormProvider>

      {snackbar ? (
        <CustomSnackbar
          isOpen={!!snackbar}
          handleClose={handleOnClose}
          severity={snackbar.status === 'success' ? 'success' : 'error'}
          message={t(`notificationPreference.snackbar.${snackbar.name}.${snackbar.status}`)}
        />
      ) : null}

      <ConfirmationDialog
        isOpen={showPrompt}
        onConfirmation={handleConfirmationAndSave}
        onDecline={onNavigate}
        onClose={onCancel}
        confirmationText={t('directMutations.confirmSaveOnExit.confirm')}
        declineText={t('directMutations.confirmSaveOnExit.decline')}
        bodyText={t('directMutations.confirmSaveOnExit.body')}
      />
    </>
  );
};
export default NotificationPreference;
