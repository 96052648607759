import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import {
  FormInputText,
  FormInputDate,
  FormInputDropdown,
  FormToggleButtonGroup,
} from 'components/Form';
import { FieldsGrid, SubTitle } from 'screens/Mutations/molecules/styled';
import FormTitleText from 'components/Form/FormTitle';
import { RaiseForm } from '../../types';
import { genders, maritalStatus } from 'screens/Mutations/constants';
import { applicantName } from 'screens/Mutations/utils';

type Props = {
  index?: number;
};

const PersonalInfo: FC<Props> = ({ index = 0 }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<RaiseForm>();

  const applicant = watch(`applicants.${index}`);

  if (!applicant) return null;

  // adding a prefix is necessary for saving data
  // without this prefix it will be added to the root object
  const prefix = `applicants.${index}.personalInfo.`;

  return (
    <section data-testid="personal-info">
      <FormTitleText
        title={applicantName(applicant) || t('directMutations.stepper.newApplicant')}
        description={t('directMutations.personalInfo.subtitle')}
      />
      <SubTitle>{t('directMutations.personalInfo.formTitle')}</SubTitle>

      <FieldsGrid key={index} container>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <FormInputText
              name={`${prefix}initials`}
              label={t('directMutations.personalInfo.initials')}
              required
              maxLength={15}
              testId="initials"
            />
          </Grid>
          <Grid item xs={9}>
            <FormInputText
              name={`${prefix}firstNames`}
              label={t('directMutations.personalInfo.firstNames')}
              maxLength={40}
              required
              testId="firstNames"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <FormInputText
              name={`${prefix}insertion`}
              label={t('directMutations.personalInfo.insertion')}
              maxLength={15}
              testId="insertion"
            />
          </Grid>
          <Grid item xs={9}>
            <FormInputText
              name={`${prefix}surname`}
              label={t('directMutations.personalInfo.surname')}
              maxLength={40}
              required
              testId="surname"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormInputDate
              name={`${prefix}dateOfBirth`}
              label={t('directMutations.personalInfo.dateOfBirth')}
              required
              testId="dateOfBirth"
              disableFuture
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormToggleButtonGroup
              name={`${prefix}gender`}
              label={t('directMutations.personalInfo.gender')}
              options={genders}
              required
              testId="gender"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormInputDropdown
              name={`${prefix}maritalStatus`}
              label={t('directMutations.personalInfo.conversionRaise.maritalStatus')}
              options={maritalStatus}
              placeholder="Kies burgerlijke staat"
              required
              testId="maritalStatus"
            />
          </Grid>
        </Grid>
      </FieldsGrid>
    </section>
  );
};

export default PersonalInfo;
