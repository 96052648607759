import {
  EmploymentIncome,
  EntrepreneurIncome,
  Income,
  OtherIncome,
  SocialSecurityIncome,
  TypeOfIncome,
} from 'screens/Mutations/utils/types';
import { sum } from 'utils/array';
import { TypeOfEmployment } from '__generated__/api';

const sumAll = (arrayOfNumbers: number[]) => {
  return arrayOfNumbers.reduce((acc, cur) => acc + cur);
};

const toNumber = (n?: number | null) => Number(n || 0);

const getIncomeLastThreeYears = (
  income1?: number | null,
  income2?: number | null,
  income3?: number | null
) => {
  const incomeLastYear = toNumber(income1);
  const average = sum([incomeLastYear, toNumber(income2), toNumber(income3)]) / 3;

  return incomeLastYear < average ? incomeLastYear : average;
};

const getEntrepreneurIncome = ({
  hasIncomeStatement,
  incomeAmount,
  incomePreviousFirstYear,
  incomePreviousSecondYear,
  incomePreviousThirdYear,
}: EntrepreneurIncome) => {
  if (hasIncomeStatement === 'yes') return toNumber(incomeAmount);

  return getIncomeLastThreeYears(
    incomePreviousFirstYear,
    incomePreviousSecondYear,
    incomePreviousThirdYear
  );
};

const getSocialSecurityIncome = ({ incomeAmount }: SocialSecurityIncome) => toNumber(incomeAmount);

const getPensionIncome = ({ incomeAmount }: SocialSecurityIncome) => toNumber(incomeAmount);

const getEmploymentIncome = ({
  typeOfEmployment,
  grossSalary,
  incomeAmount,
  flexibleAnnualIncome1,
  flexibleAnnualIncome2,
  flexibleAnnualIncome3,
  holidayAllowance,
  irregularHoursAllowance,
  lumpSumFee,
  overtimeAllowance,
  thirteenthMonth,
  yearEndBonus,
}: EmploymentIncome) => {
  if (
    typeOfEmployment === TypeOfEmployment.FlexibleWithoutPerspective ||
    typeOfEmployment === TypeOfEmployment.FlexibleWithPerspective
  ) {
    return getIncomeLastThreeYears(
      flexibleAnnualIncome1,
      flexibleAnnualIncome2,
      flexibleAnnualIncome3
    );
  }
  if (typeOfEmployment === TypeOfEmployment.SocialInsuranceWage) {
    return toNumber(incomeAmount);
  }

  if (
    typeOfEmployment === TypeOfEmployment.Permanent ||
    typeOfEmployment === TypeOfEmployment.TemporaryWithIntent ||
    typeOfEmployment === TypeOfEmployment.TemporaryWithoutIntent
  ) {
    return sumAll([
      toNumber(holidayAllowance),
      toNumber(irregularHoursAllowance),
      toNumber(lumpSumFee),
      toNumber(overtimeAllowance),
      toNumber(thirteenthMonth),
      toNumber(yearEndBonus),
      toNumber(grossSalary),
      toNumber(incomeAmount)
   ]);
  }

  return toNumber(grossSalary);
};

const getOtherIncome = ({ incomeAmount }: OtherIncome) => toNumber(incomeAmount);

export const getIncomeAmount = (income?: Income) => {
  if (!income) return 0;

  const { typeOfIncome, entrepreneur, socialSecurity, pension, employment, otherIncome } = income;

  if (typeOfIncome === TypeOfIncome.Entrepreneur && entrepreneur)
    return getEntrepreneurIncome(entrepreneur);

  if (typeOfIncome === TypeOfIncome.SocialSecurity && socialSecurity)
    return getSocialSecurityIncome(socialSecurity);

  if (typeOfIncome === TypeOfIncome.Pension && pension) return getPensionIncome(pension);

  if (typeOfIncome === TypeOfIncome.Employment && employment)
    return getEmploymentIncome(employment);

  if (typeOfIncome === TypeOfIncome.Other && otherIncome) return getOtherIncome(otherIncome);

  return 0;
};
