import { FC, useCallback } from 'react';
import { FormLabel, Switch } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { FormInputProps } from '../FormInputProps';
import { useGoogleTagManager } from 'use/gtm';

const FormInputSwitch: FC<FormInputProps> = ({ name, label = '', shouldUnregister = false }) => {
  const { control } = useFormContext();
  const googleTagManager = useGoogleTagManager();
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    shouldUnregister,
  });

  const handleChange = useCallback(
    (e: any) => {
      googleTagManager({
        event: 'setPushNotification',
        notificationName: name,
      });
      onChange(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [googleTagManager, onChange]
  );

  return (
    <>
      <FormLabel data-testid="input-label">{label}</FormLabel>
      <Switch
        data-testid={name}
        color="success"
        checked={value}
        value={value}
        onChange={(e) => handleChange(e)}
      />
    </>
  );
};

export default FormInputSwitch;
