import { mapEnum, nullableEnum } from 'utils/global';
import { TypeOfCollateral, TypeOfLeasehold } from '__generated__/api';
import { allowedTypesOfRealEstate, allowedEnergyClasses } from '../enums/realEstateEnums';
import { schemaDraft07 } from './constants';
import { maximumNumber } from '../constants';

const schema = {
  $schema: schemaDraft07,
  $id: 'https://e-adviseur.stater.com/buytolet/real-estate-existing',
  title: 'Real estate',
  type: 'object',
  properties: {
    typeOfCollateral: {
      enum: mapEnum(TypeOfCollateral),
    },
    typeOfRealEstate: {
      enum: allowedTypesOfRealEstate,
    },
    energyClass: {
      enum: nullableEnum(allowedEnergyClasses),
    },
    street: {
      type: 'string',
      minLength: 0,
      maxLength: 100,
    },
    houseNumber: {
      type: 'integer',
      minimum: 0,
      maximum: 99999,
    },
    houseNumberExtension: {
      type: ['string', 'null'],
      maxLength: 10,
    },
    postalCode: {
      type: 'string',
      minLength: 0,
      maxLength: 6,
      pattern: '^([1-9][0-9]{3}(?!sa|sd|ss)[a-zA-Z]{2}|.{0})$',
    },
    city: {
      type: 'string',
      minLength: 1,
      maxLength: 35,
    },
    purchaseAmount: {
      type: 'number',
      maximum: maximumNumber,
    },
    purchaseDate: {
      type: 'string',
      format: 'date',
    },
    hasLeasehold: {
      type: 'boolean',
    },
    typeOfLeasehold: {
      type: ['string', 'null'],
      enum: [...mapEnum(TypeOfLeasehold), null],
    },
    leaseholdAmount: {
      type: ['number', 'null'],
      maximum: maximumNumber,
    },
    isBeingRenovated: {
      type: ['boolean', 'null'],
    },
    renovationCosts: {
      type: ['number', 'null'],
      maximum: maximumNumber,
    },
    isRenovationDepotNecessary: {
      type: 'boolean',
    },
    renovationDepotAmount: {
      type: ['number', 'null'],
      maximum: maximumNumber,
    },
    nameOfTenant: {
      type: ['string', 'null'],
      minLength: 0,
      maxLength: 100,
    },
    basicAnnualRentalIncome: {
      type: ['number', 'null'],
      maximum: maximumNumber,
    },
    rentalValue: {
      type: 'number',
      maximum: maximumNumber,
    },
    marketValue: {
      type: 'number',
      maximum: maximumNumber,
    },
    rentalValueAfterRenovation: {
      type: ['number', 'null'],
      maximum: maximumNumber,
    },
    marketValueAfterRenovation: {
      type: ['number', 'null'],
      maximum: maximumNumber,
    },
    isRoomRental: {
      type: 'boolean',
    },
  },
  allOf: [
    {
      if: {
        properties: {
          hasLeasehold: {
            const: true,
          },
        },
      },
      then: {
        required: ['typeOfLeasehold'],
      },
    },
    {
      if: {
        properties: {
          isBeingRenovated: {
            const: true,
          },
        },
      },
      then: {
        properties: {
          renovationCosts: {
            type: 'number',
            min: 0,
          },
          rentalValueAfterRenovation: {
            type: 'number',
            min: 0,
          },
          marketValueAfterRenovation: {
            type: 'number',
            min: 0,
          },
        },
        allOf: [
          {
            if: {
              properties: {
                isRenovationDepotNecessary: {
                  const: true,
                },
              },
            },
            then: {
              properties: {
                renovationDepotAmount: {
                  type: 'number',
                },
              },
              required: ['renovationDepotAmount'],
            },
          },
        ],
        required: [
          'rentalValueAfterRenovation',
          'renovationCosts',
          'marketValueAfterRenovation',
          'isRenovationDepotNecessary',
        ],
      },
      else: {
        required: ['rentalValue', 'marketValue'],
      },
    },
  ],
  required: [
    'purchaseDate',
    'isRoomRental',
    'hasLeasehold',
    'rentalValue',
    'marketValue',
    'street',
    'houseNumber',
    'postalCode',
    'purchaseAmount',
    'typeOfCollateral',
    'typeOfRealEstate',
    'city',
  ],
};

export default schema;
