import { filteredLegalForms } from '../enums/legalForm.enum';
import applicantCollateralConditions from './applicantCollateralConditions';
import applicantCollateralFields from './applicantCollateralsFields';
import applicantExistingMortgageFields from './applicantExistingMortgageFields';
import applicantExistingMortgageFieldsConditionals from './applicantExistingMortgageFieldsConditionals';
import { schemaDraft07 } from './constants';

const schema = {
  $schema: schemaDraft07,
  $id: 'https://e-adviseur.stater.com/buytolet/applicant-legal-person',
  title: 'Applicant legal person',
  type: 'object',
  properties: {
    ...applicantExistingMortgageFields,
    companyName: {
      type: 'string',
      isNotEmpty: true,
      minLength: 1,
      maxLength: 100,
    },
    chamberOfCommerceNumber: {
      type: 'string',
      isNotEmpty: true,
      pattern: '^[0-9]{8}$',
    },
    legalForm: {
      enum: filteredLegalForms,
    },
    kindOfActivitiesInCompany: {
      type: 'string',
      isNotEmpty: true,
      minLength: 5,
      maxLength: 600,
    },
    companyStartDate: {
      type: 'string',
      format: 'date',
    },
    ...applicantCollateralFields,
  },
  allOf: [...applicantExistingMortgageFieldsConditionals, ...applicantCollateralConditions],
  required: [
    'hasExistingMortgageByMoneylender',
    'isMortgageRejected',
    'companyName',
    'chamberOfCommerceNumber',
    'legalForm',
    'companyStartDate',
    'kindOfActivitiesInCompany',
  ],
};

export default schema;
