import { Button as MuiButton, Fab, Theme } from '@mui/material';
import { styled } from '@mui/styles';

const Button = styled(MuiButton)({
  display: 'flex',
  flexWrap: 'wrap',
});
type FabButtonProps = {
  styletype?: string;
};

const FabButton = styled(Fab)<Theme, FabButtonProps>(({ theme, styletype }) => {
  if (styletype === 'button') {
    return {
      marginLeft: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(0),
      },
      minWidth: 150,
    };
  }
  if (styletype === 'submit') {
    return {
      'backgroundColor': theme.palette.success.main,
      'borderColor': theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    };
  }
  return {
    display: 'flex',
    flexWrap: 'wrap',
  };
});

export { Button, FabButton };
