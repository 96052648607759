import { useTranslation } from 'react-i18next';
import {
  Button,
  Icon,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { formatLoanNumber } from '../../utils/global';
import type { Notification } from '../../__generated__/api';
import { useGoogleTagManager } from 'use/gtm';
import formatDate from 'utils/formatDate';

const useStyles = makeStyles((theme) => ({
  new: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
  emphasize: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
  },
  small: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    display: 'block',
    paddingTop: theme.spacing(1),
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
}));

type NotificationListDesktopProps = {
  notifications?: Notification[];
  onClickView: (item: Notification) => void;
};

const NotificationListDesktop = (props: NotificationListDesktopProps) => {
  const { notifications, onClickView } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const googleTagManager = useGoogleTagManager();

  return (
    <TableContainer data-testid="notifications-table">
      <Table aria-label="notifications table">
        <colgroup>
          <col width="40px" />
          <col width="120px" />
          <col />
          <col width="125px" />
          <col width="125px" />
          <col width="170px" />
        </colgroup>
        <TableHead data-testid="notifications-table-header">
          <TableRow>
            <TableCell />
            <TableCell>{t('notifications.table.date')}</TableCell>
            <TableCell>{t('notifications.table.type')}</TableCell>
            <TableCell>{t('notifications.table.client')}</TableCell>
            <TableCell>{t('notifications.table.loanNumber')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        {notifications?.length === 0 ? (
          <TableBody>
            <TableRow data-testid="notifications-row">
              <TableCell data-testid="notifications-row-none" colSpan={6}>
                {t('notifications.table.none')}
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {notifications?.map((item, index) => (
              <TableRow
                data-testid="notifications-row"
                key={`${item.dateCreated}${item.consumerName}${index}`}
                hover
              >
                <TableCell data-testid="notifications-row-date">
                  {!item.dateAccessed && <div className={classes.new} />}
                </TableCell>
                <TableCell className={classes.emphasize}>{formatDate(item.dateCreated)}</TableCell>
                <TableCell
                  width="35%"
                  data-testid="notifications-row-type"
                  className={clsx({ [classes.emphasize]: !item.dateAccessed })}
                >
                  {item.name}
                </TableCell>
                <TableCell data-testid="notifications-row-customerName">
                  {item.consumerName || t('general.unknown')}
                </TableCell>
                <TableCell data-testid="notifications-row-loanNumber">
                  {item.loanNumber !== null && item.loanNumber !== undefined
                    ? formatLoanNumber(Number.parseInt(item.loanNumber, 10))
                    : t('general.unknown')}
                </TableCell>
                <TableCell>
                  <Button
                    data-testid="notifications-row-link"
                    color="primary"
                    variant="text"
                    endIcon={<Icon className={classes.btnIcon}>paperclip</Icon>}
                    onClick={() => {
                      onClickView(item);
                      googleTagManager({
                        event: 'ButtonClick',
                        btnName: 'Notification',
                      });
                    }}
                  >
                    {t('notifications.table.viewLetter')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default NotificationListDesktop;
