import { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DescriptionOutlined } from '@mui/icons-material';
import Zoom from 'components/DocumentViewer/Zoom';
import { fileSize } from 'utils/global';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    minHeight: 72,
    flexGrow: 1,
    boxShadow: theme.shadows[6],
    zIndex: 3,
  },
  headerLeft: {
    flexGrow: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  headerCenter: {
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: theme.spacing(1),
    color: theme.palette.grey[600],
    fontWeight: 'bold',
  },
  headerRight: {
    flexGrow: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: theme.palette.grey[600],
    fontWeight: 'bold',
  },
  headerIcon: {
    'margin': theme.spacing(0, 1, 0, 1),
    'color': theme.palette.grey[600],
    '&:hover': {
      color: theme.palette.grey[900],
    },
  },
  headerTitle: {
    flexGrow: 10,
  },
  closeIcon: {
    width: 24,
    height: 24,
  },
  closeButton: {
    'margin': theme.spacing(1, 3, 1, 1),
    'float': 'right',
    'backgroundColor': 'transparent',
    'borderColor': 'transparent',
    'color': theme.palette.grey[600],
    '&:hover': {
      color: theme.palette.grey[900],
      cursor: 'pointer',
    },
  },
  documentIcon: {
    width: 24,
    height: 24,
    margin: theme.spacing(1, 1, 1, 3),
    color: theme.palette.grey[600],
  },
}));

type Props = {
  fileInfo?: any;
  onZoom: (scale: number) => void;
  onClose: () => void;
  onDownloadPdf?: () => void;
  zoomVisible?: boolean;
};

const Header: FC<Props> = ({ fileInfo, zoomVisible, onZoom, onDownloadPdf, onClose }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.headerLeft}>
        <div className={classes.headerIcon}>
          <DescriptionOutlined className={classes.documentIcon} />
        </div>
        <div className={classes.headerTitle}>
          <Typography variant="h6">{fileInfo.name}</Typography>
          <span>{`${fileSize(fileInfo.size)} kB`}</span>
        </div>
      </div>

      <div className={classes.headerCenter}>{zoomVisible && <Zoom onZoom={onZoom} />}</div>

      <div className={classes.headerRight}>
        <button onClick={onClose} className={classes.closeButton} type="button" aria-label="close icon">
          <CloseIcon className={classes.closeIcon} />
        </button>
      </div>
    </div>
  );
};

export default Header;
