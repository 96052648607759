import { maximumNumber } from '../constants';
import { schemaDraft07 } from './constants';

const prefix = 'buy_to_let_validation_';
const schema = {
  $schema: schemaDraft07,
  $id: 'https://e-adviseur.stater.com/buytolet/loan',
  title: 'Loan schema',
  type: 'object',
  properties: {
    loanParts: {
      type: 'array',
    },
    totalPurchasePrice: {
      type: ['number', 'null'],
      maximum: maximumNumber,
      $comment: `${prefix}totalPurchasePrice`,
    },
    explanationTotalPurchasePrice: {
      type: ['null', 'string'],
      minLength: 5,
      maxLength: 600,
    },
    refinancingExistingMortgagesAmount: {
      type: ['number', 'null'],
      maximum: maximumNumber,
      $comment: `${prefix}refinancingExistingMortgagesAmount`,
    },
    explanationRefinancingExistingMortgagesAmount: {
      type: ['null', 'string'],
      minLength: 5,
      maxLength: 600,
    },
    totalCostBuyerAmount: {
      type: ['number', 'null'],
      maximum: maximumNumber,
      $comment: `${prefix}totalCostBuyerAmount`,
    },
    explanationTotalCostBuyerAmount: {
      type: ['null', 'string'],
      minLength: 5,
      maxLength: 600,
    },
    liquidityAmountToWithdraw: {
      type: ['number', 'null'],
      maximum: maximumNumber,
      $comment: `${prefix}liquidityAmountToWithdraw`,
    },
    explanationLiquidityAmountToWithdraw: {
      type: ['null', 'string'],
      minLength: 5,
      maxLength: 600,
    },
    otherCosts: {
      type: ['number', 'null'],
      maximum: maximumNumber,
      $comment: `${prefix}otherCosts`,
    },
    explanationOtherCosts: {
      type: ['null', 'string'],
      minLength: 5,
      maxLength: 600,
    },
    totalRenovationCosts: {
      type: ['number', 'null'],
      maximum: maximumNumber,
      $comment: `${prefix}totalRenovationCosts`,
    },
    explanationTotalRenovationCosts: {
      type: ['null', 'string'],
      minLength: 5,
      maxLength: 600,
    },
    amount: {
      type: ['number', 'null'],
      maximum: maximumNumber,
      $comment: `${prefix}amount`,
    },
    addedPersonalEquity: {
      type: ['number', 'null'],
      maximum: maximumNumber,
      $comment: `${prefix}addedPersonalEquity`,
    },
    explanationAddedPersonalEquity: {
      type: ['null', 'string'],
      minLength: 5,
      maxLength: 600,
    },
  },
  dependencies: {},
  allOf: [
    {
      properties: {
        explanationLiquidityAmountToWithdraw: {
          minLength: 1,
        },
      },
      required: ['liquidityAmountToWithdraw'],
    },
    {
      properties: {
        explanationTotalRenovationCosts: {
          minLength: 1,
        },
      },
      required: ['totalRenovationCosts'],
    },
    {
      properties: {
        explanationAddedPersonalEquity: {
          minLength: 1,
        },
      },
      required: ['addedPersonalEquity'],
    },
    {
      if: {
        not: {
          properties: {
            liquidityAmountToWithdraw: {
              type: 'null',
            },
          },
        },
      },
      then: {
        properties: {
          explanationLiquidityAmountToWithdraw: {
            type: 'string',
            minLength: 5,
            maxLength: 600,
            $comment: `${prefix}explanationLiquidityAmountToWithdraw`,
          },
        },
        required: ['explanationLiquidityAmountToWithdraw'],
      },
    },
    {
      if: {
        not: {
          properties: {
            totalRenovationCosts: {
              type: 'null',
            },
          },
        },
      },
      then: {
        properties: {
          explanationTotalRenovationCosts: {
            type: 'string',
            minLength: 5,
            maxLength: 600,
            $comment: `${prefix}explanationTotalRenovationCosts`,
          },
          totalRenovationCosts: {
            $comment: `${prefix}totalRenovationCosts`,
          },
        },
        required: ['explanationTotalRenovationCosts'],
      },
    },
    {
      if: {
        not: {
          properties: {
            addedPersonalEquity: {
              type: 'null',
            },
          },
        },
      },
      then: {
        properties: {
          explanationAddedPersonalEquity: {
            type: 'string',
            minLength: 5,
            maxLength: 600,
            $comment: `${prefix}explanationAddedPersonalEquity`,
          },
          addedPersonalEquity: {
            $comment: `${prefix}addedPersonalEquity`,
          },
        },
        required: ['explanationAddedPersonalEquity'],
      },
    },
  ],
  additionalProperties: true,
};

export default schema;
