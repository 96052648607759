import { JwtPayload } from '../types';
import jwt from 'jwt-decode';

const getResourceAccessRoles = (token: string | null, clientId: string): string[] => {
  if (!token || !clientId) return [];

  try {
    const decoded = jwt<JwtPayload>(token);

    if (!decoded || !decoded.resource_access || !decoded.resource_access[clientId]) return [];

    return decoded.resource_access[clientId].roles;
  } catch (e) {
    return [];
  }
};

export default getResourceAccessRoles;
