import { formatAddress } from 'utils/formatAddress';
import { CaseDetails } from '__generated__/api-di';

const getCaseAddress = ($case?: CaseDetails) => {
  if (!$case || !$case.addresses || !$case.addresses.length) return '';

  const [firstAddress] = $case.addresses;

  return formatAddress(firstAddress.contactData);
};

export default getCaseAddress;
