import type { FC } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  FormInputPhoneNumber,
  FormInputNumber,
  FormInputText,
  FormInputDropdown,
} from 'components/Form';
import { FieldsGrid, SubTitle } from 'screens/Mutations/molecules/styled';
import { countryOptions } from 'screens/Mutations/constants';
import FormTitleText from 'components/Form/FormTitle';

export type ContactDetailsProps = {
  title: string;
  subtitle: string;
  name: string;
  rootFormName?: string;
};

export const ContactDetails: FC<ContactDetailsProps> = ({
  rootFormName = '',
  title,
  subtitle,
  name,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FormTitleText title={name || t(`${title}`)} description={t(subtitle)} />
      <SubTitle variant="h5">{t('directMutations.contactDetails.formTitle')}</SubTitle>
      <FieldsGrid container data-testid="contact-details-fieldset">
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <FormInputText
              name={`${rootFormName}street`}
              label={t('directMutations.contactDetails.street')}
              maxLength={40}
              required
              testId="street"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormInputNumber
              name={`${rootFormName}houseNumber`}
              label={t('directMutations.contactDetails.houseNumber')}
              min={0}
              max={9999999999}
              required
              testId="houseNumber"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormInputText
              name={`${rootFormName}houseNumberSuffix`}
              label={t('directMutations.contactDetails.houseNumberSuffix')}
              maxLength={5}
              testId="houseNumberSuffix"
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid item xs={4}>
            <FormInputText
              name={`${rootFormName}postalCode`}
              label={t('directMutations.contactDetails.postalCode')}
              maxLength={15}
              required
              testId="postalCode"
            />
          </Grid>
          <Grid item xs={8}>
            <FormInputText
              name={`${rootFormName}city`}
              label={t('directMutations.contactDetails.city')}
              maxLength={40}
              required
              testId="city"
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid item xs={12}>
            <FormInputDropdown
              name={`${rootFormName}country`}
              label={t('directMutations.contactDetails.country')}
              options={countryOptions()}
              placeholder={t('directMutations.contactDetails.chooseCountry')}
              required
              testId="country"
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid item xs={12}>
            <FormInputPhoneNumber
              name={`${rootFormName}phoneNumber`}
              label={t('directMutations.contactDetails.phoneNumber')}
              testId="phoneNumber"
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid item xs={12}>
            <FormInputText
              name={`${rootFormName}email`}
              label={t('directMutations.contactDetails.email')}
              required
              maxLength={254}
              testId="email"
            />
          </Grid>
        </Grid>
      </FieldsGrid>
    </>
  );
};

export default ContactDetails;
