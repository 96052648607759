import React, { FC, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Grid, Icon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BtlApplicant, BtlApplication } from 'types/BtlFormApplication';
import TextInput from 'screens/BuyToLet/BuyToLetForm/form/TextInput';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { mapEnum } from 'utils/global';
import { Country } from '__generated__/api';
import { SelectOption } from 'types/SelectOption';
import DropDownQuestion from 'screens/BuyToLet/BuyToLetForm/form/DropDownQuestion';
import IntegerNumberField from 'lib/IntegerNumberField/IntegerNumberField';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/nl.json'));

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      display: 'flex',
    },
  },
  header: {
    height: 72,
    padding: theme.spacing(2, 4),
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    boxShadow: '0 6px 12px 0 rgba(22,20,47,0.12)',
    position: 'relative',
    zIndex: 1300,
  },
  logo: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(1),
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
  },
  gridContainer: {
    marginTop: theme.spacing(4),
  },
  description: {
    marginTop: theme.spacing(2),
    color: theme.palette.textBody.main,
  },
  formGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    maxWidth: 600,
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
  createButton: {
    marginTop: theme.spacing(2),
    maxWidth: '200px',
  },
  menuItem: {
    'display': 'flex',
    'height': 50,
    'paddingLeft': theme.spacing(2),
    'paddingRight': theme.spacing(2),
    'color': theme.palette.text.secondary,
    '&:hover, &.Mui-selected:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette.lightest.light,
    },
  },
}));

export type BuyToLetContactDataProps = {
  showAddApplicantButton: boolean;
  addApplicant: () => void;
  currentApplicantIndex: number;
  application: BtlApplication;
  updateContactData: (data: BtlApplicant, index: number) => void;
  errorMessage: (id: string, category: string) => React.ReactNode;
};

const BuyToLetContactData: FC<BuyToLetContactDataProps> = ({
  showAddApplicantButton,
  addApplicant,
  application,
  currentApplicantIndex,
  updateContactData,
  errorMessage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const applicant = application.applicants[currentApplicantIndex];
  const form = 'buyToLetForm.contactData';
  const category = 'contactData';

  useEffect(() => {
    if (applicant.postalCode) {
      let value;
      if (applicant.country === Country.NLD) {
        value = applicant.postalCode.replace(' ', '');
      } else {
        value = applicant.postalCode;
      }
      const updatedApplicant = {
        ...applicant,
        postalCode: value,
      };
      updateContactData(updatedApplicant, currentApplicantIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicant.country]);

  const handleTextChange = (event: any) => {
    let value = event.target.value;
    if (event.target.name === 'houseNumber') {
      value = parseInt(value || '', 10);
    }
    if (event.target.name === 'postalCode') {
      value = value.toUpperCase().replace('-', '');
      if (applicant.country === Country.NLD) {
        value = value.replace(' ', '');
      }
    }
    const updatedApplicant = {
      ...applicant,
      [event.target.name]: value,
    };
    updateContactData(updatedApplicant, currentApplicantIndex);
  };

  const translatedCountries: SelectOption[] = mapEnum(Country)
    .map((country: string) => ({
      value: country,
      label: country && i18nIsoCountries.getName(country, 'nl'),
    }))
    .filter((country: SelectOption) => country.value !== Country.NLD)
    .sort((a: SelectOption, b: SelectOption) => (a.label > b.label ? 1 : -1));

  const countriesList: SelectOption[] = [
    { value: Country.NLD, label: 'Nederland' },
    ...translatedCountries,
  ];

  return (
    <>
      <form>
        <Typography className={classes.description} variant="body1">
          {t(`${form}.description`)}
        </Typography>
        <Grid container spacing={3} className={classes.formGrid}>
          <Grid container item spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextInput
                label={t(`${form}.street`)}
                required
                name="street"
                onChange={handleTextChange}
                value={applicant.street || ''}
                placeholder={t(`${form}.street`)}
                inputProps={{ maxLength: 100 }}
                error={Boolean(errorMessage('.street', category))}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <IntegerNumberField
                label={t(`${form}.houseNumber`)}
                containerWidth={12}
                required
                max={99999}
                name="houseNumber"
                textAlign="left"
                onChange={handleTextChange}
                value={applicant.houseNumber || ''}
                placeholder={t(`${form}.houseNumber`)}
                error={Boolean(errorMessage('.houseNumber', category))}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextInput
                label={t(`${form}.houseNumberExtension`)}
                name="houseNumberExtension"
                onChange={handleTextChange}
                value={applicant.houseNumberExtension || ''}
                inputProps={{ maxLength: 10 }}
                placeholder={t(`${form}.houseNumberExtension`)}
                error={Boolean(errorMessage('.houseNumberExtension', category))}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} sm={4}>
              <TextInput
                label={t(`${form}.postalCode`)}
                required
                name="postalCode"
                onChange={handleTextChange}
                value={applicant.postalCode || ''}
                placeholder={t(`${form}.postalCode`)}
                inputProps={{ maxLength: 12 }}
                error={Boolean(errorMessage('.postalCode', category))}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextInput
                label={t(`${form}.city`)}
                required
                name="city"
                onChange={handleTextChange}
                value={applicant.city || ''}
                inputProps={{ maxLength: 35 }}
                placeholder={t(`${form}.city`)}
                error={Boolean(errorMessage('.city', category))}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12}>
            <DropDownQuestion
              key="country"
              name="country"
              required
              label={t(`${form}.country`)}
              answerOptions={countriesList.map((x) => ({
                id: x.value,
                answerText: x.label,
              }))}
              value={applicant.country || ''}
              onChange={handleTextChange}
              error={Boolean(errorMessage('.country', category))}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">{t(`${form}.contactData`)}</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextInput
              label={t(`${form}.privatePhoneNumber`)}
              name="privatePhoneNumber"
              type="number"
              onInput={(e: any) => {
                e.target.value = e.target.value.toString().slice(0, 10);
              }}
              onChange={handleTextChange}
              value={applicant.privatePhoneNumber || ''}
              placeholder={t(`${form}.privatePhoneNumber`)}
              error={Boolean(errorMessage('.privatePhoneNumber', category))}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              label={t(`${form}.mobilePhoneNumber`)}
              name="mobilePhoneNumber"
              required
              type="number"
              onInput={(e: any) => {
                e.target.value = e.target.value.toString().slice(0, 10);
              }}
              onChange={handleTextChange}
              value={applicant.mobilePhoneNumber || ''}
              placeholder={t(`${form}.mobilePhoneNumber`)}
              error={Boolean(errorMessage('.mobilePhoneNumber', category))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={t(`${form}.workPhoneNumber`)}
              name="workPhoneNumber"
              type="number"
              onInput={(e: any) => {
                e.target.value = e.target.value.toString().slice(0, 10);
              }}
              onChange={handleTextChange}
              value={applicant.workPhoneNumber || ''}
              placeholder={t(`${form}.workPhoneNumber`)}
              error={Boolean(errorMessage('.workPhoneNumber', category))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={t(`${form}.emailAddress`)}
              required
              name="emailAddress"
              type="email"
              onChange={handleTextChange}
              value={applicant.emailAddress || ''}
              placeholder={t(`${form}.emailAddress`)}
              error={Boolean(errorMessage('.emailAddress', category))}
            />
          </Grid>
        </Grid>
      </form>

      {showAddApplicantButton && (
        <Button
          variant="contained"
          color="primary"
          className={classes.createButton}
          onClick={addApplicant}
          endIcon={<Icon className={classes.btnIcon}>plus</Icon>}
        >
          {t(`${form}.addNewApplicant`)}
        </Button>
      )}
    </>
  );
};
export default BuyToLetContactData;
