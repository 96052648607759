import { hasValue } from '../global';
import {
  BtlApplication,
  BtlLoan,
  BtlRealEstate,
  BtlApplicant,
} from '../../types/BtlFormApplication';
import {
  ApplicantReadDto,
  ApplicationReadDto,
  LoanReadDto,
  RealEstateReadDto,
} from '../../__generated__/api';

export function toNewBtlApplication(application: ApplicationReadDto): BtlApplication {
  return {
    ...application,
    applicants: hasValue(application.applicants)
      ? application.applicants?.map((a) => toNewBtlApplicant(a))!
      : [],
    loan: hasValue(application.loan)
      ? toNewBtlLoan(application.loan!)
      : {
          loanParts: [],
        },
    realEstates: hasValue(application.realEstates)
      ? application.realEstates!.map((r) => toNewBtlRealEstate(r))
      : [],
  };
}

function toNewBtlLoan(loan: LoanReadDto): BtlLoan {
  return {
    ...loan,
    conveyanceDate: loan.conveyanceDate?.replace('T00:00:00', ''),
    loanParts: hasValue(loan.loanParts) ? loan.loanParts! : [],
  };
}

function toNewBtlApplicant(applicant: ApplicantReadDto): BtlApplicant {
  return {
    ...applicant,
    typeOfPermitWithNone: applicant.typeOfPermit === null ? 'None' : applicant.typeOfPermit,
    dateOfBirth: applicant.dateOfBirth?.replace('T00:00:00', ''),
    companyStartDate: applicant.companyStartDate?.replace('T00:00:00', ''),
  };
}

function toNewBtlRealEstate(realEstate: RealEstateReadDto): BtlRealEstate {
  // convert data from database to use in the UI components
  return {
    ...realEstate,
    purchaseDate: realEstate.purchaseDate?.replace('T00:00:00', ''),
    deliveryDate: realEstate.deliveryDate?.replace('T00:00:00', ''),
    isBeingRenovated: (realEstate?.renovationCosts ?? 0) > 0,
    isRenovationDepotNecessary: (realEstate?.renovationDepotAmount ?? 0) > 0,
    isNewlyBuildAddressKnown:
      realEstate?.buildingPlanNumber === null && realEstate?.situationNumber === null
        ? 'yes'
        : 'no',
  };
}
