import { Suspense, FC } from 'react';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { I18nProvider } from './i18n';
import './index.css';
import AppBase from './components/AppBase';
import LoadingIndicator from './components/LoadingIndicator';
import ErrorBoundary from './components/ErrorBoundary';
import { ReactQueryConfig } from './config/react-query';
import theme from './theme';
import AuthWrapper from './components/AuthWrapper';
import env from 'config/env';
import { getLoginType, isLoggedInAsObserver } from 'auth/utils';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import nl from 'date-fns/locale/nl';
import history from './history';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App: FC = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthWrapper loginType={getLoginType(env, isLoggedInAsObserver())}>
        <Suspense fallback={<LoadingIndicator />}>
          <I18nProvider>
            <ErrorBoundary>
              <ReactQueryConfig>
                <Router history={history as any}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
                    <AppBase />
                  </LocalizationProvider>
                </Router>
              </ReactQueryConfig>
            </ErrorBoundary>
          </I18nProvider>
        </Suspense>
      </AuthWrapper>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;
