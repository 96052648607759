import { FC } from 'react';
import { Button, Grid, Hidden, ListItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ChevronRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import SubInfoNews from './SubInfoNews';
import { NewsItem } from '__generated__/api';
import NewsCard from './NewsCard';

const useStyles = makeStyles((theme) => ({
  readArticleBtn: {
    color: theme.palette.primary.main,
    marginLeft: '-16px',
  },
  listItem: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 1),
  },
  arrowIcon: {
    marginLeft: theme.spacing(1),
  },
  imageSearchresult: {
    width: 184,
    height: 116,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  highlight: {
    backgroundColor: theme.palette.orange.light,
  },
  article: {
    marginTop: theme.spacing(-2),
  },
  categoryCard: {
    height: '150px',
  },
}));

type Props = {
  items: NewsItem[];
  handleClick: any;
  searchTerm: string | null;
};

const SearchedOnTitleResult: FC<Props> = ({ items, handleClick, searchTerm }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const term = searchTerm?.split(' ').join('|');

  const sortedItems = items
    .slice()
    .sort((a, b) => new Date(b.dateCreated!).getTime() - new Date(a.dateCreated!).getTime());

  const highlight = (text: string) => {
    const parts = text.split(new RegExp(`(${term})`, 'gi'));
    return (
      <>
        {parts.map((part, index) => (
          <span key={`${part}${index}`} className={index % 2 === 1 ? classes.highlight : ''}>
            {part}
          </span>
        ))}
      </>
    );
  };

  return (
    <>
      {sortedItems.map((item, index) => (
        <ListItem key={index} className={classes.listItem}>
          <Grid container justifyContent="space-between">
            <Hidden smUp>
              <Grid item xs={12}>
                <NewsCard newsItem={item} className={classes.imageSearchresult} />
              </Grid>
            </Hidden>
            <Grid item sm={4} className={classes.article}>
              <SubInfoNews newsItem={item} />
              <h3>{highlight(item.title!)}</h3>
              <Button
                variant="text"
                className={classes.readArticleBtn}
                onClick={() => {
                  handleClick(item);
                }}
                data-testid={index === 0 ? 'topresult.news.readFullArticle' : ''}
              >
                {t('news.readFullArticle')}
                <ChevronRight color="primary" className={classes.arrowIcon} />
              </Button>
            </Grid>
            <Hidden smDown>
              <Grid>
                <NewsCard newsItem={item} className={classes.imageSearchresult} />
              </Grid>
            </Hidden>
          </Grid>
        </ListItem>
      ))}
    </>
  );
};

export default SearchedOnTitleResult;
