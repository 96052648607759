import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useQueryNotifications } from 'use/notifications';
import { NextButton, NotificationTypography, NotificationGrid } from '../Home.styled';
import NotificationTile from './NotificationTile';
import NotificationCount from 'components/NotificationList/NotificationCount';

const HomeSectionNotifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useQueryNotifications();
  const notifications = data
    ?.sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime())
    .slice(0, 3);

  if (isLoading || isError || notifications?.length === 0) {
    return null;
  }

  return (
    <Grid container my={4} direction="column" alignItems="flex-start">
      <NotificationGrid container>
        <Grid>
          <NotificationTypography variant="overline" color="textSecondary">
            {t('home.notifications')}
          </NotificationTypography>
        </Grid>
        <Grid>
          <NotificationCount size="S" />
        </Grid>
      </NotificationGrid>
      <Grid container item xs={12}>
        {notifications?.map((item, index) => (
          <NotificationTile key={index} notification={item} />
        ))}
      </Grid>
      <NextButton
        color="primary"
        endIcon={<ChevronRight />}
        onClick={() => navigate('/notifications')}
      >
        {t('home.allNotifications')}
      </NextButton>
    </Grid>
  );
};

export default HomeSectionNotifications;
