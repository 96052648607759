import { JwtPayload } from '../types';
import jwt from 'jwt-decode';
import getResourceAccessRoles from './getResourceAccessRoles';

const KEYCLOAK = (window as any).REACT_APP?.KEYCLOAK ?? {};

const isLoggedInAsObserver = () => {
  const token = sessionStorage.getItem(KEYCLOAK.SESSION_STORAGE_KEY_OBSERVER);

  if (!token) return false;

  try {
    const decoded = jwt<JwtPayload>(token);

    // when logging in as observer,
    // the resource_access contains another client_id: nl.stater.eadviseur
    const roles = getResourceAccessRoles(token, KEYCLOAK.CLIENT_ID);

    const hasValidExp = !!decoded.exp && Date.now() < decoded.exp * 1000;
    const hasValidRole = roles.includes('observer');

    return hasValidExp && hasValidRole;
  } catch (e) {
    return false;
  }
};

export default isLoggedInAsObserver;
