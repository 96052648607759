import type { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TypeOfIncome } from 'screens/Mutations/utils/types';
import { formatIncome } from 'screens/Mutations/components/DismissalJointLiabilityMutation/utils';
import { AmountSubTitle, AccordionSubTitle } from 'screens/Mutations/molecules/styled';

type Props = {
  typeOfIncome?: TypeOfIncome;
  incomeAmount?: number;
};

const useStyles = makeStyles((theme) => ({
  root: {},
  greyText: {
    color: theme.palette.text.disabled,
  },
}));

const AccordionIncomeHeader: FC<Props> = ({ typeOfIncome, incomeAmount }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const text = t(
    `directMutations.income.${typeOfIncome ? `incomeTypes.${typeOfIncome}` : 'chooseTypeOfIncome'}`
  );

  return (
    <Grid container item justifyContent="space-between" className={`${classes.greyText}`}>
      <Grid xs={8} item>
        <AccordionSubTitle>{text}</AccordionSubTitle>
      </Grid>
      <Grid xs={4} item>
        <AmountSubTitle>{formatIncome(incomeAmount)}</AmountSubTitle>
      </Grid>
    </Grid>
  );
};

export default AccordionIncomeHeader;
