import { FC } from 'react';
import { NumericFormat } from 'react-number-format';

export type AmountProps = {
  value: number | undefined;
  variant?: 'value' | 'label';
};

export const Amount: FC<AmountProps> = ({ value, variant = value }) => {
  const emptyValue = variant === 'label' ? '-' : '0';
  return (
    <NumericFormat
      thousandSeparator="."
      decimalSeparator=","
      valueIsNumericString
      decimalScale={2}
      prefix="€ "
      value={value ?? 0}
      displayType="text"
      renderText={(value) => <span>{value ?? emptyValue}</span>}
      fixedDecimalScale={true}
    />
  );
};
