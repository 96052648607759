import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { BtlRealEstate } from 'types/BtlFormApplication';
import formatDate from 'utils/formatDate';
import CurrencyFormat from 'screens/Applications/components/ApplicationNew/CurrencyFormat';

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    '& td': {
      borderBottom: 'none',
      wordBreak: 'break-all',
    },
    '&.error': {
      'backgroundColor': theme.palette.danger.light,
      '& :last-child': {
        color: theme.palette.danger.main,
      },
    },
  },
  errorIcon: {
    'float': 'right',
    'display': 'none',
    '.error &': {
      display: 'block',
    },
  },
  loanPartTitle: {
    marginBottom: theme.spacing(2),
  },
}));

type BuyToLetExistingBuildSummaryProps = {
  realEstate: BtlRealEstate;
  realEstateNr: number;
  onClickField: (field: string) => void;
  errorMessage: (id: string, category: string) => void;
};

const BuyToLetExistingBuildSummary: FC<BuyToLetExistingBuildSummaryProps> = ({
  realEstate,
  realEstateNr,
  onClickField,
  errorMessage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const form = 'buyToLetForm.newRealEstate';

  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].typeOfCollateral`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.typeOfCollateral`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('typeOfCollateral')}>
            {realEstate.typeOfCollateral ? t(`${form}.${realEstate.typeOfCollateral}`) : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].typeOfRealEstate`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.typeOfRealEstate`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('typeOfRealEstate')}>
            {realEstate.typeOfRealEstate
              ? t(`${form}.realEstateTypes.${realEstate.typeOfRealEstate}`)
              : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].energyClass`, 'realEstates')),
          })}
        >
          <TableCell>{t(`${form}.energyClass`)}</TableCell>
          <TableCell onClick={() => onClickField('energyClass')}>
            {t(`buyToLetForm.newRealEstate.energyClasses.${realEstate.energyClass}`)}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].street`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.street`)}
            {realEstate.typeOfCollateral !== 'NewlyBuild' && <span className="required">*</span>}
          </TableCell>
          <TableCell onClick={() => onClickField('street')}>
            {realEstate.street}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].houseNumber`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.houseNumber`)}
            {realEstate.typeOfCollateral !== 'NewlyBuild' && <span className="required">*</span>}
          </TableCell>
          <TableCell onClick={() => onClickField('houseNumber')}>
            {realEstate.houseNumber}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].houseNumberExtension`, 'realEstates')),
          })}
        >
          <TableCell>{t(`${form}.houseNumberExtension`)}</TableCell>
          <TableCell onClick={() => onClickField('houseNumberExtension')}>
            {realEstate.houseNumberExtension}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].postalCode`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.postalCode`)}
            {realEstate.typeOfCollateral !== 'NewlyBuild' && <span className="required">*</span>}
          </TableCell>
          <TableCell onClick={() => onClickField('postalCode')}>
            {realEstate.postalCode}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].city`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.city`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('city')}>
            {realEstate.city}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].city`, 'purchaseDate')),
          })}
        >
          <TableCell>
            {t(`${form}.purchaseDate`)}
            <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('purchaseDate')}>
            {realEstate.purchaseDate && formatDate(realEstate.purchaseDate)}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].hasLeasehold`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.hasLeasehold`)} <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('hasLeasehold')}>
            {realEstate.hasLeasehold === false
              ? t(`${form}.no`)
              : realEstate.hasLeasehold === true
              ? t(`${form}.yes`)
              : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        {realEstate.hasLeasehold && (
          <>
            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(errorMessage(`[${realEstateNr}].leaseholdAmount`, 'realEstates')),
              })}
            >
              <TableCell>{t(`${form}.leaseholdAmount`)}</TableCell>
              <TableCell onClick={() => onClickField('leaseholdAmount')}>
                {!!realEstate.leaseholdAmount && (
                  <CurrencyFormat value={realEstate.leaseholdAmount} />
                )}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>

            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(errorMessage(`[${realEstateNr}].typeOfLeasehold`, 'realEstates')),
              })}
            >
              <TableCell>
                {t(`${form}.typeOfLeasehold`)} <span className="required">*</span>
              </TableCell>
              <TableCell onClick={() => onClickField('typeOfLeasehold')}>
                {realEstate.typeOfLeasehold}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>
          </>
        )}

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].isBeingRenovated`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.isBeingRenovated`)} <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('isBeingRenovated')}>
            {realEstate.isBeingRenovated === false
              ? t(`${form}.no`)
              : realEstate.isBeingRenovated === true
              ? t(`${form}.yes`)
              : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        {realEstate.isBeingRenovated && (
          <>
            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(errorMessage(`[${realEstateNr}].renovationCosts`, 'realEstates')),
              })}
            >
              <TableCell>
                {t(`${form}.renovationCosts`)} <span className="required">*</span>
              </TableCell>
              <TableCell onClick={() => onClickField('renovationCosts')}>
                {!!realEstate.renovationCosts && (
                  <CurrencyFormat value={realEstate.renovationCosts} />
                )}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>
            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(
                  errorMessage(`[${realEstateNr}].rentalValueAfterRenovation`, 'realEstates')
                ),
              })}
            >
              <TableCell>
                {t(`${form}.rentalValueAfterRenovation`)} <span className="required">*</span>
              </TableCell>
              <TableCell onClick={() => onClickField('rentalValueAfterRenovation')}>
                {!!realEstate.rentalValueAfterRenovation && (
                  <CurrencyFormat value={realEstate.rentalValueAfterRenovation} />
                )}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>
            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(
                  errorMessage(`[${realEstateNr}].marketValueAfterRenovation`, 'realEstates')
                ),
              })}
            >
              <TableCell>
                {t(`${form}.marketValueAfterRenovation`)} <span className="required">*</span>
              </TableCell>
              <TableCell onClick={() => onClickField('marketValueAfterRenovation')}>
                {!!realEstate.marketValueAfterRenovation && (
                  <CurrencyFormat value={realEstate.marketValueAfterRenovation} />
                )}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>
          </>
        )}

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(
              errorMessage(`[${realEstateNr}].isRenovationDepotNecessary`, 'realEstates')
            ),
          })}
        >
          <TableCell>
            {t(`${form}.isRenovationDepotNecessarySummary`)} <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('isRenovationDepotNecessary')}>
            {realEstate.isRenovationDepotNecessary === false
              ? t(`${form}.no`)
              : realEstate.isRenovationDepotNecessary === true
              ? t(`${form}.yes`)
              : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>

        {realEstate.isRenovationDepotNecessary && (
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(
                errorMessage(`[${realEstateNr}].renovationDepotAmount`, 'realEstates')
              ),
            })}
          >
            <TableCell>
              {t(`${form}.renovationDepotAmountSummary`)} <span className="required">*</span>
            </TableCell>
            <TableCell onClick={() => onClickField('renovationDepotAmount')}>
              {!!realEstate.renovationDepotAmount && (
                <CurrencyFormat value={realEstate.renovationDepotAmount} />
              )}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
        )}

        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].nameOfTenant`, 'realEstates')),
          })}
        >
          <TableCell>{t(`${form}.nameOfTenant`)}</TableCell>
          <TableCell onClick={() => onClickField('nameOfTenant')}>
            {realEstate.nameOfTenant}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(
              errorMessage(`[${realEstateNr}].basicAnnualRentalIncome`, 'realEstates')
            ),
          })}
        >
          <TableCell>{t(`${form}.basicAnnualRentalIncome`)}</TableCell>
          <TableCell onClick={() => onClickField('basicAnnualRentalIncome')}>
            {!!realEstate.basicAnnualRentalIncome && (
              <CurrencyFormat value={realEstate.basicAnnualRentalIncome} />
            )}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].rentalValue`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.rentalValue`)} <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('rentalValue')}>
            {!!realEstate.rentalValue && <CurrencyFormat value={realEstate.rentalValue} />}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(errorMessage(`[${realEstateNr}].marketValue`, 'realEstates')),
          })}
        >
          <TableCell>
            {t(`${form}.marketValue`)} <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('marketValue')}>
            {!!realEstate.marketValue && <CurrencyFormat value={realEstate.marketValue} />}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(classes.tableRow, {
            error: Boolean(
              errorMessage(`[${realEstateNr}].buildingInsurancePremium`, 'realEstates')
            ),
          })}
        >
          <TableCell>
            {t(`${form}.isRoomRental`)} <span className="required">*</span>
          </TableCell>
          <TableCell onClick={() => onClickField('isRoomRental')}>
            {realEstate.isRoomRental === false
              ? t(`${form}.no`)
              : realEstate.isRoomRental === true
              ? t(`${form}.yes`)
              : ''}
            <ErrorOutlineOutlined className={classes.errorIcon} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default BuyToLetExistingBuildSummary;
