import { styled } from '@mui/styles';
import {
  Container as MuiContainer,
  Typography as MuiTypography,
  Button,
  Tabs as MuiTabs,
  TableCell,
  Grid,
  IconButton,
  Box,
  Icon,
  TableRow,
} from '@mui/material';
import MuiAddIcon from '@mui/icons-material/Add';

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  'borderColor': theme.palette.grey[200],
  'borderBottomWidth': '1px',
  'borderBottomStyle': 'solid',
  'margin': theme.spacing(6, 0, 2),
  '& .MuiTab-root': {
    minWidth: '100px',
  },
  'fontSize': '12px',
}));

export const InfoItemText = styled(MuiTypography)({
  fontSize: '16px',
  margin: '4px 0',
});

export const Container = styled(MuiContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 3),
  },
}));

export const EmptyListRefreshDescriptionWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 5),
  maxWidth: '600px',
  textAlign: 'center',
}));

export const HeaderContainedButton = styled(Button)({
  width: '210px',
  marginRight: '16px',
  marginBottom: '8px',
});

export const AddIcon = styled(MuiAddIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(-2),
}));

export const TableCellLoanPart = styled(TableCell)({
  border: 'none',
});

export const CustomerNameTypo = styled(MuiTypography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '28px',
  flex: 1,
  margin: theme.spacing(0, 0, 3),
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
  },
}));

export const TitleNotification = styled(Grid)(({ theme }) => ({
  'display': '-webkit-box',
  'color': theme.palette.text.secondary,
  'fontSize': 14,
  'fontWeight': 500,
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  'overflow': 'hidden',
  'textOverflow': 'ellipsis',
  '-ms-text-overflow': 'ellipsis',
}));

export const ApplicationContentDetailsSpan = styled(Grid)(({ theme }) => ({
  'color': theme.palette.text.secondary,
  'display': 'flex',
  '& > *': {
    margin: theme.spacing(0, 0.5),
  },
  'fontSize': '12px',
  'flexWrap': 'nowrap',
  'textAlign': 'center',
}));

export const ActionButtonOnDetails = styled(Grid)(({ theme }) => ({
  alignItems: 'right',
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(-2),
    alignItems: 'left',
  },
}));

export const MoneyLenderName = styled('span')({
  whiteSpace: 'nowrap',
});

export const LoanBox = styled(Box)<any, any>(({ theme, iscentered }) => ({
  padding: theme.spacing(1, 0),
  textAlign: iscentered ? 'center' : undefined,
}));

export const MobileContainer = styled(Box)(({ theme }) => ({
  'width': '100%',
  'marginBottom': theme.spacing(2),
  '& > *': {
    marginBottom: theme.spacing(1),
  },
  'color': theme.palette.text.secondary,
  'cursor': 'pointer',
}));

export const NavLinkIcon = styled(Icon)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  fontSize: '14px',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.4em',
  },
}));

export const TableBodyRow = styled(TableRow)(({ theme }) => ({
  'cursor': 'pointer',
  '& td': {
    padding: '8px 12px',
  },
}));

// CLIENTS  DETAILS

export const ClientDetailsContainer = styled(Grid)(({ theme }) => ({
  'height': '100%',
  'display': 'flex',
  'flexDirection': 'column',
  'overflow': 'auto',
  'padding': theme.spacing(5, 6),
  '& > *': {
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.down('md')]: {
    'padding': theme.spacing(2, 3),
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
}));
export const CloseIcon = styled(IconButton)({
  position: 'absolute',
  right: 0,
  marginTop: 0,
});
