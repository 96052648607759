import React from 'react';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Chip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  tabText: {
    'fontWeight': 500,
    'color': theme.palette.text.primary,
    'marginRight': theme.spacing(1),
    '.Mui-selected &': {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  },
  tabChip: {
    'backgroundColor': theme.palette.primary.main,
    'color': theme.palette.white.main,
    '.Mui-selected &': {
      backgroundColor: alpha(theme.palette.primary.light, 0.2),
      color: theme.palette.primary.main,
    },
  },
}));

type Props = {
  text: string;
  chip?: React.ReactNode;
};

/* TODO: Chip can be a prop of Tab:
  <Tab
    value="administrativeDocuments"
    classes={tabClasses}
    label={t('proofitem.administrativeDocuments')}
    icon={<Chip size="small" label={administrativeDocumentsLabel} />}
    iconPosition="end"
  />
*/
const TabLabel: React.FC<Props> = ({ text, chip }) => {
  const classes = useStyles();
  return (
    <div className={classes.container} data-testid={`actionList-${text.replace(' ', '-')}`}>
      <Typography className={classes.tabText}>{text}</Typography>
      <Chip className={classes.tabChip} size="small" label={chip} data-testid="counter" />
    </div>
  );
};

export default TabLabel;
