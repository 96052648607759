import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    'display': 'flex',
    'minHeight': 80,
    '&:hover': {
      backgroundColor: theme.palette.white.light,
    },
    'borderRadius': 6,
    'alignItems': 'flex-start',
    'padding': theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 0),
    },
  },
  text: {
    flex: 1,
  },
  text2: {
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(0),
  },
}));

const SolvedByOtherCard: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.text}>
        <div className={classes.text2}>
          <Typography variant="body1">{t('tasks.solutions.solvedByOther')}</Typography>
        </div>
      </div>
    </div>
  );
};

export default SolvedByOtherCard;
