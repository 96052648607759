import { Grid, Divider, Button } from '@mui/material';
import Logo from 'components/Logo';
import { formatLoanNumber, IsMobile } from 'utils/global';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplicationContentDetailsSpan,
  ActionButtonOnDetails,
} from 'screens/Clients/Clients.styled';
import { Emphasize } from 'screens/Home/Home.styled';
import type { ClientLoan } from 'types/Client';

export type ClientMortgageItemProps = {
  handleLinkClick: (event: MouseEvent<any, any>) => void;
  handleMutationClick: () => void;
  mortgage: ClientLoan;
  isMutationsButton?: boolean;
};

export default function ClientMortgageItem({
  handleLinkClick,
  mortgage,
  handleMutationClick,
  isMutationsButton = false,
}: ClientMortgageItemProps) {
  const isMobile = IsMobile();
  const referrer = window.location.href;
  const { t } = useTranslation();

  return (
    <Grid mt={2}>
      <Divider />
      <Grid
        mt={2}
        container
        spacing={isMobile ? 0 : 2}
        alignItems="center"
        flex="nowrap"
        justifyContent="space-between"
      >
        <Grid item xs={12} md="auto">
          <Grid container alignItems="center">
            {!isMobile && (
              <Grid item xs={12} md="auto" pr={2} data-testid="logo">
                <Logo label={mortgage.commercialLabel} size="M" />
              </Grid>
            )}
            <Grid item xs={12} md="auto">
              <Emphasize truncate={1}>{mortgage.moneylenderName}</Emphasize>
              <ApplicationContentDetailsSpan item>
                {formatLoanNumber(mortgage.loanNumber)}
              </ApplicationContentDetailsSpan>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md="auto">
          <Grid container alignItems="center">
            <ActionButtonOnDetails item xs={12} sm="auto">
              {isMutationsButton && (
                <Button data-testid="mutation-button" color="primary" onClick={handleMutationClick}>
                  {t('clients.detail.goToMutation')}
                </Button>
              )}
            </ActionButtonOnDetails>
            <ActionButtonOnDetails item xs={12} sm="auto">
              {mortgage.eConsumerLink && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLinkClick}
                  href={`${mortgage.eConsumerLink}&referrer=${referrer}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-testid="econsument-link"
                >
                  {t('clients.detail.goToEConsumer')}
                </Button>
              )}
            </ActionButtonOnDetails>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
