import makeStyles from '@mui/styles/makeStyles';

export const buttonStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
    width: 108,
    fontSize: '12px',
    textTransform: 'none',
  },
  icon: {
    transform: 'scale(0.8)',
  },
  paper: {
    padding: theme.spacing(2),
    width: 350,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  popover: {
    pointerEvents: 'none',
  },
}));
