import FormTitleText from 'components/Form/FormTitle';
import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TemplateObligations from '../../../../templates/ObligationList';

type Props = {
  index?: number;
};
const Obligations: FC<Props> = ({ index = 0 }) => {
  const { t } = useTranslation();
  const applicant = useWatch({ name: `applicants.${index}` });

  if (!applicant || !applicant.personalInfo) return null;
  const applicationTitle = applicant.personalInfo.surname
    ? `${applicant.personalInfo.firstNames ?? ''} ${applicant.personalInfo.middleName ?? ''} ${
        applicant.personalInfo.surname
      }`
    : t('directMutations.stepper.applicant');
  return (
    <>
      <FormTitleText
        title={applicationTitle}
        description={t('applications.new.form.obligationsDescriptionConversionRaise', {
          name: `${applicant?.personalInfo?.firstNames ?? ''} ${
            applicant?.personalInfo?.surname ?? t('directMutations.stepper.newApplicant')
          }`,
        })}
      />
      <TemplateObligations
        rootFormName={`applicants.${index}.obligation.`}
        shouldUnregister={false}
      />
    </>
  );
};
export default Obligations;
