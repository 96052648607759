import type { FC } from 'react';
import { CircularProgress, IconButtonProps } from '@mui/material';
import { IconButton } from './styled';

interface LoadingIconButtonProps extends IconButtonProps {
  onClick?: () => void;
  isLoading?: boolean;
  children: React.ReactNode;
}

export const LoadingIconButton: FC<LoadingIconButtonProps> = ({
  onClick,
  isLoading,
  children,
  type,
  color,
  size,
}) => {
  const SpinnerAdornment = () => <CircularProgress size={20} />;
  return (
    <IconButton type={type} color={color} size={size} onClick={onClick} disabled={isLoading}>
      {isLoading ? null : children}
      {isLoading && <SpinnerAdornment />}
    </IconButton>
  );
};
