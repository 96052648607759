export const calculateCompletion = (
  start?: number,
  current?: number,
  end?: number
): number | null => {
  if (start === undefined || current === undefined || end === undefined) return null;
  const percentage = ((current - start) / (end - start)) * 100;
  if (percentage < 0) return 0;
  if (percentage > 100) return 100;
  return Math.round(percentage);
};
