import { Container, Grid, Button, Icon } from '@mui/material';
import { FC, useEffect, useState, useCallback } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { policyDefaultValues } from '../../../../constants';
import { ConnectedPoliciesFormType } from '../../../../../../utils/types';
import PoliciesForm from '../../../../../../templates/ConnectedPolicies/Policies/PoliciesForm/PoliciesForm';
import { ConfirmationDialog } from 'components/CustomDialog';

type PoliciesProps = {
  loanPartNumber: number;
  loanPartIndex: number;
};

export const Policies: FC<PoliciesProps> = ({ loanPartNumber, loanPartIndex }) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<ConnectedPoliciesFormType>();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [policyIndex, setPolicyIndex] = useState<number>();
  const { fields, append, remove } = useFieldArray({
    name: `loanPartPolicies.${loanPartIndex}.policies`,
    control,
  });

  const closeDialog = useCallback(() => setDialogIsOpen(false), []);

  const handleDelete = useCallback((index: number) => {
    setDialogIsOpen(true);
    setPolicyIndex(index);
  }, []);

  const handleConfirmationDialog = useCallback(() => {
    remove(policyIndex);
    setPolicyIndex(undefined);
    closeDialog();
  }, [closeDialog, remove, policyIndex]);

  const formPolicies = useWatch({
    control,
    name: `loanPartPolicies.${loanPartIndex}.policies`,
  });

  useEffect(() => {
    setValue(`loanPartPolicies.${loanPartIndex}.loanPartNumber`, loanPartNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!formPolicies) {
      append(policyDefaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {fields.map((field, policyIndex) => {
        const rootForm = `loanPartPolicies.${loanPartIndex}.policies.${policyIndex}`;

        if (!formPolicies || !formPolicies[policyIndex]) return null;

        return (
          <PoliciesForm
            key={field.id}
            formPolicies={formPolicies}
            policyIndex={policyIndex}
            rootForm={rootForm}
            remove={handleDelete}
            shouldUnregister={false}
            isPolicyAmount={false}
          />
        );
      })}
      <Grid container justifyContent="flex-end">
        <Button
          onClick={() => append(policyDefaultValues)}
          color="primary"
          variant="contained"
          size="medium"
        >
          {t('directMutations.buttons.addPolicyButton')}
          <Icon>plus</Icon>
        </Button>
      </Grid>
      <ConfirmationDialog
        isOpen={dialogIsOpen}
        onConfirmation={handleConfirmationDialog}
        onDecline={closeDialog}
        onClose={closeDialog}
        confirmationText={t('directMutations.buttons.deleteButton')}
        declineText={t('directMutations.buttons.cancelButton')}
        bodyText={t('directMutations.confirmDelete.bodyPolicies')}
      />
    </Container>
  );
};

export default Policies;
