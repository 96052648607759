import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Icon, IconButton, Input, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import searchOfficeSchema from './searchOfficeSchema';

const useStyles = makeStyles((theme) => ({
  error: {
    display: 'block',
    color: theme.palette.error.main,
  },
  andOr: {
    padding: '13px 16px 0',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    border: 'none',
  },
  searchBtn: {
    minWidth: '100px',
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      marginLeft: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

const NAME_PART = 'namePart';
const KVK_NUMBER = 'kvkNumber';

const SearchInputAdornment = (
  <InputAdornment position="start">
    <Icon fontSize="small">search</Icon>
  </InputAdornment>
);

export type SearchOfficeFormValues = {
  namePart: string;
  kvkNumber: string;
};

type SearchOfficeFormProps = {
  onValid: SubmitHandler<SearchOfficeFormValues>;
};

export const SearchOfficeForm: React.FC<SearchOfficeFormProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<SearchOfficeFormValues>({
    defaultValues: {},
    resolver: yupResolver(searchOfficeSchema),
  });

  const Validations = () => {
    const { namePart, kvkNumber } = errors;

    if (!namePart && !kvkNumber) return null;

    if (namePart?.message === 'BOTH_EMPTY' || kvkNumber?.message === 'BOTH_EMPTY') {
      return <span className={classes.error}>{t('offices.filter.required')}</span>;
    }

    return (
      <>
        {namePart?.message === 'INVALID' && (
          <span className={classes.error}>{t('offices.filter.invalidPostalCode')}</span>
        )}
        {kvkNumber?.message === 'INVALID' && (
          <span className={classes.error}>{t('offices.filter.invalidKvkNumber')}</span>
        )}
      </>
    );
  };

  const handleClickClear = (name: 'namePart' | 'kvkNumber') => () => setValue(name, '');

  const handleMouseDownClear = useCallback((event: any) => event.preventDefault(), []);

  return (
    <form onSubmit={handleSubmit(props.onValid)}>
      <Validations />

      <div className={classes.container}>
        <Input
          id={NAME_PART}
          placeholder={t('offices.filter.namePart')}
          autoComplete="off"
          autoFocus
          startAdornment={SearchInputAdornment}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="clear field"
                onClick={handleClickClear(NAME_PART)}
                onMouseDown={handleMouseDownClear}
                size="large"
              >
                <Icon fontSize="small">close</Icon>
              </IconButton>
            </InputAdornment>
          }
          inputProps={{ ...register(NAME_PART) }}
        />
        <Typography className={classes.andOr} variant="body2" color="textSecondary">
          {t('offices.filter.andOr')}
        </Typography>
        <Input
          id={KVK_NUMBER}
          placeholder={t('offices.filter.kvkNumber')}
          autoComplete="off"
          startAdornment={SearchInputAdornment}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="clear field"
                onClick={handleClickClear(KVK_NUMBER)}
                onMouseDown={handleMouseDownClear}
                size="large"
              >
                <Icon fontSize="small">close</Icon>
              </IconButton>
            </InputAdornment>
          }
          inputProps={{ maxLength: 8, ...register(KVK_NUMBER) }}
        />
        <Button type="submit" variant="contained" color="primary" className={classes.searchBtn}>
          {t('offices.filter.search')}
        </Button>
      </div>
    </form>
  );
};

export default SearchOfficeForm;
