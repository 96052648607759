import products from 'mocks/products.json';
import { incomeFormTypeDefaultValues } from 'screens/Mutations/constants';
import { mapProductsToOptions } from 'screens/Mutations/utils/mappers/mapProductsToOptions';
import { TypeOfFinancing, TypeOfPolicyMutation } from '__generated__/api';
import {
  CollateralFormType,
  ContactDetailsFormType,
  CostAndResourcesFormType,
  LoanFormType,
  LoanPartType,
  ObligationFormType,
  OHAForm,
  Option,
  PersonalInfoFormType,
  Policy,
  SplitLoan,
} from '../../utils/types';

export const ROOT_URL = '/mutaties/oha/:commercialLabel/:loanNumber/';

const currentApplicant = 'blijvende-aanvrager';
const newApplicant = 'nieuwe-aanvrager';
const financing = 'financiering';

export const steps = {
  currentApplicant: {
    default: currentApplicant,
    personalInfo: `${currentApplicant}-personalia`,
    contactDetails: `${currentApplicant}-contactgegevens`,
    income: `${currentApplicant}-inkomen`,
    obligations: `${currentApplicant}-verplichtingen`,
  },
  newApplicant: {
    default: newApplicant,
    personalInfo: `${newApplicant}-personalia`,
    contactDetails: `${newApplicant}-contactgegevens`,
    income: `${newApplicant}-inkomen`,
    obligations: `${newApplicant}-verplichtingen`,
  },
  collateral: 'onderpand',
  financing: `${financing}`,
  financingCostAndResources: `${financing}-kosten-en-eigen-middelen`,
  financingLoan: `${financing}-lening`,
  financingConnectedPolicy: `${financing}-gekoppelde-polis-rekening`,
  additionalInfo: 'aanvullende-informatie',
  summary: 'samenvatting',
};

export const stepsOrder = [
  steps.currentApplicant.personalInfo,
  steps.currentApplicant.contactDetails,
  steps.currentApplicant.income,
  steps.currentApplicant.obligations,
  steps.newApplicant.personalInfo,
  steps.newApplicant.contactDetails,
  steps.newApplicant.income,
  steps.newApplicant.obligations,
  steps.collateral,
  steps.financingCostAndResources,
  steps.financingLoan,
  steps.financingConnectedPolicy,
  steps.additionalInfo,
  steps.summary,
];

export const stepsOrderNoNewApplicant = [
  steps.currentApplicant.personalInfo,
  steps.currentApplicant.contactDetails,
  steps.currentApplicant.income,
  steps.currentApplicant.obligations,
  steps.newApplicant.personalInfo,
  steps.collateral,
  steps.financingCostAndResources,
  steps.financingLoan,
  steps.financingConnectedPolicy,
  steps.additionalInfo,
  steps.summary,
];

export const stepsOrderAll = [
  steps.currentApplicant.default,
  steps.currentApplicant.personalInfo,
  steps.currentApplicant.contactDetails,
  steps.currentApplicant.income,
  steps.currentApplicant.obligations,
  steps.newApplicant.default,
  steps.newApplicant.personalInfo,
  steps.newApplicant.personalInfo,
  steps.newApplicant.contactDetails,
  steps.newApplicant.income,
  steps.newApplicant.obligations,
  steps.collateral,
  steps.financing,
  steps.financingCostAndResources,
  steps.financingLoan,
  steps.financingConnectedPolicy,
  steps.additionalInfo,
  steps.summary,
];

const collateralDefaultValues = {} as CollateralFormType;
const CostAndResourcesFormValues = {} as CostAndResourcesFormType;
const personalInfoDefaultValues = {} as PersonalInfoFormType;
const contactDetailsDefaultValues = {} as ContactDetailsFormType;
const obligationDefaultValues = { obligations: [] } as ObligationFormType;

const loanDefaultValues = {} as LoanFormType;
export const policyDefaultValues = {} as Policy;
const productOptionDefaultValues = [] as Option[];

export const loanPartDefaultValues = {} as LoanPartType;

export const defaultApplicant = {
  personalInfo: personalInfoDefaultValues,
  contactDetails: contactDetailsDefaultValues,
  income: incomeFormTypeDefaultValues,
  obligation: obligationDefaultValues,
};

export const OHAFormDefaultValues: OHAForm = {
  commercialLabel: undefined,
  loanNumber: 0,
  moneylenderName: undefined,
  intermediary: undefined,
  currentApplicant: { ...defaultApplicant },
  newApplicant: { ...defaultApplicant, income: { incomes: [] } },
  collateral: collateralDefaultValues,
  loan: { ...loanDefaultValues, policies: [], currentLoanParts: [], newLoanParts: [] },
  costAndResources: CostAndResourcesFormValues,
  productOptions: productOptionDefaultValues,
  additionalInformation: {
    name: undefined,
    phoneNumber: undefined,
    info: undefined,
  },
};

export const mockRepaymentTypeOptions = mapProductsToOptions(products);

export const mockPolicy: Policy = {
  policyNumber: '12344',
  numberOfPolicyHolders: 2,
  policyHolders: {
    personOne: 'lalala',
    personTwo: 'aardappel',
  },
  isPolicyForConnectedPersons: 'no',
  numberOfInsuredPersons: 1,
  insuredPersons: {
    personOne: 'potato',
  },
  typeOfPolicyMutation: TypeOfPolicyMutation.Altered,
  mutationDescription: 'ajbfkanwfaw',
  policyAmount: 1200,
};

const mockSplitLoan: SplitLoan = {
  amount: 132,
  fixedRatePeriod: 60,
  repaymentType: 'Aflossingsvrij',
  NHGGuarantee: 'yes',
  box1: 20,
  box3: 10,
  interestPercentage: 11,
  endDateInterestDeduction: '2030-05-05',
  hasDurationPeriodChange: 'yes',
  newDuration: 60,
};

const mockCurrentLoanPart: LoanPartType = {
  loanPartNumber: 123,
  repaymentType: 'Aflossingsvrij',
  box1: 132,
  box3: 454,
  interestPercentage: 16,
  endDateInterestDeduction: '2030-05-05',
  hasDurationPeriodChange: 'yes',
  hasInterestRedemption: 'no',
  newDuration: 30,
  fixedRatePeriod: 20,
  shouldSplitLoan: 'yes',
  NHGGuarantee: 'no',
  loanAmount: 123456,
  hasConnectedPolicy: 'yes',
  splitLoan: [{ ...mockSplitLoan }, { ...mockSplitLoan }],
  numberOfSplitLoanParts: 2,
  typeOfFinancing: TypeOfFinancing.PersonalEquity,
  policies: [],
};

const mockNewLoanpart: LoanPartType = {
  loanPartNumber: 123,
  repaymentType: 'Aflossingsvrij',
  box1: 132,
  box3: 454,
  interestPercentage: 16,
  endDateInterestDeduction: '2030-05-05',
  hasDurationPeriodChange: 'yes',
  hasInterestRedemption: 'no',
  newDuration: 30,
  fixedRatePeriod: 20,
  shouldSplitLoan: 'yes',
  NHGGuarantee: 'no',
  loanAmount: 123456,
  hasConnectedPolicy: 'yes',
  splitLoan: [{ ...mockSplitLoan }, { ...mockSplitLoan }],
  numberOfSplitLoanParts: 2,
  typeOfFinancing: TypeOfFinancing.PersonalEquity,
};

export const mockLoan: LoanFormType = {
  loanNumber: 1234567,
  amount: 13371337,
  extraPayOffAmount: 50000,
  changeLifeInsurancePolicy: 'yes',
  changeCurrentMortgage: 'yes',
  currentLoanParts: [
    {
      ...mockCurrentLoanPart,
      policies: [
        { ...mockPolicy, policyNumber: '3' },
        { ...mockPolicy, policyNumber: '4' },
      ],
    },
    {
      ...mockCurrentLoanPart,
      hasConnectedPolicy: 'no',
      loanPartNumber: 468,
      policies: [
        { ...mockPolicy, policyNumber: '5' },
        { ...mockPolicy, policyNumber: '6' },
      ],
    },
    {
      ...mockCurrentLoanPart,
      loanPartNumber: 567,
      shouldSplitLoan: 'no',
      convertLoan: { repaymentType: 'awesomeness', fixedRatePeriod: 12, NHGGuarantee: 'no' },
      policies: [
        { ...mockPolicy, policyNumber: '7' },
        { ...mockPolicy, policyNumber: '8' },
      ],
    },
  ],
  newLoanParts: [
    { ...mockNewLoanpart, loanPartNumber: 647 },
    { ...mockNewLoanpart, loanPartNumber: 184 },
    { ...mockNewLoanpart, loanPartNumber: 943 },
  ],
  policies: [
    { ...mockPolicy, policyNumber: '1' },
    { ...mockPolicy, policyNumber: '2' },
  ],
};
