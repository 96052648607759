import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TablePagination } from '@mui/material';

type Props = {
  count: number;
  rowsPerPage: number;
  page: number;
  handleChangePage: any;
  handleChangeRowsPerPage?: any;
};

const Pagination: FC<Props> = ({
  count,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const { t } = useTranslation();
  return (
    <TablePagination
      rowsPerPageOptions={[20]}
      colSpan={3}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('pagination.of')} ${count}`}
    />
  );
};

export default Pagination;
