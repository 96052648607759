import { useMutation, useQuery } from 'react-query';
import {
  getNotificationPreference,
  saveNotificationPreference,
} from '../../../api/notificationPreference';

export function useSaveNotificationPreference(onSuccess: () => void, onError: () => void) {
  return useMutation(saveNotificationPreference, {
    onSuccess,
    onError,
  });
}

export const useGetNotificationPreference = () => {
  return useQuery(['get-notification-preference'], getNotificationPreference);
};
