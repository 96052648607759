import type { FC } from 'react';
import type { ConnectedPoliciesFormType } from 'screens/Mutations/utils/types';
import { useTranslation } from 'react-i18next';
import { SummaryCard } from 'components/SummaryCard';
import Agreement from '../../../Form/Agreement';
import { questionnaireSummaryViewMap } from './utils/summaryViewMap';
import { Container } from './Summary.styled';
import FormTitleText from 'components/Form/FormTitle';
import CardShape from './components/CardShape';
import { useWizardStep } from 'screens/Mutations/Wizard/hooks';
import { useFormContext } from 'react-hook-form';
import AdditionalSummaryCard from './components/AdditionalSummaryCard/AdditionalSummaryCard';
import { SubTitle } from 'screens/Mutations/molecules/styled';
import AdditionalInformationSummary from 'screens/Mutations/templates/Summary/AdditionalInformation';

export const Summary: FC = () => {
  const { t } = useTranslation();
  const { goToStep } = useWizardStep();
  const {
    formState: { errors },
    watch,
  } = useFormContext();
  const loanDetails: any = watch();
  const connectedPolicies: ConnectedPoliciesFormType = {
    loanPartPolicies: loanDetails.loanPartPolicies,
  };

  return (
    <Container data-testid="summary-wrapper">
      <FormTitleText
        title={t('directMutations.summary.title')}
        description={t('directMutations.summary.description')}
      />
      <SubTitle variant="h5">{t('directMutations.summary.loanDetails.title')}</SubTitle>
      <SummaryCard
        testdId="conversionMutation-loanDetails"
        title={t('directMutations.summary.originalLoan')}
        onEditClick={() => goToStep(0)}
      >
        <CardShape cardValues={questionnaireSummaryViewMap(loanDetails.conversionQuestionnaire)} />
      </SummaryCard>
      <AdditionalSummaryCard
        loanParts={loanDetails?.loanParts}
        connectedPolicies={connectedPolicies}
        goToStep={goToStep}
      />
      <AdditionalInformationSummary
        onClick={() => goToStep(2)}
        additionalInformationForm={loanDetails.additionalInformation}
        rootFormName="directMutations.additionalInformation."
      />
      <Agreement errors={errors} />
    </Container>
  );
};

export default Summary;
