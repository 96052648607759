import { AxiosPromise } from 'axios';
import { ResponseNotificationPreference } from 'types/NotificationPreference';
import { http } from '../http';

export const saveNotificationPreference = (mutation: any): AxiosPromise<any> =>
  http({
    url: '/api/v1/advisor/preferences',
    method: 'POST',
    data: JSON.stringify(mutation),
  });

export const getNotificationPreference = async (): Promise<ResponseNotificationPreference> => {
  const response = await http({
    url: '/api/v1/advisor/preferences',
    method: 'GET',
  });
  return response.data;
};
