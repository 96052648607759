import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import {
  FormInputDropdown,
  FormInputNumber,
  FormToggleButtonGroup,
  FormInputDate,
  FormInputCurrency,
} from 'components/Form';
import { yesNoGroupOptions } from '../../../components/ConversionMutation/constants';
import { RootState } from 'store';
import { FieldWrapper, TopWrapper } from '../Loan.styled';
import { SelectOption } from 'types/SelectOption';
import FormInputPercentage from 'components/Form/FormInputPercentage';

type LoanPartProps = {
  loanPartIndex: number;
  shouldUnregister: boolean;
  productsData?: SelectOption[];
};

export const NewLoanPart: FC<LoanPartProps> = ({
  loanPartIndex,
  productsData,
  shouldUnregister = true,
}) => {
  const { t } = useTranslation();
  const productOptions = useSelector((state: RootState) => state.ohaForm.productOptions);
  const rootForm = `newLoanParts[${loanPartIndex}]`;

  return (
    <>
      <TopWrapper container spacing={3}>
        <Grid item xs={6}>
          <FormInputDropdown
            name={`${rootForm}.repaymentType`}
            label={t('directMutations.loanParts.repaymentType')}
            options={productsData ?? productOptions}
            placeholder={t('directMutations.loanParts.repaymentTypePlaceholder')}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <FormInputNumber
            name={`${rootForm}.newDuration`}
            label={t('directMutations.loanParts.duration')}
            max={999}
            required
            shouldUnregister={shouldUnregister}
          />
        </Grid>
      </TopWrapper>
      <FieldWrapper container spacing={3}>
        <Grid item xs={6}>
          <FormInputCurrency
            name={`${rootForm}.loanAmount`}
            label={t('directMutations.loanParts.amount')}
            placeholder={t('directMutations.amountPlaceholder')}
            required
          />
        </Grid>
      </FieldWrapper>
      <FieldWrapper container spacing={3}>
        <Grid item xs={6}>
          <FormInputPercentage
            name={`${rootForm}.interestPercentage`}
            label={t('directMutations.loanParts.interestPercentage')}
            required
          />
        </Grid>
        <Grid item xs>
          <FormInputNumber
            name={`${rootForm}.fixedRatePeriod`}
            label={t('directMutations.loanParts.fixedRatePeriod')}
            placeholder={t('directMutations.loanParts.placeholderRvp')}
            max={999}
            required
          />
        </Grid>
      </FieldWrapper>
      <FieldWrapper container spacing={3}>
        <Grid item xs={6}>
          <FormInputCurrency
            name={`${rootForm}.box1`}
            label={t('directMutations.loanParts.box1')}
            placeholder={t('directMutations.amountPlaceholder')}
            required
            min={0}
          />
        </Grid>
        <Grid item xs>
          <FormInputDate
            name={`${rootForm}.endDateInterestDeduction`}
            label={t('directMutations.loanParts.endDateInterestDeduction')}
            required
          />
        </Grid>
      </FieldWrapper>
      <FieldWrapper container spacing={3}>
        <Grid item xs={6}>
          <FormInputCurrency
            name={`${rootForm}.box3`}
            label={t('directMutations.loanParts.box3')}
            placeholder={t('directMutations.amountPlaceholder')}
            required
            min={0}
          />
        </Grid>
      </FieldWrapper>
      <FieldWrapper container spacing={3}>
        <Grid item xs={6}>
          <FormToggleButtonGroup
            name={`${rootForm}.NHGGuarantee`}
            label={t('directMutations.loanParts.NHGGuarantee')}
            options={yesNoGroupOptions}
            required
          />
        </Grid>
      </FieldWrapper>
    </>
  );
};
export default NewLoanPart;
