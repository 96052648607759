import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Box, Icon, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { ReactComponent as ChevronDown } from '../../assets/images/icons/select-icon.svg';
import SearchBar from '../SearchBar';
import Logo from '../Logo';
import { MenuItem } from './MultiSelect.styled';

type StyleProps = {
  width: number | undefined;
};

const useStyles = makeStyles((theme) => ({
  label: {
    margin: '0 8px 0 0',
    fontSize: '12px',
    fontWeight: 400,
  },
  selectMenu: {
    'maxHeight': 420,
    'padding': theme.spacing(0, 1),
    'width': (props: StyleProps) => props.width,
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  logo: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },

  menuItemSearch: {
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: 'transparent',
    },
  },
  spacer: {
    flex: 1,
  },
  placeholder: {
    width: 20,
  },
  selectRoot: {
    [theme.breakpoints.down('lg')]: {
      flex: 1,
      textAlign: 'right',
    },
  },
}));

type Option = {
  value: string;
  text: string;
};

type Props = {
  label: string;
  selectedValue: string[];
  handleChange: (e: SelectChangeEvent<string[]>) => void;
  options: string[] | Option[];
  width?: number;
  showImg?: boolean;
  selectAll?: boolean;
  search?: boolean;
};

const MultiSelect: FC<Props> = ({
  label,
  selectedValue,
  handleChange,
  options,
  width,
  showImg = false,
  selectAll = false,
  search = false,
}) => {
  const classes = useStyles({ width: width });
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = React.useState('');
  const handleSearch = (e: { currentTarget: { value: React.SetStateAction<string> } }) => {
    setSearchTerm(e.currentTarget.value);
  };

  const convertToOptions = (options: string[] | Option[]): Option[] => {
    if (options.length > 0 && typeof options[0] === 'string') {
      return options.map((label) => ({ value: label, text: label })) as Option[];
    }
    return options as Option[];
  };

  const optionList: Option[] = convertToOptions(options);

  const emptySearch = () => {
    setSearchTerm('');
  };

  const onInputKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      emptySearch();
    }
    e.stopPropagation();
  };

  const filterAndHandleChange = (e: SelectChangeEvent<string[]>) => {
    e.target.value = (e.target.value as string[]).filter((item) => item !== undefined);
    handleChange(e);
  };

  const results = searchTerm
    ? optionList.filter((option) => option.text.toLowerCase().includes(searchTerm.toLowerCase()))
    : optionList;

  return (
    <Box display="flex" alignItems="center" data-testid={`multiSelect-input-${label}`}>
      <InputLabel className={classes.label} id={label}>
        {t(`select.${label}`)}
      </InputLabel>
      <Select
        className={classes.selectRoot}
        labelId={label}
        name={label}
        value={selectedValue}
        onChange={filterAndHandleChange}
        multiple
        variant="standard"
        disableUnderline
        IconComponent={(props) => (
          <ChevronDown
            {...props}
            style={{ width: 12, right: 0, top: 0, position: 'absolute', pointerEvents: 'none' }}
          />
        )}
        MenuProps={{
          disableScrollLock: true,
          classes: { paper: classes.selectMenu },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }}
        native={false}
        renderValue={(selected) => {
          if ((selected as string[]).some((s) => s === 'all')) {
            return t('applications.filter.all');
          }

          const selectedOptions = optionList.filter((option) =>
            (selected as string[]).includes(option.value)
          );
          return selectedOptions.map((option) => option.text).join(', ');
        }}
      >
        {search && (
          <MenuItem
            onClickCapture={(e) => e.preventDefault()}
            className={classes.menuItemSearch}
            disableTouchRipple
          >
            <SearchBar
              placeholder={t('applications.filter.searchMoneylender')}
              searchTerm={searchTerm}
              handleInputChange={handleSearch}
              emptySearch={emptySearch}
              onKeyDown={onInputKeyPress}
              width="100%"
            />
          </MenuItem>
        )}
        {selectAll && (
          <MenuItem value="all" disableTouchRipple>
            {t('applications.filter.allLong')}
            <span className={classes.spacer} />
            <Icon
              style={{
                visibility: selectedValue.some((value) => value === 'all') ? 'visible' : 'hidden',
                marginLeft: 18,
              }}
              color="primary"
            >
              checked
            </Icon>
          </MenuItem>
        )}
        {results.map((option: Option) => (
          <MenuItem key={option.value} value={option.value} disableTouchRipple>
            {showImg && <Logo className={classes.logo} label={option.value} alt="logo" />}
            {option.text}
            <span className={classes.spacer} />
            <Icon
              style={{
                visibility: selectedValue.some((value) => value === option.value)
                  ? 'visible'
                  : 'hidden',
                marginLeft: 18,
              }}
              color="primary"
            >
              checked
            </Icon>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default MultiSelect;
