import { Grid } from '@mui/material';
import { FC } from 'react';
import ContentBlock from '../Molecules/components/ContentBlock';
import HeaderApplication from '../Molecules/components/HeaderApplication';
import { ContainerGrid, ContentGrid, SideBar, SidebarGrid } from './FormTemplate.styled';

export type FormTemplateProps = {
  header: React.ReactNode;
  sidebar: React.ReactNode;
  footer?: React.ReactNode;
  ispopup?: boolean;
  children: React.ReactNode;
};

const FormTemplate: FC<FormTemplateProps> = ({ header, sidebar, footer, ispopup, children }) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <HeaderApplication ispopup={ispopup} height={72}>
          {header}
        </HeaderApplication>
      </Grid>
      <Grid item>
        <ContainerGrid container direction="row">
          <SidebarGrid item data-testid="formTemplate-menu">
            <SideBar data-testid="formTemplate-menu-list">{sidebar}</SideBar>
          </SidebarGrid>
          <ContentGrid item>
            <ContentBlock>{children}</ContentBlock>
          </ContentGrid>
        </ContainerGrid>
        {footer}
      </Grid>
    </Grid>
  );
};

export default FormTemplate;
