import { mapEnum } from 'utils/global';
import { Country } from '__generated__/api';
import { schemaDraft07 } from './constants';

const schema = {
  $schema: schemaDraft07,
  $id: 'https://e-adviseur.stater.com/buytolet/applicant-natural-person-contact-data',
  title: 'Applicant natural person contact-data',
  type: 'object',
  properties: {
    street: {
      type: 'string',
      minLength: 1,
      maxLength: 100,
    },
    houseNumber: {
      type: 'integer',
      minimum: 1,
      maximum: 99999,
    },
    houseNumberExtension: {
      type: ['string', 'null'],
      maxLength: 10,
    },
    postalCode: {
      type: 'string',
      minLength: 1,
      maxLength: 12,
    },
    city: {
      type: 'string',
      minLength: 1,
      maxLength: 35,
    },
    country: {
      enum: mapEnum(Country),
    },
    privatePhoneNumber: {
      type: ['string', 'null'],
      maxLength: 10,
      pattern: '^$|^0[0-9]{9}$',
    },
    mobilePhoneNumber: {
      type: 'string',
      minLength: 1,
      maxLength: 10,
      pattern: '^06[1-9][0-9]{7}$',
    },
    workPhoneNumber: {
      type: ['string', 'null'],
      maxLength: 10,
      pattern: '^$|^0[0-9]{9}$',
    },
    emailAddress: {
      type: 'string',
      minLength: 1,
      maxLength: 300,
      oneOf: [
        { type: 'string', pattern: '^$' },
        { type: 'string', format: 'email' },
      ],
    },
  },
  allOf: [
    {
      if: {
        properties: {
          country: {
            const: Country.NLD,
          },
        },
      },
      then: {
        properties: {
          postalCode: {
            pattern: '^[1-9][0-9]{3}(?!sa|sd|ss)[a-zA-Z]{2}$',
          },
        },
      },
    },
  ],

  required: [
    'street',
    'houseNumber',
    'postalCode',
    'city',
    'country',
    'mobilePhoneNumber',
    'emailAddress',
  ],
};

export default schema;
