import { FC } from 'react';
import { setUser } from '../auth/reducer';
import DummyLogin from './DummyLogin/DummyLogin';
import { getAccountNameClaim } from '../auth/oidc';
import { User } from 'oidc-client';
import { useDispatch } from 'react-redux';
import AuthProvider from 'auth/AuthProvider';
import { LoginType } from 'auth/types';

type Props = {
  loginType?: LoginType;
  children: React.ReactNode;
};

const AuthWrapper: FC<Props> = ({ children, loginType = 'keycloak' }) => {
  const dispatch = useDispatch();

  if (loginType === 'keycloak-dummy' || process.env.REACT_APP_DISABLE_AUTH === 'true')
    return <DummyLogin>{children}</DummyLogin>;

  const onSignIn = (user: User) => {
    const claim = user.profile[getAccountNameClaim()];

    if (claim) dispatch(setUser(claim));
  };

  return <AuthProvider onSignIn={onSignIn}>{children}</AuthProvider>;
};

export default AuthWrapper;
