import { useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import SearchBar from 'components/SearchBar';
import { useMoneylenders } from 'use/clients';
import { ActiveMoneylender } from 'types/Client';
import { useClients } from './hooks/useClients';
import ClientList from './components/ClientList/ClientList';
import ClientsHeader from './components/ClientHeader/ClientHeader';
import DesktopFilterArea from './components/Filters/DesktopFilterArea';
import MobileFilterArea from './components/Filters/MobileFilterArea';
import ClientsTemplate from '../templates/Lists/ListWrapper/WrapperTemplate';
import ClientDetail from './components/ClientDetail';

function GenericClients() {
  const { t } = useTranslation();
  const {
    clientsQueryResult,
    onCloseClient,
    onSelectClient,
    page,
    selectedClient,
    filterState,
    searchTerm,
    filteredClients,
    handleSearch,
    handlePage,
    handleFilter,
  } = useClients();
  const { data: moneylenders } = useMoneylenders();
  const [activeMoneylenders, setActiveMoneylenders] = useState<ActiveMoneylender[]>();
  const { data: clientsData, isLoading: isClientLoading } = clientsQueryResult;

  useEffect(() => {
    localStorage.removeItem('commercialRelation');
  }, []);

  useEffect(() => {
    if (moneylenders) {
      const list: ActiveMoneylender[] = [];
      moneylenders.forEach((moneylender) => {
        if (!list.some((m) => m.value === moneylender.commercialLabel)) {
          list.push({ value: moneylender.commercialLabel, text: moneylender.moneylenderName });
        }
      });
      setActiveMoneylenders(list);
    }
  }, [moneylenders]);

  const handleSelectSingleValues = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    handleFilter({ name, value });
  };

  const handleSelectMultiValues = (e: SelectChangeEvent<string[]>) => {
    const { name, value } = e.target;
    const newValues =
      value[value.length - 1] === 'all' || value.length === 0
        ? ['all']
        : (value as string[]).filter((item) => item !== 'all');

    handleFilter({ name, value: newValues });
  };

  const handleInputChange = (e: ChangeEvent<any>) => {
    const value = e.currentTarget.value;
    handleSearch(value);
  };

  const emptySearch = () => {
    handleSearch('');
  };

  return (
    <>
      <ClientsTemplate
        header={<ClientsHeader handleFilter={handleFilter} filterState={filterState} />}
        searchBar={
          <SearchBar
            placeholder={t('clients.filter.search')}
            searchTerm={searchTerm}
            handleInputChange={handleInputChange}
            emptySearch={emptySearch}
            width={260}
          />
        }
        filterArea={
          <DesktopFilterArea
            activeMoneylenders={activeMoneylenders}
            filterState={filterState}
            handleSelectSingleValues={handleSelectSingleValues}
            handleSelectMultiValues={handleSelectMultiValues}
          />
        }
        list={
          <ClientList
            clients={filteredClients}
            onClickItem={onSelectClient}
            onPage={handlePage}
            page={page}
            totalClients={clientsData?.total}
            isLoading={isClientLoading}
          />
        }
        mobileFilterArea={
          <MobileFilterArea
            activeMoneylenders={activeMoneylenders}
            filterState={filterState}
            handleSelectSingleValues={handleSelectSingleValues}
            handleSelectMultiValues={handleSelectMultiValues}
          />
        }
      />
      {selectedClient && <ClientDetail client={selectedClient} onClose={onCloseClient} />}
    </>
  );
}

export default GenericClients;
