import { useEffect } from 'react';
import { getCookie, setCookie } from 'utils/cookie';

export const useExpoints = () => {
  const id = '07294015-6300-4ced-ad01-4e303d8a3cae';
  const PR = (window as any).REACT_APP?.ENVIRONMENT === 'PR';

  const includeExpoints = () => {
    if (!(window as any).lightningjs) {
      const exp = document.createElement('script');
      exp.id = 'expoints-script';
      exp.type = 'text/javascript';
      exp.async = true;
      exp.onload = initializeExpoints;
      exp.src = 'https://stater1.expoints.nl/m/Scripts/dist/expoints-external-loader.min.js';
      const s = document.getElementsByTagName('script')[0];
      if (s && s.parentNode) s.parentNode.insertBefore(exp, s);
    } else {
      initializeExpoints();
    }
  };

  const initializeExpoints = () => {
    if (!(window as any).expoints) {
      (window as any).expoints = (window as any).lightningjs.require(
        'expoints',
        'https://stater1.expoints.nl/m/Scripts/dist/expoints-external.min.js'
      );
      (window as any).expoints('start', id, {
        instanceUrl: 'https://stater1.expoints.nl',
        isTest: !PR,
      });
    }
  };

  const callMutationFinishedSurvey = (form: string) => {
    if (!cookieExists(form, 'finished')) {
      saveCookie(form, 'finished');
      showExpointsSurvey('finished_mut_popin');
    }
  };

  const callMutationTerminatedSurvey = (form: string) => {
    if (!cookieExists(form, 'terminated')) {
      saveCookie(form, 'terminated');
      showExpointsSurvey('terminated_mut_popin');
    }
  };

  const showExpointsSurvey = (survey: string) => {
    if ((window as any).expoints) {
      (window as any).expoints.trigger(survey);
    } else {
      includeExpoints();
      setTimeout(() => {
        showExpointsSurvey(survey);
      }, 1000);
    }
  };

  const cookieKey = (form: string, action: string) => {
    return `${form}_${action}`;
  };

  const cookieExists = (form: string, action: string) => {
    const key = cookieKey(form, action);

    const value = getCookie('MutationSurvey');
    if (value === null) return false;
    const values = value.split(',');

    return values.indexOf(key) > -1;
  };

  const saveCookie = (form: string, action: string) => {
    if (cookieExists(form, action)) return;

    const key = cookieKey(form, action);
    let value = getCookie('MutationSurvey') ?? '';
    const comma = value === '' ? '' : ',';
    value = `${value}${comma}${key}`;
    setCookie('MutationSurvey', value);
  };

  useEffect(() => {
    includeExpoints();

    return () => {
      const expointsScript = document.getElementById('expoints-script');
      expointsScript?.parentNode?.removeChild(expointsScript);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    callMutationFinishedSurvey,
    callMutationTerminatedSurvey,
  };
};
