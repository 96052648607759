import { Dialog, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Close from '@mui/icons-material/Close';
import OnboardingStep from './OnboardingStep';
import { SlideUpTransition } from '../../lib/Transition/transition';
import { useOnboardingNotification } from 'use/useOnboardingNotification';
import { useUserNotifications } from 'use/userNotifications';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    backgroundColor: 'rgb(247, 245, 251)',
  },
  close: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },

  dialog: {
    backgroundColor: 'rgb(247, 245, 251)',
  },
}));

const OnboardingJourneyDialog = () => {
  const classes = useStyles();

  const { setJourneyVisible, onboardingJourneyVisible } = useOnboardingNotification();
  const { setWelcomeClosed } = useUserNotifications();

  const onClose = () => {
    setJourneyVisible(false);
    setWelcomeClosed(true);
  };

  return (
    <Dialog
      classes={{
        paperFullScreen: classes.dialog,
      }}
      fullScreen
      open={onboardingJourneyVisible}
      onClose={() => onClose()}
      TransitionComponent={SlideUpTransition}
    >
      <div className={classes.root}>
        <OnboardingStep onClose={() => onClose()} />
        <IconButton className={classes.close} onClick={() => onClose()} size="large">
          <Close />
        </IconButton>
      </div>
    </Dialog>
  );
};

export default OnboardingJourneyDialog;
