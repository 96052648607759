import i18n from 'i18n';

export const formatDuration = (months: number | undefined | null): string | undefined => {
  if (!months) return undefined;
  const translationsRoot = 'general.duration';

  if (months === 1) {
    return `1 ${i18n.t(`${translationsRoot}.month`)}`;
  }
  if (months === 12) {
    return `1 ${i18n.t(`${translationsRoot}.year`)}`;
  }

  if (months % 12 === 0) {
    return `${months / 12} ${i18n.t(`${translationsRoot}.years`)}`;
  }

  return `${months} ${i18n.t(`${translationsRoot}.months`)}`;
};

export default formatDuration;
