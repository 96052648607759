import name from 'utils/form/validations/name';
import { lazy, number, object, string } from 'yup';
import { RootFormName } from '../../utils/types';
import { errorMessages, NO_NUMBER_AND_SPECIAL_CHAR_REGX } from 'screens/Mutations/constants';
import { Gender } from '__generated__/api';
import { defaultDate } from 'utils/form/validations';
import { enumerable } from 'utils/form/validations/enumerable';

export const personalInfoSchema = (rootFormName: RootFormName) =>
  lazy(({ hasNewApplicant }) => {
    if (rootFormName === 'currentApplicant') return applicantSchema(rootFormName);

    if (hasNewApplicant === 'yes' && rootFormName === 'newApplicant')
      return applicantSchema(rootFormName);

    return object().shape({
      hasNewApplicant: string().typeError(errorMessages.required).required(errorMessages.required),
    });
  });

export const applicantSchema = (rootFormName: RootFormName) =>
  object().shape({
    initials: string()
      .matches(NO_NUMBER_AND_SPECIAL_CHAR_REGX, errorMessages.noNumberAndSpecialCharacter)
      .typeError(errorMessages.required)
      .required(errorMessages.required),
    firstNames: name.required(errorMessages.required),
    insertion: string()
      .matches(NO_NUMBER_AND_SPECIAL_CHAR_REGX, errorMessages.noNumberAndSpecialCharacter)
      .typeError(errorMessages.required)
      .nullable(),
    surname: name.required(errorMessages.required),
    dateOfBirth: defaultDate.isPastDate(errorMessages.PastDate).required(errorMessages.required),
    maritalStatusVSOld:
      rootFormName === 'currentApplicant'
        ? string().required(errorMessages.required)
        : string().nullable(),
    maritalStatus: string().required(errorMessages.required),
    numberOfDivorces: number().required(errorMessages.required),
    children: number().required(errorMessages.required).typeError(errorMessages.number),
    homeOwner: string().required(errorMessages.required).typeError(errorMessages.required),
  });

export const basePersonalInfoSchema = object({
  initials: string()
    .matches(NO_NUMBER_AND_SPECIAL_CHAR_REGX, errorMessages.noNumberAndSpecialCharacter)
    .typeError(errorMessages.required)
    .required(errorMessages.required),
  firstNames: name.required(errorMessages.required),
  insertion: string()
    .matches(NO_NUMBER_AND_SPECIAL_CHAR_REGX, errorMessages.noNumberAndSpecialCharacter)
    .typeError(errorMessages.required)
    .nullable(),
  surname: name.required(errorMessages.required),
  dateOfBirth: defaultDate.isPastDate(errorMessages.PastDate).required(errorMessages.required),
  gender: enumerable(Gender).required(errorMessages.required),
  maritalStatus: string().required(errorMessages.required),
});
