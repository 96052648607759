import { LoanPartType } from 'screens/Mutations/utils/types';

export default function hasConnectedPolicies(loanParts: LoanPartType[]) {
  if (loanParts) {
    return loanParts.some(
      (loanPart) => loanPart.hasConnectedPolicy && loanPart.hasConnectedPolicy === 'yes'
    );
  }
  return false;
}
