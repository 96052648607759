import MultiSelect from 'components/MultiSelect';
import SingleSelect from 'components/Select';
import { eConsumerLinkOptions } from 'screens/Clients/constants';
import { ClientFilterAreaProps } from 'types/Client';

export default function DesktopFilterArea({
  activeMoneylenders,
  filterState,
  handleSelectSingleValues,
  handleSelectMultiValues,
}: ClientFilterAreaProps) {
  const { eConsumerLink, moneylenderName } = filterState;

  return (
    <>
      <SingleSelect
        label="eConsumerLink"
        selectedValue={eConsumerLink}
        handleChange={handleSelectSingleValues}
        options={eConsumerLinkOptions}
        component="clients"
      />
      {activeMoneylenders && handleSelectMultiValues && (
        <MultiSelect
          label="moneylenderName"
          selectedValue={moneylenderName}
          handleChange={handleSelectMultiValues}
          options={activeMoneylenders}
          showImg
          selectAll
          search
          width={300}
        />
      )}
    </>
  );
}
