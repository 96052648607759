import { useTranslation } from 'react-i18next';
import { Dialog, IconButton, Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { useOnboardingNotification } from 'use/useOnboardingNotification';
import { SlideUpTransition } from '../../lib/Transition/transition';
import { useEffect, useState } from 'react';
import { checkCookies, checkFirstVisitCookies, setFirstVisitCookies } from 'utils/global';
import { useUserNotifications } from 'use/userNotifications';

const useStyles = makeStyles((theme) => ({
  root: {
    'position': 'relative',
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'justifyContent': 'center',
    'padding': theme.spacing(4),
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  close: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  logo: {
    width: 42,
    height: 42,
  },
}));

const OnboardingWelcomeDialog = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const firstVisitCookie = checkFirstVisitCookies();
  const cookies = checkCookies();
  const [popUpVisible, setPopUpVisible] = useState(false);

  const { setJourneyVisible } = useOnboardingNotification();
  const { setWelcomeClosed, setFirstVisitTrigger } = useUserNotifications();

  useEffect(() => {
    // This makes that the open just trigger after the cookies are handled
    if (cookies == null) return;
    if (!firstVisitCookie) {
      setPopUpVisible(true);
      setWelcomeClosed(false);
      setFirstVisitTrigger(true);
    } else {
      setFirstVisitTrigger(false);
    }
  }, [firstVisitCookie, cookies, setFirstVisitTrigger, setWelcomeClosed, setPopUpVisible]);

  const onDoLater = () => {
    setFirstVisitCookies();
    setPopUpVisible(false);
    setWelcomeClosed(true);
  };
  const onClickLetsGo = () => {
    setFirstVisitCookies();
    setPopUpVisible(false);
    setJourneyVisible(true);
    setWelcomeClosed(false);
  };

  return (
    <Dialog
      open={popUpVisible}
      onClose={onDoLater}
      TransitionComponent={SlideUpTransition}
      fullWidth
      maxWidth="md"
    >
      <div className={classes.root}>
        <IconButton className={classes.close} onClick={() => onDoLater()} size="large">
          <Close />
        </IconButton>

        <Typography variant="h3" color="textSecondary">
          {t('onboarding.welcome.title')}
        </Typography>
        <Typography color="textSecondary">{t('onboarding.welcome.text')}</Typography>
        <Button color="primary" onClick={() => onClickLetsGo()}>
          {t('onboarding.welcome.letsGo')}
        </Button>
        <Button color="primary" onClick={() => onDoLater()}>
          {t('onboarding.welcome.doLater')}
        </Button>
      </div>
    </Dialog>
  );
};

export default OnboardingWelcomeDialog;
