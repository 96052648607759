import { CardSection, SummaryCard, CardRow } from 'components/SummaryCard';
import { format } from 'date-fns';
import { formatLoanNumber } from 'utils/global';
import { t } from 'i18next';
import { getTypeOfFinancingLabel } from 'screens/Mutations/constants';
import { LoanPartType } from 'screens/Mutations/utils/types';
import { formatAmount } from 'utils/formatAmount';
import { formatYesNo } from 'utils/formatYesNo/formatYesNo';
import { renderConvertLoan } from './renderConvertLoan';
import { renderSplitLoan } from './renderSplitLoan';

export const renderLoanPart = (
  loanPart: LoanPartType,
  loanNumber: number,
  handleOnClick: (step: string) => () => void,
  type: string,
  index: number
) => (
  <SummaryCard
    title={t(`directMutations.loan.${type}LoanPartsFormTitle`)}
    onEditClick={handleOnClick('financingLoan')}
    key={loanPart.loanPartNumber}
  >
    <CardSection
      title={`${t('directMutations.form.part')} ${
        type === 'current' ? loanPart.loanPartNumber : index + 1
      }`}
    >
      {type === 'current' && (
        <CardRow name={t('directMutations.form.loanNumber')} value={formatLoanNumber(loanNumber)} />
      )}
      <CardRow name={t('directMutations.form.loanPart')} value={loanPart.loanPartNumber} />
      {type === 'current' && (
        <CardRow
          name={t('directMutations.summary.loanParts.connectedPolicy')}
          value={formatYesNo(loanPart.hasConnectedPolicy)}
        />
      )}
      <CardRow name={t('directMutations.form.repaymentType')} value={loanPart.repaymentType} />
      <CardRow
        name={`${
          type === 'current'
            ? t('directMutations.summary.loanParts.changePeriod')
            : t('directMutations.summary.loanParts.period')
        } `}
        value={loanPart.newDuration}
      />
      <CardRow
        name={t('directMutations.form.loanAmount')}
        value={formatAmount(loanPart.loanAmount)}
      />
      {type === 'current' && (
        <CardRow
          name={t('directMutations.summary.loanParts.interestRedemption')}
          value={loanPart.typeOfFinancing && getTypeOfFinancingLabel(loanPart.typeOfFinancing)}
        />
      )}
      <CardRow
        name={t('directMutations.loanParts.interestPercentage')}
        value={`${loanPart.interestPercentage}%`}
      />
      <CardRow
        name={t('directMutations.loanParts.fixedRatePeriod')}
        value={loanPart.fixedRatePeriod}
      />
      <CardRow
        name={t('directMutations.obligation.amountTaxBox1')}
        value={formatAmount(loanPart.box1)}
      />
      <CardRow
        name={t('directMutations.loanParts.endDateInterestDeduction')}
        value={
          loanPart.endDateInterestDeduction
            ? format(new Date(loanPart.endDateInterestDeduction), 'dd-MM-yyyy')
            : undefined
        }
      />
      <CardRow
        name={t('directMutations.obligation.amountTaxBox3')}
        value={formatAmount(loanPart.box3)}
      />
      <CardRow
        name={t('directMutations.summary.loanParts.nhg')}
        value={formatYesNo(loanPart.NHGGuarantee)}
      />
      {type === 'current' && (
        <CardRow
          name={t('directMutations.summary.loanParts.splitLoan')}
          value={formatYesNo(loanPart.shouldSplitLoan)}
        />
      )}
    </CardSection>
    {type === 'current' && loanPart.shouldSplitLoan === 'yes' && renderSplitLoan(loanPart)}
    {type === 'current' && loanPart.shouldSplitLoan === 'no' && renderConvertLoan(loanPart)}
  </SummaryCard>
);
