import { useQuery } from 'react-query';
import { getRoleFromToken } from '../auth/oidc';

const getRole = async (): Promise<string[] | null> => {
  const role = await getRoleFromToken();
  return role;
};

export function useRole() {
  return useQuery(['role'], () => getRole());
}
