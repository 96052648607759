import { alpha2ToAlpha3 } from 'i18n-iso-countries';
import {
  ClientDetailsLoan,
  ClientDetailsLoanParts,
  ClientDetailsPerson,
} from 'types/ClientDetailsModel';
import { incomeFormTypeDefaultValues } from 'screens/Mutations/constants';
import { ClientExtractLoanPartGuarantee } from '__generated__/api-di';
import {
  Applicant,
  ContactDetailsFormType,
  LoanFormType,
  LoanPartType,
  ObligationFormType,
  OHAForm,
  PersonalInfoFormType,
  ToggleType,
} from '../../types';

const mapGuarantee = (
  value: ClientExtractLoanPartGuarantee | undefined
): ToggleType | undefined => {
  if (
    value === ClientExtractLoanPartGuarantee.NHG ||
    value === ClientExtractLoanPartGuarantee.MunicipalWarranty
  ) {
    return 'yes';
  }
  if (
    value === ClientExtractLoanPartGuarantee.None ||
    value === ClientExtractLoanPartGuarantee.VAT
  ) {
    return 'no';
  }
  return undefined;
};

const mapApplicant = (person?: ClientDetailsPerson): Applicant => {
  const initials = person?.natural?.name?.initials || '';
  return {
    personalInfo: {
      initials: initials.replace('.', ''),
      firstNames: person?.natural?.name?.firstName,
      insertion: person?.natural?.name?.lastNamePrefix,
      surname: person?.natural?.name?.lastName,
      dateOfBirth: person?.natural?.dateOfBirth,
      maritalStatus: person?.natural?.maritalStatus,
    } as PersonalInfoFormType,
    contactDetails: {
      street: person?.contact?.address?.street,
      houseNumber: person?.contact?.address?.houseNumber
        ? parseInt(person?.contact?.address?.houseNumber, 10)
        : undefined,
      houseNumberSuffix: person?.contact?.address?.houseNumber
        ? person?.contact?.address?.houseNumber.replace(/[0-9]/g, '')
        : undefined,
      postalCode: person?.contact?.address?.postalCode,
      city: person?.contact?.address?.city,
      country: alpha2ToAlpha3(person?.contact?.address?.country?.code ?? ''),
      phoneNumber: person?.contact?.phoneNumber,
      email: person?.contact?.emailAddress,
    } as ContactDetailsFormType,
    income: incomeFormTypeDefaultValues,
    obligation: {
      obligations: [],
    } as ObligationFormType,
  };
};

const mapLoanParts = (loanParts: ClientDetailsLoanParts[] = []): LoanPartType[] =>
  loanParts.map((loanPart) => ({
    loanPartNumber: loanPart.number ?? undefined,
    repaymentType: loanPart.product ?? undefined,
    NHGGuarantee: mapGuarantee(loanPart.guarantee),
    loanAmount: loanPart.residualDebt ?? undefined,
    interestPercentage: loanPart.interestPeriod?.effectiveInterestRate ?? undefined,
    fixedRatePeriod: loanPart.interestPeriod?.duration ?? undefined,
  }));

const mapLoan = (loanParts: ClientDetailsLoanParts[], loan?: ClientDetailsLoan): LoanFormType => ({
  loanNumber: loan?.number ?? 0,
  amount: loan?.residualDebt ?? 0,
  changeCurrentMortgage: 'yes' as ToggleType,
  currentLoanParts: mapLoanParts(loanParts),
  newLoanParts: [],
  policies: [],
});

export const mapClientData = (
  loanParts: ClientDetailsLoanParts[] = [],
  person?: ClientDetailsPerson,
  loan?: ClientDetailsLoan
): OHAForm =>
  ({
    currentApplicant: mapApplicant(person),
    loan: mapLoan(loanParts, loan),
  } as OHAForm);
export default mapClientData;
