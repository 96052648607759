import {
  Hidden,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Case } from '__generated__/api-di';
import ApplicationListItem from './ApplicationListItem';

type ApplicationDesktopProps = {
  items: Case[];
  onClickItem: (item: Case) => void;
  rowsPerPage: number;
  page: number;
};

const ApplicationDesktop: FC<ApplicationDesktopProps> = ({
  items,
  onClickItem,
  rowsPerPage,
  page,
}) => {
  const { t } = useTranslation();
  return (
    <Hidden smDown>
      <TableContainer data-testid="applicationList-desktop">
        <Table aria-label="applications table">
          <TableHead>
            <TableRow>
              <TableCell>{t('applications.table.applicationName')}</TableCell>
              <TableCell>{t('applications.table.type')}</TableCell>
              <TableCell>{t('applications.table.loanNumber')}</TableCell>
              <TableCell>{t('applications.table.date')}</TableCell>
              <TableCell>{t('applications.table.proofItems')}</TableCell>
              <TableCell>{t('applications.table.status')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody data-testid="items">
            {(rowsPerPage > 0
              ? items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : items
            ).map((item, index) => (
              <ApplicationListItem
                onClickItem={onClickItem}
                application={item}
                key={`${item.lender!.label}${item.application!.loanNumberExternal}${
                  item.application!.index
                }${index}`}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Hidden>
  );
};

export default ApplicationDesktop;
