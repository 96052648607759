import { ProofItemStatus, getProofItemStatus } from 'utils/proof-item';
import { ProofItem } from 'types/ProofItem';
import i18n from 'i18next';
import formatDate from 'utils/formatDate';

const resourceDate = (resourceKey: string, date: string | null) => {
  if (date === null || date === '' || date === '0001-01-01T00:00:00') {
    return '';
  }

  return i18n.t(`proofitem.date.${resourceKey}`, { date: formatDate(date) });
};

export const getProofItemDescription = (proofItem: ProofItem) => {
  const status = getProofItemStatus(proofItem.status);
  switch (status) {
    case ProofItemStatus.approved:
      return resourceDate('approved', proofItem.dateApproved ?? null);
    case ProofItemStatus.cancelled:
      return resourceDate('cancelled', proofItem.dateRejected ?? null);
    case ProofItemStatus.pendingApproval:
      return resourceDate('pendingApproval', proofItem.dateReceived ?? null);
    case ProofItemStatus.rejected:
      return resourceDate('rejected', proofItem.dateRejected ?? null);
    case ProofItemStatus.turnedDown:
      return resourceDate('turnedDown', proofItem.dateRejected ?? null);
    case ProofItemStatus.waiting:
      return resourceDate('waiting', proofItem.dateLastReminder ?? null);
  }
  return '';
};
