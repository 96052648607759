import { FC, ReactNode, useState } from 'react';
import {
  Grid,
  GridSize,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormField } from './FormField';

type ToggleButtonQuestionAnswer = {
  id: string;
  value?: boolean;
  answerText?: string;
  answerTextKey?: string;
};

interface ToggleButtonQuestionProps extends ToggleButtonGroupProps {
  answerOptions: ToggleButtonQuestionAnswer[];
  toolTip?: ReactNode;
  containerWidth?: GridSize;
  label?: ReactNode;
  helperText?: ReactNode;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  focused?: boolean;
  placeholder?:string
}

const ToggleButtonQuestion: FC<ToggleButtonQuestionProps> = ({
  answerOptions,
  toolTip,
  containerWidth,
  label,
  error,
  required,
  helperText,
  disabled,
  focused,
  ...props
}) => {
  const { t } = useTranslation();
  const [hasFocus, setHasFocus] = useState<boolean>(focused ?? false);

  return (
    <FormField
      open
      label={label}
      tooltip={toolTip}
      required={required}
      helperText={helperText}
      disabled={disabled}
      focused={hasFocus}
    >
      <Grid container item spacing={3}>
        <Grid
          item
          container
          xs={containerWidth ?? 8}
          sm={containerWidth ?? 6}
          md={containerWidth ?? 4}
          lg={containerWidth ?? 3}
        >
          <ToggleButtonGroup
            {...props}
            size="medium"
            style={{ width: '100%' }}
            onFocus={(e) => {
              setHasFocus(true);
              props.onFocus && props.onFocus(e);
            }}
            onBlur={(e) => {
              setHasFocus(false);
              props.onBlur && props.onBlur(e);
            }}
          >
            {answerOptions.map((answer) => (
              <ToggleButton
                key={answer.id}
                name={answer.id}
                value={answer.value ?? answer.id}
                style={{ width: '100%' }}
              >
                {t(`${answer.answerTextKey}`)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </FormField>
  );
};

export default ToggleButtonQuestion;
