import { object, string } from 'yup';

const urlRegex = /(https?:\/\/)?(www\.[^\s]+)|(@)/i;
export const getFeedbackResolver = object().shape({
  subject: string().required('Velden met een * zijn verplicht'),
  page: string(),
  feedback: string()
    .test(
      'no-url',
      "Url's en e-mailadressen zijn niet toegestaan",
      (value) => !urlRegex.test(value!)
    )
    .required('Velden met een * zijn verplicht'),
  emailAddress: string()
    .email('Vul een geldig e-mailadres in')
    .required('Velden met een * zijn verplicht'),
});
