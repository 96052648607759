import { FC, useState } from 'react';
import { Button, FormControlLabel, Radio } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from './PrefillOptions.styled';
import { ClientDetailsPerson } from 'types/ClientDetailsModel';
import formatFullName from 'utils/formatFullName/formatFullName';

type Props = {
  persons?: ClientDetailsPerson[];
  onClick: (person?: ClientDetailsPerson) => void;
};

const PrefillOptions: FC<Props> = ({ persons = [], onClick }) => {
  const { t } = useTranslation();
  const [personName, setPersonName] = useState<string | undefined>(undefined);

  const handleClickOption = () => {
    onClick(
      persons.find((person) => {
        const fullName = formatFullName(
          person.natural?.name?.initials,
          person.natural?.name?.lastNamePrefix,
          person.natural?.name?.lastName
        );

        return fullName === personName;
      })
    );
  };

  return (
    <>
      <RadioGroup aria-label="prefill-names" name="prefill-names">
        {persons.map((person) => {
          const fullName = formatFullName(
            person.natural?.name?.initials,
            person.natural?.name?.lastNamePrefix,
            person.natural?.name?.lastName
          );
          return (
            <FormControlLabel
              value={fullName}
              control={<Radio color="primary" onChange={() => setPersonName(fullName)} />}
              label={fullName}
              key={fullName}
            />
          );
        })}
        <FormControlLabel
          value=""
          control={<Radio color="primary" onChange={() => setPersonName('noPerson')} />}
          label={t('directMutations.prefill.otherOption')}
        />
      </RadioGroup>

      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOption}
        disabled={!personName}
      >
        {t('directMutations.prefill.submit')}
      </Button>
    </>
  );
};

export default PrefillOptions;
