import { styled } from '@mui/styles';
import { Container as MuiContainer, Grid, Typography, Button, Box } from '@mui/material';
import Header from 'components/Header';
import { alpha } from '@mui/material/styles';

export const Container = styled(MuiContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 4),
  },
}));

export const MainSection = styled(Grid)(({ theme }) => ({
  'marginTop': theme.spacing(-15),
  '& > *': {
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(-23),
  },
}));

export const VariousSections = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(6),
    paddingRight: 0,
  },
}));

export const HomeHeaderWrapper = styled(Header)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    paddingBottom: 194,
  },
}));

export const NotificationGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const NotificationTypography = styled(Typography)({
  flex: 1,
  fontSize: '11px',
  fontWeight: '500',
});

export const NextButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(-2),
  marginRight: theme.spacing(-2),
}));

export const NotificationTitleContainer = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  width: '100%',
}));

export const FieldContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: 12,
  margin: theme.spacing(1.5, 0),
}));

export const NewDateBox = styled(Box)(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: 4,
  backgroundColor: theme.palette.primary.main,
}));

export const Emphasize = styled(Box)<any, any>(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 12,
  fontWeight: 500,
  marginBottom: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

export const Interpunt = styled('span')(({ theme }) => ({
  margin: theme.spacing(0, 1),
}));

export const SectioTypography = styled(Typography)({
  fontSize: '11px',
  fontWeight: '500',
});

export const NewTitleWrapper = styled(Grid)(({ theme }) => ({
  'width': 'calc(100% + 32px)',
  'padding': theme.spacing(0, 2, 1),
  'margin': theme.spacing(0, -2),
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    boxShadow: '0 6px 12px 0 rgba(22,20,47,0.12)',
  },
}));

export const TypographyDate = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.primary,
  fontWeight: 500,
  marginBottom: theme.spacing(1),
}));

export const TitleCategory = styled(Typography)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.grey[500], 0.25),
  color: theme.palette.text.primary,
  padding: '4px 10px',
  fontSize: '10px',
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  borderRadius: 13,
  height: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ButtonCard = styled(Button)(({ theme }) => ({
  'height': '192px',
  'display': 'flex',
  'justifyContent': 'center',
  'alignItems': 'center',
  'borderRadius': '6px',
  'flexDirection': 'column',
  'backgroundColor': theme.palette.background.default,
  'border': 'solid 1px #EBEAEE',
  'boxShadow': '0 6px 12px 0 rgb(22 20 47 / 16%)',
  '&:hover': {
    boxShadow: '0 6px 18px 0 rgb(22 20 47 / 32%)',
    backgroundColor: theme.palette.white.main,
  },
}));

export const MoneyLenderName = styled(Typography)({
  position: 'relative',
  top: '17px',
  fontWeight: 500,
  fontSize: '12px',
});

export const TileCounter = styled('div')(({ theme }) => ({
  padding: '0px 5px',
  borderRadius: 16,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 500,
  fontSize: '0.563rem',
  position: 'absolute',
  right: 10,
  top: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
