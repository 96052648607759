import { FC, useEffect, useState } from 'react';
import { Icon } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loanPartDefaultValues } from '../../constants';
import { ConfirmationDialog } from 'components/CustomDialog';

import PartOfLoan from './components/PartOfLoan';
import { Container, Button } from './LoanPart.styled';
import { Title, Description } from 'components/Form/FormTitle/FormTitle.styled';
import LoanPartHeader from './components/LoanPartHeader';
import type { LoanDetailsFormType, Option } from 'screens/Mutations/utils/types';

type LoanPartsProps = {
  loanNumber: number;
  technicalProductOptions: Option[];
};

export const LoanParts: FC<LoanPartsProps> = ({ loanNumber, technicalProductOptions }) => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext<LoanDetailsFormType>();
  const formLoanParts = watch('loanParts');
  const { fields, append, remove } = useFieldArray({ name: 'loanParts', control });
  const [selectedLoanPart, setSelectedLoanPart] = useState<number | undefined>(undefined);
  const [selectedLoanPartId, setselectedLoanPartId] = useState<string | undefined>(undefined);
  const [deleteLoanPartDialog, setDeleteLoanPartDialog] = useState(false);
  const [currentFieldAction, setCurrentFieldAction] = useState<string | undefined>(undefined);
  const [expanded, setExpanded] = useState<string[]>([fields[0].id]);

  useEffect(() => {
    if (currentFieldAction === 'append') {
      const result = fields.filter((field) => !expanded.includes(field.id));

      setExpanded((oldArray) => [...oldArray, result[result.length - 1].id]);

      setCurrentFieldAction(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const handleDeleteLoanPart = (index: number, id: string) => {
    setDeleteLoanPartDialog(true);
    setSelectedLoanPart(index);
    setselectedLoanPartId(id);
  };

  const handleConfirmationDialog = () => {
    remove(selectedLoanPart);
    setExpanded(expanded.filter((item) => item !== selectedLoanPartId));
    setSelectedLoanPart(undefined);
    setselectedLoanPartId(undefined);
    setDeleteLoanPartDialog(false);
  };

  const handleExpand = (id: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    if (!expanded.includes(id)) {
      setExpanded((oldArray) => [...oldArray, id]);
    } else {
      setExpanded(expanded.filter((item) => item !== id));
    }
  };

  return (
    <Container container>
      <Title variant="h3">{t('directMutations.loanParts.title')}</Title>
      <Description variant="body2">{t('directMutations.loanParts.description')}</Description>
      {formLoanParts && formLoanParts.length > 0 && <LoanPartHeader />}

      {fields.map((field, loanPartIndex) => (
        <PartOfLoan
          key={field.id}
          expanded={expanded.includes(field.id)}
          loanPartIndex={loanPartIndex}
          fieldId={field.id}
          technicalProductOptions={technicalProductOptions}
          loanNumber={loanNumber}
          handleExpand={handleExpand}
          handleDeleteLoanPart={handleDeleteLoanPart}
        />
      ))}
      {formLoanParts.length <= 10 && (
        <Button
          onClick={() => {
            setCurrentFieldAction('append');
            append(loanPartDefaultValues);
          }}
          variant="contained"
          color="primary"
          size="medium"
        >
          {t('directMutations.buttons.addLoanPartButton')}
          <Icon>plus</Icon>
        </Button>
      )}
      <ConfirmationDialog
        isOpen={deleteLoanPartDialog}
        onConfirmation={() => handleConfirmationDialog()}
        onDecline={() => setDeleteLoanPartDialog(false)}
        onClose={() => setDeleteLoanPartDialog(false)}
        confirmationText={t('directMutations.buttons.deleteButton')}
        declineText={t('directMutations.buttons.cancelButton')}
        bodyText={t('directMutations.confirmDelete.bodyLoanPart')}
      />
    </Container>
  );
};

export default LoanParts;
