/* eslint-disable no-debugger */
import React, { FC, useState, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import {
  AnsweredQuestion,
  TypeOfRedemption,
  TypeOfCollateral,
  Orientation,
} from '__generated__/api';
import Ajv from 'ajv';
import {
  Box,
  Dialog,
  Fab,
  Grid,
  Hidden,
  Icon,
  IconButton,
  Button,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import backgroundImageStater from 'assets/images/half.png';
import nibcLogo from 'assets/images/nibc.png';
import BuyToLetApplicants from './BuyToLetSteps/BuyToLetApplicants';
import BuyToLetRealEstates from './BuyToLetSteps/BuyToLetRealEstates';
import BuyToLetLoanParts from './BuyToLetSteps/BuyToLetLoanParts';
import BuyToLetLoan from './BuyToLetSteps/BuyToLetLoan';
import BuyToLetSteps from './BuyToLetSteps/BuyToLetSteps';
import { BtlApplicant, BtlLoan, BtlRealEstate, BtlApplication } from 'types/BtlFormApplication';
import BuyToLetApplicantNaturalPerson from './BuyToLetSteps/BuyToLetNewApplicant/BuyToLetApplicantNaturalPerson';
import BuyToLetApplicantLegalPerson from './BuyToLetSteps/BuyToLetNewApplicant/BuyToLetApplicantLegalPerson';
import BuyToLetContactData from './BuyToLetSteps/BuyToLetNewApplicant/BuyToLetContactData';
import BuyToLetIncomesAndProperties from './BuyToLetSteps/BuyToLetNewApplicant/BuyToLetIncomesAndProperties';
import BuyToLetNewRealEstate from './BuyToLetSteps/BuyToLetNewRealEstate/BuyToLetNewRealEstate';
import loanSchema from './schemas/loanSchema';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SaveAndExitWrapper from 'components/SaveAndExitPopup/SaveAndExitWrapper';
import applicantNaturalPersonSchema from './schemas/applicantNaturalPersonSchema';
import applicantIncomesAndPropertiesSchema from './schemas/applicantIncomesAndPropertiesSchema';
import applicantNaturalPersonContactDataSchema from './schemas/applicantContactDataSchema';
import applicantLegalPersonSchema from './schemas/applicantLegalPersonSchema';
import {
  useMutateBuyToLetDetails,
  useQueryBuyToLetApplicationRequestDetails,
  useSubmitBuyToLetApplication,
} from 'use/buyToLet';
import {
  getTotalRequiredAmount,
  getTotalProvenAmount,
  hasErrorsInTotalLoanPartAmount,
  newApplicant,
  newRealEstate,
  formatAddress,
  applicantCountValid,
} from './utils';
import ToggleButtonQuestion from 'screens/BuyToLet/BuyToLetForm/form/ToggleButtonQuestion';
import { QuestionState } from '../BuyToLet';
import BuyToLetSummary from './BuyToLetSteps/Summary/BuyToLetSummary';
import { useGroup } from 'use/group';
import { validateNewBuyToLetApplication } from 'api/buyToLetForm';
import loanPartsSchema from './schemas/loanPartsSchema';
import realEstateSchemaExisting from './schemas/realEstateSchemaExisting';
import realEstateSchemaNewlyBuild from './schemas/realEstateSchemaNewlyBuild';
import { maxBtlApplicants, maxBtlRealEstates } from './constants';
import { useDeleteApplication } from 'use/applications';
import LoadingIndicator from 'components/LoadingIndicator';
import { ConfirmationDialog } from 'components/CustomDialog';
import { useQueryCases } from 'use/cases';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import ajvFormats from 'ajv-formats';

const ajv = new Ajv({
  coerceTypes: false,
  allErrors: true,
  verbose: true,
});
ajvFormats(ajv);
ajv.addKeyword('min');
ajv.addKeyword({
  type: 'string',
  validate: (schema: Object, data: Object) => {
    return typeof data === 'string' && data.trim() !== '';
  },
  errors: false,
  keyword: 'isNotEmpty',
});

const iconWidth = 40;
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      display: 'flex',
    },
    '& .MuiButtonBase-root:disabled': {
      cursor: 'default',
      pointerEvents: 'auto',
    },
  },
  header: {
    height: 92,
    padding: theme.spacing(3, 5),
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    boxShadow: '0 6px 12px 0 rgba(22,20,47,0.12)',
    position: 'relative',
    zIndex: 1300,
  },
  logo: {
    width: 150,
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(4),
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
  },
  gridContainer: {
    marginTop: theme.spacing(4),
    maxWidth: 600,
  },
  wrapper: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexGrow: 1,
    height: 'calc(100vh - 92px)',
    flexDirection: 'row',
  },
  scrollWrapper: {
    overflow: 'auto',
    flex: 1,
  },
  sidebar: {
    maxWidth: 400,
    minWidth: 200,
    height: '100%',
    backgroundColor: theme.palette.purple.dark,
    backgroundImage: `url("${backgroundImageStater}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 70px',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 900,
    margin: '60px 100px',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4),
    },
  },
  spacer: {
    flexGrow: 1,
  },
  bottomNav: {
    position: 'fixed',
    bottom: theme.spacing(3),
  },
  fabLeft: {
    marginLeft: theme.spacing(2),
  },
  fabRight: {
    right: theme.spacing(3),
  },
  fabIcon: {
    marginLeft: theme.spacing(1),
    transform: 'scale(0.8)',
  },
  paper: {
    'width': '100%',
    'padding': theme.spacing(2, 3.5),
    'borderRadius': '8px',
    '& hr': {
      marginLeft: `calc(${iconWidth}px + ${theme.spacing(2)})`,
      backgroundColor: 'rgba(0,0,0,0.08)',
    },
  },
  description: {
    marginTop: theme.spacing(2),
    color: theme.palette.textBody.main,
  },
  lineDivider: {
    borderLeft: '1px solid rgba(128, 128, 128, 0.5)',
    height: '50%',
    padding: theme.spacing(0, 1),
  },
  saveAsDraftButton: {
    paddingRight: theme.spacing(3),
    color: theme.palette.textBody.main,
  },
  checkIcon: {
    color: theme.palette.success.dark,
    fontSize: '24px',
  },
  warningIcon: {
    color: theme.palette.warning.dark,
    fontSize: '24px',
  },
}));

interface ErrorsMap {
  [key: string]: unknown[];
}

const Errors: ErrorsMap = {
  applicants: [],
  naturalPerson: [],
  legalPerson: [],
  incomesAndProperties: [],
  contactData: [],
  loan: [],
  loanParts: [],
  realEstate: [],
};

type LatestSubStepSeenMap = {
  [key: number]: number;
};

const latestSubStepSeenInitial: LatestSubStepSeenMap = {
  0: 0,
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
};

export type BuyToLetFormProps = {
  open: boolean;
  wizardState: Map<string, QuestionState>;
  onClose: () => void;
  id?: number;
};

const BuyToLetForm: FC<BuyToLetFormProps> = ({ open, onClose, id, wizardState }) => {
  const applicationDetailsRequest = useQueryBuyToLetApplicationRequestDetails(
    open ? id ?? 0 : undefined
  );

  if (!open || applicationDetailsRequest.data === undefined) {
    // do not render when not shown
    return null;
  }

  if (applicationDetailsRequest.status === 'loading') {
    return <LoadingIndicator />;
  }

  return (
    <BuyToLetFormWrapped
      open={open}
      onClose={onClose}
      wizardState={wizardState}
      btlApplication={applicationDetailsRequest.data.application}
      progress={{
        activeStep: applicationDetailsRequest.data.extraInfo.activeStep ?? 0,
        activeSubStep: applicationDetailsRequest.data.extraInfo.activeSubStep ?? -1,
        latestStepSeen: applicationDetailsRequest.data.extraInfo.latestStepSeen ?? 0,
        latestSubStepSeen:
          applicationDetailsRequest.data.extraInfo.latestSubStepSeen ?? latestSubStepSeenInitial,
      }}
    />
  );
};

type BuyToLetFormWrappedProps = {
  open: boolean;
  onClose: () => void;
  btlApplication: BtlApplication;
  wizardState: Map<string, QuestionState>;
  progress: {
    activeStep: number;
    activeSubStep: number;
    latestStepSeen: number;
    latestSubStepSeen: LatestSubStepSeenMap;
  };
};

const BuyToLetFormWrapped: FC<BuyToLetFormWrappedProps> = ({
  open,
  onClose,
  btlApplication,
  wizardState,
  progress,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { refetch } = useQueryCases();
  const group = useGroup();
  const [pristine, setPristine] = useState(true);
  const [activeStep, setActiveStep] = useState(progress.activeStep);
  const [activeSubStep, setActiveSubStep] = useState(progress.activeSubStep);
  const [latestStepSeen, setLatestStepSeen] = useState(progress.latestStepSeen);
  const [latestSubStepSeen, setLatestSubStepSeen] = useState(progress.latestSubStepSeen);
  const [currentApplicantIndex, setCurrentApplicantIndex] = useState(0);
  const [currentRealEstateIndex, setCurrentRealEstateIndex] = useState(0);
  const [errors, setErrors] = useState<ErrorsMap>(Errors);
  const [askClose, setAskClose] = useState(false);
  const [successAlertMessage, setSuccessAlertMessage] = useState(false);
  const errorMessageState = useState(false);
  const setSaveErrorMessage = errorMessageState[1];
  const [showValidations, setShowValidations] = useState(false);
  const [validationResult, setValidationResult] = useState([]);
  const fieldRef = React.useRef<HTMLInputElement>(null);
  const scrollWrapper = React.useRef<any>();

  function getOrientation(): Orientation {
    return {
      answeredQuestions: Array.from(wizardState.entries())
        .map(([id, question]) => {
          const data: AnsweredQuestion = {
            answer: question.answerText,
            explanation: '',
            question: question.hiddenInHyarchis === true ? '' : question.questionText,
          };
          return data;
        })
        .filter((answer) => answer.question && answer.answer),
    };
  }

  const successCallback = (res: { data: { application: BtlApplication }; status: number }) => {
    setSuccessAlertMessage(true);

    newBtlApplication.current = res.data.application;

    refetch();
    setPristine(true);
  };

  const submitSuccessCallback = () => {
    setPristine(true);
  };

  const submitErrorCallback = () => {};

  const errorCallback = () => {
    setSaveErrorMessage(true);
  };

  const {
    mutate: submitBuyToLet,
    isLoading: isSubmitting,
    isSuccess: isSubmitted,
    data: SubmitBTLData,
    isError,
  } = useSubmitBuyToLetApplication(submitSuccessCallback, submitErrorCallback);

  const { mutate: submitConcept, isLoading: isSubmittingConcept } = useMutateBuyToLetDetails(
    successCallback,
    errorCallback
  );

  const canSaveDraft = (): boolean => {
    if (newBtlApplication.current?.applicants?.length > 0) {
      const firstApplicant = newBtlApplication.current?.applicants[0];
      const hasFirsApplicantName =
        firstApplicant.isLegalPerson === true
          ? (firstApplicant.companyName ?? '') !== ''
          : (firstApplicant.lastName ?? '') !== '';
      return hasFirsApplicantName;
    }
    return false;
  };

  const onSaveConcept = (changedApplication: BtlApplication) => {
    submitConcept({
      application: changedApplication,
      orientation: getOrientation(),
      extraInfo: { activeStep, activeSubStep, latestStepSeen, latestSubStepSeen },
    });
  };

  const newBtlApplication = useRef<BtlApplication>({
    ...btlApplication,
    product: {
      name: 'NIBCVastgoedHypotheek',
    },
  });

  const focusFirstInvalidElement = () => {
    let foundElement = false;
    if (document.forms.length > 0) {
      const form = document.forms[document.forms.length - 1];
      Array.from(form.elements).every((input) => {
        const element = input as HTMLFieldSetElement;
        if (element.validity.valid === false) {
          foundElement = true;
          element.focus();
          return false;
        }
        return true;
      });
    }

    if (!foundElement) {
      scrollWrapper.current.scrollTop = 0;
    }
  };

  function handleNext() {
    const isValid = determineValidation();

    if (!isValid) {
      focusFirstInvalidElement();
      setShowValidations(true);
      return;
    }

    handleStepChange(activeStep + 1, true);
  }

  const handleStepChange = (newStep: number, isNext: boolean, newSubStep?: number) => {
    const goToNextStep = (): boolean => {
      const valid = determineValidation();

      if (!isNext) {
        changeStep(newStep);
        return true;
      }

      // only when current step is valid, navigate to next screen
      if (valid) {
        if (latestStepSeen < newStep) {
          clearErrors();
          setShowValidations(false);
          setLatestStepSeen(newStep);
        } else {
          // this prevents to see errors of another applicant then the current one
          clearErrors();
          setShowValidations(false);
        }
        changeStep(newStep);
        return true;
      }
      setShowValidations(true);
      return false;
    };
    // check for sub step and navigate
    const isApplicantStep = steps[activeStep].applicant !== undefined;

    const updateLatestSubStepSeen = (nextSubStep: number) => {
      setActiveSubStep(nextSubStep);
      fieldRef.current?.scrollIntoView();
      if (latestSubStepSeen[currentApplicantIndex] < nextSubStep) {
        setLatestSubStepSeen((current) => ({
          ...current,
          [currentApplicantIndex]: nextSubStep,
        }));
      }
    };
    if (isApplicantStep) {
      const isLegalPerson =
        newBtlApplication.current.applicants[currentApplicantIndex].isLegalPerson;

      const maxNrOfSubSteps = isLegalPerson ? 2 : 3;
      let nextSubStep = newSubStep ?? activeSubStep + (isNext ? 1 : -1);

      if (isApplicantStep && activeSubStep < maxNrOfSubSteps - 1 && isNext) {
        setShowValidations(true);

        const valid = determineValidation();
        // only when valid navigate to next step
        if (valid) {
          updateLatestSubStepSeen(nextSubStep);
          clearErrors();
          setShowValidations(false);
          // save sub step
          handleOnSaveDraft();
        }
      } else {
        const validationResult = goToNextStep();
        if (validationResult) {
          // check if user is navigating to next sub step,
          // but that is after the last step, so reset sub step
          if (nextSubStep >= maxNrOfSubSteps) {
            nextSubStep = 0;
          }
          updateLatestSubStepSeen(nextSubStep);
          if (nextSubStep) {
            setActiveSubStep(nextSubStep);
          } else {
            setActiveSubStep(0);
          }
        }
      }
    } else {
      goToNextStep();
    }
  };

  function handleBack(): void {
    const goToPreviousStep = () => {
      handleStepChange(activeStep - 1, false);
    };
    // check for sub step and navigate
    const isApplicantStep = steps[activeStep].applicant !== undefined;
    if (isApplicantStep) {
      if (isApplicantStep && activeSubStep > 0) {
        changeStep(activeStep, activeSubStep - 1);
      } else {
        goToPreviousStep();
      }
    } else {
      goToPreviousStep();
    }
  }

  const handleMenuSubStepChange = (subStepToGoTo: number): void => {
    handleStepChange(activeStep, false, subStepToGoTo);
  };

  const clearErrors = (): void => {
    // eslint-disable-next-line no-return-assign
    Object.keys(errors).map((errorItem: string) => (errors[errorItem] = []));
    setErrors(errors);
  };

  const handleMenuStepChange = (newStep: number): boolean => {
    // when navigating to another step, clear all error
    // because it could lead to viewing the errors
    // of another "current" real estate or applicant
    clearErrors();

    if (latestStepSeen < newStep) {
      const valid = determineValidation();

      // only when current step is valid, navigate to next screen
      if (valid) {
        setShowValidations(false);
        setLatestStepSeen(newStep);
        return false;
      }
    } else {
      setShowValidations(true);
    }

    changeStep(newStep);
    return true;
  };

  const determineValidation = (): boolean => {
    try {
      switch (steps[activeStep].label) {
        case 'applicants':
          return validateApplicants();
        case 'realEstates':
          return true;
        case 'loan':
          return validateLoan();
        case 'loanParts':
          return validateLoanParts();
        case 'summary':
          return true;
        default:
          if (steps[activeStep]?.realEstate !== undefined) {
            return validateSelectedRealEstate();
          }
          if (steps[activeStep]?.applicant !== undefined) {
            if (
              newBtlApplication.current?.applicants[currentApplicantIndex].isLegalPerson === false
            ) {
              // natural person
              switch (activeSubStep) {
                case 0:
                  return validateCurrentApplicantNaturalPerson();
                case 1:
                  return validateCurrentApplicantIncomesAndProperties();
                case 2:
                  return validateCurrentApplicantContactData();
              }
            } else {
              // legal person
              switch (activeSubStep) {
                case 0:
                  return validateCurrentApplicantLegalPerson();
                case 1:
                  return validateCurrentApplicantContactData();
              }
            }
          }
          return true;
      }
    } catch (e: any) {
      // eslint-disable-next-line no-debugger
      debugger;
      return false;
    }
  };

  const validateApplicants = (): boolean => {
    const applicantsValid = applicantCountValid(newBtlApplication?.current.applicants);
    if (!applicantsValid) {
      setErrors({ applicants: [{ invalid: 'Applicants count invalid' }] });
    } else {
      setErrors({ applicants: [] });
    }
    return applicantsValid;
  };

  const validateCurrentApplicantNaturalPerson = (): boolean => {
    return validate(
      applicantNaturalPersonSchema,
      'naturalPerson',
      newBtlApplication.current.applicants[currentApplicantIndex]
    );
  };

  const validateCurrentApplicantLegalPerson = (): boolean => {
    const currentLegalPerson = newBtlApplication?.current?.applicants[currentApplicantIndex];

    // ajv validate function converts null to 0, and incorrectly fails the validation,
    // so manually convert null to undefined which fixes this :(
    currentLegalPerson.numberOfRentalCollateralsOwned =
      currentLegalPerson.numberOfRentalCollateralsOwned === null
        ? undefined
        : currentLegalPerson.numberOfRentalCollateralsOwned;
    currentLegalPerson.totalValueOfRentalCollateralsOwned =
      currentLegalPerson.totalValueOfRentalCollateralsOwned === null
        ? undefined
        : currentLegalPerson.totalValueOfRentalCollateralsOwned;

    return validate(applicantLegalPersonSchema, 'legalPerson', currentLegalPerson);
  };

  const validateCurrentApplicantIncomesAndProperties = (): boolean => {
    const currentIncomesAndProperties =
      newBtlApplication?.current?.applicants[currentApplicantIndex];
    // ajv validate function converts null to 0, and incorrectly fails the validation,
    // so manually convert null to undefined which fixes this :(
    currentIncomesAndProperties.numberOfRentalCollateralsOwned =
      currentIncomesAndProperties.numberOfRentalCollateralsOwned === null
        ? undefined
        : currentIncomesAndProperties.numberOfRentalCollateralsOwned;
    currentIncomesAndProperties.totalValueOfRentalCollateralsOwned =
      currentIncomesAndProperties.totalValueOfRentalCollateralsOwned === null
        ? undefined
        : currentIncomesAndProperties.totalValueOfRentalCollateralsOwned;
    return validate(
      applicantIncomesAndPropertiesSchema,
      'incomesAndProperties',
      currentIncomesAndProperties
    );
  };

  const validateCurrentApplicantContactData = (): boolean => {
    return validate(
      applicantNaturalPersonContactDataSchema,
      'contactData',
      newBtlApplication?.current?.applicants[currentApplicantIndex]
    );
  };

  const validateSelectedRealEstate = (): boolean => {
    if (
      newBtlApplication.current.realEstates[currentRealEstateIndex]?.typeOfCollateral ===
      TypeOfCollateral.Existing
    ) {
      return validate(
        realEstateSchemaExisting,
        'realEstate',
        newBtlApplication.current.realEstates[currentRealEstateIndex]
      );
    }
    return validate(
      realEstateSchemaNewlyBuild,
      'realEstate',
      newBtlApplication.current.realEstates[currentRealEstateIndex]
    );
  };

  const validateLoan = (): boolean => {
    const totalRequired = getTotalRequiredAmount(newBtlApplication.current?.loan);
    const totalProven = getTotalProvenAmount(newBtlApplication.current?.loan);
    const schemaValid = validate(loanSchema, 'loan', newBtlApplication?.current?.loan);
    if (totalRequired === 0 || totalProven === 0 || totalRequired !== totalProven || !schemaValid) {
      return false;
    }
    return true;
  };

  const validateLoanParts = (): boolean => {
    return (
      validate(loanPartsSchema, 'loanParts', newBtlApplication?.current?.loan?.loanParts) &&
      !hasErrorsInTotalLoanPartAmount(newBtlApplication.current?.loan)
    );
  };

  const handleUpdateNaturalPersonApplicant = (applicant: BtlApplicant, index: number) => {
    setPristine(false);
    newBtlApplication.current.applicants[index] = { ...applicant };
    validateCurrentApplicantNaturalPerson();
  };

  const handleUpdateLegalPersonApplicant = (applicant: BtlApplicant, index: number) => {
    setPristine(false);
    newBtlApplication.current.applicants[index] = { ...applicant };
    validateCurrentApplicantLegalPerson();
  };

  const handleUpdateRealEstate = (realEstate: BtlRealEstate, index: number) => {
    setPristine(false);
    newBtlApplication.current.realEstates[index] = { ...realEstate };
    validateSelectedRealEstate();
  };

  const handleUpdateIncomesAndProperties = (applicant: BtlApplicant, index: number) => {
    setPristine(false);
    newBtlApplication.current.applicants[index] = { ...applicant };
    validateCurrentApplicantIncomesAndProperties();
  };

  const handleUpdateContactData = (applicant: BtlApplicant, index: number) => {
    setPristine(false);
    newBtlApplication.current.applicants[index] = { ...applicant };
    validateCurrentApplicantContactData();
  };

  const handleUpdateLoan = (loan: BtlLoan) => {
    setPristine(false);
    newBtlApplication.current.loan = { ...loan };
    validateLoan();
  };

  function handleUpdateLoanParts(
    e: { target: { value: any; name: string } } | React.ChangeEvent<{ value: any; name: string }>,
    activeLoanPart?: number
  ) {
    setPristine(false);

    const { value, name } = e.target;
    if (activeLoanPart !== undefined && newBtlApplication?.current !== undefined) {
      const loan = newBtlApplication.current.loan;

      switch (name) {
        case 'duration': {
          const durationInMonths = value ? value * 12 : null;
          loan.loanParts[activeLoanPart].duration = durationInMonths;
          break;
        }
        case 'typeOfRedemption':
          if (value === TypeOfRedemption.InterestOnly) {
            loan.loanParts[activeLoanPart].duration = 360;
          } else if (value === TypeOfRedemption.Lineair) {
            loan.loanParts[activeLoanPart].duration = 180;
          }
          loan.loanParts[activeLoanPart].typeOfRedemption = value;
          break;
        default:
          (loan.loanParts[activeLoanPart] as any)[name] = value;
      }

      validateLoanParts();
    }
  }

  function handleOnCreateLoanPart() {
    validateLoanParts();
  }

  function validate(schema: Object, category: string, toValidate: Object): boolean {
    // HACK: ajv.validate makes null numbers 0 by adjusting the toValidate object (by reference)
    // JSON parse/stringify makes a deep clone of the object and fixes this issue.
    const clonedObjectToValidate = toValidate ? JSON.parse(JSON.stringify(toValidate)) : {};
    const valid: boolean = ajv.validate(schema, clonedObjectToValidate) as boolean;
    const errorsArray = Array.isArray(ajv.errors) ? ajv.errors : [];
    const newValues = { ...errors };
    if (!valid) {
      newValues[category] = errorsArray;
      setErrors(newValues);
    } else {
      newValues[category] = [];
      setErrors(newValues);
    }
    return valid;
  }

  function changeStep(step: number, subStep?: number) {
    handleOnSaveDraft();
    setCurrentRealEstateIndex(steps[step].realEstate ?? -1);
    setActiveApplicant(steps[step].applicant !== undefined ? steps[step].applicant ?? -1 : -1);
    setActiveSubStep(subStep ?? 0);
    setActiveStep(step);
    fieldRef.current?.scrollIntoView();
  }

  function setActiveApplicant(index: number) {
    setCurrentApplicantIndex(index);
  }

  function handleOnSaveDraft() {
    const isSavingDraftAllowed = canSaveDraft();
    if (!isSavingDraftAllowed) {
      return;
    }
    setAskClose(false);
    onSaveConcept(newBtlApplication?.current);
    // Externe test
  }

  const closeApplicationDialog = () => {
    setAskClose(true);
  };

  const onCloseSaveDialog = () => {
    setAskClose(false);
  };

  function submit() {
    if (!isSubmitted && !isSubmitting) {
      submitBuyToLet(newBtlApplication.current);
    }
  }

  function createApplicant() {
    if (newBtlApplication?.current.applicants.length <= maxBtlApplicants) {
      newBtlApplication?.current?.applicants.push({ ...newApplicant });
    }
    setActiveSubStep(0);
    const step = newBtlApplication?.current?.applicants.length;
    setActiveApplicant(step - 1);
    setActiveStep(step);
    setLatestStepSeen(latestStepSeen + 1);
    clearErrors();
    setShowValidations(false);
  }

  function createRealEstate() {
    if (newBtlApplication?.current.realEstates.length < maxBtlRealEstates) {
      newBtlApplication?.current?.realEstates.push({ ...newRealEstate } as BtlRealEstate);
    }
    // eslint-disable-next-line no-unsafe-optional-chaining
    setActiveRealEstate(newBtlApplication?.current?.realEstates.length - 1);
    fieldRef.current?.scrollIntoView();
    setLatestStepSeen(latestStepSeen + 1);
  }

  function setActiveRealEstate(index: number): void {
    setCurrentRealEstateIndex(index);
    const step =
      // eslint-disable-next-line no-unsafe-optional-chaining
      newBtlApplication?.current?.applicants.length +
      // eslint-disable-next-line no-unsafe-optional-chaining
      newBtlApplication?.current?.realEstates.length +
      1;
    setActiveStep(step);
  }

  function deleteLoanPart(index: number) {
    if (newBtlApplication?.current?.loan.loanParts.length > 1) {
      newBtlApplication?.current?.loan.loanParts.splice(index, 1);
    }
    validateLoanParts();
  }

  function deleteApplicant(index: number) {
    newBtlApplication?.current?.applicants.splice(index, 1);
    const current = latestSubStepSeen;
    const originalCurrent = { ...current };
    // move the next sub step to the previous until deleted applicant index
    for (let i = Object.keys(current).length; i >= index; i--) {
      if (i !== 0) {
        current[i - 1] = originalCurrent[i];
      }
    }
    setLatestSubStepSeen(current);
    setLatestStepSeen(latestStepSeen - 1);
    setActiveApplicant(currentApplicantIndex);
  }

  function deleteRealEstate(index: number) {
    newBtlApplication?.current?.realEstates.splice(index, 1);
    setCurrentRealEstateIndex(currentRealEstateIndex - 1);
    setLatestStepSeen(latestStepSeen - 1);
  }

  function handleTypeOfApplicantToggle(
    e: React.MouseEvent<{ name: string }>,
    newValue: 'true' | 'false'
  ) {
    newBtlApplication.current.applicants[currentApplicantIndex].isLegalPerson = newValue === 'true';
    validateApplicants();
  }

  const validateBackend = () => {
    const queryResult = validateNewBuyToLetApplication(group, newBtlApplication.current).then(
      (response) => {
        if (response.status === 200) {
          setValidationResult(response.data);
          return response.data;
        }
        return null;
      }
    );
    return queryResult;
  };

  const errorsBackend: string[] = Object.values(validationResult).map((validation: any) => {
    return validation.errors[0].fieldName.toLowerCase();
  });

  // eslint-disable-next-line consistent-return
  function errorMessage(id: string, category: string): string | undefined {
    errorsBackend.find((e) => e === `${category.toLowerCase()}${id.toLowerCase()}`);
    if (!showValidations) {
      return undefined;
    }
    const array = errors[category];
    if (!array) return undefined;

    const thisFieldErrors = array?.filter((error: any) => {
      const splitted = id.split('.');
      const lastItem = splitted[splitted.length - 1].replace('.', '');

      // in case of an array like a loan parts. The id of the field will be [1].typeOfRedemption
      // ajv marks the data path with the array index like '[1]'
      // without adding this all items in the array will be selected
      const dataPathAsIndexString = splitted[splitted.length - 2];

      if (error.keyword === 'required' && !dataPathAsIndexString) {
        return error.params?.missingProperty === lastItem;
      }

      // In case the field is an array are two ways to validate, in case it is required
      // and in case it is another kind of validation (example: min value of a field).
      // The difference between these types of validations are that the object returned
      // by the package is different for -required validation, for this reason we need to
      // create separated logic.

      if (dataPathAsIndexString) {
        const matchedDigitFromDataPath = dataPathAsIndexString.match(/\d+/);
        const indexString = matchedDigitFromDataPath != null && matchedDigitFromDataPath[0];
        if (error.keyword === 'required') {
          return (
            error.params?.missingProperty === lastItem &&
            error.instancePath.replace('/', '') === indexString
          );
        }
        const errorInputNameArray = error.instancePath.split('/');
        const lastNameFromInputError = errorInputNameArray[errorInputNameArray.length - 1];
        const indexFromInputError = errorInputNameArray[errorInputNameArray.length - 2];
        return lastNameFromInputError === lastItem && indexFromInputError === indexString;
      }

      // this validated all type of validations excluding -required.
      if (error.instancePath) {
        const errorInputNameArray = error.instancePath.split('/');
        const lastNameFromInputError = errorInputNameArray[errorInputNameArray.length - 1];
        return lastNameFromInputError.replace('/', '') === lastItem;
      }

      return undefined;
    });

    if (thisFieldErrors?.length > 0) {
      const fieldErrors = thisFieldErrors
        .map((error: any) => {
          if (error.parentSchema.$comment && !['oneOf', 'if'].includes(error.keyword)) {
            return t(`validations.${error.parentSchema.$comment}`);
          }
          return ' ';
        })
        .join(' ');
      return fieldErrors;
    }
  }

  function errorMessageBackend(id: string, category: string): string | undefined {
    const backendErrors = errorsBackend.find(
      (e: any) => e === `${category.toLowerCase()}${id.toLowerCase()}`
    );
    return backendErrors;
  }

  const applicantNames: string[] = [];
  for (let i = 0; i < maxBtlApplicants; i++) {
    const applicantName = (
      newBtlApplication.current?.applicants[i]?.isLegalPerson
        ? [newBtlApplication.current?.applicants[i]?.companyName]
        : [
            newBtlApplication.current?.applicants[i]?.firstName,
            newBtlApplication.current?.applicants[i]?.middleName,
            newBtlApplication.current?.applicants[i]?.lastName,
          ]
    )
      .filter((value) => value)
      .join(' ');
    applicantNames.push(applicantName);
  }

  const realEstateNames: string[] = [];

  for (
    let i = 0;
    i < newBtlApplication.current?.realEstates?.length && i < maxBtlRealEstates;
    i++
  ) {
    const realEstateName = formatAddress(newBtlApplication.current?.realEstates[i]);
    realEstateNames.push(realEstateName);
  }

  const createSteps = () => {
    const stepsArray: {
      label: string;
      applicant?: number | undefined;
      realEstate?: number | undefined;
      loanPart?: number | undefined;
    }[] = [];
    stepsArray.push({ label: 'applicants' });

    for (let i = 0; i < maxBtlApplicants; i++) {
      const applicantStep = {
        label:
          newBtlApplication.current?.applicants[i] !== undefined && !applicantNames[i]
            ? t('newApplicant')
            : applicantNames[i],
        applicant: i,
      };
      stepsArray.push(applicantStep);
    }

    stepsArray.push({ label: 'realEstates' });

    for (
      let i = 0;
      i < newBtlApplication.current?.realEstates?.length && i < maxBtlRealEstates;
      i++
    ) {
      const realEstateStep = {
        label:
          newBtlApplication.current?.realEstates[i] && !realEstateNames[i]
            ? 'newRealEstate'
            : realEstateNames[i],
        realEstate: i,
      };
      stepsArray.push(realEstateStep);
    }

    stepsArray.push({ label: 'loan' }, { label: 'loanParts' }, { label: 'summary' });

    const steps = stepsArray.filter((step) => step.label);
    return steps;
  };
  const steps = createSteps();

  const showAddApplicantButton = newBtlApplication.current?.applicants?.length < maxBtlApplicants;
  const showAddRealEstateButton =
    newBtlApplication.current?.realEstates?.length < maxBtlRealEstates;

  let stepHasErrors = false;
  // eslint-disable-next-line consistent-return
  const getStepContent = () => {
    if (steps[activeStep].applicant === undefined && steps[activeStep].realEstate === undefined) {
      switch (steps[activeStep].label) {
        case 'applicants':
          stepHasErrors = !applicantCountValid(newBtlApplication.current?.applicants);
          return (
            <BuyToLetApplicants
              applicants={newBtlApplication.current?.applicants}
              setActiveStep={changeStep}
              createApplicant={createApplicant}
              deleteApplicant={deleteApplicant}
              setActiveApplicant={setActiveApplicant}
              showValidations={showValidations}
            />
          );
        case 'realEstates':
          stepHasErrors = false;
          return (
            <BuyToLetRealEstates
              realEstates={newBtlApplication.current?.realEstates}
              createRealEstate={() => {
                createRealEstate();
              }}
              deleteRealEstate={deleteRealEstate}
              setActiveRealEstate={setActiveRealEstate}
            />
          );
        case 'loan': {
          const totalRequired = getTotalRequiredAmount(newBtlApplication.current?.loan);
          const totalProven = getTotalProvenAmount(newBtlApplication.current?.loan);
          stepHasErrors =
            errors?.loan?.length > 0 ||
            totalRequired === null ||
            totalProven === null ||
            totalRequired !== totalProven;
          return (
            <BuyToLetLoan
              loan={newBtlApplication.current?.loan}
              totalRequired={totalRequired}
              totalProven={totalProven}
              updateLoan={handleUpdateLoan}
              errorMessage={errorMessage}
              showValidations={showValidations || stepHasErrors}
            />
          );
        }
        case 'loanParts':
          stepHasErrors =
            errors?.loanParts?.length > 0 ||
            newBtlApplication.current?.loan?.loanParts?.length < 1 ||
            hasErrorsInTotalLoanPartAmount(newBtlApplication.current?.loan);
          return (
            <BuyToLetLoanParts
              handleFormData={handleUpdateLoanParts}
              handleOnCreateLoanPart={handleOnCreateLoanPart}
              errorMessage={errorMessage}
              showValidations={showValidations}
              deleteLoanPart={deleteLoanPart}
              loan={newBtlApplication.current.loan}
            />
          );
        case 'summary':
          stepHasErrors = errorsBackend.length > 0;
          return (
            <BuyToLetSummary
              application={newBtlApplication.current}
              steps={steps}
              changeStep={changeStep}
              errorMessage={errorMessageBackend}
              validate={validateBackend}
            />
          );
        default:
          return 'Step not supported';
      }
    } else if (steps[activeStep].realEstate !== undefined) {
      stepHasErrors = errors?.realEstate?.length > 0;
      return (
        <BuyToLetNewRealEstate
          key={currentRealEstateIndex}
          showAddRealEstateButton={showAddRealEstateButton}
          addRealEstate={() => createRealEstate()}
          realEstate={newBtlApplication.current?.realEstates[currentRealEstateIndex]}
          updateRealEstate={(r: BtlRealEstate) => handleUpdateRealEstate(r, currentRealEstateIndex)}
          errorMessage={errorMessage}
        />
      );
    } else if (steps[activeStep].applicant !== undefined) {
      stepHasErrors = errors?.applicants?.length > 0;

      const applicantHeader = () => (
        <Grid container item spacing={3} className={classes.gridContainer}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5">{t('buyToLetForm.newApplicant.isLegalPerson')}</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ToggleButtonQuestion
              key="isLegalPerson"
              label={t('buyToLetForm.newApplicant.isLegalPerson')}
              required
              answerOptions={[
                {
                  id: 'false',
                  answerTextKey: t('buyToLetForm.newApplicant.naturalPerson'),
                },
                {
                  id: 'true',
                  answerTextKey: t('buyToLetForm.newApplicant.legalPerson'),
                },
              ]}
              containerWidth={6}
              exclusive
              value={newBtlApplication.current.applicants[currentApplicantIndex]?.isLegalPerson}
              onChange={(e, newValue) => handleTypeOfApplicantToggle(e as any, newValue)}
              error={Boolean(errorMessage('.isLegalPerson', 'applicants'))}
            />
          </Grid>
        </Grid>
      );

      if (newBtlApplication.current.applicants[currentApplicantIndex]?.isLegalPerson === false) {
        switch (activeSubStep) {
          case 0:
            stepHasErrors = errors?.naturalPerson?.length > 0;
            return (
              <>
                <BuyToLetApplicantNaturalPerson
                  application={newBtlApplication.current}
                  currentApplicantIndex={currentApplicantIndex}
                  updateApplicant={handleUpdateNaturalPersonApplicant}
                  errorMessage={errorMessage}
                />
              </>
            );
          case 1:
            stepHasErrors = errors?.incomesAndProperties?.length > 0;
            return (
              <>
                <BuyToLetIncomesAndProperties
                  application={newBtlApplication.current}
                  currentApplicantIndex={currentApplicantIndex}
                  updateIncomesAndProperties={handleUpdateIncomesAndProperties}
                  errorMessage={errorMessage}
                />
              </>
            );
          case 2:
            stepHasErrors = errors?.contactData?.length > 0;
            return (
              <>
                <BuyToLetContactData
                  showAddApplicantButton={showAddApplicantButton}
                  addApplicant={() => createApplicant()}
                  application={newBtlApplication.current}
                  currentApplicantIndex={currentApplicantIndex}
                  updateContactData={handleUpdateContactData}
                  errorMessage={errorMessage}
                />
              </>
            );
          default:
            return 'Active sub step not supported from natural person';
        }
      }

      if (newBtlApplication.current.applicants[currentApplicantIndex]?.isLegalPerson === true) {
        stepHasErrors = errors?.applicants?.length > 0;
        switch (activeSubStep) {
          case 0:
            return (
              <>
                <BuyToLetApplicantLegalPerson
                  application={newBtlApplication.current}
                  currentApplicantIndex={currentApplicantIndex}
                  updateApplicant={handleUpdateLegalPersonApplicant}
                  errorMessage={errorMessage}
                />
              </>
            );
          case 1:
            return (
              <>
                <BuyToLetContactData
                  showAddApplicantButton={showAddApplicantButton}
                  addApplicant={() => createApplicant()}
                  application={newBtlApplication.current}
                  currentApplicantIndex={currentApplicantIndex}
                  updateContactData={handleUpdateContactData}
                  errorMessage={errorMessage}
                />
              </>
            );
          default:
            return 'Active sub step not supported from legal person';
        }
      }
      return applicantHeader();
    }
  };

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const handleDeleteDialogOpen = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const { mutate: deleteApplication, isLoading } = useDeleteApplication(
    newBtlApplication?.current.id,
    closeApplicationDialog,
    () => {
      setOpenAlert(true);
      handleDeleteDialogClose();
    }
  );

  const handleDeleteDraft = () => {
    deleteApplication();
  };

  const handleCloseAlert = (event?: Event | React.SyntheticEvent<any, Event>, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        // TransitionComponent={SlideUpTransition}
        className={classes.root}
        onClose={closeApplicationDialog}
      >
        <SaveAndExitWrapper
          askClose={askClose}
          pristine={pristine}
          onSave={() => handleOnSaveDraft()}
          onClose={onClose}
          onCloseSaveDialog={onCloseSaveDialog}
        />
        <ConfirmationDialog
          isOpen={isDeleteDialogOpen}
          onClose={handleDeleteDialogClose}
          onDecline={handleDeleteDialogClose}
          onConfirmation={handleDeleteDraft}
          bodyText={t('buyToLet.deleteConfirmationDialog')}
          confirmationText={t('buyToLet.deleteConfirmationText')}
          declineText={t('buyToLet.deleteDeclineText')}
          isLoading={isLoading}
        />
        <div className={classes.header}>
          <img alt="nibc logo" className={classes.logo} src={nibcLogo} />
          <span className={classes.spacer} />
          {canSaveDraft() && (
            <>
              <Button
                startIcon={<SaveOutlinedIcon />}
                onClick={handleOnSaveDraft}
                disabled={isSubmittingConcept}
                className={classes.saveAsDraftButton}
              >
                {t('general.saveAndExit.draftButton')}
              </Button>
              {newBtlApplication?.current.status === 'New' &&
                newBtlApplication?.current.id !== 0 && (
                  <Button
                    startIcon={<DeleteOutlinedIcon />}
                    onClick={handleDeleteDialogOpen}
                    className={classes.saveAsDraftButton}
                  >
                    {t('general.saveAndExit.deleteButton')}
                  </Button>
                )}
              <span className={classes.lineDivider} />
            </>
          )}
          <IconButton onClick={closeApplicationDialog} size="large">
            <Close data-testid="closeBuyToLetDialog" />
          </IconButton>
        </div>

        <Box id="potato" className={classes.wrapper}>
          <Hidden smDown>
            <Box className={classes.sidebar}>
              <BuyToLetSteps
                steps={steps}
                activeStep={activeStep}
                changeStep={handleMenuStepChange}
                changeSubStep={handleMenuSubStepChange}
                activeSubStep={activeSubStep}
                isLegalPerson={
                  newBtlApplication.current.applicants[currentApplicantIndex]?.isLegalPerson
                }
                latestStepSeen={latestStepSeen}
                latestSubStepSeen={latestSubStepSeen}
              />
            </Box>
          </Hidden>

          <div className={classes.scrollWrapper} ref={scrollWrapper}>
            {activeStep !== 0 && (
              <Fab
                size="medium"
                color="secondary"
                aria-label="previous"
                disabled={activeStep === 0}
                onClick={handleBack}
                className={`${classes.bottomNav} ${classes.fabLeft}`}
                data-testid="previousStep"
              >
                <Icon>arrow_left</Icon>
              </Fab>
            )}
            <Box className={classes.content}>
              <Typography variant="h4" ref={fieldRef}>
                {(steps[activeStep].applicant !== undefined &&
                  steps[activeStep].label !== 'newApplicant') ||
                (steps[activeStep].realEstate !== undefined &&
                  steps[activeStep].label !== 'newRealEstate')
                  ? steps[activeStep].label
                  : t(`buyToLetForm.${steps[activeStep].label}.title`)}
              </Typography>
              {steps[activeStep].applicant === undefined &&
                steps[activeStep].label !== 'newApplicant' &&
                steps[activeStep].realEstate === undefined && (
                  <Typography className={classes.description} variant="body1">
                    {t(`buyToLetForm.${steps[activeStep].label}.description`)}
                  </Typography>
                )}

              {getStepContent()}

              {steps[activeStep].label !== 'summary' ? (
                <Fab
                  color="primary"
                  variant="extended"
                  size="medium"
                  onClick={handleNext}
                  className={`${classes.bottomNav} ${classes.fabRight}`}
                >
                  {t('buyToLetForm.nextSection')}
                  <Icon className={classes.fabIcon}>arrow_right</Icon>
                </Fab>
              ) : (
                <Fab
                  variant="extended"
                  color="primary"
                  size="medium"
                  disabled={stepHasErrors || isSubmitting}
                  onClick={submit}
                  className={`${classes.bottomNav} ${classes.fabRight}`}
                >
                  {t('buyToLetForm.submit') as string}
                  <Icon className={classes.fabIcon}>arrow_right</Icon>
                </Fab>
              )}
            </Box>
          </div>
        </Box>
      </Dialog>
      <CustomSnackbar
        isOpen={openAlert}
        handleClose={handleCloseAlert}
        severity="error"
        message={t('general.saveAndExit.saveError')}
      />
      <CustomSnackbar
        isOpen={successAlertMessage}
        handleClose={() => setSuccessAlertMessage(false)}
        severity="success"
        message={t('general.saveAndExit.saveSucceeded')}
      />
      <CustomSnackbar
        isOpen={isSubmitted}
        handleClose={() => onClose()}
        severity={SubmitBTLData?.data?.emailSent ? 'success' : 'warning'}
        message={
          SubmitBTLData?.data?.emailSent
            ? t('buyToLetForm.emailSent.confirmation')
            : t('buyToLetForm.emailNotSent.confirmation')
        }
      />
      <CustomSnackbar
        isOpen={isError}
        handleClose={() => onClose()}
        severity="error"
        message={t('general.saveAndExit.submitError')}
      />
    </>
  );
};

export default BuyToLetForm;
