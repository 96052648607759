import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ApplicantSummary from '../ApplicantSummary/ApplicantSummary';
import { Box, Typography as MUITypography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { steps } from 'screens/Mutations/components/DismissalJointLiabilityMutation/constants';
import { styled } from '@mui/material/styles';
import LoanSummary from '../LoanSummary/LoanSummary';
import LoanPartSummary from '../LoanPartSummary/LoanPartSummary';
import ConnectedPolicySummary from '../ConnectedPolicySummary/ConnectedPolicySummary';
import Collateral from './Collateral';
import CostAndResources from './CostAndResources';
import AdditionalInformationSummary from '../../../../../../templates/Summary/AdditionalInformation';
import FormTitleText from 'components/Form/FormTitle';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { goToStep } from 'utils/goToStep';

const Typography = styled(MUITypography)(({ theme }) => ({
  marginTop: theme.spacing(7),
  marginBottom: theme.spacing(3),
}));

const Summary: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnClick = (step: string, applicantType?: string) => {
    let currStep;
    if (applicantType) {
      currStep = (steps as any)[applicantType][step];
    } else {
      currStep = (steps as any)[step];
    }

    goToStep(currStep, navigate, location.pathname);
  };

  const additionalInformation = useSelector(
    (state: RootState) => state.ohaForm.additionalInformation
  );

  return (
    <>
      <FormTitleText title={t('directMutations.summary.title')} description="" />
      <Box>
        <Typography variant="h5">{t('directMutations.currentApplicant')}</Typography>
        <ApplicantSummary type="currentApplicant" onClick={handleOnClick} />

        <Typography variant="h5">{t('directMutations.newApplicant')}</Typography>
        <ApplicantSummary type="newApplicant" onClick={handleOnClick} />

        <Typography variant="h5">{t('directMutations.collateral.title')}</Typography>
        <Collateral onClick={() => handleOnClick('collateral')} />

        <Typography variant="h5">{t('directMutations.costAndResources.title')}</Typography>
        <CostAndResources onClick={() => handleOnClick('financingCostAndResources')} />

        <LoanSummary onClick={handleOnClick} />

        <LoanPartSummary onClick={handleOnClick} />
        <ConnectedPolicySummary onClick={handleOnClick} />

        <AdditionalInformationSummary
          onClick={() => handleOnClick('additionalInfo')}
          additionalInformationForm={additionalInformation}
          rootFormName="directMutations.additionalInformation."
        />
      </Box>
    </>
  );
};

export default Summary;
