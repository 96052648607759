import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { TableCellText, MobileTableCellText, MobileGrid } from 'screens/Downloads/Downloads.styled';
import formatDate from 'utils/formatDate';
import { DownloadFiles } from 'api/downloads';

type DownloadListMobileProps = {
  files: DownloadFiles[];
};
const DownloadListMobile: FC<DownloadListMobileProps> = ({ files }) => {
  const { t } = useTranslation();
  return (
    <MobileGrid
      container
      alignItems="center"
      justifyContent="center"
      data-testid="downloadList-mobile"
    >
      {files.map((file: DownloadFiles, index) => {
        const { description, dateCreated } = file;
        const formatedDate = formatDate(dateCreated, ' ', "dd-MM-yyyy' ('HH:mm)");
        return (
          <Fragment key={index}>
            <MobileGrid item xs={12}>
              <MobileTableCellText
                isaccessed={file?.dateAccessed ? 1 : 0}
                data-testid="downloadListMobile-type"
              >
                {description}
              </MobileTableCellText>
            </MobileGrid>
            <MobileGrid item xs={8} data-testid="downloadListMobile-date">
              {formatedDate && (
                <TableCellText isaccessed={file?.dateAccessed ? 1 : 0}>
                  {`${t('downloads.table.date')}: ${formatedDate}`}
                </TableCellText>
              )}
            </MobileGrid>
            <MobileGrid item xs={3} data-testid="downloadListMobile-download">
              <Button variant="text" color="primary">
                <FileDownloadIcon />
              </Button>
            </MobileGrid>
            <MobileGrid item xs={12}>
              <Divider light />
            </MobileGrid>
          </Fragment>
        );
      })}
    </MobileGrid>
  );
};
export default DownloadListMobile;
