import { ApplicationData, PersonName } from '__generated__/api';
import { Case } from '__generated__/api-di';
import createCustomerSortingName from './createCustomerSortingName';

export const createSortCaseObject = () => {
  return Object.freeze({
    highestPriority: (a, b) => {
      return (a.application?.priority?.weight ?? 0) - (b.application?.priority?.weight ?? 0);
    },
    date: (a, b) => {
      const dateA =
        (a.application?.endDate ?? undefined) !== undefined
          ? (a.application!.endDate!.split('-') as any[])
          : [0, 0, 0];
      const dateB =
        (b.application?.endDate ?? undefined) !== undefined
          ? (b.application!.endDate!.split('-') as any[])
          : [0, 0, 0];
      return (
        (new Date(dateA[2], dateA[1] - 1, dateA[0]) as any) -
        (new Date(dateB[2], dateB[1] - 1, dateB[0]) as any)
      );
    },
    applicant: (a, b) =>
      createCustomerSortingName(a?.applicants)?.localeCompare(
        createCustomerSortingName(b?.applicants)
      ),
    moneylender: (a, b) => a.lender!.name?.localeCompare(b.lender!.name!),
    loanNumber: (a, b) => {
      const loanNumberExternalB = b?.application?.loanNumberExternal ?? 0;
      const loanNumberExternalA = a?.application?.loanNumberExternal ?? 0;
      return loanNumberExternalB - loanNumberExternalA;
    },
    amount: (a, b) => {
      const loanAmountB = b?.application?.loanAmount ?? 0;
      const loanAmountA = a?.application?.loanAmount ?? 0;
      return loanAmountB - loanAmountA;
    },
  }) as { [key: string]: (a: Case, b: Case) => number };
};

export const createSortApplicationObject = () => {
  return Object.freeze({
    date: (a, b) => {
      const dateA = a.dateModified!.split('-') as any[];
      const dateB = b.dateModified!.split('-') as any[];
      return (
        (new Date(dateA[2], dateA[1] - 1, dateA[0]) as any) -
        (new Date(dateB[2], dateB[1] - 1, dateB[0]) as any)
      );
    },
    applicant: (a, b) =>
      createCustomerSortingName(a.person as PersonName[])?.localeCompare(
        createCustomerSortingName(b.person as PersonName[])
      ),
    moneylender: (a, b) => a.commercialName?.localeCompare(b.commercialName!),
    amount: (a, b) => {
      const loanAmountB = b?.loanAmount ?? 0;
      const loanAmountA = a?.loanAmount ?? 0;

      return loanAmountB - loanAmountA;
    },
  }) as { [key: string]: (a: ApplicationData, b: ApplicationData) => number };
};
