import {
  ClientDetailsLoanParts,
  ClientDetailsModel,
  ClientDetailsPerson,
} from 'types/ClientDetailsModel';
import { Status, TypeOfLoan } from '__generated__/api';
import { ClientExtractLoanPartGuarantee } from '__generated__/api-di';
import { ApplicantDetails, LoanDetailsFormType, LoanPartType, ToggleType } from '../../types';

const mapGuarantee = (
  value: ClientExtractLoanPartGuarantee | undefined
): ToggleType | undefined => {
  if (
    value === ClientExtractLoanPartGuarantee.NHG ||
    value === ClientExtractLoanPartGuarantee.MunicipalWarranty
  ) {
    return 'yes';
  }
  if (
    value === ClientExtractLoanPartGuarantee.None ||
    value === ClientExtractLoanPartGuarantee.VAT
  ) {
    return 'no';
  }
  return undefined;
};

const mapLoanParts = (loanParts: ClientDetailsLoanParts[] = []): LoanPartType[] => {
  return loanParts.map((loanPart) => {
    return {
      loanPartNumber: loanPart.number ?? undefined,
      repaymentType: loanPart.product ?? undefined,
      shouldSplitLoan: undefined,
      NHGGuarantee: mapGuarantee(loanPart.guarantee),
      loanAmount: loanPart.residualDebt ?? undefined,
      hasConnectedPolicy: undefined,
      convertLoan: undefined,
      splitLoan: undefined,
      numberOfSplitLoanParts: undefined,
    };
  });
};

const mapApplicants = (persons: ClientDetailsPerson[] | undefined): ApplicantDetails[] | [] => {
  if (!persons) return [];
  return persons?.map((person) => ({
    initials: person?.natural?.name?.initials,
    lastName: person?.natural?.name?.lastName,
    middleName: '',
  }));
};

export const mapClientData = (
  clientData: ClientDetailsModel,
  commercialLabel: string
): LoanDetailsFormType => {
  const { loan, loanParts, persons } = clientData;
  return {
    conversionQuestionnaire: {
      residualDebt: loan?.residualDebt ?? undefined,
      loanNumber: loan?.number ?? 0,
      toggleInterest: undefined,
      toggleCoFinancedFine: undefined,
      toggleFine: undefined,
    },
    loanParts: mapLoanParts(loanParts),
    status: Status.New,
    typeOfLoan: TypeOfLoan.Conversion,
    commercialLabel: commercialLabel,
    moneylenderName: '',
    id: 0,
    applicants: mapApplicants(persons),
  };
};
export default mapClientData;
