import applicantAccumulatedIncomesSchema from './applicantAccumulatedIncomesSchema';
import applicantCollateralFields from './applicantCollateralsFields';
import applicantCollateralConditions from './applicantCollateralConditions';
import { schemaDraft07 } from './constants';

const schema = {
  $schema: schemaDraft07,
  $id: 'https://e-adviseur.stater.com/buytolet/applicant-incomes-and-properties',
  title: 'Applicant incomes and properties',
  definitions: {
    accumulatedIncomes: applicantAccumulatedIncomesSchema,
  },
  type: 'object',
  properties: {
    ...applicantCollateralFields,
    accumulatedIncomes: {
      $ref: '#/definitions/accumulatedIncomes',
    },
    isVastgoedBelangMember: {
      type: 'boolean',
    },
    isShareholder: {
      type: 'boolean',
    },
    companyNameShareholdership: {
      type: ['string', 'null'],
    },
    shareholderPercentage: {
      type: ['number', 'null'],
      maximum: 100,
    },
    isDirector: {
      type: 'boolean',
    },
    companyNameDirectorship: {
      type: ['string', 'null'],
    },
  },
  allOf: [
    {
      if: {
        properties: {
          isShareholder: {
            const: true,
          },
        },
      },
      then: {
        properties: {
          companyNameShareholdership: {
            type: 'string',
            minLength: 1,
            maxLength: 100,
          },
        },
        required: ['companyNameShareholdership'],
      },
    },
    {
      if: {
        properties: {
          isDirector: {
            const: true,
          },
        },
      },
      then: {
        properties: {
          companyNameDirectorship: {
            type: 'string',
            minLength: 1,
            maxLength: 100,
          },
        },
        required: ['companyNameDirectorship'],
      },
    },
    ...applicantCollateralConditions,
  ],
  required: ['isVastgoedBelangMember', 'isShareholder', 'isDirector'],
};

export default schema;
