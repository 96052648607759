import { SelectOption } from 'types/SelectOption';
import SplitLoan from '../SplitLoan';

export const renderSplitLoan = (
  loanPartIndex: number,
  expanded: boolean,
  shouldUnregister: boolean,
  productsData?: SelectOption[],
  numberOfSplitLoanParts?: number
) => {
  if (numberOfSplitLoanParts) {
    return Array.from(Array(numberOfSplitLoanParts)).map((part, splitLoanIndex) => (
      <SplitLoan
        key={`splitloan-${part}-${splitLoanIndex}`}
        loanPartIndex={loanPartIndex}
        splitLoanIndex={splitLoanIndex}
        expanded={expanded}
        shouldUnregister={shouldUnregister}
        productsData={productsData}
      />
    ));
  }
  return undefined;
};
