import { LoanInfo } from 'components/MutationDialog/useMutation';
import { FC } from 'react';
import ConnectedPolicies from '../ConnectedPolicies';
import LoanDetails from '../LoanDetails';
import Summary from '../Summary';
import { Option } from 'screens/Mutations/utils/types';
import { StepNames } from '../../types';
import AdditionalInformation from '../AdditionalInformation/AdditionalInformation';

type StepsProps = {
  technicalProductOptions: Option[];
  mutationInfo?: LoanInfo;
  activeStep: StepNames;
};

const Steps: FC<StepsProps> = ({ technicalProductOptions, mutationInfo, activeStep }) => {
  return (
    <>
      {activeStep === 'loanDetails' && (
        <LoanDetails
          technicalProductOptions={technicalProductOptions}
          mutationInfo={mutationInfo}
        />
      )}
      {activeStep === 'policies' && <ConnectedPolicies />}
      {activeStep === 'additionalInformation' && <AdditionalInformation />}
      {activeStep === 'summary' && <Summary />}
    </>
  );
};
export default Steps;
