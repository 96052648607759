import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Button, IconButton, Icon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Zoom from 'components/DocumentViewer/Zoom';

const iconPending = require('../../assets/images/proof-item/pending.svg');

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    minHeight: 72,
    flexGrow: 1,
    boxShadow: theme.shadows[6],
    zIndex: 3,
  },
  headerLeft: {
    marginLeft: theme.spacing(2),
    flexGrow: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerCenter: {
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: theme.spacing(1),
    color: theme.palette.grey[600],
    fontWeight: 'bold',
  },
  headerRight: {
    flexGrow: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: theme.palette.grey[600],
    fontWeight: 'bold',
  },
  headerIcon: {
    'margin': theme.spacing(0, 1, 0, 1),
    'color': theme.palette.grey[600],
    '&:hover': {
      color: theme.palette.grey[900],
    },
  },
  headerTitle: {
    flexGrow: 10,
  },
  closeButton: {
    'margin': theme.spacing(1, 3, 1, 1),
    'float': 'right',
    'backgroundColor': 'transparent',
    'borderColor': 'transparent',
    'color': theme.palette.grey[600],
    '&:hover': {
      color: theme.palette.grey[900],
      cursor: 'pointer',
    },
  },
  documentIcon: {
    width: 24,
    height: 24,
    margin: theme.spacing(1, 1, 1, 3),
    color: theme.palette.grey[600],
  },
  download: {
    display: 'flex',
    alignItems: 'center',
  },
  downloadBtn: {
    'color': theme.palette.grey[600],
    'paddingRight': theme.spacing(2),
    '&:hover': {
      'color': theme.palette.grey[900],
      '&.headerIcon': {
        color: theme.palette.grey[900],
      },
    },
  },
}));

type Props = {
  filename?: string;
  onZoom: (scale: number) => void;
  onClose: () => void;
  onDownloadPdf?: () => void;
  zoomVisible?: boolean;
  downloadVisible?: boolean;
};

const ViewMutationDocumentHeader: FC<Props> = ({
  filename,
  zoomVisible,
  downloadVisible,
  onZoom,
  onDownloadPdf,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.header}>
      <div className={classes.headerLeft}>
        <div className={classes.headerIcon}>
          <img src={iconPending.default ?? iconPending} alt="" />
        </div>
        <div className={classes.headerTitle}>
          <Typography variant="h6">{filename}</Typography>
        </div>
      </div>

      <div className={classes.headerCenter}>{zoomVisible && <Zoom onZoom={onZoom} />}</div>

      <div className={classes.headerRight}>
        {downloadVisible && (
          <div className={classes.download}>
            <Button
              onClick={onDownloadPdf}
              variant="text"
              className={classes.downloadBtn}
              type="submit"
              startIcon={<Icon>download</Icon>}
            >
              {t('mutations.download')}
            </Button>
          </div>
        )}
        <IconButton onClick={onClose} className={classes.closeButton} size="large">
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default ViewMutationDocumentHeader;
