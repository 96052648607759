import { Box, Button } from '@mui/material';
import { FC } from 'react';
import BaseDialog from './BaseDialog';
import { useStyles } from './BaseDialog.style';

type ConfirmationDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirmation: () => void;
  onDecline: () => void;
  confirmationText: string;
  declineText: string;
  bodyText: string;
  isLoading?: boolean;
};

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  isOpen,
  onClose,
  onConfirmation,
  onDecline,
  confirmationText,
  declineText,
  bodyText,
  isLoading,
}) => {
  const ConfirmationButtons = () => {
    const classes = useStyles();

    return (
      <Box className={classes.buttonGroup}>
        <Button
          onClick={onConfirmation}
          variant="contained"
          color="primary"
          disabled={isLoading}
          data-testid="btn-confirm"
        >
          {confirmationText}
        </Button>
        <Button onClick={onDecline} variant="text" disabled={isLoading} data-testid="btn-decline">
          {declineText}
        </Button>
      </Box>
    );
  };

  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={onClose}
      buttonGroup={ConfirmationButtons()}
      bodyText={bodyText}
    />
  );
};

export default ConfirmationDialog;
