import { TableRow, TableCell, IconButton, Typography, Collapse } from '@mui/material';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Amount } from 'lib/Amount/Amount';
import { calculateCompletion } from 'utils/calculateCompletion';
import PercentageDisplay from 'lib/PercentageDisplay';
import { calcPrincipalSum } from 'screens/Clients/utils/calculateLoanParts';
import TableCollapseArea from './TableCollapseArea';
import { LoanPartsProgress } from './BorderLinearProgress';
import { LoanPartsTableProps } from 'types/Client';

function LoanPartsTable({ loanPart, translationsRoot }: LoanPartsTableProps) {
  const [open, setOpen] = useState(false);
  const principalSum = calcPrincipalSum(loanPart);
  const progress = calculateCompletion(principalSum, loanPart.residualDebt, 0) ?? 0;
  const { interestPeriod } = loanPart;

  return (
    <>
      <TableRow style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)} hover>
        <TableCell align="left">
          <Typography sx={{ fontWeight: 'bold' }}>{loanPart.product}</Typography>
        </TableCell>
        <TableCell align="left">
          <Amount value={principalSum} />
        </TableCell>
        <TableCell align="left">
          <Amount value={loanPart.residualDebt} />
        </TableCell>
        <TableCell align="left">
          <PercentageDisplay value={interestPeriod?.effectiveInterestRate} emptyValue="-" />
        </TableCell>
        <TableCell align="left">
          <LoanPartsProgress variant="determinate" value={progress} />
        </TableCell>
        <TableCell align="left">
          <IconButton aria-label="expand row">{open ? <RemoveIcon /> : <AddIcon />}</IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} padding="none" style={{ border: 'none' }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableCollapseArea loanPart={loanPart} translationsRoot={translationsRoot} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
export default LoanPartsTable;
