import { styled } from '@mui/styles';

export const NotificationNumber = styled('div')<any>(({ theme }) => {
  return {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
});

export const NotificationNumberLarge = styled(NotificationNumber)<any>(({ theme }) => {
  return {
    minWidth: '24px',
    padding: '0 8px',
    height: 24,
    borderRadius: 12,
    marginLeft: theme.spacing(2),
    fontSize: 13,
  };
});

export const NotificationNumberMedium = styled(NotificationNumber)<any>(({ theme }) => {
  return {
    minWidth: '20px',
    padding: '0 5px',
    height: 20,
    borderRadius: 10,
    marginLeft: theme.spacing(2),
    fontSize: 10,
  };
});

export const NotificationNumberSmall = styled(NotificationNumber)<any>(({ theme }) => {
  return {
    minWidth: '16px',
    padding: '0 5px',
    height: 16,
    borderRadius: 8,
    marginLeft: theme.spacing(1),
    fontSize: 8,
  };
});
