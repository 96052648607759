import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import ApplicationList from './components/ApplicationList';
import ConceptList from './components/ConceptList';
import ApplicationsHeader from './components/ApplicationsHeader/ApplicationHeader';
import SearchBar from '../../components/SearchBar';
import { useApplications } from './hooks/useApplications';
import { useFilter } from './hooks/useFilter';
import BuyToLet from 'screens/BuyToLet/BuyToLet';
import { useQueryConcepts } from 'use/applications';
import { useQueryCases } from 'use/cases';
import { Case } from '__generated__/api-di';
import { ApplicationData } from '__generated__/api';
import ApplicationFilters from './components/Filters/ApplicationFilters';
import WrapperTemplate from '../templates/Lists/ListWrapper/WrapperTemplate';
import ConceptsFilters from './components/Filters/ConceptsFilters';
import ApplicationDetail from './components/ApplicationDetail';
import { UrlParams } from 'screens/Mutations/utils/types';
import { useParams } from 'react-router-dom';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

const Applications: React.FC = () => {
  const { t } = useTranslation();
  const { commercialRelation } = useParams<keyof UrlParams>() as UrlParams;
  if (commercialRelation) localStorage.setItem('commercialRelation', commercialRelation);

  const [btlVisible, setBtlVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | false>(false);
  const [activeTab, setActiveTab] = useState<'cases' | 'concepts'>('cases');
  const { data: concepts, isLoading: isConceptLoading } = useQueryConcepts();
  const { data: cases, isLoading: isCasesLoading, refetch: casesRefetch } = useQueryCases();
  const { selectedApplication, buyToLetApplication, onSelectApplication, onClose } =
    useApplications();

  const {
    filterState,
    searchTerm,
    emptySearch,
    filteredItems,
    handleInputChange,
    handleMultiSelectChange,
    handleSingleFilterChange,
  } = useFilter('cases', cases);

  const {
    filterState: conceptFilterState,
    searchTerm: conceptSearchTerm,
    filteredItems: conceptFilteredItems,
    handleInputChange: conceptHandleSearch,
    emptySearch: conceptEmptySearch,
    handleMultiSelectChange: conceptHandleMultiSelectChange,
    handleSingleFilterChange: conceptHandleSortingChanged,
  } = useFilter('concepts', concepts);

  const handleConceptSortChange = (e: SelectChangeEvent) => {
    conceptHandleSortingChanged(e);
  };
  const handleConfirmationClose = () => {
    setSuccessMessage(false);
  };
  const onBTLSuccess = (emailSent: boolean) => {
    if (emailSent) {
      setSuccessMessage('buyToLetForm.emailSent');
    } else {
      setSuccessMessage('buyToLetForm.emailNotSent');
    }
  };

  const onBTLClose = () => {
    setBtlVisible(false);
    onClose();
    casesRefetch();
  };

  return (
    <>
      {activeTab === 'cases' && (
        <WrapperTemplate
          header={
            <ApplicationsHeader
              type={activeTab}
              handleType={setActiveTab}
              onClickNew={() => setBtlVisible(true)}
            />
          }
          searchBar={
            <SearchBar
              placeholder={t('applications.filter.search')}
              searchTerm={searchTerm}
              handleInputChange={handleInputChange}
              emptySearch={emptySearch}
              width={260}
            />
          }
          filterArea={
            <ApplicationFilters
              filterState={filterState}
              handleMultiSelectChange={handleMultiSelectChange}
              handleSingleFilterChange={handleSingleFilterChange}
              items={cases}
            />
          }
          list={
            <ApplicationList
              isLoading={isCasesLoading}
              items={filteredItems as Case[]}
              onClickItem={onSelectApplication}
            />
          }
          mobileFilterArea={
            <ApplicationFilters
              filterState={filterState}
              handleMultiSelectChange={handleMultiSelectChange}
              handleSingleFilterChange={handleSingleFilterChange}
              items={Array.isArray(cases) ? cases : []}
              isMobile
              onClick={() => setBtlVisible(true)}
            />
          }
        />
      )}
      {activeTab === 'concepts' && (
        <WrapperTemplate
          header={
            <ApplicationsHeader
              type={activeTab}
              handleType={setActiveTab}
              onClickNew={() => setBtlVisible(true)}
            />
          }
          searchBar={
            <SearchBar
              placeholder={t('applications.filter.search')}
              searchTerm={conceptSearchTerm}
              handleInputChange={conceptHandleSearch}
              emptySearch={conceptEmptySearch}
              width={260}
            />
          }
          filterArea={
            <ConceptsFilters
              filterState={conceptFilterState}
              items={Array.isArray(concepts) ? concepts : []}
              handleMultiSelectChange={conceptHandleMultiSelectChange}
              handleSingleFilterChange={handleConceptSortChange}
            />
          }
          list={
            <ConceptList
              isLoading={isConceptLoading}
              items={conceptFilteredItems as ApplicationData[]}
              onClickItem={onSelectApplication}
            />
          }
        />
      )}

      {selectedApplication && (
        <ApplicationDetail application={selectedApplication} onClose={onClose} />
      )}

      <BuyToLet
        open={btlVisible || Boolean(buyToLetApplication)}
        application={buyToLetApplication}
        showSuccess={onBTLSuccess}
        onClose={onBTLClose}
      />
      <CustomSnackbar
        isOpen={Boolean(successMessage)}
        handleClose={handleConfirmationClose}
        severity={successMessage === 'buyToLetForm.emailNotSent' ? 'warning' : 'success'}
        message={t(`${successMessage}.confirmation`)}
      />
    </>
  );
};

export default Applications;
