/* eslint-disable camelcase */
import jwt from 'jwt-decode';
import { UserManager, UserManagerSettings } from 'oidc-client';
import { removeSelectedGroup, removeLogInFlag } from '../store/appReducer';
import env from '../config/env';
import { isLoggedInAsObserver, getResourceAccessRoles, getClientId, getLoginType } from './utils';
import { removePartyId } from 'components/OfficeSelect/utils/setPartyId';
import { OBSERVER_ENTRY_URL } from './constants';
import isNil from 'utils/predicates/isNil';

const KEYCLOAK = (window as any).REACT_APP?.KEYCLOAK ?? {};

export const getAccountNameClaim = () => KEYCLOAK.ACCOUNT_NAME_CLAIM;

const settings: UserManagerSettings = {
  response_type: 'code',
  scope: 'openid',
  prompt: 'login',
  loadUserInfo: false,
  automaticSilentRenew: true,
  redirect_uri: window.location.origin,
  post_logout_redirect_uri: window.location.origin,
  client_id: getClientId(getLoginType(env, isLoggedInAsObserver())),
  monitorSession: false,
};

const settingsStaterSso = {
  ...settings,
  authority: KEYCLOAK.AUTHORITY,
  metadataUrl: KEYCLOAK.METADATA_URL,
};

const settingsObserver = {
  ...settingsStaterSso,
  redirect_uri: `${window.location.origin}/${OBSERVER_ENTRY_URL}`,
  authority: KEYCLOAK.AUTHORITY,
  client_id: KEYCLOAK.CLIENT_OBSERVER_ID,
};

// TODO: remove these 'global state' variables
const managerStaterSso = new UserManager(settingsStaterSso);
const managerObserver = new UserManager(settingsObserver);

export const addAccessTokenExpired = (callback: () => void) => {
  getUserManager().events.addAccessTokenExpired(callback);
};

export const addSilentRenewError = (callback: () => void) => {
  getUserManager().events.addSilentRenewError(callback);
};

export const handleSilentRenewError = () => {
  logout();
};

export const handleAccessTokenExpired = () => {
  getUserManager().signoutRedirect();
  removeLogInFlag();
};

export const getUserManager = () =>
  window.location.pathname === `/${OBSERVER_ENTRY_URL}` || isLoggedInAsObserver()
    ? managerObserver
    : managerStaterSso;

export function getBearerToken() {
  const accessToken = getUserManager()
    .getUser()
    .then((user) => user?.access_token);
  return accessToken;
}

export async function logout() {
  const userManager = getUserManager();

  removeSelectedGroup();
  removePartyId();
  userManager.signoutRedirect({ extraQueryParams: { prompt: 'login' } });
  removeLogInFlag();
}

export function selectOffice() {
  // temporarily do a complete logout
  logout();
  // removeSelectedGroup();
  // return managerAdvisory.signoutRedirect();
}

export async function getUserIdFromToken() {
  const token = await getBearerToken();

  if (!token) return false;

  const decoded = jwt<any>(token);

  return decoded?.sub;
}

export async function isEherkenningUserFromToken() {
  const token = await getBearerToken();

  if (!token) return false;

  const decoded = jwt<any>(token);

  return !isNil(decoded.party_id);
}

export async function getRoleFromToken() {
  const token = await getBearerToken();

  if (!token) return null;

  const loginType = getLoginType(env, isLoggedInAsObserver());

  return getResourceAccessRoles(
    token,
    // when logging in as observer, the resource_access contains
    // another client_id: nl.stater.eadviseur
    loginType === 'keycloak-observer' ? getClientId('keycloak') : getClientId(loginType)
  );
}
