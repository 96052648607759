const currentDeployment = () => {
  const url = window.location.href.toLocaleLowerCase();
  let environment: string;
  if (url.includes('adviseur-')) {
    environment = url.split('adviseur-')[1].split('.')[0];
  } else {
    environment = 'default';
  }
  const capitalizedEnv = environment.charAt(0).toUpperCase() + environment.slice(1);
  return capitalizedEnv;
};

export default currentDeployment;
