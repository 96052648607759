import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import Link from '@mui/material/Link';
import Logo from 'components/Logo';
import { formatLoanNumber } from 'utils/global';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Client, getCustomerNames } from 'types/Client';
import { Emphasize, MobileTableRow } from 'screens/templates/Lists/ListWrapper/Wrapper.styled';
import { MobileContainer, NavLinkIcon } from 'screens/Clients/Clients.styled';

type MobileProps = {
  clients: Client[] | [];
  onClickItem: (item: Client, location?: string) => void;
};

const ClientListMobile: FC<MobileProps> = ({ clients, onClickItem }) => {
  const { t } = useTranslation();
  const referrer = window.location.href;
  const pathName = window.location.pathname;

  return (
    <Hidden smUp>
      {clients.map((item, index) => (
        <MobileContainer
          key={`${item.customerId}-${index}`}
          data-testid="clientList-mobile"
          onClick={() => onClickItem(item)}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Emphasize>{getCustomerNames(item)}</Emphasize>
          </Box>

          {item.loans.map((loan, i) => (
            <Box
              data-testid={`clientListMobile-loan-${index}`}
              key={`${loan.loanNumber}-${i}`}
              display="flex"
              alignItems="center"
              flexDirection="row"
              onClick={() => onClickItem(item, pathName)}
            >
              <Box display="flex" alignItems="center">
                <Logo label={loan.commercialLabel} size="S" />
              </Box>
              <Box display="flex" flexDirection="row">
                <MobileTableRow>
                  <span>{loan.moneylenderName}</span>
                  <span>‧</span>
                  <span>{formatLoanNumber(loan.loanNumber)}</span>
                  <span>‧</span>
                  <span>{t(`clients.loanType.${loan.loanType}`)}</span>
                </MobileTableRow>
                {loan.eConsumerLink && (
                  <Link
                    onClick={(e: any) => e.stopPropagation()}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${loan.eConsumerLink}&referrer=${referrer}`}
                  >
                    <NavLinkIcon>logout</NavLinkIcon>
                  </Link>
                )}
              </Box>
            </Box>
          ))}
          <Divider />
        </MobileContainer>
      ))}
    </Hidden>
  );
};

export default ClientListMobile;
