import MoneyLenderSubmitBody from 'types/MoneyLenderSubmitBody';
import { HEADER_SELECTED_GROUP_CLAIM, http } from '../http';
import type { Client } from '../types/Client';

export type Filter = {
  filterField: string;
  filterOperator: 'like' | 'equal';
  value: string;
};

export type ClientListResponse = {
  total: number;
  syncedOnUtc: string;
  isExportAvailable: boolean;
  clients: Client[];
};

export const getClientsList = async (
  isLabeled: boolean,
  commercialRelation: string | undefined,
  group: string | null,
  skip: number,
  take: number,
  filters: Filter[]
): Promise<ClientListResponse> => {
  return isLabeled
    ? getLabeledClientsList(group, commercialRelation, skip, take, filters)
    : getGenericClientsList(group, skip, take, filters);
};

const getLabeledClientsList = async (
  group: string | null,
  commercialRelation: string | undefined,
  skip: number,
  take: number,
  filters: Filter[]
): Promise<ClientListResponse> => {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const mappedFilters = filters
    .map((filter) => `&filter=${filter.filterField} ${filter.filterOperator} ${filter.value}`)
    .join('');

  const response = await http.get(
    `/api/clients/commercialrelation/${commercialRelation}?skip=${skip}&take=${take}${mappedFilters}`,
    {
      headers,
    }
  );

  return response.data;
};

const getGenericClientsList = async (
  group: string | null,
  skip: number,
  take: number,
  filters: Filter[]
): Promise<ClientListResponse> => {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const mappedFilters = filters
    .map((filter) => `&filter=${filter.filterField} ${filter.filterOperator} ${filter.value}`)
    .join('');

  const response = await http.get(`/api/clients?skip=${skip}&take=${take}${mappedFilters}`, {
    headers,
  });

  return response.data;
};

export type MoneylenderResponse = {
  commercialLabel: string;
  moneylenderName: string;
};

export const getMoneylenders = async (group: string | null): Promise<MoneylenderResponse[]> => {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const response = await http.get('/api/clients/moneylenders', {
    headers,
  });

  return response.data;
};

export const syncClientList = async (group: string) => {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  await http.post('/api/clients/sync', null, { headers });
};

export type RelatedMoneylenderResponse = {
  commercialRelation: string;
  displayName: string;
};

export const getRelatedMoneylenders = async (
  group: string | null
): Promise<RelatedMoneylenderResponse[]> => {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };

  const response = await http.get('/api/v1/moneylenders', {
    headers,
  });

  return response.data;
};

export async function submitCreateClient(group: string, formData: MoneyLenderSubmitBody) {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };

  const response = await http.post('/api/v1/clients/transfer', formData, {
    headers,
  });
  return response;
}
