import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setApplicationId } from '../../../store/conversionFormReducer';
import { setApplicationId as setOhaApplicationId } from '../../../store/ohaFormReducer';
import { ApplicationReadDto, ApplicationData } from '__generated__/api';
import { Case } from '__generated__/api-di';
import {
  isApplicationData,
  isConceptDismissalJointLiability,
  isConceptConversion,
  isCase,
  isNewLoan,
} from '../predicates';
import { UrlParams } from 'screens/Mutations/utils/types';

export function useApplications() {
  const { state } = useLocation();
  const { commercialRelation } = useParams<keyof UrlParams>() as UrlParams;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedApplication, setSelectedApplication] = useState<{
    application: Case;
    from: string;
  }>();
  const [buyToLetApplication, setBuyToLetApplication] = useState<ApplicationReadDto | undefined>();

  useEffect(() => {
    if (state) {
      setSelectedApplication(state as { application: Case; from: string });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    if (commercialRelation) {
      navigate(`/${commercialRelation}/applications`);
    }
    setBuyToLetApplication(undefined);
    setSelectedApplication(undefined);
  };

  const onSelectApplication = (application: Case | ApplicationData, location?: string) => {
    if (isApplicationData(application) && isConceptDismissalJointLiability(application)) {
      dispatch(setOhaApplicationId(application.id!));
      navigate(
        `/mutaties/oha/${application.commercialLabel}/${application.loanNumber}/blijvende-aanvrager-personalia`,
        { state: { from: location } }
      );
    }
    if (isApplicationData(application) && isConceptConversion(application)) {
      dispatch(setApplicationId(application.id!));
      navigate(`/mutations/conversion/${application.commercialLabel}/${application.loanNumber}`, {
        state: { from: location },
      });
    }
    if (isApplicationData(application) && isNewLoan(application)) {
      setBuyToLetApplication(application);
    }
    if (isCase(application)) {
      setSelectedApplication({ application, from: location } as
        | { application: Case; from: string }
        | undefined);
      navigate(
        `/${commercialRelation}/applications/${application.application!.loanNumberExternal}/${
          application.application!.index
        }`,
        { state: { application, from: location } }
      );
    }
  };

  return {
    selectedApplication,
    buyToLetApplication,
    onClose,
    onSelectApplication,
  };
}
