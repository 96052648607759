import { Box as MuiBox } from '@mui/material';
import { styled } from '@mui/material/styles';

const Box = styled(MuiBox)({
  display: 'flex',
  flexGrow: 1,
  height: '100%',
  flexDirection: 'row',
});

export default Box;
