import {
  Hidden,
  Divider,
  Grid,
  Button,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { formatTime } from 'utils/global';
import { TitleNotification } from 'screens/Clients/Clients.styled';
import { Emphasize, MobileTableRow } from 'screens/templates/Lists/ListWrapper/Wrapper.styled';
import formatDate from 'utils/formatDate';
import type { Letter } from '__generated__/api';
import { useTranslation } from 'react-i18next';

export type LetterTableProps = {
  clientLetters?: Letter[];
  handleDocument: (item: Letter) => void;
};

export default function LetterTable({ clientLetters, handleDocument }: LetterTableProps) {
  const { t } = useTranslation();

  return (
    <>
      <Hidden mdDown>
        <TableContainer>
          <Table aria-label="letters table">
            <TableHead>
              <TableRow>
                <TableCell>{t('letters.date')}</TableCell>
                <TableCell>{t('letters.type')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {clientLetters?.map((item: Letter, index: number) => (
                <TableRow key={`${item.creationDate}${item.id}${index}`}>
                  <TableCell>
                    <Emphasize>{formatDate(item.creationDate)}</Emphasize>
                  </TableCell>
                  <TableCell>
                    <Emphasize>{item.displayName}</Emphasize>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      color="primary"
                      variant="text"
                      endIcon={<Icon>paperclip</Icon>}
                      onClick={() => handleDocument(item)}
                    >
                      {t('letters.viewLetter')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>
      <TableMobile clientLetters={clientLetters} handleDocument={handleDocument} />
    </>
  );
}

const TableMobile = ({ clientLetters, handleDocument }: LetterTableProps) => {
  return (
    <Hidden mdUp>
      <Divider />
      {clientLetters?.map((item: Letter, index: number) => (
        <Grid container mt={1} alignItems="center" key={`${item.creationDate}${item.id}${index}`}>
          <Grid item xs={10} my={1}>
            <Grid item xs={12}>
              <MobileTableRow color="secondary">
                <span>{formatDate(item.creationDate)}</span>
                <span>‧</span>
                <span>{formatTime(item.creationDate)} </span>
              </MobileTableRow>
            </Grid>
            <TitleNotification item xs={12}>
              {item.displayName}
            </TitleNotification>
          </Grid>

          <Grid item xs={2}>
            <Button
              color="primary"
              variant="text"
              endIcon={<Icon>paperclip</Icon>}
              onClick={() => handleDocument(item)}
            />
          </Grid>
        </Grid>
      ))}
    </Hidden>
  );
};
