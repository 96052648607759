import type { FC } from 'react';
import { Description, TitleBox, Title } from './FormTitle.styled';

type FormTitleTextProps = {
  title: string;
  description?: string[] | string;
  className?: string;
};

export const FormTitleText: FC<FormTitleTextProps> = ({ title, description, className }) => {
  const descriptionArray = !Array.isArray(description) ? [description] : description;

  return (
    <TitleBox className={className} data-testid="header-area">
      <Title variant="h2">{title}</Title>

      {descriptionArray?.map((text, index) => (
        <Description data-testid="second-description" variant="body1" paragraph key={index}>
          {text}
        </Description>
      ))}
    </TitleBox>
  );
};

export default FormTitleText;
