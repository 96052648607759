import React, { FC } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Grid,
  Icon,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';
import { BtlLoan } from 'types/BtlFormApplication';
import { Add, Remove } from '@mui/icons-material';
import { FIXED_INTEREST_PERIOD_IN_YEARS } from '../constants';
import DeleteOutlineIcon from '@mui/icons-material/Delete';
import { formatLoanAmount } from 'utils/global';
import TextInput from 'screens/BuyToLet/BuyToLetForm/form/TextInput';
import DropDownQuestion from 'screens/BuyToLet/BuyToLetForm/form/DropDownQuestion';
import CurrencyField from 'lib/CurrencyField/CurrencyField';
import { TypeOfRedemption } from '__generated__/api';
import { hasErrorsInTotalLoanPartAmount } from '../utils';
import ConfirmationDialog from 'lib/ConfirmationDialog/ConfirmationDialog';
import withStyles from '@mui/styles/withStyles';
import { ButToLetDecimalAmountEnabled } from 'config/constants';
import DurationLabel from 'lib/DurationLabel';

const useStyles = makeStyles((theme) => ({
  formGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  userIcon: {
    color: theme.palette.text.secondary,
    fontSize: '20px',
    marginRight: theme.spacing(2),
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
  tableHeader: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(4),
  },
  tableRow: {
    borderBottom: '1px solid rgba(224, 224, 224, 0.5)',
  },
  accordion: {
    width: '100%',
  },
  createButton: {
    marginTop: theme.spacing(2),
    maxWidth: '200px',
  },
  deleteButton: {
    marginLeft: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    color: theme.palette.danger.main,
  },
  padding: {
    padding: theme.spacing(2, 3),
  },
  nowrap: {
    'white-space': 'nowrap',
  },
}));

export type BuyToLetLoanPartsProps = {
  handleFormData: (
    e: { target: { value: any; name: string } } | React.ChangeEvent<{ value: any; name: string }>,
    loanPartIndex?: number
  ) => void;
  handleOnCreateLoanPart: () => void;
  showValidations: boolean;
  errorMessage: (id: string, category: string) => React.ReactNode;
  deleteLoanPart: (index: number) => void;
  loan: BtlLoan;
};

const BuyToLetLoanParts: FC<BuyToLetLoanPartsProps> = ({
  handleFormData,
  handleOnCreateLoanPart,
  showValidations,
  errorMessage,
  deleteLoanPart,
  loan,
}) => {
  const maxBtlLoanParts = 25;
  const category = 'loanParts';

  const classes = useStyles();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = React.useState<boolean | number>(false);
  const [expanded, setExpanded] = React.useState<number | false>(false);

  const availableRedemptionTypes = [TypeOfRedemption.Lineair, TypeOfRedemption.InterestOnly];

  const columns = 'buyToLetForm.loanParts.columns';
  const form = 'buyToLetForm.loanParts.form';

  const loanParts = loan.loanParts;

  function handleOnLoanPartChange(e: any) {
    const loanPartIndex = expanded !== false ? expanded : undefined;

    let value = e.target.value;
    if (e.target.name === 'duration') {
      value = value.replace('-', '').replace(',', '').replace('.', '');
      e.target.value = value;
    }

    handleFormData(e, loanPartIndex);
  }

  function createLoanPart() {
    if (loanParts.length < maxBtlLoanParts) {
      loanParts.push({ duration: 360 });
      setExpanded(loanParts.length - 1);
    }
    handleOnCreateLoanPart();
  }

  const handleLoanPartOpen =
    (index: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? index : false);
    };

  function handleOnCurrencyChange(value: number | null, newName: string) {
    const loanPartIndex = expanded !== false ? expanded : undefined;
    const values = {
      target: { name: newName, value: value === null || isNaN(value) ? undefined : value },
    };
    handleFormData(values, loanPartIndex);
  }

  const showLoanAmountError = showValidations && hasErrorsInTotalLoanPartAmount(loan);
  const showLoanPartsError = showValidations && loan.loanParts?.length < 1;
  const durationClasses = `MuiTableCell-head ${classes.nowrap}`;

  const AccordionSummaryStyled = withStyles({
    expandIcon: {
      position: 'absolute',
      right: 10,
    },
  })(AccordionSummary);

  return (
    <>
      <Grid container spacing={4} className={classes.formGrid}>
        <Grid container item spacing={3}>
          <Grid item xs={3} sm={3}>
            <CurrencyField
              label={t('buyToLetForm.loanParts.loanAmount')}
              disabled
              name="amount"
              containerWidth={12}
              value={loan.amount}
              variant="outlined"
              error={showLoanAmountError}
              placeholder=""
              allowDecimals={ButToLetDecimalAmountEnabled}
            />
          </Grid>
          <Grid item xs={9} sm={9}>
            {showLoanAmountError && (
              <Alert severity="error" style={{ marginTop: '22px' }}>
                {t('buyToLetForm.loanParts.errors.loanAmount')}
              </Alert>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Box my={4}>
        <Typography variant="h6">{t(`${form}.loanParts`)}</Typography>
      </Box>
      <Grid
        container
        direction="row"
        item
        xs={12}
        justifyContent="center"
        alignItems="flex-start"
        className={classes.tableHeader}
      >
        <Grid item xs={2} className="MuiTableCell-head">
          {t(`${columns}.part`)}
        </Grid>
        <Grid item xs={2} className="MuiTableCell-head">
          {t(`${columns}.amount`)}
        </Grid>
        <Grid item xs={3} className="MuiTableCell-head">
          {t(`${columns}.typeOfRedemption`)}
        </Grid>
        <Grid item xs={3} className="MuiTableCell-head">
          {t(`${columns}.fixedInterestPeriod`)}
        </Grid>
        <Grid item xs={2} className={durationClasses}>
          {t(`${columns}.duration`)}
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row">
        {loanParts.map((part, index) => (
          <Grid container item key={index}>
            <Accordion
              expanded={expanded === index}
              onChange={handleLoanPartOpen(index)}
              elevation={expanded === index ? 1 : 0}
              className={classes.accordion}
            >
              <AccordionSummaryStyled
                expandIcon={expanded === index ? <Remove /> : <Add />}
                className={classes.tableRow}
              >
                <Grid container item justifyContent="center">
                  <Grid item xs={2}>
                    <strong>{`${t(`${form}.part`)} ${index + 1}`}</strong>
                  </Grid>
                  <Grid item xs={2}>
                    {part.amount ? formatLoanAmount(part.amount) : <RemoveIcon fontSize="small" />}
                  </Grid>
                  <Grid item xs={3}>
                    {part.typeOfRedemption ? (
                      t(`${form}.${part.typeOfRedemption}`)
                    ) : (
                      <RemoveIcon fontSize="small" />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {part.fixedInterestPeriod ? (
                      <DurationLabel months={part.fixedInterestPeriod * 12} />
                    ) : (
                      <RemoveIcon fontSize="small" />
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    {part.duration ? (
                      <DurationLabel months={part.duration} />
                    ) : (
                      <RemoveIcon fontSize="small" />
                    )}
                  </Grid>
                </Grid>
              </AccordionSummaryStyled>

              <AccordionDetails>
                <Grid container item xs={12} sm={10} direction="column">
                  <Grid container item spacing={3} className={classes.formGrid}>
                    <Grid item xs={12} sm={6}>
                      <CurrencyField
                        label={t(`${columns}.amount`)}
                        required
                        containerWidth={12}
                        name="amount"
                        onChange={(number) => handleOnCurrencyChange(number, 'amount')}
                        error={Boolean(errorMessage(`[${index}].amount`, category))}
                        value={part.amount || ''}
                        placeholder={t(`${columns}.amount`)}
                        min={0}
                        allowDecimals={ButToLetDecimalAmountEnabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DropDownQuestion
                        answerOptions={availableRedemptionTypes.map((typeOfRedemption) => ({
                          id: typeOfRedemption,
                          answerTextKey: `${form}.${typeOfRedemption}`,
                        }))}
                        label={t(`${columns}.typeOfRedemption`)}
                        placeholder={t(`${columns}.typeOfRedemption`)}
                        required
                        value={part.typeOfRedemption ?? ''}
                        name="typeOfRedemption"
                        error={Boolean(errorMessage(`[${index}].typeOfRedemption`, category))}
                        onChange={(e) =>
                          handleOnLoanPartChange(
                            e as React.ChangeEvent<{ value: string; name: string }>
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3} className={classes.formGrid}>
                    <Grid item xs={12} sm={6}>
                      <DropDownQuestion
                        answerOptions={FIXED_INTEREST_PERIOD_IN_YEARS.map(
                          (fixedInterestPeriod: number) => ({
                            id: fixedInterestPeriod.toString(),
                            answerTextKey: `${form}.interestPeriod.${fixedInterestPeriod}`,
                          })
                        )}
                        label={t(`${columns}.fixedInterestPeriod`)}
                        placeholder={t(`${columns}.fixedInterestPeriod`)}
                        required
                        value={part.fixedInterestPeriod ?? ''}
                        name="fixedInterestPeriod"
                        error={Boolean(errorMessage(`[${index}].fixedInterestPeriod`, category))}
                        onChange={(e) => {
                          const value = e.target.value;
                          handleOnLoanPartChange({
                            target: {
                              name: 'fixedInterestPeriod',
                              value:
                                value === '' || value === undefined || value === null
                                  ? undefined
                                  : parseInt(value as string, 10),
                            },
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        label={t(`${columns}.duration`)}
                        required
                        type="number"
                        inputProps={{ max: 30, min: 1, pattern: '[1-9][0-9]{2}' }}
                        name="duration"
                        onChange={handleOnLoanPartChange}
                        disabled={part.typeOfRedemption === TypeOfRedemption.InterestOnly}
                        error={Boolean(errorMessage(`[${index}].duration`, category))}
                        value={part.duration ? part.duration / 12 : ''}
                        placeholder={t(`${columns}.duration`)}
                      />
                    </Grid>
                  </Grid>
                  {loanParts.length > 1 ? (
                    <Grid container>
                      <Button
                        onClick={() => setOpenDialog(index)}
                        variant="text"
                        className={classes.deleteButton}
                        endIcon={<DeleteOutlineIcon />}
                      >
                        {t(`${form}.deleteLoanPart`)}
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}

        <ConfirmationDialog
          dialogTitle={t(`${form}.confirmDeleteLoanpart`)}
          confirmButtonText={t(`${form}.delete`)}
          cancelButtonText={t(`${form}.cancel`)}
          open={openDialog !== false}
          handleClose={() => setOpenDialog(false)}
          onConfirm={() => {
            setExpanded(false);
            setOpenDialog(false);
            deleteLoanPart(openDialog as number);
          }}
        />
      </Grid>

      <Box my={3}>
        <Button
          variant="contained"
          color="primary"
          className={classes.createButton}
          disabled={loanParts?.length >= maxBtlLoanParts}
          onClick={createLoanPart}
          endIcon={<Icon className={classes.btnIcon}>plus</Icon>}
        >
          {t(`${form}.addNew`)}
        </Button>
      </Box>

      {showLoanPartsError && (
        <Alert severity="error">{t('buyToLetForm.loanParts.errors.loanParts')}</Alert>
      )}
    </>
  );
};

export default BuyToLetLoanParts;
