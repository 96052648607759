import type { FC } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormInputCurrency } from 'components/Form';
import { FieldsGrid } from 'components/Form/Forms.styled';
import FormTitleText from 'components/Form/FormTitle';

type CollateralProps = {
  title: string;
  subtitle: string;
  rootFormName?: string;
};

const Collateral: FC<CollateralProps> = ({ title, subtitle, rootFormName }) => {
  const { t } = useTranslation();

  return (
    <>
      <FormTitleText title={title} description={subtitle} />
      <FieldsGrid container>
        <Grid item xs={12}>
          <FormInputCurrency
            name={`${rootFormName ?? ''}distributionValue`}
            label={t('directMutations.collateral.distributionValue')}
            placeholder={t('directMutations.amountPlaceholder')}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputCurrency
            name={`${rootFormName ?? ''}marketValue`}
            label={t('directMutations.collateral.marketValue')}
            placeholder={t('directMutations.amountPlaceholder')}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputCurrency
            name={`${rootFormName ?? ''}valuationCosts`}
            label={t('directMutations.collateral.valuationCosts')}
            placeholder={t('directMutations.amountPlaceholder')}
            required
            min={0}
          />
        </Grid>
      </FieldsGrid>
    </>
  );
};

export default Collateral;
