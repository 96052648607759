import { formatAmount } from 'utils/formatAmount';
import { formatLoanNumber } from 'utils/formatLoanNumber';
import { formatTypeOfMutation } from 'utils/formatTypeOfMutation';
import { formatYesNo } from 'utils/formatYesNo/formatYesNo';
import { findConnectedPolicies } from '../../../../../utils/selectors/findConnectedPolicies';
import {
  CardValues,
  ConnectedPoliciesFormType,
  ConversionQuestionnaire,
  LoanPartType,
  SplitLoan,
} from '../../../../../utils/types';

export const getSplitLoanValues = (splitLoans?: SplitLoan[]): CardValues[] => {
  if (!splitLoans || splitLoans.length === 0) return [];

  return splitLoans.map((splitLoan, i) => ({
    headerName: `Leningdeel ${i + 1}`,
    values: [
      {
        testId: 'loanAmount',
        label: 'directMutations.form.loanAmount',
        value: formatAmount(splitLoan.amount),
      },
      {
        testId: 'repaymentType',
        label: 'directMutations.form.repaymentType',
        value: splitLoan.repaymentType,
      },
      {
        testId: 'newFixedInterestPeriod',
        label: 'directMutations.form.newFixedInterestPeriod',
        value: splitLoan.fixedRatePeriod,
      },
      {
        testId: 'NHGGuarantee',
        label: 'directMutations.loanParts.NHGGuarantee',
        value: formatYesNo(splitLoan.NHGGuarantee),
      },
    ],
  }));
};

export const loanPartSummaryViewMap = (
  loanPart: LoanPartType,
  policies: ConnectedPoliciesFormType
): CardValues[] => {
  const returnModel: CardValues[] = [];
  const connectedLoanPartPolicies = findConnectedPolicies(loanPart, policies.loanPartPolicies);

  const conversionValues =
    loanPart.shouldSplitLoan === 'no'
      ? [
          {
            headerName: 'directMutations.header.title',
            values: [
              {
                testId: 'NHGGuarantee',
                label: 'directMutations.loanParts.NHGGuarantee',
                value: formatYesNo(loanPart.convertLoan?.NHGGuarantee),
              },
              {
                testId: 'newFixedInterestPeriod',
                label: 'directMutations.form.newFixedInterestPeriod',
                value: loanPart.convertLoan?.fixedRatePeriod,
              },
              {
                testId: 'newRepaymentType',
                label: 'directMutations.form.newRepaymentType',
                value: loanPart.convertLoan?.repaymentType,
              },
            ],
          },
        ]
      : getSplitLoanValues(loanPart.splitLoan);

  const policyValueObject = connectedLoanPartPolicies.map((policy) => {
    return {
      headerName: 'directMutations.summary.policyHeader',
      values: [
        {
          testId: 'policyNumber',
          label: 'directMutations.policies.policyNumber',
          value: policy.policyNumber,
        },
        {
          testId: 'numberOfPolicyholders',
          label: 'directMutations.policies.numberOfPolicyholders',
          value: policy.numberOfPolicyHolders,
        },
        {
          testId: 'policyHoldersPersonOne',
          label: 'Naam verzekeringnemer 1',
          value: policy.policyHolders?.personOne,
        },
        {
          testId: 'policyHoldersPersonTwo',
          label: 'Naam verzekeringnemer 2',
          value: policy.policyHolders?.personTwo,
        },
        {
          testId: 'isPolicyForConnectedPersons',
          label: 'directMutations.policies.isPolicyForConnectedPersons',
          value: formatYesNo(policy.isPolicyForConnectedPersons),
        },
        {
          testId: 'numberOfInsuredPersons',
          label: 'directMutations.policies.numberOfInsuredPersons',
          value: policy.numberOfInsuredPersons,
        },
        {
          testId: 'insuredPersonOne',
          label: 'Naam verzekerde 1',
          value: policy.insuredPersons?.personOne,
        },
        {
          testId: 'insuredPersonTwo',
          label: 'Naam verzekerde 2',
          value: policy.insuredPersons?.personTwo,
        },
        {
          testId: 'policyAction',
          label: 'directMutations.policies.policyAction',
          value: formatTypeOfMutation(policy.typeOfPolicyMutation),
        },
        {
          testId: 'mutationDescription',
          label: 'directMutations.summary.mutationDescription',
          value: policy.mutationDescription,
        },
      ],
    };
  });
  returnModel.push(
    {
      headerName: 'directMutations.loanDetails.title',
      values: [
        {
          testId: 'loanAmount',
          label: 'directMutations.form.loanAmount',
          value: formatAmount(loanPart.loanAmount),
        },
        {
          testId: 'existingRepaymentType',
          label: 'directMutations.summary.existingRepaymentType',
          value: loanPart.repaymentType,
        },
        {
          testId: 'shouldSplitLoan',
          label: 'directMutations.loanParts.shouldSplitLoan',
          value: formatYesNo(loanPart.shouldSplitLoan),
        },
        {
          testId: 'howManyParts',
          label: 'directMutations.summary.howManyParts',
          value: loanPart.numberOfSplitLoanParts,
        },
        {
          testId: 'NHGGuarantee',
          label: 'directMutations.loanParts.NHGGuarantee',
          value: formatYesNo(loanPart.NHGGuarantee),
        },
        {
          testId: 'hasConnectedPolicy',
          label: 'directMutations.loanParts.hasConnectedPolicy',
          value: formatYesNo(loanPart.hasConnectedPolicy),
        },
      ],
    },
    ...policyValueObject,
    ...conversionValues
  );
  return returnModel;
};

export const questionnaireSummaryViewMap = (
  conversionQuestionnaire: ConversionQuestionnaire
): CardValues[] => {
  const result = [
    {
      headerName: 'directMutations.loanDetails.title',
      values: [
        {
          testId: 'loanNumber',
          label: 'directMutations.form.loanNumber',
          value: formatLoanNumber(conversionQuestionnaire.loanNumber),
        },
        {
          testId: 'residualDebt',
          label: 'directMutations.loanDetails.residualDebt',
          value: formatAmount(conversionQuestionnaire.residualDebt),
        },
        {
          testId: 'toggleInterest',
          label: 'directMutations.loanDetails.toggleInterest',
          value: formatYesNo(conversionQuestionnaire.toggleInterest),
        },
      ],
    },
  ];
  if (conversionQuestionnaire.toggleFine) {
    result[0].values.push({
      testId: 'toggleFine',
      label: 'directMutations.loanDetails.toggleFine',
      value: formatYesNo(conversionQuestionnaire.toggleFine),
    });
  }
  if (conversionQuestionnaire.toggleCoFinancedFine) {
    result[0].values.push({
      testId: 'toggleCoFinancedFine',
      label: 'directMutations.loanDetails.toggleCoFinancedFine',
      value: formatYesNo(conversionQuestionnaire.toggleCoFinancedFine),
    });
  }

  return result;
};
