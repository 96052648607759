import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import { setFirstVisit, setWelcomeClosed } from '../store/appReducer';
import { TargetGroup, UserNotification } from '../__generated__/api';
import { useQuery } from 'react-query';
import { getLastNotificationsDate, storeLastNotificationsDate } from '../utils/global';
import { useEherkenningUser } from './eHerkenning';
import getUserNotifications from 'api/getUserNotification';

const NOTIFICATION_CLOSED_KEY = 'EADVISOR_NOTIFICATION_CLOSED';

const hasValue =
  (searchString: string) =>
  ({ targetGroup }: TargetGroup) =>
    targetGroup ? targetGroup.indexOf(searchString) > -1 : false;

const filterUserNotification =
  (userNotificationLastSeen: string | null, eherkenningUser?: boolean) =>
  ({ targetGroups, priority, dateCreated }: UserNotification) => {
    if (eherkenningUser !== undefined && targetGroups?.length) {
      const forEHerkenning = targetGroups.filter(hasValue('eHerkenning'));

      if (eherkenningUser === false) {
        return false;
      }
      if (eherkenningUser === true && !forEHerkenning.length) {
        return false;
      }
    }

    if (priority?.priority && priority?.priority[0] === '0') return true;

    if (dateCreated !== undefined && userNotificationLastSeen)
      return dateCreated > userNotificationLastSeen;

    return true;
  };

const filterUserNotifications = (
  userNotificationLastSeen: string | null,
  userNotifications?: UserNotification[],
  eherkenningUser?: boolean
) => {
  if (!userNotifications) return null;
  const notifications: UserNotification[] = userNotifications.filter(
    filterUserNotification(userNotificationLastSeen, eherkenningUser)
  );
  return notifications;
};

const storeCookie = () => storeLastNotificationsDate(new Date().toISOString());
const storeNotificationClosed = () => sessionStorage.setItem(NOTIFICATION_CLOSED_KEY, 'true');

export function useUserNotifications() {
  const dispatch = useAppDispatch();
  const [isPopOverClosed, closePopUp] = useState(false);

  // Determines if the user closed a notification today
  const notificationClosed = sessionStorage.getItem(NOTIFICATION_CLOSED_KEY);
  const hasSelectedGroup = useAppSelector((state) => !!state.app.group);
  const { data: userNotificationsData, isLoading: isNotificationLoading } = useQuery(
    ['user-notifications'],
    getUserNotifications
  );
  const { data: eherkenningUser, isLoading: isHerkeningLoading } = useEherkenningUser();

  const userNotificationsLastSeen = getLastNotificationsDate();

  const isLoading = isNotificationLoading || isHerkeningLoading || !hasSelectedGroup;

  const userNotifications: UserNotification[] | null =
    !isLoading && userNotificationsData
      ? filterUserNotifications(userNotificationsLastSeen, userNotificationsData, eherkenningUser)
      : null;

  const setWelcomeClosedTrigger = useCallback(
    (isOpen: boolean) => dispatch(setWelcomeClosed(isOpen)),
    [dispatch]
  );
  const setFirstVisitTrigger = useCallback(
    (isFirstVisit: boolean) => dispatch(setFirstVisit(isFirstVisit)),
    [dispatch]
  );
  const isWelcomeClosed = useAppSelector((state) => !!state.app.isWelcomeClosed);
  const isFirstVisit = useAppSelector((state) => !!state.app.isFirstVisit);

  return {
    userNotificationsLoading: isLoading,
    userNotifications,
    closePopUp,
    setWelcomeClosed: setWelcomeClosedTrigger,
    storeNotificationClosed,
    isPopOverClosed,
    isWelcomeClosed,
    notificationClosed,
    storeCookie,
    setFirstVisitTrigger,
    isFirstVisit,
  };
}
