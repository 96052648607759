import styled from '@mui/styles/styled';
import { Paper, TableRow as MUITableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const BTLCard = styled(Paper)<any, any>(({ theme, open }) => ({
  width: 330,
  padding: theme.spacing(0.5, 3, 2, 3),
  marginTop: -45,
  marginBottom: -30,
  zIndex: open ? 1 : 0,
}));
export const TableRow = styled(MUITableRow)({
  cursor: 'pointer',
});
export const InterPunt = styled('span')(({ theme }) => ({
  margin: theme.spacing(0, 1),
}));

export const useClasses = makeStyles((theme) => ({
  tooltip: {
    'backgroundColor': theme.palette.common.white,
    'fontWeight': 500,
    'borderRadius': 3,
    'padding': '6px 8px',
    'color': theme.palette.text.primary,
    '&.name': {
      fontSize: '14px',
      boxShadow: '0 6px 12px 0 rgba(22,20,47,0.12)',
    },
    '&.status': {
      padding: theme.spacing(1, 2),
      fontSize: '10px',
      textTransform: 'uppercase',
    },
    '&.red': {
      color: theme.palette.danger.dark,
    },
    '&.orange': {
      color: theme.palette.warning.dark,
    },
    '&.grey': {
      color: theme.palette.text.primary,
    },
    '&.green': {
      color: theme.palette.success.dark,
    },
    '&.blue': {
      color: theme.palette.info.dark,
    },
  },
}));

export const LenderName = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '12px',
  display: 'block',
  paddingTop: theme.spacing(1),
}));
