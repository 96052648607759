import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Item from 'components/Menu/Item';
import { goToStep } from 'utils/goToStep';
import { getStepState, StepState } from '../utils/getStepState';

type MenuItemProps = {
  visible?: boolean;
  text?: string;
  step?: string;
  mainStep?: string;
  label?: string;
  children?: React.ReactNode;
};

export const MenuItem: FC<MenuItemProps> = ({
  visible = true,
  text,
  step,
  mainStep,
  label,
  children,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [firstUrlPart, secondUrlPart] = location.pathname.split('/').slice(-2);

  const stepState: StepState = getStepState(firstUrlPart, secondUrlPart, step, mainStep);
  const labelText = text ?? t(`directMutations.stepper.${label}`);

  return visible ? (
    <Item
      step={step}
      active={stepState === StepState.Current || stepState === StepState.Parent}
      expanded={stepState === StepState.Current || stepState === StepState.Parent}
      text={labelText}
      onClick={(e) => {
        if (stepState === StepState.Done && step) {
          goToStep(step, navigate, location.pathname);
        }
      }}
      clickable={stepState === StepState.Done}
    >
      {children}
    </Item>
  ) : null;
};

export default MenuItem;
