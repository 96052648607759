import { http } from '../http';

export async function loginCounter() {
  const url = '/api/v1/login';
  const response = await http({
    url: url,
    method: 'POST',
  });
  return response;
}
