import React, { FC } from 'react';
import { Grid, Typography as MUITypography } from '@mui/material';

import { styled } from '@mui/material/styles';

const Container = styled(Grid)(({ theme }) => ({
  'marginBottom': theme.spacing(5),
  'color': 'grey',
  '&:last-child': {
    marginBottom: '0',
  },
  '.cardRow:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
}));

const Typography = styled(MUITypography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

type Props = {
  title?: string;
  children?: React.ReactNode;
};

const CardSection: FC<Props> = ({ title, children }) => (
  <Container container>
    {title && <Typography>{title}</Typography>}
    {React.Children.toArray(children)}
  </Container>
);

export default CardSection;
