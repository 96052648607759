import { submitCreateClient } from 'api/clients';
import { useMutation } from 'react-query';
import MoneyLenderSubmitBody from 'types/MoneyLenderSubmitBody';
import { useGroup } from './group';

export function useMutateCreateClient(
  onSuccess: (result: any) => void,
  onError: (error: any) => void
) {
  const group = useGroup();
  return useMutation(
    (formData: MoneyLenderSubmitBody) => submitCreateClient(group as string, formData),
    {
      onSuccess,
      onError,
    }
  );
}
