/**
 * see: lodash isNil
 *
 * Checks if `value` is `null` or `undefined`.
 *
 * @returns {boolean} Returns `true` if `value` is nullish, else `false`.
 * @example
 *
 * isNil(null)
 * // => true
 *
 * isNil(void 0)
 * // => true
 *
 * isNil(NaN)
 * // => false
 */
const isNil = (value: any) => value == null;

export default isNil;
