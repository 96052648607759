import { object, string } from 'yup';
import { errorMessages } from '../../../constants';

const getConvertLoanSchema = () =>
  object({
    repaymentType: string().required(errorMessages.required),
    fixedRatePeriod: string().nullable(),
    NHGGuarantee: string().required(errorMessages.required).nullable(),
  });

export default getConvertLoanSchema;
