import { mapEnum } from 'utils/global';
import { CivilStatus, Gender, Nationality, TypeOfPermit } from '__generated__/api';
import { filteredTypeOfIdentification } from '../enums/typeOfIdentification.enum';
import applicantExistingMortgageFields from './applicantExistingMortgageFields';
import applicantExistingMortgageFieldsConditionals from './applicantExistingMortgageFieldsConditionals';
import { schemaDraft07 } from './constants';

const prefix = 'buy_to_let_validation_';

const schema = {
  $schema: schemaDraft07,
  $id: 'https://e-adviseur.stater.com/buytolet/applicant-natural-person',
  title: 'Applicant natural person',
  type: 'object',
  properties: {
    ...applicantExistingMortgageFields,
    gender: {
      enum: mapEnum(Gender),
      description: 'Gender of the applicant',
      $comment: `${prefix}gender`,
    },
    initials: {
      type: 'string',
      isNotEmpty: true,
      description: 'The initials of the applicant',
      $comment: `${prefix}initials`,
      minLength: 1,
      maxLength: 15,
    },
    firstName: {
      type: 'string',
      isNotEmpty: true,
      description: 'The first name of the applicant',
      $comment: `${prefix}firstName`,
      minLength: 1,
      maxLength: 35,
    },
    middleName: {
      type: ['string', 'null'],
      description: 'The middle name of the applicant',
      $comment: `${prefix}middleName`,
      maxLength: 10,
    },
    lastName: {
      type: 'string',
      isNotEmpty: true,
      description: 'The last name of the applicant',
      $comment: `${prefix}lastName`,
      minLength: 1,
      maxLength: 100,
    },
    dateOfBirth: {
      type: 'string',
      format: 'date',
      description: 'Date of birth of the applicant',
      $comment: `${prefix}dateOfBirth`,
    },
    civilStatus: {
      enum: mapEnum(CivilStatus),
      description: 'The marital status of the applicant',
      $comment: `${prefix}dateOfBirth`,
    },
    nationality: {
      enum: mapEnum(Nationality),
      description: 'The nationality of the applicant',
      $comment: `${prefix}nationality`,
    },
    typeOfIdentification: {
      enum: filteredTypeOfIdentification,
      description: 'The type of identification of the applicant',
      $comment: `${prefix}typeOfIdentification`,
    },
    identificationNumber: {
      type: 'string',
      isNotEmpty: true,
      minLength: 1,
      maxLength: 20,
      description: 'The identification nr of the applicant',
      $comment: `${prefix}identificationNumber`,
    },
    typeOfPermit: {
      type: ['string', 'null'],
      enum: [...mapEnum(TypeOfPermit), null],
      description: 'The permit type of the applicant',
      $comment: `${prefix}typeOfPermit`,
    },
    permitNumber: {
      type: ['string', 'null'],
      maxLength: 20,
      description: 'The permit nr of the applicant',
      $comment: `${prefix}permitNumber`,
    },
  },
  allOf: [...applicantExistingMortgageFieldsConditionals],
  required: [
    'hasExistingMortgageByMoneylender',
    'isMortgageRejected',
    'gender',
    'initials',
    'firstName',
    'lastName',
    'dateOfBirth',
    'civilStatus',
    'nationality',
    'typeOfIdentification',
    'identificationNumber',
  ],
};

export default schema;
