import { FC, useEffect, useState } from 'react';
import { Dialog, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Header from 'components/ViewFile/Header';
import FileViewer from 'components/DocumentViewer';
import Footer from 'components/DocumentUpload/Footer';

import { useTranslation } from 'react-i18next';
import { SlideUpTransition } from 'lib/Transition/transition';

const useStyles = makeStyles((theme) => ({
  main: {
    flexGrow: 100,
  },
  fullHeight: {
    height: '100%',
    flexGrow: 100,
  },
}));

type Props = {
  fileInfo: any;
  file: any;
  open: boolean;
  onSuccess: () => void;
  onCancel: () => void;
};

const UploadFile: FC<Props> = ({ open, fileInfo, onSuccess, onCancel, file }) => {
  const [state, setState] = useState('');
  const [selectedFile, setSelectedFile] = useState<File>();
  const classes = useStyles();
  const [scale, setScale] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedFile(file);
  }, [file]);

  const uploadFileInfo = {
    name: file?.name,
    size: file?.size,
  };

  if (uploadFileInfo !== null) {
    fileInfo = uploadFileInfo;
  }

  const reset = () => {
    setState('');
    setSelectedFile(undefined);
  };

  const handleSubmit = () => {
    if (selectedFile !== undefined) {
      onSuccess();
    }
  };

  const handleCancel = () => {
    reset();
    onCancel();
  };

  const handleZoom = (scalePercentage: number) => {
    setScale(scalePercentage / 100);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={SlideUpTransition}
      onClose={onCancel}
      TransitionProps={{ onExited: reset }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="stretch"
        className={classes.fullHeight}
      >
        {state !== 'error' ? (
          <>
            <Header fileInfo={fileInfo} onZoom={handleZoom} onClose={handleCancel} zoomVisible />
            <div className={classes.main}>
              <FileViewer file={selectedFile} scale={scale} hasFooter />
            </div>
            <Footer
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              submitLabel={t('buyToLet.done')}
            />
          </>
        ) : (
          t('error.default')
        )}
      </Box>
    </Dialog>
  );
};

export default UploadFile;
