import { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  documentPage: {
    boxShadow: theme.shadows[6],
  },
  document: {
    marginTop: 48,
    marginBottom: 48,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

type Props = {
  file: File | string | undefined;
  scale: number;
};

const fileAsString = (file: File | string | undefined) => {
  if (!file) return '';

  if (typeof file === 'string') return file;

  return URL.createObjectURL(new Blob([file]));
};

const TifViewer: FC<Props> = ({ file, scale }) => {
  const classes = useStyles();

  const fileName = (file as File)?.name;
  const imageFile = fileAsString(file);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.document}>
          <img src={imageFile} alt={fileName} />
        </div>
      </div>
    </>
  );
};

export default TifViewer;
