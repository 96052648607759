import { FC } from 'react';
import { useQueryClientLetterDocument } from 'use/letters';
import ViewerDialog from './ViewerDialog';

type Props = {
  commercialLabel: string;
  letterId: string;
  open: boolean;
  onClose: () => void;
  loanNumber: number;
  displayName: string;
};

const ViewClientFile: FC<Props> = ({
  commercialLabel,
  letterId,
  open,
  onClose,
  loanNumber,
  displayName,
}) => {
  const document = useQueryClientLetterDocument(commercialLabel, letterId, loanNumber);

  return (
    <ViewerDialog document={document} open={open} onClose={onClose} displayName={displayName} />
  );
};

export default ViewClientFile;
