import { object, number, string } from 'yup';
import { errorMessages } from '../../../constants';

const getSplitLoanSchema = () =>
  object({
    amount: number().typeError(errorMessages.number).required(errorMessages.required),
    fixedRatePeriod: string().nullable(),
    repaymentType: string().required(errorMessages.required),
    NHGGuarantee: string().required(errorMessages.required).nullable(),
  });
export default getSplitLoanSchema;
