import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { BorderLinearProgress } from './components/BorderLinearProgress';
import { Amount } from 'lib/Amount/Amount';
import { calculateCompletion } from 'utils/calculateCompletion';
import LoanPartsTable from './components/LoanPartsTable';
import TemplateDetails from '../ClientData/components/TemplateDetails';
import { DetailsRow } from 'lib/ClientDataDetails/Fields';
import { ClientDetailsLoan, ClientDetailsLoanParts } from 'types/ClientDetailsModel';

export type LoanPartsDetailsProps = {
  loan?: ClientDetailsLoan;
  loanParts?: ClientDetailsLoanParts[];
};

const LoanDetails: FC<LoanPartsDetailsProps> = ({ loan, loanParts }) => {
  const { t } = useTranslation();
  const translationsRoot = 'clients.clientData.loan';
  const principalSumAmount = loan?.principalSum ?? 0;
  const paidAmount = principalSumAmount - (loan?.residualDebt ?? 0);
  const progress = calculateCompletion(principalSumAmount, loan?.residualDebt, 0) ?? 0;

  if (!loan) {
    return null;
  }

  return (
    <>
      <Grid container data-testid="loan-details">
        <Grid item xs={12} md={6} p={2}>
          <TemplateDetails>
            <DetailsRow
              type="currency"
              label={t(`${translationsRoot}.originalAmount`)}
              value={principalSumAmount}
            />
            <DetailsRow
              type="currency"
              label={t(`${translationsRoot}.remainingAmount`)}
              value={loan.residualDebt}
            />
          </TemplateDetails>
        </Grid>

        <Grid item xs={12} md={6} py={1} px={2}>
          <Grid container>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <Typography sx={{ textTransform: 'uppercase' }} variant="body2" component="p">
                {t(`${translationsRoot}.originalAmount`)}
              </Typography>
              <Amount value={principalSumAmount} variant="label" />
            </Grid>
            <BorderLinearProgress variant="determinate" value={progress} />
            <Grid item xs={6} data-testid="loan-paid">
              <Typography variant="body2" color="textSecondary">
                {t(`${translationsRoot}.paid`)}
              </Typography>
              <Amount value={paidAmount} variant="label" />
            </Grid>
            <Grid item xs={6} textAlign="right" data-testid="loan-remaining">
              <Typography variant="body2" color="textSecondary">
                {t(`${translationsRoot}.remaining`)}
              </Typography>
              <Amount value={loan.residualDebt} variant="label" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <LoanPartsTable loanParts={loanParts} />
    </>
  );
};

export default LoanDetails;
