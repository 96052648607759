import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Button,
  Icon,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { fetchMutationForm, MutationInfo } from './useMutation';
import ViewMutationDocument from './ViewMutationDocument';
import { useGoogleTagManager } from 'use/gtm';

const iconMissing = require('../../assets/images/proof-item/missing.svg');
const iconPending = require('../../assets/images/proof-item/pending.svg');

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
  },
  heading: {
    'margin': theme.spacing(5, 0),
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
  list: {
    borderRadius: 6,
    padding: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
  dangerBtn: {
    marginLeft: theme.spacing(1),
    color: theme.palette.danger.main,
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
}));

type MutationFlowStepSelectFileProps = {
  commercialLabel: string;
  selectedMutation: MutationInfo;
  maxFilesizeMb: number;
  selectedFile: any;
  selectFile: (file: any) => void;
  setError: (error: string) => void;
};

const MutationFlowStepSelectFile = (props: MutationFlowStepSelectFileProps) => {
  const { commercialLabel, selectedMutation, maxFilesizeMb, selectedFile, selectFile, setError } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();
  const googleTagManager = useGoogleTagManager();

  const maxFilesizeBytes = maxFilesizeMb * 1024 * 1024;
  const filename = `${t('mutations.mutationForm')} - ${t(
    `mutations.type.${selectedMutation.type}`
  )}`;

  const [showDocument, setShowDocument] = useState(false);
  const downloadForm = () => fetchMutationForm(commercialLabel, selectedMutation);
  const onChangeFile = (event: any) => {
    const { files } = event.target;
    const file = files[0];

    if (file && file.size > maxFilesizeBytes) {
      setError('fileTooLarge');
    } else {
      selectFile(file);
    }
  };

  const mutationList = ['infoTP', 'loanNumber', 'name', 'description'];

  return (
    <Container className={classes.root}>
      <div className={classes.heading}>
        <Typography variant="h4">{t('mutations.stepFileSelectTitle')}</Typography>
        {selectedMutation.applicationForm ? (
          <Typography variant="subtitle1" color="textSecondary">
            {t('mutations.stepFileSelectSubtitle')}
            <Button color="primary" onClick={downloadForm} endIcon={<Icon>download</Icon>}>
              {t('mutations.emptyMutationForm')}
            </Button>
          </Typography>
        ) : (
          <>
            <Typography variant="subtitle1" color="textSecondary">
              {t('mutations.stepFileSelectSubtitleNoForm')}
            </Typography>
            {mutationList.map((m) => {
              return (
                <ul key={m}>
                  <li>
                    <Typography variant="subtitle1" color="textSecondary">
                      {t(`mutations.mutationList.${m}`)}
                    </Typography>
                  </li>
                </ul>
              );
            })}
            <Typography variant="subtitle1" color="textSecondary">
              {t('mutations.stepSelectSubtitleContinue')}
            </Typography>
          </>
        )}
      </div>
      <Paper className={classes.list}>
        <List disablePadding>
          <ListItem>
            <ListItemIcon>
              <img
                src={
                  selectedFile
                    ? iconPending.default ?? iconPending
                    : iconMissing.default ?? iconMissing
                }
                alt=""
              />
            </ListItemIcon>
            <ListItemText primary={filename} secondary={t('mutations.fileNotUploaded')} />
            <ListItemSecondaryAction>
              <input
                accept="application/pdf"
                className={classes.input}
                id="uploadFile"
                type="file"
                onChange={onChangeFile}
                onClick={(e) => {
                  e.currentTarget.value = '';
                }}
              />
              {selectedFile ? (
                <>
                  <Button
                    size="small"
                    color="primary"
                    variant="text"
                    onClick={() => {
                      setShowDocument(true);
                      googleTagManager({ event: 'ButtonClick', btnName: 'ViewMutationFile' });
                    }}
                  >
                    {t('mutations.view')}
                  </Button>
                  <Button
                    className={classes.dangerBtn}
                    size="small"
                    variant="text"
                    onClick={() => {
                      selectFile(undefined);
                      googleTagManager({ event: 'ButtonClick', btnName: 'DeleteMutationFile' });
                    }}
                    endIcon={<Icon className={classes.btnIcon}>trash</Icon>}
                  >
                    {t('mutations.delete')}
                  </Button>
                </>
              ) : (
                <label htmlFor="uploadFile">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    component="span"
                    onClick={() =>
                      googleTagManager({ event: 'ButtonClick', btnName: 'UploadMutationFile' })
                    }
                    endIcon={<Icon fontSize="small">upload</Icon>}
                  >
                    {t('mutations.upload')}
                  </Button>
                </label>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Paper>

      <ViewMutationDocument
        file={selectedFile}
        filename={filename}
        open={showDocument}
        onClose={() => {
          setShowDocument(false);
          googleTagManager({ event: 'ButtonClick', btnName: 'ExitMutationFileView' });
        }}
      />
    </Container>
  );
};

export default MutationFlowStepSelectFile;
