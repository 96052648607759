import { FC, ReactNode } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormField } from './FormField';
import { StandardTextFieldProps, TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  input: {
    '& input': {
      padding: 15,
    },
  },
}));

interface TextInputProps extends StandardTextFieldProps {
  tooltip?: ReactNode;
}

const TextInput: FC<TextInputProps> = ({ tooltip, required, ...props }) => {
  const classes = useStyles();
  return (
    <FormField open tooltip={tooltip} label={props.label} required={required}>
      <TextField
        {...props}
        multiline={false}
        label={null}
        variant="outlined"
        fullWidth
        className={classes.input}
        inputProps={{ autoComplete: 'off', ...props.inputProps }}
        InputLabelProps={{ shrink: true }}
      />
    </FormField>
  );
};

export default TextInput;
