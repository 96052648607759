import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StateProps = {
  result?: string;
  loanDetails: {
    id?: number;
  };
};
const initialState: StateProps = {
  result: undefined,
  loanDetails: {
    id: undefined,
  },
};
const rootSlice = createSlice({
  name: 'conversionForm',
  initialState,
  reducers: {
    setApplicationId: (state, action: PayloadAction<number>) => {
      state.loanDetails.id = action.payload;
    },
    setSubmitStatus: (state, action: PayloadAction<string | undefined>) => {
      state.result = action.payload;
    },
  },
});

export default rootSlice.reducer;

export const { setApplicationId, setSubmitStatus } = rootSlice.actions;
