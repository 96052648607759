import { Box, Button, Icon, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HomeIcon from '@mui/icons-material/Home';
import ConfirmationDialog from 'lib/ConfirmationDialog/ConfirmationDialog';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BtlRealEstate } from 'types/BtlFormApplication';
import { maxBtlRealEstates } from '../constants';
import { formatAddress } from '../utils';

const form = 'buyToLetForm.realEstates';

const useStyles = makeStyles((theme) => ({
  homeIcon: {
    color: theme.palette.text.secondary,
    fontSize: '20px',
    marginRight: theme.spacing(2),
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
  createButton: {
    marginTop: theme.spacing(2),
    maxWidth: '200px',
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.danger.main,
  },
}));

export type BuyToLetRealEstatesProps = {
  createRealEstate: () => void;
  deleteRealEstate: (index: number) => void;
  setActiveRealEstate: (index: number) => void;
  realEstates: BtlRealEstate[];
};

const BuyToLetRealEstates: FC<BuyToLetRealEstatesProps> = ({
  createRealEstate,
  deleteRealEstate,
  setActiveRealEstate,
  realEstates,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [toBeDeleted, setToBeDeleted] = React.useState(-1);

  return (
    <>
      {realEstates && realEstates.length > 0 && (
        <Box my={4}>
          <Paper>
            {realEstates.map((realEstate, index) => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={3}
                py={2}
                key={`${realEstate.street}${realEstate.buildingPlanNumber}${index}`}
              >
                <Box display="flex">
                  <HomeIcon className={classes.homeIcon} />
                  <Typography>{formatAddress(realEstate) || t(`${form}.newRealEstate`)}</Typography>
                </Box>
                <Box mx={2}>
                  <Button
                    color="primary"
                    endIcon={<Icon className={classes.btnIcon}>pencil</Icon>}
                    variant="text"
                    onClick={() => {
                      setActiveRealEstate(index);
                    }}
                  >
                    {t(`${form}.edit`)}
                  </Button>
                  <Button
                    className={classes.deleteButton}
                    endIcon={<Icon className={classes.btnIcon}>trash</Icon>}
                    variant="text"
                    onClick={() => {
                      setToBeDeleted(index);
                    }}
                  >
                    {t(`${form}.delete`)}
                  </Button>
                </Box>
              </Box>
            ))}
          </Paper>
        </Box>
      )}
      <Button
        variant="contained"
        color="primary"
        className={classes.createButton}
        disabled={realEstates?.length === maxBtlRealEstates}
        onClick={createRealEstate}
        endIcon={<Icon className={classes.btnIcon}>plus</Icon>}
      >
        {t(`${form}.addNew`)}
      </Button>

      <ConfirmationDialog
        dialogTitle={t(`${form}.deleteConfirmation`)}
        confirmButtonText={t(`${form}.delete`)}
        cancelButtonText={t(`${form}.cancel`)}
        open={toBeDeleted >= 0}
        handleClose={() => setToBeDeleted(-1)}
        onConfirm={() => {
          setToBeDeleted(-1);
          deleteRealEstate(toBeDeleted);
        }}
      />
    </>
  );
};

export default BuyToLetRealEstates;
