import {
  ListItem,
  ListItemText,
  Icon,
  Button,
  Grid,
  IconButton,
  FormHelperText,
} from '@mui/material';
import ViewerDialog from 'components/ViewFile/ViewFile';
import { fileSize, IsMobile } from 'utils/global';
import { FC, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { renderCorrectIcon } from '../helpers';
import { FileIcon, FileInput, List, Container, FormRequired } from '../../UploadArea.styled';
import { UploadOptionProps, DocumentDetailsProps } from 'components/UploadArea/types';
import { useFormContext } from 'react-hook-form';

const UploadOption: FC<UploadOptionProps> = ({
  name,
  type,
  label,
  handleUploadSuccess,
  acceptanceFileDetails,
  handleOnChange,
  subtitleText,
  handleDeleteFile,
  required = false,
}) => {
  const { getFieldState, formState } = useFormContext();
  const { error } = getFieldState(name, formState);
  const { t } = useTranslation();
  const [documentDetails, setDocumentDetails] = useState<DocumentDetailsProps | null>();
  const [isViewFileOpen, setViewFileOpen] = useState(false);
  const uploadOptionWrapper = useRef<HTMLDivElement | null>(null);
  const isMobile = IsMobile();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      setDocumentDetails(files[0]);
      handleUploadSuccess(files[0]);
    }

    if (handleOnChange) {
      handleOnChange(event);
    }
  };

  const toggleViewFile = () => {
    setViewFileOpen(!isViewFileOpen);
  };

  const deleteFile = () => {
    handleDeleteFile();
    setDocumentDetails(null);
  };

  return (
    <div ref={uploadOptionWrapper} data-testid="uploadOption-wrapper">
      <List data-testid="upload-list">
        <ListItem disableGutters>
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <FileIcon src={renderCorrectIcon(type)} alt="file-icon" data-testid="file-icon" />
              </Grid>
              <Grid item xs>
                <ListItemText
                  primary={
                    <>
                      {t(label, { type })} {required && <FormRequired>*</FormRequired>}
                    </>
                  }
                  secondary={
                    documentDetails
                      ? `${documentDetails?.name} • ${fileSize(documentDetails?.size)} kB`
                      : t(subtitleText)
                  }
                />
                <FormHelperText data-testid="error-message-input" error={!!error}>
                  {error?.message}
                </FormHelperText>
              </Grid>
              {documentDetails ? (
                <>
                  <Grid item>
                    <Button
                      size="small"
                      color="primary"
                      variant="text"
                      onClick={toggleViewFile}
                      data-testid="uploadOption-viewButton"
                    >
                      {t('applications.upload.uploadSubmit.view')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      data-testid="uploadOption-deleteButton"
                      size="small"
                      variant="text"
                      onClick={deleteFile}
                      endIcon={<Icon>trash</Icon>}
                    >
                      {t('applications.upload.uploadSubmit.delete')}
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid item>
                  <FileInput
                    accept={acceptanceFileDetails.fileType}
                    id={type}
                    type="file"
                    data-testid="uploadOption-file-uploader"
                    onChange={handleChange}
                    // eslint-disable-next-line no-return-assign
                    onClick={(e) => (e.currentTarget.value = '')}
                  />
                  <label htmlFor={type}>
                    {isMobile ? (
                      <IconButton data-testid="upload-button" color="primary" component="span">
                        <Icon>upload</Icon>
                      </IconButton>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        component="span"
                        data-testid="upload-button"
                        endIcon={<Icon>upload</Icon>}
                      >
                        {t('applications.upload.uploadSubmit.upload')}
                      </Button>
                    )}
                  </label>
                </Grid>
              )}
            </Grid>
          </Container>
        </ListItem>
      </List>
      <ViewerDialog file={documentDetails} open={isViewFileOpen} onClose={toggleViewFile} />
    </div>
  );
};

export default UploadOption;
