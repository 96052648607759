import { useTranslation } from 'react-i18next';
import { Container, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import env from 'config/env';

import MutationItem from './MutationItem';
import type { MutationInfo } from './useMutation';
import isEnabledForLabel from 'screens/Mutations/predicates/isEnabledForLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 4, 4, 4),
  },
  heading: {
    'margin': theme.spacing(6, 0),
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
}));

type MutationSelectProps = {
  commercialLabel: string;
  mutations: MutationInfo[];
  selectedMutation: MutationInfo | undefined;
  onClick: (item: MutationInfo) => () => void;
};

const MutationSelect = ({
  commercialLabel,
  mutations,
  selectedMutation,
  onClick,
}: MutationSelectProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getTitle = ({ type, customFormUrl }: MutationInfo) =>
    t(`mutations.type.${customFormUrl ? `CustomFormUrl.${commercialLabel}` : type}`);

  const getDescription = ({ type, customFormUrl }: MutationInfo) =>
    t(`mutations.typeDescription.${customFormUrl ? `CustomFormUrl.${commercialLabel}` : type}`);

  const toggledMutations = mutations.filter((mutation) => {
    switch (mutation.type) {
      case 'CollateralValue':
        return (
          env.FEATURES.STAT_460696_COLLATERAL_VALUE === true &&
          isEnabledForLabel('CollateralValue', commercialLabel)
        );
      case 'DismissalJointLiability':
        return isEnabledForLabel('DismissalJointLiability', commercialLabel);
    }
    return true;
  });

  return (
    <Container className={classes.root}>
      <div className={classes.heading}>
        <Typography variant="h4">{t('mutations.stepTypeSelectTitle')}</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t('mutations.stepTypeSelectSubtitle')}
        </Typography>
      </div>
      <Grid container spacing={4}>
        {toggledMutations.map((mutation) => (
          <Grid item xs={12} md={6} key={mutation.type}>
            <MutationItem
              title={getTitle(mutation)}
              description={getDescription(mutation)}
              isSelected={mutation === selectedMutation}
              onClick={onClick(mutation)}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default MutationSelect;
