import { taskSolutionGenericCommentKey } from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { ProofItem } from 'types/ProofItem';
import { useImmerReducer } from 'use-immer';
import {
  TaskDetails,
  TaskInfoDelegationState,
  TaskSolutionResolutionFileDocumentType,
} from '__generated__/api-di';

interface SolutionBase {
  type: 'comment' | 're-upload' | 'addNew' | 'solvedByOther';
  state?: any;
  id: string;
  data: any;
}

export type Solution = CommentSolution | SolvedByOtherSolution | ReUploadSolution | AddNewSolution;
export interface CommentSolution extends SolutionBase {
  type: 'comment';
  data: {
    comment: string;
  };
}

export interface SolvedByOtherSolution extends SolutionBase {
  type: 'solvedByOther';
  data: {};
}

export interface ReUploadSolution extends SolutionBase {
  type: 're-upload';
  data: {
    action: 'ReUpload';
    proofItem: {
      typeCode: number;
      sequenceNumber: number;
    } | null;
    file: File | null;
    name: string;
    // hyarchis_documentId: string;
  };
  state: ReUploadSolutionState;
}

interface ReUploadSolutionState {
  showProofItemSelect: boolean;
  selectedProofItem: ProofItem | null;
  uploadVisible: boolean;
}

export interface AddNewSolution extends SolutionBase {
  type: 'addNew';
  data: {
    action: 'AddUpload';
    label: TaskSolutionResolutionFileDocumentType | null;
    file: File | null;
    name: string;
    // hyarchis_documentId: string;
  };
  state: AddNewSolutionState;
}

interface AddNewSolutionState {
  showProofItemSelect: boolean;
  selectedProofItem: ProofItem | null;
  uploadVisible: boolean;
}

export type SolutionState = Map<string, Array<Solution>>;

interface Payload {
  issueId: string;
  data: Solution;
}

interface AddPayload extends Payload {}

interface UpdatePayload extends Payload {}

interface RemovePayload extends Payload {}

export type Action =
  | { type: 'add'; payload: AddPayload }
  | { type: 'update'; payload: UpdatePayload }
  | { type: 'remove'; payload: RemovePayload };

export const initialSolutionState: SolutionState = new Map<string, Array<Solution>>();

const addCompletedTaskData = (solutionState: SolutionState, task: TaskDetails) => {
  if (task.delegationState === TaskInfoDelegationState.Completed) {
    solutionState.set(taskSolutionGenericCommentKey, [
      {
        id: uuidv4(),
        data: {
          comment: task.context?.comment ?? '',
        },
        type: 'comment',
      },
    ]);
  }

  return solutionState;
};

export function useSolutionReducer(task: TaskDetails) {
  const combinedState = addCompletedTaskData(initialSolutionState, task);

  return useImmerReducer<SolutionState, Action>((draft, action) => {
    switch (action.type) {
      case 'add': {
        if (!draft.has(action.payload.issueId)) {
          draft.set(action.payload.issueId, []);
        }

        draft.get(action.payload.issueId)?.push(action.payload.data);
        break;
      }
      case 'update': {
        const solutions = draft.get(action.payload.issueId) ?? [];
        const indexOfItemToUpdate = solutions.findIndex((s) => s.id === action.payload.data.id);
        solutions[indexOfItemToUpdate] = action.payload.data;
        break;
      }
      case 'remove': {
        const indexOfItemToRemove = (draft.get(action.payload.issueId) ?? []).findIndex(
          (s) => s.id === action.payload.data.id
        );
        draft.get(action.payload.issueId)?.splice(indexOfItemToRemove, 1);
        break;
      }
      default:
        break;
    }
  }, combinedState);
}
