import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, List, Divider, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useGoogleTagManager } from 'use/gtm';
import { setPartyId } from '../../utils/setPartyId';
import OfficeListItem from '../OfficeListItem/OfficeListItem';
import { useAppDispatch } from 'store';
import { appGroupSelected } from 'store/appReducer';
import { AdvisorOffice } from 'types/AdvisorOffices';
import { useQueryGroups } from 'use/group';

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 36,
    width: 36,
    margin: theme.spacing(0.25, 2, 0.25, 0),
  },
  center: { display: 'flex', justifyContent: 'center' },
  selected: {
    color: theme.palette.primary.main,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 0),
  },
  button: {
    width: '96px',
  },
}));

export type OfficeListProps = {
  onClick: () => void;
  offices: AdvisorOffice[];
  error: string | null;
};

const OfficeList: FC<OfficeListProps> = ({ onClick, offices, error }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { refetch: refetchGroups, isLoading } = useQueryGroups(false);
  const googleTagManager = useGoogleTagManager();

  const handleClick =
    ({ partyId, fullName }: AdvisorOffice) =>
    async () => {
      setPartyId(partyId);
      onClick();

      const { data: groups } = await refetchGroups();

      if (groups && groups.length > 0) {
        dispatch(appGroupSelected(groups[0].name));
      }

      googleTagManager({
        event: 'ButtonClick',
        btnName: 'SelectOffice',
        selectedGroup: fullName,
      });
    };

  if (isLoading)
    return (
      <div className={classes.center}>
        <CircularProgress />
      </div>
    );

  if (error)
    return (
      <div className={classes.wrapper}>
        <Typography>{t(error)}</Typography>
      </div>
    );

  return (
    <List>
      {offices.map((office, index) => (
        <React.Fragment key={`${office.partyId}-${index}`}>
          <Divider />
          <OfficeListItem name={office.fullName} onClick={handleClick(office)} />
        </React.Fragment>
      ))}
    </List>
  );
};

export default OfficeList;
