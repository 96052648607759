import { useNavigate, useLocation } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import { steps, stepsOrder } from '../../constants';
import { goToPreviousStep, goToStep } from 'utils/goToStep/goToStep';
import { getIncompleteStep } from '../FormStepper/utils/stepValidator';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import BottomNavigationMolecule from 'components/Molecules/components/BottomNavigation';

type BottomNavigationProps = {
  isLoadingSubmit?: boolean;
  summaryButtonHandler?: () => void;
  dispatchCurrentForm: () => void;
};

const BottomNavigation: FC<BottomNavigationProps> = ({
  isLoadingSubmit,
  summaryButtonHandler,
  dispatchCurrentForm,
}) => {
  const [hasPreviousStep, setHasPreviousStep] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const state = useSelector((state: RootState) => state.ohaForm);
  const isSummaryDisabled = getIncompleteStep(state) !== steps.summary;
  const currentStep = location.pathname.split('/').pop();

  useEffect(() => {
    if (currentStep) {
      setHasPreviousStep(stepsOrder.indexOf(currentStep) !== 0);
    }
  }, [currentStep]);

  const handleSummary = () => {
    if (summaryButtonHandler) {
      summaryButtonHandler();
    }
    dispatchCurrentForm();
    goToStep(steps.summary, navigate, location.pathname);
  };

  const handleClickBack = () => {
    dispatchCurrentForm();
    goToPreviousStep(navigate, location.pathname, currentStep);
  };

  return (
    <BottomNavigationMolecule
      nextButtonStyleType={currentStep === steps.summary ? 'submit' : 'button'}
      hasPreviousStep={hasPreviousStep}
      isSubmitting={isLoadingSubmit}
      isSummaryDisabled={isSummaryDisabled}
      onPreviousClick={handleClickBack}
      nextButtonType="submit"
      onSummaryClick={handleSummary}
    />
  );
};
export default BottomNavigation;
