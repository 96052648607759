import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Ajv from 'ajv';

import {
  Box,
  Button,
  Dialog,
  Divider,
  Fab,
  FormLabel,
  Hidden,
  Icon,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { Close, Search } from '@mui/icons-material';

import Upload from './UploadDialog';
import ConfirmationDialog from './ConfirmationDialog';
import StepIcon from 'components/StepIcon/StepIcon';
import schema from './buyToLetUploadSchema';
import { BtlUploadApplication } from 'types/BtlUploadApplication';
import { sendBtlApplication } from 'use/newApplication';
import { useGroup } from 'use/group';
import { fileSize, IsMobile } from 'utils/global';
import backgroundImageStater from 'assets/images/half.png';
import pdfIcon from 'assets/images/icons/pdf.svg';
import xlsxIcon from 'assets/images/icons/xlsx.svg';
import nibcLogo from 'assets/images/nibc-icon.png';
import ViewFile from 'components/ViewFile';
import { SlideUpTransition } from 'lib/Transition/transition';
import { useGoogleTagManager } from 'use/gtm';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import ajvFormats from 'ajv-formats';

const ajv = new Ajv({
  coerceTypes: false,
  allErrors: true,
  verbose: true,
});
ajvFormats(ajv);

const iconWidth = 40;
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      display: 'flex',
    },
  },
  header: {
    height: 72,
    padding: theme.spacing(2, 4),
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    boxShadow: '0 6px 12px 0 rgba(22,20,47,0.12)',
    position: 'relative',
    zIndex: 1300,
  },
  logo: {
    height: '32px',
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
  },
  wrapper: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100% - 72px)',
  },
  sidebar: {
    maxWidth: 400,
    height: '100%',
    backgroundColor: theme.palette.purple.dark,
    backgroundImage: `url("${backgroundImageStater}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 70px',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 900,
    margin: '60px 100px',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4),
    },
  },
  stepper: {
    padding: theme.spacing(7.5, 4),
    backgroundColor: 'transparent',
  },
  stepConnector: {
    borderColor: 'transparent',
  },
  stepLabel: {
    'width': 250,
    'height': 40,
    'lineHeight': '40px',
    'padding': theme.spacing(0, 3),
    'borderRadius': theme.shape.borderRadius,
    'color': theme.palette.common.white,
    'fontSize': 14,
    'fontWeight': 500,
    '&.Mui-active': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-completed': {
      color: theme.palette.common.white,
    },
  },
  spacer: {
    flexGrow: 1,
  },
  bottomNav: {
    position: 'fixed',
    bottom: theme.spacing(3),
  },
  fabLeft: {
    marginLeft: theme.spacing(-8),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(0),
    },
  },
  fabRight: {
    right: theme.spacing(3),
  },
  fabIcon: {
    marginLeft: theme.spacing(1),
    transform: 'scale(0.8)',
  },
  paper: {
    'width': '100%',
    'padding': theme.spacing(2, 3.5),
    'borderRadius': '8px',
    '& hr': {
      marginLeft: `calc(${iconWidth}px + ${theme.spacing(2)})`,
      backgroundColor: 'rgba(0,0,0,0.08)',
    },
  },
  fileIcon: {
    width: iconWidth,
    marginRight: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
  multiline: {
    minHeight: 120,
  },
  description: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
  dangerBtn: {
    marginLeft: theme.spacing(1),
    color: theme.palette.danger.main,
  },
  mobileDiv: {
    display: 'flex',
    flexDirection: 'column',
  },
  viewIcon: {
    color: theme.palette.grey[700],
    marginBottom: '6px',
  },
}));

const ApplicantInformation: FC<{
  formData: BtlUploadApplication;
  handleFormData: (e: any) => void;
  errorMessage: (id: string) => React.ReactNode;
}> = ({ formData, handleFormData, errorMessage }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isMobile = IsMobile();
  return (
    <Box mt={5} width={isMobile ? '100%' : '480px'}>
      <Box mb={3}>
        <FormLabel>{t('buyToLet.applicantInformation.applicantName')}</FormLabel>
        <TextField
          id="applicantName"
          required
          fullWidth
          defaultValue={formData.applicantName || ''}
          onBlur={handleFormData}
          onChange={handleFormData}
          error={Boolean(errorMessage('applicantName'))}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <Box>
        <FormLabel>{t('buyToLet.applicantInformation.extraInformation')}</FormLabel>
        <TextField
          id="extraInformation"
          fullWidth
          defaultValue={formData.extraInformation || ''}
          onBlur={handleFormData}
          onChange={handleFormData}
          error={Boolean(errorMessage('extraInformation'))}
          variant="outlined"
          placeholder={t('buyToLet.applicantInformation.enterInformation')}
          className={classes.multiline}
          multiline
          InputLabelProps={{
            shrink: true,
            classes: { root: 'formLabel' },
          }}
          InputProps={{ classes: { input: classes.multiline } }}
        />
      </Box>
    </Box>
  );
};

const UploadOption: FC<{
  formData: BtlUploadApplication;
  type: 'xlsx' | 'pdf';
  handleChange: (e: any) => void;
  viewFile: (type: string) => void;
  deleteFile: (type: string) => void;
}> = ({ formData, type, handleChange, viewFile, deleteFile }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = IsMobile();
  return (
    <div>
      <ListItem disableGutters>
        <img
          className={classes.fileIcon}
          src={type === 'pdf' ? pdfIcon : xlsxIcon}
          alt="file-icon"
        />
        <ListItemText
          primary={t('applications.upload.uploadSubmit.requestForm', { type })}
          secondary={
            formData[type]
              ? `${formData[type]?.name} • ${fileSize(formData[type]?.size)} kB`
              : t('applications.upload.uploadSubmit.toBeUploaded')
          }
        />
        {formData[type] ? (
          <>
            {type === 'pdf' &&
              (isMobile ? (
                <div className={classes.mobileDiv}>
                  <Search className={classes.viewIcon} onClick={() => viewFile(type)} />
                  <Icon
                    className={classes.btnIcon}
                    onClick={() => {
                      deleteFile(type);
                    }}
                  >
                    trash
                  </Icon>
                </div>
              ) : (
                <>
                  <Button
                    size="small"
                    color="primary"
                    variant="text"
                    onClick={() => viewFile(type)}
                  >
                    {t('applications.upload.uploadSubmit.view')}
                  </Button>
                  <Button
                    size="small"
                    variant="text"
                    color="error"
                    onClick={() => {
                      deleteFile(type);
                    }}
                    endIcon={<Icon className={classes.btnIcon}>trash</Icon>}
                  >
                    {t('applications.upload.uploadSubmit.delete')}
                  </Button>
                </>
              ))}
          </>
        ) : (
          <>
            <input
              accept={
                type === 'pdf'
                  ? `application/${type}`
                  : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              }
              className={classes.input}
              id={type}
              type="file"
              onChange={handleChange}
              onClick={(e) => {
                e.currentTarget.value = '';
              }}
            />
            <label htmlFor={type}>
              {isMobile ? (
                <Icon className={classes.btnIcon}>upload</Icon>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  component="span"
                  endIcon={<Icon className={classes.btnIcon}>upload</Icon>}
                >
                  {t('applications.upload.uploadSubmit.upload')}
                </Button>
              )}
            </label>
          </>
        )}
      </ListItem>
    </div>
  );
};

const maxFilesizeMb = (window as any).REACT_APP_UPLOAD_MAX_FILE_SIZE_IN_MB || 100;

const steps = ['applicantInformation', 'uploadSubmit'];

type Props = {
  open: boolean;
  onClose: () => void;
  showSuccess: (emailSent: boolean) => void;
};

const BuyToLetUpload: FC<Props> = ({ open, onClose, showSuccess }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [uploadVisible, setUploadVisible] = React.useState(false);
  const [viewVisible, setViewVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [file, setFile] = React.useState<{ fileType: string; data: File | null }>();
  const [errors, setErrors] = React.useState<any>(['initial error to disable next button']);
  const [alertMessage, setAlertMessage] = React.useState<any>();
  const googleTagManager = useGoogleTagManager();
  const group = useGroup();
  const formData = React.useRef<BtlUploadApplication>({
    applicantName: '',
    extraInformation: null,
    pdf: null,
    xlsx: null,
  });

  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function handleFormData(
    e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>
  ) {
    const { value, id } = e.target;
    formData.current[id as 'applicantName' | 'extraInformation'] = value;
    const valid = ajv.validate(schema, formData.current);
    const errorsArray = Array.isArray(ajv.errors) ? ajv.errors : [];
    if (!valid) {
      setErrors(errorsArray);
    } else {
      setErrors([]);
    }
  }

  function errorMessage(id: string) {
    const thisFieldErrors = errors.filter((error: any) => error.dataPath === `.${id}`);
    if (thisFieldErrors.length > 0) {
      return thisFieldErrors
        .map((error: any) => t(`validations.${error.parentSchema.$comment}`))
        .join(' ');
    }
    return '';
  }

  function handleChange(event: any) {
    const { id, files } = event.target;
    if (files[0].size > 1024 * 1024 * maxFilesizeMb) {
      googleTagManager({
        event: 'upload',
        btnName: 'UploadDocumentBrowse',
        uploadSuccesful: false,
        fileType: files[0].type,
      });
      setAlertMessage('sizeError');
      return;
    }

    if (id === 'pdf') {
      setFile({ fileType: id, data: files[0] });
      setUploadVisible(true);
    } else {
      formData.current.xlsx = files[0];
      setFile({ fileType: id, data: null });
    }

    googleTagManager({
      event: 'upload',
      btnName: 'UploadDocumentBrowse',
      uploadSuccesful: true,
      fileType: files[0].type,
    });
  }

  function viewFile(type: string) {
    if (formData.current[type as 'pdf' | 'xlsx']) {
      setFile({ fileType: type, data: formData.current[type as 'pdf' | 'xlsx'] as File });
      setViewVisible(true);
    }
  }

  function deleteFile(type: string) {
    formData.current[type as 'pdf' | 'xlsx'] = null;
    // setFile to null to trigger rerender after deleting file
    setFile({ fileType: type, data: null });
    setErrors([]);
  }
  function onSubmit() {
    if (formData.current.pdf !== null || formData.current.xlsx !== null) {
      sendApplication();
    } else {
      setShowConfirmation(true);
    }
  }

  const successResponse = (res: any) => {
    setLoading(false);

    showSuccess(res.data);
    reset();
  };

  const errorResponse = (res: any) => {
    setAlertMessage(res);
    setLoading(false);
  };

  function sendApplication() {
    setLoading(true);
    sendBtlApplication(formData.current, successResponse, errorResponse, group as string);
  }

  function reset() {
    formData.current = { applicantName: '', extraInformation: null, pdf: null, xlsx: null };
    setActiveStep(0);
    setErrors(['initial error to disable next button']);
    onClose();
  }

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={SlideUpTransition}
      className={classes.root}
      onClose={reset}
    >
      <div className={classes.header}>
        <img alt="nibc logo" className={classes.logo} src={nibcLogo} />
        <Box>
          <Typography className={classes.title}>{t('buyToLet.header')}</Typography>
          <Typography className={classes.subTitle}>{t('buyToLet.nibc')}</Typography>
        </Box>
        <span className={classes.spacer} />
        <IconButton onClick={reset} size="large">
          <Close />
        </IconButton>
      </div>

      <Box className={classes.wrapper}>
        <Hidden smDown>
          <Box className={classes.sidebar}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              className={classes.stepper}
              connector={
                <StepConnector
                  classes={{
                    line: classes.stepConnector,
                  }}
                />
              }
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel
                    classes={{
                      label: classes.stepLabel,
                    }}
                    StepIconComponent={StepIcon}
                  >
                    {t(`buyToLet.${label}.title`)}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Hidden>

        <Box className={classes.content}>
          <Typography variant="h4">{t(`buyToLet.${steps[activeStep]}.title`)}</Typography>
          <Typography className={classes.description} variant="body1">
            {t(`buyToLet.${steps[activeStep]}.description`)}
          </Typography>

          {activeStep === 0 ? (
            <ApplicantInformation
              formData={formData.current}
              handleFormData={handleFormData}
              errorMessage={errorMessage}
            />
          ) : (
            <>
              <Box my={6}>
                <Paper className={classes.paper}>
                  <UploadOption
                    handleChange={handleChange}
                    viewFile={viewFile}
                    deleteFile={deleteFile}
                    formData={formData.current}
                    type="xlsx"
                  />
                  <Divider />
                  <UploadOption
                    handleChange={handleChange}
                    viewFile={viewFile}
                    deleteFile={deleteFile}
                    formData={formData.current}
                    type="pdf"
                  />
                </Paper>
              </Box>
              <Typography className={classes.description} variant="body1">
                {t('applications.upload.uploadSubmit.submitDescription')}
              </Typography>
            </>
          )}

          {activeStep !== 0 && (
            <Fab
              size="medium"
              color="secondary"
              aria-label="previous"
              disabled={activeStep === 0}
              onClick={handleBack}
              className={`${classes.bottomNav} ${classes.fabLeft}`}
            >
              <Icon>arrow_left</Icon>
            </Fab>
          )}
          {activeStep === 0 ? (
            <Fab
              color="primary"
              variant="extended"
              size="medium"
              disabled={errors.length > 0}
              onClick={handleNext}
              className={`${classes.bottomNav} ${classes.fabRight}`}
            >
              {t('buyToLet.nextSection')}
              <Icon className={classes.fabIcon}>arrow_right</Icon>
            </Fab>
          ) : (
            <Fab
              variant="extended"
              color="primary"
              size="medium"
              disabled={errors.length > 1 || loading}
              onClick={onSubmit}
              className={`${classes.bottomNav} ${classes.fabRight}`}
            >
              {t('buyToLet.submit') as string}
              <Icon className={classes.fabIcon}>arrow_right</Icon>
            </Fab>
          )}
        </Box>
      </Box>
      <Upload
        fileInfo={{ name: 'Test' }}
        open={uploadVisible}
        file={file?.data}
        onSuccess={() => {
          if (file) {
            formData.current[file?.fileType as 'pdf' | 'xlsx'] = file.data;
            setUploadVisible(false);
          }
        }}
        onCancel={() => {
          setFile(undefined);
          setUploadVisible(false);
        }}
      />

      <ViewFile file={file?.data} open={viewVisible} onClose={() => setViewVisible(false)} />
      <ConfirmationDialog
        open={showConfirmation}
        handleClose={() => setShowConfirmation(false)}
        onConfirm={() => {
          setShowConfirmation(false);
          sendApplication();
        }}
      />
      <CustomSnackbar
        isOpen={Boolean(alertMessage)}
        handleClose={() => setAlertMessage(null)}
        severity="error"
        message={t(`buyToLet.${alertMessage}`, { maxFilesizeMb })}
      />
    </Dialog>
  );
};

export default BuyToLetUpload;
