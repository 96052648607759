import React, { FC, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Icon, TextField, Typography } from '@mui/material';
import CurrencyField from 'lib/CurrencyField/CurrencyField';
import { BtlRealEstate } from 'types/BtlFormApplication';
import {
  TypeOfRealEstate,
  TypeOfLeasehold,
  TypeOfCollateral,
  EnergyClass,
} from '__generated__/api';
import ToggleButtonQuestion from 'screens/BuyToLet/BuyToLetForm/form/ToggleButtonQuestion';
import DropDownQuestion from 'screens/BuyToLet/BuyToLetForm/form/DropDownQuestion';
import TextInput from 'screens/BuyToLet/BuyToLetForm/form/TextInput';
import { allowedEnergyClasses, allowedTypesOfRealEstate } from '../../enums/realEstateEnums';
import IntegerNumberField from 'lib/IntegerNumberField/IntegerNumberField';
import { ButToLetDecimalAmountEnabled } from 'config/constants';
import { DatePicker } from '@mui/x-date-pickers';
import { isValid, format } from 'date-fns';
import { FormField } from 'screens/BuyToLet/BuyToLetForm/form/FormField';

const useStyles = makeStyles((theme) => ({
  formGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    maxWidth: 600,
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
  createButton: {
    marginTop: theme.spacing(2),
    maxWidth: '200px',
  },
  description: {
    marginTop: theme.spacing(2),
    color: theme.palette.textBody.main,
  },
  smallInputRight: {
    '& .MuiOutlinedInput-root, fieldset': {
      borderRadius: '4px 0 0 4px',
    },
  },
  smallInputLeft: {
    '& .MuiOutlinedInput-root, fieldset': {
      borderRadius: '0 4px 4px 0',
      left: '-1px',
    },
  },
}));

export type BuyToLetNewRealEstateProps = {
  realEstate: BtlRealEstate;
  updateRealEstate: (data: BtlRealEstate) => void;
  showAddRealEstateButton: boolean;
  addRealEstate: () => void;
  errorMessage: (id: string, category: string) => React.ReactNode;
};

const BuyToLetNewRealEstate: FC<BuyToLetNewRealEstateProps> = ({
  realEstate,
  updateRealEstate,
  showAddRealEstateButton,
  addRealEstate,
  errorMessage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const form = 'buyToLetForm.newRealEstate';
  const category = 'realEstate';
  const [purchaseDate, setPurchaseDate] = useState<Date | string>('');
  const [deliveryDate, setDeliveryDate] = useState<Date | string>('');

  function handleChange(updatedRealEstate: BtlRealEstate): void {
    updateRealEstate(updatedRealEstate);
  }

  function handleTypeOfCollateralChange(updatedRealEstate: BtlRealEstate): void {
    if (updatedRealEstate.typeOfCollateral === TypeOfCollateral.Existing) {
      handleChange({
        ...updatedRealEstate,
        deliveryDate: undefined,
        buildingPlanNumber: undefined,
        situationNumber: undefined,
        isGroundInPossession: undefined,
        purchasePriceGround: undefined,
        additionalWorkAmount: undefined,
        purchaseAmount: undefined,
        isNewlyBuildAddressKnown: 'yes',
        isBeingRenovated: false,
        isRenovationDepotNecessary: false,
        renovationDepotAmount: undefined,
      });
    } else if (updatedRealEstate.typeOfCollateral === TypeOfCollateral.NewlyBuild) {
      handleChange({
        ...updatedRealEstate,
        purchaseDate: undefined,
        purchaseAmount: undefined,
        isBeingRenovated: false,
        renovationCosts: undefined,
        isRenovationDepotNecessary: undefined,
        renovationDepotAmount: undefined,
        marketValueAfterRenovation: undefined,
        rentalValueAfterRenovation: undefined,
        isNewlyBuildAddressKnown: 'yes',
      });
    }
  }

  function handleIsNewlyBuildAddressKnown(updatedRealEstate: BtlRealEstate): void {
    if (updatedRealEstate.isNewlyBuildAddressKnown === 'yes') {
      handleChange({
        ...updatedRealEstate,
        buildingPlanNumber: undefined,
        situationNumber: undefined,
      });
    }
    if (updatedRealEstate.isNewlyBuildAddressKnown === 'no') {
      handleChange({
        ...updatedRealEstate,
        street: undefined,
        houseNumber: undefined,
        houseNumberExtension: undefined,
        postalCode: undefined,
      });
    }
  }

  function handleIsBeingRenovatedChange(updatedRealEstate: BtlRealEstate): void {
    if (!updatedRealEstate.isBeingRenovated) {
      handleChange({
        ...updatedRealEstate,
        marketValueAfterRenovation: undefined,
        rentalValueAfterRenovation: undefined,
        renovationCosts: undefined,
        renovationDepotAmount: undefined,
        isRenovationDepotNecessary: undefined,
      });
    } else {
      handleChange({
        ...updatedRealEstate,
      });
    }
  }

  function handleIsRenovationDepotNecessaryChange(updatedRealEstate: BtlRealEstate): void {
    if (!updatedRealEstate.isRenovationDepotNecessary) {
      handleChange({
        ...updatedRealEstate,
        renovationDepotAmount: undefined,
      });
    } else {
      handleChange({
        ...updatedRealEstate,
      });
    }
  }

  function handleHasLeaseholdChange(updatedRealEstate: BtlRealEstate): void {
    if (!updatedRealEstate.hasLeasehold) {
      handleChange({
        ...updatedRealEstate,
        typeOfLeasehold: undefined,
        leaseholdAmount: undefined,
      });
    } else {
      handleChange({
        ...updatedRealEstate,
      });
    }
  }

  function handleIsGroundInPossessionChange(updatedRealEstate: BtlRealEstate): void {
    if (!updatedRealEstate.isGroundInPossession) {
      handleChange({
        ...updatedRealEstate,
        purchasePriceGround: undefined,
      });
    } else {
      handleChange({
        ...updatedRealEstate,
      });
    }
  }

  const handleDateChange = (e: any, field: string) => {
    if (!e) {
      field === 'purchaseDate' ? setPurchaseDate('') : setDeliveryDate('');
      handleChange({
        ...realEstate,
        [field]: null,
      });
    }
    if (isValid(e)) {
      const formatedDate = format(e as any, 'yyyy-MM-dd');
      field === 'purchaseDate' ? setPurchaseDate(e as any) : setDeliveryDate(e as any);
      handleChange({
        ...realEstate,
        [field]: formatedDate,
      });
    }
  };

  useEffect(() => {
    if (realEstate.purchaseDate) {
      const formatedPurchaseDate = new Date(realEstate.purchaseDate);
      isValid(formatedPurchaseDate) && setPurchaseDate(formatedPurchaseDate);
    } else {
      setPurchaseDate('');
    }
    if (realEstate.deliveryDate) {
      const formatedDateDeliveryDate = new Date(realEstate.deliveryDate);
      isValid(formatedDateDeliveryDate) && setDeliveryDate(formatedDateDeliveryDate);
    } else {
      setDeliveryDate('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form>
      <Typography className={classes.description} variant="body1">
        {t(`${form}.description`)}
      </Typography>
      <Grid container spacing={3} className={classes.formGrid}>
        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <ToggleButtonQuestion
              key="typeOfCollateral"
              label={t(`${form}.typeOfCollateral`)}
              answerOptions={[
                {
                  id: TypeOfCollateral.Existing,
                  answerTextKey: `${form}.existing`,
                },
                {
                  id: TypeOfCollateral.NewlyBuild,
                  answerTextKey: `${form}.newlyBuild`,
                },
              ]}
              containerWidth={12}
              exclusive
              required
              value={realEstate.typeOfCollateral ?? undefined}
              onChange={(e, value: TypeOfCollateral) =>
                handleTypeOfCollateralChange({ ...realEstate, typeOfCollateral: value })
              }
              error={Boolean(errorMessage('.typeOfCollateral', category))}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">{t(`${form}.collateralInfo`)}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DropDownQuestion
              label={t(`${form}.typeOfRealEstate`)}
              placeholder={t(`${form}.typeOfRealEstate`)}
              value={realEstate.typeOfRealEstate ?? ''}
              answerOptions={allowedTypesOfRealEstate.map((typeOfRealEstate) => ({
                id: typeOfRealEstate,
                answerText: t(`${form}.realEstateTypes.${typeOfRealEstate}`),
              }))}
              required
              onChange={(e) =>
                handleChange({
                  ...realEstate,
                  typeOfRealEstate: e.target.value as TypeOfRealEstate,
                })
              }
              error={Boolean(errorMessage('.typeOfRealEstate', category))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DropDownQuestion
              label={t(`${form}.energyClass`)}
              value={realEstate.energyClass ?? ''}
              placeholder={t(`${form}.energyClassPlaceholder`)}
              nullable
              answerOptions={allowedEnergyClasses.map((energyClass) => ({
                id: energyClass,
                answerText: t(`${form}.energyClasses.${energyClass}`),
              }))}
              onChange={(e) =>
                handleChange({
                  ...realEstate,
                  energyClass: e.target.value as EnergyClass | undefined,
                })
              }
            />
          </Grid>
          {realEstate.typeOfCollateral === TypeOfCollateral.NewlyBuild && (
            <Grid item xs={12} sm={12}>
              <ToggleButtonQuestion
                key="isNewlyBuildAddressKnown"
                label={t(`${form}.isNewlyBuildAddressKnown`)}
                placeholder={t(`${form}.isNewlyBuildAddressKnown`)}
                value={realEstate.isNewlyBuildAddressKnown ?? 'yes'}
                answerOptions={[
                  {
                    id: 'yes',
                    answerTextKey: `${form}.yes`,
                  },
                  {
                    id: 'no',
                    answerTextKey: `${form}.no`,
                  },
                ]}
                required
                exclusive
                containerWidth={6}
                onChange={(e: any, value: 'yes' | 'no') =>
                  handleIsNewlyBuildAddressKnown({
                    ...realEstate,
                    isNewlyBuildAddressKnown: value,
                  })
                }
                error={Boolean(errorMessage('.isNewlyBuildAddressKnown', category))}
              />
            </Grid>
          )}
          {((realEstate.typeOfCollateral === TypeOfCollateral.NewlyBuild &&
            realEstate.isNewlyBuildAddressKnown === 'yes') ||
            realEstate.typeOfCollateral === TypeOfCollateral.Existing) && (
            <>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label={t(`${form}.street`)}
                  name="street"
                  placeholder={t(`${form}.street`)}
                  required
                  inputProps={{ maxLength: 100 }}
                  value={realEstate.street ?? ''}
                  onChange={(e) => handleChange({ ...realEstate, street: e.target.value })}
                  error={Boolean(errorMessage('.street', category))}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <IntegerNumberField
                  label={t(`${form}.houseNumber`)}
                  name="houseNumber"
                  placeholder={t(`${form}.houseNumber`)}
                  required
                  max={99999}
                  textAlign="left"
                  containerWidth={12}
                  value={realEstate.houseNumber || undefined}
                  onChange={(e) =>
                    handleChange({
                      ...realEstate,
                      houseNumber: Number(e.target.value) || undefined,
                    })
                  }
                  error={Boolean(errorMessage('.houseNumber', category))}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextInput
                  label={t(`${form}.houseNumberExtension`)}
                  name="houseNumberExtension"
                  placeholder={t(`${form}.houseNumberExtension`)}
                  inputProps={{ maxLength: 10 }}
                  value={realEstate.houseNumberExtension ?? ''}
                  onChange={(e) =>
                    handleChange({ ...realEstate, houseNumberExtension: e.target.value })
                  }
                  error={Boolean(errorMessage('.houseNumberExtension', category))}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label={t(`${form}.postalCode`)}
                  name="postalCode"
                  placeholder={t(`${form}.postalCode`)}
                  required
                  inputProps={{ maxLength: 6, pattern: '[1-9][0-9]{3}(?!sa|sd|ss)[A-Z]{2}$' }}
                  value={realEstate.postalCode ?? ''}
                  onChange={(e) => {
                    handleChange({
                      ...realEstate,
                      postalCode: e.target.value.toUpperCase().replace('-', '').replace(' ', ''),
                    });
                  }}
                  error={Boolean(errorMessage('.postalCode', category))}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <TextInput
              label={t(`${form}.city`)}
              name="city"
              inputProps={{ maxLength: 35 }}
              placeholder={t(`${form}.city`)}
              required
              value={realEstate.city ?? ''}
              onChange={(e) => handleChange({ ...realEstate, city: e.target.value })}
              error={Boolean(errorMessage('.city', category))}
            />
          </Grid>

          {realEstate.typeOfCollateral === TypeOfCollateral.NewlyBuild &&
            realEstate.isNewlyBuildAddressKnown === 'no' && (
              <Grid item container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    label={t(`${form}.buildingPlanNumber`)}
                    inputProps={{ maxLength: 35 }}
                    name="buildingPlanNumber"
                    required
                    placeholder={t(`${form}.buildingPlanNumber`)}
                    value={realEstate.buildingPlanNumber ?? ''}
                    onChange={(e) =>
                      handleChange({
                        ...realEstate,
                        buildingPlanNumber: e.target.value === '' ? null : e.target.value,
                      })
                    }
                    error={Boolean(errorMessage('.buildingPlanNumber', category))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    label={t(`${form}.situationNumber`)}
                    inputProps={{ maxLength: 35 }}
                    required
                    name="situationNumber"
                    placeholder={t(`${form}.situationNumber`)}
                    value={realEstate.situationNumber ?? ''}
                    onChange={(e) =>
                      handleChange({
                        ...realEstate,
                        situationNumber: e.target.value === '' ? null : e.target.value,
                      })
                    }
                    error={Boolean(errorMessage('.situationNumber', category))}
                  />
                </Grid>
              </Grid>
            )}
        </Grid>

        {realEstate.typeOfCollateral === TypeOfCollateral.NewlyBuild && (
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">{t(`${form}.newlyBuild`)}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField label={t(`${form}.deliveryDate`)} required open>
                <DatePicker
                  inputFormat="dd-MM-yyyy"
                  value={deliveryDate}
                  onChange={(e) => {
                    handleDateChange(e, 'deliveryDate');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="deliveryDate"
                      required
                      error={Boolean(errorMessage('.deliveryDate', category))}
                      placeholder="dd-mm-jjjj"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </FormField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CurrencyField
                label={t(`${form}.purchaseAmount`)}
                name="purchaseAmount"
                required={Boolean(realEstate.typeOfCollateral === TypeOfCollateral.NewlyBuild)}
                variant="outlined"
                placeholder={t(`${form}.purchaseAmount`)}
                fullWidth
                containerWidth={12}
                value={realEstate.purchaseAmount ?? undefined}
                onChange={(v) => handleChange({ ...realEstate, purchaseAmount: v })}
                error={Boolean(errorMessage('.purchaseAmount', category))}
                allowDecimals={ButToLetDecimalAmountEnabled}
              />
            </Grid>
            <Grid container item spacing={3}>
              <Grid item xs={12} sm={6}>
                <ToggleButtonQuestion
                  key="isGroundInPossession"
                  label={t(`${form}.isGroundInPossession`)}
                  answerOptions={[
                    {
                      id: 'yes',
                      value: true,
                      answerTextKey: `${form}.yes`,
                    },
                    {
                      id: 'no',
                      value: false,
                      answerTextKey: `${form}.no`,
                    },
                  ]}
                  containerWidth={12}
                  exclusive
                  required={realEstate.typeOfCollateral === TypeOfCollateral.NewlyBuild}
                  value={realEstate.isGroundInPossession ?? undefined}
                  onChange={(e, value: boolean) =>
                    handleIsGroundInPossessionChange({ ...realEstate, isGroundInPossession: value })
                  }
                  error={Boolean(errorMessage('.isGroundInPossession', category))}
                />
              </Grid>
              {realEstate.isGroundInPossession && (
                <Grid item xs={12} sm={6}>
                  <CurrencyField
                    label={t(`${form}.purchasePriceGround`)}
                    name="purchasePriceGround"
                    required={Boolean(realEstate.isGroundInPossession)}
                    variant="outlined"
                    placeholder={t(`${form}.purchasePriceGround`)}
                    fullWidth
                    containerWidth={12}
                    value={realEstate.purchasePriceGround ?? undefined}
                    onChange={(v) => handleChange({ ...realEstate, purchasePriceGround: v })}
                    error={Boolean(errorMessage('.purchasePriceGround', category))}
                    allowDecimals={ButToLetDecimalAmountEnabled}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <CurrencyField
                label={t(`${form}.additionalWorkAmount`)}
                name="additionalWorkAmount"
                defaultValue={realEstate.additionalWorkAmount}
                variant="outlined"
                placeholder={t(`${form}.additionalWorkAmount`)}
                fullWidth
                containerWidth={12}
                value={realEstate.additionalWorkAmount ?? undefined}
                onChange={(v) => handleChange({ ...realEstate, additionalWorkAmount: v })}
                error={Boolean(errorMessage('.additionalWorkAmount', category))}
                allowDecimals={ButToLetDecimalAmountEnabled}
              />
            </Grid>
          </Grid>
        )}
        {realEstate.typeOfCollateral === TypeOfCollateral.Existing && (
          <>
            <Grid container item spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">{t(`${form}.purchaseProperty`)}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6}>
              <FormField label={t(`${form}.purchaseDate`)} required open>
                <DatePicker
                  inputFormat="dd-MM-yyyy"
                  value={purchaseDate}
                  onChange={(e) => {
                    handleDateChange(e, 'purchaseDate');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="purchaseDate"
                      error={Boolean(errorMessage('.purchaseDate', category))}
                      placeholder="dd-mm-jjjj"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </FormField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <CurrencyField
                label={t(`${form}.purchaseAmount`)}
                name="purchaseAmount"
                required={Boolean(realEstate.typeOfCollateral === TypeOfCollateral.Existing)}
                variant="outlined"
                placeholder={t(`${form}.purchaseAmount`)}
                fullWidth
                containerWidth={12}
                value={realEstate.purchaseAmount ?? undefined}
                onChange={(v) => handleChange({ ...realEstate, purchaseAmount: v })}
                error={Boolean(errorMessage('.purchaseAmount', category))}
                allowDecimals={ButToLetDecimalAmountEnabled}
              />
            </Grid>
          </>
        )}

        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">{t(`${form}.leasehold`)}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ToggleButtonQuestion
              key="hasLeasehold"
              label={t(`${form}.hasLeasehold`)}
              answerOptions={[
                {
                  id: 'yes',
                  value: true,
                  answerTextKey: `${form}.yes`,
                },
                {
                  id: 'no',
                  value: false,
                  answerTextKey: `${form}.no`,
                },
              ]}
              containerWidth={12}
              exclusive
              required
              value={realEstate.hasLeasehold ?? undefined}
              onChange={(e, value: boolean) =>
                handleHasLeaseholdChange({ ...realEstate, hasLeasehold: value })
              }
              error={Boolean(errorMessage('.hasLeasehold', category))}
            />
          </Grid>
          {realEstate.hasLeasehold && (
            <>
              <Grid item xs={12} sm={6}>
                <DropDownQuestion
                  label={t(`${form}.typeOfLeasehold`)}
                  placeholder={t(`${form}.typeOfLeasehold`)}
                  answerOptions={Object.values(TypeOfLeasehold).map((typeOfLeasehold) => ({
                    id: typeOfLeasehold,
                    answerText: t(`${form}.leaseholdTypes.${typeOfLeasehold}`),
                  }))}
                  required={Boolean(realEstate.hasLeasehold)}
                  value={realEstate.typeOfLeasehold ?? ''}
                  onChange={(e) =>
                    handleChange({
                      ...realEstate,
                      typeOfLeasehold: e.target.value as TypeOfLeasehold,
                    })
                  }
                  error={Boolean(errorMessage('.typeOfLeasehold', category))}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CurrencyField
                  label={
                    <>
                      {t(`${form}.leaseholdAmount`)}
                      &nbsp;
                      {t(`${form}.leaseholdAmountPerYear`)}
                    </>
                  }
                  name="leaseholdAmount"
                  variant="outlined"
                  placeholder={t(`${form}.leaseholdAmount`)}
                  fullWidth
                  containerWidth={12}
                  value={realEstate.leaseholdAmount ?? undefined}
                  onChange={(v) => handleChange({ ...realEstate, leaseholdAmount: v })}
                  error={Boolean(errorMessage('.leaseholdAmount', category))}
                  allowDecimals={ButToLetDecimalAmountEnabled}
                />
              </Grid>
            </>
          )}
        </Grid>

        {realEstate.typeOfCollateral === TypeOfCollateral.Existing && (
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">{t(`${form}.renovationInformation`)}</Typography>
            </Grid>
            <Grid item container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ToggleButtonQuestion
                  key="isBeingRenovated"
                  label={t(`${form}.isBeingRenovated`)}
                  answerOptions={[
                    {
                      id: 'yes',
                      value: true,
                      answerTextKey: `${form}.yes`,
                    },
                    {
                      id: 'no',
                      value: false,
                      answerTextKey: `${form}.no`,
                    },
                  ]}
                  containerWidth={12}
                  exclusive
                  value={realEstate.isBeingRenovated ?? undefined}
                  onChange={(e, value: boolean) =>
                    handleIsBeingRenovatedChange({ ...realEstate, isBeingRenovated: value })
                  }
                />
              </Grid>
              {realEstate.isBeingRenovated && (
                <Grid item xs={12} sm={6}>
                  <CurrencyField
                    label={t(`${form}.renovationCosts`)}
                    name="renovationCosts"
                    required={Boolean(realEstate.isBeingRenovated)}
                    defaultValue={realEstate.renovationCosts}
                    variant="outlined"
                    placeholder={t(`${form}.renovationCosts`)}
                    fullWidth
                    containerWidth={12}
                    value={realEstate.renovationCosts ?? undefined}
                    onChange={(v) => handleChange({ ...realEstate, renovationCosts: v })}
                    error={Boolean(errorMessage('.renovationCosts', category))}
                    allowDecimals={ButToLetDecimalAmountEnabled}
                  />
                </Grid>
              )}
            </Grid>
            {realEstate.isBeingRenovated && (
              <Grid item container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <ToggleButtonQuestion
                    key="isRenovationDepotNecessary"
                    label={t(`${form}.isRenovationDepotNecessary`)}
                    answerOptions={[
                      {
                        id: 'yes',
                        value: true,
                        answerTextKey: `${form}.yes`,
                      },
                      {
                        id: 'no',
                        value: false,
                        answerTextKey: `${form}.no`,
                      },
                    ]}
                    containerWidth={12}
                    exclusive
                    required
                    value={realEstate.isRenovationDepotNecessary ?? undefined}
                    onChange={(e, value: boolean) =>
                      handleIsRenovationDepotNecessaryChange({
                        ...realEstate,
                        isRenovationDepotNecessary: value,
                      })
                    }
                    error={Boolean(errorMessage('.isRenovationDepotNecessary', category))}
                  />
                </Grid>
                {realEstate.isRenovationDepotNecessary && (
                  <Grid item xs={12} sm={6}>
                    <CurrencyField
                      label={t(`${form}.renovationDepotAmount`)}
                      name="renovationDepotAmount"
                      required={Boolean(realEstate.isRenovationDepotNecessary)}
                      defaultValue={realEstate.renovationDepotAmount}
                      variant="outlined"
                      placeholder={t(`${form}.renovationDepotAmount`)}
                      fullWidth
                      containerWidth={12}
                      value={realEstate.renovationDepotAmount ?? undefined}
                      onChange={(v) => handleChange({ ...realEstate, renovationDepotAmount: v })}
                      error={Boolean(errorMessage('.renovationDepotAmount', category))}
                      allowDecimals={ButToLetDecimalAmountEnabled}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}

        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">{t(`${form}.rentalInformation`)}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextInput
              label={t(`${form}.nameOfTenant`)}
              inputProps={{ maxLength: 100 }}
              name="nameOfTenant"
              placeholder={t(`${form}.nameOfTenant`)}
              value={realEstate.nameOfTenant ?? ''}
              onChange={(e) => handleChange({ ...realEstate, nameOfTenant: e.target.value })}
              error={Boolean(errorMessage('.nameOfTenant', category))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CurrencyField
              label={t(`${form}.basicAnnualRentalIncome`)}
              name="basicAnnualRentalIncome"
              variant="outlined"
              placeholder={t(`${form}.basicAnnualRentalIncome`)}
              fullWidth
              containerWidth={12}
              value={realEstate.basicAnnualRentalIncome ?? undefined}
              onChange={(v) => handleChange({ ...realEstate, basicAnnualRentalIncome: v })}
              error={Boolean(errorMessage('.basicAnnualRentalIncome', category))}
              allowDecimals={ButToLetDecimalAmountEnabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CurrencyField
              label={t(`${form}.rentalValue`)}
              name="rentalValue"
              required
              variant="outlined"
              placeholder={t(`${form}.rentalValue`)}
              fullWidth
              containerWidth={12}
              value={realEstate.rentalValue ?? undefined}
              onChange={(v) => handleChange({ ...realEstate, rentalValue: v })}
              error={Boolean(errorMessage('.rentalValue', category))}
              allowDecimals={ButToLetDecimalAmountEnabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CurrencyField
              label={t(`${form}.marketValue`)}
              name="marketValue"
              required
              variant="outlined"
              placeholder={t(`${form}.marketValue`)}
              fullWidth
              containerWidth={12}
              value={realEstate.marketValue ?? undefined}
              onChange={(v) => handleChange({ ...realEstate, marketValue: v })}
              error={Boolean(errorMessage('.marketValue', category))}
              allowDecimals={ButToLetDecimalAmountEnabled}
            />
          </Grid>
          {realEstate.isBeingRenovated && (
            <>
              <Grid item xs={12} sm={6}>
                <CurrencyField
                  label={t(`${form}.rentalValueAfterRenovation`)}
                  name="rentalValueAfterRenovation"
                  required={Boolean(realEstate.isBeingRenovated)}
                  variant="outlined"
                  placeholder={t(`${form}.rentalValueAfterRenovation`)}
                  fullWidth
                  containerWidth={12}
                  value={realEstate.rentalValueAfterRenovation ?? undefined}
                  onChange={(v) => handleChange({ ...realEstate, rentalValueAfterRenovation: v })}
                  error={Boolean(errorMessage('.rentalValueAfterRenovation', category))}
                  allowDecimals={ButToLetDecimalAmountEnabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CurrencyField
                  label={t(`${form}.marketValueAfterRenovation`)}
                  name="marketValueAfterRenovation"
                  required={Boolean(realEstate.isBeingRenovated)}
                  variant="outlined"
                  placeholder={t(`${form}.marketValueAfterRenovation`)}
                  fullWidth
                  containerWidth={12}
                  value={realEstate.marketValueAfterRenovation ?? undefined}
                  onChange={(v) => handleChange({ ...realEstate, marketValueAfterRenovation: v })}
                  error={Boolean(errorMessage('.marketValueAfterRenovation', category))}
                  allowDecimals={ButToLetDecimalAmountEnabled}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <ToggleButtonQuestion
              key="isRoomRental"
              label={t(`${form}.isRoomRental`)}
              answerOptions={[
                {
                  id: 'yes',
                  value: true,
                  answerTextKey: `${form}.yes`,
                },
                {
                  id: 'no',
                  value: false,
                  answerTextKey: `${form}.no`,
                },
              ]}
              containerWidth={12}
              exclusive
              required
              value={realEstate.isRoomRental ?? undefined}
              onChange={(e, value: boolean) => handleChange({ ...realEstate, isRoomRental: value })}
              error={Boolean(errorMessage('.isRoomRental', category))}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          className={classes.createButton}
          disabled={!showAddRealEstateButton}
          onClick={addRealEstate}
          endIcon={<Icon className={classes.btnIcon}>plus</Icon>}
        >
          {t(`${form}.addNew`)}
        </Button>
      </Grid>
    </form>
  );
};
export default BuyToLetNewRealEstate;
