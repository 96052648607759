import type { FC } from 'react';
import { IconButton, Dialog, Typography, Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import { SlideUpTransition } from '../../lib/Transition/transition';

import { useStyles } from './BaseDialog.style';

export type BaseDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  buttonGroup: JSX.Element;
  bodyText: string;
};

const BaseDialog: FC<BaseDialogProps> = ({ isOpen, onClose, buttonGroup, bodyText }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={SlideUpTransition}
      onClose={onClose}
      data-testid="base-dialog"
    >
      <Box className={classes.content}>
        <div className={classes.title}>
          <Typography variant="h6">{bodyText}</Typography>
          <span className={classes.spacer} />
          <IconButton onClick={onClose} className={classes.close} size="large">
            <Close />
          </IconButton>
        </div>
        {buttonGroup}
      </Box>
    </Dialog>
  );
};

export default BaseDialog;
