import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AccordionSummary, Divider, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { yesNoGroupOptions } from '../../../../constants';
import { FormInputDropdown, FormToggleButtonGroup, FormInputNumber } from 'components/Form';
import { Option } from '../../../../../../utils/types';
import AccordionLoanPartHeader from '../AccordionLoanPartHeader';
import { Accordion, AccordionDetails, FormContainer } from '../../LoanPart.styled';

type ConvertLoanProps = {
  loanPartIndex: number;
  productOptions: Option[];
  expanded: boolean;
};

export const ConvertLoan: FC<ConvertLoanProps> = ({ loanPartIndex, productOptions, expanded }) => {
  const rootFormField = `loanParts.${loanPartIndex}.convertLoan`;
  const { watch } = useFormContext();
  const { t } = useTranslation();

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        aria-controls={`panel${loanPartIndex}-converLoan`}
        id={`panel${loanPartIndex}-converLoan`}
      >
        <AccordionLoanPartHeader
          loanPartNumber={t('directMutations.form.convertedPart')}
          oldOrNew={t('directMutations.form.new')}
          loanAmount={watch(`loanParts[${loanPartIndex}].loanAmount`)}
          repaymentType={watch(`${rootFormField}.repaymentType`)}
          fixedRatePeriod={watch(`${rootFormField}.fixedRatePeriod`)}
        />
      </AccordionSummary>
      <Divider />

      <AccordionDetails>
        <FormContainer container spacing={2}>
          <Grid item xs={6}>
            <FormInputDropdown
              name={`${rootFormField}.repaymentType`}
              label={t('directMutations.form.newRepaymentType')}
              options={productOptions}
              placeholder={t('directMutations.loanParts.repaymentTypePlaceholder')}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormInputNumber
              name={`${rootFormField}.fixedRatePeriod`}
              label={t('directMutations.form.newFixedInterestPeriod')}
              placeholder={t('directMutations.form.placeholderRvp')}
              min={0}
              max={999}
            />
          </Grid>

          <Grid item xs={6}>
            <FormToggleButtonGroup
              name={`${rootFormField}.NHGGuarantee`}
              label={t('directMutations.loanParts.NHGGuarantee')}
              options={yesNoGroupOptions}
              required
            />
          </Grid>
        </FormContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default ConvertLoan;
