import { ApplicationData } from '__generated__/api';
import { http, HEADER_SELECTED_GROUP_CLAIM } from '../http';

export async function getConceptsData(
  group: string | null,
  commercialRelation: string | null
): Promise<ApplicationData[]> {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const response = await http.get(
    `/api/applicationdata/concepts/commercialrelation/${commercialRelation}`,
    { headers }
  );
  return response.data;
}
