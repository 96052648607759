import { http } from '../http';
import { UserNotification } from '__generated__/api';
import { AxiosResponse } from 'axios';

function sortUserNotifications({ data: userNotifications }: AxiosResponse<UserNotification[]>) {
  return userNotifications.sort((a, b) =>
    a.priority &&
    b.priority &&
    a.priority.priority &&
    b.priority.priority &&
    a.priority.priority !== b.priority.priority
      ? a.priority.priority > b.priority.priority
        ? 1
        : -1
      : new Date(b.dateCreated!).getTime() - new Date(a.dateCreated!).getTime()
  );
}

async function getUserNotifications(): Promise<Array<UserNotification>> {
  return http
    .get<Array<UserNotification>>('/api/user-notifications', { responseType: 'json' })
    .then(sortUserNotifications);
}

export default getUserNotifications;
