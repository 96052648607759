import { FormControlLabel, FormGroup, Grid, GridSize, Link } from '@mui/material';
import { FormField } from './FormField';
import { AnswerOption } from 'types/Wizard';
import React, { FC, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Checkbox } from 'components/Form/Forms.styled';

type CheckBoxQuestionProps = {
  answerOptions: AnswerOption[];
  toolTip?: ReactNode;
  containerWidth?: GridSize;
  value: string[];
  label?: ReactNode;
  helperText?: ReactNode;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  focused?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string[]) => void;
  onFocus?: React.FocusEventHandler<HTMLButtonElement>;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
};

const CheckBoxQuestion: FC<CheckBoxQuestionProps> = ({
  answerOptions,
  value,
  toolTip,
  label,
  helperText,
  containerWidth,
  error,
  required,
  disabled,
  focused,
  onChange,
  onFocus,
  onBlur,
}) => {
  const { t } = useTranslation();
  const [hasFocus, setHasFocus] = React.useState<boolean>(focused ?? false);
  value = value ?? [];

  return (
    <FormField
      open
      label={label}
      tooltip={toolTip}
      required={required}
      error={error}
      helperText={helperText}
      disabled={disabled}
      focused={hasFocus}
    >
      <FormGroup>
        <Grid item container xs={containerWidth ?? 12}>
          {answerOptions.map((answer) => (
            <FormControlLabel
              key={answer.id}
              disabled={answer.disabled}
              control={
                <Checkbox
                  checked={value.find((a) => a === answer.id) !== undefined}
                  onChange={(e: any, checked: boolean) => {
                    if (checked) {
                      value.push(answer.id);
                    } else {
                      const index = value.findIndex((a) => a === answer.id);
                      if (index >= 0) {
                        value.splice(index);
                      }
                    }
                    onChange && onChange(e, value);
                  }}
                  onFocus={(e: any) => {
                    setHasFocus(true);
                    onFocus && onFocus(e);
                  }}
                  onBlur={(e: any) => {
                    setHasFocus(false);
                    onBlur && onBlur(e);
                  }}
                  name={answer.id}
                  color={error ? 'error' : 'primary'}
                />
              }
              label={
                <>
                  <Trans
                    i18nKey={`${answer.answerTextKey}`}
                    components={[
                      <Link
                        href={t('wizard.btl.orientation.AcceptProductAgreements.answer.hyperlink')}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        0
                      </Link>,
                    ]}
                  />
                </>
              }
            />
          ))}
        </Grid>
      </FormGroup>
    </FormField>
  );
};

export default CheckBoxQuestion;
