import { FC, useState } from 'react';
import Button from '@mui/material/Button';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from './DropdownButton.styled';
import { PopoverOrigin } from '@mui/material/Popover';

type DropdownButtonProps = {
  'label': string;
  'anchorOrigin'?: PopoverOrigin;
  'transformOrigin'?: PopoverOrigin;
  'className'?: string;
  'name'?: string;
  'data-testid'?: string;
  'children'?: React.ReactNode;
};

const DropdownButton: FC<DropdownButtonProps> = ({
  label,
  children,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  className,
  name,
  'data-testid': dataTestId,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        name={name}
        data-testid={dataTestId}
      >
        {label}
      </Button>
      <Menu
        elevation={0}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div onClick={handleClose}>{children}</div>
      </Menu>
    </div>
  );
};

export default DropdownButton;
