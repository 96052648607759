import { FC } from 'react';
import { Container } from '@mui/material';

import Details from './Details';
import NotificationList from './NotificationList';
import { NotificationPreferenceFormType } from 'types/NotificationPreference';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PlaceholderEmptyList from 'components/PlaceholderEmptyList';

type FormProps = { onSubmit: () => void };

const Form: FC<FormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<NotificationPreferenceFormType>();
  const notifications = watch('notifications');

  return (
    <Container maxWidth="lg">
      {notifications.length ? (
        <form onSubmit={onSubmit} data-testid="notification-preference-form">
          <Details />
          <NotificationList />
        </form>
      ) : (
        <PlaceholderEmptyList
          title={t('notificationPreference.formComponents.nothingToShow')}
          subtitle={t('notificationPreference.formComponents.nothingToShowDesc')}
        />
      )}
    </Container>
  );
};
export default Form;
