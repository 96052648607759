export enum Status {
  Loading = 'Loading',
  New = 'New',
  Submitted = 'Submitted',
  Cancelled = 'Cancelled',
  Accepted = 'Accepted',
}

export enum TypeOfLoan {
  FirstMortgage = 'FirstMortgage',
  Private = 'Private',
  Conversion = 'Conversion',
  InternalRefinancing = 'InternalRefinancing',
  RefinancingDifferentMoneylender = 'RefinancingDifferentMoneylender',
  SecondOrHigherMortgage = 'SecondOrHigherMortgage',
  MortgageTransfer = 'MortgageTransfer',
  PassOnMortgage = 'PassOnMortgage',
}
export type StepNames = 'loanDetails' | 'policies' | 'additionalInformation' | 'summary';

export type Step = {
  name: StepNames;
  index: number;
};
