import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableBody, Table, TableHead, TableRow, TableContainer, TableCell } from '@mui/material';
import PlaceholderEmptyList from 'components/PlaceholderEmptyList';
import LoanPartsMobileTable from './LoanPartMobileTable';
import LoanPartsTableBody from './LoanPartsTableBody';
import { IsMobile } from 'utils/global';
import { ClientDetailsLoanParts } from 'types/ClientDetailsModel';

type LoanPartsDetailsProps = {
  loanParts?: ClientDetailsLoanParts[];
};

const LoanPartsDetails: FC<LoanPartsDetailsProps> = ({ loanParts }) => {
  const { t } = useTranslation();
  const translationsRoot = 'clients.clientData.loan.loanParts';
  const isMobile = IsMobile();

  if (!loanParts || !loanParts.length) {
    return <PlaceholderEmptyList title={t(`${translationsRoot}.none`)} />;
  }

  if (isMobile) {
    return (
      <>
        {loanParts?.map((loanPart, index) => (
          <LoanPartsMobileTable
            key={index}
            loanPart={loanPart}
            translationsRoot={translationsRoot}
          />
        ))}
      </>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">{t(`${translationsRoot}.part`)}</TableCell>
            <TableCell sx={{ maxWidth: '100px' }} align="left">
              {t(`${translationsRoot}.originalAmount`)}
            </TableCell>
            <TableCell sx={{ maxWidth: '100px' }} align="left">
              {t(`${translationsRoot}.remainingAmount`)}
            </TableCell>
            <TableCell align="left">{t(`${translationsRoot}.interestRate`)}</TableCell>
            <TableCell align="left">{t(`${translationsRoot}.progress`)}</TableCell>
            <TableCell align="left" padding="checkbox" />
          </TableRow>
        </TableHead>
        <TableBody>
          {loanParts?.map((loanPart, index) => (
            <LoanPartsTableBody
              loanPart={loanPart}
              key={index}
              translationsRoot={translationsRoot}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LoanPartsDetails;
