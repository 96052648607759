import type { RouteConfig as ReactRouterConfig } from 'react-router-config';
import CollateralValuesMutation from 'screens/Mutations/components/CollateralValuesMutation/CollateralValuesMutation';
import NewsItemPage from 'screens/News/components/NewsItemPage';
import Applications from 'screens/Applications';
import Home from 'screens/Home';
import ConversionMutation from 'screens/Mutations/components/ConversionMutation/ConversionMutation';
import DismissalJointLiabilityMutation from 'screens/Mutations/components/DismissalJointLiabilityMutation';
import News from 'screens/News';
import Notifications from 'screens/Notifications';
import CreateClient from 'screens/Clients/components/CreateClient';
import Downloads from './screens/Downloads';
import { Env } from 'config/env';
import ConversionRaiseMutation from 'screens/Mutations/components/ConversionRaiseMutation';
import NotificationPreferenceScreen from 'screens/NotificationPreference';
import Overview from 'screens/Overview/Overview';
import LabeledClients from 'screens/Clients/LabeledClients';
import GenericClients from 'screens/Clients/GenericClients';

export type RouteConfig = ReactRouterConfig & {
  name: string;
  showInMenu?: boolean;
  showInNav?: boolean;
  isLabeled?: boolean;
  path: string;
};

const getRoutes = ({ FEATURES }: Env): RouteConfig[] => [
  {
    path: '/',
    name: 'home',
    exact: true,
    Component: Home,
    showInMenu: true,
    icon: 'dashboard',
  },
  {
    path: '/meekijken',
    name: 'meekijken',
    exact: true,
    Component: Home,
    showInMenu: false,
  },
  {
    path: '/:commercialRelation/applications',
    name: 'applications',
    Component: Applications,
    showInMenu: true,
    icon: 'clipboard',
    isLabeled: true,
  },
  {
    path: '/:commercialRelation/applications/:loanNumber/:applicationIndexNumber',
    name: 'applications',
    Component: Applications,
    showInMenu: false,
    icon: 'clipboard',
    isLabeled: true,
  },
  {
    path: '/clients/create',
    name: 'create-client',
    Component: CreateClient,
  },
  {
    path: '/clients',
    name: 'clients',
    Component: GenericClients,
    showInMenu: true,
    icon: 'user',
  },
  {
    path: '/clients/create',
    name: 'create-client',
    Component: CreateClient,
  },
  {
    path: '/:commercialRelation/clients/:loanNumber/:applicationIndexNumber',
    name: 'labeledClients',
    Component: LabeledClients,
    showInMenu: false,
    icon: 'user',
    isLabeled: true,
  },
  {
    path: '/:commercialRelation/clients/:loanNumber',
    name: 'labeledClients',
    Component: LabeledClients,
    showInMenu: false,
    icon: 'user',
    isLabeled: true,
  },
  {
    path: '/:commercialRelation/clients',

    name: 'labeledClients',
    Component: LabeledClients,
    showInMenu: true,
    icon: 'user',
    isLabeled: true,
  },
  {
    path: '/notifications',
    name: 'notifications',
    Component: Notifications,
    showInMenu: true,
    icon: 'file_text',
  },
  {
    path: '/downloads',
    name: 'downloads',
    Component: Downloads,
    showInMenu: true,
    icon: 'download',
  },
  {
    path: '/news/:slug',
    name: 'news-item',
    Component: NewsItemPage,
  },
  {
    path: '/news/:id',
    name: 'news-item',
    Component: NewsItemPage,
  },
  {
    path: '/news',
    name: 'news',
    Component: News,
    showInMenu: true,
    icon: 'note',
  },
  {
    path: '/mutations/conversion/:commercialLabel/:loanNumber',
    name: 'mutations',
    Component: ConversionMutation,
  },
  {
    path: '/mutaties/oha/:commercialLabel/:loanNumber',
    name: 'mutations',
    Component: DismissalJointLiabilityMutation,
  },
  {
    path: '/mutaties/oha/:commercialLabel/:loanNumber/:step',
    name: 'mutations',
    Component: DismissalJointLiabilityMutation,
  },
  {
    path: '/mutaties/onderpandwaarde/:commercialLabel/:loanNumber',
    name: 'mutations',
    Component: CollateralValuesMutation,
  },
  {
    path: '/mutaties/verhogen/:commercialLabel/:loanNumber',
    name: 'mutations',
    Component: ConversionRaiseMutation,
  },
  {
    path: '/mutaties/verhogen-omzetten/:commercialLabel/:loanNumber',
    name: 'mutations',
    Component: ConversionRaiseMutation,
  },
  {
    path: '/beheer-notificaties',
    name: 'notification-preference',
    Component: NotificationPreferenceScreen,
    showInMenu: false,
    icon: 'settings',
  },
  {
    path: '/functionaliteit-overzicht',
    name: 'functionalities-overview',
    Component: Overview,
    showInMenu: false,
    icon: 'tune',
  },
];

export default getRoutes;
