import { SummaryCard, CardRow, CardSection } from 'components/SummaryCard';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAmount } from 'utils/formatAmount';
import { formatYesNo } from 'utils/formatYesNo';
import { getTypeOfFinancingLabel } from 'screens/Mutations/constants';
import { showPersonalEquity } from '../../../../CostAndResourcesForm/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

type CostAndResourcesProps = {
  onClick: () => void;
};

const CostAndResources: FC<CostAndResourcesProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const root = 'directMutations.costAndResources';
  const costAndResources = useSelector((state: RootState) => state.ohaForm.costAndResources) ?? {};

  return (
    <SummaryCard title={t(`${root}.formTitle`)} onEditClick={onClick}>
      <CardSection title={t(`${root}.formTitle`)}>
        <CardRow
          name={t(`${root}.hasBuyoutAmount`)}
          value={formatYesNo(costAndResources.hasBuyoutAmount)}
        />
        {costAndResources.hasBuyoutAmount === 'yes' && (
          <>
            <CardRow
              name={t(`${root}.buyoutAmount`)}
              value={formatAmount(costAndResources.buyoutAmount)}
            />
            <CardRow
              name={t(`${root}.typeOfBuyoutFinancing`)}
              value={getTypeOfFinancingLabel(costAndResources.typeOfBuyoutFinancing)}
            />
            {showPersonalEquity(costAndResources.typeOfBuyoutFinancing) && (
              <CardRow
                name={t(`${root}.buyoutAmountByPersonalEquity`)}
                value={formatAmount(costAndResources.buyoutAmountByPersonalEquity)}
              />
            )}
          </>
        )}
      </CardSection>

      <CardSection title={t(`${root}.costFormTitle`)}>
        <CardRow
          name={t(`${root}.consultancyCosts`)}
          value={formatAmount(costAndResources.consultancyCosts)}
        />
        <CardRow name={t(`${root}.nhgCosts`)} value={formatAmount(costAndResources.nhgCosts)} />
        <CardRow
          name={t(`${root}.notaryCosts`)}
          value={formatAmount(costAndResources.notaryCosts)}
        />
      </CardSection>

      <CardSection title={t(`${root}.addedPersonalEquityFormTitle`)}>
        <CardRow
          name={t(`${root}.addedPersonalEquity`)}
          value={formatAmount(costAndResources.addedPersonalEquity)}
        />
      </CardSection>
    </SummaryCard>
  );
};

export default CostAndResources;
