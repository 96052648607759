import { Button } from '@mui/material';
import { FC } from 'react';
import BaseDialog from './BaseDialog';

type NotificationDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  buttonText: string;
  bodyText: string;
};

const NotificationDialog: FC<NotificationDialogProps> = ({
  isOpen,
  onClose,
  buttonText,
  bodyText,
}) => {
  const notificationButtons = (
    <Button onClick={onClose} variant="contained" color="primary">
      {buttonText}
    </Button>
  );

  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={onClose}
      buttonGroup={notificationButtons}
      bodyText={bodyText}
    />
  );
};

export default NotificationDialog;
