import React, { FC } from 'react';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import clsx from 'clsx';
import formatDate from 'utils/formatDate';
import { useTranslation } from 'react-i18next';
import { BtlApplicant } from 'types/BtlFormApplication';

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    '& td': {
      borderBottom: 'none',
      wordBreak: 'break-all',
    },
    '&.error': {
      'backgroundColor': theme.palette.danger.light,
      '& :last-child': {
        color: theme.palette.danger.main,
      },
    },
  },
  errorIcon: {
    'float': 'right',
    'display': 'none',
    '.error &': {
      display: 'block',
    },
  },
  loanPartTitle: {
    marginBottom: theme.spacing(2),
  },
}));

type BuyToLetLegalPersonPersonalSummaryProps = {
  applicant: BtlApplicant;
  applicantIndex: number;
  onClickField: (field: string) => void;
  errorMessage: (id: string, category: string) => void;
};
const BuyToLetLegalPersonPersonalSummary: FC<BuyToLetLegalPersonPersonalSummaryProps> = ({
  applicant,
  applicantIndex,
  onClickField,
  errorMessage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const form = 'buyToLetForm.newApplicantLegalPerson';
  const existingMortgageQuestions = 'buyToLetForm.buyToLetExistingMortgageQuestions';

  return (
    <>
      <Table className={classes.table}>
        <TableBody>
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(
                errorMessage(`[${applicantIndex}].hasExistingMortgageByMoneylender`, 'applicants')
              ),
            })}
          >
            <TableCell>
              {t(`${existingMortgageQuestions}.hasExistingMortgageByMoneylender`)}
            </TableCell>
            <TableCell onClick={() => onClickField('hasExistingMortgageByMoneylender')}>
              {applicant.hasExistingMortgageByMoneylender === false
                ? t(`${form}.no`)
                : applicant.hasExistingMortgageByMoneylender === true
                ? t(`${form}.yes`)
                : ''}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>

          {applicant.hasExistingMortgageByMoneylender === true && (
            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(
                  errorMessage(`[${applicantIndex}].existingLoanNumber`, 'applicants')
                ),
              })}
            >
              <TableCell>
                {t(`${existingMortgageQuestions}.existingLoanNumber`)}
                <span className="required">*</span>
              </TableCell>
              <TableCell onClick={() => onClickField('existingLoanNumber')}>
                {applicant.existingLoanNumber}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>
          )}
          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage(`[${applicantIndex}].isMortgageRejected`, 'applicants')),
            })}
          >
            <TableCell>{t(`${existingMortgageQuestions}.isMortgageRejected`)}</TableCell>
            <TableCell onClick={() => onClickField('isMortgageRejected')}>
              {applicant.isMortgageRejected === false
                ? t(`${form}.no`)
                : applicant.isMortgageRejected === true
                ? t(`${form}.yes`)
                : ''}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>

          {applicant.isMortgageRejected === true && (
            <TableRow
              className={clsx(classes.tableRow, {
                error: Boolean(
                  errorMessage(`[${applicantIndex}].reasonMortgageRejection`, 'applicants')
                ),
              })}
            >
              <TableCell>
                {t(`${existingMortgageQuestions}.reasonMortgageRejection`)}
                <span className="required">*</span>
              </TableCell>
              <TableCell onClick={() => onClickField('reasonMortgageRejection')}>
                {applicant.reasonMortgageRejection}
                <ErrorOutlineOutlined className={classes.errorIcon} />
              </TableCell>
            </TableRow>
          )}

          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage(`[${applicantIndex}].companyName`, 'applicants')),
            })}
          >
            <TableCell>
              {t(`${form}.companyName`)}
              <span className="required">*</span>
            </TableCell>
            <TableCell onClick={() => onClickField('companyName')}>
              {applicant.companyName}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>

          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(
                errorMessage(`[${applicantIndex}].chamberOfCommerceNumber`, 'applicants')
              ),
            })}
          >
            <TableCell>
              {t(`${form}.chamberOfCommerceNumber`)}
              <span className="required">*</span>
            </TableCell>
            <TableCell onClick={() => onClickField('chamberOfCommerceNumber')}>
              {applicant.chamberOfCommerceNumber}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>

          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage(`[${applicantIndex}].legalForm`, 'applicants')),
            })}
          >
            <TableCell>
              {t(`${form}.legalForm`)}
              <span className="required">*</span>
            </TableCell>
            <TableCell onClick={() => onClickField('companlegalFormyName')}>
              {applicant.legalForm && t(`${form}.${applicant.legalForm}`)}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>

          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(errorMessage(`[${applicantIndex}].companyStartDate`, 'applicants')),
            })}
          >
            <TableCell>
              {t(`${form}.companyStartDate`)}
              <span className="required">*</span>
            </TableCell>
            <TableCell onClick={() => onClickField('companyStartDate')}>
              {applicant.companyStartDate && formatDate(applicant.companyStartDate)}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>

          <TableRow
            className={clsx(classes.tableRow, {
              error: Boolean(
                errorMessage(`[${applicantIndex}].kindOfActivitiesInCompany`, 'applicants')
              ),
            })}
          >
            <TableCell>
              {t(`${form}.kindOfActivitiesInCompany`)}
              <span className="required">*</span>
            </TableCell>
            <TableCell onClick={() => onClickField('kindOfActivitiesInCompany')}>
              {applicant.kindOfActivitiesInCompany}
              <ErrorOutlineOutlined className={classes.errorIcon} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default BuyToLetLegalPersonPersonalSummary;
