import { useEffect } from 'react';
import FormTitleText from 'components/Form/FormTitle';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { FormInputCurrency, FormToggleButtonGroup } from 'components/Form';
import { FieldsGrid } from 'components/Form/Forms.styled';
import { SubTitle } from 'screens/Mutations/molecules/styled';
import { yesNoGroupOptions } from 'screens/Mutations/constants';
import { useWatch } from 'react-hook-form';
import useUnregisterFields from 'screens/Mutations/hooks/useUnregisterField';

export default function CostAndResources() {
  const { t } = useTranslation();
  const isPrivateIncrease = useWatch({ name: 'financingCostAndResources.isPrivateIncrease' });
  const unregisterMultiple = useUnregisterFields();

  useEffect(() => {
    if (!isPrivateIncrease || isPrivateIncrease === 'yes') {
      unregisterMultiple([
        'financingCostAndResources.notaryCosts',
        'financingCostAndResources.amountOfMortgage',
      ]);
    }
  }, [isPrivateIncrease, unregisterMultiple]);

  return (
    <>
      <FormTitleText
        title={t('directMutations.costAndResources.title')}
        description={t('directMutations.costAndResources.subtitle')}
      />
      <FieldsGrid container>
        <SubTitle variant="h5">{t('directMutations.costAndResources.costFormTitle')}</SubTitle>
        <Grid item xs={12}>
          <FormInputCurrency
            name="financingCostAndResources.consultancyCosts"
            label={t('directMutations.costAndResources.consultancyCosts')}
            placeholder={t('directMutations.amountPlaceholder')}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputCurrency
            name="financingCostAndResources.nhgCosts"
            label={t('directMutations.costAndResources.nhgCosts')}
            placeholder={t('directMutations.amountPlaceholder')}
            required
            min={0}
          />
        </Grid>
        <Grid item xs={12}>
          <FormToggleButtonGroup
            name="financingCostAndResources.isPrivateIncrease"
            options={yesNoGroupOptions}
            label={t('directMutations.costAndResources.isPrivateIncrease')}
            required
          />
        </Grid>
        {isPrivateIncrease === 'no' && (
          <>
            <Grid item xs={12}>
              <FormInputCurrency
                name="financingCostAndResources.notaryCosts"
                label={t('directMutations.costAndResources.notaryCosts')}
                placeholder={t('directMutations.amountPlaceholder')}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputCurrency
                name="financingCostAndResources.amountOfMortgage"
                label={t('directMutations.costAndResources.amountOfMortgage')}
                placeholder={t('directMutations.amountPlaceholder')}
                required
              />
            </Grid>
          </>
        )}
      </FieldsGrid>
      <FieldsGrid container>
        <SubTitle variant="h5">
          {t('directMutations.costAndResources.addedPersonalEquityFormTitle')}
        </SubTitle>
        <Grid item xs={12}>
          <FormInputCurrency
            name="financingCostAndResources.addedPersonalEquity"
            label={t('directMutations.costAndResources.addedPersonalEquity')}
            placeholder={t('directMutations.amountPlaceholder')}
            required
            min={0}
          />
        </Grid>
      </FieldsGrid>
    </>
  );
}
