import currency from 'currency.js';

export const formatAmount = (amount: number | undefined | null): string => {
  if (!amount) return '-';
  return currency(amount, {
    separator: '.',
    decimal: ',',
    symbol: '€\u00A0',
    precision: 2,
  }).format();
};
