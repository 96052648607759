import { Alert, AlertColor, Snackbar } from '@mui/material';
import { SnackbarDuration } from 'config/constants';

type CustomSnackbarProps = {
  isOpen: boolean;
  handleClose: () => void;
  severity: AlertColor | undefined;
  message: string;
  testid?: string;
  horizontalPosition?: 'right' | 'left' | 'center';
  verticalPosition?: 'top' | 'bottom';
};

function CustomSnackbar({
  isOpen,
  handleClose,
  severity,
  message,
  testid,
  horizontalPosition = 'center',
  verticalPosition = 'bottom',
}: CustomSnackbarProps) {
  return (
    <Snackbar
      onClick={handleClose}
      anchorOrigin={{ vertical: verticalPosition, horizontal: horizontalPosition }}
      open={isOpen}
      autoHideDuration={SnackbarDuration}
      onClose={handleClose}
      data-testid={testid}
    >
      <Alert severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
}

export default CustomSnackbar;
