import { GridRow } from 'screens/Overview/Overview.style';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

export default function TableBodyRow({ isActive }: { isActive?: boolean }) {
  return (
    <GridRow item>
      {isActive ? (
        <CheckCircleIcon fontSize="small" color="success" />
      ) : (
        <CloseIcon fontSize="small" color="action" />
      )}
    </GridRow>
  );
}
