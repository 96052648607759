import { Icon as MUIIcon, Box as MUIBox, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const UserIcon = styled(MUIIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '20px',
  marginRight: theme.spacing(2),
}));
export const BoxWrapper = styled(MUIBox)({
  marginTop: 0,
});
export const AddApplicantButton = styled(Button)({
  textTransform: 'none',
  fontSize: '13px',
});
type CustomTypo = {
  isvalid?: 1 | 0;
};
export const ApplicantName = styled(Typography)<CustomTypo>(({ theme, isvalid }) => ({
  color: isvalid ? theme.palette.danger.main : '',
}));
