import { AxiosRequestConfig } from 'axios';
import { HEADER_SELECTED_GROUP_CLAIM, http } from '../http';

export const uploadLoanDocument = (
  data: FormData,
  group: string | null,
  config?: AxiosRequestConfig
) => {
  const headers = {
    [HEADER_SELECTED_GROUP_CLAIM]: group,
    'Accept': '*/*',
    'Content-Type': 'multipart/form-data',
  };

  return http.post('/api/loandocuments/proof-items', data, { ...config, headers });
};
