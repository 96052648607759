import { array, object, number, string } from 'yup';
import { errorMessages } from '../../../constants';
import getConvertLoanSchema from './getConvertLoanSchema';
import getSplitLoanSchema from './getSplitLoanSchema';

const getLoanPartsSchema = () =>
  array().of(
    object({
      convertLoan: object().when('shouldSplitLoan', {
        is: (val: any) => val === 'no',
        then: getConvertLoanSchema(),
      }),
      splitLoan: array().when('shouldSplitLoan', {
        is: (val: any) => val === 'yes',
        then: array().of(getSplitLoanSchema()),
      }),
      loanPartNumber: number().typeError(errorMessages.number).required(errorMessages.required),
      repaymentType: string().required(errorMessages.required),
      shouldSplitLoan: string().required(errorMessages.required).nullable(),
      NHGGuarantee: string().required(errorMessages.required).nullable(),
      loanAmount: number().typeError(errorMessages.number).required(errorMessages.required),
      hasConnectedPolicy: string().required(errorMessages.required).nullable(),
      numberOfSplitLoanParts: number().when('shouldSplitLoan', {
        is: (val: any) => val === 'yes',
        then: number().required(errorMessages.required).nullable(),
      }),
    })
  );

export default getLoanPartsSchema;
