import { styled } from '@mui/material/styles';

export const Submenu = styled('ul')(({ theme }) => ({
  'listStyle': 'none',
  'height': 0,
  'overflow': 'hidden',
  'padding': 0,
  'margin': 0,
  'backgroundColor': 'rgba(54, 26, 96, 0.5)',
  'borderRadius': `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 1),
  },
  '& li span': {
    backgroundColor: 'transparent',
  },
}));
