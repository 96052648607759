import { DeleteOutline } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { FormInputCurrency, FormInputText, FormToggleButtonGroup } from 'components/Form';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  oneOrTwoGroupOption,
  policyChange,
} from 'screens/Mutations/components/ConversionMutation/constants';
import { yesNoGroupOptions } from 'screens/Mutations/constants';
import { Policy } from 'screens/Mutations/utils/types';
import { TypeOfPolicyMutation } from '__generated__/api';
import { WarningButton } from '../../../../components/ConversionMutation/components/ConnectedPolicies/ConnectedPolicies.styled';

type PoliciesFormProps = {
  rootForm: string;
  policyIndex: number;
  formPolicies: Policy[];
  shouldUnregister: boolean;
  remove: (index: number) => void;
  isPolicyAmount?: boolean;
};
const PoliciesForm: FC<PoliciesFormProps> = ({
  shouldUnregister = true,
  formPolicies,
  policyIndex,
  remove,
  rootForm,
  isPolicyAmount = true,
}) => {
  const { t } = useTranslation();
  const { unregister } = useFormContext();

  const {
    numberOfPolicyHolders,
    isPolicyForConnectedPersons,
    numberOfInsuredPersons,
    typeOfPolicyMutation,
  } = formPolicies[policyIndex];

  const removeFields = () => {
    remove(policyIndex);
  };
  useEffect(() => {
    if (!numberOfPolicyHolders) {
      unregister([`${rootForm}.policyHolders`]);
    }
    if (numberOfPolicyHolders === 1) {
      unregister([`${rootForm}.policyHolders.personTwo`]);
    }
  }, [numberOfPolicyHolders, unregister, rootForm]);

  useEffect(() => {
    if (isPolicyForConnectedPersons === 'yes') {
      unregister([`${rootForm}.insuredPersons`, `${rootForm}.numberOfInsuredPersons`]);
    }
    if (
      numberOfInsuredPersons &&
      numberOfInsuredPersons === 1 &&
      isPolicyForConnectedPersons === 'no'
    ) {
      unregister([`${rootForm}.insuredPersons.personTwo`]);
    }
  }, [numberOfInsuredPersons, isPolicyForConnectedPersons, unregister, rootForm]);

  useEffect(() => {
    if (typeOfPolicyMutation === TypeOfPolicyMutation.Continued) {
      unregister([`${rootForm}.mutationDescription`]);
    }
  }, [typeOfPolicyMutation, unregister, rootForm]);

  return (
    <Grid container data-testid="policiesForm" pb={1}>
      <Grid item xs={12}>
        <FormInputText
          name={`${rootForm}.policyNumber`}
          label={t('directMutations.policies.policyNumber')}
          placeholder={t('directMutations.policies.policyNumberPlaceholder')}
          required
        />
      </Grid>
      {isPolicyAmount && (
        <Grid item xs={12}>
          <FormInputCurrency
            name={`${rootForm}.policyAmount`}
            label={t('directMutations.policies.policyAmount')}
            placeholder={t('directMutations.amountPlaceholder')}
            min={0}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormToggleButtonGroup
          options={oneOrTwoGroupOption}
          name={`${rootForm}.numberOfPolicyHolders`}
          label={t('directMutations.policies.numberOfPolicyholders')}
          required
        />
      </Grid>
      {numberOfPolicyHolders && (
        <Grid item xs={12}>
          <FormInputText
            name={`${rootForm}.policyHolders.personOne`}
            label={t('directMutations.policies.namePolicyHolder')}
            required
          />
        </Grid>
      )}
      {numberOfPolicyHolders === 2 && (
        <>
          <Grid item xs={12}>
            <FormInputText
              name={`${rootForm}.policyHolders.personTwo`}
              label={t('directMutations.policies.namePolicyHolder')}
              required
              shouldUnregister={shouldUnregister}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <FormToggleButtonGroup
          name={`${rootForm}.isPolicyForConnectedPersons`}
          label={t('directMutations.policies.isPolicyForConnectedPersons')}
          options={yesNoGroupOptions}
          required
        />
      </Grid>
      {isPolicyForConnectedPersons === 'no' && (
        <Grid item xs={12}>
          <FormToggleButtonGroup
            options={oneOrTwoGroupOption}
            name={`${rootForm}.numberOfInsuredPersons`}
            label={t('directMutations.policies.numberOfInsuredPersons')}
            required
            shouldUnregister={shouldUnregister}
          />
        </Grid>
      )}
      {numberOfInsuredPersons && isPolicyForConnectedPersons === 'no' && (
        <Grid item xs={12}>
          <FormInputText
            name={`${rootForm}.insuredPersons.personOne`}
            label={t('directMutations.policies.nameInsured')}
            required
            shouldUnregister={shouldUnregister}
          />
        </Grid>
      )}
      {numberOfInsuredPersons === 2 && isPolicyForConnectedPersons === 'no' && (
        <>
          <Grid item xs={12}>
            <FormInputText
              name={`${rootForm}.insuredPersons.personTwo`}
              label={t('directMutations.policies.nameInsured')}
              required
              shouldUnregister={shouldUnregister}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <FormToggleButtonGroup
          name={`${rootForm}.typeOfPolicyMutation`}
          label={t('directMutations.policies.policyAction')}
          options={policyChange}
          required
        />
      </Grid>
      {(typeOfPolicyMutation === TypeOfPolicyMutation.Altered ||
        typeOfPolicyMutation === TypeOfPolicyMutation.Ended) && (
        <Grid item xs={12}>
          <FormInputText
            name={`${rootForm}.mutationDescription`}
            label={t('directMutations.policies.mutationDescription')}
            multiline
            rows={4}
            placeholder={t('directMutations.policies.mutationDiscriptionPlaceholder')}
            required
            shouldUnregister={shouldUnregister}
          />
        </Grid>
      )}
      {policyIndex !== 0 && (
        <Grid item xs={12}>
          <WarningButton onClick={removeFields} size="medium">
            {t('directMutations.buttons.deleteButton')} <DeleteOutline />
          </WarningButton>
        </Grid>
      )}
    </Grid>
  );
};

export default PoliciesForm;
