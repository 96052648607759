import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Icon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bolder',
  },
  icon: {
    marginLeft: theme.spacing(1),
    fontSize: '1.3em',
  },
}));

type HandlingTimeButtonProps = {
  link: string;
};

const HandlingTimeButton = (props: HandlingTimeButtonProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Link
      className={classes.link}
      href={props.link}
      target="_blank"
      rel="noopener noreferrer"
      color="primary"
    >
      {t('applications.detail.viewHandlingTime')}
      <Icon className={classes.icon}>external_link</Icon>
    </Link>
  );
};

export default HandlingTimeButton;
