import { object, array, string, bool, number } from 'yup';
import { errorMessages } from '../../constants';
import {
  getInsuredPersonsSchema,
  getLoanDetailSchema,
  getLoanPartsSchema,
  getPoliceHolders,
} from './helpers';

export const acceptedAgreementSchema = object({
  acceptedAgreement: bool().required(errorMessages.required).oneOf([true], errorMessages.required),
});

export const loanDetailFormSchema = object({
  conversionQuestionnaire: getLoanDetailSchema(),
  loanParts: array().when(
    [
      'conversionQuestionnaire.toggleInterest',
      'conversionQuestionnaire.toggleFine',
      'conversionQuestionnaire.toggleCoFinancedFine',
    ],
    {
      is: (toggleInterest: any, toggleFine: any, toggleCoFinancedFine: any) =>
        toggleInterest === 'no' || toggleFine === 'no' || toggleCoFinancedFine === 'no',
      then: getLoanPartsSchema(),
    }
  ),
});

export const connectedPoliciesFormSchema = object({
  loanPartPolicies: array().of(
    object({
      policies: array().of(
        object({
          policyNumber: string().required(errorMessages.required),
          numberOfPolicyHolders: number().required(errorMessages.required).nullable(),
          policyHolders: getPoliceHolders(),
          isPolicyForConnectedPersons: string().required(errorMessages.required).nullable(),
          numberOfInsuredPersons: number().when('isPolicyForConnectedPersons', {
            is: (val: string) => val === 'no',
            then: number().required(errorMessages.required).nullable(),
          }),
          insuredPersons: getInsuredPersonsSchema(),
          typeOfPolicyMutation: string().required(errorMessages.required).nullable(),
          mutationDescription: string().when('typeOfPolicyMutation', {
            is: (val: any) => val && val !== 'Continued',
            then: string().required(errorMessages.required),
          }),
        })
      ),
    })
  ),
});
