import { blue, green, grey, orange, purple, red } from '@mui/material/colors';
import { createTheme, ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    danger: PaletteColor;
    creme: PaletteColor;
    green: PaletteColor;
    red: PaletteColor;
    white: PaletteColor;
    black: PaletteColor;
    purple: PaletteColor;
    orange: PaletteColor;
    lightest: PaletteColor;
    icon: PaletteColor;
    textBody: PaletteColor;
    menuItem: PaletteColor;
    disabled: PaletteColor;
  }

  interface PaletteOptions {
    danger: PaletteColorOptions;
  }
}
const primary = {
  main: '#7C46CB',
  dark: '#3A1275',
  light: '#BE95FA',
  lightest: {
    dark: '#E5DFED',
    main: '#EDE1FF',
    light: '#FAF6FF',
  },
};

const text = {
  primary: 'rgba(0, 0, 0, 0.87)',
  secondary: 'rgba(0, 0, 0, 0.48)',
  body: 'rgba(0, 0, 0, 0.6)',
};

const muiV5Theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: primary.main,
      dark: primary.dark,
      light: primary.light,
    },
    secondary: {
      main: '#EF8B00',
      light: '#FBDFB2',
    },
    lightest: {
      dark: primary.lightest.dark,
      light: primary.lightest.light,
      main: primary.lightest.main,
    },
    red: {
      main: '#E11B35',
    },
    white: {
      main: '#fff',
      light: '#FDFBFF',
    },
    black: {
      main: '#000',
    },
    purple: {
      main: purple[500],
      dark: '#4D2A80',
      light: purple[50],
    },
    orange: {
      light: '#FADDA4',
    },
    info: {
      main: blue[500],
      dark: blue[700],
      light: blue[50],
    },
    warning: {
      main: orange[500],
      dark: orange[700],
      light: orange[50],
    },
    success: {
      main: green[500],
      dark: green[700],
      light: green[50],
    },
    danger: {
      main: red[500],
      dark: red[700],
      light: red[50],
    },
    text: {
      primary: text.primary,
      secondary: text.secondary,
    },
    textBody: {
      main: text.body,
    },
    icon: {
      main: 'rgba(0, 0, 0, 0.38)',
      dark: text.primary,
    },
    background: {
      default: '#FCFBFD',
    },
    menuItem: {
      main: '#863CD2',
      light: '#F7F1FF',
    },
  },
  typography: {
    // This changes the material-ui font-size. NOT the html font-size value, which remains 16px
    fontSize: 12,
    // fontSize: '12px',
    h4: {
      fontSize: '32px',
    },
    // },
  },

  components: {
    // Client-side only rendering
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },

    // MuiAlert: {
    //   defaultProps: {
    //     variant: 'filled',
    //   },
    // },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          marginBottom: 8,
          fontWeight: 500,
        },
      },
    },
    // BUTTONS
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          height: 40,
          textTransform: 'none',
          fontSize: '13px',
          padding: '10px 16px',
        },
        contained: {
          boxShadow: 'none',
        },
        containedPrimary: {
          '&.Mui-disabled': {
            backgroundColor: primary.lightest.main,
            color: '#FFFFFF',
          },
        },
        containedSecondary: {
          'backgroundColor': '#FFFFFF',
          'border': `1px solid ${grey[200]}`,
          'color': primary.main,
          '&:hover': {
            backgroundColor: primary.lightest.main,
            borderColor: primary.light,
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            backgroundColor: '#FFFFFF',
            color: primary.lightest,
          },
        },
        containedSizeSmall: {
          padding: '0.625 16px',
          fontSize: '13px',
          textTransform: 'none',
        },
        // TODO: check if we can remove this one
        containedSizeLarge: {
          height: 48,
          borderRadius: 24,
          fontSize: 14,
        },
        textSizeSmall: {
          height: 36,
          borderRadius: 18,
          padding: '10px 16px',
          fontSize: '13px',
        },
        text: {
          textTransform: 'none',
          padding: '10px 16px',
        },
        textSizeLarge: {
          height: '48px',
          borderRadius: 24,
          fontSize: 14,
        },
        textSizeMedium: {
          fontSize: '13px',
        },
        outlinedSizeSmall: {
          height: '36px',
          borderRadius: 18,
          padding: '10px 16px',
          fontSize: '13px',
        },
        outlinedSizeLarge: {
          height: '48px',
          borderRadius: 24,
          fontSize: 14,
        },
        containedSuccess: {
          'backgroundColor': primary.lightest.main,
          'borderWidth': 1,
          'borderStyle': 'solid',
          'borderColor': primary.main,
          'color': primary.main,
          '&:hover': {
            backgroundColor: primary.light,
            borderColor: primary.dark,
            color: primary.dark,
          },
        },
      },
    },
    // FORM
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: text.primary,
          fontWeight: 500,
          fontSize: '12px',
          margin: '0 0 8px 0',
        },
        asterisk: {
          'color': '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          'fontWeight': 500,
          '&.MuiInputLabel-shrink': {
            transform: 'translate(0, -22px) scale(1)',
            fontSize: '12px',
          },
          '&.PrivateNotchedOutline-legendLabelled': {
            width: 0,
          },
        },
        asterisk: {
          color: '#E9B040',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          'background': 'white',
          '&.Mui-disabled': {
            backgroundColor: 'rgba(229, 229, 229, 0.1)',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          margin: 0,
          width: '100%',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          'textTransform': 'none',
          'height': '48px',
          '&.Mui-selected': {
            borderColor: primary.main,
            color: primary.main,
            backgroundColor: primary.lightest.main,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
        groupedHorizontal: {
          '&:not(:first-of-type)': {
            '&.Mui-selected': {
              borderLeftColor: primary.main,
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover': {
            '&:hover': {
              'borderRadius': '4px',
              'boxShadow': '0 6px 12px 0 rgba(22,20,47,0.12)',
              '& td': {
                'backgroundColor': '#FFFFFF',
                'borderBottomColor': 'transparent',
                '&:first-of-type': {
                  borderRadius: '4px 0 0 4px',
                },
                '&:last-child': {
                  borderRadius: '0 4px 4px 0',
                },
              },
            },
          },
          '& .required': {
            color: orange[500],
            marginLeft: 6,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          fontSize: 14,
        },
        head: {
          textTransform: 'uppercase',
          color: text.secondary,
          letterSpacing: 0.92,
          padding: '7px 0 9px 16px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: '12px',
        },
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    // FAB
    MuiFab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        primary: {
          '&.Mui-disabled': {
            backgroundColor: '#EDE1FF',
            color: '#FFFFFF',
          },
        },
        secondary: {
          'backgroundColor': '#FFFFFF',
          'border': `1px solid ${grey[200]}`,
          'color': primary.main,
          '&:hover': {
            backgroundColor: primary.lightest.main,
            borderColor: primary.light,
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            backgroundColor: '#FFFFFF',
            color: primary.lightest.main,
          },
        },
        sizeSmall: {
          'width': 34,
          'height': 34,
          'minHeight': 34,
          '&.MuiFab-extended': {
            padding: '0 16px',
          },
        },
        // sizeMedium: {
        //   width: 40,
        //   height: 40,
        //   minHeight: 40,
        //   fontSize: '13px',
        // },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'transparent',
            fontWeight: 500,
            color: text.primary,
          },
          '&.Mui-focusVisible': {
            background: 'transparent',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 12,
        },
        elevation1: {
          boxShadow: '0 6px 12px 0 rgba(22,20,47,0.12)',
        },
      },
    },
    // MUI STEPPER
    MuiStepConnector: {
      styleOverrides: {
        lineVertical: {
          minHeight: 0,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          display: 'none',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#ffffff',
          borderRadius: 3,
          padding: '1em',
          color: text.primary,
          fontSize: '1em',
          lineHeight: 'inherit',
          boxShadow: '0 6px 12px 0 rgba(22,20,47,0.12)',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        rounded: {
          borderRadius: 12,
        },
        elevation1: {
          boxShadow: '0 6px 12px 0 rgba(22,20,47,0.12)',
        },
        root: {
          borderRadius: 6,
          boxShadow: '0 6px 10px 0 rgba(0,0,0,0.14)',
          color: '#fff',
        },
        message: {
          padding: '10px 0',
        },
        standardSuccess: {
          backgroundColor: green[500],
        },
        standardError: {
          'backgroundColor': '#E53956',
          '.MuiAlert-icon': {
            color: '#fff',
          },
        },
        standardWarning: {
          backgroundColor: '#EF8B00',
        },
        standardInfo: {
          backgroundColor: '#00BDE3',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          border: 'none',
        },
        displayedRows: {
          alignContent: 'center',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          width: '160px',
        },
        actions: {
          display: 'flex',
          justifyContent: 'space-between',
          width: '160px',
          marginLeft: 0,
        },
      },
    },
    //   MuiTextField: {
    //     root: {
    //       'marginTop': 22,
    //       '& .MuiOutlinedInput-multiline': {
    //         padding: '16px',
    //       },
    //       '& .Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
    //         borderColor: 'red',
    //       },
    //     },
    //   },
    //   MuiFormControl: {
    //     root: {
    //       '&.formInput': {
    //         marginTop: 22,
    //       },
    //       '& .MuiGrid-grid-xs-6': {
    //         paddingRight: '12px',
    //       },
    //     },
    //   },
    //   MuiOutlinedInput: {
    //     input: {
    //       padding: '16px',
    //       backgroundColor: '#FFFFFF',
    //     },
    //   },

    //   PrivateNotchedOutline: {
    //     legendNotched: {
    //       maxWidth: 0,
    //     },
    //   },
    //   MuiFormLabel: {
    //     root: {
    //       'color': 'rgba(0,0,0,.87)',
    //       '&.formLabel': {
    //         'marginBottom': 10,
    //         'fontSize': 12,
    //         'fontWeight': 500,
    //         '&.Mui-focused': {
    //           color: primary.main,
    //         },
    //         '&.Mui-error': {
    //           color: 'red',
    //         },
    //       },
    //     },
    //     asterisk: {
    //       color: '#E9B040',
    //     },
    //   },
    //   MuiBackdrop: {
    //     root: {
    //       backgroundColor: 'rgba(16, 5, 33, 0.72)',
    //     },
    // },
  },
  shape: {
    borderRadius: 4,
  },
} as ThemeOptions;

const theme = createTheme(muiV5Theme);

export default theme;
