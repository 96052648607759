import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import formatDate from 'utils/formatDate';
import { TaskInfo, TaskInfoDelegationState } from '__generated__/api-di';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  text: {
    fontSize: '12px',
    color: theme.palette.text.secondary,
  },
  interpunct: {
    margin: theme.spacing(0, 0.5),
    color: theme.palette.text.secondary,
  },
  interpunctRightSpacing: {
    margin: theme.spacing(0, 0.5, 0, 0),
    color: theme.palette.text.secondary,
  },
  dueIcon: {
    marginRight: theme.spacing(0.5),
    fontSize: '12px',
  },
  documentDueRed: {
    color: theme.palette.danger.dark,
  },
  documentDueOrange: {
    color: theme.palette.warning.dark,
  },
  dueWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },

  warningText: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
}));

type TaskDateTextProps = {
  task: TaskInfo;
};

const TaskDateText: React.FC<TaskDateTextProps> = (props) => {
  const { task } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const status = task.delegationState;
  const deadline = task.due;

  if (status === TaskInfoDelegationState.Revoked) {
    return (
      <div className={classes.root}>
        <Typography className={classes.text}>{t(`tasks.status.${status}`)}</Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>{t(`tasks.status.${status}`)}</Typography>
      <Typography className={classes.interpunctRightSpacing}>:</Typography>
      {/* <Typography className={classes.text}>{task.description}</Typography>
      <Typography className={classes.interpunct}>‧</Typography> */}
      <DeadlineText deadline={deadline} />
    </div>
  );
};

type DateRemainingProps = {
  deadline: string | null | undefined;
};

const DeadlineText: React.FC<DateRemainingProps> = (props) => {
  const { deadline } = props;
  const classes = useStyles();

  if (!deadline) {
    return null;
  }

  return <Typography className={classes.text}>{formatDate(deadline)}</Typography>;
};

export default TaskDateText;
