import { Button } from '@mui/material';
import { styled } from '@mui/styles';
import DefaultDropDownButton from 'components/DropdownButton';
import { DeleteOutline as MUIDeleteOutline } from '@mui/icons-material';

export const DropDownButton = styled(DefaultDropDownButton)(({ theme }) => ({
  padding: theme.spacing(1, 0),
}));
export const DeleteButton = styled(Button)(({ theme }) => ({
  width: 'fit-content',
  color: theme.palette.danger.dark,
}));
export const DeleteOutline = styled(MUIDeleteOutline)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));
