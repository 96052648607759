import { FC, ReactNode } from 'react';
import { FormField } from './FormField';
import { Grid } from '@mui/material';
import TextArea, { TextAreaFieldProps } from 'lib/TextArea/TextArea';

interface FreeTextAreaQuestionProps extends TextAreaFieldProps {
  toolTip?: ReactNode;
}

const FreeTextAreaQuestion: FC<FreeTextAreaQuestionProps> = ({ toolTip, ...props }) => {
  return (
    <FormField
      open
      label={props.label}
      tooltip={toolTip}
      required={props.required}
      error={props.error}
    >
      <Grid item container xs={12} lg={9} xl={6}>
        <TextArea rows={4} {...props} />
      </Grid>
    </FormField>
  );
};

export default FreeTextAreaQuestion;
