import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { TaskInfoDelegationState } from '__generated__/api-di';

const iconMissing = require('../../../assets/images/task-item/missing.svg');
const iconValid = require('../../../assets/images/task-item/valid.svg');

const useStyles = makeStyles((theme) => ({
  root: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
  },
}));

type TaskStatusIconProps = {
  status: TaskInfoDelegationState | undefined;
};

const TaskStatusIcon: React.FC<TaskStatusIconProps> = (props) => {
  const classes = useStyles();

  const icon = getIcon(props.status);

  return <img className={classes.root} src={icon} alt="" />;
};

function getIcon(status: TaskInfoDelegationState | undefined) {
  switch (status) {
    case TaskInfoDelegationState.Pending:
    case TaskInfoDelegationState.InProgress:
      return iconMissing.default ?? iconMissing;
    case TaskInfoDelegationState.Completed:
      return iconValid.default ?? iconValid;
    case TaskInfoDelegationState.Revoked:
    default:
      return iconMissing.default ?? iconMissing;
  }
}

export default TaskStatusIcon;
