import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { Client, ClientLoan } from 'types/Client';
import { getCustomerNames } from 'types/Client';
import { ChevronRight } from '@mui/icons-material';
import ClientDataConfirmationDialog from './ClientDataConfirmationDialog';
import { submitConsentRequest, optimisticUpdateClientConsent } from 'use/clientConsent';
import { useGroup } from 'use/group';
import { ConsentStatus } from '__generated__/api-di';
import { useClients } from 'screens/Clients/hooks/useClients';
import ClientDataSharingIcon from '../ClientDataSharingIcon/ClientDataSharingIcon';
import { useGoogleTagManager } from 'use/gtm';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    height: 24,
  },
  arrow: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(-1),
  },
}));

export type DisplayClientDataSharingProps = {
  client: Client;
  loan: ClientLoan;
};

const DisplayClientDataSharing: FC<DisplayClientDataSharingProps> = ({ client, loan }) => {
  const group = useGroup();
  const classes = useStyles();
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [status, setStatus] = useState(loan.consentStatus);
  const [showSnackbar, setShowSnackbar] = useState<null | 'error' | 'success' | 'badRequest'>(null);
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const clientName = getCustomerNames(client);
  const { queryKey } = useClients();
  const googleTagManager = useGoogleTagManager();
  const severity = showSnackbar === 'success' ? 'success' : 'error';

  if (!status) {
    return null;
  }
  const handleClose = () => {
    setDialogOpen(false);
    setSnackbarOpen(false);
  };

  async function handleConfirm() {
    submitConsentRequest(group, loan)
      .then((result) => {
        googleTagManager({
          event: 'buttonClick',
          btnName: 'RequestClientDataShare',
          moneylenderName: loan.moneylenderName,
        });
        setShowSnackbar(result);
        setStatus(ConsentStatus.Requested);
        optimisticUpdateClientConsent(queryKey, loan);
      })
      .then(() => {
        setSnackbarOpen(true);
        setDialogOpen(false);
      });
  }
  return (
    <>
      <ClientDataSharingIcon status={status} />
      <span
        onClick={(e: any) => {
          // don't bubble clickevent
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {(status === undefined || status === ConsentStatus.NotFound) && (
          <Button
            variant="text"
            onClick={() => {
              setDialogOpen(true);
            }}
            className={classes.link}
            data-testid="clientdata-request-button"
          >
            {t('clients.dataSharing.requestDataSharing')}
            <ChevronRight className={classes.arrow} />
          </Button>
        )}

        <ClientDataConfirmationDialog
          open={dialogOpen}
          client={client}
          loan={loan}
          handleClose={handleClose}
          onConfirm={handleConfirm}
        />

        <CustomSnackbar
          verticalPosition="top"
          isOpen={isSnackbarOpen}
          handleClose={handleClose}
          testid={`alert-${showSnackbar}`}
          severity={severity}
          message={t(`clients.dataSharing.${showSnackbar}`, { clientName })}
        />
      </span>
    </>
  );
};

export default DisplayClientDataSharing;
