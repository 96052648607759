import { AxiosResponse } from 'axios';
import { ApplicationReadDto } from '__generated__/api';
import { HEADER_SELECTED_GROUP_CLAIM, http } from '../http';

export async function getApplicationRequestDetails(
  id: number,
  group: string | null
): Promise<ApplicationReadDto> {
  const url = `/api/applicationregistration/${id}`;
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const response = await http.get(url, { headers });
  return response.data;
}

export async function updateApplicationDetails(group: string | null, formData: any) {
  try {
    const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
    const response = await http({
      url: '/api/applicationregistration',
      method: 'POST',
      headers: headers,
      data: JSON.stringify(formData),
    });
    return { data: response.data, status: 200 };
  } catch (err: any) {
    const {
      response: { status },
    } = err;
    return { status: status };
  }
}

export async function applicationRegistration(group: string | null, formData: any) {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const response: AxiosResponse<ApplicationReadDto> = await http({
    url: '/api/applicationregistration',
    method: 'POST',
    headers: headers,
    data: JSON.stringify(formData),
  });
  return response;
}

export async function sendAsrApplication(formData: any, group: string) {
  const url = '/api/applicationregistration/submit';
  try {
    const response = await http.post<{}>(url, JSON.stringify(formData), {
      headers: {
        Accept: '*/*',
        [HEADER_SELECTED_GROUP_CLAIM]: group,
      },
    });
    return { data: response.data, status: 200 };
  } catch (err: any) {
    const {
      response: { status },
    } = err;
    return { status: status };
  }
}

export async function validateNewBtlApplication(
  newBtlApplication: ApplicationReadDto,
  group: string | null
) {
  const url = '/api/applicationregistration/validate';

  try {
    const response = await http.post(url, JSON.stringify(newBtlApplication), {
      headers: {
        Accept: '*/*',
        [HEADER_SELECTED_GROUP_CLAIM]: group,
        AuthorizedCommercialLabels: 25,
      },
    });
    return { data: response.data, status: 200 };
  } catch (err: any) {
    const {
      response: { status },
    } = err;
    return { status: status };
  }
}

export const deleteApplication = async (id: number | undefined, group: string | null) => {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const response = await http({
    url: `/api/applicationregistration/${id}`,
    method: 'DELETE',
    headers: headers,
  });
  return { response };
};
