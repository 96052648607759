import React, { FC, useEffect } from 'react';
import {
  Alert,
  AlertTitle,
  Fab,
  Grid,
  Icon,
  Link,
  Step,
  StepConnector,
  StepIcon,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ToggleButtonQuestion from 'screens/BuyToLet/BuyToLetForm/form/ToggleButtonQuestion';
import { QuestionData, WizardData } from 'types/Wizard';
import DropDownQuestion from 'screens/BuyToLet/BuyToLetForm/form/DropDownQuestion';
import nibcLogo from 'assets/images/nibc.png';
import { Trans, useTranslation } from 'react-i18next';
import { FormField } from 'screens/BuyToLet/BuyToLetForm/form/FormField';
import CheckBoxQuestion from 'screens/BuyToLet/BuyToLetForm/form/CheckBoxQuestion';
import FreeTextAreaQuestion from 'screens/BuyToLet/BuyToLetForm/form/FreeTextAreaQuestion';
import { useQueryBuyToLetConfiguration, useValidateBuyToLetOrientation } from 'use/buyToLet';
import { QuestionState } from '../BuyToLet';
import { Answer, FinalStatus, Question, QuestionType } from '__generated__/api';
import LoadingIndicator from 'components/LoadingIndicator';
import ApplicationModal from './ApplicationModal';
import { useGoogleTagManager } from 'use/gtm';

const useStyles = makeStyles((theme) => ({
  formGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  stepper: {
    padding: theme.spacing(7.5, 4),
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(7.5, 2),
    },
  },
  stepConnector: {
    borderColor: 'transparent',
  },
  stepLabel: {
    'width': 250,
    'height': 40,
    'lineHeight': '40px',
    'padding': theme.spacing(0, 3),
    'borderRadius': theme.shape.borderRadius,
    'color': theme.palette.common.white,
    'fontSize': 14,
    'fontWeight': 300,
    '&.Mui-active': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      fontWeight: 400,
    },
    '&.Mui-completed': {
      color: theme.palette.common.white,
      fontWeight: 300,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 1),
      width: 200,
    },
  },
  bottomNav: {
    position: 'fixed',
    bottom: theme.spacing(3),
  },
  fabLeft: {
    marginLeft: theme.spacing(2),
  },
  fabRight: {
    right: theme.spacing(3),
  },
  fabInline: {
    marginTop: '1em',
  },
  fabIcon: {
    marginLeft: theme.spacing(1),
    transform: 'scale(0.8)',
  },
  description: {
    marginTop: theme.spacing(2),
    color: theme.palette.textBody.main,
  },
}));

type BuyToLetOrientationProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: (orientationData: Map<string, QuestionState>) => void;
  onFailure: () => void;
  wizardState: Map<string, QuestionState>;
  setWizardState: React.Dispatch<React.SetStateAction<Map<string, QuestionState>>>;
};

type AlertState = {
  title?: string;
  children?: React.ReactNode;
};

const BuyToLetOrientation: FC<BuyToLetOrientationProps> = ({
  open,
  onClose,
  onSuccess,
  onFailure,
  wizardState,
  setWizardState,
}) => {
  if (!open) {
    return null;
  }

  return (
    <BuyToLetOrientationWrapped
      open={open}
      onClose={onClose}
      onSuccess={onSuccess}
      onFailure={onFailure}
      wizardState={wizardState}
      setWizardState={setWizardState}
    />
  );
};

type BuyToLetOrientationWrappedProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: (orientationData: Map<string, QuestionState>) => void;
  onFailure: () => void;
  wizardState: Map<string, QuestionState>;
  setWizardState: React.Dispatch<React.SetStateAction<Map<string, QuestionState>>>;
};

const BuyToLetOrientationWrapped: FC<BuyToLetOrientationWrappedProps> = ({
  open,
  onClose,
  onSuccess,
  onFailure,
  wizardState,
  setWizardState,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showButtonToLegacyFlow, setShowButtonToLegacyFlow] = React.useState(false);
  const [showButtonToBtlFlow, setShowButtonToBtlFlow] = React.useState(false);
  const [alert, setAlert] = React.useState<AlertState>();
  const questionData = useQueryBuyToLetConfiguration();
  const { mutateAsync } = useValidateBuyToLetOrientation();
  const googleTagManager = useGoogleTagManager();

  useEffect(() => {
    if (open) {
      googleTagManager({
        event: 'test',
        funnel: 'Aanvraag',
        step: '1',
      });
    }
  }, [open, googleTagManager]);

  useEffect(() => {
    const initialState: Map<string, QuestionState> = new Map();
    const questions = questionData?.data?.questions;

    if (questions) {
      questions.forEach((question) => {
        initialState.set(question.id, {
          visible: question.id === questionData?.data?.entryQuestionId,
          hiddenInHyarchis: question.hiddenInHyarchis,
        });
      });
    }
    setWizardState(initialState);
    setShowButtonToLegacyFlow(false);
    setShowButtonToBtlFlow(false);
    setAlert(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, questionData.status]);

  function toQuestionAndAnswerText(
    question?: Question,
    answer?: string,
    selectedAnswers?: Array<Answer>
  ) {
    let questionText: string | undefined;
    let answerText: string | undefined;
    const hiddenInHyarchis = question?.hiddenInHyarchis;

    switch (question?.type) {
      case QuestionType.Checkbox:
        questionText = selectedAnswers?.map((a) => t(`${a.answerTextKey}`)).join(' | ');
        answerText = (selectedAnswers?.length ?? 0) > 0 ? 'Ja' : 'Nee';
        break;
      case QuestionType.Dropdown:
        questionText = question?.questionTextKey ? t(`${question.questionTextKey}`) : '';
        answerText = selectedAnswers?.map((a) => t(`${a.answerTextKey}`)).join(' | ');
        break;
      case QuestionType.Radio:
        questionText = selectedAnswers?.map((a) => t(`${a.answerTextKey}`)).join(' | ');
        answerText = (selectedAnswers?.length ?? 0) > 0 ? 'Ja' : 'Nee';
        break;
      case QuestionType.Text:
        questionText = question?.questionTextKey
          ? t(`${question.questionTextKey}`)
          : question?.placeholderKey
          ? t(`${question?.placeholderKey}`)
          : '';
        answerText = answer;
        break;
      case QuestionType.Toggle:
        questionText = question?.questionTextKey ? t(`${question.questionTextKey}`) : '';
        answerText = selectedAnswers?.map((a) => t(`${a.answerTextKey}`)).join(' | ');
        break;
      default:
        questionText = '';
        answerText = '';
        break;
    }

    return {
      questionText,
      hiddenInHyarchis,
      answerText,
    };
  }

  function handleChange(questionId: string, event: any, answerIds?: string[], answer?: string) {
    const newState = new Map(wizardState);
    // Clear all following questions
    Array.from(newState.keys())
      .splice(Array.from(newState.keys()).findIndex((k) => k === questionId) + 1)
      .forEach((id) => {
        newState.set(id, { visible: false });
      });
    setShowButtonToLegacyFlow(false);
    setShowButtonToBtlFlow(false);
    setAlert(undefined);

    const question = questionData.data?.questions.find((q) => q.id === questionId);
    const selectedAnswers = question?.possibleAnswers?.filter((a) =>
      (answerIds ?? []).some((s) => s === a.id)
    );

    const questionAndAnswerText = toQuestionAndAnswerText(question, answer, selectedAnswers);
    const newQuestionState: QuestionState = {
      ...newState.get(questionId),
      answerIds: answerIds,
      answer: answer,
      ...questionAndAnswerText,
    };
    newState.set(questionId, newQuestionState);

    for (const selectedAnswer of selectedAnswers ?? []) {
      if (selectedAnswer?.action?.errorMessageKey) {
        // errormessage
        setAlert({
          children: (
            <>
              <Trans
                i18nKey={selectedAnswer.action.errorMessageKey}
                components={[
                  <Link
                    href={t('wizard.btl.orientation.nibcLink')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    0
                  </Link>,
                ]}
              />
            </>
          ),
        });

        break;
      }

      if (selectedAnswer?.action?.nextQuestionId) {
        // Next question
        newState.set(selectedAnswer?.action?.nextQuestionId, { visible: true });
        break;
      }

      if (selectedAnswer?.action?.finalStatus === FinalStatus.StartUpload) {
        // Show button to the upload flow
        setShowButtonToLegacyFlow(true);
        break;
      }

      if (selectedAnswer?.action?.finalStatus === FinalStatus.StartBTLRequestWorkflow) {
        // Show button to the upload flow
        validate(newState);
        break;
      }
    }

    setWizardState(newState);
  }

  function handleSuccess() {
    onSuccess(wizardState);
  }

  function validate(state: Map<string, QuestionState>) {
    const wizardData: WizardData = {
      answers: Array.from(state.entries()).map(([id, question]) => {
        const data: QuestionData = { questionId: id, ...question };
        return data;
      }),
    };
    mutateAsync(wizardData).then((response) => {
      if (response.status === 200 && response.data?.finalStatus === 'StartBTLRequestWorkflow') {
        setShowButtonToBtlFlow(true);
      } else {
        setAlert({ children: t('wizard.btl.orientation.validationError') });
      }
    });
  }

  function getQuestionComponent(id: string): JSX.Element | null {
    const questionState = wizardState.get(id);
    const question = questionData.data!.questions.find((q) => q.id === id);

    if (!questionState?.visible) {
      return null;
    }

    switch (question?.type) {
      case 'Toggle':
        return (
          <ToggleButtonQuestion
            key={id}
            label={question.questionTextKey && t(`${question.questionTextKey}`)}
            toolTip={question.questionInfoTextKey && t(`${question.questionInfoTextKey}`)}
            answerOptions={question.possibleAnswers!.map((a) => ({
              id: a.id,
              answerTextKey: a.answerTextKey ?? undefined,
            }))}
            exclusive
            required={question.required}
            value={(questionState?.answerIds ?? [])[0]}
            onChange={(e, value) => handleChange(id, e, [value])}
          />
        );
      case 'Dropdown':
        return (
          <DropDownQuestion
            key={id}
            name={id}
            label={question.questionTextKey && t(`${question.questionTextKey}`)}
            placeholder={question.placeholderKey ? t(`${question.placeholderKey}`) : undefined}
            toolTip={
              question.questionInfoTextKey ? t(`${question.questionInfoTextKey}`) : undefined
            }
            answerOptions={question.possibleAnswers!.map((a) => ({
              id: a.id,
              answerTextKey: a.answerTextKey ?? undefined,
            }))}
            required={question.required}
            value={(questionState?.answerIds ?? [])[0] ?? ''}
            onChange={(e) => handleChange(id, e, [e.target.value as string], undefined)}
          />
        );
      case 'Checkbox':
        return (
          <CheckBoxQuestion
            key={id}
            label={question.questionTextKey && t(`${question.questionTextKey}`)}
            toolTip={question.questionInfoTextKey && t(`${question.questionInfoTextKey}`)}
            answerOptions={question.possibleAnswers!}
            required={question.required}
            value={questionState?.answerIds ?? []}
            onChange={(e, value) => handleChange(id, e, value as string[], undefined)}
          />
        );
      case 'Text':
        return (
          <FreeTextAreaQuestion
            key={id}
            label={question.questionTextKey && t(`${question.questionTextKey}`)}
            toolTip={question.questionInfoTextKey && t(`${question.questionInfoTextKey}`)}
            placeholder={question.placeholderKey ? t(`${question.placeholderKey}`) : undefined}
            required={question.required}
            value={questionState?.answer}
            inputProps={{ maxLength: 600 }}
            onChange={(e) => handleChange(id, e, [id], e.target.value)}
          />
        );
      default:
        return null;
    }
  }

  return (
    <ApplicationModal
      open={open}
      onClose={onClose}
      logo={nibcLogo}
      sidebar={
        <Stepper
          activeStep={0}
          orientation="vertical"
          className={classes.stepper}
          connector={
            <StepConnector
              classes={{
                line: classes.stepConnector,
              }}
            />
          }
        >
          <Step key="orientation">
            <StepLabel
              classes={{
                label: classes.stepLabel,
              }}
              StepIconComponent={StepIcon}
            >
              {t('wizard.btl.orientation.title')}
            </StepLabel>
          </Step>
        </Stepper>
      }
    >
      <Typography variant="h4">{t('wizard.btl.orientation.title')}</Typography>
      <Typography className={classes.description} variant="body1">
        {t('wizard.btl.orientation.description')}
      </Typography>
      {questionData.status === 'loading' ? (
        <LoadingIndicator />
      ) : (
        <form>
          <Grid container spacing={4} className={classes.formGrid}>
            <Grid container item spacing={3}>
              {Array.from(wizardState.keys()).map((id) => getQuestionComponent(id))}
              {alert && (
                <Grid item container xs={12}>
                  <Alert severity="error" style={{ minWidth: '100%' }}>
                    {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
                    {alert.children}
                  </Alert>
                </Grid>
              )}
              {showButtonToLegacyFlow && (
                <FormField open label={t('wizard.btl.orientation.legacy.title')}>
                  <Grid item container xs={12}>
                    <Fab
                      variant="extended"
                      color="primary"
                      size="medium"
                      onClick={onFailure}
                      className={classes.fabInline}
                    >
                      {t('wizard.btl.orientation.legacy.button') as string}
                      <Icon className={classes.fabIcon}>arrow_right</Icon>
                    </Fab>
                  </Grid>
                </FormField>
              )}
              {showButtonToBtlFlow && (
                <FormField open>
                  <Grid item container xs={12}>
                    <Fab
                      variant="extended"
                      color="primary"
                      size="medium"
                      onClick={handleSuccess}
                      className={classes.fabInline}
                    >
                      {t('wizard.btl.orientation.startApplicationBtn') as string}
                      <Icon className={classes.fabIcon}>arrow_right</Icon>
                    </Fab>
                  </Grid>
                </FormField>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    </ApplicationModal>
  );
};

export default BuyToLetOrientation;
