import { AxiosError } from 'axios';

type UploadError = 'responseError' | 'fileNotAllowed' | 'fileEncrypted';

// I'm not a fan of string comparisons to check error states
// Maybe change to error codes in the future?
const mapUploadError = (error: AxiosError<any>): UploadError => {
  if (!error || !error.response) return 'responseError';

  if (JSON.stringify(error.response).indexOf('The file is not allowed') > -1) {
    return 'fileNotAllowed';
  }

  const { data } = error.response;

  // response from buytolet differs from proof-items
  // see: https://jira.stater.nl/browse/STAT-493981
  const file: string[] = data?.Pdf || data?.File;

  if (file && file[0] === 'The file is not allowed') return 'fileNotAllowed';
  if (file && file[0] === 'The file is encrypted') return 'fileEncrypted';

  return 'responseError';
};

export default mapUploadError;
