/**
 * see: lodash isObject
 *
 * Checks if `value` is the
 * [language type](http://www.ecma-international.org/ecma-262/7.0/#sec-ecmascript-language-types)
 * of `Object`. (e.g. arrays, functions, objects, regexes, `new Number(0)`, and `new String('')`)
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is an object, else `false`.
 * @example
 *
 * isObject({})
 * // => true
 *
 * isObject(Function)
 * // => true
 *-
 * isObject(null)
 * // => false
 */
const isObject = (value: any): value is object =>
  value != null && (typeof value === 'object' || typeof value === 'function');

export default isObject;
