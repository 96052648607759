import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3),
    },
  },
  filterButton: {
    'width': 'max-content',
    'height': 32,
    'color': 'rgba(255,255,255)',
    'backgroundColor': theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '& .MuiButton-label': {
      textTransform: 'uppercase',
      fontSize: 11,
    },
    'marginRight': theme.spacing(1),
  },
  activeButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
  },
}));

export default useStyles;
