import { styled } from '@mui/material/styles';
import { Box as MuiBox } from '@mui/material';

const FormWrapper = styled(MuiBox)(({ theme }) => ({
  maxWidth: '864px',
  margin: theme.spacing(0, 0, 0, 42),
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(0, 0, 0, 0),
  },
}));

export default FormWrapper;
