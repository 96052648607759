/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { loanPartDefaultValues } from 'screens/Mutations/components/DismissalJointLiabilityMutation/constants';
import { LoanFormType } from 'screens/Mutations/utils/types';
import { ConfirmationDialog } from 'components/CustomDialog';
import LoanPartAccordion from '../LoanPartAccordion';
import LoanPartHeader from '../LoanPartHeader';
import { isEmptyLoanPart } from '../utils';
import { AddButton, Icon, LoanPartListWrapper } from '../Loan.styled';
import { SubTitle, Description } from 'screens/Mutations/molecules/styled';
import { SelectOption } from 'types/SelectOption';

type LoanPartListProps = {
  fieldSet: 'newLoanParts' | 'currentLoanParts';
  maxLoanPartsAllowed?: number;
  shouldUnregister?: boolean;
  productsData?: SelectOption[];
};

export const LoanPartList: FC<LoanPartListProps> = ({
  fieldSet,
  maxLoanPartsAllowed = 10,
  shouldUnregister = true,
  productsData,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<LoanFormType>();
  const { fields, append, remove } = useFieldArray({ name: fieldSet, control });

  const loanParts = useWatch({ control, name: fieldSet });
  const [selectedLoanPart, setSelectedLoanPart] = useState<number>();
  const [selectedLoanPartId, setSelectedLoanPartId] = useState<string>();
  const [deleteLoanPartDialog, setDeleteLoanPartDialog] = useState(false);
  const [currentFieldAction, setCurrentFieldAction] = useState<string>();
  const [expanded, setExpanded] = useState<string[]>([]);

  useEffect(() => {
    if (currentFieldAction === 'append') {
      const result = fields.filter((field) => !expanded.includes(field.id));

      setExpanded((oldArray) => [...oldArray, result[result.length - 1].id]);

      setCurrentFieldAction(undefined);
    }
  }, [fields]);

  const handleAddLoanPart = useCallback(() => {
    setCurrentFieldAction('append');
    append(loanPartDefaultValues);
  }, []);

  const handleDeleteLoanPart = useCallback((index: number, id: string) => {
    setDeleteLoanPartDialog(true);
    setSelectedLoanPart(index);
    setSelectedLoanPartId(id);
  }, []);

  const handleConfirmationDialog = useCallback(() => {
    remove(selectedLoanPart);
    setExpanded(expanded.filter((item) => item !== selectedLoanPartId));
    setSelectedLoanPart(undefined);
    setSelectedLoanPartId(undefined);
    setDeleteLoanPartDialog(false);
  }, [expanded, selectedLoanPart, remove]);

  const handleExpand = (id: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    if (!expanded.includes(id)) {
      setExpanded((oldArray) => [...oldArray, id]);
    } else {
      setExpanded(expanded.filter((item) => item !== id));
    }
  };

  const handleClose = () => setDeleteLoanPartDialog(false);

  const showButton = () => {
    if (loanParts && loanParts.length <= maxLoanPartsAllowed) {
      return true;
    }
    if (!loanParts) {
      return true;
    }
    return false;
  };

  return (
    <LoanPartListWrapper container>
      <SubTitle>{t(`directMutations.loan.${fieldSet}FormTitle`)}</SubTitle>
      <Description variant="body2">{t(`directMutations.loan.${fieldSet}Description`)}</Description>
      {loanParts && loanParts.length > 0 && <LoanPartHeader />}

      {fields
        .filter((currentLoanPart) => !isEmptyLoanPart(currentLoanPart))
        .map((field, loanPartIndex) => {
          return (
            <LoanPartAccordion
              shouldUnregister={shouldUnregister}
              key={field.id}
              expanded={expanded.includes(field.id)}
              loanPartIndex={loanPartIndex}
              fieldId={field.id}
              fieldSet={fieldSet}
              handleExpand={handleExpand}
              handleDeleteLoanPart={handleDeleteLoanPart}
              productsData={productsData}
            />
          );
        })}

      {showButton() && (
        <Grid item xs={12}>
          <AddButton
            variant="contained"
            color="primary"
            size="medium"
            onClick={handleAddLoanPart}
            data-testid={`add-button-${fieldSet}`}
          >
            {t('directMutations.loan.addLoanPart')}
            <Icon>plus</Icon>
          </AddButton>
        </Grid>
      )}

      <ConfirmationDialog
        isOpen={deleteLoanPartDialog}
        onConfirmation={handleConfirmationDialog}
        onDecline={handleClose}
        onClose={handleClose}
        confirmationText={t('directMutations.buttons.deleteButton')}
        declineText={t('directMutations.buttons.cancelButton')}
        bodyText={t('directMutations.confirmDelete.bodyLoanPart')}
      />
    </LoanPartListWrapper>
  );
};
export default LoanPartList;
