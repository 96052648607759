import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Hidden, Paper, TextField, Button, Icon, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Action, CommentSolution as CommentSolutionData } from '../../../store/taskReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 6,
    display: 'flex',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
  },
  text: {
    flex: 1,
  },
  input: {
    marginTop: 0,
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.danger.main,
    flexDirection: 'column',
  },
}));

interface CommentSolutionProps {
  issueId: string;
  solution: CommentSolutionData;
  dispatch: React.Dispatch<Action>;
}

const CommentSolution: React.FC<CommentSolutionProps> = ({ dispatch, issueId, solution }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'update',
      payload: {
        issueId: issueId,
        data: {
          ...solution,
          data: {
            ...solution.data,
            comment: e.target.value,
          },
        },
      },
    });
  };

  const deleteSolution = () => {
    dispatch({
      type: 'remove',
      payload: {
        issueId: issueId,
        data: solution,
      },
    });
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.text}>
        <TextField
          className={classes.input}
          name="comment"
          required
          fullWidth
          onChange={handleFormData}
          variant="outlined"
          placeholder={t('tasks.solutions.enterCommentPlaceholder')}
          InputLabelProps={{
            shrink: true,
            classes: { root: 'formLabel' },
          }}
          value={solution.data.comment}
        />
      </div>
      <Hidden mdUp>
        <IconButton
          className={classes.deleteButton}
          onClick={(e) => {
            e.stopPropagation();
            deleteSolution();
          }}
          size="large"
        >
          <Icon>trash</Icon>
        </IconButton>
      </Hidden>
      <Hidden mdDown>
        <Button
          className={classes.deleteButton}
          endIcon={<Icon className={classes.btnIcon}>trash</Icon>}
          variant="text"
          onClick={(e) => {
            e.stopPropagation();
            deleteSolution();
          }}
        >
          {t('tasks.solutions.delete')}
        </Button>
      </Hidden>
    </Paper>
  );
};

export default CommentSolution;
