export default function handleContentFileType(type: string) {
  switch (type) {
    case 'application/pdf':
      return 'pdf';
    case 'application/octet-stream':
      return 'txt';
    default:
      return 'pdf';
  }
}
