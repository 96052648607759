import type { FC } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FormTitleText from 'components/Form/FormTitle';
import { RootState } from 'store';
import { LoanPartType, LoanFormType } from 'screens/Mutations/utils/types';
import Policies from './Policies';
import { CustomNumberFormat } from 'components/CustomNumberFormat';
import { formatLoanNumber } from 'utils/formatLoanNumber';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'screens/Mutations/molecules/Accordion/Accordion';
import {
  SubTitle,
  Title,
} from '../../components/DismissalJointLiabilityMutation/components/ConnectedPolicies/ConnectedPolicies.styled';
import { ExpandMore } from '@mui/icons-material';
import { FormGrid } from 'screens/Mutations/components/DismissalJointLiabilityMutation/DismissalJointLiabilityMutation.styled';

type Props = { loanAmount?: number; loanForm: LoanFormType; shouldUnregister?: boolean };

const ConnectedPolicies: FC<Props> = ({ loanAmount, loanForm, shouldUnregister = true }) => {
  const { t } = useTranslation();
  const amount = useSelector((state: RootState) => state.ohaForm.loan.amount);

  return (
    <FormGrid>
      <FormTitleText
        title={t('directMutations.loan.title')}
        description={t('directMutations.loan.subtitle')}
      />
      {loanForm.changeLifeInsurancePolicy && loanForm.changeLifeInsurancePolicy === 'yes' && (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel-life-insurance-content"
          >
            <Grid container>
              <Grid item>
                <Title variant="h5">{t('directMutations.policies.deathInsurance')}</Title>
              </Grid>
              <Grid item>
                <SubTitle color="textSecondary">
                  {t('directMutations.policies.loanAmount')}{' '}
                  <CustomNumberFormat fixedDecimalScale value={loanAmount ?? amount} />
                </SubTitle>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Policies name="policies" shouldUnregister={shouldUnregister} />
          </AccordionDetails>
        </Accordion>
      )}

      <Grid>
        {loanForm.changeCurrentMortgage === 'yes' &&
          loanForm.currentLoanParts.map((loanPart: LoanPartType, i: number) => {
            if (!loanPart.hasConnectedPolicy || loanPart.hasConnectedPolicy === 'no') return null;

            return (
              <Accordion key={loanPart.loanPartNumber} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls={`panel${i}-content`}
                  id={`panel${i}-content`}
                >
                  <Grid container>
                    <Grid item>
                      <Title variant="h5">
                        {`${t('directMutations.form.loanPart')}
                        ${loanPart.loanPartNumber}`}
                      </Title>
                    </Grid>

                    <Grid item xs={5}>
                      <SubTitle color="textSecondary">
                        {t('directMutations.form.loanPartNumber')}{' '}
                        {loanForm.loanNumber && `${formatLoanNumber(loanForm.loanNumber)}.`}
                        {loanPart.loanPartNumber}
                      </SubTitle>
                    </Grid>

                    <Grid item xs={3}>
                      <SubTitle color="textSecondary">
                        {t('directMutations.form.loanType')} {loanPart.repaymentType}
                      </SubTitle>
                    </Grid>

                    <Grid item xs={4}>
                      <SubTitle color="textSecondary">
                        {t('directMutations.policies.loanAmount')}{' '}
                        <CustomNumberFormat fixedDecimalScale value={loanAmount ?? amount} />
                      </SubTitle>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {loanPart.loanPartNumber && (
                    <Policies
                      name={`currentLoanParts.${i}.policies`}
                      shouldUnregister={shouldUnregister}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
      </Grid>
    </FormGrid>
  );
};

export default ConnectedPolicies;
