import { Box, Typography, Chip, Divider, List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
  },
  title: {
    marginRight: theme.spacing(1),
  },
  category: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  title: string;
  label?: string | null;
  children: React.ReactNode;
};

const ProofItemsList: FC<Props> = ({ children, title, label }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.heading}>
        <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
          {label && <Chip size="small" label={label} data-testid="proofItems-documentList" />}
        </Box>
      </div>
      <Divider />
      <List disablePadding>{React.Children.toArray(children)}</List>
    </>
  );
};

export default ProofItemsList;
