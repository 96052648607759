import { styled } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

const Wrapper = styled(Grid)(({ theme }) => ({
  color: theme.palette.text.disabled,
  maxWidth: 800,
}));

const Name = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 'bold',
}));

export { Wrapper, Name };
