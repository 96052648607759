import { FC } from 'react';
import { Container, StyledGrid, Typography } from './CardRow.styled';

type Props = {
  name: string;
  value?: string | boolean | number | null;
  testId?: string;
};

const CardRow: FC<Props> = ({ name, value, testId }) => (
  <Container container data-testid={`summary-${testId}`}>
    <StyledGrid item xs={7}>
      <Typography data-testid="cardRow-heading">{name}</Typography>
    </StyledGrid>
    <StyledGrid item xs={5}>
      <Typography data-testid="cardRow-value">{value || '-'}</Typography>
    </StyledGrid>
  </Container>
);

export default CardRow;
