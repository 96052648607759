import { styled } from '@mui/material/styles';
import { Box as MuiBox } from '@mui/material';
import backgroundImageStater from 'assets/images/half.png';

const Sidebar = styled(MuiBox)(({ theme }) => ({
  'backgroundColor': theme.palette.purple.dark,
  'backgroundImage': `url("${backgroundImageStater}")`,
  'backgroundRepeat': 'no-repeat',
  'backgroundPosition': '0 70px',
  'overflow': 'auto',
  '&::-webkit-scrollbar': {
    width: 18,
    height: 18,
  },
  '&::-webkit-scrollbar-corner': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    border: '6px solid transparent',
    background: 'rgba(255,255,255,.2)',
    borderRadius: '10px',
    backgroundClip: 'padding-box',
  },
}));

export { Sidebar };
