import { PersonName } from '__generated__/api';
import { CaseApplicantInfo } from '__generated__/api-di';

const createCustomerSortingName = (applicants?: CaseApplicantInfo[] | PersonName[]) => {
  if (!applicants || !applicants.length) {
    return '';
  }

  if (applicants.length === 1) {
    return (
      `${applicants[0].name ?? ''} ` +
      `${applicants[0].prefix ?? ''} ` +
      `${applicants[0].initials ?? ''}`
    );
  }
  return (
    `${applicants[0].name ?? ''} ` +
    `${applicants[0].prefix ?? ''} ` +
    `${applicants[1].name ?? ''} ` +
    `${applicants[1].prefix ?? ''} ` +
    `${applicants[0].initials ?? ''} ` +
    `${applicants[1].initials ?? ''}`
  );
};

export default createCustomerSortingName;
