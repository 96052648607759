import { useTranslation } from 'react-i18next';
import { LoanInfo } from 'components/MutationDialog/useMutation';
import { ClientLoan } from 'types/Client';
import ClientDetailContentMortgage from './ClientDetailMortgageContent';
import { Typography, Container } from '@mui/material';

export type ClientDetailMortgageListProps = {
  mortgages: ClientLoan[];
  onClickMutate: (mortgageIndex: number, loanInfo: LoanInfo) => void;
};

const ClientDetailMortgageList = ({ mortgages, onClickMutate }: ClientDetailMortgageListProps) => {
  const { t } = useTranslation();

  if (!mortgages.length) {
    return null;
  }

  return (
    <Container disableGutters sx={{ marginTop: '32px' }}>
      <Typography variant="h6" component="div">
        {t('clients.detail.mortgages')}
      </Typography>
      {mortgages.map((mortgage, i) => (
        <ClientDetailContentMortgage
          mortgageIndex={i}
          mortgage={mortgage}
          onClickMutate={onClickMutate}
          key={i}
        />
      ))}
    </Container>
  );
};

export default ClientDetailMortgageList;
