import { ObligationReadDto, TypeOfObligation } from '__generated__/api';
import { Obligation, ObligationFormType } from '../types';
import mapToggleButton from './mapToggleButton';

const mapObligation = ({
  typeOfObligation,
  redemptionTypeDisplayName,
  partialRedemptionByPersonalEquity,
  isObligationByBKR,
  redemptionByNewMortgage,
  principalDebt,
  actualDebt,
  obligationAmount,
  repayableAmount,
  startDate,
  endDate,
  ...rest
}: Obligation): ObligationReadDto => {
  const base = {
    typeOfObligation,
    isNewObligation: mapToggleButton(isObligationByBKR),
    partialRedemptionByPersonalEquity: mapToggleButton(partialRedemptionByPersonalEquity),
    redemptionByNewMortgage: mapToggleButton(redemptionByNewMortgage),
    principalDebt,
    ActualDebt: actualDebt,
    obligationAmount,
    repayableAmount,
  };

  return typeOfObligation === TypeOfObligation.ExistingMortgage
    ? {
        ...base,
        startDate: startDate,
        endDate: endDate,
        redemptionTypeDisplayName: redemptionTypeDisplayName,
        ...rest,
      }
    : base;
};

const mapObligations = ({ obligations }: ObligationFormType) => {
  if (obligations.length === 0) return [];

  return obligations.map(mapObligation);
};

export default mapObligations;
