import { styled } from '@mui/styles';
import { Box } from '@mui/material';

export const HeaderBox = styled(Box)(({ theme }) => ({
  minHeight: 108,
}));

export const LogoBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: '4px',
  padding: '3px',
  marginRight: '16px',
  lineHeight: '0',
}));
