import { CardSection, CardRow } from 'components/SummaryCard';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactDetailsFormType } from 'screens/Mutations/utils/types';
import * as i18nIsoCountries from 'i18n-iso-countries';

type Props = {
  contactDetails: ContactDetailsFormType;
};
const ContactDetailsSummary: FC<Props> = ({ contactDetails }) => {
  const { t } = useTranslation();
  const { street, houseNumber, houseNumberSuffix, postalCode, city, country, phoneNumber, email } =
    contactDetails;

  return (
    <CardSection>
      <CardRow name={t('directMutations.contactDetails.street')} value={street} />
      <CardRow name={t('directMutations.contactDetails.houseNumber')} value={houseNumber} />
      <CardRow
        name={t('directMutations.contactDetails.houseNumberSuffix')}
        value={houseNumberSuffix}
      />
      <CardRow name={t('directMutations.contactDetails.postalCode')} value={postalCode} />
      <CardRow name={t('directMutations.contactDetails.city')} value={city} />
      <CardRow
        name={t('directMutations.contactDetails.country')}
        value={country && i18nIsoCountries.getName(country, 'nl')}
      />
      <CardRow name={t('directMutations.contactDetails.phoneNumber')} value={phoneNumber} />
      <CardRow name={t('directMutations.contactDetails.email')} value={email} />
    </CardSection>
  );
};
export default ContactDetailsSummary;
