import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { formatAddressWithPerson } from 'utils/formatAddress';
import { InfoItemText } from 'screens/Clients/Clients.styled';
import { ClientDetailsModel } from '../../../../types/ClientDetailsModel';
import formatFullName from 'utils/formatFullName/formatFullName';

export type ClientDataHeaderProps = {
  clientData: ClientDetailsModel;
};

const ClientDataHeader: FC<ClientDataHeaderProps> = ({ clientData }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      {clientData.persons?.map((person, index) => {
        const fullName = formatFullName(
          person.natural?.name?.initials,
          person.natural?.name?.lastNamePrefix,
          person.natural?.name?.lastName
        );

        return (
          <Grid item xs={12} md={6} key={`person${index}`} data-testid={`client.${index}`}>
            <Grid item xs={12} my={1}>
              <Typography variant="subtitle2">{fullName}</Typography>
            </Grid>
            <Grid container>
              <Grid item md={6} xs={12} my={1}>
                <Typography variant="body2" color="textSecondary">
                  {t('clients.detail.phone')}
                </Typography>
                <InfoItemText>{person.contact?.phoneNumber}</InfoItemText>
              </Grid>
              <Grid item md={6} xs={12} my={1}>
                <Typography variant="body2" color="textSecondary">
                  {t('clients.detail.email')}
                </Typography>
                <InfoItemText>{person.contact?.emailAddress}</InfoItemText>
              </Grid>
            </Grid>
            {index === 0 && (
              <Grid item md={12} xs={12} my={1}>
                <Typography variant="body2" color="textSecondary">
                  {t('clients.detail.address')}
                </Typography>
                <InfoItemText>{formatAddressWithPerson(person) ?? '-'}</InfoItemText>
              </Grid>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ClientDataHeader;
