import { addDays, isValid } from 'date-fns';
import defaultDate from './defaultDate';

const errorMessage = 'De einddatum moet na de startdatum komen.';

const endDate = defaultDate.when('startDate', (startDate, schema) =>
  startDate && isValid(startDate) ? schema.min(addDays(startDate, 1), errorMessage) : schema
);

export default endDate;
