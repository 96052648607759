import { FC } from 'react';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import formatDate from 'utils/formatDate';
import { Amount } from '../Amount/Amount';
import { PercentageDisplay } from '../PercentageDisplay/PercentageDisplay';
import DurationLabel from '../DurationLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dl: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.background.default,
      margin: theme.spacing(1, 0),
      padding: theme.spacing(1, 1),
    },
    dt: {
      minWidth: '34%',
    },
    dd: {
      textAlign: 'right',
      margin: 0,
      minWidth: '34%',
    },
  })
);

const displayValue = (
  value: any,
  type?: 'string' | 'currency' | 'date' | 'percentage' | 'duration'
) => {
  switch (type) {
    case 'currency':
      return <Amount value={value} variant="label" />;
    case 'percentage':
      return <PercentageDisplay value={value} emptyValue="-" />;
    case 'duration':
      return <DurationLabel months={value} />;
    case 'date':
      return <>{formatDate(value, '-')}</>;
    default:
      return value ? <>{value}</> : <>-</>;
  }
};

export type DetailsRowProps = {
  label: string;
  value?: any;
  type?: 'string' | 'currency' | 'date' | 'percentage' | 'duration';
  children?: React.ReactNode;
};

export const DetailsRow: FC<DetailsRowProps> = ({ label, value, type, children }) => {
  const classes = useStyles();

  return (
    <dl className={classes.dl} data-testid="client-details-column">
      <dt className={classes.dt}>{label}</dt>
      <dd className={classes.dd}>{children || displayValue(value, type)}</dd>
    </dl>
  );
};
