import { FC, ReactNode } from 'react';
import {
  FormControl,
  FormLabel,
  Grid,
  Fade,
  Tooltip,
  FormControlProps,
  FormHelperText,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 3,
    padding: '1em',
    color: theme.palette.text.primary,
    fontSize: '1em',
    lineHeight: 'inherit',
    boxShadow: '0 6px 12px 0 rgba(22,20,47,0.12)',
  },
  icon: {
    height: '16px',
    color: theme.palette.primary.main,
  },
}));

export interface FormFieldProps extends FormControlProps {
  open: boolean;
  label?: ReactNode;
  tooltip?: ReactNode;
  helperText?: ReactNode;
}

export const FormField: FC<FormFieldProps> = ({
  open,
  label,
  tooltip,
  children,
  helperText,
  ...props
}) => {
  const classes = useStyles();
  return (
    <>
      {open && (
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth {...props}>
            {label && (
              <FormLabel className="formLabel">
                {label}
                {tooltip && (
                  <Tooltip
                    title={tooltip}
                    placement="right"
                    TransitionComponent={Fade}
                    classes={{ tooltip: `${classes.tooltip}` }}
                  >
                    <InfoIcon className={classes.icon} />
                  </Tooltip>
                )}
              </FormLabel>
            )}
            <Grid item xs={12}>
              {children}
            </Grid>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        </Grid>
      )}
    </>
  );
};
