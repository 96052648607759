import { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'block',
  },
  outer: {
    width: 200,
    height: 20,
    border: '1px solid #888',
    borderRadius: 10,
    padding: '2px 2px',
    display: 'inline-block',
  },
  inner: {
    backgroundColor: theme.palette.success.main,
    minWidth: 16,
    height: '100%',
    borderRadius: 8,
    transition: 'width 0.1s ease',
  },
}));

type Props = {
  percentage: number;
};

const ProgressBar: FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.outer}>
        <div className={classes.inner} style={{ width: `${props.percentage}%` }} />
      </div>
    </div>
  );
};

export default ProgressBar;
