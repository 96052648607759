import { object, string } from 'yup';
import { kvkNumber } from 'utils/form/validations';

const searchOfficeSchema = object().shape(
  {
    namePart: string().when('kvkNumber', {
      is: (kvkNumber: string) => !kvkNumber || !kvkNumber.length,
      then: string().required('BOTH_EMPTY'),
      otherwise: string(),
    }),
    kvkNumber: kvkNumber().when('namePart', {
      is: (namePart: string) => !namePart || !namePart.length,
      then: string().required('BOTH_EMPTY'),
      otherwise: string(),
    }),
  },
  // this array prevents a circular reference
  [['namePart', 'kvkNumber']]
);

export default searchOfficeSchema;
