import { CardRow, CardSection } from 'components/SummaryCard';
import { t } from 'i18next';
import { LoanPartType } from 'screens/Mutations/utils/types';
import { formatYesNo } from 'utils/formatYesNo/formatYesNo';

export const renderConvertLoan = (part: LoanPartType) => (
  <CardSection title={t('directMutations.header.title')}>
    <CardRow
      name={t('directMutations.form.newRepaymentType')}
      value={part.convertLoan?.repaymentType}
    />
    <CardRow
      name={t('directMutations.loanParts.fixedRatePeriod')}
      value={part.convertLoan?.fixedRatePeriod}
    />
    <CardRow
      name={t('directMutations.summary.loanParts.nhg')}
      value={formatYesNo(part.convertLoan?.NHGGuarantee)}
    />
  </CardSection>
);
