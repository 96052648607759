import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from 'components/Menu';
import Item from 'components/Menu/Item';
import { useWatch } from 'react-hook-form';
import { hasConnectedPolicies } from '../../utils';
import { StepNames } from '../../types';

type FormStepperProps = {
  activeStep: StepNames;
};
const FormStepper: FC<FormStepperProps> = ({ activeStep }) => {
  const { t } = useTranslation();
  const loanParts = useWatch({ name: 'loanParts' });
  const isConnectedPolice = hasConnectedPolicies(loanParts);

  return (
    <Menu data-testid="conversion-menu">
      <Item
        text={t('directMutations.stepper.loanDetails')}
        active={activeStep === 'loanDetails'}
        data-testid="loanDetails"
      />
      {isConnectedPolice && (
        <Item
          text={t('directMutations.stepper.policies')}
          active={activeStep === 'policies'}
          data-testid="policies"
        />
      )}
      <Item
        text={t('directMutations.stepper.additionalInfo')}
        active={activeStep === 'additionalInformation'}
      />

      <Item text={t('directMutations.stepper.summary')} active={activeStep === 'summary'} />
    </Menu>
  );
};
export default FormStepper;
