import { AxiosError } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useClients } from 'screens/Clients/hooks/useClients';
import { RootState } from 'store';
import { setRequestCsvButtonDisabled } from 'store/appReducer';
import useMutateRequestCsv from 'screens/Clients/hooks/useMutateRequestCsv';
import { HeaderContainedButton } from 'screens/Clients/Clients.styled';

type ButtonProps = {
  setSnackbar: ({ status, name }: { status: string; name: string }) => void;
};

const CsvRequestButton: FC<ButtonProps> = ({ setSnackbar }) => {
  const { t } = useTranslation();
  const { isExportAvailable } = useClients();
  const dispatch = useDispatch();
  const isButtonDisabled = useSelector((state: RootState) => state.app.requestCsvButtonDisabled);

  const onRequest = () => {
    requestCsv();
  };

  const onSuccessRequestCsv = () => {
    setSnackbar({ status: 'success', name: 'requestCsv' });
    dispatch(setRequestCsvButtonDisabled(true));
  };

  const onErrorRequestCsv = (error: AxiosError) => {
    if (error?.response?.status === 409) {
      setSnackbar({ status: 'error-409', name: 'requestCsv' });
      dispatch(setRequestCsvButtonDisabled(true));
    } else {
      setSnackbar({ status: 'error', name: 'requestCsv' });
    }
  };

  const { mutate: requestCsv, isLoading: isLoadingRequestCsv } = useMutateRequestCsv(
    onSuccessRequestCsv,
    onErrorRequestCsv
  );

  return (
    <>
      {isExportAvailable && (
        <HeaderContainedButton
          size="small"
          variant="contained"
          disableElevation
          color="primary"
          disabled={isButtonDisabled}
          onClick={onRequest}
        >
          {isLoadingRequestCsv ? t('clients.header.loading') : t('clients.header.downloadCsv')}
        </HeaderContainedButton>
      )}
    </>
  );
};
export default CsvRequestButton;
