import { styled } from '@mui/styles';
import { Container as MuiContainer } from '@mui/material';

const Container = styled(MuiContainer)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
}));

export { Container };
