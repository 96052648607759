import { useState, FC } from 'react';

import { useTranslation } from 'react-i18next';
import {
  handleSilentRenewError,
  handleAccessTokenExpired,
  addSilentRenewError,
  addAccessTokenExpired,
} from '../../auth/oidc';
import NotificationDialog from '../CustomDialog/NotificationDialog';

const TokenExpireNotification: FC = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [reason, setReason] = useState('');

  addSilentRenewError(() => {
    setReason('silentRenew');
    setVisible(true);
  });

  addAccessTokenExpired(() => {
    setReason('tokenExpired');
    setVisible(true);
  });

  const handleClose = () => {
    setVisible(false);
    if (reason === 'tokenExpired') {
      handleAccessTokenExpired();
    } else {
      handleSilentRenewError();
    }
  };

  return (
    <NotificationDialog
      bodyText={t('offices.tokenExpiredNotification')}
      buttonText={t('offices.tokenExpiredNotificationButton')}
      isOpen={visible}
      onClose={handleClose}
    />
  );
};

export default TokenExpireNotification;
