import { FC } from 'react';
import { HeaderAppBar } from './HeaderApplication.styled';

export type Props = {
  height?: number;
  ispopup?: boolean;
  children: React.ReactNode;
};

const HeaderApplication: FC<Props> = ({ height, ispopup, children }) => {
  return (
    <HeaderAppBar position="fixed" elevation={1} height={height} ispopup={ispopup}>
      {children}
    </HeaderAppBar>
  );
};

export default HeaderApplication;
