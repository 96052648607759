import React from 'react';
import { Grid } from '@mui/material';
import { FormInputCurrency } from 'components/Form';
import { useTranslation } from 'react-i18next';

export default function Subform() {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12}>
        <FormInputCurrency
          name="collateral.renovationAmount"
          label={t('directMutations.collateral.renovationAmount')}
          placeholder={t('directMutations.amountPlaceholder')}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <FormInputCurrency
          name="collateral.EBVAmounts"
          label={t('directMutations.collateral.EBVAmount')}
          placeholder={t('directMutations.amountPlaceholder')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputCurrency
          name="collateral.growMarketValue"
          label={t('directMutations.collateral.growMarketValue')}
          placeholder={t('directMutations.amountPlaceholder')}
          required
        />
      </Grid>
    </>
  );
}
