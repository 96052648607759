import { FC } from 'react';

import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Done } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  status: {
    'padding': '4px 8px',
    'fontSize': '10px',
    'letterSpacing': '0.5px',
    'fontWeight': 'bold',
    'borderRadius': 24,
    'height': 36,
    'width': 80,
    'textAlign': 'center',

    '&.done': {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.success.light,
      padding: '4px 8px',
      margin: '10px',
    },
  },
  marginRight: {
    marginRight: '8px',
  },
  text: {
    fontWeight: 'bold',
  },
}));

type Props = {
  status: string;
  label: string;
  variant?: string;
};

const IconTag: FC<Props> = ({ status, label, variant }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={`${classes.status} ${status} `}
    >
      <div className={classes.marginRight}>
        <Done />
      </div>
      <span className={classes.text}>{label}</span>
    </Box>
  );
};

export default IconTag;
