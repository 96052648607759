import { http, HEADER_SELECTED_GROUP_CLAIM } from '../http';
import { BtlUploadApplication } from 'types/BtlUploadApplication';
import mapUploadError from 'utils/upload/mapUploadError';

export async function sendBtlApplication(
  { applicantName, extraInformation, pdf, xlsx }: BtlUploadApplication,
  onSuccess: (result: any) => void,
  onError: (result: any) => void,
  group: string
) {
  const url = '/api/applicationregistration/buytolet';
  const data = new FormData();
  data.append(
    'json',
    JSON.stringify({
      applicantName,
      extraInformation,
    })
  );

  pdf && data.append('pdf', pdf);
  xlsx && data.append('xlxs', xlsx);

  try {
    const response = await http.post(url, data, {
      headers: {
        'Accept': '*/*',
        'Content-Type': 'multipart/form-data',
        [HEADER_SELECTED_GROUP_CLAIM]: group,
      },
    });
    return onSuccess({ data: response.data, status: 200 });
  } catch (err: any) {
    return onError(mapUploadError(err));
  }
}
