import { useQuery } from 'react-query';
import { isEherkenningUserFromToken } from '../auth/oidc';

const isEherkenningUser = async (): Promise<boolean> => {
  const isEherkenning = await isEherkenningUserFromToken();
  return isEherkenning;
};

export function useEherkenningUser() {
  return useQuery(['EherkenningUser'], () => isEherkenningUser());
}
