import { useTranslation } from 'react-i18next';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Divider,
  Fade,
  Grid,
  Hidden,
  IconButton,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ChevronRight, Close } from '@mui/icons-material';
import ClampLines from 'react-clamp-lines';
import { getBgImg, IsMobile } from 'utils/global';
import Video from './Video';
import SubInfoNews from './SubInfoNews';
import backgroundImage from '../../../assets/images/bg.png';
import { useQueryNewsItems } from 'use/newsItems';
import NewsCard from './NewsCard';
import { parseHtml } from 'utils/cms';
import formatDate from 'utils/formatDate';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    position: 'relative',
  },
  card: {
    padding: theme.spacing(0, 3, 8),
    borderRadius: 12,
    marginTop: theme.spacing(-4),
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  category: {
    backgroundColor: theme.palette.grey[200],
    padding: '2px 8px',
    fontSize: '10px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderRadius: 15,
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 12,
    },
  },
  positionCategory: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  pageName: {
    padding: '4px 0px',
    fontSize: '10px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.palette.grey[400],
    margin: theme.spacing(2, 0, 2, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeBtn: {
    marginTop: theme.spacing(2),
    opacity: 0.6,
    alignSelf: 'flex-end',
  },
  closeBtnMobile: {
    position: 'absolute',
    backgroundColor: 'rgb(255,255,255,0.75)',
    borderRadius: 20,
    padding: 5,
    fontSize: '0.5em',
    top: theme.spacing(2),
    right: theme.spacing(2),
    opacity: 0.6,
    alignSelf: 'flex-end',
  },
  iconSmall: {
    width: 16,
    height: 16,
  },
  icon: {
    width: 24,
    height: 24,
  },
  header: {
    position: 'relative',
    [theme.breakpoints.up('xs')]: { height: '100px' },
    [theme.breakpoints.up('sm')]: { height: '200px' },
    [theme.breakpoints.up('md')]: { height: '300px' },
    [theme.breakpoints.up('lg')]: { height: '400px' },
    [theme.breakpoints.up('xl')]: { height: '500px' },
    display: 'flex',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: theme.palette.primary.dark,
    boxShadow: '0 6px 12px 0 rgb(22 20 47 / 12%)',
    backgroundImage: `url("${backgroundImage}")`,
  },
  headerLogo: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  cardLogo: {
    borderRadius: 8,
    marginTop: '-10px',
  },
  gridItem: {
    margin: theme.spacing(0, 3),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 2),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 1),
    },
  },
  flex: {
    display: 'flex',
  },
  fullWidth: {
    width: '100%',
  },
  date: {
    color: 'rgba(0,0,0,0.48)',
  },
  arrow: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    width: 30,
    height: 30,
    margin: theme.spacing(2, 2, 2, 0),
  },
  author: {
    fontWeight: 'bold',
    marginBottom: '-8px',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row-reverse',
  },
  text: {
    color: theme.palette.textBody.main,
    marginBottom: theme.spacing(1),
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '28px',
    marginBottom: theme.spacing(3),
    whiteSpace: 'break-spaces',
  },
  videoModal: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  readArticleBtn: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
  },
  headerName: {
    textAlign: 'center',
    color: theme.palette.white.main,
  },
  placement: {
    textAlign: 'center',
  },
  videoIcon: {
    width: 64,
    height: 64,
  },
  span: {
    display: 'flex',
    alignItems: 'baseline',
    margin: theme.spacing(1, 0),
  },
  title: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(5, 0, 1),
      fontSize: '2em',
    },
  },
  categoryTitle: {
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    marginBottom: theme.spacing(1),
  },
  more: {
    margin: theme.spacing(3, 0),
    width: '100%',
    textTransform: 'uppercase',
    fontSize: '11px',
  },
  categoryCard: {
    height: '150px',
  },
}));

const NewsItemPage = () => {
  const isMobile = IsMobile();
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ slug: string }>();
  const { data: news, isLoading } = useQueryNewsItems();
  const newsItem = news?.find((item) => item.slug === params.slug);

  if (!isLoading && !newsItem) {
    navigate('/news');
    return null;
  }

  const backgroundImage = newsItem?.backgroundImage?.url ?? newsItem?.image?.url ?? getBgImg();

  const sameCategoryItems =
    news?.filter((a) => a.category === newsItem?.category && a.id !== newsItem?.id) ?? [];

  const handleClose = () => {
    navigate('/news');
  };

  return (
    <>
      {newsItem && (
        <>
          <div
            className={`${classes.header} ${newsItem.isLogo ? classes.headerLogo : null}`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
              ...(newsItem.backgroundColor && { backgroundColor: newsItem.backgroundColor }),
            }}
          >
            <div className={classes.placement}>
              {newsItem.video && <Video src={newsItem.video.url!} className={classes.videoIcon} />}
              {!newsItem.backgroundImage && !newsItem.image && !newsItem.video && (
                <>
                  <img src={newsItem.author!.avatar!.url!} alt="author" />
                  <Typography variant="h3" className={classes.headerName}>
                    {newsItem.author?.name}
                  </Typography>
                </>
              )}
              <Hidden smUp>
                <IconButton onClick={handleClose} className={classes.closeBtnMobile} size="large">
                  <Fade in timeout={400} style={{ transitionDelay: '400ms' }}>
                    <Close className={classes.iconSmall} />
                  </Fade>
                </IconButton>
              </Hidden>
            </div>
          </div>
          <Container className={classes.container} maxWidth="md" disableGutters={isMobile}>
            <Paper
              className={`${classes.card} ${newsItem.isLogo ? classes.cardLogo : null}`}
              style={{ zIndex: 1400 }}
            >
              <Hidden smDown>
                <IconButton onClick={handleClose} className={classes.closeBtn} size="large">
                  <Fade in timeout={400} style={{ transitionDelay: '400ms' }}>
                    <Close className={classes.icon} />
                  </Fade>
                </IconButton>
              </Hidden>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={8} className={classes.gridItem}>
                  <Typography variant="h3" className={classes.title}>
                    {newsItem && newsItem.title}
                  </Typography>
                  <span className={classes.flex}>
                    <img
                      src={newsItem.author!.avatar!.url!}
                      alt="author"
                      className={classes.logo}
                    />
                    <div>
                      <p className={classes.author}>
                        {newsItem?.author?.name && `${t('news.by')} ${newsItem.author.name}`}
                      </p>
                      <p className={classes.date}>
                        {newsItem?.dateCreated && formatDate(newsItem.dateCreated)}
                      </p>
                    </div>
                    <div className={classes.positionCategory}>
                      <p className={classes.category}>{newsItem.category}</p>
                    </div>
                  </span>
                  <Box mt={3}>
                    <div className={`${classes.text} ${classes.paragraph}`}>
                      {parseHtml(newsItem.htmlContent!)}
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Divider />
                </Grid>
                {sameCategoryItems.length > 0 && (
                  <Grid item xs={12} md={8} className={`${classes.gridItem} ${classes.fullWidth}`}>
                    <Typography variant="body2" color="textSecondary" className={classes.more}>
                      {t('news.moreFromCategory')}
                    </Typography>

                    <Grid container direction="row" spacing={8}>
                      {sameCategoryItems.slice(0, 2).map((item, index) => {
                        return (
                          <Grid item xs={12} md={6} key={`${item.title}${index}`}>
                            <NewsCard
                              newsItem={item}
                              useThumbnail
                              className={classes.categoryCard}
                            />
                            <SubInfoNews newsItem={item} />
                            <ClampLines
                              text={item.title!}
                              id={item.id!}
                              buttons={false}
                              ellipsis="..."
                              lines={2}
                              className={classes.categoryTitle}
                              innerElement="div"
                            />
                            <ClampLines
                              text={item.introParagraph!}
                              id={item.id!}
                              buttons={false}
                              ellipsis="..."
                              lines={2}
                              className={classes.text}
                              innerElement="div"
                            />
                            <Link
                              className={classes.readArticleBtn}
                              to={`/news/${item.slug}`}
                              component={NavLink}
                            >
                              {t('news.readFullArticle')}
                              <ChevronRight className={classes.arrow} />
                            </Link>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Container>
        </>
      )}
    </>
  );
};

export default NewsItemPage;
