import { errorMessages } from 'screens/Mutations/constants';
import { array, object, string, mixed } from 'yup';
import { SchemaLike } from 'yup/lib/types';
import employmentSchema from './employment/employmentSchema';
import entrepreneurSchema from './entrepreneur/entrepreneurSchema';
import { socialSecuritySchema } from './socialSecurity';
import { pensionSchema } from './pension';
import { TypeOfIncome } from 'screens/Mutations/utils/types';
import { otherIncomeSchema } from './otherIncome';

const isPension = (v: TypeOfIncome) => v === TypeOfIncome.Pension;
const isEntrepreneur = (v: TypeOfIncome) => v === TypeOfIncome.Entrepreneur;
const isEmployment = (v: TypeOfIncome) => v === TypeOfIncome.Employment;
const isSocialSecurity = (v: TypeOfIncome) => v === TypeOfIncome.SocialSecurity;
const isOtherIncome = (v: TypeOfIncome) => v === TypeOfIncome.Other;

const typeOfIncomeSchema = (predicate: (v: TypeOfIncome) => boolean, schema: SchemaLike) =>
  object().when('typeOfIncome', {
    is: predicate,
    then: schema,
  });

export const incomeSchema = object({
  typeOfIncome: string().required(errorMessages.required),
  entrepreneur: typeOfIncomeSchema(isEntrepreneur, entrepreneurSchema),
  employment: typeOfIncomeSchema(isEmployment, employmentSchema),
  socialSecurity: typeOfIncomeSchema(isSocialSecurity, socialSecuritySchema),
  pension: typeOfIncomeSchema(isPension, pensionSchema),
  otherIncome: typeOfIncomeSchema(isOtherIncome, otherIncomeSchema),
});

export const incomesSchema = object({
  incomes: array().of(incomeSchema),
  hasIncome: mixed().test('array', errorMessages.required, (_: any, meta: any) => {
    return meta?.parent?.incomes?.length > 0;
  }),
});
