import { RelatedMoneylenderResponse } from 'api/clients';
import isEmpty from 'utils/predicates/isEmpty';
import { Option } from '../../../Mutations/utils/types';

/**
 *  maps the relatedMoneylenders to options
 * @param relatedMoneylenders
 * @returns Option[]
 */
export const mapRelatedMoneylendersToOptions = (
  relatedMoneylenders?: RelatedMoneylenderResponse[]
): Option[] | [] => {
  if (isEmpty(relatedMoneylenders)) {
    return [];
  }

  const moneyLenders = relatedMoneylenders.map(
    (relatedMoneylender: RelatedMoneylenderResponse) => ({
      label: relatedMoneylender.displayName,
      value: relatedMoneylender.commercialRelation,
    })
  );

  return moneyLenders.sort((a: Option, b: Option) => {
    if (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase()) {
      return -1;
    }
    if (a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase()) {
      return 1;
    }
    return 0;
  });
};
