import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Alert, CircularProgress } from '@mui/material';
import type { ClientLoan } from 'types/Client';
import ClientDataHeader from './ClientDataHeader';
import ClientDataContent from './ClientDataContent';
import { isDataSharingEnabledForLoan } from 'use/clientConsent';
import { ConsentStatus } from '__generated__/api-di';
import { useClientData } from 'use/clientData';
import env from 'config/env';

export type ClientDataItemProps = {
  mortgage: ClientLoan;
  showHeader: boolean;
};

const ClientDataItem: FC<ClientDataItemProps> = ({ mortgage, showHeader }) => {
  const { t } = useTranslation();
  const { consentStatus, loanNumber, commercialLabel } = mortgage;
  const isGranted = consentStatus === ConsentStatus.Granted;
  const isDataSharingEnabled = env.FEATURES.STAT_419521_CLIENT_DATA_SHARING;
  const dataSharingEnabled = Boolean(isDataSharingEnabled && isDataSharingEnabledForLoan(mortgage));
  const { isLoading, isError, data } = useClientData(
    commercialLabel,
    loanNumber,
    dataSharingEnabled && isGranted
  );

  if (!dataSharingEnabled || !isGranted) return null;

  if (isLoading) return <CircularProgress size={30} />;

  if (!data) return null;

  if (isError)
    return <Alert severity="error">{t('clients.clientData.errors.errorFetchingData')}</Alert>;

  return (
    <>
      {showHeader && <ClientDataHeader clientData={data} />}
      <ClientDataContent clientData={data} />
    </>
  );
};

export default ClientDataItem;
