/* eslint-disable prefer-template */
export default function formatFullName(
  initials?: string,
  lastNamePrefix?: string,
  lastName?: string
): string {
  if (!lastName) return '';
  const fullName = `${initials ? initials + ' ' : ''}${lastNamePrefix ? lastNamePrefix + ' ' : ''}${
    lastName ?? ''
  }`;
  return fullName;
}
