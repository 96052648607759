import { styled } from '@mui/styles';
import { Typography, IconButton as MuiIconButton } from '@mui/material';

export const ButtonText = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 1),
}));

export const IconButton = styled(MuiIconButton)({
  hover: { borderRadius: '30px' },
});
