import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HeaderWrapper } from '../../LoanPart.styled';

export default function LoanPartHeader() {
  const { t } = useTranslation();
  return (
    <HeaderWrapper container item xs={12} justifyContent="space-between">
      <Grid xs={2} item>
        <Typography variant="overline">{t('directMutations.form.part')}</Typography>
      </Grid>
      <Grid xs={2} item>
        <Typography variant="overline">{t('directMutations.form.exisitingOrNew')}</Typography>
      </Grid>
      <Grid xs={2} item>
        <Typography variant="overline">{t('directMutations.form.loanAmount')}</Typography>
      </Grid>
      <Grid xs={3} item>
        <Typography variant="overline">{t('directMutations.form.repaymentType')}</Typography>
      </Grid>
      <Grid xs={2} item>
        <Typography variant="overline">
          {t('directMutations.form.AbreviatedNewFixedInterestPeriod')}
        </Typography>
      </Grid>
    </HeaderWrapper>
  );
}
