import type { ClientLoan } from 'types/Client';
import { LoanInfo, useQueryMutationInfo } from 'components/MutationDialog/useMutation';
import { useTranslation } from 'react-i18next';
import { googleTagManager } from 'use/gtm';
import { useCallback } from 'react';
import ClientMortgageItem from './ClientMortgageItem';
import { Alert, CircularProgress } from '@mui/material';

type ClientDetailMortgageContentProps = {
  mortgage: ClientLoan;
  mortgageIndex: number;
  onClickMutate: (mortgageIndex: number, loanInfo: LoanInfo) => void;
};

const ClientDetailMortgageContent = ({
  mortgage,
  mortgageIndex,
  onClickMutate,
}: ClientDetailMortgageContentProps) => {
  const { commercialLabel, loanNumber } = mortgage;
  const { data: loanInfo, isLoading, isError } = useQueryMutationInfo(loanNumber, commercialLabel);
  const { t } = useTranslation();

  const handleMutationClick = useCallback(() => {
    onClickMutate(mortgageIndex, loanInfo as LoanInfo);
    googleTagManager({
      event: 'ButtonClick',
      btnName: 'MutateMortage',
      moneylenderName: mortgage.moneylenderName,
    });
  }, [onClickMutate, mortgageIndex, loanInfo, mortgage.moneylenderName]);

  const handleLinkClick = useCallback(
    (event: any) => {
      event.stopPropagation();
      googleTagManager({
        event: 'ButtonClick',
        btnName: 'AccessToEconsumer',
        moneylenderName: mortgage.moneylenderName,
      });
    },
    [mortgage.moneylenderName]
  );

  if (isLoading) {
    return <CircularProgress size={30} />;
  }

  if (isError || !mortgage) {
    return <Alert severity="error">{t('clients.detail.error')}</Alert>;
  }
  return (
    <ClientMortgageItem
      mortgage={mortgage}
      handleMutationClick={handleMutationClick}
      handleLinkClick={handleLinkClick}
      isMutationsButton={!!loanInfo?.mutations && loanInfo.mutations.length > 0}
    />
  );
};

export default ClientDetailMortgageContent;
