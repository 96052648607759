import { useState, FC, useCallback, useEffect } from 'react';
import { Route, Routes as Switch, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import NavBar from './NavDrawer';
import getRoutes from '../routes';
import NotFound from '../screens/NotFound';
import CookieBar from './CookieBar';
import { useGoogleTagManager, useInitTagManager } from 'use/gtm';
import { checkCookies } from '../utils/global';
import env from '../config/env';
import Feedback from './Feedback/Feedback';
import TokenExpireNotification from './TokenExpireNotification';
import { isLoggedInAsObserver } from 'auth/utils';
import OfficeSelect from './OfficeSelect';
import HasRole from './Role';
import { loginCounter } from 'api/loginCounter';
import { useQueryGroups } from 'use/group';
import { setSelectedGroup } from '../store/appReducer';
import { useDispatch } from 'react-redux';
import CustomSnackbar from './Snackbar/CustomSnackbar';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
  },
}));

const AppBase: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cookiesAccepted = checkCookies();
  const [showCookieBar, setShowCookieBar] = useState(false);
  const [isError, setError] = useState(false);
  const isUserLoggedIn = sessionStorage.getItem('isLoggedIn');
  const closeCookieBar = useCallback(() => setShowCookieBar(false), []);
  const location = useLocation();
  const googleTag = useGoogleTagManager();
  const { isLoading: isGroupsCallLoading, data: groups } = useQueryGroups();

  const selectGroup = useCallback(
    (group: string | null) => dispatch(setSelectedGroup(group ?? '')),
    [dispatch]
  );

  useEffect(() => {
    setError(false);

    if (isGroupsCallLoading) {
      return;
    }

    if (!groups || !groups.length) {
      setError(true);
      return;
    }

    if (!groups?.[0].name) {
      setError(true);
      return;
    }

    selectGroup(groups?.[0].name);
  }, [groups, isGroupsCallLoading, selectGroup]);

  useEffect(() => {
    if (cookiesAccepted !== null) {
      setShowCookieBar(false);
    } else {
      setShowCookieBar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookiesAccepted]);

  useInitTagManager();

  const routes = getRoutes(env);

  const getLoginCounter = async () => {
    try {
      await loginCounter();
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  };

  useEffect(() => {
    if (isLoggedInAsObserver()) {
      googleTag({ event: 'logAsObserver' });
    }
  }, [googleTag]);

  useEffect(() => {
    if (!isUserLoggedIn && !isLoggedInAsObserver()) {
      getLoginCounter();
      sessionStorage.setItem('isLoggedIn', '1');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {
        // TODO: Needs to be refactored when Omzetting urls are going to be Dutch
        location.pathname.includes('/mutations') ||
        location.pathname.includes('/mutaties') ||
        location.pathname.includes('/clients/create') ? null : (
          <NavBar routes={routes} />
        )
      }

      <main className={classes.main}>
        <Switch>
          {routes.map(({ Component, ...route }) => (
            <Route index key={route.name} path={route.path} element={<Component />} />
          ))}
          <Route path="*" element={<NotFound />} />
        </Switch>

        {
          // FEATURE TOGGLE FEEDBACK FORMULIER
          env.FEATURES.STAT_395388_FEEDBACK && <Feedback />
        }
      </main>
      <CookieBar open={showCookieBar} closeDialog={closeCookieBar} />

      <>
        <HasRole role="observer">
          <OfficeSelect />
        </HasRole>
      </>

      <TokenExpireNotification />
      <CustomSnackbar
        isOpen={isError}
        severity="error"
        message="Er is iets mis met onze servers probeer het later opnieuw"
        handleClose={() => setError(false)}
      />
    </>
  );
};

export default AppBase;
