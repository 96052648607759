import { styled } from '@mui/material/styles';
import { Container as MuiContainer, Paper as MuitPaper, Grid, Box } from '@mui/material';

const FormRequired = styled(Box)(({ theme }) => ({
  color: theme.palette.warning.main,
  display: 'inline',
}));
const Container = styled(MuiContainer)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
});

const FileIcon = styled('img')(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginTop: '4px',
}));

const FileInput = styled('input')({
  display: 'none',
});

const List = styled('ul')({
  padding: 0,
});

const Paper = styled(MuitPaper)(({ theme }) => ({
  'borderRadius': '8px',
  'boxShadow': '0px 6px 12px 0px rgb(22 20 47 / 12%)',
  '& hr': {
    marginLeft: theme.spacing(7),
    backgroundColor: 'rgba(0,0,0,0.08)',
  },
}));

const Section = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(15),
}));

export { Container, Section, FileInput, FileIcon, Paper, List, FormRequired };
