export interface CompleteTaskBody {
  data: { issues: TaskSolutionIssue[]; comment?: string };
}

export interface TaskSolutionIssue {
  id: string;
  category: string;
  description: string;
  proofItem?: TaskSolutionIssueProofItem;
  resolution: TaskSolutionResolution;
}

export interface TaskSolutionIssueProofItem {
  /** @format int32 */
  typeCode?: number;

  /** @format int32 */
  sequenceNumber?: number;
}

export interface TaskSolutionResolution {
  comment?: string;
  solvedByOtherIssue?: boolean;
  files?: TaskSolutionResolutionFile[];
}

export interface TaskSolutionResolutionFile {
  action: TaskSolutionResolutionFileActionType;
  name: string;
  base64Content: string;
  encoding: string;
  mimeType: string;
  documentType?: TaskSolutionResolutionFileDocumentType;
  proofItem?: TaskSolutionResolutionFileProofItem;
  comment?: string;
}

export interface TaskSolutionResolutionFileProofItem {
  /** @format int32 */
  typeCode?: number;

  /** @format int32 */
  sequenceNumber?: number;
}

export enum TaskSolutionResolutionFileActionType {
  ReUpload = 'ReUpload',
  AddUpload = 'AddUpload',
}

export enum TaskSolutionResolutionFileDocumentType {
  IdentityDocument = 'IdentityDocument',
  EmployerStatement = 'EmployerStatement',
  BankStatement = 'BankStatement',
  ExplanationCollateral = 'ExplanationCollateral',
  ExplanationIncome = 'ExplanationIncome',
  ExplanationCredits = 'ExplanationCredits',
}

/**
 * @example {"officeName":"Advisor inc","kvk":"0034567890","afm":"0066778899","registrations":[{"destination":"NLAA","number":12345,"default":true},{"destination":"NLOV","number":54321,"default":false}]}
 */
export interface Advisor {
  /** The name of the office of the advisor. */
  officeName?: string;

  /** The advisors registration number with the chamber of commerce */
  kvk?: string;

  /** The advisors registration number at AFM (Autoriteit Financiele Markt), needed for WFD (Wet Financiele Dienstverlening) */
  afm?: string;

  /** The backoffice registrations for the advisor. */
  registrations?: Registration[];
}

export interface Registration {
  /** The destination/street of the registration. (example NLAA). */
  destination?: 'NLAA' | 'NLBF' | 'NLOV' | 'NLST' | 'DEST';

  /**
   * The relation number of the registration.
   * @format int32
   */
  number?: number;

  /** Only one registration is the golden source, marked as default. */
  default?: boolean;
}

export interface ErrorResponse {
  message?: string;
  errors?: object[];
}

/**
 * The information of a consent.
 * @example {"id":"XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX","status":"Granted","commercialLabel":"LENDER_CL_LENDER","loanNumber":123456}
 */
export interface Consent {
  /**
   * The unique identifier (GUID) of the consent.
   * @format ^{[A-Z0-9]{8}-([A-Z0-9]{4}-){3}[A-Z0-9]{12}}$
   */
  id: string;

  /** The status of the consent. */
  status: ConsentStatus;

  /**
   * The label of the originating organisation of the loan.
   * @pattern ^[A-Z,a-z,0-9]*_CL_[A-Z,a-z,0-9]*$
   */
  commercialLabel: string;

  /**
   * The number of the loan for which the consent information is entered.
   * @format int32
   * @min 1
   */
  loanNumber: number;
}

/**
 * The status of the consent.
 */
export enum ConsentStatus {
  Requested = 'RequestedEnum',
  Granted = 'GrantedEnum',
  Denied = 'DeniedEnum',
  Revoked = 'RevokedEnum',
  NotFound = 'NotFoundEnum',
}

/**
 * The information of a newly created request for consent.
 * @example {"commercialLabel":"LENDER_CL_LENDER","loanNumber":123456}
 */
export interface RequestConsent {
  /**
   * The label of the originating organisation of the loan.
   * @pattern ^[A-Z,a-z,0-9]*_CL_[A-Z,a-z,0-9]*$
   */
  commercialLabel: string;

  /**
   * The number of the loan for which the consent information is entered.
   * @format int32
   * @min 1
   */
  loanNumber: number;
}

/**
 * The information of a client extract for which a grant is given.
 */
export interface ClientExtract {
  /** The information of a loan within a client extract. */
  loan?: ClientExtractLoan;

  /** Collection of loan parts linked to the loan within a client extract. */
  loanParts?: ClientExtractLoanPart[];

  /** Collection of real estates linked to the loan within a client extract. */
  realEstates?: ClientExtractRealEstate[];

  /** Collection of depots. */
  depot?: ClientExtractDepot[];

  /** Collection of persons linked to the client of the loan within a client extract. */
  persons?: ClientExtractPerson[];
}

/**
 * The information of a depot linked to a loan within a client extract.
 */
export interface ClientExtractDepot {
  /** The type of depot. */
  type?: ClientExtractDepotType;

  /**
   * The principal sum of the depot.
   * @format decimal
   */
  amount?: number;

  /**
   * The interest rate linked to a depot.
   * @format decimal
   */
  interestRate?: number;

  /**
   * The end date of the depot.
   * @format date
   */
  endDate?: string;
}

/**
 * The type of depot.
 */
export enum ClientExtractDepotType {
  ConstructionDepot = 'ConstructionDepot',
}

/**
 * The information of a loan within a client extract.
 */
export interface ClientExtractLoan {
  /**
   * The number of the loan administrated by Stater.
   * @format int32
   * @min 1
   */
  number?: number;

  /** Collection of principal sum information. */
  principalSum?: ClientExtractLoanPrincipalSum[];

  /**
   * The actual residual debt of the complete loan.
   * @format decimal
   */
  residualDebt?: number;

  /** The information of the loan to value calculated during the monthly closure. */
  loanToValueClause?: ClientExtractLoanLoanToValueClause;
}

/**
 * The information of the loan to value calculated during the monthly closure.
 */
export interface ClientExtractLoanLoanToValueClause {
  /**
   * The LTV as calculated for this period.
   * @format decimal
   * @min 0
   */
  loanToValue?: number;

  /**
   * The LTMV as calculated for this period.
   * @min 0
   */
  loanToMarketValue?: number;
}

/**
 * The information of the principal sum of a loan.
 */
export interface ClientExtractLoanPrincipalSum {
  /** The ISO 4217 code of the currency in which the principal sum amount is set. */
  currency?: string;

  /**
   * The amount of the principal sum of the loan, that is the total of the principal sum of all active loan parts.
   * @format decimal
   */
  amount?: number;
}

/**
 * The information of a loan part linked to a loan within a client extract.
 */
export interface ClientExtractLoanPart {
  /** The information of the technical product. */
  product?: ClientExtractLoanPartProduct;

  /** The status of the loan part. */
  status?: ClientExtractLoanPartStatus;

  /**
   * The number of the loan part administrated by Stater.
   * @format int32
   * @min 1
   */
  number?: number;

  /** Collection of principal sum information. */
  principalSum?: ClientExtractLoanPartPrincipalSum[];

  /**
   * The actual residual debt of the loan part.
   * @format decimal
   */
  residualDebt?: number;

  /** The way in which it has been agreed with the consumer that the mortgage debt of a part of a loan will be repaid. */
  redemptionMethod?: ClientExtractLoanPartRedemptionMethod;

  /**
   * The start date of the loan part (the date on which the loan part became interest-bearing).
   * @format date
   */
  startDate?: string;

  /**
   * The date when the economic term of the loan part is reached.
   * @format date
   */
  endDate?: string;

  /** The information of the interest period linked ot a loan part. */
  interestPeriod?: ClientExtractLoanPartInterestPeriod;

  /** The type of guarantee. */
  guarantee?: ClientExtractLoanPartGuarantee;
}

/**
 * The type of guarantee.
 */
export enum ClientExtractLoanPartGuarantee {
  None = 'None',
  NHG = 'NHG',
  MunicipalWarranty = 'MunicipalWarranty',
  VAT = 'VAT',
}

/**
 * The information of the technical product.
 */
export interface ClientExtractLoanPartProduct {
  /**
   * The technical name of the technical product.
   * @pattern ^[A-Z,a-z,0-9]*_TP_[A-Z,a-z,0-9,_]*$
   */
  name?: string;

  /** The name that will be displayed in communications. */
  displayName?: string;
}

/**
 * The way in which it has been agreed with the consumer that the mortgage debt of a part of a loan will be repaid.
 */
export enum ClientExtractLoanPartRedemptionMethod {
  Annuity = 'Annuity',
  Linear = 'Linear',
  AtOnce = 'AtOnce',
  Other = 'Other',
}

/**
 * The status of the loan part.
 */
export enum ClientExtractLoanPartStatus {
  Active = 'Active',
  Converted = 'Converted',
  Redeemed = 'Redeemed',
}

/**
 * The information of the interest period linked ot a loan part.
 */
export interface ClientExtractLoanPartInterestPeriod {
  /**
   * The duration of the interest period in months..
   * @format int32
   * @min 1
   */
  duration?: number;

  /** The interest renewal type. */
  interestRenewalType?: string;

  /**
   * The interest renewal date of the interest.
   * @format date
   */
  renewalDate?: string;

  /**
   * The effective interest rate.
   * @format decimal
   */
  effectiveInterestRate?: number;
}

/**
 * The information of the principal sum of the complete sum.
 */
export interface ClientExtractLoanPartPrincipalSum {
  /** The ISO 4217 code of the currency in which the principal sum amount is set. */
  currency?: string;

  /**
   * The amount of the principal sum of the loan, that is the total of the principal sum of all active loan parts.
   * @format decimal
   */
  amount?: number;
}

/**
 * The information of a person linked to a client of a loan within a client extract.
 */
export interface ClientExtractPerson {
  /** The type of person. */
  type?: ClientExtractPersonType;

  /** The full name of the person. This is a composition of the name parts, which can be different for a NaturalPerson or a LegalPerson. */
  fullName?: string;

  /** The information of a natural person. */
  natural?: ClientExtractPersonNatural;

  /** The information of a legal person. */
  legal?: ClientExtractPersonLegal;

  /** The contact information of a person. */
  contact?: ClientExtractPersonContact;
}

/**
 * The contact information of a person.
 */
export interface ClientExtractPersonContact {
  /** The information of a contact address of a person. */
  address?: ClientExtractPersonContactAddress;

  /** Collection of email addresses. */
  emailAddresses?: ClientExtractPersonContactEmailAddress[];

  /** Collection of telephone numbers. */
  phoneNumbers?: ClientExtractPersonContactPhoneNumbers[];
}

/**
 * The information of a contact address of a person.
 */
export interface ClientExtractPersonContactAddress {
  /** The street name of a contact address of a person. */
  street?: string;

  /** The house number of a contact address of a person. */
  houseNumber?: string;

  /** The addition of the house number of a contact address of a person. */
  houseNumberSuffix?: string;

  /** The postal code of a contact address of a person. */
  postalCode?: string;

  /** The name of the city of a contact address of a person. */
  city?: string;

  /** The information of the country of a contact address. */
  country?: ClientExtractPersonContactAddressCountry;
}

/**
 * The information of the country of a contact address.
 */
export interface ClientExtractPersonContactAddressCountry {
  /** The ISO 3166-1 alpha-2 code of the country. */
  code?: string;

  /** The display name of the country. */
  displayName?: string;
}

/**
 * The information of an email address of a person.
 */
export interface ClientExtractPersonContactEmailAddress {
  /** The type of email address. */
  type?: ClientExtractPersonContactEmailAddressType;

  /**
   * The address.
   * @pattern ^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,63}$
   */
  address?: string;
}

/**
 * The type of email address.
 */
export enum ClientExtractPersonContactEmailAddressType {
  Personal = 'Personal',
  Work = 'Work',
}

/**
 * The information of a telephone number of a person.
 */
export interface ClientExtractPersonContactPhoneNumbers {
  /** The type of telephone number. */
  type?: ClientExtractPersonContactPhoneNumbersType;

  /** The phone number of a person. */
  number?: string;
}

/**
 * The type of telephone number.
 */
export enum ClientExtractPersonContactPhoneNumbersType {
  Mobile = 'Mobile',
  Work = 'Work',
  Home = 'Home',
  Fax = 'Fax',
}

/**
 * The information of a legal person.
 */
export interface ClientExtractPersonLegal {
  /** The number under which this legal entity is registered with the Kamer Van Koophandel (Chamber of Commerce). */
  kvk?: string;
}

/**
 * The information of a natural person.
 */
export interface ClientExtractPersonNatural {
  /** The information of the name of a natural person. */
  name?: ClientExtractPersonNaturalName;

  /**
   * The date of birth of the natural person.
   * @format date
   */
  dateOfBirth?: string;

  /** The place of birth of the natural person. */
  placeOfBirth?: string;

  /** The gender of a natural person. */
  gender?: ClientExtractPersonNaturalGender;

  /** The Identity document used as proof item. */
  proofOfIdentity?: ClientExtractPersonNaturalProofOfIdentity;

  /** The information of the nationality of a natural person. */
  nationality?: ClientExtractPersonNaturalNationality;

  /** The information of the marital status of a natural person. */
  maritalStatus?: ClientExtractPersonNaturalMaritalStatus;
}

/**
 * The gender of a natural person.
 */
export enum ClientExtractPersonNaturalGender {
  Male = 'Male',
  Female = 'Female',
  Unknown = 'Unknown',
}

/**
 * The information of the marital status of a natural person.
 */
export interface ClientExtractPersonNaturalMaritalStatus {
  /** The marital status of the natural person. */
  status?: string;

  /** Indicates if this natural person was ever divorced. */
  divorced?: boolean;
}

/**
 * The information of the name of a natural person.
 */
export interface ClientExtractPersonNaturalName {
  /** The initials of a natural person. */
  initials?: string;

  /** The last name prefix of a natural person. */
  lastNamePrefix?: string;

  /** The first name of a natural person. */
  firstName?: string;

  /** The last name of a natural person. */
  lastName?: string;
}

/**
 * The information of the nationality of a natural person.
 */
export interface ClientExtractPersonNaturalNationality {
  /**
   * The code to identify nationality based on Table 32 from the Dutch Authorities (Rijksdienst voor Indentiteitsgegevens https://publicaties.rvig.nl/).
   * @pattern [0-9]{4}
   */
  code?: string;

  /** The display name for the nationality code. */
  displayName?: string;
}

/**
 * The Identity document used as proof item.
 */
export interface ClientExtractPersonNaturalProofOfIdentity {
  /** The type of identity card used as proof. */
  type?: string;

  /** The id of the document. */
  number?: string;
}

/**
 * The type of person.
 */
export enum ClientExtractPersonType {
  NaturalPerson = 'Natural',
  LegalPerson = 'Legal',
}

/**
 * The information of a real estate linked to a loan within a client extract.
 */
export interface ClientExtractRealEstate {
  /** The role of the real estate within the loan. */
  role?: ClientExtractRealEstateRole;

  /** The information of the type of real estate. */
  type?: ClientExtractRealEstateType;

  /** The valuation information of a real estate object. */
  valuation?: ClientExtractRealEstateValuation;

  /** The information of the address of the real estate object. */
  address?: ClientExtractRealEstateAddress;

  /** The information of the construction plan in case of a newly built real estate. */
  constructionPlan?: ClientExtractRealEstateConstructionPlan;
}

/**
 * The information of the address of the real estate object.
 */
export interface ClientExtractRealEstateAddress {
  /** The name of the street. */
  street?: string;

  /** The housenumber is the number assigned to the object by municipal government and it is part of the address. */
  houseNumber?: string;

  /** The postal code */
  postalCode?: string;

  /** The official name of the city. */
  city?: string;

  /** The information of the country. */
  country?: ClientExtractRealEstateAddressCountry;
}

/**
 * The information of the construction plan in case of a newly built real estate.
 */
export interface ClientExtractRealEstateConstructionPlan {
  /** The name of the construction project for the newly built real estate. */
  project?: string;

  /** The code that identifies the location of the newly built object within the project. */
  identification?: string;
}

/**
 * The information of the country.
 */
export interface ClientExtractRealEstateAddressCountry {
  /** The ISO 3166-1 alpha-2 code of the country. */
  code?: string;

  /** The display name of the country. */
  displayName?: string;
}

/**
 * The role of the real estate within the loan.
 */
export enum ClientExtractRealEstateRole {
  MainCollateral = 'MainCollateral',
  BridgingCollateral = 'BridgingCollateral',
  AdditionalCollateral = 'AdditionalCollateral',
}

/**
 * The information of the type of real estate.
 */
export interface ClientExtractRealEstateType {
  /** The technical name of the type of real estate. */
  name?: string;

  /** The display name of the type of real estate. */
  displayName?: string;
}

/**
 * The valuation information of a real estate object.
 */
export interface ClientExtractRealEstateValuation {
  /**
   * The date of this valuation
   * @format date
   */
  date?: string;

  /**
   * The estimated market value of the real estate object if not rented and in case of a private sale.
   * @format decimal
   */
  marketValue?: number;

  /**
   * The expected value of the object in case of forced sale.
   * @format decimal
   */
  forcedSaleValue?: number;
}

/**
 * All real estate objects connected to the specified loan
 * @example {"lender":"ROBEC_CL_ROBEC","loanNumber":115560,"loanToValue":{"endDate":"2020-12-31","loanToValue":18.5},"loanParts":[{"number":103,"loanToValueCalculationMethod":"ForcedSaleValue"},{"number":107,"loanToValueCalculationMethod":"ForcedSaleValue"}],"realEstates":[{"realEstateNumber":2388658,"role":"MainCollateral","address":{"street":"Podium","houseNumber":"33A","countryCode":"NL","city":"AMERSFOORT","postalCode":"3826PA"},"constructionYear":2021,"constructionPlan":{"name":"Molenpark","situationNumber":"3.30"},"groundLease":{"endDate":"2050-01-01","amount":505.5},"valuation":{"date":"2020-10-01","marketValue":380000,"forcedSaleValue":220000,"woz":{"value":373000,"date":"2020-02-01"},"relations":{"appraiser":{"number":12776}}}},{"realEstateNumber":2388700,"role":"BridgingCollateral","address":{"street":"Grutenhaege","houseNumber":"12","countryCode":"NL","city":"AMERSFOORT","postalCode":"3826PA"},"constructionYear":1998,"valuation":{"date":"1997-02-17","endDate":"2020-07-16","marketValue":215545,"forcedSaleValue":186049,"relations":{"appraiser":{"number":12776}}},"energyEfficiencyReport":{"reportDate":"2020-07-23","measurementMethod":"EI","value":1.44,"energyLabel":"C","isEnergyNeutral":false}}]}
 */
export interface RealEstatesForLoan {
  /**
   * The label of the originating organization.
   * @pattern ^[A-Z,a-z,0-9]*_CL_[A-Z,a-z,0-9]*$
   */
  lender?: string;

  /**
   * The number of the loan per commercial label (lender).
   * @format int32
   * @min 1
   */
  loanNumber?: number;

  /** All active loan parts in this loan, needed for the loanToValueCalculationMethod */
  loanParts?: RealEstatesForLoanLoanPart[];

  /** The information of the loan to value calculated during the monthly closure. */
  loanToValue?: RealEstatesForLoanLoanToValue;

  /** the collection of real estate objects */
  realEstates?: RealEstate[];
}

/**
 * The information of the loan to value calculated during the monthly closure.
 */
export interface RealEstatesForLoanLoanToValue {
  /**
   * The start date of the accounting period over which the LT(M)V was calculated.
   * @format date
   */
  startDate?: string;

  /**
   * The LAST day of the accounting period over which the LT(M)V was calculated.
   * @format date
   */
  endDate?: string;

  /**
   * The LTV as calculated for this period.
   * @format decimal
   * @min 0
   */
  loanToValue?: number;

  /**
   * The LTMV as calculated for this period.
   * @min 0
   */
  loanToMarketValue?: number;
}

/**
 * Additional information on loan part level needed for loan to value calculations. Only active parts are involved.
 */
export interface RealEstatesForLoanLoanPart {
  /**
   * The number of the loan part administrated by Stater.
   * @format int32
   */
  number?: number;

  /** The method used to determine surcharge ineterest rates. */
  loanToValueCalculationMethod?: RealEstatesForLoanLoanPartLoanToValueCalculationMethod;
}

/**
 * The method used to determine surcharge ineterest rates.
 */
export enum RealEstatesForLoanLoanPartLoanToValueCalculationMethod {
  ForcedSaleValue = 'ForcedSaleValue',
  MarketValue = 'MarketValue',
}

/**
 * Loan object in E-advisor
 * @example {"loanNumber":892382,"consumer":{"contact":{"addresses":[{"type":"Business","street":"Postbus","houseNumber":"3002","city":"Roosendaal","postalCode":"4700AA","countryCode":"NL"},{"type":"Visiting","street":"Kostverlorenlaan","houseNumber":"12","houseNumberSuffix":"C-I","city":"Roosendaal","postalCode":"4788RA","countryCode":"NL"}],"emails":[{"type":"Personal","address":"pvr@gmail.com"},{"type":"Personal","address":"pvr@outlook.com"}],"telephoneNumbers":[{"type":"Mobile","number":"06-21268992"},{"type":"Home","number":"01655-50879"}]},"persons":[{"role":"Debtor","number":1,"type":"NaturalPerson","fullName":"Baron K.Z. van der Stadt RA","natural":{"name":{"initials":"K.Z.","lastNamePrefix":"van der","firstName":"Kumar","lastName":"Stadt"},"dateOfBirth":"1980-03-18","gender":"Male"}},{"role":"Debtor","number":2,"type":"NaturalPerson","fullName":"Ir. J. de Boender","natural":{"name":{"initials":"J.","lastNamePrefix":"de","lastName":"Boender"},"dateOfBirth":"1982-03-18","gender":"Female"}}]}}
 */
export interface Loan {
  /**
   * The number of the loan per commercial label (lender).
   * @format int32
   * @min 1
   */
  loanNumber?: number;

  /** The consumer of the loan consists of one or multiple persons, debtors or co-debtors. */
  consumer?: LoanConsumer;
}

/**
 * The consumer of the loan consists of one or multiple persons, debtors or co-debtors.
 */
export interface LoanConsumer {
  /** The contact data for a consumer of a loan. */
  contact?: LoanConsumerContact;

  /** The collection of (co)debtors for this loan */
  persons?: LoanConsumerPerson[];
}

/**
 * The information of a person as part of the loan consumer.
 * @example {"number":0,"role":"Debtor","type":"NaturalPerson","fullName":"Baron K.Z. van der Stadt RA","natural":{"name":{"initials":"K.Z.","lastNamePrefix":"van der","firstName":"Kumar","lastName":"Stadt","titles":{"prefix":"Baron","suffix":"RA"}},"dateOfBirth":"1980-03-18","gender":"Male"}}
 */
export interface LoanConsumerPerson {
  /** @format int32 */
  number: number;

  /** The role of a person within a loan. */
  role: LoanConsumerPersonRole;

  /** NaturalPerson or LegalPerson. */
  type: LoanConsumerPersonType;

  /** The full name of this person. This is a composition of the name parts, which can be different for a NaturalPerson or a LegalPerson. */
  fullName?: string;

  /** Properties for a natural person. NOTE These properties will be present only for persons of type NaturalPerson. */
  natural?: LoanConsumerPersonNatural;

  /** Properties typically for a legal person, only present for personType LegalPerson. */
  legal?: LoanConsumerPersonLegal;
}

/**
 * NaturalPerson or LegalPerson.
 */
export enum LoanConsumerPersonType {
  NaturalPerson = 'NaturalPerson',
  LegalPerson = 'LegalPerson',
}

/**
 * Properties typically for a legal person, only present for personType LegalPerson.
 */
export interface LoanConsumerPersonLegal {
  /** The registration number under which this legal entity is registered with the Chamber of Commerce */
  kvk?: string;
}

/**
 * Properties for a natural person. NOTE These properties will be present only for persons of type NaturalPerson.
 */
export interface LoanConsumerPersonNatural {
  /** The name parts of a natural person. */
  name?: LoanConsumerPersonNaturalName;

  /**
   * date of birth
   * @format date
   */
  dateOfBirth?: string;

  /**
   * The date of decease
   * @format date
   */
  dateOfDeath?: string;

  /** The gender for this person. */
  gender?: LoanConsumerPersonNaturalGender;
}

/**
 * The gender for this person.
 */
export enum LoanConsumerPersonNaturalGender {
  Male = 'Male',
  Female = 'Female',
  Unknown = 'Unknown',
}

/**
 * The name parts of a natural person.
 */
export interface LoanConsumerPersonNaturalName {
  /** the initials of this natural person */
  initials?: string;

  /** Prepostion for this NaturalPerson */
  lastNamePrefix?: string;

  /** The first name` */
  firstName?: string;

  /** the last name */
  lastName?: string;

  /** the titles for a person */
  titles?: LoanConsumerPersonNaturalNameTitles;
}

/**
 * The role of a person within a loan.
 */
export enum LoanConsumerPersonRole {
  Debtor = 'Debtor',
  CoDebtor = 'CoDebtor',
}

/**
 * the titles for a person
 */
export interface LoanConsumerPersonNaturalNameTitles {
  /** The titles used as a prefix, like Drs., or Baron */
  prefix?: string;

  /** The titles used as a suffix, like RA or AAG. */
  suffix?: string;
}

/**
 * The contact data for a consumer of a loan.
 * @example {"addresses":[{"type":"Visiting","street":"Kostverlorenlaan","houseNumber":"12","houseNumberSuffix":"C-I","city":"Roosendaal","postalCode":"4788RA","countryCode":"NL"}],"emails":[{"type":"Personal","address":"pvr@gmail.com"},{"type":"Personal","address":"pvr@outlook.com"}],"telephoneNumbers":[{"type":"Mobile","number":"06-21268992"},{"type":"Home","number":"01655-50879"}]}
 */
export interface LoanConsumerContact {
  /** the current address(es) for this relation */
  addresses?: LoanConsumerContactAddress[];

  /** all current email addresses */
  emails?: LoanConsumerContactEmail[];

  /** Telephone numbers */
  telephoneNumbers?: LoanConsumerContactTelephone[];
}

/**
 * Address as part of contact data for a person.
 */
export interface LoanConsumerContactAddress {
  /** The type of address. */
  type?: LoanConsumerContactAddressType;

  /** Street name */
  street?: string;

  /** house number (without addition if houseNumberSuffix is present) */
  houseNumber?: string;

  /** addition house number */
  houseNumberSuffix?: string;

  /** City */
  city?: string;

  /** Postal code */
  postalCode?: string;

  /**
   * ISO 3166-1 alpha-2 Country code (NL, DE, etc)
   * @format ISO3166-1
   */
  countryCode?: string;
}

/**
 * The type of address.
 */
export enum LoanConsumerContactAddressType {
  Visiting = 'Visiting',
  Correspondence = 'Correspondence',
  Business = 'Business',
  Vacation = 'Vacation',
}

/**
 * a telephone number
 */
export interface LoanConsumerContactTelephone {
  /** the type of telephoneNumber */
  type?: LoanConsumerContactTelephoneType;

  /** The phonenumber */
  number?: string;
}

/**
 * the type of telephoneNumber
 */
export enum LoanConsumerContactTelephoneType {
  Mobile = 'Mobile',
  Work = 'Work',
  Home = 'Home',
  Fax = 'Fax',
}

/**
 * an email address
 */
export interface LoanConsumerContactEmail {
  /** the type of email address */
  type?: LoanConsumerContactEmailType;

  /** The address */
  address?: string;
}

/**
 * the type of email address
 */
export enum LoanConsumerContactEmailType {
  Personal = 'Personal',
  Work = 'Work',
}

/**
 * Real Estate object
 */
export interface RealEstate {
  /**
   * The number of the real estate object per label.
   * @format int32
   * @min 1
   */
  realEstateNumber?: number;

  /** The role of the real estate within the loan. */
  role?: RealEstateRealEstateRole;

  /** the address of the real estate object */
  address?: RealEstateAddress;

  /** The information of the ground lease of the real estate. The object is only added when ground lease is applicable. */
  groundLease?: RealEstateGroundLease;

  /**
   * The year of construction
   * @format int32
   * @min 1000
   * @max 2500
   */
  constructionYear?: number;

  /** The information of the construction plan for the real estate. */
  constructionPlan?: RealEstateConstructionPlan;

  /** The current valuation of a real estate object */
  valuation?: RealEstateValuation;

  /** Energy Efficiency Report for a real estate object */
  energyEfficiencyReport?: RealEstateEnergyEfficiencyReport;
}

/**
 * Energy Efficiency Report for a real estate object
 */
export interface RealEstateEnergyEfficiencyReport {
  /**
   * The date of this report
   * @format date
   */
  reportDate?: string;

  /**
   * The end date of this report. Normally a report is valid for ten years
   * @format date
   */
  endDate?: string;

  /** The code indicating the applied measurement method for determining the energy efficiency */
  measurementMethod?: RealEstateEnergyEfficiencyReportMeasurementMethod;

  /** the measured energy efficiency */
  value?: number;

  /** The energy label for a real estate object */
  energyLabel?: RealEstateEnergyLabel;

  /** the object consumes as much energy as it produces (nul op de meter) */
  isEnergyNeutral?: boolean;
}

/**
 * The code indicating the applied measurement method for determining the energy efficiency
 */
export enum RealEstateEnergyEfficiencyReportMeasurementMethod {
  EI = 'EI',
  EPC = 'EPC',
  EL = 'EL',
}

/**
 * The information of the construction plan for the real estate.
 */
export interface RealEstateConstructionPlan {
  /** The name of the construction plan for the newly built real estate */
  name: string;

  /** The situation number within the construction plan for the newly built real estate */
  situationNumber: string;
}

/**
 * The energy label for a real estate object
 */
export enum RealEstateEnergyLabel {
  A5Plus = 'A+++++',
  A4Plus = 'A++++',
  A3Plus = 'A+++',
  A2Plus = 'A++',
  A1Plus = 'A+',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

/**
 * The information of the ground lease of the real estate. The object is only added when ground lease is applicable.
 */
export interface RealEstateGroundLease {
  /**
   * The amount for ground lease that is monthly due
   * @format decimal
   * @min 0
   */
  amount?: number;

  /**
   * The ground lease end date
   * @format date
   */
  endDate?: string;
}

/**
 * the address of the real estate object
 */
export interface RealEstateAddress {
  /** The name of a street, road, lane or alley */
  street?: string;

  /** The housenumber is the number assigned to the object by municipal government and it is part of the address */
  houseNumber?: string;

  /** Postal code of 4 digits and 2 characters */
  postalCode?: string;

  /** The official name of a town or city, in capitals */
  city?: string;

  /**
   * The code used to indicate a specific country (ISO 3166-1 alpha-2)
   * @format ISO3166-1
   */
  countryCode?: string;
}

/**
 * The role of the real estate within the loan.
 */
export enum RealEstateRealEstateRole {
  MainCollateral = 'MainCollateral',
  BridgingCollateral = 'BridgingCollateral',
  AdditionalCollateral = 'AdditionalCollateral',
}

/**
 * The current valuation of a real estate object
 */
export interface RealEstateValuation {
  /**
   * The date of this valuation
   * @format date
   */
  date?: string;

  /**
   * The end date of this valuation. That is the same date is the startDate of a newer valuation.
   * @format date
   */
  endDate?: string;

  /**
   * Estimated marketvalue or free private sales value, VOV) of the object if not rented, in case of a private sale
   * @format decimal
   */
  marketValue?: number;

  /**
   * The expected value of the object in case of forced sale  (executiewaarde)
   * @format decimal
   */
  forcedSaleValue?: number;

  /** The WOZ value of the object according to the local government */
  woz?: ValuationWoz;

  /** The energy label for a real estate object */
  energyLabel?: RealEstateEnergyLabel;

  /**
   * The market value of the object if not rented, according to CHF (bedr_mrktwrde_chf)
   * @format decimal
   */
  marketValueChf?: number;

  /** The keys to parties and persons in the relation domain */
  relations?: ValuationRelations;
}

/**
 * The WOZ value of the object according to the local government
 */
export interface ValuationWoz {
  /** @format decimal */
  value: number;

  /**
   * The reference date for the WOZ valuation (dtm_woz_tax)
   * @format date
   */
  date?: string;
}

/**
 * The keys to parties and persons in the relation domain
 */
export interface ValuationRelations {
  /** The reference to a Party in the Relation domain */
  appraiser: Party;
}

/**
 * The reference to a Party in the Relation domain
 */
export interface Party {
  /** the personNumber of this party */
  number: number;
}

export enum CaseApplicationType {
  NewLoan = 'NewLoan',
  Conversion = 'Conversion',
  ConversionRaise = 'ConversionRaise',
  Raise = 'Raise',
  Move = 'Move',
  ChangeLiability = 'ChangeLiability',
  Mutation = 'Mutation',
}

export interface CaseStatusInfo {
  value?: string;

  /** @format date-time */
  changedDate?: string;
}

export interface CasePassingDateInfo {
  /** @format date-time */
  planned?: string;

  /** @format date-time */
  utmost?: string;
}

export interface CasePriorityInfo {
  urgency?: string;
  weight?: number;
}

export interface CaseApplicationInfo {
  /** @format int32 */
  index?: number;
  status?: CaseStatusInfo;
  priority?: CasePriorityInfo;
  passingDate?: CasePassingDateInfo;

  /** @format date-time */
  creationDate?: string;

  /** @format date-time */
  validityEndDate?: string;

  /** @format date-time */
  endDate?: string;
  progress?: number;

  /** @format decimal */
  loanAmount?: number;

  /**
   * @format int32
   * @min 1
   */
  loanNumberExternal?: number;
  type?: CaseApplicationType;
  subType?: string;
}

export interface CaseProofItemInfo {
  /** @format int32 */
  approved?: number;

  /** @format int32 */
  amount?: number;

  /** @format int32 */
  received?: number;

  /** @format int32 */
  rejected?: number;
}

export interface CaseApplicantInfo {
  initials?: string;
  name?: string;
  prefix?: string;
}

export interface CaseLenderInfo {
  label?: string;
  name?: string;

  /** @format uri */
  deepLink?: string;
}

export enum CaseClassificationEnum {
  VeryLow = 'VeryLow',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  VeryHigh = 'VeryHigh',
}

export interface CaseQualityScoreInfo {
  /** @format double */
  value?: number;
  classification?: CaseClassificationEnum;
}

export interface CaseTaskInfo {
  /** @format int32 */
  open?: number;
}

export interface Case {
  application?: CaseApplicationInfo;
  proofItem?: CaseProofItemInfo;
  applicants?: CaseApplicantInfo[];
  lender?: CaseLenderInfo;
  qualityScore?: CaseQualityScoreInfo;
  task?: CaseTaskInfo;
}

export type GetCasesResponse = Case[];

export enum CaseDetailsApplicationType {
  NewLoan = 'NewLoan',
  Conversion = 'Conversion',
  ConversionRaise = 'ConversionRaise',
  Raise = 'Raise',
  Move = 'Move',
  ChangeLiability = 'ChangeLiability',
  Mutation = 'Mutation',
}

export interface CaseDetailsStatusInfo {
  value?: string;

  /** @format date-time */
  changedDate?: string;
}

export interface CaseDetailsPassingDateInfo {
  /** @format date-time */
  planned?: string;

  /** @format date-time */
  utmost?: string;
}

export interface CaseDetailsPriorityInfo {
  urgency?: string;
  weight?: number;
}

export interface CaseDetailsApplicationInfo {
  /** @format int32 */
  index?: number;
  status?: CaseDetailsStatusInfo;
  priority?: CaseDetailsPriorityInfo;
  passingDate?: CaseDetailsPassingDateInfo;

  /** @format date-time */
  creationDate?: string;

  /** @format date-time */
  validityEndDate?: string;

  /** @format date-time */
  endDate?: string;
  progress?: number;

  /** @format decimal */
  loanAmount?: number;

  /**
   * @format int32
   * @min 1
   */
  loanNumberExternal?: number;
  type?: CaseDetailsApplicationType;
  subType?: string;
  assignedTeamType?: string;
}

export interface CaseDetailsProofItemInfo {
  /** @format int32 */
  approved?: number;

  /** @format int32 */
  amount?: number;

  /** @format int32 */
  received?: number;

  /** @format int32 */
  rejected?: number;
}

export interface CaseDetailsApplicantInfo {
  initials?: string;
  name?: string;
  prefix?: string;
  contactData?: CaseDetailsContactDataInfo[];
}

export interface CaseDetailsLenderInfo {
  label?: string;
  name?: string;

  /** @format uri */
  deepLink?: string;
}

export enum CaseDetailsClassificationEnum {
  VeryLow = 'VeryLow',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  VeryHigh = 'VeryHigh',
}

export interface CaseDetailsQualityScoreInfo {
  /** @format double */
  value?: number;
  classification?: CaseDetailsClassificationEnum;
}

export interface CaseDetailsTaskInfo {
  /** @format int32 */
  open?: number;
}

export interface CaseDetailsContactDataInfo {
  street?: string;

  /** @format interger */
  houseNumber?: number;
  houseNumberAddition?: string;
  zipCode?: string;
  city?: string;
  emailAddress?: string;
  phoneNumberFixed?: string;
  phoneNumberWork?: string;
}

export interface CaseDetailsAssetInfo {
  contactData?: CaseDetailsContactDataInfo;
  typeOfRealEstate?: string;
  constructionType?: string;
  constructionYear?: number;
  hasBeenEvaluated?: boolean;

  /** @format date-time */
  evaluationDate?: string;
  purchasePrice?: number;
  marketValue?: number;
  forcedSaleValue?: number;
}

export interface CaseDetails {
  application?: CaseDetailsApplicationInfo;
  proofItem?: CaseDetailsProofItemInfo;
  applicants?: CaseDetailsApplicantInfo[];
  lender?: CaseDetailsLenderInfo;
  qualityScore?: CaseDetailsQualityScoreInfo;
  task?: CaseDetailsTaskInfo;
  addresses?: CaseDetailsAssetInfo[];
}

export enum TaskInfoDelegationState {
  Pending = 'Pending',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Revoked = 'Revoked',
}

export interface TaskInfoAssignee {
  role?: string;
  id?: string;
}

export interface TaskInfoOwner {
  role?: string;
  id?: string;
}

export interface TaskInfo {
  name?: string;
  description?: string;
  assignee?: TaskInfoAssignee;
  owner?: TaskInfoOwner;
  origin?: string;
  delegationState?: TaskInfoDelegationState;

  /** @format date-time */
  due?: string;
  taskId?: string;
  commercialLabel?: string;
}

export type GetTaskListResponse = TaskInfo[];

export interface TaskDetailsAssignee {
  role?: string;
  id?: string;
}

export interface TaskDetailsOwner {
  role?: string;
  id?: string;
}

export interface TaskDetailsContext {
  version?: string;
  type?: string;
  issues?: TaskDetailsIssue[];
  comment?: string;
}

export interface TaskDetailsProofItem {
  /** @format int32 */
  typeCode?: number;
  sequenceNumber?: number;
}

export interface TaskDetailsIssue {
  id?: string;
  category?: string;
  description?: string;
  proofItem?: TaskDetailsProofItem;
  resolution?: TaskDetailsResolution;
}

export interface TaskDetailsResolution {
  comment?: string;
  solvedByOtherIssue?: boolean;
  files?: TaskDetailsResolutionFile[];
}

export interface TaskDetailsResolutionFile {
  action?: string;
  documentId?: string;
  name?: string;
  documentType?: TaskSolutionResolutionFileDocumentType;
  proofItem?: TaskDetailsProofItem;
}

export interface TaskDetails {
  name?: string;
  description?: string;
  assignee?: TaskDetailsAssignee;
  owner?: TaskDetailsOwner;
  origin?: string;
  delegationState?: TaskInfoDelegationState;

  /** @format date-time */
  due?: string;
  taskId?: string;
  commercialLabel?: string;
  context?: TaskDetailsContext;
}

export interface GetClientConsentStatusResponse {
  /** The information of a consent. */
  consent?: Consent;
}

export interface RequestClientConsentResponse {
  success?: boolean;
}

export type GetProofItemListResponse = ProofItem[];

export interface ProofItem {
  allowedPdmTools?: string[];
  category?: string;

  /** @format int32 */
  code?: number;
  contentType?: string;

  /** @format date-time */
  dateApproved?: string;

  /** @format date-time */
  dateLastReminder?: string;

  /** @format date-time */
  dateReceived?: string;

  /** @format date-time */
  dateRejected?: string;
  description?: string;

  /** @format int32 */
  documentId?: number;

  /** @format int32 */
  documentSequenceNumber?: number;
  isAutomaticApprovalEnabled?: boolean;
  isDischarge?: boolean;
  isFinancialApprovalDocument?: boolean;
  isOriginalObligatory?: boolean;

  /** @format int32 */
  numberOfUploads?: number;
  purpose?: string;
  reasonForRejection?: string;
  shortDescription?: string;
  status?: string;
  supplier?: string;
  utmostDeliveryMoment?: string;
  uploadAllowed?: boolean;
}

export interface LoanProduct {
  name?: string;
  displayName?: string;
}

export type LoanProductList = LoanProduct[];
