import { useTranslation } from 'react-i18next';
import { Typography, Icon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { parseISO, differenceInBusinessDays } from 'date-fns';
import type { ProofItem } from 'types/ProofItem';
import formatDate from 'utils/formatDate';
import { ProofItemStatus, getProofItemStatus } from 'utils/proof-item';
import { Case } from '__generated__/api-di';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  text: {
    fontSize: '12px',
    color: theme.palette.text.secondary,
  },
  interpunct: {
    margin: theme.spacing(0, 0.5),
    color: theme.palette.text.secondary,
  },
  dueIcon: {
    marginRight: theme.spacing(0.5),
    fontSize: '12px',
  },
  documentDueRed: {
    color: theme.palette.danger.dark,
  },
  documentDueOrange: {
    color: theme.palette.warning.dark,
  },
  dueWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },

  warningText: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
}));

type ProofItemDateTextProps = {
  proofItem: ProofItem;
  application: Case;
  now?: Date;
};

const ProofItemDateText = (props: ProofItemDateTextProps) => {
  const { proofItem, application, now = new Date() } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const status = getProofItemStatus(proofItem.status);
  const deadline = getDeadlineDate(proofItem, status, application);

  if (status === ProofItemStatus.cancelled) {
    return (
      <div className={classes.root}>
        <Typography className={classes.text}>{t(`proofitem.status.${status}`)}</Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>{t(`proofitem.date.${status}`)}</Typography>
      <Typography className={classes.interpunct}>‧</Typography>
      <DeadlineText deadline={deadline} now={now} proofItem={proofItem} proofItemStatus={status} />
    </div>
  );
};

type DateRemainingProps = {
  now: Date;
  deadline: string | null | undefined;
  proofItem: ProofItem;
  proofItemStatus: string;
};

const DeadlineText = (props: DateRemainingProps) => {
  const { deadline, now, proofItem, proofItemStatus } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  if (!deadline) {
    return null;
  }

  const deadlineDate = parseISO(deadline);
  const dueDays = differenceInBusinessDays(deadlineDate, now);

  const getLabel = () => {
    if (dueDays < -1) return t('proofitem.documentDueDaysPast', { days: dueDays * -1 });
    if (dueDays === -1) return t('proofitem.documentDueDayPast');
    if (dueDays === 0) return t('proofitem.documentDueToday');
    if (dueDays === 1) return t('proofitem.documentDueDay');
    return t('proofitem.documentDueDays', { days: dueDays });
  };

  const isError =
    (proofItem.code === 100 && dueDays <= 3) || (proofItem.code !== 100 && dueDays <= 23);
  const isWarning =
    (proofItem.code === 100 && dueDays <= 5) || (proofItem.code !== 100 && dueDays <= 28);

  if (proofItemStatus === ProofItemStatus.waiting && (isError || isWarning)) {
    return (
      <div
        className={`${classes.dueWrapper} ${
          isError ? classes.documentDueRed : classes.documentDueOrange
        }`}
      >
        <Icon className={classes.dueIcon}>clock</Icon>
        <Typography className={classes.warningText}>{getLabel()}</Typography>
      </div>
    );
  }

  return <Typography className={classes.text}>{formatDate(deadline)}</Typography>;
};

function getDeadlineDate(proofItem: ProofItem, proofItemStatus: string, applicationData: Case) {
  const finalPassingDate = applicationData.application!.passingDate!.planned
    ? applicationData.application!.passingDate!.planned
    : applicationData.application!.passingDate!.utmost;

  switch (proofItemStatus) {
    case ProofItemStatus.waiting:
      if (proofItem.code === 100) {
        return applicationData.application!.validityEndDate;
      }
      return finalPassingDate;
    case ProofItemStatus.pendingApproval:
      return proofItem.dateReceived;
    case ProofItemStatus.approved:
      return proofItem.dateApproved;
    case ProofItemStatus.rejected:
    case ProofItemStatus.turnedDown:
      return proofItem.dateRejected;
    case ProofItemStatus.cancelled:
    default:
      return null;
  }
}

export default ProofItemDateText;
