export interface CaseStatusInfo {
  value?: string | null;

  /** @format date-time */
  changedDate?: string | null;
}

export interface CasePriorityInfo {
  urgency?: string | null;

  /** @format double */
  weight?: number | null;
}

export interface CasePassingDateInfo {
  /** @format date-time */
  planned?: string | null;

  /** @format date-time */
  utmost?: string | null;
}

export enum CaseApplicationType {
  NewLoanEnum = 'NewLoanEnum',
  ConversionEnum = 'ConversionEnum',
  ConversionRaiseEnum = 'ConversionRaiseEnum',
  RaiseEnum = 'RaiseEnum',
  MoveEnum = 'MoveEnum',
  ChangeLiabilityEnum = 'ChangeLiabilityEnum',
  MutationEnum = 'MutationEnum',
}

export interface CaseApplicationInfo {
  /** @format int32 */
  index?: number | null;
  status?: CaseStatusInfo;
  priority?: CasePriorityInfo;
  passingDate?: CasePassingDateInfo;

  /** @format date-time */
  creationDate?: string | null;

  /** @format date-time */
  validityEndDate?: string | null;

  /** @format date-time */
  endDate?: string | null;

  /** @format double */
  progress?: number | null;

  /** @format double */
  loanAmount?: number | null;

  /** @format int32 */
  loanNumberExternal?: number | null;
  type?: CaseApplicationType;
  subType?: string | null;
}

export interface CaseProofItemInfo {
  /** @format int32 */
  approved?: number | null;

  /** @format int32 */
  amount?: number | null;

  /** @format int32 */
  received?: number | null;

  /** @format int32 */
  rejected?: number | null;
}

export interface CaseApplicantInfo {
  initials?: string | null;
  name?: string | null;
  prefix?: string | null;
}

export interface CaseLenderInfo {
  label?: string | null;
  name?: string | null;
  deepLink?: string | null;
}

export enum CaseClassificationEnum {
  VeryLowEnum = 'VeryLowEnum',
  LowEnum = 'LowEnum',
  MediumEnum = 'MediumEnum',
  HighEnum = 'HighEnum',
  VeryHighEnum = 'VeryHighEnum',
}

export interface CaseQualityScoreInfo {
  /** @format double */
  value?: number | null;
  classification?: CaseClassificationEnum;
}

export interface CaseTaskInfo {
  /** @format int32 */
  open?: number | null;
}

export interface Case {
  application?: CaseApplicationInfo;
  proofItem?: CaseProofItemInfo;
  applicants?: CaseApplicantInfo[] | null;
  lender?: CaseLenderInfo;
  qualityScore?: CaseQualityScoreInfo;
  task?: CaseTaskInfo;
}

export interface PersonName {
  initials?: string | null;
  name?: string | null;
  prefix?: string | null;
}

export interface ApplicationData {
  applicationStatus?: string | null;

  /** @format date-time */
  applicationStatusStartDate?: string | null;
  commercialLabel?: string | null;
  commercialName?: string | null;

  /** @format int32 */
  id?: number;

  /** @format double */
  loanAmount?: number | null;
  person?: PersonName[] | null;

  /** @format date-time */
  dateCreated?: string | null;

  /** @format date-time */
  dateModified?: string | null;
  type?: string | null;

  /** @format int32 */
  loanNumber?: number | null;
  subType?: string | null;
}

export interface CaseDetailsStatusInfo {
  value?: string | null;

  /** @format date-time */
  changedDate?: string | null;
}

export interface CaseDetailsPriorityInfo {
  urgency?: string | null;

  /** @format double */
  weight?: number | null;
}

export interface CaseDetailsPassingDateInfo {
  /** @format date-time */
  planned?: string | null;

  /** @format date-time */
  utmost?: string | null;
}

export enum CaseDetailsApplicationType {
  NewLoanEnum = 'NewLoanEnum',
  ConversionEnum = 'ConversionEnum',
  ConversionRaiseEnum = 'ConversionRaiseEnum',
  RaiseEnum = 'RaiseEnum',
  MoveEnum = 'MoveEnum',
  ChangeLiabilityEnum = 'ChangeLiabilityEnum',
  MutationEnum = 'MutationEnum',
}

export interface CaseDetailsApplicationInfo {
  /** @format int32 */
  index?: number | null;
  status?: CaseDetailsStatusInfo;
  priority?: CaseDetailsPriorityInfo;
  passingDate?: CaseDetailsPassingDateInfo;

  /** @format date-time */
  creationDate?: string | null;

  /** @format date-time */
  validityEndDate?: string | null;

  /** @format date-time */
  endDate?: string | null;

  /** @format double */
  progress?: number | null;

  /** @format double */
  loanAmount?: number | null;

  /** @format int32 */
  loanNumberExternal?: number | null;
  type?: CaseDetailsApplicationType;
  subType?: string | null;
  assignedTeamType?: string | null;
}

export interface CaseDetailsProofItemInfo {
  /** @format int32 */
  approved?: number | null;

  /** @format int32 */
  amount?: number | null;

  /** @format int32 */
  received?: number | null;

  /** @format int32 */
  rejected?: number | null;
}

export interface CaseDetailsContactDataInfo {
  street?: string | null;

  /** @format double */
  houseNumber?: number | null;
  houseNumberAddition?: string | null;
  zipCode?: string | null;
  city?: string | null;
  emailAddress?: string | null;
  phoneNumberFixed?: string | null;
  phoneNumberWork?: string | null;
}

export interface CaseDetailsApplicantInfo {
  initials?: string | null;
  name?: string | null;
  prefix?: string | null;
  contactData?: CaseDetailsContactDataInfo[] | null;
}

export interface CaseDetailsLenderInfo {
  label?: string | null;
  name?: string | null;
  deepLink?: string | null;
}

export enum CaseDetailsClassificationEnum {
  VeryLowEnum = 'VeryLowEnum',
  LowEnum = 'LowEnum',
  MediumEnum = 'MediumEnum',
  HighEnum = 'HighEnum',
  VeryHighEnum = 'VeryHighEnum',
}

export interface CaseDetailsQualityScoreInfo {
  /** @format double */
  value?: number | null;
  classification?: CaseDetailsClassificationEnum;
}

export interface CaseDetailsTaskInfo {
  /** @format int32 */
  open?: number | null;
}

export interface CaseDetailsAssetInfo {
  contactData?: CaseDetailsContactDataInfo;
  typeOfRealEstate?: string | null;
  constructionType?: string | null;

  /** @format double */
  constructionYear?: number | null;
  hasBeenEvaluated?: boolean | null;

  /** @format date-time */
  evaluationDate?: string | null;

  /** @format double */
  purchasePrice?: number | null;

  /** @format double */
  marketValue?: number | null;

  /** @format double */
  forcedSaleValue?: number | null;
}

export interface CaseDetails {
  application?: CaseDetailsApplicationInfo;
  proofItem?: CaseDetailsProofItemInfo;
  applicants?: CaseDetailsApplicantInfo[] | null;
  lender?: CaseDetailsLenderInfo;
  qualityScore?: CaseDetailsQualityScoreInfo;
  task?: CaseDetailsTaskInfo;
  addresses?: CaseDetailsAssetInfo[] | null;
}

export interface BuyToLetApplication {
  /** @format binary */
  xlxs?: File | null;

  /** @format binary */
  pdf?: File | null;
  applicantName: string;
  extraInformation?: string | null;
}

export enum CivilStatus {
  Married = 'Married',
  MarriedLimited = 'MarriedLimited',
  MarriedWithSettlement = 'MarriedWithSettlement',
  Single = 'Single',
  LivingTogetherWithContract = 'LivingTogetherWithContract',
  LivingTogetherWithoutContract = 'LivingTogetherWithoutContract',
  CivilPartnership = 'CivilPartnership',
  CivilPartnershipLimited = 'CivilPartnershipLimited',
  CivilPartnershipWithConditions = 'CivilPartnershipWithConditions',
  MarriedForeignLaw = 'MarriedForeignLaw',
  Coheir = 'Coheir',
}

export enum Country {
  ABW = 'ABW',
  AFG = 'AFG',
  AGO = 'AGO',
  AIA = 'AIA',
  ALA = 'ALA',
  ALB = 'ALB',
  AND = 'AND',
  ARE = 'ARE',
  ARG = 'ARG',
  ARM = 'ARM',
  ASM = 'ASM',
  ATA = 'ATA',
  ATF = 'ATF',
  ATG = 'ATG',
  AUS = 'AUS',
  AUT = 'AUT',
  AZE = 'AZE',
  BDI = 'BDI',
  BEL = 'BEL',
  BEN = 'BEN',
  BES = 'BES',
  BFA = 'BFA',
  BGD = 'BGD',
  BGR = 'BGR',
  BHR = 'BHR',
  BHS = 'BHS',
  BIH = 'BIH',
  BLM = 'BLM',
  BLR = 'BLR',
  BLZ = 'BLZ',
  BMU = 'BMU',
  BOL = 'BOL',
  BRA = 'BRA',
  BRB = 'BRB',
  BRN = 'BRN',
  BTN = 'BTN',
  BVT = 'BVT',
  BWA = 'BWA',
  CAF = 'CAF',
  CAN = 'CAN',
  CCK = 'CCK',
  CHE = 'CHE',
  CHL = 'CHL',
  CHN = 'CHN',
  CIV = 'CIV',
  CMR = 'CMR',
  COD = 'COD',
  COG = 'COG',
  COK = 'COK',
  COL = 'COL',
  COM = 'COM',
  CPV = 'CPV',
  CRI = 'CRI',
  CUB = 'CUB',
  CUW = 'CUW',
  CXR = 'CXR',
  CYM = 'CYM',
  CYP = 'CYP',
  CZE = 'CZE',
  DEU = 'DEU',
  DJI = 'DJI',
  DMA = 'DMA',
  DNK = 'DNK',
  DOM = 'DOM',
  DZA = 'DZA',
  ECU = 'ECU',
  EGY = 'EGY',
  ERI = 'ERI',
  ESH = 'ESH',
  ESP = 'ESP',
  EST = 'EST',
  ETH = 'ETH',
  FIN = 'FIN',
  FJI = 'FJI',
  FLK = 'FLK',
  FRA = 'FRA',
  FRO = 'FRO',
  FSM = 'FSM',
  GAB = 'GAB',
  GBR = 'GBR',
  GEO = 'GEO',
  GGY = 'GGY',
  GHA = 'GHA',
  GIB = 'GIB',
  GIN = 'GIN',
  GLP = 'GLP',
  GMB = 'GMB',
  GNB = 'GNB',
  GNQ = 'GNQ',
  GRC = 'GRC',
  GRD = 'GRD',
  GRL = 'GRL',
  GTM = 'GTM',
  GUF = 'GUF',
  GUM = 'GUM',
  GUY = 'GUY',
  HKG = 'HKG',
  HMD = 'HMD',
  HND = 'HND',
  HRV = 'HRV',
  HTI = 'HTI',
  HUN = 'HUN',
  IDN = 'IDN',
  IMN = 'IMN',
  IND = 'IND',
  IOT = 'IOT',
  IRL = 'IRL',
  IRN = 'IRN',
  IRQ = 'IRQ',
  ISL = 'ISL',
  ISR = 'ISR',
  ITA = 'ITA',
  JAM = 'JAM',
  JEY = 'JEY',
  JOR = 'JOR',
  JPN = 'JPN',
  KAZ = 'KAZ',
  KEN = 'KEN',
  KGZ = 'KGZ',
  KHM = 'KHM',
  KIR = 'KIR',
  KNA = 'KNA',
  KOR = 'KOR',
  KWT = 'KWT',
  LAO = 'LAO',
  LBN = 'LBN',
  LBR = 'LBR',
  LBY = 'LBY',
  LCA = 'LCA',
  LIE = 'LIE',
  LKA = 'LKA',
  LSO = 'LSO',
  LTU = 'LTU',
  LUX = 'LUX',
  LVA = 'LVA',
  MAC = 'MAC',
  MAF = 'MAF',
  MAR = 'MAR',
  MCO = 'MCO',
  MDA = 'MDA',
  MDG = 'MDG',
  MDV = 'MDV',
  MEX = 'MEX',
  MHL = 'MHL',
  MKD = 'MKD',
  MLI = 'MLI',
  MLT = 'MLT',
  MMR = 'MMR',
  MNE = 'MNE',
  MNG = 'MNG',
  MNP = 'MNP',
  MOZ = 'MOZ',
  MRT = 'MRT',
  MSR = 'MSR',
  MTQ = 'MTQ',
  MUS = 'MUS',
  MWI = 'MWI',
  MYS = 'MYS',
  MYT = 'MYT',
  NAM = 'NAM',
  NCL = 'NCL',
  NER = 'NER',
  NFK = 'NFK',
  NGA = 'NGA',
  NIC = 'NIC',
  NIU = 'NIU',
  NLD = 'NLD',
  NOR = 'NOR',
  NPL = 'NPL',
  NRU = 'NRU',
  NZL = 'NZL',
  OMN = 'OMN',
  PAK = 'PAK',
  PAN = 'PAN',
  PCN = 'PCN',
  PER = 'PER',
  PHL = 'PHL',
  PLW = 'PLW',
  PNG = 'PNG',
  POL = 'POL',
  PRI = 'PRI',
  PRK = 'PRK',
  PRT = 'PRT',
  PRY = 'PRY',
  PSE = 'PSE',
  PYF = 'PYF',
  QAT = 'QAT',
  REU = 'REU',
  ROU = 'ROU',
  RUS = 'RUS',
  RWA = 'RWA',
  SAU = 'SAU',
  SDN = 'SDN',
  SEN = 'SEN',
  SGP = 'SGP',
  SGS = 'SGS',
  SHN = 'SHN',
  SJM = 'SJM',
  SLB = 'SLB',
  SLE = 'SLE',
  SLV = 'SLV',
  SMR = 'SMR',
  SOM = 'SOM',
  SPM = 'SPM',
  SRB = 'SRB',
  SSD = 'SSD',
  STP = 'STP',
  SUR = 'SUR',
  SVK = 'SVK',
  SVN = 'SVN',
  SWE = 'SWE',
  SWZ = 'SWZ',
  SXM = 'SXM',
  SYC = 'SYC',
  SYR = 'SYR',
  TCA = 'TCA',
  TCD = 'TCD',
  TGO = 'TGO',
  THA = 'THA',
  TJK = 'TJK',
  TKL = 'TKL',
  TKM = 'TKM',
  TLS = 'TLS',
  TON = 'TON',
  TTO = 'TTO',
  TUN = 'TUN',
  TUR = 'TUR',
  TUV = 'TUV',
  TWN = 'TWN',
  TZA = 'TZA',
  UGA = 'UGA',
  UKR = 'UKR',
  UMI = 'UMI',
  URY = 'URY',
  USA = 'USA',
  UZB = 'UZB',
  VAT = 'VAT',
  VCT = 'VCT',
  VEN = 'VEN',
  VGB = 'VGB',
  VIR = 'VIR',
  VNM = 'VNM',
  VUT = 'VUT',
  WLF = 'WLF',
  WSM = 'WSM',
  YEM = 'YEM',
  ZAF = 'ZAF',
  ZMB = 'ZMB',
  ZWE = 'ZWE',
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export enum Nationality {
  Mvbz0000 = 'Mvbz0000',
  Mvbz0001 = 'Mvbz0001',
  Mvbz0027 = 'Mvbz0027',
  Mvbz0028 = 'Mvbz0028',
  Mvbz0029 = 'Mvbz0029',
  Mvbz0030 = 'Mvbz0030',
  Mvbz0031 = 'Mvbz0031',
  Mvbz0032 = 'Mvbz0032',
  Mvbz0033 = 'Mvbz0033',
  Mvbz0034 = 'Mvbz0034',
  Mvbz0035 = 'Mvbz0035',
  Mvbz0036 = 'Mvbz0036',
  Mvbz0037 = 'Mvbz0037',
  Mvbz0038 = 'Mvbz0038',
  Mvbz0039 = 'Mvbz0039',
  Mvbz0040 = 'Mvbz0040',
  Mvbz0041 = 'Mvbz0041',
  Mvbz0042 = 'Mvbz0042',
  Mvbz0043 = 'Mvbz0043',
  Mvbz0044 = 'Mvbz0044',
  Mvbz0045 = 'Mvbz0045',
  Mvbz0046 = 'Mvbz0046',
  Mvbz0047 = 'Mvbz0047',
  Mvbz0048 = 'Mvbz0048',
  Mvbz0049 = 'Mvbz0049',
  Mvbz0050 = 'Mvbz0050',
  Mvbz0051 = 'Mvbz0051',
  Mvbz0052 = 'Mvbz0052',
  Mvbz0053 = 'Mvbz0053',
  Mvbz0054 = 'Mvbz0054',
  Mvbz0055 = 'Mvbz0055',
  Mvbz0056 = 'Mvbz0056',
  Mvbz0057 = 'Mvbz0057',
  Mvbz0058 = 'Mvbz0058',
  Mvbz0059 = 'Mvbz0059',
  Mvbz0060 = 'Mvbz0060',
  Mvbz0061 = 'Mvbz0061',
  Mvbz0062 = 'Mvbz0062',
  Mvbz0063 = 'Mvbz0063',
  Mvbz0064 = 'Mvbz0064',
  Mvbz0066 = 'Mvbz0066',
  Mvbz0067 = 'Mvbz0067',
  Mvbz0068 = 'Mvbz0068',
  Mvbz0069 = 'Mvbz0069',
  Mvbz0070 = 'Mvbz0070',
  Mvbz0071 = 'Mvbz0071',
  Mvbz0072 = 'Mvbz0072',
  Mvbz0073 = 'Mvbz0073',
  Mvbz0074 = 'Mvbz0074',
  Mvbz0076 = 'Mvbz0076',
  Mvbz0077 = 'Mvbz0077',
  Mvbz0079 = 'Mvbz0079',
  Mvbz0080 = 'Mvbz0080',
  Mvbz0081 = 'Mvbz0081',
  Mvbz0083 = 'Mvbz0083',
  Mvbz0084 = 'Mvbz0084',
  Mvbz0085 = 'Mvbz0085',
  Mvbz0086 = 'Mvbz0086',
  Mvbz0087 = 'Mvbz0087',
  Mvbz0100 = 'Mvbz0100',
  Mvbz0101 = 'Mvbz0101',
  Mvbz0104 = 'Mvbz0104',
  Mvbz0105 = 'Mvbz0105',
  Mvbz0106 = 'Mvbz0106',
  Mvbz0108 = 'Mvbz0108',
  Mvbz0109 = 'Mvbz0109',
  Mvbz0110 = 'Mvbz0110',
  Mvbz0111 = 'Mvbz0111',
  Mvbz0112 = 'Mvbz0112',
  Mvbz0113 = 'Mvbz0113',
  Mvbz0114 = 'Mvbz0114',
  Mvbz0115 = 'Mvbz0115',
  Mvbz0116 = 'Mvbz0116',
  Mvbz0117 = 'Mvbz0117',
  Mvbz0118 = 'Mvbz0118',
  Mvbz0119 = 'Mvbz0119',
  Mvbz0120 = 'Mvbz0120',
  Mvbz0121 = 'Mvbz0121',
  Mvbz0122 = 'Mvbz0122',
  Mvbz0123 = 'Mvbz0123',
  Mvbz0125 = 'Mvbz0125',
  Mvbz0126 = 'Mvbz0126',
  Mvbz0127 = 'Mvbz0127',
  Mvbz0128 = 'Mvbz0128',
  Mvbz0129 = 'Mvbz0129',
  Mvbz0130 = 'Mvbz0130',
  Mvbz0131 = 'Mvbz0131',
  Mvbz0132 = 'Mvbz0132',
  Mvbz0133 = 'Mvbz0133',
  Mvbz0134 = 'Mvbz0134',
  Mvbz0135 = 'Mvbz0135',
  Mvbz0136 = 'Mvbz0136',
  Mvbz0137 = 'Mvbz0137',
  Mvbz0138 = 'Mvbz0138',
  Mvbz0139 = 'Mvbz0139',
  Mvbz0140 = 'Mvbz0140',
  Mvbz0142 = 'Mvbz0142',
  Mvbz0143 = 'Mvbz0143',
  Mvbz0144 = 'Mvbz0144',
  Mvbz0145 = 'Mvbz0145',
  Mvbz0147 = 'Mvbz0147',
  Mvbz0148 = 'Mvbz0148',
  Mvbz0149 = 'Mvbz0149',
  Mvbz0151 = 'Mvbz0151',
  Mvbz0152 = 'Mvbz0152',
  Mvbz0154 = 'Mvbz0154',
  Mvbz0155 = 'Mvbz0155',
  Mvbz0156 = 'Mvbz0156',
  Mvbz0157 = 'Mvbz0157',
  Mvbz0200 = 'Mvbz0200',
  Mvbz0202 = 'Mvbz0202',
  Mvbz0204 = 'Mvbz0204',
  Mvbz0205 = 'Mvbz0205',
  Mvbz0206 = 'Mvbz0206',
  Mvbz0207 = 'Mvbz0207',
  Mvbz0208 = 'Mvbz0208',
  Mvbz0211 = 'Mvbz0211',
  Mvbz0212 = 'Mvbz0212',
  Mvbz0213 = 'Mvbz0213',
  Mvbz0214 = 'Mvbz0214',
  Mvbz0216 = 'Mvbz0216',
  Mvbz0218 = 'Mvbz0218',
  Mvbz0219 = 'Mvbz0219',
  Mvbz0222 = 'Mvbz0222',
  Mvbz0223 = 'Mvbz0223',
  Mvbz0250 = 'Mvbz0250',
  Mvbz0251 = 'Mvbz0251',
  Mvbz0252 = 'Mvbz0252',
  Mvbz0253 = 'Mvbz0253',
  Mvbz0254 = 'Mvbz0254',
  Mvbz0255 = 'Mvbz0255',
  Mvbz0256 = 'Mvbz0256',
  Mvbz0259 = 'Mvbz0259',
  Mvbz0261 = 'Mvbz0261',
  Mvbz0262 = 'Mvbz0262',
  Mvbz0263 = 'Mvbz0263',
  Mvbz0264 = 'Mvbz0264',
  Mvbz0265 = 'Mvbz0265',
  Mvbz0267 = 'Mvbz0267',
  Mvbz0268 = 'Mvbz0268',
  Mvbz0300 = 'Mvbz0300',
  Mvbz0301 = 'Mvbz0301',
  Mvbz0302 = 'Mvbz0302',
  Mvbz0304 = 'Mvbz0304',
  Mvbz0305 = 'Mvbz0305',
  Mvbz0306 = 'Mvbz0306',
  Mvbz0307 = 'Mvbz0307',
  Mvbz0308 = 'Mvbz0308',
  Mvbz0309 = 'Mvbz0309',
  Mvbz0310 = 'Mvbz0310',
  Mvbz0312 = 'Mvbz0312',
  Mvbz0313 = 'Mvbz0313',
  Mvbz0314 = 'Mvbz0314',
  Mvbz0315 = 'Mvbz0315',
  Mvbz0316 = 'Mvbz0316',
  Mvbz0317 = 'Mvbz0317',
  Mvbz0319 = 'Mvbz0319',
  Mvbz0320 = 'Mvbz0320',
  Mvbz0321 = 'Mvbz0321',
  Mvbz0322 = 'Mvbz0322',
  Mvbz0324 = 'Mvbz0324',
  Mvbz0325 = 'Mvbz0325',
  Mvbz0326 = 'Mvbz0326',
  Mvbz0327 = 'Mvbz0327',
  Mvbz0328 = 'Mvbz0328',
  Mvbz0329 = 'Mvbz0329',
  Mvbz0331 = 'Mvbz0331',
  Mvbz0333 = 'Mvbz0333',
  Mvbz0334 = 'Mvbz0334',
  Mvbz0335 = 'Mvbz0335',
  Mvbz0336 = 'Mvbz0336',
  Mvbz0337 = 'Mvbz0337',
  Mvbz0338 = 'Mvbz0338',
  Mvbz0339 = 'Mvbz0339',
  Mvbz0341 = 'Mvbz0341',
  Mvbz0342 = 'Mvbz0342',
  Mvbz0345 = 'Mvbz0345',
  Mvbz0400 = 'Mvbz0400',
  Mvbz0401 = 'Mvbz0401',
  Mvbz0402 = 'Mvbz0402',
  Mvbz0405 = 'Mvbz0405',
  Mvbz0421 = 'Mvbz0421',
  Mvbz0424 = 'Mvbz0424',
  Mvbz0425 = 'Mvbz0425',
  Mvbz0430 = 'Mvbz0430',
  Mvbz0431 = 'Mvbz0431',
  Mvbz0432 = 'Mvbz0432',
  Mvbz0437 = 'Mvbz0437',
  Mvbz0442 = 'Mvbz0442',
  Mvbz0443 = 'Mvbz0443',
  Mvbz0444 = 'Mvbz0444',
  Mvbz0445 = 'Mvbz0445',
  Mvbz0446 = 'Mvbz0446',
  Mvbz0447 = 'Mvbz0447',
  Mvbz0448 = 'Mvbz0448',
  Mvbz0449 = 'Mvbz0449',
  Mvbz0450 = 'Mvbz0450',
  Mvbz0451 = 'Mvbz0451',
  Mvbz0452 = 'Mvbz0452',
  Mvbz0454 = 'Mvbz0454',
  Mvbz0455 = 'Mvbz0455',
  Mvbz0499 = 'Mvbz0499',
}

export enum TypeOfIdentification {
  Passport = 'Passport',
  DriverLicense = 'DriverLicense',
  EuropeanID = 'EuropeanID',
  Visa = 'Visa',
  Unknown = 'Unknown',
}

export enum LegalForm {
  OneManBusiness = 'OneManBusiness',
  Partnership = 'Partnership',
  GeneralPartnership = 'GeneralPartnership',
  LimitedPartnership = 'LimitedPartnership',
  PrivateCompany = 'PrivateCompany',
  PublicCompany = 'PublicCompany',
  Foundation = 'Foundation',
}

export interface CreateClientReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;

  /** @format int32 */
  loanNumber: number;
  commercialLabel: string;
  name: string;
}

export interface DirectorReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;
  isLegalPerson?: boolean | null;
  firstName?: string | null;
  initials?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  gender?: Gender;

  /** @format date-time */
  dateOfBirth?: string | null;
  nationality?: Nationality;
  emailAddress?: string | null;
  privatePhoneNumber?: string | null;
  mobilePhoneNumber?: string | null;
  street?: string | null;

  /** @format int64 */
  houseNumber?: number | null;
  houseNumberExtension?: string | null;
  postalCode?: string | null;
  city?: string | null;
  country?: Country;
  typeOfIdentification?: TypeOfIdentification;
  identificationNumber?: string | null;
  hasExistingMortgageByMoneylender?: boolean | null;

  /** @format int32 */
  existingLoanNumber?: number | null;
  isMortgageRejected?: boolean | null;
  reasonMortgageRejection?: string | null;
  legalForm?: LegalForm;

  /** @format int32 */
  yearsOfBuyToLetExperience?: number | null;
  explanationOfBTLExperience?: string | null;
  civilStatus?: CivilStatus;
}

export enum TypeOfEmployment {
  Permanent = 'Permanent',
  TemporaryWithIntent = 'TemporaryWithIntent',
  TemporaryWithoutIntent = 'TemporaryWithoutIntent',
  FlexibleWithPerspective = 'FlexibleWithPerspective',
  FlexibleWithoutPerspective = 'FlexibleWithoutPerspective',
  SocialInsuranceWage = 'SocialInsuranceWage',
  TemporaryWithIntentLimited = 'TemporaryWithIntentLimited',
}

export enum TypeOfProfession {
  Isco0110 = 'Isco0110',
  Isco0210 = 'Isco0210',
  Isco0310 = 'Isco0310',
  Isco1111 = 'Isco1111',
  Isco1112 = 'Isco1112',
  Isco1113 = 'Isco1113',
  Isco1114 = 'Isco1114',
  Isco1120 = 'Isco1120',
  Isco1211 = 'Isco1211',
  Isco1212 = 'Isco1212',
  Isco1213 = 'Isco1213',
  Isco1219 = 'Isco1219',
  Isco1221 = 'Isco1221',
  Isco1222 = 'Isco1222',
  Isco1223 = 'Isco1223',
  Isco1311 = 'Isco1311',
  Isco1312 = 'Isco1312',
  Isco1321 = 'Isco1321',
  Isco1322 = 'Isco1322',
  Isco1323 = 'Isco1323',
  Isco1324 = 'Isco1324',
  Isco1330 = 'Isco1330',
  Isco1341 = 'Isco1341',
  Isco1342 = 'Isco1342',
  Isco1343 = 'Isco1343',
  Isco1344 = 'Isco1344',
  Isco1345 = 'Isco1345',
  Isco1346 = 'Isco1346',
  Isco1349 = 'Isco1349',
  Isco1411 = 'Isco1411',
  Isco1412 = 'Isco1412',
  Isco1420 = 'Isco1420',
  Isco1431 = 'Isco1431',
  Isco1439 = 'Isco1439',
  Isco2111 = 'Isco2111',
  Isco2112 = 'Isco2112',
  Isco2113 = 'Isco2113',
  Isco2114 = 'Isco2114',
  Isco2120 = 'Isco2120',
  Isco2131 = 'Isco2131',
  Isco2132 = 'Isco2132',
  Isco2133 = 'Isco2133',
  Isco2141 = 'Isco2141',
  Isco2142 = 'Isco2142',
  Isco2143 = 'Isco2143',
  Isco2144 = 'Isco2144',
  Isco2145 = 'Isco2145',
  Isco2146 = 'Isco2146',
  Isco2149 = 'Isco2149',
  Isco2151 = 'Isco2151',
  Isco2152 = 'Isco2152',
  Isco2153 = 'Isco2153',
  Isco2161 = 'Isco2161',
  Isco2162 = 'Isco2162',
  Isco2163 = 'Isco2163',
  Isco2164 = 'Isco2164',
  Isco2165 = 'Isco2165',
  Isco2166 = 'Isco2166',
  Isco2211 = 'Isco2211',
  Isco2212 = 'Isco2212',
  Isco2221 = 'Isco2221',
  Isco2222 = 'Isco2222',
  Isco2230 = 'Isco2230',
  Isco2240 = 'Isco2240',
  Isco2250 = 'Isco2250',
  Isco2261 = 'Isco2261',
  Isco2262 = 'Isco2262',
  Isco2263 = 'Isco2263',
  Isco2264 = 'Isco2264',
  Isco2265 = 'Isco2265',
  Isco2266 = 'Isco2266',
  Isco2267 = 'Isco2267',
  Isco2269 = 'Isco2269',
  Isco2310 = 'Isco2310',
  Isco2320 = 'Isco2320',
  Isco2330 = 'Isco2330',
  Isco2341 = 'Isco2341',
  Isco2342 = 'Isco2342',
  Isco2351 = 'Isco2351',
  Isco2352 = 'Isco2352',
  Isco2353 = 'Isco2353',
  Isco2354 = 'Isco2354',
  Isco2355 = 'Isco2355',
  Isco2356 = 'Isco2356',
  Isco2359 = 'Isco2359',
  Isco2411 = 'Isco2411',
  Isco2412 = 'Isco2412',
  Isco2413 = 'Isco2413',
  Isco2421 = 'Isco2421',
  Isco2422 = 'Isco2422',
  Isco2423 = 'Isco2423',
  Isco2424 = 'Isco2424',
  Isco2431 = 'Isco2431',
  Isco2432 = 'Isco2432',
  Isco2433 = 'Isco2433',
  Isco2434 = 'Isco2434',
  Isco2511 = 'Isco2511',
  Isco2512 = 'Isco2512',
  Isco2513 = 'Isco2513',
  Isco2514 = 'Isco2514',
  Isco2519 = 'Isco2519',
  Isco2521 = 'Isco2521',
  Isco2522 = 'Isco2522',
  Isco2523 = 'Isco2523',
  Isco2529 = 'Isco2529',
  Isco2611 = 'Isco2611',
  Isco2612 = 'Isco2612',
  Isco2619 = 'Isco2619',
  Isco2621 = 'Isco2621',
  Isco2622 = 'Isco2622',
  Isco2631 = 'Isco2631',
  Isco2632 = 'Isco2632',
  Isco2633 = 'Isco2633',
  Isco2634 = 'Isco2634',
  Isco2635 = 'Isco2635',
  Isco2636 = 'Isco2636',
  Isco2641 = 'Isco2641',
  Isco2642 = 'Isco2642',
  Isco2643 = 'Isco2643',
  Isco2651 = 'Isco2651',
  Isco2652 = 'Isco2652',
  Isco2653 = 'Isco2653',
  Isco2654 = 'Isco2654',
  Isco2655 = 'Isco2655',
  Isco2656 = 'Isco2656',
  Isco2659 = 'Isco2659',
  Isco3111 = 'Isco3111',
  Isco3112 = 'Isco3112',
  Isco3113 = 'Isco3113',
  Isco3114 = 'Isco3114',
  Isco3115 = 'Isco3115',
  Isco3116 = 'Isco3116',
  Isco3117 = 'Isco3117',
  Isco3118 = 'Isco3118',
  Isco3119 = 'Isco3119',
  Isco3121 = 'Isco3121',
  Isco3122 = 'Isco3122',
  Isco3123 = 'Isco3123',
  Isco3131 = 'Isco3131',
  Isco3132 = 'Isco3132',
  Isco3133 = 'Isco3133',
  Isco3134 = 'Isco3134',
  Isco3135 = 'Isco3135',
  Isco3139 = 'Isco3139',
  Isco3141 = 'Isco3141',
  Isco3142 = 'Isco3142',
  Isco3143 = 'Isco3143',
  Isco3151 = 'Isco3151',
  Isco3152 = 'Isco3152',
  Isco3153 = 'Isco3153',
  Isco3154 = 'Isco3154',
  Isco3155 = 'Isco3155',
  Isco3211 = 'Isco3211',
  Isco3212 = 'Isco3212',
  Isco3213 = 'Isco3213',
  Isco3214 = 'Isco3214',
  Isco3221 = 'Isco3221',
  Isco3222 = 'Isco3222',
  Isco3230 = 'Isco3230',
  Isco3240 = 'Isco3240',
  Isco3251 = 'Isco3251',
  Isco3252 = 'Isco3252',
  Isco3253 = 'Isco3253',
  Isco3254 = 'Isco3254',
  Isco3255 = 'Isco3255',
  Isco3256 = 'Isco3256',
  Isco3257 = 'Isco3257',
  Isco3258 = 'Isco3258',
  Isco3259 = 'Isco3259',
  Isco3311 = 'Isco3311',
  Isco3312 = 'Isco3312',
  Isco3313 = 'Isco3313',
  Isco3314 = 'Isco3314',
  Isco3315 = 'Isco3315',
  Isco3321 = 'Isco3321',
  Isco3322 = 'Isco3322',
  Isco3323 = 'Isco3323',
  Isco3324 = 'Isco3324',
  Isco3331 = 'Isco3331',
  Isco3332 = 'Isco3332',
  Isco3333 = 'Isco3333',
  Isco3334 = 'Isco3334',
  Isco3339 = 'Isco3339',
  Isco3341 = 'Isco3341',
  Isco3342 = 'Isco3342',
  Isco3343 = 'Isco3343',
  Isco3344 = 'Isco3344',
  Isco3351 = 'Isco3351',
  Isco3352 = 'Isco3352',
  Isco3353 = 'Isco3353',
  Isco3354 = 'Isco3354',
  Isco3355 = 'Isco3355',
  Isco3359 = 'Isco3359',
  Isco3411 = 'Isco3411',
  Isco3412 = 'Isco3412',
  Isco3413 = 'Isco3413',
  Isco3421 = 'Isco3421',
  Isco3422 = 'Isco3422',
  Isco3423 = 'Isco3423',
  Isco3431 = 'Isco3431',
  Isco3432 = 'Isco3432',
  Isco3433 = 'Isco3433',
  Isco3434 = 'Isco3434',
  Isco3435 = 'Isco3435',
  Isco3511 = 'Isco3511',
  Isco3512 = 'Isco3512',
  Isco3513 = 'Isco3513',
  Isco3514 = 'Isco3514',
  Isco3521 = 'Isco3521',
  Isco3522 = 'Isco3522',
  Isco4110 = 'Isco4110',
  Isco4120 = 'Isco4120',
  Isco4131 = 'Isco4131',
  Isco4132 = 'Isco4132',
  Isco4211 = 'Isco4211',
  Isco4212 = 'Isco4212',
  Isco4213 = 'Isco4213',
  Isco4214 = 'Isco4214',
  Isco4221 = 'Isco4221',
  Isco4222 = 'Isco4222',
  Isco4223 = 'Isco4223',
  Isco4224 = 'Isco4224',
  Isco4225 = 'Isco4225',
  Isco4226 = 'Isco4226',
  Isco4227 = 'Isco4227',
  Isco4229 = 'Isco4229',
  Isco4311 = 'Isco4311',
  Isco4312 = 'Isco4312',
  Isco4313 = 'Isco4313',
  Isco4321 = 'Isco4321',
  Isco4322 = 'Isco4322',
  Isco4323 = 'Isco4323',
  Isco4411 = 'Isco4411',
  Isco4412 = 'Isco4412',
  Isco4413 = 'Isco4413',
  Isco4414 = 'Isco4414',
  Isco4415 = 'Isco4415',
  Isco4416 = 'Isco4416',
  Isco4419 = 'Isco4419',
  Isco5111 = 'Isco5111',
  Isco5112 = 'Isco5112',
  Isco5113 = 'Isco5113',
  Isco5120 = 'Isco5120',
  Isco5131 = 'Isco5131',
  Isco5132 = 'Isco5132',
  Isco5141 = 'Isco5141',
  Isco5142 = 'Isco5142',
  Isco5151 = 'Isco5151',
  Isco5152 = 'Isco5152',
  Isco5153 = 'Isco5153',
  Isco5161 = 'Isco5161',
  Isco5162 = 'Isco5162',
  Isco5163 = 'Isco5163',
  Isco5164 = 'Isco5164',
  Isco5165 = 'Isco5165',
  Isco5169 = 'Isco5169',
  Isco5211 = 'Isco5211',
  Isco5212 = 'Isco5212',
  Isco5221 = 'Isco5221',
  Isco5222 = 'Isco5222',
  Isco5223 = 'Isco5223',
  Isco5230 = 'Isco5230',
  Isco5241 = 'Isco5241',
  Isco5242 = 'Isco5242',
  Isco5243 = 'Isco5243',
  Isco5244 = 'Isco5244',
  Isco5245 = 'Isco5245',
  Isco5246 = 'Isco5246',
  Isco5249 = 'Isco5249',
  Isco5311 = 'Isco5311',
  Isco5312 = 'Isco5312',
  Isco5321 = 'Isco5321',
  Isco5322 = 'Isco5322',
  Isco5329 = 'Isco5329',
  Isco5411 = 'Isco5411',
  Isco5412 = 'Isco5412',
  Isco5413 = 'Isco5413',
  Isco5414 = 'Isco5414',
  Isco5419 = 'Isco5419',
  Isco6111 = 'Isco6111',
  Isco6112 = 'Isco6112',
  Isco6113 = 'Isco6113',
  Isco6114 = 'Isco6114',
  Isco6121 = 'Isco6121',
  Isco6122 = 'Isco6122',
  Isco6123 = 'Isco6123',
  Isco6129 = 'Isco6129',
  Isco6130 = 'Isco6130',
  Isco6210 = 'Isco6210',
  Isco6221 = 'Isco6221',
  Isco6222 = 'Isco6222',
  Isco6223 = 'Isco6223',
  Isco6224 = 'Isco6224',
  Isco6310 = 'Isco6310',
  Isco6320 = 'Isco6320',
  Isco6330 = 'Isco6330',
  Isco6340 = 'Isco6340',
  Isco7111 = 'Isco7111',
  Isco7112 = 'Isco7112',
  Isco7113 = 'Isco7113',
  Isco7114 = 'Isco7114',
  Isco7115 = 'Isco7115',
  Isco7119 = 'Isco7119',
  Isco7121 = 'Isco7121',
  Isco7122 = 'Isco7122',
  Isco7123 = 'Isco7123',
  Isco7124 = 'Isco7124',
  Isco7125 = 'Isco7125',
  Isco7126 = 'Isco7126',
  Isco7127 = 'Isco7127',
  Isco7131 = 'Isco7131',
  Isco7132 = 'Isco7132',
  Isco7133 = 'Isco7133',
  Isco7211 = 'Isco7211',
  Isco7212 = 'Isco7212',
  Isco7213 = 'Isco7213',
  Isco7214 = 'Isco7214',
  Isco7215 = 'Isco7215',
  Isco7221 = 'Isco7221',
  Isco7222 = 'Isco7222',
  Isco7223 = 'Isco7223',
  Isco7224 = 'Isco7224',
  Isco7231 = 'Isco7231',
  Isco7232 = 'Isco7232',
  Isco7233 = 'Isco7233',
  Isco7234 = 'Isco7234',
  Isco7311 = 'Isco7311',
  Isco7312 = 'Isco7312',
  Isco7313 = 'Isco7313',
  Isco7314 = 'Isco7314',
  Isco7315 = 'Isco7315',
  Isco7316 = 'Isco7316',
  Isco7317 = 'Isco7317',
  Isco7318 = 'Isco7318',
  Isco7319 = 'Isco7319',
  Isco7321 = 'Isco7321',
  Isco7322 = 'Isco7322',
  Isco7323 = 'Isco7323',
  Isco7411 = 'Isco7411',
  Isco7412 = 'Isco7412',
  Isco7413 = 'Isco7413',
  Isco7421 = 'Isco7421',
  Isco7422 = 'Isco7422',
  Isco7511 = 'Isco7511',
  Isco7512 = 'Isco7512',
  Isco7513 = 'Isco7513',
  Isco7514 = 'Isco7514',
  Isco7515 = 'Isco7515',
  Isco7516 = 'Isco7516',
  Isco7521 = 'Isco7521',
  Isco7522 = 'Isco7522',
  Isco7523 = 'Isco7523',
  Isco7531 = 'Isco7531',
  Isco7532 = 'Isco7532',
  Isco7533 = 'Isco7533',
  Isco7534 = 'Isco7534',
  Isco7535 = 'Isco7535',
  Isco7536 = 'Isco7536',
  Isco7541 = 'Isco7541',
  Isco7542 = 'Isco7542',
  Isco7543 = 'Isco7543',
  Isco7544 = 'Isco7544',
  Isco7549 = 'Isco7549',
  Isco8111 = 'Isco8111',
  Isco8112 = 'Isco8112',
  Isco8113 = 'Isco8113',
  Isco8114 = 'Isco8114',
  Isco8121 = 'Isco8121',
  Isco8122 = 'Isco8122',
  Isco8131 = 'Isco8131',
  Isco8132 = 'Isco8132',
  Isco8141 = 'Isco8141',
  Isco8142 = 'Isco8142',
  Isco8143 = 'Isco8143',
  Isco8151 = 'Isco8151',
  Isco8152 = 'Isco8152',
  Isco8153 = 'Isco8153',
  Isco8154 = 'Isco8154',
  Isco8155 = 'Isco8155',
  Isco8156 = 'Isco8156',
  Isco8157 = 'Isco8157',
  Isco8159 = 'Isco8159',
  Isco8160 = 'Isco8160',
  Isco8171 = 'Isco8171',
  Isco8172 = 'Isco8172',
  Isco8181 = 'Isco8181',
  Isco8182 = 'Isco8182',
  Isco8183 = 'Isco8183',
  Isco8189 = 'Isco8189',
  Isco8211 = 'Isco8211',
  Isco8212 = 'Isco8212',
  Isco8219 = 'Isco8219',
  Isco8311 = 'Isco8311',
  Isco8312 = 'Isco8312',
  Isco8321 = 'Isco8321',
  Isco8322 = 'Isco8322',
  Isco8331 = 'Isco8331',
  Isco8332 = 'Isco8332',
  Isco8341 = 'Isco8341',
  Isco8342 = 'Isco8342',
  Isco8343 = 'Isco8343',
  Isco8344 = 'Isco8344',
  Isco8350 = 'Isco8350',
  Isco9111 = 'Isco9111',
  Isco9112 = 'Isco9112',
  Isco9121 = 'Isco9121',
  Isco9122 = 'Isco9122',
  Isco9123 = 'Isco9123',
  Isco9129 = 'Isco9129',
  Isco9211 = 'Isco9211',
  Isco9212 = 'Isco9212',
  Isco9213 = 'Isco9213',
  Isco9214 = 'Isco9214',
  Isco9215 = 'Isco9215',
  Isco9216 = 'Isco9216',
  Isco9311 = 'Isco9311',
  Isco9312 = 'Isco9312',
  Isco9313 = 'Isco9313',
  Isco9321 = 'Isco9321',
  Isco9329 = 'Isco9329',
  Isco9331 = 'Isco9331',
  Isco9332 = 'Isco9332',
  Isco9333 = 'Isco9333',
  Isco9334 = 'Isco9334',
  Isco9411 = 'Isco9411',
  Isco9412 = 'Isco9412',
  Isco9510 = 'Isco9510',
  Isco9520 = 'Isco9520',
  Isco9611 = 'Isco9611',
  Isco9612 = 'Isco9612',
  Isco9613 = 'Isco9613',
  Isco9621 = 'Isco9621',
  Isco9622 = 'Isco9622',
  Isco9623 = 'Isco9623',
  Isco9624 = 'Isco9624',
  Isco9629 = 'Isco9629',
  Isco9999 = 'Isco9999',
}

export enum FlexibleContractPhase {
  A = 'A',
  B = 'B',
  C = 'C',
}

export interface EmploymentIncomeReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;
  cityOfEmployer?: string | null;

  /** @format date-time */
  endDate?: string | null;

  /** @format double */
  flexibleAnnualIncome1?: number | null;

  /** @format double */
  flexibleAnnualIncome2?: number | null;

  /** @format double */
  flexibleAnnualIncome3?: number | null;

  /** @format double */
  grossSalary?: number | null;
  hasAttachmentOfEarning?: boolean | null;
  hasProbationaryPeriod?: boolean | null;

  /** @format double */
  holidayAllowance?: number | null;

  /** @format double */
  irregularHoursAllowance?: number | null;
  isPartOfAssessmentIncome?: boolean | null;
  isProbationaryPeriodExpired?: boolean | null;

  /** @format double */
  lifeCourseSavingsAmount?: number | null;

  /** @format double */
  lumpSumFee?: number | null;
  nameOfEmployer?: string | null;

  /** @format double */
  overtimeAllowance?: number | null;
  salaryPaymentTerm?: string | null;

  /** @format date-time */
  startDate?: string | null;

  /** @format double */
  surchargeIncome?: number | null;

  /** @format double */
  thirteenthMonth?: number | null;
  typeOfEmployment?: TypeOfEmployment;
  typeOfProfession?: TypeOfProfession;

  /** @format double */
  yearEndBonus?: number | null;
  flexibleContractPhase?: FlexibleContractPhase;

  /** @format int32 */
  averageHoursPerWeek?: number | null;
}

export enum SbiCode {
  Sbi0111 = 'Sbi0111',
  Sbi0113 = 'Sbi0113',
  Sbi01131 = 'Sbi01131',
  Sbi01132 = 'Sbi01132',
  Sbi01133 = 'Sbi01133',
  Sbi01134 = 'Sbi01134',
  Sbi0116 = 'Sbi0116',
  Sbi0119 = 'Sbi0119',
  Sbi01191 = 'Sbi01191',
  Sbi01192 = 'Sbi01192',
  Sbi01193 = 'Sbi01193',
  Sbi01199 = 'Sbi01199',
  Sbi0121 = 'Sbi0121',
  Sbi0124 = 'Sbi0124',
  Sbi01241 = 'Sbi01241',
  Sbi01242 = 'Sbi01242',
  Sbi0125 = 'Sbi0125',
  Sbi01251 = 'Sbi01251',
  Sbi01252 = 'Sbi01252',
  Sbi01253 = 'Sbi01253',
  Sbi01254 = 'Sbi01254',
  Sbi0127 = 'Sbi0127',
  Sbi0128 = 'Sbi0128',
  Sbi0129 = 'Sbi0129',
  Sbi0130 = 'Sbi0130',
  Sbi01301 = 'Sbi01301',
  Sbi01302 = 'Sbi01302',
  Sbi01303 = 'Sbi01303',
  Sbi01304 = 'Sbi01304',
  Sbi01305 = 'Sbi01305',
  Sbi01309 = 'Sbi01309',
  Sbi0141 = 'Sbi0141',
  Sbi01411 = 'Sbi01411',
  Sbi01412 = 'Sbi01412',
  Sbi0142 = 'Sbi0142',
  Sbi01421 = 'Sbi01421',
  Sbi01422 = 'Sbi01422',
  Sbi0143 = 'Sbi0143',
  Sbi0145 = 'Sbi0145',
  Sbi01451 = 'Sbi01451',
  Sbi01452 = 'Sbi01452',
  Sbi0146 = 'Sbi0146',
  Sbi01461 = 'Sbi01461',
  Sbi01462 = 'Sbi01462',
  Sbi01463 = 'Sbi01463',
  Sbi0147 = 'Sbi0147',
  Sbi01471 = 'Sbi01471',
  Sbi01472 = 'Sbi01472',
  Sbi01473 = 'Sbi01473',
  Sbi01479 = 'Sbi01479',
  Sbi0149 = 'Sbi0149',
  Sbi01491 = 'Sbi01491',
  Sbi01499 = 'Sbi01499',
  Sbi0150 = 'Sbi0150',
  Sbi0161 = 'Sbi0161',
  Sbi0162 = 'Sbi0162',
  Sbi0163 = 'Sbi0163',
  Sbi0164 = 'Sbi0164',
  Sbi0170 = 'Sbi0170',
  Sbi0210 = 'Sbi0210',
  Sbi0220 = 'Sbi0220',
  Sbi0240 = 'Sbi0240',
  Sbi0311 = 'Sbi0311',
  Sbi0312 = 'Sbi0312',
  Sbi0321 = 'Sbi0321',
  Sbi0322 = 'Sbi0322',
  Sbi0610 = 'Sbi0610',
  Sbi0620 = 'Sbi0620',
  Sbi0812 = 'Sbi0812',
  Sbi0892 = 'Sbi0892',
  Sbi0893 = 'Sbi0893',
  Sbi0899 = 'Sbi0899',
  Sbi0910 = 'Sbi0910',
  Sbi0990 = 'Sbi0990',
  Sbi1011 = 'Sbi1011',
  Sbi1012 = 'Sbi1012',
  Sbi1013 = 'Sbi1013',
  Sbi1020 = 'Sbi1020',
  Sbi1031 = 'Sbi1031',
  Sbi1032 = 'Sbi1032',
  Sbi1039 = 'Sbi1039',
  Sbi1041 = 'Sbi1041',
  Sbi1042 = 'Sbi1042',
  Sbi1051 = 'Sbi1051',
  Sbi1052 = 'Sbi1052',
  Sbi1061 = 'Sbi1061',
  Sbi1062 = 'Sbi1062',
  Sbi1071 = 'Sbi1071',
  Sbi1072 = 'Sbi1072',
  Sbi1073 = 'Sbi1073',
  Sbi1081 = 'Sbi1081',
  Sbi1082 = 'Sbi1082',
  Sbi10821 = 'Sbi10821',
  Sbi10822 = 'Sbi10822',
  Sbi1083 = 'Sbi1083',
  Sbi1084 = 'Sbi1084',
  Sbi1085 = 'Sbi1085',
  Sbi1086 = 'Sbi1086',
  Sbi1089 = 'Sbi1089',
  Sbi1091 = 'Sbi1091',
  Sbi1092 = 'Sbi1092',
  Sbi1101 = 'Sbi1101',
  Sbi1102 = 'Sbi1102',
  Sbi1103 = 'Sbi1103',
  Sbi1104 = 'Sbi1104',
  Sbi1105 = 'Sbi1105',
  Sbi1106 = 'Sbi1106',
  Sbi1107 = 'Sbi1107',
  Sbi1200 = 'Sbi1200',
  Sbi1310 = 'Sbi1310',
  Sbi1320 = 'Sbi1320',
  Sbi1330 = 'Sbi1330',
  Sbi1391 = 'Sbi1391',
  Sbi1392 = 'Sbi1392',
  Sbi1393 = 'Sbi1393',
  Sbi1394 = 'Sbi1394',
  Sbi1395 = 'Sbi1395',
  Sbi1396 = 'Sbi1396',
  Sbi1399 = 'Sbi1399',
  Sbi1411 = 'Sbi1411',
  Sbi1412 = 'Sbi1412',
  Sbi1413 = 'Sbi1413',
  Sbi1414 = 'Sbi1414',
  Sbi1419 = 'Sbi1419',
  Sbi1420 = 'Sbi1420',
  Sbi1431 = 'Sbi1431',
  Sbi1439 = 'Sbi1439',
  Sbi1511 = 'Sbi1511',
  Sbi1512 = 'Sbi1512',
  Sbi1520 = 'Sbi1520',
  Sbi1610 = 'Sbi1610',
  Sbi16101 = 'Sbi16101',
  Sbi16102 = 'Sbi16102',
  Sbi1621 = 'Sbi1621',
  Sbi1622 = 'Sbi1622',
  Sbi1623 = 'Sbi1623',
  Sbi16231 = 'Sbi16231',
  Sbi16239 = 'Sbi16239',
  Sbi1624 = 'Sbi1624',
  Sbi1629 = 'Sbi1629',
  Sbi1711 = 'Sbi1711',
  Sbi1712 = 'Sbi1712',
  Sbi17121 = 'Sbi17121',
  Sbi17122 = 'Sbi17122',
  Sbi17129 = 'Sbi17129',
  Sbi1721 = 'Sbi1721',
  Sbi17211 = 'Sbi17211',
  Sbi17212 = 'Sbi17212',
  Sbi1722 = 'Sbi1722',
  Sbi1723 = 'Sbi1723',
  Sbi1724 = 'Sbi1724',
  Sbi1729 = 'Sbi1729',
  Sbi1811 = 'Sbi1811',
  Sbi1812 = 'Sbi1812',
  Sbi18121 = 'Sbi18121',
  Sbi18122 = 'Sbi18122',
  Sbi18123 = 'Sbi18123',
  Sbi18124 = 'Sbi18124',
  Sbi18125 = 'Sbi18125',
  Sbi18129 = 'Sbi18129',
  Sbi1813 = 'Sbi1813',
  Sbi1814 = 'Sbi1814',
  Sbi1820 = 'Sbi1820',
  Sbi1910 = 'Sbi1910',
  Sbi1920 = 'Sbi1920',
  Sbi19201 = 'Sbi19201',
  Sbi19202 = 'Sbi19202',
  Sbi2011 = 'Sbi2011',
  Sbi2012 = 'Sbi2012',
  Sbi2013 = 'Sbi2013',
  Sbi2014 = 'Sbi2014',
  Sbi20141 = 'Sbi20141',
  Sbi20149 = 'Sbi20149',
  Sbi2015 = 'Sbi2015',
  Sbi2016 = 'Sbi2016',
  Sbi2017 = 'Sbi2017',
  Sbi2020 = 'Sbi2020',
  Sbi2030 = 'Sbi2030',
  Sbi2041 = 'Sbi2041',
  Sbi2042 = 'Sbi2042',
  Sbi2051 = 'Sbi2051',
  Sbi2052 = 'Sbi2052',
  Sbi2053 = 'Sbi2053',
  Sbi2059 = 'Sbi2059',
  Sbi2060 = 'Sbi2060',
  Sbi2110 = 'Sbi2110',
  Sbi2120 = 'Sbi2120',
  Sbi2211 = 'Sbi2211',
  Sbi2219 = 'Sbi2219',
  Sbi2221 = 'Sbi2221',
  Sbi2222 = 'Sbi2222',
  Sbi2223 = 'Sbi2223',
  Sbi2229 = 'Sbi2229',
  Sbi2311 = 'Sbi2311',
  Sbi2312 = 'Sbi2312',
  Sbi2313 = 'Sbi2313',
  Sbi2314 = 'Sbi2314',
  Sbi2319 = 'Sbi2319',
  Sbi2320 = 'Sbi2320',
  Sbi2331 = 'Sbi2331',
  Sbi2332 = 'Sbi2332',
  Sbi2341 = 'Sbi2341',
  Sbi2342 = 'Sbi2342',
  Sbi2343 = 'Sbi2343',
  Sbi2344 = 'Sbi2344',
  Sbi2349 = 'Sbi2349',
  Sbi2351 = 'Sbi2351',
  Sbi2352 = 'Sbi2352',
  Sbi2361 = 'Sbi2361',
  Sbi23611 = 'Sbi23611',
  Sbi23612 = 'Sbi23612',
  Sbi2362 = 'Sbi2362',
  Sbi2363 = 'Sbi2363',
  Sbi2364 = 'Sbi2364',
  Sbi2365 = 'Sbi2365',
  Sbi2369 = 'Sbi2369',
  Sbi2370 = 'Sbi2370',
  Sbi2391 = 'Sbi2391',
  Sbi2399 = 'Sbi2399',
  Sbi2410 = 'Sbi2410',
  Sbi2420 = 'Sbi2420',
  Sbi2431 = 'Sbi2431',
  Sbi2432 = 'Sbi2432',
  Sbi2433 = 'Sbi2433',
  Sbi2434 = 'Sbi2434',
  Sbi2441 = 'Sbi2441',
  Sbi2442 = 'Sbi2442',
  Sbi2443 = 'Sbi2443',
  Sbi2444 = 'Sbi2444',
  Sbi2445 = 'Sbi2445',
  Sbi2446 = 'Sbi2446',
  Sbi2451 = 'Sbi2451',
  Sbi2452 = 'Sbi2452',
  Sbi2453 = 'Sbi2453',
  Sbi2454 = 'Sbi2454',
  Sbi2511 = 'Sbi2511',
  Sbi2512 = 'Sbi2512',
  Sbi2521 = 'Sbi2521',
  Sbi2529 = 'Sbi2529',
  Sbi2530 = 'Sbi2530',
  Sbi2540 = 'Sbi2540',
  Sbi2550 = 'Sbi2550',
  Sbi2561 = 'Sbi2561',
  Sbi2562 = 'Sbi2562',
  Sbi2571 = 'Sbi2571',
  Sbi2572 = 'Sbi2572',
  Sbi2573 = 'Sbi2573',
  Sbi2591 = 'Sbi2591',
  Sbi2592 = 'Sbi2592',
  Sbi2593 = 'Sbi2593',
  Sbi2594 = 'Sbi2594',
  Sbi2599 = 'Sbi2599',
  Sbi2611 = 'Sbi2611',
  Sbi2612 = 'Sbi2612',
  Sbi2620 = 'Sbi2620',
  Sbi2630 = 'Sbi2630',
  Sbi2640 = 'Sbi2640',
  Sbi2651 = 'Sbi2651',
  Sbi2652 = 'Sbi2652',
  Sbi2660 = 'Sbi2660',
  Sbi2670 = 'Sbi2670',
  Sbi2680 = 'Sbi2680',
  Sbi2711 = 'Sbi2711',
  Sbi2712 = 'Sbi2712',
  Sbi2720 = 'Sbi2720',
  Sbi2731 = 'Sbi2731',
  Sbi2732 = 'Sbi2732',
  Sbi2733 = 'Sbi2733',
  Sbi2740 = 'Sbi2740',
  Sbi2751 = 'Sbi2751',
  Sbi2752 = 'Sbi2752',
  Sbi2790 = 'Sbi2790',
  Sbi2811 = 'Sbi2811',
  Sbi2812 = 'Sbi2812',
  Sbi2813 = 'Sbi2813',
  Sbi2814 = 'Sbi2814',
  Sbi2815 = 'Sbi2815',
  Sbi2821 = 'Sbi2821',
  Sbi2822 = 'Sbi2822',
  Sbi2823 = 'Sbi2823',
  Sbi2824 = 'Sbi2824',
  Sbi2825 = 'Sbi2825',
  Sbi2829 = 'Sbi2829',
  Sbi2830 = 'Sbi2830',
  Sbi2841 = 'Sbi2841',
  Sbi2849 = 'Sbi2849',
  Sbi2891 = 'Sbi2891',
  Sbi2892 = 'Sbi2892',
  Sbi2893 = 'Sbi2893',
  Sbi2894 = 'Sbi2894',
  Sbi2895 = 'Sbi2895',
  Sbi2896 = 'Sbi2896',
  Sbi2899 = 'Sbi2899',
  Sbi2910 = 'Sbi2910',
  Sbi2920 = 'Sbi2920',
  Sbi29201 = 'Sbi29201',
  Sbi29202 = 'Sbi29202',
  Sbi2931 = 'Sbi2931',
  Sbi2932 = 'Sbi2932',
  Sbi3011 = 'Sbi3011',
  Sbi3012 = 'Sbi3012',
  Sbi3020 = 'Sbi3020',
  Sbi3030 = 'Sbi3030',
  Sbi3040 = 'Sbi3040',
  Sbi3091 = 'Sbi3091',
  Sbi3092 = 'Sbi3092',
  Sbi3099 = 'Sbi3099',
  Sbi3101 = 'Sbi3101',
  Sbi31011 = 'Sbi31011',
  Sbi31012 = 'Sbi31012',
  Sbi3102 = 'Sbi3102',
  Sbi3103 = 'Sbi3103',
  Sbi3109 = 'Sbi3109',
  Sbi3211 = 'Sbi3211',
  Sbi3212 = 'Sbi3212',
  Sbi3213 = 'Sbi3213',
  Sbi3220 = 'Sbi3220',
  Sbi3230 = 'Sbi3230',
  Sbi3240 = 'Sbi3240',
  Sbi3250 = 'Sbi3250',
  Sbi32501 = 'Sbi32501',
  Sbi32502 = 'Sbi32502',
  Sbi3291 = 'Sbi3291',
  Sbi3299 = 'Sbi3299',
  Sbi32991 = 'Sbi32991',
  Sbi32999 = 'Sbi32999',
  Sbi3311 = 'Sbi3311',
  Sbi3312 = 'Sbi3312',
  Sbi33121 = 'Sbi33121',
  Sbi33122 = 'Sbi33122',
  Sbi33123 = 'Sbi33123',
  Sbi3313 = 'Sbi3313',
  Sbi3314 = 'Sbi3314',
  Sbi3315 = 'Sbi3315',
  Sbi3316 = 'Sbi3316',
  Sbi3317 = 'Sbi3317',
  Sbi3319 = 'Sbi3319',
  Sbi3321 = 'Sbi3321',
  Sbi3322 = 'Sbi3322',
  Sbi33221 = 'Sbi33221',
  Sbi33222 = 'Sbi33222',
  Sbi33223 = 'Sbi33223',
  Sbi3323 = 'Sbi3323',
  Sbi3324 = 'Sbi3324',
  Sbi3329 = 'Sbi3329',
  Sbi3511 = 'Sbi3511',
  Sbi35111 = 'Sbi35111',
  Sbi35112 = 'Sbi35112',
  Sbi35113 = 'Sbi35113',
  Sbi3512 = 'Sbi3512',
  Sbi3513 = 'Sbi3513',
  Sbi3514 = 'Sbi3514',
  Sbi3520 = 'Sbi3520',
  Sbi3530 = 'Sbi3530',
  Sbi3600 = 'Sbi3600',
  Sbi3700 = 'Sbi3700',
  Sbi3811 = 'Sbi3811',
  Sbi3812 = 'Sbi3812',
  Sbi3821 = 'Sbi3821',
  Sbi3822 = 'Sbi3822',
  Sbi3831 = 'Sbi3831',
  Sbi3832 = 'Sbi3832',
  Sbi3900 = 'Sbi3900',
  Sbi4110 = 'Sbi4110',
  Sbi4120 = 'Sbi4120',
  Sbi4211 = 'Sbi4211',
  Sbi42111 = 'Sbi42111',
  Sbi42112 = 'Sbi42112',
  Sbi4212 = 'Sbi4212',
  Sbi4213 = 'Sbi4213',
  Sbi4221 = 'Sbi4221',
  Sbi4222 = 'Sbi4222',
  Sbi4291 = 'Sbi4291',
  Sbi4299 = 'Sbi4299',
  Sbi4311 = 'Sbi4311',
  Sbi4312 = 'Sbi4312',
  Sbi4313 = 'Sbi4313',
  Sbi4321 = 'Sbi4321',
  Sbi4322 = 'Sbi4322',
  Sbi43221 = 'Sbi43221',
  Sbi43222 = 'Sbi43222',
  Sbi4329 = 'Sbi4329',
  Sbi4331 = 'Sbi4331',
  Sbi4332 = 'Sbi4332',
  Sbi4333 = 'Sbi4333',
  Sbi4334 = 'Sbi4334',
  Sbi4339 = 'Sbi4339',
  Sbi4391 = 'Sbi4391',
  Sbi4399 = 'Sbi4399',
  Sbi43991 = 'Sbi43991',
  Sbi43992 = 'Sbi43992',
  Sbi43993 = 'Sbi43993',
  Sbi43999 = 'Sbi43999',
  Sbi4511 = 'Sbi4511',
  Sbi45111 = 'Sbi45111',
  Sbi45112 = 'Sbi45112',
  Sbi4519 = 'Sbi4519',
  Sbi45191 = 'Sbi45191',
  Sbi45192 = 'Sbi45192',
  Sbi45193 = 'Sbi45193',
  Sbi45194 = 'Sbi45194',
  Sbi4520 = 'Sbi4520',
  Sbi45201 = 'Sbi45201',
  Sbi45202 = 'Sbi45202',
  Sbi45203 = 'Sbi45203',
  Sbi45204 = 'Sbi45204',
  Sbi45205 = 'Sbi45205',
  Sbi4531 = 'Sbi4531',
  Sbi45311 = 'Sbi45311',
  Sbi45312 = 'Sbi45312',
  Sbi4532 = 'Sbi4532',
  Sbi4540 = 'Sbi4540',
  Sbi45401 = 'Sbi45401',
  Sbi45402 = 'Sbi45402',
  Sbi4611 = 'Sbi4611',
  Sbi4612 = 'Sbi4612',
  Sbi4613 = 'Sbi4613',
  Sbi4614 = 'Sbi4614',
  Sbi4615 = 'Sbi4615',
  Sbi4616 = 'Sbi4616',
  Sbi4617 = 'Sbi4617',
  Sbi4618 = 'Sbi4618',
  Sbi4619 = 'Sbi4619',
  Sbi4621 = 'Sbi4621',
  Sbi46211 = 'Sbi46211',
  Sbi46212 = 'Sbi46212',
  Sbi46213 = 'Sbi46213',
  Sbi46214 = 'Sbi46214',
  Sbi46215 = 'Sbi46215',
  Sbi46216 = 'Sbi46216',
  Sbi46217 = 'Sbi46217',
  Sbi46218 = 'Sbi46218',
  Sbi46219 = 'Sbi46219',
  Sbi4622 = 'Sbi4622',
  Sbi4623 = 'Sbi4623',
  Sbi46231 = 'Sbi46231',
  Sbi46232 = 'Sbi46232',
  Sbi4624 = 'Sbi4624',
  Sbi46241 = 'Sbi46241',
  Sbi46242 = 'Sbi46242',
  Sbi4631 = 'Sbi4631',
  Sbi46311 = 'Sbi46311',
  Sbi46312 = 'Sbi46312',
  Sbi4632 = 'Sbi4632',
  Sbi4633 = 'Sbi4633',
  Sbi46331 = 'Sbi46331',
  Sbi46332 = 'Sbi46332',
  Sbi4634 = 'Sbi4634',
  Sbi4635 = 'Sbi4635',
  Sbi4636 = 'Sbi4636',
  Sbi4637 = 'Sbi4637',
  Sbi4638 = 'Sbi4638',
  Sbi46381 = 'Sbi46381',
  Sbi46382 = 'Sbi46382',
  Sbi46383 = 'Sbi46383',
  Sbi46384 = 'Sbi46384',
  Sbi46389 = 'Sbi46389',
  Sbi4639 = 'Sbi4639',
  Sbi4641 = 'Sbi4641',
  Sbi46411 = 'Sbi46411',
  Sbi46412 = 'Sbi46412',
  Sbi4642 = 'Sbi4642',
  Sbi46421 = 'Sbi46421',
  Sbi46422 = 'Sbi46422',
  Sbi46423 = 'Sbi46423',
  Sbi46424 = 'Sbi46424',
  Sbi46425 = 'Sbi46425',
  Sbi46429 = 'Sbi46429',
  Sbi4643 = 'Sbi4643',
  Sbi46431 = 'Sbi46431',
  Sbi46432 = 'Sbi46432',
  Sbi46433 = 'Sbi46433',
  Sbi46434 = 'Sbi46434',
  Sbi46435 = 'Sbi46435',
  Sbi46436 = 'Sbi46436',
  Sbi4644 = 'Sbi4644',
  Sbi46441 = 'Sbi46441',
  Sbi46442 = 'Sbi46442',
  Sbi4645 = 'Sbi4645',
  Sbi4646 = 'Sbi4646',
  Sbi46461 = 'Sbi46461',
  Sbi46462 = 'Sbi46462',
  Sbi4647 = 'Sbi4647',
  Sbi46471 = 'Sbi46471',
  Sbi46472 = 'Sbi46472',
  Sbi46473 = 'Sbi46473',
  Sbi4648 = 'Sbi4648',
  Sbi4649 = 'Sbi4649',
  Sbi46491 = 'Sbi46491',
  Sbi46492 = 'Sbi46492',
  Sbi46493 = 'Sbi46493',
  Sbi46494 = 'Sbi46494',
  Sbi46495 = 'Sbi46495',
  Sbi46496 = 'Sbi46496',
  Sbi46497 = 'Sbi46497',
  Sbi46498 = 'Sbi46498',
  Sbi46499 = 'Sbi46499',
  Sbi4651 = 'Sbi4651',
  Sbi4652 = 'Sbi4652',
  Sbi4661 = 'Sbi4661',
  Sbi4662 = 'Sbi4662',
  Sbi4663 = 'Sbi4663',
  Sbi4664 = 'Sbi4664',
  Sbi4665 = 'Sbi4665',
  Sbi4666 = 'Sbi4666',
  Sbi4668 = 'Sbi4668',
  Sbi46681 = 'Sbi46681',
  Sbi46682 = 'Sbi46682',
  Sbi4669 = 'Sbi4669',
  Sbi46691 = 'Sbi46691',
  Sbi46692 = 'Sbi46692',
  Sbi46693 = 'Sbi46693',
  Sbi46694 = 'Sbi46694',
  Sbi46695 = 'Sbi46695',
  Sbi46696 = 'Sbi46696',
  Sbi46697 = 'Sbi46697',
  Sbi46699 = 'Sbi46699',
  Sbi4671 = 'Sbi4671',
  Sbi46711 = 'Sbi46711',
  Sbi46712 = 'Sbi46712',
  Sbi46713 = 'Sbi46713',
  Sbi4672 = 'Sbi4672',
  Sbi46721 = 'Sbi46721',
  Sbi46722 = 'Sbi46722',
  Sbi46723 = 'Sbi46723',
  Sbi4673 = 'Sbi4673',
  Sbi46731 = 'Sbi46731',
  Sbi46732 = 'Sbi46732',
  Sbi46733 = 'Sbi46733',
  Sbi46734 = 'Sbi46734',
  Sbi46735 = 'Sbi46735',
  Sbi46736 = 'Sbi46736',
  Sbi46737 = 'Sbi46737',
  Sbi46738 = 'Sbi46738',
  Sbi46739 = 'Sbi46739',
  Sbi4674 = 'Sbi4674',
  Sbi46741 = 'Sbi46741',
  Sbi46742 = 'Sbi46742',
  Sbi4675 = 'Sbi4675',
  Sbi46751 = 'Sbi46751',
  Sbi46752 = 'Sbi46752',
  Sbi4676 = 'Sbi4676',
  Sbi46761 = 'Sbi46761',
  Sbi46762 = 'Sbi46762',
  Sbi46769 = 'Sbi46769',
  Sbi4677 = 'Sbi4677',
  Sbi46771 = 'Sbi46771',
  Sbi46772 = 'Sbi46772',
  Sbi46779 = 'Sbi46779',
  Sbi4690 = 'Sbi4690',
  Sbi46901 = 'Sbi46901',
  Sbi46902 = 'Sbi46902',
  Sbi4711 = 'Sbi4711',
  Sbi4719 = 'Sbi4719',
  Sbi47191 = 'Sbi47191',
  Sbi47192 = 'Sbi47192',
  Sbi4721 = 'Sbi4721',
  Sbi4722 = 'Sbi4722',
  Sbi47221 = 'Sbi47221',
  Sbi47222 = 'Sbi47222',
  Sbi4723 = 'Sbi4723',
  Sbi4724 = 'Sbi4724',
  Sbi47241 = 'Sbi47241',
  Sbi47242 = 'Sbi47242',
  Sbi4725 = 'Sbi4725',
  Sbi4726 = 'Sbi4726',
  Sbi4729 = 'Sbi4729',
  Sbi47291 = 'Sbi47291',
  Sbi47292 = 'Sbi47292',
  Sbi47293 = 'Sbi47293',
  Sbi47299 = 'Sbi47299',
  Sbi4730 = 'Sbi4730',
  Sbi4741 = 'Sbi4741',
  Sbi4742 = 'Sbi4742',
  Sbi4743 = 'Sbi4743',
  Sbi47431 = 'Sbi47431',
  Sbi47432 = 'Sbi47432',
  Sbi4751 = 'Sbi4751',
  Sbi47511 = 'Sbi47511',
  Sbi47512 = 'Sbi47512',
  Sbi47513 = 'Sbi47513',
  Sbi4752 = 'Sbi4752',
  Sbi47521 = 'Sbi47521',
  Sbi47522 = 'Sbi47522',
  Sbi47523 = 'Sbi47523',
  Sbi47524 = 'Sbi47524',
  Sbi47525 = 'Sbi47525',
  Sbi47526 = 'Sbi47526',
  Sbi47527 = 'Sbi47527',
  Sbi47528 = 'Sbi47528',
  Sbi4753 = 'Sbi4753',
  Sbi4754 = 'Sbi4754',
  Sbi47541 = 'Sbi47541',
  Sbi47542 = 'Sbi47542',
  Sbi47543 = 'Sbi47543',
  Sbi47544 = 'Sbi47544',
  Sbi4759 = 'Sbi4759',
  Sbi47591 = 'Sbi47591',
  Sbi47592 = 'Sbi47592',
  Sbi47593 = 'Sbi47593',
  Sbi47594 = 'Sbi47594',
  Sbi47595 = 'Sbi47595',
  Sbi47596 = 'Sbi47596',
  Sbi47597 = 'Sbi47597',
  Sbi4761 = 'Sbi4761',
  Sbi4762 = 'Sbi4762',
  Sbi4763 = 'Sbi4763',
  Sbi4764 = 'Sbi4764',
  Sbi47641 = 'Sbi47641',
  Sbi47642 = 'Sbi47642',
  Sbi47643 = 'Sbi47643',
  Sbi47644 = 'Sbi47644',
  Sbi4765 = 'Sbi4765',
  Sbi4771 = 'Sbi4771',
  Sbi47711 = 'Sbi47711',
  Sbi47712 = 'Sbi47712',
  Sbi47713 = 'Sbi47713',
  Sbi47714 = 'Sbi47714',
  Sbi47715 = 'Sbi47715',
  Sbi47716 = 'Sbi47716',
  Sbi47717 = 'Sbi47717',
  Sbi47718 = 'Sbi47718',
  Sbi4772 = 'Sbi4772',
  Sbi47721 = 'Sbi47721',
  Sbi47722 = 'Sbi47722',
  Sbi4773 = 'Sbi4773',
  Sbi4774 = 'Sbi4774',
  Sbi47741 = 'Sbi47741',
  Sbi47742 = 'Sbi47742',
  Sbi4775 = 'Sbi4775',
  Sbi4776 = 'Sbi4776',
  Sbi47761 = 'Sbi47761',
  Sbi47762 = 'Sbi47762',
  Sbi47763 = 'Sbi47763',
  Sbi4777 = 'Sbi4777',
  Sbi4778 = 'Sbi4778',
  Sbi47781 = 'Sbi47781',
  Sbi47782 = 'Sbi47782',
  Sbi47783 = 'Sbi47783',
  Sbi47789 = 'Sbi47789',
  Sbi4779 = 'Sbi4779',
  Sbi47791 = 'Sbi47791',
  Sbi47792 = 'Sbi47792',
  Sbi47793 = 'Sbi47793',
  Sbi4781 = 'Sbi4781',
  Sbi47811 = 'Sbi47811',
  Sbi47819 = 'Sbi47819',
  Sbi4782 = 'Sbi4782',
  Sbi4789 = 'Sbi4789',
  Sbi47891 = 'Sbi47891',
  Sbi47892 = 'Sbi47892',
  Sbi47899 = 'Sbi47899',
  Sbi4791 = 'Sbi4791',
  Sbi47911 = 'Sbi47911',
  Sbi47912 = 'Sbi47912',
  Sbi47913 = 'Sbi47913',
  Sbi47914 = 'Sbi47914',
  Sbi47915 = 'Sbi47915',
  Sbi47916 = 'Sbi47916',
  Sbi47918 = 'Sbi47918',
  Sbi47919 = 'Sbi47919',
  Sbi4799 = 'Sbi4799',
  Sbi47991 = 'Sbi47991',
  Sbi47992 = 'Sbi47992',
  Sbi47999 = 'Sbi47999',
  Sbi4910 = 'Sbi4910',
  Sbi4920 = 'Sbi4920',
  Sbi4931 = 'Sbi4931',
  Sbi4932 = 'Sbi4932',
  Sbi4939 = 'Sbi4939',
  Sbi49391 = 'Sbi49391',
  Sbi49392 = 'Sbi49392',
  Sbi49393 = 'Sbi49393',
  Sbi4941 = 'Sbi4941',
  Sbi4942 = 'Sbi4942',
  Sbi4950 = 'Sbi4950',
  Sbi5010 = 'Sbi5010',
  Sbi5020 = 'Sbi5020',
  Sbi50201 = 'Sbi50201',
  Sbi50202 = 'Sbi50202',
  Sbi5030 = 'Sbi5030',
  Sbi5040 = 'Sbi5040',
  Sbi50401 = 'Sbi50401',
  Sbi50402 = 'Sbi50402',
  Sbi50403 = 'Sbi50403',
  Sbi5110 = 'Sbi5110',
  Sbi5121 = 'Sbi5121',
  Sbi5210 = 'Sbi5210',
  Sbi52101 = 'Sbi52101',
  Sbi52102 = 'Sbi52102',
  Sbi52109 = 'Sbi52109',
  Sbi5221 = 'Sbi5221',
  Sbi5222 = 'Sbi5222',
  Sbi5223 = 'Sbi5223',
  Sbi5224 = 'Sbi5224',
  Sbi52241 = 'Sbi52241',
  Sbi52242 = 'Sbi52242',
  Sbi5229 = 'Sbi5229',
  Sbi52291 = 'Sbi52291',
  Sbi52292 = 'Sbi52292',
  Sbi5310 = 'Sbi5310',
  Sbi5320 = 'Sbi5320',
  Sbi53201 = 'Sbi53201',
  Sbi53202 = 'Sbi53202',
  Sbi5510 = 'Sbi5510',
  Sbi55101 = 'Sbi55101',
  Sbi55102 = 'Sbi55102',
  Sbi5520 = 'Sbi5520',
  Sbi55201 = 'Sbi55201',
  Sbi55202 = 'Sbi55202',
  Sbi5530 = 'Sbi5530',
  Sbi5590 = 'Sbi5590',
  Sbi5610 = 'Sbi5610',
  Sbi56101 = 'Sbi56101',
  Sbi56102 = 'Sbi56102',
  Sbi5621 = 'Sbi5621',
  Sbi5629 = 'Sbi5629',
  Sbi5630 = 'Sbi5630',
  Sbi5811 = 'Sbi5811',
  Sbi5812 = 'Sbi5812',
  Sbi5813 = 'Sbi5813',
  Sbi5814 = 'Sbi5814',
  Sbi5819 = 'Sbi5819',
  Sbi5821 = 'Sbi5821',
  Sbi5829 = 'Sbi5829',
  Sbi5911 = 'Sbi5911',
  Sbi59111 = 'Sbi59111',
  Sbi59112 = 'Sbi59112',
  Sbi5912 = 'Sbi5912',
  Sbi5913 = 'Sbi5913',
  Sbi5914 = 'Sbi5914',
  Sbi5920 = 'Sbi5920',
  Sbi6010 = 'Sbi6010',
  Sbi6020 = 'Sbi6020',
  Sbi6110 = 'Sbi6110',
  Sbi6120 = 'Sbi6120',
  Sbi6130 = 'Sbi6130',
  Sbi6190 = 'Sbi6190',
  Sbi6201 = 'Sbi6201',
  Sbi6202 = 'Sbi6202',
  Sbi6203 = 'Sbi6203',
  Sbi6209 = 'Sbi6209',
  Sbi6311 = 'Sbi6311',
  Sbi6312 = 'Sbi6312',
  Sbi6391 = 'Sbi6391',
  Sbi6399 = 'Sbi6399',
  Sbi6411 = 'Sbi6411',
  Sbi6419 = 'Sbi6419',
  Sbi64191 = 'Sbi64191',
  Sbi64192 = 'Sbi64192',
  Sbi64193 = 'Sbi64193',
  Sbi64194 = 'Sbi64194',
  Sbi6420 = 'Sbi6420',
  Sbi6430 = 'Sbi6430',
  Sbi64301 = 'Sbi64301',
  Sbi64302 = 'Sbi64302',
  Sbi64303 = 'Sbi64303',
  Sbi6491 = 'Sbi6491',
  Sbi6492 = 'Sbi6492',
  Sbi64921 = 'Sbi64921',
  Sbi64922 = 'Sbi64922',
  Sbi64923 = 'Sbi64923',
  Sbi64924 = 'Sbi64924',
  Sbi6499 = 'Sbi6499',
  Sbi6511 = 'Sbi6511',
  Sbi65111 = 'Sbi65111',
  Sbi65112 = 'Sbi65112',
  Sbi65113 = 'Sbi65113',
  Sbi6512 = 'Sbi6512',
  Sbi65121 = 'Sbi65121',
  Sbi65122 = 'Sbi65122',
  Sbi6520 = 'Sbi6520',
  Sbi6530 = 'Sbi6530',
  Sbi65301 = 'Sbi65301',
  Sbi65302 = 'Sbi65302',
  Sbi65303 = 'Sbi65303',
  Sbi65309 = 'Sbi65309',
  Sbi6611 = 'Sbi6611',
  Sbi6612 = 'Sbi6612',
  Sbi6619 = 'Sbi6619',
  Sbi66191 = 'Sbi66191',
  Sbi66192 = 'Sbi66192',
  Sbi66193 = 'Sbi66193',
  Sbi6621 = 'Sbi6621',
  Sbi6622 = 'Sbi6622',
  Sbi6629 = 'Sbi6629',
  Sbi66291 = 'Sbi66291',
  Sbi66292 = 'Sbi66292',
  Sbi66293 = 'Sbi66293',
  Sbi66299 = 'Sbi66299',
  Sbi6630 = 'Sbi6630',
  Sbi6810 = 'Sbi6810',
  Sbi6820 = 'Sbi6820',
  Sbi68201 = 'Sbi68201',
  Sbi68202 = 'Sbi68202',
  Sbi68203 = 'Sbi68203',
  Sbi68204 = 'Sbi68204',
  Sbi6831 = 'Sbi6831',
  Sbi6832 = 'Sbi6832',
  Sbi6910 = 'Sbi6910',
  Sbi69101 = 'Sbi69101',
  Sbi69102 = 'Sbi69102',
  Sbi69103 = 'Sbi69103',
  Sbi69104 = 'Sbi69104',
  Sbi69105 = 'Sbi69105',
  Sbi6920 = 'Sbi6920',
  Sbi69201 = 'Sbi69201',
  Sbi69202 = 'Sbi69202',
  Sbi69203 = 'Sbi69203',
  Sbi69204 = 'Sbi69204',
  Sbi69209 = 'Sbi69209',
  Sbi7010 = 'Sbi7010',
  Sbi70101 = 'Sbi70101',
  Sbi70102 = 'Sbi70102',
  Sbi7021 = 'Sbi7021',
  Sbi7022 = 'Sbi7022',
  Sbi70221 = 'Sbi70221',
  Sbi70222 = 'Sbi70222',
  Sbi7111 = 'Sbi7111',
  Sbi71111 = 'Sbi71111',
  Sbi71112 = 'Sbi71112',
  Sbi7112 = 'Sbi7112',
  Sbi7120 = 'Sbi7120',
  Sbi71201 = 'Sbi71201',
  Sbi71202 = 'Sbi71202',
  Sbi71203 = 'Sbi71203',
  Sbi7211 = 'Sbi7211',
  Sbi72111 = 'Sbi72111',
  Sbi72112 = 'Sbi72112',
  Sbi72113 = 'Sbi72113',
  Sbi7219 = 'Sbi7219',
  Sbi72191 = 'Sbi72191',
  Sbi72192 = 'Sbi72192',
  Sbi72193 = 'Sbi72193',
  Sbi72199 = 'Sbi72199',
  Sbi7220 = 'Sbi7220',
  Sbi7311 = 'Sbi7311',
  Sbi7312 = 'Sbi7312',
  Sbi7320 = 'Sbi7320',
  Sbi7410 = 'Sbi7410',
  Sbi74101 = 'Sbi74101',
  Sbi74102 = 'Sbi74102',
  Sbi74103 = 'Sbi74103',
  Sbi7420 = 'Sbi7420',
  Sbi74201 = 'Sbi74201',
  Sbi74202 = 'Sbi74202',
  Sbi74203 = 'Sbi74203',
  Sbi7430 = 'Sbi7430',
  Sbi7490 = 'Sbi7490',
  Sbi7500 = 'Sbi7500',
  Sbi7711 = 'Sbi7711',
  Sbi77111 = 'Sbi77111',
  Sbi77112 = 'Sbi77112',
  Sbi7712 = 'Sbi7712',
  Sbi7721 = 'Sbi7721',
  Sbi7722 = 'Sbi7722',
  Sbi7729 = 'Sbi7729',
  Sbi77291 = 'Sbi77291',
  Sbi77292 = 'Sbi77292',
  Sbi77299 = 'Sbi77299',
  Sbi7731 = 'Sbi7731',
  Sbi7732 = 'Sbi7732',
  Sbi7733 = 'Sbi7733',
  Sbi7734 = 'Sbi7734',
  Sbi7735 = 'Sbi7735',
  Sbi7739 = 'Sbi7739',
  Sbi77391 = 'Sbi77391',
  Sbi77399 = 'Sbi77399',
  Sbi7740 = 'Sbi7740',
  Sbi7810 = 'Sbi7810',
  Sbi7820 = 'Sbi7820',
  Sbi78201 = 'Sbi78201',
  Sbi78202 = 'Sbi78202',
  Sbi78203 = 'Sbi78203',
  Sbi7830 = 'Sbi7830',
  Sbi7911 = 'Sbi7911',
  Sbi7912 = 'Sbi7912',
  Sbi7990 = 'Sbi7990',
  Sbi8010 = 'Sbi8010',
  Sbi8020 = 'Sbi8020',
  Sbi8030 = 'Sbi8030',
  Sbi8110 = 'Sbi8110',
  Sbi8121 = 'Sbi8121',
  Sbi8122 = 'Sbi8122',
  Sbi81221 = 'Sbi81221',
  Sbi81222 = 'Sbi81222',
  Sbi81229 = 'Sbi81229',
  Sbi8129 = 'Sbi8129',
  Sbi8130 = 'Sbi8130',
  Sbi8211 = 'Sbi8211',
  Sbi8219 = 'Sbi8219',
  Sbi8220 = 'Sbi8220',
  Sbi8230 = 'Sbi8230',
  Sbi8291 = 'Sbi8291',
  Sbi8292 = 'Sbi8292',
  Sbi8299 = 'Sbi8299',
  Sbi82991 = 'Sbi82991',
  Sbi82992 = 'Sbi82992',
  Sbi82999 = 'Sbi82999',
  Sbi8411 = 'Sbi8411',
  Sbi8412 = 'Sbi8412',
  Sbi8413 = 'Sbi8413',
  Sbi8421 = 'Sbi8421',
  Sbi8422 = 'Sbi8422',
  Sbi8423 = 'Sbi8423',
  Sbi84231 = 'Sbi84231',
  Sbi84232 = 'Sbi84232',
  Sbi8424 = 'Sbi8424',
  Sbi8425 = 'Sbi8425',
  Sbi8430 = 'Sbi8430',
  Sbi8520 = 'Sbi8520',
  Sbi85201 = 'Sbi85201',
  Sbi85202 = 'Sbi85202',
  Sbi85203 = 'Sbi85203',
  Sbi8531 = 'Sbi8531',
  Sbi85311 = 'Sbi85311',
  Sbi85312 = 'Sbi85312',
  Sbi85313 = 'Sbi85313',
  Sbi85314 = 'Sbi85314',
  Sbi8532 = 'Sbi8532',
  Sbi85321 = 'Sbi85321',
  Sbi85322 = 'Sbi85322',
  Sbi85323 = 'Sbi85323',
  Sbi8541 = 'Sbi8541',
  Sbi8542 = 'Sbi8542',
  Sbi8551 = 'Sbi8551',
  Sbi85511 = 'Sbi85511',
  Sbi85519 = 'Sbi85519',
  Sbi8552 = 'Sbi8552',
  Sbi85521 = 'Sbi85521',
  Sbi85522 = 'Sbi85522',
  Sbi8553 = 'Sbi8553',
  Sbi8559 = 'Sbi8559',
  Sbi85591 = 'Sbi85591',
  Sbi85592 = 'Sbi85592',
  Sbi85599 = 'Sbi85599',
  Sbi8560 = 'Sbi8560',
  Sbi8610 = 'Sbi8610',
  Sbi86101 = 'Sbi86101',
  Sbi86102 = 'Sbi86102',
  Sbi86103 = 'Sbi86103',
  Sbi86104 = 'Sbi86104',
  Sbi8621 = 'Sbi8621',
  Sbi8622 = 'Sbi8622',
  Sbi86221 = 'Sbi86221',
  Sbi86222 = 'Sbi86222',
  Sbi8623 = 'Sbi8623',
  Sbi86231 = 'Sbi86231',
  Sbi86232 = 'Sbi86232',
  Sbi8691 = 'Sbi8691',
  Sbi86911 = 'Sbi86911',
  Sbi86912 = 'Sbi86912',
  Sbi86913 = 'Sbi86913',
  Sbi86919 = 'Sbi86919',
  Sbi8692 = 'Sbi8692',
  Sbi86921 = 'Sbi86921',
  Sbi86922 = 'Sbi86922',
  Sbi86923 = 'Sbi86923',
  Sbi86924 = 'Sbi86924',
  Sbi86925 = 'Sbi86925',
  Sbi86929 = 'Sbi86929',
  Sbi8710 = 'Sbi8710',
  Sbi8720 = 'Sbi8720',
  Sbi8730 = 'Sbi8730',
  Sbi87301 = 'Sbi87301',
  Sbi87302 = 'Sbi87302',
  Sbi8790 = 'Sbi8790',
  Sbi87901 = 'Sbi87901',
  Sbi87902 = 'Sbi87902',
  Sbi8810 = 'Sbi8810',
  Sbi88101 = 'Sbi88101',
  Sbi88102 = 'Sbi88102',
  Sbi88103 = 'Sbi88103',
  Sbi8891 = 'Sbi8891',
  Sbi8899 = 'Sbi8899',
  Sbi88991 = 'Sbi88991',
  Sbi88992 = 'Sbi88992',
  Sbi88993 = 'Sbi88993',
  Sbi88999 = 'Sbi88999',
  Sbi9001 = 'Sbi9001',
  Sbi90011 = 'Sbi90011',
  Sbi90012 = 'Sbi90012',
  Sbi90013 = 'Sbi90013',
  Sbi9002 = 'Sbi9002',
  Sbi9003 = 'Sbi9003',
  Sbi9004 = 'Sbi9004',
  Sbi90041 = 'Sbi90041',
  Sbi90042 = 'Sbi90042',
  Sbi9101 = 'Sbi9101',
  Sbi91011 = 'Sbi91011',
  Sbi91012 = 'Sbi91012',
  Sbi91019 = 'Sbi91019',
  Sbi9102 = 'Sbi9102',
  Sbi91021 = 'Sbi91021',
  Sbi91022 = 'Sbi91022',
  Sbi9103 = 'Sbi9103',
  Sbi9104 = 'Sbi9104',
  Sbi91041 = 'Sbi91041',
  Sbi91042 = 'Sbi91042',
  Sbi9200 = 'Sbi9200',
  Sbi92001 = 'Sbi92001',
  Sbi92009 = 'Sbi92009',
  Sbi9311 = 'Sbi9311',
  Sbi93111 = 'Sbi93111',
  Sbi93112 = 'Sbi93112',
  Sbi93113 = 'Sbi93113',
  Sbi93119 = 'Sbi93119',
  Sbi9312 = 'Sbi9312',
  Sbi93121 = 'Sbi93121',
  Sbi93122 = 'Sbi93122',
  Sbi93123 = 'Sbi93123',
  Sbi93124 = 'Sbi93124',
  Sbi93125 = 'Sbi93125',
  Sbi93126 = 'Sbi93126',
  Sbi93127 = 'Sbi93127',
  Sbi93128 = 'Sbi93128',
  Sbi93129 = 'Sbi93129',
  Sbi9313 = 'Sbi9313',
  Sbi9314 = 'Sbi9314',
  Sbi93141 = 'Sbi93141',
  Sbi93142 = 'Sbi93142',
  Sbi93143 = 'Sbi93143',
  Sbi93144 = 'Sbi93144',
  Sbi93145 = 'Sbi93145',
  Sbi93146 = 'Sbi93146',
  Sbi93149 = 'Sbi93149',
  Sbi9315 = 'Sbi9315',
  Sbi93151 = 'Sbi93151',
  Sbi93152 = 'Sbi93152',
  Sbi9319 = 'Sbi9319',
  Sbi93191 = 'Sbi93191',
  Sbi93192 = 'Sbi93192',
  Sbi93193 = 'Sbi93193',
  Sbi93194 = 'Sbi93194',
  Sbi93195 = 'Sbi93195',
  Sbi93196 = 'Sbi93196',
  Sbi93199 = 'Sbi93199',
  Sbi9321 = 'Sbi9321',
  Sbi93211 = 'Sbi93211',
  Sbi93212 = 'Sbi93212',
  Sbi9329 = 'Sbi9329',
  Sbi93291 = 'Sbi93291',
  Sbi93299 = 'Sbi93299',
  Sbi9411 = 'Sbi9411',
  Sbi9412 = 'Sbi9412',
  Sbi9420 = 'Sbi9420',
  Sbi9491 = 'Sbi9491',
  Sbi94911 = 'Sbi94911',
  Sbi94919 = 'Sbi94919',
  Sbi9492 = 'Sbi9492',
  Sbi9499 = 'Sbi9499',
  Sbi94991 = 'Sbi94991',
  Sbi94992 = 'Sbi94992',
  Sbi94993 = 'Sbi94993',
  Sbi94994 = 'Sbi94994',
  Sbi94995 = 'Sbi94995',
  Sbi94996 = 'Sbi94996',
  Sbi94997 = 'Sbi94997',
  Sbi9511 = 'Sbi9511',
  Sbi9512 = 'Sbi9512',
  Sbi9521 = 'Sbi9521',
  Sbi9522 = 'Sbi9522',
  Sbi9523 = 'Sbi9523',
  Sbi9524 = 'Sbi9524',
  Sbi9525 = 'Sbi9525',
  Sbi9529 = 'Sbi9529',
  Sbi9601 = 'Sbi9601',
  Sbi96011 = 'Sbi96011',
  Sbi96012 = 'Sbi96012',
  Sbi96013 = 'Sbi96013',
  Sbi9602 = 'Sbi9602',
  Sbi96021 = 'Sbi96021',
  Sbi96022 = 'Sbi96022',
  Sbi9603 = 'Sbi9603',
  Sbi96031 = 'Sbi96031',
  Sbi96032 = 'Sbi96032',
  Sbi9604 = 'Sbi9604',
  Sbi9609 = 'Sbi9609',
  Sbi9700 = 'Sbi9700',
  Sbi9810 = 'Sbi9810',
  Sbi9820 = 'Sbi9820',
  Sbi9900 = 'Sbi9900',
}

export enum TypeOfCompany {
  SelfEmployedNoEmployees = 'SelfEmployedNoEmployees',
  SelfEmployedWithEmployeed = 'SelfEmployedWithEmployeed',
}

export interface EntrepreneurIncomeReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;

  /** @format double */
  annualIncome1?: number | null;

  /** @format double */
  annualIncome2?: number | null;

  /** @format double */
  annualIncome3?: number | null;

  /** @format double */
  averageIncome?: number | null;
  chamberOfCommerceNumber?: string | null;
  isDirectorsowners?: boolean | null;
  isPartOfAssessmentIncome?: boolean | null;
  legalForm?: LegalForm;
  sbiCode?: SbiCode;

  /** @format date-time */
  startDate?: string | null;

  /** @format float */
  stockPercentage?: number | null;
  typeOfCompany?: TypeOfCompany;
}

export enum TypeOfObligation {
  AlimonyChildren = 'AlimonyChildren',
  AlimonyPartner = 'AlimonyPartner',
  PensionContribution = 'PensionContribution',
  PersonalLoan = 'PersonalLoan',
  RevolvingCredit = 'RevolvingCredit',
  ExistingMortgage = 'ExistingMortgage',
  LoanEmployer = 'LoanEmployer',
  MailOrderCredit = 'MailOrderCredit',
  DebtRepayment = 'DebtRepayment',
  Telecommunication = 'Telecommunication',
  RentalCosts = 'RentalCosts',
  StudentLoan = 'StudentLoan',
  PrivateLease = 'PrivateLease',
  AdvancedPaymentStudy = 'AdvancedPaymentStudy',
  ResidualDebtNhg = 'ResidualDebtNhg',
  ResidualDebtNoNhg = 'ResidualDebtNoNhg',
  Remaining = 'Remaining',
}

export enum TypeOfRedemption {
  Annuity = 'Annuity',
  Lineair = 'Lineair',
  LifeInsurance = 'LifeInsurance',
  InterestOnly = 'InterestOnly',
  Investment = 'Investment',
  SavingsBased = 'SavingsBased',
}

export interface ObligationReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;

  /** @format double */
  additionalRepayableAmount?: number | null;
  contractNumber?: string | null;
  isNewObligation?: boolean | null;
  isPartOfAssessmentIncome?: boolean | null;
  isRedeemedWithNewLoan?: boolean | null;
  moneylenderName?: string | null;

  /** @format double */
  obligationAmount?: number | null;

  /** @format double */
  principalDebt?: number | null;

  /** @format int32 */
  remainingDuration?: number | null;

  /** @format double */
  repayableAmount?: number | null;

  /** @format double */
  residualDebt?: number | null;

  /** @format double */
  revolvingCreditLimit?: number | null;
  typeOfObligation?: TypeOfObligation;

  /** @format date-time */
  startDate?: string | null;

  /** @format date-time */
  endDate?: string | null;
  description?: string | null;
  typeOfRedemption?: TypeOfRedemption;

  /** @format int32 */
  fixedInterestPeriod?: number | null;

  /** @format float */
  interestPercentage?: number | null;

  /** @format double */
  amountTaxBox1?: number | null;

  /** @format date-time */
  endDateInterestDeduction?: string | null;

  /** @format double */
  amountTaxBox3?: number | null;

  /** @format int32 */
  registryRank?: number | null;

  /** @format double */
  ActualDebt?: number | null;
  partialRedemptionByPersonalEquity?: boolean | null;
  redemptionByNewMortgage?: boolean | null;
  redemptionTypeDisplayName?: string | null;
}

export enum TypeOfOtherIncome {
  Alimony = 'Alimony',
  PartnerAlimony = 'PartnerAlimony',
  ChildAlimony = 'ChildAlimony',
  Donation = 'Donation',
  BoxTwo = 'BoxTwo',
  Different = 'Different',
  Rental = 'Rental',
  Capital = 'Capital',
  AnnuityInsurance = 'AnnuityInsurance',
  OwnerOccupiedHomeReserve = 'OwnerOccupiedHomeReserve',
  SocialInsuranceWage = 'SocialInsuranceWage',
}

export interface OtherIncomeReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;

  /** @format double */
  amount?: number | null;

  /** @format date-time */
  endDate?: string | null;
  isPartOfAssessmentIncome?: boolean | null;
  typeOfOtherIncome?: TypeOfOtherIncome;

  /** @format int32 */
  numberOfEmployments?: number | null;

  /** @format double */
  pensionContribution?: number | null;
}

export interface ShareholderReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;
  isLegalPerson?: boolean | null;
  firstName?: string | null;
  initials?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  gender?: Gender;

  /** @format date-time */
  dateOfBirth?: string | null;
  nationality?: Nationality;
  emailAddress?: string | null;
  privatePhoneNumber?: string | null;
  mobilePhoneNumber?: string | null;
  street?: string | null;

  /** @format int64 */
  houseNumber?: number | null;
  houseNumberExtension?: string | null;
  postalCode?: string | null;
  city?: string | null;
  country?: Country;
  typeOfIdentification?: TypeOfIdentification;
  identificationNumber?: string | null;
  hasExistingMortgageByMoneylender?: boolean | null;

  /** @format int32 */
  existingLoanNumber?: number | null;
  isMortgageRejected?: boolean | null;
  reasonMortgageRejection?: string | null;
  legalForm?: LegalForm;

  /** @format int32 */
  yearsOfBuyToLetExperience?: number | null;
  explanationOfBTLExperience?: string | null;
  civilStatus?: CivilStatus;
  chamberOfCommerceNumber?: string | null;
  companyName?: string | null;
}

export enum TypeOfBenefit {
  Welfare = 'Welfare',
  UnemploymentBenefits = 'UnemploymentBenefits',
  TideoverAllowance = 'TideoverAllowance',
  StateUnemploymentBenefits = 'StateUnemploymentBenefits',
  LawForSurvivingDependants = 'LawForSurvivingDependants',
  Insurance = 'Insurance',
  Wajong = 'Wajong',
  OldAgeInsurance = 'OldAgeInsurance',
  InvalidInsuranceSelfEmployed = 'InvalidInsuranceSelfEmployed',
  ResumptionPartiallyIncapacitated = 'ResumptionPartiallyIncapacitated',
  ProvisionFullyIncapacitated = 'ProvisionFullyIncapacitated',
  DisablityInsuranceWithReinspection = 'DisablityInsuranceWithReinspection',
  DisablityInsuranceWithoutReinspection = 'DisablityInsuranceWithoutReinspection',
  OldAgePension = 'OldAgePension',
  DependantPension = 'DependantPension',
  StudyGrant = 'StudyGrant',
  PrePension = 'PrePension',
}

export enum TypeOfInsurance {
  AnnuityInsurance = 'AnnuityInsurance',
  DisabilityInsurance = 'DisabilityInsurance',
  IncomeProtectionCover = 'IncomeProtectionCover',
}

export interface SocialSecurityIncomeReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;

  /** @format double */
  benefit?: number | null;

  /** @format date-time */
  endDate?: string | null;
  isPartOfAssessmentIncome?: boolean | null;

  /** @format date-time */
  startDate?: string | null;
  typeOfBenefit?: TypeOfBenefit;
  typeOfInsurance?: TypeOfInsurance;
}

export enum TypeOfPermit {
  Permanent = 'Permanent',
  WithEndDate = 'WithEndDate',
  PermanentAsylum = 'PermanentAsylum',
  WithEndDateAsylum = 'WithEndDateAsylum',
}

export enum TypeOfAccumulatedIncome {
  AnnualIncomeTaxBox1 = 'AnnualIncomeTaxBox1',
  AnnualIncomeTaxBox2 = 'AnnualIncomeTaxBox2',
  AnnualIncomeTaxBox3 = 'AnnualIncomeTaxBox3',
  TotalIncome = 'TotalIncome',
}

export interface AccumulatedIncomeReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;
  explanation?: string | null;

  /** @format double */
  income?: number | null;
  isPartOfAssessmentIncome?: boolean | null;
  typeOfAccumulatedIncome?: TypeOfAccumulatedIncome;
}

export interface ApplicantReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;
  bankAccountNumber?: string | null;
  chamberOfCommerceNumber?: string | null;
  city?: string | null;
  cityOfBirth?: string | null;
  civilStatus?: CivilStatus;
  companyName?: string | null;
  companyNameDirectorship?: string | null;
  companyNameShareholdership?: string | null;

  /** @format date-time */
  companyStartDate?: string | null;
  country?: Country;
  countryOfBirth?: Country;

  /** @format date-time */
  dateOfBirth?: string | null;

  /** @format date-time */
  dateOfDivorce?: string | null;
  directors?: DirectorReadDto[] | null;
  emailAddress?: string | null;
  employmentIncomes?: EmploymentIncomeReadDto[] | null;
  entrepreneurIncomes?: EntrepreneurIncomeReadDto[] | null;
  explanationOfBTLExperience?: string | null;

  /** @format int32 */
  existingLoanNumber?: number | null;
  firstName?: string | null;
  gender?: Gender;
  hasExistingMortgageByMoneylender?: boolean | null;

  /** @format int64 */
  houseNumber?: number | null;
  houseNumberExtension?: string | null;

  /** @format date-time */
  identificationDateOfIssue?: string | null;

  /** @format date-time */
  identificationEndDate?: string | null;
  identificationIssuingCity?: string | null;
  identificationIssuingCountry?: Country;
  identificationNumber?: string | null;
  initials?: string | null;
  isDirector?: boolean | null;
  isDivorced?: boolean | null;
  isFirstMortgage?: boolean | null;
  isLegalPerson?: boolean | null;
  isMortgageRejected?: boolean | null;
  isSeverallyLiable?: boolean | null;
  isShareholder?: boolean | null;
  isTaxResidentOfNetherland?: boolean | null;
  isTaxResidentOutsideNetherland?: boolean | null;
  isWidower?: boolean | null;
  kindOfActivitiesInCompany?: string | null;
  lastName?: string | null;
  legalForm?: LegalForm;
  middleName?: string | null;
  mobilePhoneNumber?: string | null;
  nationality?: Nationality;

  /** @format int32 */
  numberOfRentalCollateralsOwned?: number | null;
  obligations?: ObligationReadDto[] | null;
  otherIncomes?: OtherIncomeReadDto[] | null;

  /** @format double */
  ownHomeDebt?: number | null;

  /** @format date-time */
  permitEndDate?: string | null;
  permitNumber?: string | null;
  postalCode?: string | null;
  privatePhoneNumber?: string | null;
  reasonMortgageRejection?: string | null;
  shareholders?: ShareholderReadDto[] | null;

  /** @format double */
  shareholderPercentage?: number | null;
  socialSecurityIncomes?: SocialSecurityIncomeReadDto[] | null;
  street?: string | null;

  /** @format double */
  totalValueOfRentalCollateralsOwned?: number | null;
  typeOfIdentification?: TypeOfIdentification;
  typeOfPermit?: TypeOfPermit;
  vastgoedBelangMembershipNumber?: string | null;
  workPhoneNumber?: string | null;

  /** @format int32 */
  yearsOfBuyToLetExperience?: number | null;
  hasBuiltUpFullOldAgeInsurance?: boolean | null;

  /** @format int32 */
  maxAttainableYearsOfOldAgeInsurance?: number | null;
  isVastgoedBelangMember?: boolean | null;
  accumulatedIncomes?: AccumulatedIncomeReadDto[] | null;
  civilStatusBeforeEndOfRelationship?: CivilStatus;

  /** @format int32 */
  numberOfDivorces?: number | null;

  /** @format int32 */
  numberOfChildrenLivingAtHome?: number | null;
  ownerRealEstate?: boolean | null;
}

export interface InsuredPersonReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;
  fullName?: string | null;
}

export interface PolicyHolderReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;
  fullName?: string | null;
}

export enum TypeOfPolicyMutation {
  Altered = 'Altered',
  Ended = 'Ended',
  Continued = 'Continued',
}

export interface CurrentPolicyReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;
  insuredPeople?: InsuredPersonReadDto[] | null;
  insuredPersonName?: string | null;
  mutationDescription?: string | null;
  policyHolderName?: string | null;
  policyHolders?: PolicyHolderReadDto[] | null;
  policyNumber?: string | null;
  typeOfMutation?: TypeOfPolicyMutation;

  /** @format double */
  amount?: number | null;

  /** @format int32 */
  originalLoanPart?: number | null;
}

export enum TypeOfFinancing {
  PersonalEquity = 'PersonalEquity',
  RaiseMortgage = 'RaiseMortgage',
  PersonalEquityAndRaiseMortgage = 'PersonalEquityAndRaiseMortgage',
}

export interface CurrentLoanPartReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;

  /** @format int32 */
  duration?: number | null;

  /** @format date-time */
  endDate?: string | null;

  /** @format double */
  estimatedResidualDebtAtConveyance?: number | null;

  /** @format int32 */
  fixedInterestPeriod?: number | null;

  /** @format float */
  interestPercentage?: number | null;

  /** @format double */
  policyValue?: number | null;

  /** @format double */
  principalAmount?: number | null;

  /** @format date-time */
  startDate?: string | null;

  /** @format int32 */
  taxBox?: number | null;
  typeOfRedemption?: TypeOfRedemption;
  willBeRedeemed?: boolean | null;
  hasNhg?: boolean | null;
  hasMortgageOfTwentyYearsOrLonger?: boolean | null;

  /** @format int32 */
  loanPartNumber?: number | null;

  /** @format double */
  currentResidualDebtAmount?: number | null;

  /** @format date-time */
  endDateInterestDeduction?: string | null;
  currentPolicies?: CurrentPolicyReadDto[] | null;

  /** @format int32 */
  newDuration?: number | null;
  hasInterestRedemption?: boolean | null;
  typeOfInterestRedemptionFinancing?: TypeOfFinancing;

  /** @format double */
  amountTaxBox1?: number | null;

  /** @format double */
  amountTaxBox3?: number | null;
  shouldLoanPartSplit?: boolean | null;
  redemptionTypeDisplayName?: string | null;
}

export interface CurrentLoanReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;

  /** @format int32 */
  bureauOfRecordsRank?: number | null;
  currentLoanParts?: CurrentLoanPartReadDto[] | null;

  /** @format double */
  estimatedResidualDebtAtConveyance?: number | null;
  hasNhgGuarantee?: boolean | null;
  loanNumber?: string | null;
  moneylenderName?: string | null;

  /** @format double */
  ownHomeDebt?: number | null;

  /** @format double */
  principalAmount?: number | null;

  /** @format date-time */
  startDate?: string | null;

  /** @format double */
  subscriptionAmount?: number | null;
  willBeRedeemed?: boolean | null;

  /** @format double */
  annualMortgageCosts?: number | null;
  changeLifeInsurance?: boolean | null;
  changeCurrentLoan?: boolean | null;

  /** @format double */
  amountExtraRedemption?: number | null;
}

export enum TypeOfRealEstate {
  FamilyHome = 'FamilyHome',
  FamilyHomeWithGarage = 'FamilyHomeWithGarage',
  Appartement = 'Appartement',
  AppartementWithGarage = 'AppartementWithGarage',
  StorePrivateHouse = 'StorePrivateHouse',
  PrivateHouseWithCommercialSpace = 'PrivateHouseWithCommercialSpace',
  Store = 'Store',
  CommercialProperty = 'CommercialProperty',
  RecreationHouse = 'RecreationHouse',
  Farm = 'Farm',
  CommercialRealEstate = 'CommercialRealEstate',
  BuildingLand = 'BuildingLand',
  Garage = 'Garage',
  BuildingPlot = 'BuildingPlot',
  Houseboat = 'Houseboat',
  PleasureBoat = 'PleasureBoat',
  Studio = 'Studio',
  Office = 'Office',
  Horeca = 'Horeca',
  Parking = 'Parking',
  Other = 'Other',
}

export enum TypeOfRental {
  None = 'None',
  Permanent = 'Permanent',
  Temporary = 'Temporary',
  PartialPermanent = 'PartialPermanent',
  PartialTemporary = 'PartialTemporary',
}

export interface CurrentRealEstateReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;

  /** @format double */
  bridgeLoanAmount?: number | null;

  /** @format date-time */
  bridgeLoanEndDate?: string | null;

  /** @format double */
  bridgeLoanInterestPercentage?: number | null;
  city?: string | null;
  country?: Country;

  /** @format int64 */
  houseNumber?: number | null;
  houseNumberExtension?: string | null;
  currentLoans?: CurrentLoanReadDto[] | null;
  isBridgeLoanNeeded?: boolean | null;
  isDeedOfSaleAvailable?: boolean | null;
  isSold?: boolean | null;

  /** @format double */
  marketValue?: number | null;
  postalCode?: string | null;

  /** @format date-time */
  purchaseDate?: string | null;

  /** @format double */
  rentalIncome?: number | null;

  /** @format double */
  sellingCost?: number | null;

  /** @format date-time */
  sellingDate?: string | null;

  /** @format double */
  sellingPrice?: number | null;
  street?: string | null;
  typeOfRealEstate?: TypeOfRealEstate;
  typeOfRental?: TypeOfRental;
  willBeSold?: boolean | null;

  /** @format int32 */
  yearOfConstruction?: number | null;

  /** @format double */
  totalPurchaseCosts?: number | null;
  hasLeasehold?: boolean | null;
  isLeaseholdAmountEverlasting?: boolean | null;

  /** @format date-time */
  leaseholdEndDate?: string | null;

  /** @format date-time */
  leaseholdAmountRedeemedDate?: string | null;

  /** @format double */
  annualLeaseholdAmount?: number | null;

  /** @format double */
  wozValue?: number | null;

  /** @format double */
  basicAnnualRentalIncome?: number | null;

  /** @format double */
  rentalValue?: number | null;

  /** @format double */
  expectedBasicAnnualRentalIncomeAfterRenovation?: number | null;

  /** @format double */
  rentalValueAfterRenovation?: number | null;
  hasRentalAgreement?: boolean | null;

  /** @format date-time */
  rentalAgreementStartDate?: string | null;
}

export interface IntermediaryReadDto {
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;

  /** @format int32 */
  id?: number;

  /** @format int32 */
  intermediaryNumber?: number | null;
  name?: string;
  phoneNumber?: string;
}

export enum TypeOfFiscalRegime {
  Before2013 = 'Before2013',
  After2013 = 'After2013',
}

export enum NatureOfLoanPart {
  New = 'New',
  Transfer = 'Transfer',
  PassOn = 'PassOn',
  Conversion = 'Conversion',
  Increase = 'Increase',
}

export enum PaymentTerm {
  Once = 'Once',
  Monthly = 'Monthly',
  TwoMontly = 'TwoMontly',
  Quarterly = 'Quarterly',
  HalfYearly = 'HalfYearly',
  Yearly = 'Yearly',
}

export enum TypeOfInterest {
  Fixed = 'Fixed',
  Damping = 'Damping',
  ContinuousVariable = 'ContinuousVariable',
}

export enum TypeOfInterestReflectionPeriod {
  None = 'None',
  InAdvance = 'InAdvance',
  Afterwards = 'Afterwards',
}

export enum TypeOfProductLoanPart {
  Default = 'Default',
  AsrWelthuisStartersHypotheek = 'AsrWelthuisStartersHypotheek',
}

export interface LoanPartReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;

  /** @format double */
  amount?: number | null;

  /** @format int32 */
  duration?: number | null;

  /** @format date-time */
  endDate?: string | null;

  /** @format date-time */
  endDateInterestDeduction?: string | null;
  typeOfFiscalRegime?: TypeOfFiscalRegime;

  /** @format int32 */
  fixedInterestPeriod?: number | null;
  hasFixedEndDate?: boolean | null;

  /** @format float */
  interestPercentage?: number | null;

  /** @format int32 */
  interestReflectionPeriodDuration?: number | null;
  isPostPayment?: boolean | null;
  natureOfLoanPart?: NatureOfLoanPart;
  paymentTerm?: PaymentTerm;

  /** @format int32 */
  taxBox?: number | null;
  typeOfInterest?: TypeOfInterest;
  typeOfInterestReflectionPeriod?: TypeOfInterestReflectionPeriod;
  typeOfProductLoanPart?: TypeOfProductLoanPart;
  typeOfRedemption?: TypeOfRedemption;

  /** @format int32 */
  originalLoanPart?: number | null;
  hasNhg?: boolean | null;

  /** @format double */
  amountTaxBox1?: number | null;

  /** @format double */
  amountTaxBox3?: number | null;
  redemptionTypeDisplayName?: string | null;
}

export enum TypeOfLoan {
  FirstMortgage = 'FirstMortgage',
  Private = 'Private',
  Conversion = 'Conversion',
  InternalRefinancing = 'InternalRefinancing',
  RefinancingDifferentMoneylender = 'RefinancingDifferentMoneylender',
  SecondOrHigherMortgage = 'SecondOrHigherMortgage',
  MortgageTransfer = 'MortgageTransfer',
  PassOnMortgage = 'PassOnMortgage',
  DismissalJointLiability = 'DismissalJointLiability',
}

export enum TypeOfMutation {
  PurchaseHouse = 'PurchaseHouse',
  AcquisitionUndividedEstate = 'AcquisitionUndividedEstate',
  RefinancingNewMoneylender = 'RefinancingNewMoneylender',
  LeaseholdLumpsum = 'LeaseholdLumpsum',
  Divorce = 'Divorce',
  QualityImprovement = 'QualityImprovement',
  AcquisitionFullOwnership = 'AcquisitionFullOwnership',
  PurchaseRegionAuction = 'PurchaseRegionAuction',
  ImprovedCustomerSituation = 'ImprovedCustomerSituation',
}

export interface LoanReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;

  /** @format double */
  addedPersonalEquity?: number | null;

  /** @format double */
  amount?: number | null;

  /** @format double */
  amountOfRentalTax?: number | null;

  /** @format double */
  bankGuaranteeAmount?: number | null;

  /** @format double */
  bankGuaranteeCosts?: number | null;

  /** @format int32 */
  bureauOfRecordsRank?: number | null;

  /** @format double */
  consultancyCosts?: number | null;

  /** @format date-time */
  conveyanceDate?: string | null;
  explanationAddedPersonalEquity?: string | null;
  explanationLiquidityAmountToWithdraw?: string | null;
  explanationRefinancingExistingMortgagesAmount?: string | null;
  explanationOtherCosts?: string | null;
  explanationTotalCostBuyerAmount?: string | null;
  explanationTotalPurchasePrice?: string | null;
  explanationTotalRenovationCosts?: string | null;
  isNhgRequested?: boolean | null;
  isRealEstateBought?: boolean | null;

  /** @format double */
  liquidityAmountToWithdraw?: number | null;
  loanParts?: LoanPartReadDto[] | null;

  /** @format double */
  mediationCosts?: number | null;

  /** @format double */
  mortgageDeedCosts?: number | null;

  /** @format double */
  mortgageRegistration?: number | null;

  /** @format double */
  nhgCosts?: number | null;

  /** @format double */
  notaryCosts?: number | null;

  /** @format double */
  otherCosts?: number | null;

  /** @format double */
  penaltyInterestAmount?: number | null;

  /** @format double */
  refinancingExistingMortgagesAmount?: number | null;

  /** @format double */
  totalCostBuyerAmount?: number | null;

  /** @format double */
  totalPurchasePrice?: number | null;

  /** @format double */
  totalRenovationCosts?: number | null;
  typeOfLoan?: TypeOfLoan;
  typeOfMutation?: TypeOfMutation;

  /** @format double */
  loanAmountBuyout?: number | null;
  typeOfBuyoutFinancing?: TypeOfFinancing;

  /** @format double */
  buyoutAmountByPersonalEquity?: number | null;
  additionalInformation?: string | null;
  amountRaise?: number | null;
}

export interface MoneylenderReadDto {
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;
  commercialLabel?: string | null;

  /** @format int32 */
  id?: number;
  name?: string | null;
}

export enum NotaryRole {
  Passing = 'Passing',
  Carrying = 'Carrying',
  DrafterWarranty = 'DrafterWarranty',
}

export interface NotaryReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;
  city?: string | null;

  /** @format int64 */
  houseNumber?: number | null;
  houseNumberExtension?: string | null;
  name?: string | null;
  notaryRole?: NotaryRole;
  phoneNumber?: string | null;
  postalCode?: string | null;
  street?: string | null;
}

export interface ProductReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;
  name?: string | null;
}

export enum EnergyClass {
  APlusPlus = 'APlusPlus',
  APlus = 'APlus',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  APlusPlusPlus = 'APlusPlusPlus',
  APlusPlusPlusPlus = 'APlusPlusPlusPlus',
  EnergyNeutral = 'EnergyNeutral',
  ZeroOnTheMeter = 'ZeroOnTheMeter',
}

export enum TypeOfCollateral {
  Existing = 'Existing',
  NewlyBuild = 'NewlyBuild',
  ExistingRenovated = 'ExistingRenovated',
}

export enum TypeOfLeasehold {
  Tijdelijk = 'Tijdelijk',
  Voortdurend = 'Voortdurend',
  Eeuwigdurend = 'Eeuwigdurend',
}

export enum TypeOfNewlybuild {
  ProjectConstruction = 'ProjectConstruction',
  SelfConstruction = 'SelfConstruction',
  CollectivePrivateCommissioning = 'CollectivePrivateCommissioning',
}

export enum TypeOfSubsidyRegulation {
  PurchaseGrant = 'PurchaseGrant',
  StartersLoan = 'StartersLoan',
}

export interface RealEstateReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;

  /** @format double */
  additionalWorkAmount?: number | null;

  /** @format double */
  architecturalInspectionCosts?: number | null;

  /** @format double */
  brokerCosts?: number | null;

  /** @format double */
  buildingInsurancePremium?: number | null;
  buildingPlanNumber?: string | null;

  /** @format double */
  buildingSum?: number | null;
  city?: string | null;

  /** @format double */
  commutationPartner?: number | null;

  /** @format double */
  constructionInterestAmount?: number | null;
  country?: Country;

  /** @format date-time */
  deliveryDate?: string | null;

  /** @format double */
  ebbCosts?: number | null;

  /** @format double */
  ebvCosts?: number | null;
  energyClass?: EnergyClass;

  /** @format date-time */
  energyClassIssueDate?: string | null;
  hasApartmentRight?: boolean | null;
  hasLeasehold?: boolean | null;

  /** @format int64 */
  houseNumber?: number | null;
  houseNumberExtension?: string | null;

  /** @format double */
  interestLosesAmount?: number | null;
  isGroundInPossession?: boolean | null;
  isLeaseholdAmountEverlasting?: boolean | null;
  isLeaseholdAmountRedeemedEverlasting?: boolean | null;
  isLeaseholdAmountRedeemedInPast?: boolean | null;
  isLeaseholdPartOfFinancing?: boolean | null;
  isLeaseholdRedeemed?: boolean | null;
  isMainResidence?: boolean | null;
  isOwnResidence?: boolean | null;
  isSoldOnConditions?: boolean | null;
  isValuationAvailable?: boolean | null;

  /** @format double */
  leaseholdAmount?: number | null;

  /** @format date-time */
  leaseholdAmountRedeemedDate?: string | null;

  /** @format date-time */
  leaseholdEndDate?: string | null;

  /** @format double */
  leaseholdLumpsumAmount?: number | null;

  /** @format date-time */
  leaseholdStartDate?: string | null;

  /** @format double */
  marketValue?: number | null;

  /** @format double */
  marketValueAfterRenovation?: number | null;

  /** @format double */
  movableProperty?: number | null;

  /** @format double */
  notarialActOfDeliveryCosts?: number | null;

  /** @format double */
  overdueMaintenanceCosts?: number | null;
  postalCode?: string | null;

  /** @format double */
  purchaseAmount?: number | null;

  /** @format double */
  purchasePriceGround?: number | null;

  /** @format double */
  renovationCosts?: number | null;

  /** @format double */
  renovationDepotAmount?: number | null;

  /** @format double */
  rentalIncome?: number | null;

  /** @format double */
  serviceCosts?: number | null;
  situationNumber?: string | null;
  street?: string | null;

  /** @format double */
  subsidyRegulationAmount?: number | null;

  /** @format double */
  transferTax?: number | null;
  typeOfCollateral?: TypeOfCollateral;
  typeOfLeasehold?: TypeOfLeasehold;
  typeOfNewlybuild?: TypeOfNewlybuild;
  typeOfRealEstate?: TypeOfRealEstate;
  typeOfRental?: TypeOfRental;
  typeOfSubsidyRegulation?: TypeOfSubsidyRegulation;

  /** @format double */
  valuationCosts?: number | null;

  /** @format date-time */
  valuationDate?: string | null;

  /** @format date-time */
  wozValuationDate?: string | null;

  /** @format double */
  wozValue?: number | null;

  /** @format int32 */
  yearOfConstruction?: number | null;

  /** @format double */
  basicAnnualRentalIncome?: number | null;

  /** @format double */
  rentalValue?: number | null;

  /** @format double */
  expectedBasicAnnualRentalIncomeAfterRenovation?: number | null;

  /** @format double */
  rentalValueAfterRenovation?: number | null;
  isSustainabilityDiscussed?: boolean | null;
  isRoomRental?: boolean | null;
  nameOfTenant?: string | null;

  /** @format date-time */
  purchaseDate?: string | null;

  /** @format double */
  distributionValue?: number | null;

  /** @format double */
  collateralValue?: number | null;
}

export interface RemarkReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;
  line1?: string | null;
  line2?: string | null;
  line3?: string | null;
  line4?: string | null;
  line5?: string | null;
}

export enum Status {
  Loading = 'Loading',
  New = 'New',
  Submitted = 'Submitted',
  Cancelled = 'Cancelled',
  Accepted = 'Accepted',
}

export interface ApplicationReadDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  modifiedBy?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdDate?: string;
  applicants?: ApplicantReadDto[] | null;
  currentRealEstates?: CurrentRealEstateReadDto[] | null;
  intermediary?: IntermediaryReadDto;
  loan?: LoanReadDto;
  /** @format int32 */
  loanNumber?: number | null;
  moneylender?: MoneylenderReadDto;
  notaries?: NotaryReadDto[] | null;
  product?: ProductReadDto;
  realEstates?: RealEstateReadDto[] | null;
  remark?: RemarkReadDto;
  source?: string | null;
  status?: Status;
}

export interface AnsweredQuestion {
  question?: string | null;
  answer?: string | null;
  explanation?: string | null;
}

export interface Orientation {
  answeredQuestions?: AnsweredQuestion[] | null;
}

export interface BuyToLetApplicationRequest {
  application?: ApplicationReadDto;

  /** @format int32 */
  currentStep?: number | null;

  /** @format int32 */
  currentSubStep?: number | null;

  /** @format int32 */
  latestStepSeen?: number | null;
  latestSubStepSeen?: Record<string, number>;
  orientation?: Orientation;
}

export interface CreateBuyToLetEventResponse {
  application?: ApplicationReadDto;
  emailSent?: boolean;
}

export interface AnswerResult {
  questionId: string;
  answerIds?: string[] | null;
  answer?: string | null;
}

export interface ValidateOrientationPageRequestModel {
  answers?: AnswerResult[] | null;
}

export interface AnswerValidationResult {
  questionId?: string | null;
  answer?: AnswerResult;
  isValidated?: boolean;
}

export enum FinalStatus {
  None = 'None',
  StartUpload = 'StartUpload',
  StartBTLRequestWorkflow = 'StartBTLRequestWorkflow',
}

export interface ValidationResult {
  answers?: AnswerValidationResult[] | null;
  finalStatus?: FinalStatus;
}

export enum QuestionType {
  Toggle = 'Toggle',
  Checkbox = 'Checkbox',
  Radio = 'Radio',
  Dropdown = 'Dropdown',
  Text = 'Text',
}

export interface AnswerAction {
  nextQuestionId?: string | null;
  errorMessageKey?: string | null;
  finalStatus?: FinalStatus;
}

export interface Answer {
  id: string;
  answerTextKey?: string | null;
  action?: AnswerAction;
}

export interface Question {
  id: string;
  type: QuestionType;
  possibleAnswers?: Answer[] | null;
  questionTextKey?: string | null;
  questionInfoTextKey?: string | null;
  placeholderKey?: string | null;
  required?: boolean;
  exclusive?: boolean;
  hiddenInHyarchis?: boolean;
}

export interface QuestionData {
  entryQuestionId: string;
  questions: Question[];
}

export enum ClientFilterFilterField {
  LoanNumber = 'LoanNumber',
  Surname = 'Surname',
  MoneylenderName = 'MoneylenderName',
  HasPermission = 'HasPermission',
  LoanType = 'LoanType',
  CommercialLabel = 'CommercialLabel',
}

export enum ClientFilterFilterOperator {
  Equal = 'Equal',
  Like = 'Like',
}

export interface ClientFilter {
  field?: ClientFilterFilterField;
  operator?: ClientFilterFilterOperator;
  value?: string | null;
}

export interface FeedbackModel {
  feedback?: string | null;
  page?: string | null;
  subject?: string | null;
}

export interface Document {
  commercialLabel: string;

  /** @format int32 */
  loanNumber?: number;

  /** @format int32 */
  applicationIndexNumber?: number;

  /** @format int32 */
  code?: number;

  /** @format binary */
  file: File;

  /** @format int32 */
  sequenceNumber?: number;
}

/**
 * Mutation request types
 */
export enum MutationType {
  Raise = 'Raise',
  Conversion = 'Conversion',
  DismissalJointLiability = 'DismissalJointLiability',
  Transfer = 'Transfer',
  CustomApplication = 'CustomApplication',
}

/**
 * Represents a mutation request
 */
export interface MutationRequest {
  /**
   * The loan number the mutation is for
   * @format int32
   */
  loanNumber: number;

  /** Mutation request types */
  mutationType: MutationType;

  /**
   * File containing the information for the request
   * @format binary
   */
  uploadedFile: File;
}

export interface PolicyInfo {
  policyNumber?: string | null;
  hasPolicyInNameOfInsuredPerson?: boolean | null;

  /** @format int32 */
  numberOfPolicyHolders?: number | null;

  /** @format int32 */
  numberOfInsuredPeople?: number | null;
}

export interface LoanPartInfo {
  /** @format int32 */
  loanPartNumber?: number | null;
  hasConnectedPolicy?: boolean | null;
  policies?: PolicyInfo[] | null;
}

export interface ConversionMutationStateInfo {
  toggleInterest?: boolean | null;
  toggleCoFinancedFine?: boolean | null;
  toggleFine?: boolean | null;
  loanParts?: LoanPartInfo[] | null;
}

export interface ConversionMutationApplication {
  conversionMutationStateInfo?: ConversionMutationStateInfo;
  application?: ApplicationReadDto;
}

export interface Media {
  id?: string | null;
  title?: string | null;
  url?: string | null;
}

export interface Profile {
  id?: string | null;
  name?: string | null;
  identifier?: string | null;
  avatar?: Media;
  displayName?: string;
}

export interface NewsItem {
  id?: string | null;
  slug?: string | null;
  title?: string | null;
  author?: Profile;
  category?: string | null;

  /** @format date-time */
  dateCreated?: string;
  htmlContent?: string | null;
  introParagraph?: string | null;
  thumbnail?: Media;
  image?: Media;
  backgroundImage?: Media;
  video?: Media;
  backgroundColor?: string | null;
  isImportant?: boolean;
  isLogo?: boolean;
}

export interface Letter {
  /** @format date-time */
  creationDate: string;
  id: string;
  displayName: string;
}

export interface LetterResponse {
  /**
   * @format date-time
   * @example 2021-06-20T12:00:00.0000000+02:00
   */
  letters: Letter[];
}

export interface LetterDateAccessed {
  /** @format date-time */
  dateAccessed: string;
}

export interface Notification {
  consumerName?: string | null;

  /** @format date-time */
  dateAccessed?: string | null;

  /** @format date-time */
  dateCreated: string;

  /** @format int32 */
  id: number;

  /** @format int32 */
  intermediaryNumber: number;
  loanNumber?: string | null;
  commercialLabel: string;
  name: string;

  /** @format uuid */
  reference: string;

  /** @format int32 */
  streetNumber: number;
}

export interface NotificationResponse {
  /**
   * @format date-time
   * @example 2021-06-20T12:00:00.0000000+02:00
   */
  date: string;
  notifications: Notification[];
}

export interface NotificationDateAccessed {
  /** @format date-time */
  dateAccessed: string;
}

export interface DismissalJointLiabilityMutationStateInfo {
  changeLifeInsurance?: boolean | null;
  changeCurrentMortgage?: boolean | null;
  loanParts?: LoanPartInfo[] | null;
  hasBuyoutAmount?: boolean | null;
}

export interface DismissalJointLiabilityMutationApplication {
  application?: ApplicationReadDto;
  dismissalJointLiabilityMutationStateInfo?: DismissalJointLiabilityMutationStateInfo;
}

export interface UserManual {
  name?: string | null;

  /** @format date-time */
  updateDate?: string | null;
  pdf?: Media;
}

export interface Priority {
  priority?: string | null;
}

export interface TargetGroup {
  targetGroup?: string | null;
}

export interface UserNotification {
  id?: string | null;
  name?: string | null;
  title?: string | null;
  slug?: string | null;
  message?: string | null;
  messageBody?: string | null;
  image?: Media;

  /** @format date-time */
  dateCreated?: string;
  source?: Profile;
  priority?: Priority;
  targetGroups?: TargetGroup[] | null;
}
