import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterButton } from 'components/Header/Header.styled';

type FilterAreaProps = {
  loanType: string;
  handleTypeOfLoanSelection: (type: string) => void;
};
export default function FilterArea({ loanType, handleTypeOfLoanSelection }: FilterAreaProps) {
  const { t } = useTranslation();
  return (
    <Box>
      <FilterButton
        data-testid="filter-all"
        isselected={loanType === 'all' ? 1 : 0}
        disableRipple
        variant="text"
        disableElevation
        onClick={() => handleTypeOfLoanSelection('all')}
      >
        {t('clients.header.all')}
      </FilterButton>
      <FilterButton
        data-testid="filter-liveMortgage"
        isselected={loanType === 'LiveMortgage' ? 1 : 0}
        disableRipple
        variant="text"
        disableElevation
        onClick={() => handleTypeOfLoanSelection('LiveMortgage')}
      >
        {t('clients.header.mortgages')}
      </FilterButton>
      <FilterButton
        data-testid="filter-application"
        isselected={loanType === 'Application' ? 1 : 0}
        disableRipple
        variant="text"
        disableElevation
        onClick={() => handleTypeOfLoanSelection('Application')}
      >
        {t('clients.header.applications')}
      </FilterButton>
    </Box>
  );
}
