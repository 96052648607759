import { FC, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useMutationFeedback } from './postFeedback';
import SelectFeedback from './SelectFeedback';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getFeedbackResolver } from './feedbackFormSchema';

const useStyles = makeStyles((theme) => ({
  root: {
    'display': 'flex',
    'justifyContent': ' flex-end',
    'transition': 'all 0.3s ease-in-out',
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(1),
      transition: 'all 0.3s ease-in-out',
    },
    '& .MuiAccordion-root': {
      transform: 'rotate(-90deg)',
    },
    '& .MuiAccordion-root.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
  },
  form: {
    margin: theme.spacing(4, 1, 1),
  },
  feedbackBtn: {
    padding: theme.spacing(0, 2),
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.dark,
    borderRadius: theme.spacing(1, 1, 0, 0),
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      '&.MuiAccordionSummary-root': {
        height: '27px',
        minHeight: '27px',
      },
      '&.MuiAccordionSummary-root.Mui-expanded': {
        height: '48px',
        minHeight: '48px',
      },
    },
  },
  accordion: {
    'width': 140,
    'borderRadius': theme.spacing(1, 0, 0, 1),
    '&.MuiAccordion-root': {
      position: 'fixed',
      bottom: theme.spacing(7),
      right: '-46px',
      zIndex: 1300,
      [theme.breakpoints.down('sm')]: {
        bottom: '50vh',
        right: '-56px',
      },
    },
    '&.MuiAccordion-root.Mui-expanded': {
      'width': 500,
      'bottom': theme.spacing(4),
      'right': '0px',
      'boxShadow': '0 0 32px 16px rgb(22, 20, 47, 0.3)',
      '&.feedbackBtn': {
        borderRadius: theme.spacing(1, 0, 0, 0),
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },

  formError: {
    marginLeft: 0,
  },
}));

export type SubmittedFeedback = {
  subject: string;
  emailAddress: string;
  page: string;
  feedback: string;
};
const Feedback: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const pageUrl = window.location.href;

  const initialState: SubmittedFeedback = {
    subject: 'Algemeen',
    emailAddress: '',
    page: pageUrl,
    feedback: '',
  };

  const [expanded, setExpanded] = useState(false);

  const formMethods = useForm<SubmittedFeedback>({
    defaultValues: initialState,
    resolver: yupResolver(getFeedbackResolver),
    mode: 'onSubmit',
  });
  const {
    handleSubmit,
    setValue,
    watch,
    reset: resetFields,
    clearErrors,
    formState: { errors },
  } = formMethods;

  const feedback = watch('feedback');
  const subject = watch('subject');
  const emailAddress = watch('emailAddress');

  const options = [
    'Algemeen',
    'Uploaden',
    'Homepage',
    'Aanvragen',
    'Klantenlijst',
    'Mutaties',
    'Meekijken E-consument',
    'Brieven',
    'Nieuws',
    'Inloggen',
    'Rondleiding',
    'Handleiding',
  ];

  const { mutate, reset, isLoading, isError, isSuccess } = useMutationFeedback({
    subject,
    page: pageUrl,
    feedback,
    emailAddress,
  });

  useEffect(() => {
    if (isSuccess) {
      resetFields();
      setExpanded(false);
    }
  }, [isSuccess, resetFields]);

  const handleChange = (name: 'feedback' | 'subject' | 'emailAddress', value: string) => {
    clearErrors();
    setValue(name, value);
  };

  const handleCloseErrorSnackbar = () => {
    reset();
  };

  const handleCloseSuccessSnackbar = () => {
    reset();
    setExpanded(false);
  };

  const onSubmit = () => {
    mutate();
  };

  return (
    <div className={classes.root}>
      <FormProvider {...formMethods}>
        <Accordion
          className={classes.accordion}
          expanded={expanded || isLoading}
          onChange={() => setExpanded(!expanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.feedbackBtn}
            data-testid="feedback-btn"
          >
            <Typography>{t('feedback.title')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box className={classes.form}>
              <Grid container item alignItems="stretch">
                <Grid item xs={6} mb={3} pr={2}>
                  <FormControl required fullWidth>
                    <SelectFeedback
                      label={t('feedback.subject')}
                      selectedValue={subject}
                      handleChange={(e) => handleChange('subject', e.target.value)}
                      options={options}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} mb={3}>
                  <FormControl required fullWidth>
                    <TextField
                      name="emailAddress"
                      inputProps={{ 'data-testid': 'textfield-input-emailAddress' }}
                      required
                      error={!!errors.emailAddress}
                      id="outlined-multiline-static"
                      label={t('feedback.emailAddress')}
                      onChange={(e) => handleChange('emailAddress', e.target.value)}
                      onBlur={(e) => handleChange('emailAddress', e.target.value)}
                      value={emailAddress}
                      variant="outlined"
                    />
                    <FormHelperText className={classes.formError} error={!!errors.emailAddress}>
                      {errors.emailAddress?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  <FormControl required fullWidth>
                    <TextField
                      name="feedback"
                      inputProps={{ 'data-testid': 'textfield-input-feedback' }}
                      required
                      error={!!errors.feedback}
                      id="outlined-multiline-static"
                      label={t('feedback.title')}
                      onChange={(e) => handleChange('feedback', e.target.value)}
                      onBlur={(e) => handleChange('feedback', e.target.value)}
                      multiline
                      rows={4}
                      value={feedback}
                      variant="outlined"
                    />
                    <FormHelperText className={classes.formError} error={!!errors.feedback}>
                      {' '}
                      {errors.feedback?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <Button
              data-testid="send-feedback-btn"
              disabled={isLoading}
              size="small"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {t('btn.submit')}
            </Button>
          </AccordionActions>
        </Accordion>
        <CustomSnackbar
          isOpen={isError}
          handleClose={handleCloseErrorSnackbar}
          severity="error"
          message={t('error.default')}
          data-testid="feedback-snackbar-fail"
        />

        <CustomSnackbar
          isOpen={isSuccess}
          handleClose={handleCloseSuccessSnackbar}
          severity="success"
          message={t('feedback.sent')}
          data-testid="feedback-snackbar-success"
        />
      </FormProvider>
    </div>
  );
};

export default Feedback;
