const w = window as any;

export type Env = {
  FEATURES: {
    QUALITY_SCORE?: boolean;
    STAT_366304_ONBOARDING?: boolean;
    STAT_395388_FEEDBACK?: boolean;
    STAT_398216_REUPLOAD?: boolean;
    STAT_402813_BUYTOLETFLOW?: boolean;
    STAT_419521_CLIENT_DATA_SHARING?: boolean;
    STAT_420029_CONVERSION_MUTATION?: boolean;
    STAT_423710_RAISE_MUTATION?: boolean;
    STAT_430611_DUMMY_LOGIN?: boolean;
    STAT_458229_OHA_MUTATION?: boolean;
    STAT_460696_COLLATERAL_VALUE?: boolean;
    STAT_470139_PUSHNOTIFICATIES?: boolean;
    STAT_482932_ADD_NEW_CLIENT?: boolean;
    TASKS?: boolean;
  };
};

const runtimeEnv: Env = {
  FEATURES: w.FEATURES,
};

export default runtimeEnv;
