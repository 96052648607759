import { FC } from 'react';
import Unload from './Unload';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { SlideUpTransition } from '../../lib/Transition/transition';

const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    padding: theme.spacing(4, 4),
  },
  title: {
    display: 'flex',
    alignItems: 'flex-start',
    color: theme.palette.text.secondary,
    padding: theme.spacing(0, 0, 2, 0),
  },
  close: {
    margin: theme.spacing(-2, 0, 0, 0),
  },
  buttons: {
    padding: theme.spacing(4, 0, 2, 0),
  },
  button: {
    margin: theme.spacing(0, 2, 0, 0),
  },
  spacer: {
    flexGrow: 1,
  },
}));

type Props = {
  open?: boolean;
  pristine?: boolean;
  onSave: () => void;
  onClose: () => void;
  onContinue: () => void;
};

const SaveAndExitPopup: FC<Props> = ({
  open = false,
  pristine = true,
  onSave,
  onClose,
  onContinue,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      {!pristine && <Unload />}
      <Dialog open={!pristine && open} TransitionComponent={SlideUpTransition} onClose={onClose}>
        <Box className={classes.content}>
          <div className={classes.title}>
            <Typography variant="h6">{t('general.saveAndExit.header')}</Typography>
            <span className={classes.spacer} />
            <IconButton onClick={onClose} className={classes.close} size="large">
              <Close />
            </IconButton>
          </div>

          <Box className={classes.buttons}>
            <Button
              onClick={onSave}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {t('general.saveAndExit.saveButton')}
            </Button>
            <Button onClick={onContinue} variant="text" className={classes.button}>
              {t('general.saveAndExit.exitButton')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default SaveAndExitPopup;
