import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Typography, Button, IconButton, Hidden } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DescriptionOutlined, Close } from '@mui/icons-material';
import Zoom from '../DocumentViewer/Zoom';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky', // TOOD: ie11??
    top: 0,

    display: 'flex',
    width: '100%',
    minHeight: 72,

    alignItems: 'center',
    backgroundColor: theme.palette.background.default,

    boxShadow: theme.shadows[4],
    zIndex: 3,

    padding: theme.spacing(2),
  },

  headerIcon: {
    color: theme.palette.grey[600],
    margin: 12,
  },

  zoom: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  headerTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      flexGrow: 1,
    },
  },

  download: {
    display: 'flex',
    alignItems: 'center',
  },
  downloadBtn: {
    'color': theme.palette.grey[600],
    'paddingRight': theme.spacing(2),
    '&:hover': {
      color: theme.palette.grey[900],
    },
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
}));

type Props = {
  description: string;
  shortDescription?: string;
  onZoom: (scale: number) => void;
  onClose: () => void;
  onDownloadPdf?: () => void;
  zoomVisible?: boolean;
  downloadVisible?: boolean;
  status?: string;
};

const DocumentHeader: FC<Props> = ({
  description,
  shortDescription = '',
  zoomVisible,
  downloadVisible,
  onZoom,
  onDownloadPdf,
  onClose,
  status,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <DescriptionOutlined className={classes.headerIcon} />

      <div className={classes.headerTitle}>
        <Typography variant="h6">{shortDescription}</Typography>
        <span>{description}</span>
      </div>

      <Hidden mdDown>
        <div className={classes.zoom}>{zoomVisible && <Zoom onZoom={onZoom} />}</div>
      </Hidden>

      <Hidden mdDown>
        {downloadVisible ? (
          <div className={classes.download}>
            <Button
              disabled={status !== 'success'}
              onClick={onDownloadPdf}
              variant="text"
              className={classes.downloadBtn}
              type="submit"
              startIcon={<Icon className={classes.btnIcon}>download</Icon>}
            >
              {t('fileViewer.download')}
            </Button>
          </div>
        ) : null}
      </Hidden>

      <IconButton onClick={onClose} size="large">
        <Close />
      </IconButton>
    </div>
  );
};

export default DocumentHeader;
