import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Typography, DialogContent, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import OfficeList from './components/OfficeList';
import SearchOfficeForm from './components/SearchOfficeForm';
import { SearchOfficeFormValues } from './components/SearchOfficeForm/SearchOfficeForm';
import { logout } from '../../auth/oidc';
import { getPartyId } from './utils/setPartyId';
import isNil from 'utils/predicates/isNil';
import { AdvisorOffice } from 'types/AdvisorOffices';
import getAdvisorOffices from 'api/postAdvisorOffices';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(2, 0, 5),
  },
  search: {
    marginBottom: theme.spacing(2),
  },
  result: {
    minHeight: '100px',
  },
  footer: {
    textAlign: 'right',
    paddingBottom: theme.spacing(3),
  },
  logo: {
    height: 36,
    width: 36,
    margin: theme.spacing(0.25, 3, 0.25, 0),
  },
  button: {
    minWidth: '100px',
  },
}));

export const OfficeSelect: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [resultsVisible, setResultsVisible] = useState(false);
  const [advisorOffices, setAdvisorOffices] = useState<AdvisorOffice[]>([]);
  const [dialogOpen, setDialogOpen] = useState(isNil(getPartyId()));
  const [error, setError] = useState<string | null>(null);

  const onSubmit = async (data: SearchOfficeFormValues) => {
    setError(null);

    try {
      const response = await getAdvisorOffices(data.namePart, data.kvkNumber);

      setAdvisorOffices(response.advisoryOffices);
    } catch (e) {
      const error = e as Error;
      const errorType: string = ErrorTypeHelper(error.cause, error.message);
      setError(errorType);
    } finally {
      setResultsVisible(true);
    }
  };

  const ErrorTypeHelper = (status: unknown, message?: string): string => {
    switch (status) {
      case 400:
        return message ?? '';
      case 404:
        return 'offices.noOffices';
      default:
        return 'offices.noService';
    }
  };
  return (
    <Dialog open={dialogOpen} fullWidth maxWidth="sm" scroll="paper">
      <DialogContent>
        <div className={classes.header}>
          <Typography variant="h4">{t('offices.select')}</Typography>
        </div>

        <div className={classes.search}>
          <SearchOfficeForm onValid={onSubmit} />
        </div>
        <div className={classes.result}>
          {resultsVisible && (
            <OfficeList
              error={error}
              offices={advisorOffices}
              onClick={() => {
                setDialogOpen(false);
              }}
            />
          )}
        </div>
        <div className={classes.footer}>
          <Button variant="contained" color="secondary" onClick={logout} className={classes.button}>
            {t('offices.logout')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OfficeSelect;
