import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    padding: theme.spacing(4, 4),
  },
  title: {
    display: 'flex',
    alignItems: 'flex-start',
    color: theme.palette.text.secondary,
    padding: theme.spacing(0, 0, 2, 0),
  },
  close: {
    margin: theme.spacing(-2, 0, 0, 0),
  },
  buttonGroup: {
    'padding': theme.spacing(4, 0, 2, 0),
    '& button': {
      margin: theme.spacing(0, 2, 0, 0),
    },
  },
  spacer: {
    flexGrow: 1,
  },
}));
