import { FC, useCallback, useState } from 'react';
import { Button, Icon } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStyles } from './ObligationList.styled';
import { Obligation as ObligationType, RootFormName } from 'screens/Mutations/utils/types';
import Obligation from './Obligation';
import { DeleteOutline } from '@mui/icons-material';
import { ConfirmationDialog } from 'components/CustomDialog';

type Props = {
  rootFormName?: RootFormName | string;
  shouldUnregister?: boolean;
};

const ObligationList: FC<Props> = ({ rootFormName, shouldUnregister = true }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: rootFormName ? `${rootFormName}obligations` : 'obligations',
    control,
  });

  const [selectedIncome, setSelectedIncome] = useState<number>();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const closeDialog = useCallback(() => setDialogIsOpen(false), []);

  const handleDelete = useCallback(
    (index: number) => () => {
      setDialogIsOpen(true);
      setSelectedIncome(index);
    },
    []
  );

  const handleConfirmationDialog = useCallback(() => {
    remove(selectedIncome);
    setSelectedIncome(undefined);
    closeDialog();
  }, [closeDialog, remove, selectedIncome]);

  const handleAddIncome = useCallback(() => {
    append({} as ObligationType);
  }, [append]);

  return (
    <>
      {fields.map((field, index) => (
        <Obligation
          key={field.id}
          index={index}
          rootFormName={rootFormName}
          shouldUnregister={shouldUnregister}
        >
          <Button
            onClick={handleDelete(index)}
            size="medium"
            className={`${classes.deleteButton} ${classes.warning}`}
          >
            {t('directMutations.buttons.deleteButton')}
            <DeleteOutline className={classes.buttonIcon} />
          </Button>
        </Obligation>
      ))}
      {fields.length <= 10 && (
        <Button
          onClick={handleAddIncome}
          variant="contained"
          color="primary"
          size="medium"
          className={classes.button}
        >
          {t('directMutations.buttons.addObligationButton')}
          <Icon className={classes.buttonIcon}>plus</Icon>
        </Button>
      )}
      <ConfirmationDialog
        isOpen={dialogIsOpen}
        onConfirmation={handleConfirmationDialog}
        onDecline={closeDialog}
        onClose={closeDialog}
        confirmationText={t('directMutations.buttons.deleteButton')}
        declineText={t('directMutations.buttons.cancelButton')}
        bodyText={t('directMutations.confirmDelete.bodyObligation')}
      />
    </>
  );
};

export default ObligationList;
