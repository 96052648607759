import { getProofItemButtonOptions } from 'components/ProofItemListItem/utils/getProofItemButtonOptions';
import env from 'config/env';
import { getProofItemStatus } from 'utils/proof-item';
import { ProofItem } from 'types/ProofItem';
import ProofItemButtonOptions from 'types/ProofItemsButtons';

export const mapToProofItemButtonOptions = ({
  status,
  isUploadAllowed,
  isOckto,
  numberOfUploads,
}: ProofItem): ProofItemButtonOptions | null => {
  const proofItemStatus = getProofItemStatus(status);
  return getProofItemButtonOptions(
    proofItemStatus,
    isUploadAllowed,
    isOckto,
    env.FEATURES.STAT_398216_REUPLOAD,
    numberOfUploads
  );
};
