import { useRef } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OnboardingJourneyDialog from '../../OnboardingJourney/OnboardingJourneyDialog';
import OnboardingWelcomeDialog from '../../OnboardingJourney/OnboardingWelcomeDialog';
import OnboardingPopover from '../../OnboardingJourney/OnboardingPopover';
import { useOnboardingNotification } from 'use/useOnboardingNotification';
import { IsMobile } from 'utils/global';
import { useTranslation } from 'react-i18next';
import { MenuItemWapper } from '../NavDrawer.styled';
import Item from './Item';
import env from 'config/env';

const OnboardingJourneyButton = () => {
  const { t } = useTranslation();
  const { setJourneyVisible, onboardingJourneyVisible } = useOnboardingNotification();
  const anchorElRef = useRef();
  const mobile = IsMobile();

  const handleClick = () => {
    setJourneyVisible(true);
  };

  if (mobile || !env.FEATURES.STAT_366304_ONBOARDING) {
    return null;
  }

  return (
    <>
      <MenuItemWapper ref={anchorElRef.current}>
        <Item
          icon={<HelpOutlineIcon fontSize="inherit" />}
          menuName={t('onboarding.demo')}
          handleClick={handleClick}
        />
      </MenuItemWapper>
      <OnboardingPopover
        open={onboardingJourneyVisible}
        onClose={() => setJourneyVisible(false)}
        anchorEl={anchorElRef.current}
      />
      <OnboardingJourneyDialog />
      <OnboardingWelcomeDialog />
    </>
  );
};

export default OnboardingJourneyButton;
