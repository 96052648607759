import { Hidden, Box, Divider, Grid } from '@mui/material';
import Logo from 'components/Logo';
import StatusTag from 'components/StatusTag';
import { getCustomerNamesApplicationData, formatLoanAmount } from 'utils/global';
import { useTranslation } from 'react-i18next';
import { Emphasize, MobileTableRow } from 'screens/templates/Lists/ListWrapper/Wrapper.styled';
import { ApplicationData } from '__generated__/api';

type ConceptMobileProps = {
  onClickItem: (item: ApplicationData) => void;
  items: ApplicationData[];
};

export default function ConceptMobile({ onClickItem, items }: ConceptMobileProps) {
  const { t } = useTranslation();

  return (
    <Hidden smUp>
      {items.map((item: ApplicationData, index: number) => (
        <Box
          key={`${item.id}-${index}`}
          onClick={() => onClickItem(item)}
          data-testid="conceptList-mobile"
        >
          <Grid container my={1}>
            <Grid item xs={2}>
              <Logo label={item.commercialLabel} alt="logo" />
            </Grid>
            <Grid item xs={9}>
              <Emphasize>{getCustomerNamesApplicationData(item)}</Emphasize>
              <MobileTableRow>
                <span>{item.commercialName}</span>
                <span>‧</span>
                <span>{formatLoanAmount(item.loanAmount!)}</span>
                <span>‧</span>
                <span> {t(`type.${item.type}`)}</span>
              </MobileTableRow>
              <Grid my={2}>
                <StatusTag urgency="blue" progress={0} label={t('applications.filter.concept')} />
              </Grid>
            </Grid>
          </Grid>
          <Divider />
        </Box>
      ))}
    </Hidden>
  );
}
