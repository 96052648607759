import {
  Grid,
  GridSize,
  Icon,
  MenuItem,
  Select,
  SelectProps,
  SelectChangeEvent,
} from '@mui/material';
import { FormField } from './FormField';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

export type DropDownQuestionAnswer = {
  id: string;
  answerText?: string;
  answerTextKey?: string;
};

interface DropDownQuestionProps extends SelectProps {
  answerOptions: DropDownQuestionAnswer[];
  toolTip?: ReactNode;
  containerWidth?: GridSize;
  helperText?: ReactNode;
  nullable?: boolean;
}

const useStyles = makeStyles((theme) => ({
  menuItem: {
    'display': 'flex',
    'height': 50,
    'paddingLeft': theme.spacing(2),
    'paddingRight': theme.spacing(2),
    'color': theme.palette.text.secondary,
    '&:hover, &.Mui-selected:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette.lightest.light,
    },
  },
  placeholder: {
    'display': 'flex',
    'height': 50,
    'paddingLeft': theme.spacing(2),
    'paddingRight': theme.spacing(2),
    'color': theme.palette.text.primary,
    'backgroundColor': theme.palette.grey[100],
    '&:hover, &.Mui-selected:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette.lightest.light,
    },
  },
  spacer: {
    flex: 1,
  },
  select: {
    '& .MuiIcon-root': {
      display: 'none',
    },
  },
  selectDefault: {
    '& .MuiSelect-select': {
      color: theme.palette.text.disabled,
      fontWeight: 400,
    },
    '& .MuiSelect-root': {
      fontSize: 13.713,
    },
  },
}));

const DropDownQuestion: FC<DropDownQuestionProps> = ({
  answerOptions,
  toolTip,
  containerWidth,
  helperText,
  nullable,
  ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isSelected = (value: unknown, option: string) => {
    if (typeof value === 'string' && value === option) {
      return true;
    }
    return null;
  };

  return (
    <FormField
      open
      label={props.label}
      tooltip={toolTip}
      required={props.required}
      helperText={helperText}
      disabled={props.disabled}
    >
      <Grid container item spacing={3}>
        <Grid
          item
          container
          xs={containerWidth ?? 12}
          md={containerWidth ?? 9}
          lg={containerWidth ?? 6}
        >
          <Select
            style={{ width: '100%' }}
            {...props}
            label={null}
            onChange={(e, c) => {
              props.onChange &&
                props.onChange(
                  {
                    ...e,
                    target: {
                      ...e.target,
                      value: e.target.value === '' ? undefined : e.target.value,
                    },
                  } as SelectChangeEvent<{ value: unknown; name: string }>,
                  c
                );
            }}
            value={props.value}
            displayEmpty
            className={`${classes.select} ${props.value === '' ? classes.selectDefault : ''}`}
          >
            <MenuItem
              key={`${props.name}`}
              className={classes.placeholder}
              disabled={!nullable}
              value=""
            >
              {props.placeholder ?? t('lib.select')}
            </MenuItem>

            {answerOptions.map((answer) => (
              <MenuItem key={answer.id} value={answer.id} className={classes.menuItem}>
                {answer.answerText ?? t(`${answer.answerTextKey}`)}
                <span className={classes.spacer} />
                <Icon
                  style={{
                    visibility: isSelected(props.value, answer.id) ? 'visible' : 'hidden',
                    marginLeft: 18,
                  }}
                  color="primary"
                >
                  checked
                </Icon>
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </FormField>
  );
};

export default DropDownQuestion;
