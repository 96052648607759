import { useEffect, useState, FC } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { googleTagManager } from 'use/gtm';
import { useTranslation } from 'react-i18next';
import ScreenModal from 'components/ScreenModal';
import ClientDetailMortgageList from './ClientMortgage/ClientDetailMortgageList';
import { Client, ClientLoan } from 'types/Client';
import ClientDetailHeader from './ClientDetailHeader';
import ClientDetailLetterList from './ClientLetter/ClientDetailLetterList';
import ClientDetailApplicationList from './ClientApplication/ClientDetailApplicationList';
import { LoanInfo } from 'components/MutationDialog/useMutation';
import MutationPreFlow from 'components/MutationDialog/MutationPreFlow';
import { ClientDetailsContainer, CloseIcon } from 'screens/Clients/Clients.styled';
import { useLocation } from 'react-router-dom';
import { State } from 'screens/Clients/hooks/useClients';

export type ClientDetailProps = {
  client: Client;
  onClose: () => void;
};

const ClientDetail: FC<ClientDetailProps> = ({ client, onClose }) => {
  const { t } = useTranslation();
  const state: State = useLocation().state;
  const [modalOpen, setModalOpen] = useState(false);
  const [mortgageToMutate, setMortgageToMutate] = useState<ClientLoan>();
  const [loanInfo, setLoanInfo] = useState<LoanInfo>();
  const hideModal = () => setModalOpen(false);
  const applications = client.loans.filter((loan) => loan.loanType === 'Application');
  const mortgages = client.loans.filter((loan) => loan.loanType === 'LiveMortgage');

  useEffect(() => {
    if (client) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [client]);

  const handleMutateMortgage = (mortgageIndex: number, loanInfo: LoanInfo) => {
    setMortgageToMutate(mortgages[mortgageIndex]);
    setLoanInfo(loanInfo);
  };

  const handleCancelMutate = () => {
    setMortgageToMutate(undefined);
    googleTagManager({
      event: 'ButtonClick',
      btnName: 'PreviousStep',
    });
  };

  return (
    <ScreenModal open={modalOpen} onClose={hideModal} onClosed={onClose}>
      <ClientDetailsContainer data-testid="client-details">
        <Grid position="relative">
          <CloseIcon onClick={onClose} size="large">
            <Close />
          </CloseIcon>
          <ClientDetailHeader
            persons={client.persons}
            applications={applications}
            mortgages={mortgages}
          />
          {mortgageToMutate && loanInfo?.mutations ? (
            <>
              <Box mt={4} mb={2}>
                <Typography variant="h6" component="div">
                  {t('clients.detail.selected')}
                  {mortgageToMutate.moneylenderName}
                </Typography>
              </Box>
              <Divider />
              <MutationPreFlow
                from={state?.from}
                loanInfo={loanInfo}
                loanNumber={mortgageToMutate.loanNumber.toString()}
                commercialLabel={mortgageToMutate.commercialLabel}
                onCancelMutate={handleCancelMutate}
              />
            </>
          ) : (
            <>
              <ClientDetailMortgageList
                onClickMutate={handleMutateMortgage}
                mortgages={mortgages}
              />
              <ClientDetailApplicationList applications={applications} />
            </>
          )}
          {client.loans.map((loan) => (
            <ClientDetailLetterList
              loanNumber={loan.loanNumber}
              commercialLabel={loan.commercialLabel}
            />
          ))}
        </Grid>
      </ClientDetailsContainer>
    </ScreenModal>
  );
};

export default ClientDetail;
