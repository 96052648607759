import { ApplicationReadDto, ConversionMutationApplication } from '__generated__/api';
import { source } from 'screens/Mutations/components/ConversionMutation/constants';
import { ConversionForm } from '../../../types';
import {
  mapToggleButton,
  mapLoanPartInfo,
  mapCurrentLoanParts,
  mapNewLoanParts,
  mapIntermediaryData,
} from './helpers';

export function mapConversionMutationForm(form: ConversionForm): ConversionMutationApplication {
  const {
    connectedPolicies,
    loanDetails: { conversionQuestionnaire, loanParts, ...loanDetails },
  } = form;

  return {
    conversionMutationStateInfo: {
      toggleInterest: mapToggleButton(conversionQuestionnaire.toggleInterest),
      toggleCoFinancedFine: mapToggleButton(conversionQuestionnaire.toggleCoFinancedFine),
      toggleFine: mapToggleButton(conversionQuestionnaire.toggleFine),
      loanParts: mapLoanPartInfo(loanParts, connectedPolicies.loanPartPolicies),
    },
    application: {
      currentRealEstates: [
        {
          currentLoans: [
            {
              currentLoanParts: mapCurrentLoanParts(loanParts, connectedPolicies.loanPartPolicies),
              loanNumber: conversionQuestionnaire.loanNumber.toString(),
              principalAmount: conversionQuestionnaire.residualDebt,
            },
          ],
        },
      ],
      loan: {
        loanParts: mapNewLoanParts(loanParts),
        amount: conversionQuestionnaire.residualDebt,
        typeOfLoan: loanDetails.typeOfLoan,
        additionalInformation: loanDetails.additionalInformation?.info,
      },
      loanNumber: conversionQuestionnaire.loanNumber,
      moneylender: {
        commercialLabel: loanDetails.commercialLabel,
        name: loanDetails.moneylenderName,
      },
      intermediary: mapIntermediaryData(form),
      applicants: loanDetails.applicants ?? [],
      id: loanDetails.id,
      notaries: null,
      realEstates: null,
      product: {},
      remark: {},
      source: source,
      status: loanDetails.status,
    },
  };
}

export function mapSubmitConversionMutation(form: ConversionForm): ApplicationReadDto {
  const mappedForm = mapConversionMutationForm(form);
  return mappedForm.application ? mappedForm.application : {};
}
