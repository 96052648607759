import { useState } from 'react';
import Hidden from '@mui/material/Hidden';
import Table from '@mui/material/Table';
import TableFooter from '@mui/material/TableFooter';
import Typography from '@mui/material/Typography';
import type { Client } from 'types/Client';
import Pagination from 'components/Pagination/Pagination';
import ClientListMobile from './components/ClientListMobile';
import ClientListDesktop from './components/ClientListDesktop';
import PlaceholderEmptyList from 'components/PlaceholderEmptyList';
import { EmptyListRefreshDescriptionWrapper } from 'screens/Clients/Clients.styled';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from 'components/LoadingIndicator';
import { PaginationWrapper } from 'screens/templates/Lists/ListWrapper/Wrapper.styled';

export type ClientListProps = {
  clients: Client[] | [];
  totalClients?: number;
  page: number;
  onClickItem: (item: Client, location?: string) => void;
  onPage: (page: number) => void;
  isLoading: boolean;
};

const ClientList = ({
  isLoading,
  clients,
  onPage,
  totalClients = 0,
  page,
  onClickItem,
}: ClientListProps) => {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (event: any, newPage: number) => {
    onPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 20));
  };

  if (isLoading) {
    return <LoadingIndicator data-testid="client-loading" />;
  }

  if (clients.length === 0) {
    return (
      <PlaceholderEmptyList subtitle={t('clients.empty.subtitle')}>
        <EmptyListRefreshDescriptionWrapper>
          <Typography variant="subtitle2" color="textSecondary">
            {t('clients.empty.refreshPage')}
          </Typography>
        </EmptyListRefreshDescriptionWrapper>
      </PlaceholderEmptyList>
    );
  }

  return (
    <>
      <ClientListDesktop clients={clients} onClickItem={onClickItem} />
      <ClientListMobile clients={clients} onClickItem={onClickItem} />
      <Hidden smDown>
        <Table data-testid="pagination">
          <TableFooter>
            <PaginationWrapper data-testid="pagination-wrapper">
              <Pagination
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                count={totalClients}
              />
            </PaginationWrapper>
          </TableFooter>
        </Table>
      </Hidden>
    </>
  );
};

export default ClientList;
