import type { FC } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { FormInputProps } from '../FormInputProps';
import NumberInputsTemplate from '../NumberInputsTemplate/NumberInputsTemplate';

export interface FormInputPercentageProps extends FormInputProps {
  placeholder?: string;
  defaultValue?: string | number;
  required?: boolean;
  testId?: string;
}

const FormInputPercentage: FC<FormInputPercentageProps> = ({
  name,
  label,
  placeholder,
  required,
  disabled,
  shouldUnregister = false,
  testId,
  defaultValue,
}) => {
  const validatePercentage = (values: NumberFormatValues): boolean => {
    const { floatValue } = values;
    let valid = true;
    valid = valid && (floatValue === undefined || floatValue >= 0);
    valid = valid && (floatValue === undefined || floatValue < 100);

    return valid;
  };

  return (
    <NumberInputsTemplate
      defaultValue={defaultValue}
      name={name}
      label={label}
      placeholder={placeholder}
      suffix=" %"
      required={required}
      disabled={disabled}
      shouldUnregister={shouldUnregister}
      testId={testId}
      handleIsAllowed={validatePercentage}
    />
  );
};

export default FormInputPercentage;
