import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Hidden, Paper, Button, Icon, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Action,
  SolvedByOtherSolution as SolvedByOtherSolutionData,
} from '../../../store/taskReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 6,
    display: 'flex',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
  },
  text: {
    flex: 1,
  },
  text2: {
    marginBottom: theme.spacing(0),
  },
  input: {
    marginTop: 0,
  },
  btnIcon: {
    transform: 'scale(0.8)',
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.danger.main,
    flexDirection: 'column',
  },
}));

interface SolvedByOtherSolutionProps {
  issueId: string;
  solution: SolvedByOtherSolutionData;
  dispatch: React.Dispatch<Action>;
}

const SolvedByOtherSolution: React.FC<SolvedByOtherSolutionProps> = ({
  dispatch,
  issueId,
  solution,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const deleteSolution = () => {
    dispatch({
      type: 'remove',
      payload: {
        issueId: issueId,
        data: solution,
      },
    });
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.text}>
        <div className={classes.text2}>
          <Typography variant="body1">{t('tasks.solutions.solvedByOther')}</Typography>
        </div>
      </div>
      <Hidden mdUp>
        <IconButton
          className={classes.deleteButton}
          onClick={(e) => {
            e.stopPropagation();
            deleteSolution();
          }}
          size="large"
        >
          <Icon>trash</Icon>
        </IconButton>
      </Hidden>
      <Hidden mdDown>
        <Button
          className={classes.deleteButton}
          endIcon={<Icon className={classes.btnIcon}>trash</Icon>}
          variant="text"
          onClick={(e) => {
            e.stopPropagation();
            deleteSolution();
          }}
        >
          {t('tasks.solutions.delete')}
        </Button>
      </Hidden>
    </Paper>
  );
};

export default SolvedByOtherSolution;
