import { useTranslation } from 'react-i18next';
import { logout } from 'auth/oidc';
import Item from './Item';
import LogoutIcon from '@mui/icons-material/Logout';
import { MenuItemWapper } from '../NavDrawer.styled';

const LogoutButton = () => {
  const { t } = useTranslation();

  return (
    <MenuItemWapper>
      <Item
        icon={<LogoutIcon fontSize="inherit" color="inherit" />}
        menuName={t('general.header.menu.logout')}
        handleClick={logout}
      />
    </MenuItemWapper>
  );
};

export default LogoutButton;
