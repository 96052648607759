import { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
} from '@mui/material/';

import { useTranslation } from 'react-i18next';
import { Client, ClientLoan, getCustomerNames } from 'types/Client';
import { formatLoanNumber } from 'utils/global';
import { SlideUpTransition } from 'lib/Transition/transition';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2, 3),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  closeIcon: {
    margin: '-12px -12px 12px 12px',
  },
  dialogBtns: {
    padding: theme.spacing(3),
  },
  value: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    paddingTop: theme.spacing(0.5),
  },
}));

type Props = {
  open: boolean;
  client: Client;
  loan: ClientLoan;
  handleClose: () => void;
  onConfirm: () => void;
};

const ClientDataConfirmationDialog: FC<Props> = ({
  open,
  client,
  loan,
  handleClose,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const translationsRoot = 'clients.dataSharing.confirmationDialog';
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={SlideUpTransition}
      aria-labelledby="datasharing-confirmation-dialog"
      data-testid="confirmation-dialog"
      className={classes.dialog}
    >
      <Box className={classes.dialog}>
        <DialogTitle className={classes.title}>
          {t(`${translationsRoot}.question`)}
          <IconButton
            onClick={handleClose}
            data-testid="confirmation-dialog-close"
            size="large"
            className={classes.closeIcon}
          >
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            className={classes.label}
          >
            <div>{t(`${translationsRoot}.customerName`)}</div>
            <div>{t(`${translationsRoot}.loanNumber`)}</div>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            className={classes.value}
          >
            <div>{getCustomerNames(client)}</div>
            <div>{formatLoanNumber(loan.loanNumber)}</div>
          </Box>
        </DialogContent>

        <DialogActions classes={{ root: classes.dialogBtns }}>
          <Button
            onClick={handleClose}
            variant="text"
            color="primary"
            data-testid="confirmation-dialog-cancel"
          >
            {t(`${translationsRoot}.cancel`)}
          </Button>
          <Button
            autoFocus
            onClick={onConfirm}
            variant="contained"
            color="primary"
            data-testid="confirmation-dialog-submit"
          >
            {t(`${translationsRoot}.submit`)}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ClientDataConfirmationDialog;
