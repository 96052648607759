import React, { FC } from 'react';
import BuyToLetApplicantPersonalSummary from './BuyToLetApplicantPersonalSummary';
import BuyToLetApplicantIncomesAndPropertysSummary from './BuyToLetApplicantIncomesAndPropertiesSummary';
import BuyToLetApplicantContactDataSummary from '../BuyToLetApplicantContactDataSummary';
import { Typography, Paper, Box, Button, Icon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BtlApplicant } from 'types/BtlFormApplication';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: 60,
    marginTop: theme.spacing(5),
  },
  card: {
    'padding': theme.spacing(3),
    'borderRadius': 4,
    '&.margin': {
      marginBottom: theme.spacing(3),
    },
  },
  subHeader: {
    marginBottom: theme.spacing(3),
  },
}));

export type BuyToLetNaturalApplicantSummaryProps = {
  applicant: BtlApplicant;
  applicantIndex: number;
  navigateToApplicant: any;
  navigateToApplicantField: any;
  errorMessage: (id: string, category: string) => void;
};

const BuyToLetNaturalApplicantSummary: FC<BuyToLetNaturalApplicantSummaryProps> = ({
  applicant,
  applicantIndex,
  navigateToApplicant,
  navigateToApplicantField,
  errorMessage,
}) => {
  const classes = useStyles();
  const form = 'buyToLetForm.summary';
  const { t } = useTranslation();

  function formatName(applicant: BtlApplicant) {
    return [applicant.firstName, applicant.middleName, applicant.lastName]
      .filter((value) => value)
      .join(' ');
  }
  return (
    <>
      <div className={classes.wrapper} key={applicant.dateOfBirth}>
        <Typography className={classes.subHeader} variant="h4">
          {formatName(applicant)}
        </Typography>
        <Paper className={`${classes.card} margin`}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.subHeader} variant="h6">
              {`${formatName(applicant)} - ${t('applications.stepTitle.personalData')}`}
            </Typography>
            <Button
              variant="text"
              color="primary"
              endIcon={<Icon>pencil</Icon>}
              onClick={() => navigateToApplicant(applicantIndex, 0)}
            >
              {t(`${form}.edit`)}
            </Button>
          </Box>

          <BuyToLetApplicantPersonalSummary
            applicant={applicant}
            applicantIndex={applicantIndex}
            onClickField={(field: any) => navigateToApplicantField(applicantIndex, 0, field)}
            errorMessage={errorMessage}
          />
        </Paper>
        <Paper className={`${classes.card} margin`}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.subHeader} variant="h6">
              {`${formatName(applicant)} - ${t('buyToLetForm.subStepTitle.incomesAndProperties')}`}
            </Typography>
            <Button
              variant="text"
              color="primary"
              endIcon={<Icon>pencil</Icon>}
              onClick={() => navigateToApplicant(applicantIndex, 1)}
            >
              {t(`${form}.edit`)}
            </Button>
          </Box>

          <BuyToLetApplicantIncomesAndPropertysSummary
            applicant={applicant}
            applicantIndex={applicantIndex}
            onClickField={(field: any) => navigateToApplicantField(applicantIndex, 1, field)}
            errorMessage={errorMessage}
          />
        </Paper>

        <Paper className={`${classes.card} margin`}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.subHeader} variant="h6">
              {`${formatName(applicant)} - ${t('applications.stepTitle.correspondance')}`}
            </Typography>
            <Button
              variant="text"
              color="primary"
              endIcon={<Icon>pencil</Icon>}
              onClick={() => navigateToApplicant(applicantIndex, 2)}
            >
              {t(`${form}.edit`)}
            </Button>
          </Box>
          <BuyToLetApplicantContactDataSummary
            applicant={applicant}
            applicantIndex={applicantIndex}
            onClickField={(field: any) => navigateToApplicantField(applicantIndex, 2, field)}
            errorMessage={errorMessage}
          />
        </Paper>
      </div>
    </>
  );
};

export default BuyToLetNaturalApplicantSummary;
