import { SummaryCard, CardRow, CardSection } from 'components/SummaryCard';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EmploymentIncome } from 'screens/Mutations/utils/types';
import { TypeOfEmployment } from '__generated__/api';
import { formatAmount } from 'utils/formatAmount';
import formatDate from 'utils/formatDate';
import { getTypeOfEmploymentOption } from 'screens/Mutations/constants';

type EmploymentIncomeProps = {
  income: EmploymentIncome;
  onClick: () => void;
};

const Employment: FC<EmploymentIncomeProps> = ({ income, onClick }) => {
  const { t } = useTranslation();

  const isFlexible =
    income.typeOfEmployment === TypeOfEmployment.FlexibleWithoutPerspective ||
    income.typeOfEmployment === TypeOfEmployment.FlexibleWithPerspective;

  const isSocialInsuranceWage = income.typeOfEmployment === TypeOfEmployment.SocialInsuranceWage;

  const hasEndDate =
    income.typeOfEmployment !== TypeOfEmployment.Permanent &&
    income.typeOfEmployment !== TypeOfEmployment.TemporaryWithIntent;

  const root = 'directMutations.income.employment';

  return (
    <>
      <SummaryCard title={t('directMutations.income.formTitle')} onEditClick={onClick}>
        <CardSection>
          <CardRow
            name={t('directMutations.income.typeOfIncome')}
            value={t('directMutations.income.incomeTypes.Employment')}
          />
          <CardRow
            name={t(`${root}.typeOfEmployment`)}
            value={getTypeOfEmploymentOption(income.typeOfEmployment).label}
          />
          {isSocialInsuranceWage ? (
            <>
              <CardRow name={t(`${root}.incomeAmount`)} value={formatAmount(income.incomeAmount)} />
              <CardRow name={t(`${root}.numberOfEmployments`)} value={income.numberOfEmployments} />
              <CardRow
                name={t(`${root}.pensionContribution`)}
                value={formatAmount(income.pensionContribution)}
              />
            </>
          ) : (
            <>
              <CardRow name={t(`${root}.startDate`)} value={formatDate(income.startDate, '-')} />
              {hasEndDate && (
                <CardRow name={t(`${root}.endDate`)} value={formatDate(income.endDate, '-')} />
              )}
              <CardRow name={t(`${root}.averageHoursPerWeek`)} value={income.averageHoursPerWeek} />
              <CardRow name={t(`${root}.grossSalary`)} value={formatAmount(income.grossSalary)} />
              {isFlexible && (
                <>
                  <CardRow
                    name={t(`${root}.flexibleAnnualIncome1`)}
                    value={formatAmount(income.flexibleAnnualIncome1)}
                  />
                  <CardRow
                    name={t(`${root}.flexibleAnnualIncome2`)}
                    value={formatAmount(income.flexibleAnnualIncome2)}
                  />
                  <CardRow
                    name={t(`${root}.flexibleAnnualIncome3`)}
                    value={formatAmount(income.flexibleAnnualIncome3)}
                  />
                </>
              )}
              <CardRow
                name={t(`${root}.holidayAllowance`)}
                value={formatAmount(income.holidayAllowance)}
              />
              <CardRow
                name={t(`${root}.irregularHoursAllowance`)}
                value={formatAmount(income.irregularHoursAllowance)}
              />
              <CardRow
                name={t(`${root}.thirteenthMonth`)}
                value={formatAmount(income.thirteenthMonth)}
              />
              <CardRow name={t(`${root}.lumpSumFee`)} value={formatAmount(income.lumpSumFee)} />
              <CardRow name={t(`${root}.yearEndBonus`)} value={formatAmount(income.yearEndBonus)} />
              <CardRow
                name={t(`${root}.overtimeAllowance`)}
                value={formatAmount(income.overtimeAllowance)}
              />
            </>
          )}
        </CardSection>
      </SummaryCard>
    </>
  );
};

export default Employment;
