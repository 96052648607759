import React from 'react';
import { Box, Icon } from '@mui/material';
import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    'padding': theme.spacing(2),
    'borderRadius': 6,
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    'backgroundColor': alpha(theme.palette.primary.main, 0.1),
    '&.red': {
      backgroundColor: alpha(theme.palette.danger.main, 0.1),
    },
    '&.orange': {
      backgroundColor: alpha(theme.palette.warning.main, 0.1),
    },
    '&.grey': {
      backgroundColor: alpha(theme.palette.text.primary, 0.1),
    },
    '&.green': {
      backgroundColor: alpha(theme.palette.success.main, 0.1),
    },
    '&.blue': {
      backgroundColor: alpha(theme.palette.info.main, 0.1),
    },
  },
  icon: {
    'marginRight': theme.spacing(2),
    'color': theme.palette.primary.dark,
    '&.red': {
      color: theme.palette.danger.dark,
    },
    '&.orange': {
      color: theme.palette.warning.dark,
    },
    '&.grey': {
      color: theme.palette.text.primary,
    },
    '&.green': {
      color: theme.palette.success.dark,
    },
    '&.blue': {
      color: theme.palette.info.dark,
    },
  },
}));

type ApplicationCardAlertProps = {
  urgency: string;
  text: string;
};

const ApplicationCardAlert = (props: ApplicationCardAlertProps) => {
  const { urgency, text } = props;
  const classes = useStyles({ urgency: urgency });

  return (
    <div className={`${classes.root} ${urgency}`} data-testid="application-card-alert">
      <Box display="flex" alignItems="center" fontWeight={500} fontSize="12px" color="textPrimary">
        <Icon className={`${classes.icon} ${urgency}`}>alert_triangle</Icon>
        {text}
      </Box>
    </div>
  );
};

export default ApplicationCardAlert;
