import { object, string, number } from 'yup';
import { errorMessages } from 'screens/Mutations/constants';

const collateralSchemaBase = object().shape({
  marketValue: number().typeError(errorMessages.number).required(errorMessages.required).nullable(),
  isRenovation: string().required(errorMessages.required).nullable(),
  NHGGuarantee: string().required(errorMessages.required).nullable(),
  valuationCosts: number()
    .typeError(errorMessages.number)
    .required(errorMessages.required)
    .nullable(),
  renovationAmount: number().when('isRenovation', {
    is: (val: string) => val === 'yes',
    then: number().typeError(errorMessages.number).required(errorMessages.required).nullable(),
  }),
  EBVAmount: number(),
  growMarketValue: number().when('isRenovation', {
    is: (val: string) => val === 'yes',
    then: number().typeError(errorMessages.number).required(errorMessages.required).nullable(),
  }),
});

export const collateralSchema = object({
  collateral: collateralSchemaBase,
});
