import { FC, useState, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Summary from './components/Summary';
import TitleBar from '../TitleBar';
import BottomNavigation from '../BottomNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { useSubmitMutation } from '../../../../hooks/useMutations';
import { RootState } from 'store';
import { mapOHAForm } from '../../../../utils/mappers/oha/mapFormToApi';
import Agreement from '../../../Form/Agreement';
import { yupResolver } from '@hookform/resolvers/yup';
import { dismissalJointLiabilityMutationFormSchema } from '../../schema/summary/dismissalJointLiabilityMutationFormSchema';
import { setResultOhaMutation } from 'store/ohaFormReducer';
import FormTemplate from 'components/Templates/FormTemplate';
import FormStepper from '../FormStepper';
import { Form } from 'components/Molecules/components/Form/Form.styled';
import { useGoogleTagManager } from 'use/gtm';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import { useClients } from 'screens/Clients/hooks/useClients';

const noop = () => {};

const SummaryForm: FC = () => {
  const { handleNavigateOnCloseForm } = useClients();
  const googleTagManager = useGoogleTagManager();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [snackbar, setSnackbar] = useState<{ status: string; name: string }>();
  const ohaFormState = useSelector((state: RootState) => state.ohaForm);

  const formMethods = useForm({
    resolver: yupResolver(dismissalJointLiabilityMutationFormSchema),
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });
  const {
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = formMethods;

  const handleSuccess = () => {
    dispatch(setResultOhaMutation('success'));
    handleNavigateOnCloseForm();
    googleTagManager({
      event: 'submitForm',
      typeOfMutation: 'DismissalJointLiabilityMutation',
      moneylenderName: ohaFormState.moneylenderName ?? '',
    });
  };

  const handleError = (error: any) => {
    handleNotification('error', 'submit');
  };

  const { mutateAsync: submitApplication, isLoading: isLoadingsubmitApplication } =
    useSubmitMutation(handleSuccess, handleError);

  const onSubmit = () => submitApplication(mapOHAForm(ohaFormState).application!);

  const handleNotification = (status: string, name: string) => setSnackbar({ status, name });

  const handleOnClose = useCallback(() => setSnackbar(undefined), []);

  return (
    <>
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormTemplate
            header={
              <TitleBar
                onResult={handleNotification}
                formState={ohaFormState}
                resetForm={reset}
                isFormDirty={isDirty}
                dispatchCurrentForm={noop}
              />
            }
            sidebar={<FormStepper />}
            footer={
              <BottomNavigation
                dispatchCurrentForm={noop}
                isLoadingSubmit={isLoadingsubmitApplication}
              />
            }
          >
            <Summary />
            <Agreement
              errors={errors}
              showBkr
              title={t('directMutations.summary.agreementTitle')}
            />
          </FormTemplate>
        </Form>
      </FormProvider>
      {snackbar ? (
        <CustomSnackbar
          isOpen={!!snackbar}
          handleClose={handleOnClose}
          severity={snackbar.status === 'success' ? 'success' : 'error'}
          message={t(`directMutations.snackbar.${snackbar.name}.${snackbar.status}`)}
        />
      ) : null}
    </>
  );
};

export default SummaryForm;
