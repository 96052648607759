import { styled } from '@mui/styles';
import { Button, Theme } from '@mui/material';

type FilterButtonProps = {
  isselected?: 1 | 0;
};
export const FilterButton = styled(Button)<Theme, FilterButtonProps>(
  ({ theme, isselected, ...rest }) => {
    return {
      'width': 'max-content',
      'height': 32,
      'color': isselected ? theme.palette.common.white : 'rgba(255,255,255)',
      'backgroundColor': isselected ? theme.palette.primary.dark : theme.palette.primary.main,

      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '& .MuiButton-label': {
        textTransform: 'uppercase',
        fontSize: 11,
      },
      'marginRight': theme.spacing(1),
      'marginBottom': theme.spacing(1),
    };
  }
);
