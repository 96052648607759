import { SummaryCard } from 'components/SummaryCard';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { LoanPartType, ConnectedPoliciesFormType } from 'screens/Mutations/utils/types';
import { loanPartSummaryViewMap } from '../../utils/summaryViewMap';
import CardShape from '../CardShape';

type AdditionalSummaryCardProps = {
  loanParts?: LoanPartType[];
  connectedPolicies: ConnectedPoliciesFormType;
  goToStep: (number: number) => void;
};
const AdditionalSummaryCard: FC<AdditionalSummaryCardProps> = ({
  loanParts,
  connectedPolicies,
  goToStep,
}) => {
  const { t } = useTranslation();

  if (!loanParts || !loanParts.length) {
    return null;
  }

  return (
    <>
      {loanParts.map((loanPart: LoanPartType, index: number) => {
        const loanPartCardValues = loanPartSummaryViewMap(loanPart, connectedPolicies);
        return (
          <Fragment key={loanPart.loanPartNumber}>
            <SummaryCard
              testdId={`conversionMutation-connectedPolicies-${index}`}
              title={`${t('directMutations.form.loanPart')} ${loanPart.loanPartNumber}`}
              onEditClick={() => goToStep(0)}
            >
              <CardShape cardValues={loanPartCardValues} />
            </SummaryCard>
          </Fragment>
        );
      })}
    </>
  );
};

export default AdditionalSummaryCard;
