import { BaseApplicant } from 'screens/Mutations/utils/types';
import { defaultApplicant } from '../DismissalJointLiabilityMutation/constants';
import { RaiseForm } from './types';

const applicant = {
  ...defaultApplicant,
  income: {
    id: undefined,
    incomes: [],
    isIncome: false,
  },
} as BaseApplicant;

export const raiseFormDefaultValues = {
  applicants: [applicant], // there should always be one applicant
} as RaiseForm;
