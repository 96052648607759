import { Hidden, Box, Grid, Divider } from '@mui/material';
import Logo from 'components/Logo';
import StatusTag from 'components/StatusTag';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Emphasize, MobileTableRow } from 'screens/templates/Lists/ListWrapper/Wrapper.styled';
import { Case } from '__generated__/api-di';
import { formatLoanAmount, getCustomerNames } from 'utils/global';

type MobileProps = {
  items: Case[];
  onClickItem: (item: Case) => void;
};

const ApplicationMobile: FC<MobileProps> = ({ items, onClickItem }) => {
  const { t } = useTranslation();

  return (
    <Hidden smUp>
      {items.map((item) => (
        <Box
          key={`${item.application!.loanNumberExternal}-${item.application!.index}`}
          onClick={() => onClickItem(item)}
          data-testid="applicationList-mobile"
          style={{ cursor: 'pointer' }}
        >
          <Grid container my={1}>
            <Grid item xs={2}>
              <Logo label={item.lender!.label} size="M" alt="logo" />
            </Grid>
            <Grid item xs={9}>
              <Emphasize>{getCustomerNames(item)}</Emphasize>
              <MobileTableRow>
                <span>{item.lender!.name}</span>
                <span>‧</span>
                <span>{formatLoanAmount(item.application!.loanAmount!)} </span>
                <span>‧</span>
                <span>{t(`type.${item.application!.type}`)}</span>
              </MobileTableRow>
              <Grid my={2}>
                <StatusTag
                  urgency={item.application!.priority!.urgency!}
                  progress={item.application!.progress!}
                  label={t(`applications.filter.${item.application!.status!.value}`)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider />
        </Box>
      ))}
    </Hidden>
  );
};

export default ApplicationMobile;
