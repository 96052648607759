function handleRowsPerPageToScreenSize(screenSize: number): number {
  const margin = 48;
  const labelWidth = 220;
  const menuWidth = screenSize >= 600 ? 200 : 0;
  const columnWidth = 58;
  let columns = Math.floor((screenSize - menuWidth - margin - labelWidth) / columnWidth);
  if (columns < 1) columns = 1;
  if (columns > 15) columns = 15;
  return columns;
}

export default handleRowsPerPageToScreenSize;
