import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Container, Paper, Modal, Slide } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    paddingTop: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      marginLeft: theme.spacing(27),
      marginRight: theme.spacing(2),
    },
    zIndex: 1225,
  },
  content: {
    height: '100%',
    backgroundColor: 'white',
    outline: 0,
    borderRadius: '12px 12px 0 0',
    overflow: 'hidden',
    padding: '12px 0',
  },
}));

type ScreenModalProps = {
  open: boolean;
  onClose: () => void;
  onClosed?: () => void;
  children?: React.ReactNode;
};

const ScreenModal: FC<ScreenModalProps> = (props) => {
  const { open, onClose, onClosed } = props;
  const classes = useStyles();

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      className={classes.root}
      open={open}
      onClose={(e, reason: 'escapeKeyDown' | 'backdropClick') => {
        onClose();
      }}
    >
      <Slide direction="up" in={open} onExited={onClosed}>
        <Container className={classes.content} component={Paper} elevation={6} square>
          <>{props.children}</>
        </Container>
      </Slide>
    </Modal>
  );
};

export default ScreenModal;
