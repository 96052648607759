import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ViewManualDocument from '../../Manual/ViewManualDocument';
import { useGoogleTagManager } from 'use/gtm';
import Item from './Item';
import HelpOutlineIcon from '@mui/icons-material/MenuBook';
import { MenuItemWapper } from '../NavDrawer.styled';

const OpenManualButton = () => {
  const { t } = useTranslation();
  const [showManual, setShowManual] = useState(false);
  const googleTagManager = useGoogleTagManager();

  const handlePopUp = () => {
    setShowManual(!showManual);
    !showManual && googleTagManager({ event: 'ButtonClick', btnName: 'ExitManualFileView' });
  };

  return (
    <>
      <MenuItemWapper>
        <Item
          icon={<HelpOutlineIcon fontSize="inherit" />}
          menuName={t('general.header.menu.manual')}
          handleClick={() => handlePopUp()}
        />
      </MenuItemWapper>

      <ViewManualDocument open={showManual} onClose={() => handlePopUp()} />
    </>
  );
};

export default OpenManualButton;
