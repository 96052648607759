import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  stepLabel: {
    'overflow': 'hidden',
    'display': 'flex',
    'alignItems': 'center',
    'minHeight': 40,
    'padding': theme.spacing(1, 3),
    'borderRadius': theme.shape.borderRadius,
    'color': theme.palette.common.white,
    'fontSize': 14,
    'fontWeight': 300,
    'cursor': 'default',

    '&.Mui-active': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-completed': {
      color: theme.palette.common.white,
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  expanded: {
    '& ul': {
      height: 'auto',
      padding: theme.spacing(2, 0),
    },
    '& span': {
      borderRadius: '4px 4px 0 0',
    },
  },
  active: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: 500,
  },
}));
