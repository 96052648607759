import type { FC, ReactElement } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { FormLabel, FormHelperText } from '@mui/material';
import { FormInputProps } from '../FormInputProps';
import { InputWrapper, TextInput } from '../Forms.styled';

interface FormInputTextProps extends FormInputProps {
  placeholder?: string;
  defaultValue?: string | number;
  startAdornment?: ReactElement;
  required?: boolean;
  testId?: string;
  customExtraStyle?: {};
}

export const FormInputText: FC<FormInputTextProps> = ({
  name,
  label,
  multiline,
  placeholder,
  rows,
  required,
  defaultValue,
  startAdornment,
  disabled,
  maxLength,
  shouldUnregister = false,
  testId,
  customExtraStyle,
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    shouldUnregister,
  });

  return (
    <InputWrapper data-testid={`${testId || name}-input-wrapper`}>
      <FormLabel
        data-testid={`${testId || name}-input-label`}
        sx={customExtraStyle}
        required={required}
      >
        {label}
      </FormLabel>
      <TextInput
        InputProps={{
          startAdornment,
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          'maxLength': maxLength,
          'data-testid': testId ?? name,
        }}
        placeholder={placeholder}
        defaultValue={defaultValue}
        size="small"
        error={!!error}
        onChange={onChange}
        value={value || defaultValue || ''}
        fullWidth
        multiline={multiline}
        rows={rows}
        variant="outlined"
        disabled={disabled}
      />
      <FormHelperText data-testid="error-message-input" error={!!error}>
        {error?.message}
      </FormHelperText>
    </InputWrapper>
  );
};

export default FormInputText;
