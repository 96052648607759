import isEmpty from 'utils/predicates/isEmpty';
import { CurrentRealEstateReadDto } from '__generated__/api';
import mapBooleanToToggleType from './mapBooleanToToggleType';

const hasNhgGuarantee = (currentRealEstates?: CurrentRealEstateReadDto[] | null) => {
  if (isEmpty(currentRealEstates)) return undefined;

  const [currentRealEstate] = currentRealEstates;

  if (isEmpty(currentRealEstate.currentLoans)) return undefined;

  const [currentLoan] = currentRealEstate.currentLoans;

  return mapBooleanToToggleType(currentLoan.hasNhgGuarantee);
};

export default hasNhgGuarantee;
