import { SummaryCard, CardRow, CardSection } from 'components/SummaryCard';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EntrepreneurIncome } from 'screens/Mutations/utils/types';
import { getCompanyTypeLabel, getLegalFormLabel } from 'screens/Mutations/constants';
import { formatAmount } from 'utils/formatAmount';
import formatDate from 'utils/formatDate';
import { formatYesNo } from 'utils/formatYesNo';

type EntrepreneurIncomeProps = {
  income: EntrepreneurIncome;
  onClick: () => void;
};

const Entrepreneur: FC<EntrepreneurIncomeProps> = ({ income, onClick }) => {
  const { t } = useTranslation();

  const root = 'directMutations.income.entrepreneur';
  return (
    <>
      <SummaryCard title={t('directMutations.income.formTitle')} onEditClick={onClick}>
        <CardSection>
          <CardRow
            name={t('directMutations.income.typeOfIncome')}
            value={t('directMutations.income.incomeTypes.Entrepreneur')}
          />
          <CardRow
            name={t(`${root}.typeOfCompany`)}
            value={income.typeOfCompany && getCompanyTypeLabel(income.typeOfCompany)}
          />
          <CardRow
            name={t(`${root}.typeOfLegalForm`)}
            value={income.legalForm && getLegalFormLabel(income.legalForm)}
          />
          <CardRow name={t(`${root}.startDate`)} value={formatDate(income.startDate, '-')} />
          <CardRow
            name={t(`${root}.hasIncomeStatement`)}
            value={formatYesNo(income.hasIncomeStatement)}
          />
          {income.hasIncomeStatement === 'yes' && (
            <CardRow name={t(`${root}.testIncome`)} value={formatAmount(income.incomeAmount)} />
          )}
          {income.hasIncomeStatement === 'no' && (
            <>
              <CardRow
                name={t(`${root}.incomePreviousFirstYear`)}
                value={formatAmount(income.incomePreviousFirstYear)}
              />
              <CardRow
                name={t(`${root}.incomePreviousSecondYear`)}
                value={formatAmount(income.incomePreviousSecondYear)}
              />
              <CardRow
                name={t(`${root}.incomePreviousThirdYear`)}
                value={formatAmount(income.incomePreviousThirdYear)}
              />
            </>
          )}
        </CardSection>
      </SummaryCard>
    </>
  );
};

export default Entrepreneur;
