import React from 'react';
import { useWatch } from 'react-hook-form';
import ConnectedPoliciesTemplate from 'screens/Mutations/templates/ConnectedPolicies';

export default function ConnectedPolicies() {
  const financingLoan = useWatch({ name: 'financingLoan' });
  const currentLoanParts = useWatch({ name: 'currentLoanParts' });

  const form = {
    ...financingLoan,
    currentLoanParts,
  };
  return (
    <ConnectedPoliciesTemplate
      loanAmount={financingLoan.amount}
      loanForm={form}
      shouldUnregister={false}
    />
  );
}
