import { useMutation, useQuery } from 'react-query';
import { getApplicationRequestDetails } from '../../../api/applicationRegistration';
import { useGroup } from 'use/group';
import { DismissalJointLiabilityMutationApplication } from '__generated__/api';
import { AxiosResponse } from 'axios';
import { saveOhaMutation } from 'api/oha';

export function useMutateApplication(
  onSuccess: (data: AxiosResponse<DismissalJointLiabilityMutationApplication>) => void,
  onError: (error: any) => void
) {
  const group = useGroup();
  return useMutation(saveOhaMutation(group as string), {
    onSuccess,
    onError,
  });
}

export function useGetApplication(id = 0) {
  const group = useGroup();
  return useQuery(
    ['get-application-request-details', group],
    () => getApplicationRequestDetails(id, group as string),
    { enabled: false }
  );
}
