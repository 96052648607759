import { Case, CaseDetails } from '__generated__/api-di';
import { http, HEADER_SELECTED_GROUP_CLAIM } from '../http';

type ApplicationdataDetailsId = {
  commercialLabel: string;
  loanNumberExternal: number;
  applicationIndexNumber: number;
};

export async function getCasesData(
  group: string | null,
  commercialRelation: string | null
): Promise<Case[]> {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const url = `/api/v1/cases/commercialrelation/${commercialRelation}`;
  const response = await http.get<Case[]>(url, { headers });
  return response.data;
}

export async function getAllCases(group: string | null): Promise<Case[]> {
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const url = '/api/v1/cases';
  const response = await http.get<Case[]>(url, { headers });
  return response.data;
}

export async function getCaseDetails(
  id: ApplicationdataDetailsId,
  group: string | null
): Promise<CaseDetails> {
  const { commercialLabel, loanNumberExternal, applicationIndexNumber } = id;
  const url = `/api/v1/lenders/${commercialLabel}/cases/${loanNumberExternal}-${applicationIndexNumber}`;

  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const response = await http.get<CaseDetails>(url, { headers });
  return response.data;
}

export async function getASRCaseDetails(id: number, group: string | null): Promise<any> {
  const url = `/applicationregistration/${id}`;
  const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
  const response = await http.get(url, { headers });
  return response.data;
}

export async function updateAsrCasesDetails(id: number, group: string | null, asrDetails: any) {
  try {
    const headers = { [HEADER_SELECTED_GROUP_CLAIM]: group };
    const response = await http({
      url: '/applicationregistration',
      method: 'POST',
      headers: headers,
      data: JSON.stringify(asrDetails),
    });
    return { data: response.data, status: 200 };
  } catch (err: any) {
    const {
      response: { status },
    } = err;
    return { status: status };
  }
}

export async function sendAsrApplicationEx(formData: any, group: string) {
  const url = '/applicationregistration/submit';
  try {
    const response = await http.post<{}>(url, JSON.stringify(formData), {
      headers: {
        Accept: '*/*',
        [HEADER_SELECTED_GROUP_CLAIM]: group,
      },
    });
    return { data: response.data, status: 200 };
  } catch (err: any) {
    const {
      response: { status },
    } = err;
    return { status: status };
  }
}
