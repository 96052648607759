import React, { FC } from 'react';
import { Icon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  documentPage: {
    boxShadow: theme.shadows[6],
  },
  file: {
    marginTop: 48,
    marginBottom: 48,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
}));

const XlsxViewer: FC<{ file: File | string | undefined }> = ({ file }) => {
  const classes = useStyles();

  const fileName = (file as File)?.name;

  return (
    <>
      <div className={classes.container}>
        <div className={classes.file}>
          <Icon>file_text</Icon>
          <Typography>{fileName}</Typography>
        </div>
      </div>
    </>
  );
};

export default XlsxViewer;
