import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProofItem } from '../../types/ProofItem';
import { Document } from '../../types/Document';
import UploadDialog from './UploadDialog';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

type DocumentUploadProps = {
  open: boolean;
  commercialLabel?: string;
  loanNumber?: number;
  applicationIndexNumber?: number;
  proofItem: ProofItem;
  onSuccess: () => void;
  onCancel: () => void;
  uploadTarget?: (
    document: Document,
    updatePercentagProgress: (percent: number) => void,
    successResponse: () => void,
    errorResponse: (errorMessage: string) => void
  ) => void;
};

const DocumentUpload: React.FC<DocumentUploadProps> = (props) => {
  const { t } = useTranslation();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [filename, setFilename] = useState('');
  const closeSnackbar = () => setSnackbarOpen(false);

  const onSuccess = (file: string) => {
    setFilename(file);
    props.onSuccess();
    setSnackbarOpen(true);
  };

  return (
    <>
      {props.proofItem &&
        props.commercialLabel &&
        !!props.loanNumber &&
        !!props.applicationIndexNumber && (
          <UploadDialog
            open={props.open}
            proofItem={props.proofItem}
            commercialLabel={props.commercialLabel}
            loanNumber={props.loanNumber}
            applicationIndexNumber={props.applicationIndexNumber}
            onSuccess={onSuccess}
            onCancel={props.onCancel}
            uploadTarget={props.uploadTarget}
          />
        )}

      <CustomSnackbar
        isOpen={snackbarOpen}
        handleClose={closeSnackbar}
        severity="success"
        message={t('applications.upload.successMessage', {
          file: filename,
        })}
      />
    </>
  );
};

export default DocumentUpload;
