import { FC, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Grid as MUIGrid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormInputSingleCheckbox from 'components/Form/FormInputSingleCheckbox';

const Grid = styled(MUIGrid)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2, 0, 2, 0),
}));

type Props = {
  errors: any;
  showBkr?: boolean;
  title?: string;
};

const Agreement: FC<Props> = ({ errors, showBkr, title }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  if (errors && ref.current) ref.current.scrollIntoView({ behavior: 'smooth' });

  return (
    <>
      {title ? (
        <Box>
          <Typography variant="h5">{title}</Typography>
        </Box>
      ) : null}
      <Grid ref={ref} data-testid="checkbox-agreement">
        <FormInputSingleCheckbox
          required
          name="acceptedAgreement"
          label={t('directMutations.summary.agreementText')}
        />
        {showBkr && (
          <FormInputSingleCheckbox
            required
            name="acceptedBkrAgreement"
            label={t('directMutations.summary.agreementBkrText')}
          />
        )}
      </Grid>
    </>
  );
};

export default Agreement;
