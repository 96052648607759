import { Grid } from '@mui/material';
import LoadingIndicator from 'components/LoadingIndicator';
import PlaceholderEmptyList from 'components/PlaceholderEmptyList';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { MoneyLenderTilesProps } from 'types/MoneyLenders';
import { ButtonCard } from '../Home.styled';
import { useTranslation } from 'react-i18next';
import Tile from './Tile';

export type HomeTilesAreaProps = {
  moneyLenders?: MoneyLenderTilesProps[];
  isLoading: boolean;
  isError: boolean;
};

export default function HomeTilesArea({ moneyLenders, isLoading, isError }: HomeTilesAreaProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleButtonClick = useCallback(
    (commercialRelation: string) => {
      navigate(`/${commercialRelation}/applications`);
    },
    [navigate]
  );

  if (isLoading) {
    return (
      <Grid container spacing={2}>
        <LoadingIndicator data-testid="loading" />
      </Grid>
    );
  }

  if (isError || !moneyLenders?.length || !Array.isArray(moneyLenders)) {
    const title = t('home.noTilesTitle');
    const subtitle = t('home.noTilesSubtitle');
    return <PlaceholderEmptyList title={title} subtitle={subtitle} />;
  }
  return (
    <Grid container spacing={1}>
      {isLoading ? (
        <LoadingIndicator data-testid="loading" />
      ) : (
        <>
          {moneyLenders?.map((tileInfo) => (
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              key={tileInfo.name}
              data-testid="moneyLenderTiles"
            >
              <ButtonCard
                fullWidth
                onClick={() => handleButtonClick(tileInfo.name)}
                variant="outlined"
              >
                <Tile tileInfo={tileInfo} />
              </ButtonCard>
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
}
