import { format } from 'date-fns';

const base36Chars = Object.freeze('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''));

export function getNewTicketId(): string {
  const sb = new Array(5)
    .fill(null)
    .map(() => base36Chars[getRandomInt(base36Chars.length)])
    .join('');
  const now = new Date();
  const ticketId = `${format(now, 'yyMMdd-HHmm')}-${sb}`;
  return ticketId;
}

function getRandomInt(max: number): number {
  return Math.floor(Math.random() * Math.floor(max));
}
