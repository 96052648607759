import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, StandardTextFieldProps, TextField, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lengthHint: {
      fontSize: '80%',
      marginTop: '-16px',
      textAlign: 'right',
      marginRight: '10px',
      position: 'relative',
    },
    inputBackground: {
      background: theme.palette.white.main,
    },
  })
);

export interface TextAreaFieldProps extends StandardTextFieldProps {
  charactersLeftThreshold?: number;
}

const TextArea = (props: TextAreaFieldProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { charactersLeftThreshold, ...other } = props;
  const [showHint, setShowHint] = useState<boolean>(false);
  const [charactersLeft, setCharactersLeft] = useState<number>(0);

  useEffect(() => {
    let showHintMessage: boolean = false;
    const defaultCharacterLeftThreshold = 10;
    const val = (props.value as string)?.toString() || '';

    if (props.inputProps?.maxLength) {
      // check if the characters left hint should be displayed
      if (
        val.length >
        props.inputProps?.maxLength -
          ((charactersLeftThreshold || defaultCharacterLeftThreshold) + 1)
      ) {
        showHintMessage = true;
        setCharactersLeft(props.inputProps?.maxLength - val.length);
      } else {
        showHintMessage = false;
      }
    }

    setShowHint(showHintMessage);
  }, [props.value, props.inputProps?.maxLength, charactersLeftThreshold]);

  return (
    <FormControl fullWidth required={props.required}>
      <FormLabel>{props.label}</FormLabel>
      <TextField
        {...other}
        label={null}
        multiline={true}
        variant="outlined"
        fullWidth
        className={classes.inputBackground}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {showHint && (
        <div className={classes.lengthHint}>
          {t('lib.textArea.charactersLeft', { charactersLeft })}
        </div>
      )}
    </FormControl>
  );
};

export default TextArea;
