import { FC, useState } from 'react';
import { Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import ClampLines from 'react-clamp-lines';
import { ChevronRight } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SubInfoNews from './SubInfoNews';
import { NewsItem } from '__generated__/api';
import NewsCard from './NewsCard';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.grey[500],
  },
  readArticleBtn: {
    color: theme.palette.primary.main,
    marginLeft: '-16px',
  },
  otherResults: {
    fontSize: '13px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderRadius: 2,
    margin: theme.spacing(5, 0),
    color: theme.palette.grey[500],
  },
  btnPlacement: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(4),
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  categoryCard: {
    height: '150px',
  },
}));

type Props = {
  items: NewsItem[];
  handleClick: any;
};

const SearchedOnTextResult: FC<Props> = ({ items, handleClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const sortedItems = items
    .slice()
    .sort((a, b) => new Date(b.dateCreated!).getTime() - new Date(a.dateCreated!).getTime());

  const [itemsPerPage, setItemsPerPage] = useState<number>(3);
  const handleShowMoreItems = () => {
    setItemsPerPage(itemsPerPage + 3);
  };

  return (
    <>
      <div className={classes.otherResults}>{t('news.filter.otherResults')}</div>
      <Grid container spacing={5}>
        {sortedItems.slice(0, itemsPerPage).map((item: NewsItem, index: number) => (
          <Grid sm={6} md={4} key={index} item>
            <NewsCard newsItem={item} className={classes.categoryCard} />
            <SubInfoNews newsItem={item} />
            <ClampLines
              text={item.title!}
              id={item.id!}
              lines={1}
              ellipsis="..."
              buttons={false}
              innerElement="h3"
            />

            <ClampLines
              text={item.introParagraph!}
              id={item.id!}
              lines={3}
              ellipsis="..."
              buttons={false}
              className={classes.text}
              innerElement="p"
            />

            <Button
              variant="text"
              className={classes.readArticleBtn}
              onClick={() => {
                handleClick(item);
              }}
            >
              {t('news.readFullArticle')}
              <ChevronRight className={classes.icon} color="primary" />
            </Button>
          </Grid>
        ))}
      </Grid>
      <div className={classes.btnPlacement}>
        {sortedItems.length > 4 && sortedItems.length > itemsPerPage && (
          <Button variant="contained" color="primary" onClick={handleShowMoreItems}>
            {t('news.loadMore')}
            <KeyboardArrowDownIcon className={classes.icon} />
          </Button>
        )}
      </div>
    </>
  );
};

export default SearchedOnTextResult;
