import { ReactElement } from 'react';
import { ItemContainer, IconContainer } from '../NavDrawer.styled';

type ItemProps = {
  icon: ReactElement;
  menuName: string;
  handleClick: () => void;
  children?: ReactElement;
};

export default function Item({ icon, menuName, handleClick, children }: ItemProps) {
  return (
    <ItemContainer disableGutters onClick={handleClick}>
      <IconContainer>{icon}</IconContainer>
      <span>{menuName}</span>
      <span style={{ marginLeft: 'auto' }}>{children}</span>
    </ItemContainer>
  );
}
