import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Step, Stepper, StepLabel, StepContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import BtlApplicantSteps from './BuyToLetApplicantSteps';
import backgroundImageStater from 'assets/images/half.png';

const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(7.5, 4),
    backgroundColor: theme.palette.purple.dark,
    backgroundImage: `url("${backgroundImageStater}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 70px',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(7.5, 2),
    },
  },
  step: {
    cursor: 'pointer',
  },
  noPadding: {
    paddingTop: 16,
    paddingBottom: 0,
  },
  stepLabel: {
    'width': 250,
    'height': 40,
    'whiteSpace': 'nowrap',
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
    'lineHeight': '40px',
    'padding': theme.spacing(0, 3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 1),
      width: 200,
    },
    'borderRadius': theme.shape.borderRadius,
    'color': theme.palette.common.white,
    'fontSize': 14,
    'fontWeight': 300,
    '&.Mui-active': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      fontWeight: 400,
    },
    '&.Mui-completed': {
      color: theme.palette.common.white,
      fontWeight: 300,
    },
  },
  stepIndention: {
    marginLeft: '21px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '8px',
    },
  },
  stepContent: {
    margin: 0,
    padding: 0,
    borderLeft: 'none',
    width: 250,
    [theme.breakpoints.down('md')]: {
      width: 200,
    },
  },
}));

type BuyToLetStepsProps = {
  activeStep: number;
  activeSubStep: number;
  changeStep: (index: number) => void;
  changeSubStep: (index: number) => void;
  steps: {
    label: string;
    realEstate?: number | undefined;
    applicant?: number | undefined;
    loanPart?: number | undefined;
  }[];
  isLegalPerson?: boolean | null;
  latestStepSeen: number;
  latestSubStepSeen: any;
};

const BuyToLetSteps: FC<BuyToLetStepsProps> = ({
  activeStep,
  changeStep,
  changeSubStep,
  activeSubStep,
  steps,
  isLegalPerson,
  latestStepSeen,
  latestSubStepSeen,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Stepper className={classes.stepper} activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => {
        const stepIsDisabled = index > latestStepSeen;
        return (
          <Step
            key={`${step.label}-${index}`}
            className={`${!stepIsDisabled && classes.step} ${
              step.applicant !== undefined ||
              step.realEstate !== undefined ||
              step.loanPart !== undefined
                ? classes.stepIndention
                : ''
            }`}
            disabled={stepIsDisabled}
          >
            <StepLabel
              classes={{
                label: classes.stepLabel,
              }}
              className={classes.noPadding}
              onClick={() => !stepIsDisabled && changeStep(index)}
              // disabled={stepIsDisabled}
            >
              <>
                {(step.applicant !== undefined && step.label !== 'newApplicant') ||
                (step.realEstate !== undefined && step.label !== 'newRealEstate')
                  ? step.label
                  : t(`buyToLetForm.${step.label}.title`)}
              </>
            </StepLabel>
            {step.applicant !== undefined && (
              <StepContent className={classes.stepContent}>
                <BtlApplicantSteps
                  activeApplicantIndex={step?.applicant}
                  activeSubStep={activeSubStep}
                  changeSubStep={changeSubStep}
                  isLegalPerson={isLegalPerson}
                  latestSubStepSeen={latestSubStepSeen}
                />
              </StepContent>
            )}
          </Step>
        );
      })}
    </Stepper>
  );
};

export default BuyToLetSteps;
