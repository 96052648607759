import {
  EmploymentIncomeReadDto,
  EntrepreneurIncomeReadDto,
  OtherIncomeReadDto,
  SocialSecurityIncomeReadDto,
  TypeOfEmployment,
  TypeOfOtherIncome,
} from '__generated__/api';
import {
  EntrepreneurIncome,
  EmploymentIncome,
  IncomeFormType,
  Income,
  SocialSecurityIncome,
  TypeOfIncome,
  OtherIncome,
} from '../types';
import { formatISO } from 'date-fns';

const isOtherIncome = ({ otherIncome, typeOfIncome }: Income) =>
  Boolean(typeOfIncome === TypeOfIncome.Other && otherIncome);

const isEntrepreneur = ({ entrepreneur, typeOfIncome }: Income) =>
  Boolean(typeOfIncome === TypeOfIncome.Entrepreneur && entrepreneur);

const isEmployment = ({ employment, typeOfIncome }: Income) =>
  Boolean(
    typeOfIncome === TypeOfIncome.Employment &&
      employment &&
      employment.typeOfEmployment !== TypeOfEmployment.SocialInsuranceWage
  );

const isSocialInsuranceWage = ({ employment, typeOfIncome }: Income) =>
  Boolean(
    typeOfIncome === TypeOfIncome.Employment &&
      employment &&
      employment.typeOfEmployment === TypeOfEmployment.SocialInsuranceWage
  );

const isPension = ({ pension, typeOfIncome }: Income) =>
  Boolean(typeOfIncome === TypeOfIncome.Pension && pension);

const isSocialSecurity = ({ socialSecurity, typeOfIncome }: Income) =>
  Boolean(typeOfIncome === TypeOfIncome.SocialSecurity && socialSecurity);

const mapEntrepreneurIncome = (
  entrepreneurIncome: EntrepreneurIncome
): EntrepreneurIncomeReadDto => {
  const {
    legalForm,
    startDate,
    typeOfCompany,
    hasIncomeStatement,
    incomeAmount,
    incomePreviousFirstYear,
    incomePreviousSecondYear,
    incomePreviousThirdYear,
  } = entrepreneurIncome;

  const rest =
    hasIncomeStatement === 'yes'
      ? { averageIncome: incomeAmount }
      : {
          annualIncome1: incomePreviousFirstYear,
          annualIncome2: incomePreviousSecondYear,
          annualIncome3: incomePreviousThirdYear,
        };

  return {
    legalForm,
    startDate: startDate
      ? formatISO(new Date(startDate) as Date, { representation: 'date' })
      : null,
    typeOfCompany,
    ...rest,
  };
};

const mapEmploymentIncome = ({
  typeOfEmployment,
  flexibleAnnualIncome1,
  flexibleAnnualIncome2,
  flexibleAnnualIncome3,
  startDate,
  endDate,
  ...rest
}: EmploymentIncome): EmploymentIncomeReadDto => {
  const base = {
    typeOfEmployment,
    startDate: startDate
      ? formatISO(new Date(startDate) as Date, { representation: 'date' })
      : null,
    endDate: endDate ? formatISO(new Date(endDate) as Date, { representation: 'date' }) : null,
    ...rest,
  };

  return typeOfEmployment === TypeOfEmployment.FlexibleWithoutPerspective ||
    typeOfEmployment === TypeOfEmployment.FlexibleWithPerspective
    ? { ...base, flexibleAnnualIncome1, flexibleAnnualIncome2, flexibleAnnualIncome3 }
    : base;
};

export const mapEntrepreneurIncomes = ({ incomes }: IncomeFormType) => {
  const filteredIncomes = incomes.filter(isEntrepreneur).map((x) => x.entrepreneur!);

  if (filteredIncomes.length === 0) return [];

  return filteredIncomes.map(mapEntrepreneurIncome);
};

const mapSocialInsuranceWage = ({
  incomeAmount: amount,
  pensionContribution,
  numberOfEmployments,
}: EmploymentIncome): OtherIncomeReadDto => ({
  amount,
  typeOfOtherIncome: TypeOfOtherIncome.SocialInsuranceWage,
  pensionContribution,
  numberOfEmployments,
});

const mapOtherIncome = ({
  typeOfOtherIncome,
  endDate,
  incomeAmount,
}: OtherIncome): OtherIncomeReadDto => {
  return {
    typeOfOtherIncome,
    endDate: endDate ? formatISO(new Date(endDate) as Date, { representation: 'date' }) : null,
    amount: incomeAmount,
  };
};

export const mapOtherIncomes = ({ incomes }: IncomeFormType) => {
  const otherIncomes = incomes.filter(isOtherIncome).map((x) => x.otherIncome!);

  const socialInsuranceWages = incomes.filter(isSocialInsuranceWage).map((x) => x.employment!);

  if (otherIncomes.length === 0 && socialInsuranceWages.length === 0) return [];

  return [...otherIncomes.map(mapOtherIncome), ...socialInsuranceWages.map(mapSocialInsuranceWage)];
};

export const mapEmploymentIncomes = ({ incomes }: IncomeFormType) => {
  const filteredIncomes = incomes.filter(isEmployment).map((x) => x.employment!);

  if (filteredIncomes.length === 0) return [];

  return filteredIncomes.map(mapEmploymentIncome);
};

const mapSocialSecurityIncome = (
  socialSecurity: SocialSecurityIncome
): SocialSecurityIncomeReadDto => {
  const { typeOfBenefit, startDate, endDate, incomeAmount } = socialSecurity;

  return {
    typeOfBenefit,
    startDate: startDate
      ? formatISO(new Date(startDate) as Date, { representation: 'date' })
      : null,
    endDate: endDate ? formatISO(new Date(endDate) as Date, { representation: 'date' }) : null,
    benefit: incomeAmount,
  };
};

export const mapSocialSecurityIncomes = ({ incomes }: IncomeFormType) => {
  const socialSecurityIncomes = incomes.filter(isSocialSecurity).map((x) => x.socialSecurity!);
  const pensionIncomes = incomes.filter(isPension).map((x) => x.pension!);

  if (socialSecurityIncomes.length === 0 && pensionIncomes.length === 0) return [];

  const allSocialSecurityIncomes = [...socialSecurityIncomes, ...pensionIncomes];

  return allSocialSecurityIncomes.map(mapSocialSecurityIncome);
};
