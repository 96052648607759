import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInputDropdown } from 'components/Form';
import { getIncomeAmount } from './utils';
import { useFormContext } from 'react-hook-form';
import { ExpandMore } from '@mui/icons-material';
import AccordionIncomeHeader from './components/AccordionIncomeHeader';
import Entrepreneur from './components/Entrepreneur';
import { Income as IncomeType, TypeOfIncome } from 'screens/Mutations/utils/types';
import React from 'react';
import { getTypeOfIncomeOptions } from 'screens/Mutations/constants';
import OtherIncome from './components/Other';
import Pension from './components/Pension';
import SocialSecurity from './components/SocialSecurity';
import Employment from './components/Employment';

import { Grid } from '@mui/material';
import { FieldsGrid, FormGrid } from 'screens/Mutations/molecules/styled';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'screens/Mutations/molecules/Accordion/Accordion';

type Props = {
  incomeIndex: number;
  isInputDropdown?: boolean;
  rootFormName?: string;
  // Omzetten en verhogen has a single form this results in shouldUnregister not working correctly
  shouldUnregister?: boolean;
  children?: React.ReactNode;
};

const Income: FC<Props> = ({
  incomeIndex,
  children,
  isInputDropdown = true,
  rootFormName,
  shouldUnregister = true,
}) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const rootForm = `${rootFormName ?? ''}incomes.${incomeIndex}`;
  const typeOfIncome: TypeOfIncome | undefined = watch(`${rootForm}.typeOfIncome`);
  const income: IncomeType | undefined = watch(rootForm);
  const incomeAmount = getIncomeAmount(income);

  return (
    <FormGrid container>
      <Grid item xs={12}>
        <Accordion data-testid="income-section" defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`panel${incomeIndex}-content`}
            id={`panel${incomeIndex}-content`}
          >
            <AccordionIncomeHeader typeOfIncome={typeOfIncome} incomeAmount={incomeAmount} />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <FieldsGrid item>
                {isInputDropdown && (
                  <FormInputDropdown
                    name={`${rootForm}.typeOfIncome`}
                    label={t('directMutations.income.typeOfIncome')}
                    options={getTypeOfIncomeOptions()}
                    placeholder={t('directMutations.income.chooseTypeOfIncome')}
                    required
                  />
                )}
                {typeOfIncome === TypeOfIncome.Entrepreneur && (
                  <Entrepreneur index={incomeIndex} rootFormName={rootFormName} />
                )}
                {typeOfIncome === TypeOfIncome.SocialSecurity && (
                  <SocialSecurity index={incomeIndex} rootFormName={rootFormName} />
                )}
                {typeOfIncome === TypeOfIncome.Pension && (
                  <Pension index={incomeIndex} rootFormName={rootFormName} />
                )}
                {typeOfIncome === TypeOfIncome.Employment && (
                  <Employment
                    index={incomeIndex}
                    rootFormName={rootFormName}
                    shouldUnregister={shouldUnregister}
                  />
                )}
                {typeOfIncome === TypeOfIncome.Other && (
                  <OtherIncome index={incomeIndex} rootFormName={rootFormName} />
                )}
              </FieldsGrid>
            </Grid>

            {React.Children.toArray(children)}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </FormGrid>
  );
};

export default Income;
