import { FC } from 'react';
import clsx from 'clsx';
import { StepIconProps } from '@mui/material';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Check } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  root: {
    color: alpha(theme.palette.common.white, 0.4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 26,
    height: 26,
  },
  active: {
    color: theme.palette.common.white,
  },
  circle: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.success.main,
    zIndex: 1,
    fontSize: '18px',
  },
}));

const StepIcon: FC<StepIconProps> = ({ active, completed }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
};

export default StepIcon;
