import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, SaveOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setApplicationId, saveIntermediary } from 'store/ohaFormReducer';
import { ConfirmationDialog } from 'components/CustomDialog';
import { mapOHAForm } from 'screens/Mutations/utils/mappers/oha/mapFormToApi';
import type { OHAForm } from 'screens/Mutations/utils/types';
import { useDeleteApplication } from 'use/applications';
import { DismissalJointLiabilityMutationApplication } from '__generated__/api';
import { RootState } from 'store';
import { useMutateApplication } from 'screens/Mutations/hooks/useUpdateApplication';
import { AxiosResponse } from 'axios';
import { UseFormReset } from 'react-hook-form';
import { LoadingIconButton } from 'components/LoadingIconButton';
import StyledTitleBar from 'components/TitleBar';
import { ButtonText, IconButton } from './TitleBar.styled';
import { useGoogleTagManager } from 'use/gtm';
import { useClients } from 'screens/Clients/hooks/useClients';
import { useExpoints } from 'screens/Clients/hooks/useExpoints';

type Status = 'success' | 'error';

type Action = 'deleteApplication' | 'saveApplication';

type TitleBarProps = {
  onResult: (status: Status, translationKey: Action) => void;
  resetForm: UseFormReset<any>;
  isFormDirty: boolean;
  formState: OHAForm;
  dispatchCurrentForm: () => void;
};

export const TitleBar: FC<TitleBarProps> = ({
  formState,
  isFormDirty,
  onResult,
  resetForm,
  dispatchCurrentForm,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [saveAndExitDialog, setSaveAndExitDialog] = useState(false);
  const [deleteApplicationDialog, setDeleteApplicationDialog] = useState(false);
  const { moneylenderName } = useSelector((state: RootState) => state.ohaForm);
  const googleTagManager = useGoogleTagManager();
  const { handleNavigateOnCloseForm } = useClients();
  const { callMutationTerminatedSurvey } = useExpoints();

  const terminate = () => {
    callMutationTerminatedSurvey('oha');
  };

  const onSuccessDelete = () => {
    onResult('success', 'deleteApplication');
    setDeleteApplicationDialog(false);
    handleNavigateOnCloseForm();
  };

  const onErrorDelete = () => onResult('error', 'deleteApplication');

  const onSuccessSave = (result: AxiosResponse<DismissalJointLiabilityMutationApplication>) => {
    if (result.data && result.data.application) {
      dispatch(setApplicationId(result.data.application.id!));
      dispatch(saveIntermediary(result.data.application.intermediary!));
    }
    googleTagManager({
      event: 'saveForm',
      typeOfMutation: 'OHA',
      moneylenderName: moneylenderName ?? '',
    });
    // todo: add in reset form when application is saved to make form prestine again.
    resetForm({}, { keepValues: true });
    onResult('success', 'saveApplication');
  };

  const onErrorSave = (error: any) => {
    onResult('error', 'saveApplication');
  };

  const { mutateAsync: saveApplication, isLoading: isSaving } = useMutateApplication(
    onSuccessSave,
    onErrorSave
  );

  const { mutate: deleteApplication } = useDeleteApplication(
    formState.id ?? 0,
    onSuccessDelete,
    onErrorDelete
  );

  const handleClose = () => {
    if (isFormDirty) {
      setSaveAndExitDialog(true);
    } else {
      handleNavigateOnCloseForm();
    }
  };

  const handleSaveAndExit = async () => {
    const result = await handleSaveApplication(mapOHAForm({ ...formState }));
    if (result.status >= 200 && result.status < 300) {
      setSaveAndExitDialog(false);
      handleNavigateOnCloseForm();
    }
  };

  const handleSaveApplication = useCallback(
    (allFields: DismissalJointLiabilityMutationApplication) => {
      dispatchCurrentForm();
      return saveApplication(allFields);
    },
    [dispatchCurrentForm, saveApplication]
  );

  const handleClickSave = useCallback(
    () => handleSaveApplication(mapOHAForm({ ...formState })),
    [formState, handleSaveApplication]
  );

  return (
    <>
      <StyledTitleBar
        title={t('directMutations.header.ohaTitle')}
        moneyLender={moneylenderName!}
        onCloseClick={handleClose}
      >
        <LoadingIconButton isLoading={isSaving} onClick={handleClickSave} size="medium">
          <SaveOutlined />
          <ButtonText>{t('directMutations.header.save')}</ButtonText>
        </LoadingIconButton>
        <IconButton
          onClick={() => {
            setDeleteApplicationDialog(true);
          }}
        >
          <DeleteOutlined />
          <ButtonText>{t('directMutations.header.delete')}</ButtonText>
        </IconButton>
      </StyledTitleBar>

      <ConfirmationDialog
        isOpen={saveAndExitDialog}
        onConfirmation={handleSaveAndExit}
        onDecline={() => {
          setSaveAndExitDialog(false);
          handleNavigateOnCloseForm();
          terminate();
        }}
        onClose={() => setSaveAndExitDialog(false)}
        confirmationText={t('directMutations.confirmSaveOnExit.confirm')}
        declineText={t('directMutations.confirmSaveOnExit.decline')}
        bodyText={t('directMutations.confirmSaveOnExit.body')}
      />
      <ConfirmationDialog
        isOpen={deleteApplicationDialog}
        onConfirmation={deleteApplication}
        onDecline={() => setDeleteApplicationDialog(false)}
        onClose={() => setDeleteApplicationDialog(false)}
        confirmationText={t('directMutations.buttons.deleteButton')}
        declineText={t('directMutations.buttons.cancelButton')}
        bodyText={t('directMutations.confirmDelete.bodyMutation')}
      />
    </>
  );
};

export default TitleBar;
