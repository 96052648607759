import clsx from 'clsx';
import { FC } from 'react';
import { useStyles } from './Item.styled';
import { SubMenu } from './SubMenu';

type ItemProps = {
  active?: boolean;
  expanded?: boolean;
  text?: string;
  step?: string;
  onClick?: (e: any) => void;
  clickable?: boolean;
  children?: React.ReactNode;
  className?: string;
  testid?: string;
};

export const Item: FC<ItemProps> = ({
  active = true,
  expanded = false,
  text,
  step,
  onClick,
  clickable,
  children,
  className,
  testid,
}) => {
  const classes = useStyles();
  const isExpanded = (active || expanded) && children;

  return (
    <li
      data-testid={testid}
      key={step}
      className={clsx(`${isExpanded ? `${className || ''} ${classes.expanded}` : className || ''}`)}
    >
      <span
        className={`${active ? classes.active : ''} ${clickable ? classes.clickable : ''} ${
          classes.stepLabel
        }`}
        onClick={onClick}
      >
        {text}
      </span>
      {children ? <SubMenu>{children}</SubMenu> : null}
    </li>
  );
};

export default Item;
