import { errorMessages, typeOfObligations } from 'screens/Mutations/constants';
import { defaultDate, endDate } from 'utils/form/validations';
import percentage from 'utils/form/validations/percentage';
import isEmpty from 'utils/predicates/isEmpty';
import { addMethod, array, date, DateSchema, lazy, number, object, string } from 'yup';
import { TypeOfObligation } from '__generated__/api';

// eslint-disable-next-line func-names
addMethod<DateSchema>(date, 'isFutureDate', function (message: string | undefined) {
  // TODO: place in common location
  // we probably need to place our jest-config in a TS file
  // to get this working so we can import it in the same location
  return this.test({
    name: 'isFutureDate',
    message,
    test: (value) => (value ? value > new Date() : true),
  });
});

const isExistingMortgage = (typeOfObligation: TypeOfObligation) =>
  typeOfObligation === TypeOfObligation.ExistingMortgage;

const typeOfObligation = string()
  .oneOf(typeOfObligations().map((f) => f.value as string))
  .required(errorMessages.required);

const requiredNumber = number()
  .min(1)
  .required(errorMessages.required)
  .typeError(errorMessages.number);

const yesOrNo = string().oneOf(['yes', 'no']).nullable().required(errorMessages.required);

const baseSchema = {
  typeOfObligation,
  partialRedemptionByPersonalEquity: yesOrNo,
  redemptionByNewMortgage: yesOrNo,
  principalDebt: requiredNumber,
  obligationAmount: number().nullable(true).typeError(errorMessages.number),
  repayableAmount: requiredNumber,
  actualDebt: requiredNumber,
};

const existingMortgageSchema = {
  ...baseSchema,
  redemptionTypeDisplayName: string().required(errorMessages.required),
  startDate: defaultDate.isPastDate(errorMessages.PastDate).required(errorMessages.required),
  endDate: endDate.isFutureDate(errorMessages.FutureDate).required(errorMessages.required),
  fixedInterestPeriod: requiredNumber.max(999),
  interestPercentage: percentage.required(errorMessages.required),
  amountTaxBox1: number().min(0).required(errorMessages.required).typeError(errorMessages.number),
  endDateInterestDeduction: defaultDate
    .isFutureDate(errorMessages.FutureDate)
    .required(errorMessages.required),
  amountTaxBox3: number().min(0).required(errorMessages.required).typeError(errorMessages.number),
  registryRank: number()
    .min(0)
    .max(999, errorMessages.maxNumber)
    .required(errorMessages.required)
    .typeError(errorMessages.number),
};

const defaultSchema = {
  ...baseSchema,
  isObligationByBKR: yesOrNo,
};

export const obligationSchema = lazy(({ typeOfObligation: value }) => {
  if (isEmpty(value)) return object().shape({ typeOfObligation });

  if (isExistingMortgage(value)) return object().shape(existingMortgageSchema);

  return object().shape(defaultSchema);
});

// Lazy does not work with array.of(), see: https://github.com/jquense/yup/issues/1283#issuecomment-786559444
const obligationsSchema = object({ obligations: array().of(obligationSchema as any) });

export default obligationsSchema;
