import { LoanPartPolicy, LoanPartType, Policy } from '../types';
/**
 * return the policies that are connected to the loanpart
 *
 * @param loanPart
 * @param loanPartPolicies
 * @returns Policy[]
 */
export const findConnectedPolicies = (
  loanPart: LoanPartType,
  loanPartPolicies?: LoanPartPolicy[]
): Policy[] => {
  const connectedPolicies =
    loanPartPolicies &&
    loanPartPolicies.filter(
      (loanPartPolicy) =>
        loanPartPolicy.loanPartNumber === loanPart.loanPartNumber &&
        loanPart.hasConnectedPolicy === 'yes'
    )[0];
  return connectedPolicies ? connectedPolicies.policies : [];
};
